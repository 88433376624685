import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
  drawerWidth: number;
  drawerOpen: boolean;
}

const initialState: SettingsState = {
  drawerWidth: 240,
  drawerOpen: true,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateDrawerWidth: (state, action: PayloadAction<number>) => {
      state.drawerWidth = action.payload;
    },
    toggleDrawerOpen: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateDrawerWidth, toggleDrawerOpen } = settingsSlice.actions;

export default settingsSlice.reducer;
