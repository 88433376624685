import produce from "immer";
import { MessageDriver, Schedule } from "../api/types/schedule";

export function processUpdate(msg: any, schedule: Schedule) {
  //console.log("Received schedule update:", msg, schedule);

  if (schedule) {
    const newSchedule = produce(schedule, (draft) => {
      //let processedFields = 0;

      if (msg["day_empty_shifts"]) {
        // console.log("Received day_empty_shifts update");
        draft.day_empty_shifts = {
          ...draft.day_empty_shifts,
          ...msg["day_empty_shifts"],
        };
        //processedFields += 1;
      }

      if (msg["day_issues"]) {
        // console.log("Received day_issues update");
        draft.day_issues = {
          ...draft.day_issues,
          ...msg["day_issues"],
        };
        //processedFields += 1;
      }

      if (msg["save_status"]) {
        // console.log("Received save_status update");
        draft.save_status = { ...draft.save_status, ...msg["save_status"] };
        //processedFields += 1;
      }

      if (msg["planner_status"]) {
        // console.log("Received planner_status update");
        draft.planner_status = {
          ...draft.planner_status,
          ...msg["planner_status"],
        };
        //processedFields += 1;
      }

      if (msg["expl_plan_status"]) {
        // console.log("Received expl_plan_status update");
        draft.expl_plan_status = {
          ...draft.expl_plan_status,
          ...msg["expl_plan_status"],
        };
        //processedFields += 1;
      }

      if (msg["expl_plan_counts"]) {
        // console.log("Received expl_plan_counts update");
        draft.expl_plan_counts = msg["expl_plan_counts"];
        //processedFields += 1;
      }

      if (msg["expl_plan_counts_by_vt"]) {
        // console.log("Received expl_plan_counts_by_vt update");
        draft.expl_plan_counts_by_vt = msg["expl_plan_counts_by_vt"];
        //processedFields += 1;
      }

      if (msg["drivers_sync_status"]) {
        // console.log("Received drivers_sync_status update");
        draft.drivers_sync_status = {
          ...draft.drivers_sync_status,
          ...msg["drivers_sync_status"],
        };
        //processedFields += 1;
      }

      if (msg["db_schedule"]) {
        // console.log("Received db_schedule update");
        draft.db_schedule = { ...draft.db_schedule, ...msg["db_schedule"] };
        //processedFields += 1;
      }

      if (msg["drivers"]) {
        console.time("Drivers update");
        const msgDrivers: MessageDriver[] = msg["drivers"];
        // const isPreliminary = msg?.db_schedule?.preliminary_ready !== true;

        for (const { driver_days, ...msgDriver } of msgDrivers) {
          if (Object.hasOwn(draft.drivers, msgDriver.id)) {
            const days = draft.drivers[msgDriver.id].driver_days;

            for (const msgDay of driver_days) {
              if (Object.hasOwn(days, msgDay.day_in_month)) {
                days[msgDay.day_in_month] = {
                  ...days[msgDay.day_in_month],
                  ...msgDay,
                };
              } else {
                days[msgDay.day_in_month] = msgDay;
              }
            }

            draft.drivers[msgDriver.id] = {
              ...draft.drivers[msgDriver.id],
              ...msgDriver,
              driver_days: days,
            };
          } else {
            const days = Object.fromEntries(
              driver_days.map((msgDay) => [msgDay.day_in_month, msgDay])
            );
            draft.drivers[msgDriver.id] = {
              ...msgDriver,
              driver_days: days,
            };
          }
        }
        console.timeEnd("Drivers update");
        //processedFields += 1;
      }

      if (msg["drivers_to_remove"] && Array.isArray(msg["drivers_to_remove"])) {
        const driverIds: readonly string[] = msg["drivers_to_remove"];
        for (const driverId of driverIds) {
          delete draft.drivers[driverId];
        }
      }

      //console.log(`Processed ${processedFields} from update:`, msg);
    });

    console.log("Updated schedule:", newSchedule);
    return newSchedule;
  }
}
