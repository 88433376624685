import { Theme } from "@mui/material";
import { Moment } from "moment";
import { FixedSizeList as List } from "react-window";
import {
  DriverSummary,
  TableCellRef,
  TableColumnRef,
  TableRowRef,
} from "./types";

export type VListData = {
  cols: TableColumnRef[];
  rows: TableRowRef[];
  today: Moment;
  selectedDate: Moment | null;
  headerColumnWidth: number;
  firstHeaderColumnWidth: number;
  driverSummary: Record<string, DriverSummary>;
  isHoliday: (day: string | moment.Moment) => boolean | undefined;
  theme: Theme;
  onContextMenu?: (
    mouseX: number,
    mouseY: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number,
    changeDepotOnly: boolean
  ) => void;
  onSelectionStart?: (
    mouseX: number,
    mouseY: number,
    mouseButton: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number
  ) => void;
  onSelectionDrag?: (mouse: {
    x: number;
    y: number;
    initialX: number;
    initialY: number;
  }) => void;
  onSelectionEnd?: (mouseX: number, mouseY: number) => void;
  onSelectState: (stateId: number) => void;
  setSelection: (selection: {
    row: number;
    startCell: number;
    endCell: number;
    initialMouseCoordinates: {
      x: number;
      y: number;
    };
  }) => void;
};

export const VirtualizedList = List<VListData>;
