import {
  CancelOutlined,
  CheckCircle,
  PrintOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Portal,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import Grid from "@mui/system/Unstable_Grid/Grid";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getSettingsByGarage } from "../../common/useGarageSettings";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import {
  useFakeScheduleActionMutation,
  useGetDriversListQuery,
  useGetFakeScheduleQuery,
} from "../../data/api/hooks";
import { FakeSchedule as FakeScheduleType } from "../../data/api/types";
import { Driver } from "../../data/api/types/driver";
import { RootState } from "../../data/store";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { MonthPicker } from "../../filters/MonthPicker";
import { TransportSelect } from "../../filters/TransportSelect";
import { useSubmenu } from "../../hooks/useSubmenu";
import { usePrintFakeSchedule } from "../../printing/usePrintFakeSchedule";

function Error() {
  return <Alert severity="error">Грешка при зареждане на данните!</Alert>;
}

function Loading() {
  return (
    <Backdrop
      open
      sx={{
        position: "absolute",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

const border = "1px solid rgba(175, 175, 175, 1)";
const darkBorder = "1px solid rgba(80, 80, 80, 1)";

export function getDayContent(
  driver: FakeScheduleType["data"]["schedule"][0],
  dayIndex: number,
  printFakeLeaves: boolean,
  printFakeSickLeaves: boolean,
  printFakeWork: boolean
) {
  const day = driver.days[dayIndex];
  const nw_day = driver.nw_days?.[dayIndex];

  if (day === "") {
    // Неназначен
    return "×";
  } else if (day[0]?.toLocaleLowerCase() === "п") {
    // Почивка
    return "П";
  } else if (printFakeLeaves && nw_day?.toLowerCase() === "о") {
    return "О";
  } else if (printFakeSickLeaves && nw_day?.toLowerCase() === "б") {
    return "Б";
  } else if (printFakeWork) {
    return "Р";
  } else {
    return " ";
  }
}

function Data({
  headers,
  schedule,
  drivers,
  printFakeLeaves,
  printFakeSickLeaves,
  printFakeWork,
  sx,
}: {
  headers: readonly { content: string; isHoliday: boolean }[];
  drivers: Record<number, Driver>;
  schedule: FakeScheduleType;
  printFakeLeaves: boolean;
  printFakeSickLeaves: boolean;
  printFakeWork: boolean;
  sx?: SxProps<Theme>;
}) {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  return (
    <TableContainer component={Box} sx={{ ...sx, height: "100%" }}>
      <Table size="small" stickyHeader id="fake-schedule-table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderTop: border,
                borderLeft: border,
                borderRight: border,
                borderBottom: darkBorder,
              }}
            >
              №
            </TableCell>
            <TableCell
              sx={{
                borderTop: border,
                borderRight: darkBorder,
                borderBottom: darkBorder,
              }}
            >
              Водач
            </TableCell>
            {headers.map((h, hIdx) => (
              <TableCell
                key={hIdx}
                data-is-holiday={h?.isHoliday ? "1" : "0"}
                sx={{
                  py: 0.5,
                  px: 0.5,
                  textAlign: "center",
                  width: "1px",
                  borderTop: border,
                  borderRight: hIdx >= headers.length - 1 ? darkBorder : border,
                  borderBottom: darkBorder,
                  backgroundColor: h?.isHoliday ? orange[200] : undefined,
                }}
              >
                {h.content}
              </TableCell>
            ))}
            {selectedTransport === "A" && (
              <TableCell
                sx={{
                  py: 0.5,
                  px: 0.5,
                  textAlign: "center",
                  width: "1px",
                  borderTop: border,
                  borderRight: border,
                  borderBottom: darkBorder,
                }}
              >
                Кола
              </TableCell>
            )}
            <TableCell
              sx={{
                py: 0.5,
                px: 0.5,
                textAlign: "center",
                width: "1px",
                borderTop: border,
                borderRight: border,
                borderBottom: darkBorder,
              }}
            >
              Поч.
            </TableCell>
            {selectedTransport !== "A" && (
              <TableCell
                sx={{
                  py: 0.5,
                  px: 0.5,
                  textAlign: "center",
                  width: "1px",
                  borderTop: border,
                  borderRight: border,
                  borderBottom: darkBorder,
                }}
              >
                Подпис
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {schedule.data.schedule.map((driver) =>
            Object.hasOwn(drivers, driver.sl_number) ? (
              <TableRow key={driver.sl_number}>
                <TableCell
                  sx={{
                    py: 0.5,
                    px: 1.5,
                    textAlign: "center",
                    width: "1px",
                    borderLeft: border,
                    borderRight: border,
                    borderBottom: border,
                  }}
                >
                  {driver.sl_number}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: darkBorder,
                    borderBottom: border,
                    minWidth: "175px",
                    maxWidth: "175px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={`${driver.sl_number}: ${
                    drivers[driver.sl_number]?.name || ""
                  }`}
                >
                  {drivers[driver.sl_number]?.name || ""}
                </TableCell>
                {driver.days.map((_day, dayIdx) => (
                  <TableCell
                    key={dayIdx}
                    sx={{
                      py: 0.5,
                      px: 1.5,
                      textAlign: "center",
                      width: "22px",
                      borderRight:
                        dayIdx >= driver.days.length - 1 ? darkBorder : border,
                      borderBottom: border,
                      backgroundColor: headers[dayIdx]?.isHoliday
                        ? orange[200]
                        : undefined,
                    }}
                  >
                    {getDayContent(
                      driver,
                      dayIdx,
                      printFakeLeaves,
                      printFakeSickLeaves,
                      printFakeWork
                    )}
                  </TableCell>
                ))}
                {selectedTransport === "A" && (
                  <TableCell
                    sx={{
                      py: 0.5,
                      px: 1,
                      textAlign: "center",
                      width: "1px",
                      borderRight: border,
                      borderBottom: border,
                    }}
                  >
                    {drivers[driver.sl_number]?.attributes?.preferred_vehicle ||
                      ""}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    py: 0.5,
                    px: 1,
                    textAlign: "center",
                    width: "1px",
                    borderRight: border,
                    borderBottom: border,
                  }}
                >
                  {
                    driver.days.filter((d) => d[0]?.toLocaleLowerCase() === "п")
                      .length
                  }
                </TableCell>
                {selectedTransport !== "A" && (
                  <TableCell
                    sx={{
                      py: 0.5,
                      px: 1,
                      textAlign: "center",
                      width: "1px",
                      borderRight: border,
                      borderBottom: border,
                    }}
                  >
                    &nbsp;
                  </TableCell>
                )}
              </TableRow>
            ) : undefined
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function FakeSchedule() {
  const {
    scheduleParameters,
    needsTransportSelect,
    needsDepotSelect,
    needsAutocolumnSelect,
  } = useScheduleParameters();
  const selectedMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );
  const [startDate, endDate] = useMemo(() => {
    const startDate = moment(`${selectedMonth}-01`, "YYYY-MM-DD").startOf(
      "day"
    );
    const endDate = startDate.clone().endOf("month").startOf("day");
    return [startDate, endDate];
  }, [selectedMonth]);

  const schedule = useGetFakeScheduleQuery(scheduleParameters, {
    skip: !scheduleParameters,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  useEffect(() => {
    if (
      schedule.isSuccess &&
      schedule.data.data.schedule_status !== "idle" &&
      schedule.data.data.schedule_status !== "not_created"
    ) {
      const id = setTimeout(() => {
        schedule.refetch();
      }, 5000);

      return () => clearTimeout(id);
    }
  }, [schedule]);

  const headers = useMemo(() => {
    const headers: { content: string; isHoliday: boolean }[] = [];
    const currentDate = startDate.clone();
    let currentIdx = 0;
    while (currentDate.isSameOrBefore(endDate)) {
      headers.push({
        content: currentDate.format("DD"),
        isHoliday: !!schedule.data?.data?.holidays?.[currentIdx],
      });
      currentDate.add(1, "day");
      currentIdx += 1;
    }
    return headers;
  }, [endDate, schedule.data?.data?.holidays, startDate]);

  const apiDrivers = useGetDriversListQuery(
    {
      transport: scheduleParameters?.[0] || "",
      garage: scheduleParameters?.[1] || 0,
    },
    { skip: !scheduleParameters }
  );

  const drivers = useMemo<Record<number, Driver>>(
    () =>
      apiDrivers.isSuccess
        ? Object.fromEntries(
            apiDrivers.data.drivers.map((d) => [d.sl_number, d])
          )
        : {},
    [apiDrivers.isSuccess, apiDrivers.data]
  );
  // console.log(drivers);

  const isLoading =
    schedule.isLoading ||
    //schedule.isFetching ||
    apiDrivers.isLoading ||
    apiDrivers.isFetching;
  const isError = schedule.isError || apiDrivers.isError;
  const isSuccess = schedule.isSuccess || apiDrivers.isSuccess;

  const {
    plannerSettings,
    printFakeLeaves,
    printFakeSickLeaves,
    printFakeWork,
  } = getSettingsByGarage(
    scheduleParameters?.[0] || "",
    scheduleParameters?.[1] || 0
  );
  const [scheduleAction, scheduleActionResult] =
    useFakeScheduleActionMutation();

  const printer = usePrintFakeSchedule(schedule.data?.data?.schedule || []);

  const { rightToolbarMenu } = useSubmenu();
  const profile = useProfile();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Portal container={rightToolbarMenu}>
        {/*<WithAnyRole roles={["nariad_admin"]}>*/}
        {(schedule.isSuccess &&
          schedule.data.data.schedule_status === "idle" &&
          schedule.data.data.schedule.length === 0 &&
          scheduleParameters &&
          scheduleParameters[0] !== "TM" &&
          scheduleParameters[0] !== "TB") ||
        profile?.roles.includes("nariad_admin") ? (
          <IconButton
            color="inherit"
            size="large"
            aria-label={
              schedule.data &&
              schedule.data.data.schedule_status === "not_created"
                ? "Създай предварителен график"
                : "Преизчисли предварителен график"
            }
            title={
              schedule.data &&
              schedule.data.data.schedule_status === "not_created"
                ? "Създай предварителен график"
                : "Преизчисли предварителен график"
            }
            disabled={
              !scheduleParameters ||
              scheduleActionResult.isLoading ||
              !schedule.isSuccess ||
              schedule.data.status !== "ok" ||
              (schedule.data.data.schedule_status !== "idle" &&
                schedule.data.data.schedule_status !== "not_created") ||
              scheduleParameters[0] === "TM" ||
              scheduleParameters[0] === "TB"
            }
            onClick={() =>
              scheduleParameters &&
              scheduleAction({
                scheduleParameters,
                action: "start_calc",
                params: plannerSettings,
              })
            }
          >
            {scheduleActionResult.isLoading ? (
              <CircularProgress
                size="0.75em"
                sx={{ color: (theme) => theme.palette.grey[100] }}
              />
            ) : (
              <CheckCircle />
            )}
          </IconButton>
        ) : (
          <></>
        )}
        {/*</WithAnyRole>*/}
      </Portal>

      <Container sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {needsTransportSelect ? (
            <Grid xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsAutocolumnSelect ? (
            <Grid xs={12} sm={1}>
              <AutoColumnSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid xs={12} sm={2}>
            <MonthPicker />
          </Grid>

          <Grid
            //xs={12}
            //sm={2}
            sx={{
              alignSelf: "end",
              paddingLeft: 6,
              paddingBottom: 2,
            }}
          >
            <Button
              variant="contained"
              startIcon={<PrintOutlined />}
              disabled={
                isLoading ||
                isError ||
                !isSuccess ||
                !schedule.data ||
                schedule.data.status !== "ok" ||
                schedule.data.data.schedule_status !== "idle" ||
                printer.isLoading ||
                !scheduleParameters
              }
              //sx={{ display: "inline-block" }}
              onClick={() => {
                if (schedule.data) {
                  printer.exportPdf(
                    headers,
                    drivers,
                    schedule.data.data,
                    scheduleParameters?.[2] || 0,
                    scheduleParameters?.[3] || 0,
                    false
                  );
                }
              }}
            >
              Печат
            </Button>

            {scheduleParameters && scheduleParameters[0] !== "A" && (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                startIcon={<PrintOutlined />}
                disabled={
                  isLoading ||
                  isError ||
                  !isSuccess ||
                  !schedule.data ||
                  schedule.data.status !== "ok" ||
                  schedule.data.data.schedule_status !== "idle" ||
                  printer.isLoading ||
                  !scheduleParameters
                }
                //sx={{ display: "inline-block" }}
                onClick={() => {
                  if (schedule.data) {
                    printer.exportPdf(
                      headers,
                      drivers,
                      schedule.data.data,
                      scheduleParameters?.[2] || 0,
                      scheduleParameters?.[3] || 0,
                      true
                    );
                  }
                }}
              >
                Печат (разширен)
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>

      <Box style={{ flexGrow: 1, position: "relative", overflow: "hidden" }}>
        {isLoading ? (
          <Loading />
        ) : !isError && isSuccess && schedule.data ? (
          schedule.data.data.schedule_status === "idle" ? (
            <Data
              headers={headers}
              drivers={drivers}
              schedule={schedule.data}
              printFakeLeaves={printFakeLeaves}
              printFakeSickLeaves={printFakeSickLeaves}
              printFakeWork={printFakeWork}
            />
          ) : schedule.data.data.schedule_status === "not_created" ? (
            <Alert severity="warning" sx={{ mb: 4 }}>
              <AlertTitle>Информация</AlertTitle>
              <Typography>Няма създаден график.</Typography>
              <Button
                startIcon={<CheckCircle />}
                variant="outlined"
                color="info"
                disabled={
                  !scheduleParameters ||
                  scheduleActionResult.isLoading ||
                  !schedule.isSuccess ||
                  schedule.data.status !== "ok" ||
                  scheduleParameters[0] === "TM" ||
                  scheduleParameters[0] === "TB"
                }
                sx={{ mt: 4 }}
                onClick={() =>
                  scheduleParameters &&
                  scheduleAction({
                    scheduleParameters,
                    action: "start_calc",
                    params: plannerSettings,
                  })
                }
              >
                Създай
              </Button>
            </Alert>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Alert severity="info" sx={{ mb: 4 }}>
                <AlertTitle>Информация</AlertTitle>
                <Typography>Графикът се изчислява. Моля изчакайте.</Typography>
                <Button
                  startIcon={<CancelOutlined />}
                  variant="outlined"
                  color="warning"
                  disabled={
                    !scheduleParameters ||
                    scheduleParameters[0] === "TM" ||
                    scheduleParameters[0] === "TB"
                  }
                  sx={{ mt: 4 }}
                  onClick={() =>
                    scheduleParameters &&
                    scheduleAction({ scheduleParameters, action: "stop_calc" })
                  }
                >
                  Спри
                </Button>
              </Alert>
              <Data
                sx={{ flexGrow: 1 }}
                headers={headers}
                drivers={drivers}
                schedule={schedule.data}
                printFakeLeaves={printFakeLeaves}
                printFakeSickLeaves={printFakeSickLeaves}
                printFakeWork={printFakeWork}
              />
            </Box>
          )
        ) : (
          <Error />
        )}
      </Box>
    </Box>
  );
}
