import { BaseQueryApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { SerializableUser } from "../authSlice";
import { RootState } from "../store";

function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}

function extractToken(user?: SerializableUser | null) {
  if (user && user.access_token) {
    return user.access_token;
  } else {
    return undefined;
  }
}

function createResponse<T>(body: T): Response {
  return new Response(JSON.stringify(body));
}

async function prepareHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): Promise<Headers> {
  let token = extractToken((api.getState() as RootState).auth.user);
  let tries = 0;
  while (!token && tries <= 20) {
    await sleep(50);
    //console.log("Retry token");
    token = extractToken((api.getState() as RootState).auth.user);
    tries += 1;
  }
  headers.set("Authorization", `Bearer ${token}`);
  return headers;
}

export function createBaseQuery(baseUrl: string) {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: prepareHeaders,
    fetchFn: (input, init) => {
      const inputObject: { url?: string } = input as any;
      if (
        inputObject &&
        typeof inputObject.url === "string" &&
        inputObject.url.endsWith(import.meta.env.VITE_EMPTY_API_URI)
      ) {
        console.log("Has URL:", inputObject.url);
        return Promise.resolve(createResponse(null));
      } else {
        return fetch(input, init);
      }
    },
  });
}

export const commonApiParameters = {
  refetchOnMountOrArgChange: 30,
  refetchOnReconnect: true,
  refetchOnFocus: false,
};
