import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box, Container, IconButton, Portal, styled } from "@mui/material";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow as TableRowMUI,
  TextField,
} from "@mui/material";

import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { QueryError } from "../QueryError";
import {
  useGetVehiclesQuery,
  useGetVehiclesRosterQuery,
} from "../../data/api/hooks";
import { MonthPicker } from "../../filters/MonthPicker";
import { useRVehiclePersonalAcountPdfExporter } from "../../printing/useVehiclePersonalAccountPrint";
import { useSubmenu } from "../../hooks/useSubmenu";
import { Print } from "@mui/icons-material";

const TableRow = styled(TableRowMUI)(() => ({
  height: 23,
}));

const TableCell = styled(TableCellMUI)(() => ({
  padding: "0px 16px",
}));

interface VehiclePersonalAccountColumn {
  id:
    | "daytask_date"
    | "start_shift"
    | "end_shift"
    | "state"
    | "task_line"
    | "task_car"
    | "task_shift"
    | "working_hours"
    | "task_veh1_id"
    | "state"
    | "task_driver_id";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  width: number;
}

const VehiclePersonalAccountColumns: readonly VehiclePersonalAccountColumn[] = [
  { id: "daytask_date", label: "Ден", minWidth: 80, width: 60, align: "right" },
  { id: "state", label: "Състояние", minWidth: 80, width: 80 },

  {
    id: "task_line",
    label: "Линия",
    minWidth: 50,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "task_car",
    label: "Кола",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "task_shift",
    label: "Смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "task_driver_id",
    label: "Водач",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "start_shift",
    label: "Начало",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "end_shift",
    label: "Край",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
];

function StickyHeadTable({
  rows,
}: {
  rows: {
    day: string;
    state: string | null;
    linename: string;
    car_no: number | null;
    shift: string | undefined;
    start: string;
    end: string;
    driver_id: number;
  }[];
}) {
  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table
          stickyHeader
          aria-label="stickyHeader"
          id="vehicle_personal_account"
        >
          <TableHead>
            <TableRowMUI>
              {VehiclePersonalAccountColumns.map((column) => (
                <TableCellMUI
                  key={column.id}
                  align={column.align}
                  //style={{ width: column.width }}
                  sx={{ width: column.width }}
                >
                  {column.label}
                </TableCellMUI>
              ))}
            </TableRowMUI>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover tabIndex={-1} key={i}>
                  <TableCell align="right">
                    {moment(row.day).format("L")}
                  </TableCell>
                  {row.linename ? (
                    <React.Fragment>
                      <TableCell align="left">
                        {row.state && row.state !== "Наличен" ? row.state : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.linename !== "" ? row.linename : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.car_no && row.car_no > 0 ? row.car_no : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.shift ? row.shift : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.driver_id && row.driver_id > 0
                          ? row.driver_id
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.start).format("HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.end).format("HH:mm")}
                      </TableCell>
                      {/* <TableCell align="center">
                        {moment
                          .utc(
                            moment
                              .duration(row.working_hours, "seconds")
                              .as("milliseconds")
                          )
                          .format("HH:mm")}
                      </TableCell> */}
                    </React.Fragment>
                  ) : (
                    <TableCell colSpan={7}>
                      {row.state && row.state !== "Наличен"
                        ? row.state
                        : "Няма задача"}
                    </TableCell>
                  )}
                  {/* {PersonalAccountColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })} */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function VehiclePersonalAccountReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const { rightToolbarMenu } = useSubmenu();

  const [selectedVehicle, setSelectedVehicle] = useState<{
    id: number;
    label: string;
  } | null>(null);

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const { exportPdf, isLoading: exportPdfLoading } =
    useRVehiclePersonalAcountPdfExporter();

  const {
    data: vehicleData,
    isLoading: isVehiclesLoading,
    isError: isVehiclesError,
  } = useGetVehiclesQuery(
    { transport: selectedTransport, garage: selectedDepot },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const selMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );

  const selectedMonth = useMemo(
    () => (selMonth && selMonth !== "" ? moment(selMonth) : null),
    [selMonth]
  );

  const { data: vehiclesRoster, isError } = useGetVehiclesRosterQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      year: selectedMonth ? selectedMonth.year() : 0,
      month: selectedMonth ? selectedMonth.month() + 1 : 0,
      avtocolona: selectedAvtoColumn,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(selectedDepot !== -1 && selectedTransport !== ""),
    }
  );

  const rows = useMemo(() => {
    const veh_days = vehiclesRoster?.data.find((v) =>
      selectedVehicle ? v.vehicle_id === selectedVehicle.id : false
    )?.days;
    if (veh_days) {
      console.log("VehicleDays:", veh_days);
      const row = Object.values(veh_days)
        .filter(
          (vd) => moment(vd.date).month() === moment(selectedMonth).month()
        )
        .flatMap((d) => {
          const element =
            d.daytasks && d.daytasks.length > 0
              ? d.daytasks
                  .slice()
                  .sort((a, b) =>
                    moment(a.start_time) > moment(b.start_time) ? 1 : -1
                  )
                  .map((nar_task) => ({
                    day: d.date,
                    state: d.state
                      ? d.state.description.concat(
                          d.state.comment ? ": " + d.state.comment : ""
                        )
                      : "",
                    linename: nar_task.linename,
                    car_no: nar_task.car_no,
                    shift: nar_task.shift_type?.toString(),
                    start: nar_task.start_time,
                    end: nar_task.end_time,
                    driver_id: nar_task.sl_number,
                  }))
              : d.workshifts && d.workshifts.length > 0
              ? d.workshifts
                  .slice()
                  .sort((a, b) =>
                    moment(a.start_time) > moment(b.start_time) ? 1 : -1
                  )
                  .map((work_shift) => ({
                    day: d.date,
                    state: d.state
                      ? d.state.description.concat(
                          d.state.comment ? ": " + d.state.comment : ""
                        )
                      : "",
                    linename: work_shift.linename,
                    car_no: work_shift.car_no,
                    shift: work_shift.shift_type
                      ? work_shift.shift_type.toString()
                      : "",
                    start: work_shift.start_time,
                    end: work_shift.end_time,
                    driver_id: work_shift.sl_number,
                  }))
              : {
                  day: d.date,
                  state: d.state
                    ? d.state.description.concat(
                        d.state.comment ? ": " + d.state.comment : ""
                      )
                    : "",
                  linename: "",
                  car_no: -1,
                  shift: "",
                  start: "",
                  end: "",
                  driver_id: -1,
                };
          return element;
        });
      return row;
    }
  }, [selectedMonth, selectedVehicle, vehiclesRoster?.data]);

  console.log("Rows", rows);

  useEffect(() => {
    setSelectedVehicle(null);
  }, [selectedAvtoColumn]);

  console.log("vehiclesRoster", vehiclesRoster);

  const vehiclesList = useMemo(() => {
    return vehicleData?.data
      .filter((v) => v.autocolumn === selectedAvtoColumn)
      .sort((a, b) => a.vehicle_id - b.vehicle_id)
      .map((v) => ({ label: v.vehicle_id.toString(), id: v.vehicle_id }));
  }, [selectedAvtoColumn, vehicleData?.data]);

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() =>
            exportPdf(
              rows,
              selectedMonth
                ? selectedMonth.format("MMMM YYYY").toUpperCase()
                : "",
              selectedVehicle ? selectedVehicle.label : ""
            )
          }
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={2}>
              <Autocomplete
                sx={{ m: 1 }}
                key={selectedVehicle?.id}
                value={selectedVehicle ? selectedVehicle : undefined}
                options={vehiclesList ? vehiclesList : []}
                renderInput={(params) => (
                  <TextField {...params} label="Кола" variant="standard" />
                )}
                onChange={(_, data) => {
                  if (data) setSelectedVehicle(data);
                }}
                noOptionsText={""}
                // getOptionLabel={(option) => (option ? option.label : "")}
                //isOptionEqualToValue={(option, value) => option === value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                autoSelect
                disabled={isVehiclesLoading || isVehiclesError}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MonthPicker />
            </Grid>
          </Grid>
          {/* {vehicleData?.data && !isError && !isLoading && selectedVehicle && (
          <Grid container mt={1} mb={1} spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
            >
              Обща заработка:{" "}
              {totalWorkHours ? (totalWorkHours / 60 / 60).toFixed(2) : "0"}{" "}
              часа
            </Grid>
          </Grid>
        )} */}

          {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            //open={isLoading}
            open={false}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ py: 4, height: "100%" }}>
            {!isError && vehicleData?.data && selectedVehicle && rows && (
              <StickyHeadTable
                rows={rows.sort((s1, s2) =>
                  moment(s1.day) > moment(s2.day) ? 1 : -1
                )}
              />
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
}
