import { styled } from "@mui/material";
import { orange } from "@mui/material/colors";
import color from "color";
import { PropsWithChildren } from "react";

export const CELL_WIDTH = 35;
export const CELL_HEIGHT = 35;

//export const SchedulesTableContainer = styled("div")(() => ({
//  width: "100%",
//  height: "100%",
//  display: "flex",
//  flexDirection: "column",
//}));

// export const SchedulesTableTitle = styled("div")(() => ({
//   flexGrow: 0,
//   display: "flex",
//   flexDirection: "column",
//   overflowX: "hidden",
// }));

// export const SchedulesTableContent = styled("div")(() => ({
//   flexGrow: 1,
// }));

//export const SchedulesTitleRow = styled("div")(() => ({
//  flexGrow: 0,
//  display: "flex",
//  flexDirection: "row",
//  flexWrap: "nowrap",
//}));

export const DataRowBox = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  boxSizing: "border-box",

  "&:hover > div": {
    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
    borderBottom: "1px solid rgb(33, 33, 33)",
  },
}));

// export function DataCell({
//   children,
//   width,
//   height,
//   isHoliday,
//   background,
//   foreground,
// }: PropsWithChildren<{
//   width?: number;
//   height?: number;
//   isHoliday?: boolean;
//   background?: string;
//   foreground?: string;
// }>) {
//   return isHoliday ? (
//     <div
//       style={{
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: background ? background : "#f5f5f5",
//         color: foreground,
//         borderBottom: "1px solid rgba(224, 224, 224, 1)",
//         borderRight: "1px solid rgba(224, 224, 224, 1)",
//         cursor: "pointer",
//         userSelect: "none",
//         width: width ? width : CELL_WIDTH,
//         minWidth: width ? width : CELL_WIDTH,
//         height: height ? height : CELL_HEIGHT,
//         //"--hatch-bg": "rgba(255, 255, 255, 0)",
//         //"--hatch-fg":
//         //  background && color(background).isDark() ? orange[800] : orange[400],
//
//         backgroundImage:
//           "linear-gradient(135deg, var(--hatch-bg) 33.333333%, var(--hatch-fg) 33.333333%, var(--hatch-fg) 50%, var(--hatch-bg) 50%, var(--hatch-bg) 83.333333%, var(--hatch-fg) 83.333333%, var(--hatch-fg) 100%)",
//         backgroundSize: "6.25px 6.25px",
//         textShadow: color(foreground).isLight()
//           ? "rgba(0, 0, 0, 1) 0px 0px 2px"
//           : "rgba(255, 255, 255, 1) 0px 0px 2px",
//         fontWeight: "bold",
//       }}
//     >
//       {children}
//     </div>
//   ) : (
//     <div
//       style={{
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: background ? background : "#f5f5f5",
//         color: foreground,
//         borderBottom: "1px solid rgba(224, 224, 224, 1)",
//         borderRight: "1px solid rgba(224, 224, 224, 1)",
//         cursor: "pointer",
//         userSelect: "none",
//         width: width ? width : CELL_WIDTH,
//         minWidth: width ? width : CELL_WIDTH,
//         height: height ? height : CELL_HEIGHT,
//       }}
//     >
//       {children}
//     </div>
//   );
// }

export const DataCell = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "width" &&
    prop !== "height" &&
    prop !== "isHoliday" &&
    prop !== "background" &&
    prop !== "foreground",
})<{
  width?: number;
  height?: number;
  isHoliday?: boolean;
  background?: string;
  foreground?: string;
}>(({ width, height, isHoliday, background, foreground }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: background ? background : "#f5f5f5",
  color: foreground,
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  cursor: "pointer",
  userSelect: "none",
  width: width ? width : CELL_WIDTH,
  minWidth: width ? width : CELL_WIDTH,
  height: height ? height : CELL_HEIGHT,

  "&": isHoliday
    ? {
        "--hatch-bg": "rgba(255, 255, 255, 0)",
        //"--hatch-fg": "rgba(211, 47, 47, 0.5)",
        //"--hatch-fg": orange[300],
        "--hatch-fg":
          background && color(background).isDark() ? orange[800] : orange[400],
        //"--hatch-fg": blue[100],

        //backgroundImage:
        //  "linear-gradient(135deg, var(--hatch-bg) 40%, var(--hatch-fg) 40%, var(--hatch-fg) 50%, var(--hatch-bg) 50%, var(--hatch-bg) 90%, var(--hatch-fg) 90%, var(--hatch-fg) 100%)",
        //backgroundSize: "5px 5px",
        backgroundImage:
          "linear-gradient(135deg, var(--hatch-bg) 33.333333%, var(--hatch-fg) 33.333333%, var(--hatch-fg) 50%, var(--hatch-bg) 50%, var(--hatch-bg) 83.333333%, var(--hatch-fg) 83.333333%, var(--hatch-fg) 100%)",
        backgroundSize: "6.25px 6.25px",
        textShadow: color(foreground).isLight()
          ? "rgba(0, 0, 0, 1) 0px 0px 2px"
          : "rgba(255, 255, 255, 1) 0px 0px 2px",
        fontWeight: "bold",
      }
    : undefined,
}));

export const DataHeaderCell = styled(DataCell, {
  shouldForwardProp: (prop) =>
    prop !== "fixLeftIdx" && prop !== "firstColumnWidth",
})<{
  firstColumnWidth?: number;
  fixLeftIdx?: number;
}>(({ fixLeftIdx, width, firstColumnWidth }) => ({
  position: fixLeftIdx !== undefined ? "sticky" : undefined,
  left:
    fixLeftIdx !== undefined
      ? fixLeftIdx * (width ? width : CELL_WIDTH) +
        ((firstColumnWidth ? firstColumnWidth : width ? width : CELL_WIDTH) -
          (width ? width : CELL_WIDTH))
      : undefined,
  zIndex: fixLeftIdx !== undefined ? 10 : undefined,
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  fontWeight: "bold",
  backgroundColor: "white",
  display: "inline-flex",
  flexDirection: "column",
}));

export const TitleDataCell = styled(DataHeaderCell)(() => ({
  backgroundColor: "#f5f5f5",
  fontWeight: "normal",
}));

export const TitleHeaderCell = styled(TitleDataCell)(() => ({
  backgroundColor: "white",
  fontWeight: "bold",
}));
