import { useCallback, useMemo, useState } from "react";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";
import { useDriverItemTitle } from "./useDriverItemTitle";

export function useDriverItemState(
  driver: RosterDriver | ElectroRosterDriver,
  _assigned: boolean,
  isReadOnly: boolean,
  isFirstOfMonth: boolean
) {
  const driverComment = useMemo(() => {
    const comments: string[] = [];
    for (const dt of driver.daytasks) {
      if (dt.comment && dt.comment.trim() !== "") {
        comments.push(dt.comment);
      }
    }
    if (comments.length > 0) {
      return comments.join("\n");
    } else {
      return null;
    }
  }, [driver]);

  const driverTitle = useDriverItemTitle(driver, driverComment, isFirstOfMonth);

  const [contextMenu, setContextMenu] = useState<{
    left: number;
    top: number;
  } | null>(null);

  const onContextMenu = useCallback(
    (e: React.MouseEvent) => {
      if (!isReadOnly) {
        e.preventDefault();
        setContextMenu(
          contextMenu === null
            ? { left: e.clientX + 2, top: e.clientY - 6 }
            : null
        );
      }
    },
    [contextMenu, isReadOnly]
  );

  return {
    driverComment,
    driverTitle,
    contextMenu,
    setContextMenu,
    onContextMenu,
  };
}
