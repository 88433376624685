import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useScheduleParameters } from "../../../components/schedule/useScheduleParameters";
import {
  useGetRosterForDriversCompactQuery,
  useGetTimetablesByDateRosterQuery,
} from "../../../data/api/hooks";
import { RootState } from "../../../data/store";
import { useScheduleRecapitulation } from "../schedule_recapitulation/useScheduleRecapitulation";
import { useVehicleRecapitulationReport } from "../vehicles_recapitulation/useVehicleRecapitulationReport";
import { ScheduleDay } from "../../../data/api/types/schedule";

export function useRosterData(selectedDate: string | null) {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const getRosterForDriversQueryData = useMemo(
    () => ({
      transport: selectedTransport,
      garage: selectedDepot,
      date: selectedDate ? selectedDate : moment().format("YYYY-MM-DD"),
      avtocolona: selectedAvtoColumn,
    }),
    [selectedAvtoColumn, selectedDate, selectedDepot, selectedTransport]
  );

  const {
    data: roster,
    isError: isRosterError,
    isLoading: isRosterLoading,
    isFetching: isRosterFetching,
  } = useGetRosterForDriversCompactQuery(getRosterForDriversQueryData);

  // console.log("RosterForDrives:", roster);

  return {
    roster,
    isRosterError,
    isRosterLoading,
    isRosterFetching,
  };
}

export function useRosterReportData(
  selectedDate: string,
  selectedAvtoColumn: number
) {
  const { scheduleParameters } = useScheduleParameters();

  const {
    data: explPlanData,
    isError: isExplPlanError,
    isLoading: isExplPlanLoading,
    isFetching: isExplPlanFetching,
  } = useGetTimetablesByDateRosterQuery(
    scheduleParameters
      ? {
          transport: scheduleParameters[0],
          garage: scheduleParameters[1],
          date: selectedDate,
          avtocolona: scheduleParameters[4],
        }
      : { transport: "", garage: 0, date: "1970-01-01", avtocolona: 1 },
    { skip: !scheduleParameters }
  );
  const { roster, isRosterError, isRosterLoading, isRosterFetching } =
    useRosterData(selectedDate);

  // console.log("roster", roster);
  // console.log("selectedAvtoColumn", selectedAvtoColumn);
  const day_roster = useMemo(() => {
    if (roster) {
      const arr: Record<
        number,
        { driver_id: number; scheduleDay: ScheduleDay }
      >[] = [];
      for (const driver of roster.data.drivers) {
        const driver_day = driver.driver_days.find(
          (dd) =>
            dd.day_in_month === selectedDate &&
            dd.autocolumn === selectedAvtoColumn
        );

        if (driver_day)
          arr.push({
            [driver.sl_number]: {
              driver_id: driver.sl_number,
              scheduleDay: driver_day,
            },
          });
      }
      return arr;
    }
  }, [roster, selectedAvtoColumn, selectedDate]);

  // console.log("day_roster", day_roster);

  const veh_with_roster_ids = useMemo(
    () =>
      day_roster
        ? day_roster?.flatMap((drv) =>
            Object.values(drv).flatMap((d) =>
              d.scheduleDay.daytasks.map((t) => (t.veh1_id ? t.veh1_id : -1))
            )
          )
        : [],
    [day_roster]
  );

  const day_workshift_ids = useMemo(
    () =>
      explPlanData?.expl_plan
        .flatMap((l) => l.cars.flatMap((c) => c.workshifts.map((w) => w.id)))
        .concat(explPlanData?.spec_shifts.map((s) => s.id)),
    [explPlanData?.expl_plan, explPlanData?.spec_shifts]
  );

  return {
    explPlanData,
    isExplPlanError,
    isExplPlanLoading,
    isExplPlanFetching,
    roster,
    isRosterError,
    isRosterLoading,
    isRosterFetching,
    day_roster,
    veh_with_roster_ids,
    day_workshift_ids,
  };
}

export function useReportData(selectedDate: string) {
  //const start_time = performance.now();
  //  const { scheduleParameters } = useScheduleParameters();

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  // const {
  //   data: explPlanData,
  //   isError: isExplPlanError,
  //   isLoading: isExplPlanLoading,
  //   isFetching: isExplPlanFetching,
  // } = useGetTimetablesByDateRosterQuery(
  //   scheduleParameters
  //     ? {
  //         transport: scheduleParameters[0],
  //         garage: scheduleParameters[1],
  //         date: selectedDate,
  //         avtocolona: scheduleParameters[4],
  //       }
  //     : { transport: "", garage: 0, date: "1970-01-01", avtocolona: 1 },
  //   { skip: !scheduleParameters }
  // );

  const {
    explPlanData,
    isExplPlanError,
    isExplPlanLoading,
    isExplPlanFetching,
    roster,
    isRosterError,
    isRosterLoading,
    isRosterFetching,
    day_roster,
    day_workshift_ids,
    veh_with_roster_ids,
  } = useRosterReportData(selectedDate, selectedAvtoColumn);

  // const { roster, isRosterError, isRosterLoading, isRosterFetching } =
  //   useRosterData(selectedDate);

  // const day_roster = useMemo(() => {
  //   if (roster) {
  //     const arr: Record<
  //       number,
  //       { driver_id: number; scheduleDay: ScheduleDay }
  //     >[] = [];
  //     for (const driver of roster.data.drivers) {
  //       const driver_day = driver.driver_days.find(
  //         (dd) => dd.day_in_month === selectedDate
  //       );

  //       if (driver_day)
  //         arr.push({
  //           [driver.sl_number]: {
  //             driver_id: driver.sl_number,
  //             scheduleDay: driver_day,
  //           },
  //         });
  //     }
  //     return arr;
  //   }
  // }, [roster, selectedDate]);

  const {
    data: recapData,
    isLoading: isRecapLoading,
    isError: isRecapError,
    rows: recapRows,
    totalDriverCount,
  } = useScheduleRecapitulation(
    selectedTransport,
    selectedDepot,
    selectedDate,
    selectedAvtoColumn,
    day_roster,
    day_workshift_ids
  );

  const {
    rows: VehicleRows,
    totalVehicleCount,
    states: VehicleStates,
  } = useVehicleRecapitulationReport(
    selectedTransport,
    selectedDepot,
    selectedDate,
    selectedAvtoColumn,
    veh_with_roster_ids
  );

  //const end_time = performance.now();

  // console.log("NarVehRows:", VehicleRows);
  // console.log(
  //   "Performance NarVehRows:",
  //   start_time,
  //   end_time,
  //   end_time - start_time
  // );
  //console.log("Errors:", roster?.data.drivers.map((d) => d.));

  return {
    explPlanData,
    roster,
    recapData,
    recapRows,
    totalDriverCount,
    isError: isExplPlanError || isRosterError || isRecapError,
    isLoading:
      isExplPlanLoading ||
      isRosterLoading ||
      isRecapLoading ||
      isExplPlanFetching ||
      isRosterFetching,
    VehicleRows,
    totalVehicleCount,
    VehicleStates,
    day_roster,
    day_workshift_ids,
  };
}
