import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { store } from "./data/store";
import { Provider } from "react-redux";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { NotFoundError } from "./common/NotFoundError";
import { theme } from "./theme";
import { AdminPage } from "./pages/admin/AdminPage";
import { NomenclaturePage } from "./pages/nomenclatures/NomenclaturesPage";
import { HomePage } from "./pages/home/HomePage";
import { CurrentUser } from "./pages/admin/CurrentUser";
import { NotFoundPage } from "./pages/NotFoundPage";
import { DriversList } from "./pages/admin/DriversList";
import { ExploatationPlan } from "./pages/nomenclatures/ExplotationPlan";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { updateUser } from "./data/authSlice";
import { DriverSchedule } from "./pages/schedule/DriverSchedule";
import { Index } from "./pages/home/Index";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import moment from "moment";
import "moment/dist/locale/bg";
import { SpecialShiftCalendar } from "./pages/nomenclatures/SpecialShiftCalendar";
import { SchedulePage } from "./pages/schedule/SchedulePage";
import { ReportsPage } from "./pages/reports/ReportsPage";
import { WorkShiftCountsReport } from "./pages/reports/WorkShiftCounts";
import { Timetables_ByDateReport } from "./pages/reports/Timetables_ByDate";
import { ScheduleRecapitulationReport } from "./pages/reports/schedule_recapitulation/Schedule_Recapitulation";
import { DriverPersonalAccountReport } from "./pages/reports/DriverPersonalAccount";
import { ScheduleHistoryReport } from "./pages/reports/schedule_history/Schedule_history";
import { RosterPage } from "./pages/roster/RosterPage";
import { DriverRoster } from "./pages/roster/DriverRoster";
import { VehiclesList } from "./pages/nomenclatures/VehiclesList";
import { RosterDayReport } from "./pages/reports/roster_report/RosterDayReport";
import { ExploatationPlanCalendar } from "./pages/nomenclatures/ExplotationPlanCalendar";
import { NariadSocket } from "./sockets/NariadSocket";
import { VehicleRecapitulationReport } from "./pages/reports/vehicles_recapitulation/Vehicle_recapitulation_report";
import { VehiclePersonalAccountReport } from "./pages/reports/VehiclePersonalAccount";
import { WithAnyRole } from "./common/WithRoles";
import { SeparatedCarsReport } from "./pages/reports/SeparatedCars";
import { RosterOutTimesReport } from "./pages/reports/RosterOuttimes";
import { FakeSchedule } from "./pages/reports/FakeSchedule";

moment.locale("bg");

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_AUTH_SERVER,
  client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
  client_secret: import.meta.env.VITE_AUTH_CLIENT_SECRET,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  scope: "openid profile email offline_access",
  automaticSilentRenew: true,
  onSigninCallback: (user: User | void): void => {
    //console.log("Sign in callback:", user, window.location);
    if (user) {
      const { ...serializableUser } = user;
      store.dispatch(updateUser(serializableUser));
      window.globals.nariadSocket.access_token = serializableUser.access_token;
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

window.globals = {
  nariadSocket: new NariadSocket(import.meta.env.VITE_WS_ADDRESS),
};
console.debug("Phoenix socket object:", window.globals.nariadSocket);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        children: [
          {
            index: true,
            element: <Index />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/schedule",
        element: (
          <WithAnyRole roles={["view_schedule"]} fallback={<NotFoundPage />}>
            <SchedulePage />
          </WithAnyRole>
        ),
        children: [
          {
            index: true,
            element: <DriverSchedule />,
          },
          {
            path: "special-shifts",
            element: <SpecialShiftCalendar />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/roster",
        element: (
          <WithAnyRole roles={["view_roster"]} fallback={<NotFoundPage />}>
            <RosterPage />
          </WithAnyRole>
        ),
        children: [
          {
            index: true,
            element: <DriverRoster />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/nomenclatures",
        element: (
          <WithAnyRole
            roles={[
              "view_roster",
              "view_schedule",
              "edit_tech_service",
              "edit_drivers_licenses",
            ]}
            fallback={<NotFoundPage />}
          >
            <NomenclaturePage />
          </WithAnyRole>
        ),
        children: [
          {
            path: "exploatation-plan",
            element: (
              <WithAnyRole
                roles={["view_schedule", "view_roster"]}
                fallback={<NotFoundPage />}
              >
                <ExploatationPlan selLine={null} mnth={null} />
              </WithAnyRole>
            ),
          },

          {
            path: "drivers",
            element: (
              <WithAnyRole
                roles={[
                  "view_schedule",
                  "view_roster",
                  "edit_drivers_licenses",
                ]}
                fallback={<NotFoundPage />}
              >
                <DriversList />
              </WithAnyRole>
            ),
          },
          {
            path: "vehicles",
            element: (
              <WithAnyRole
                roles={["view_schedule", "view_roster", "edit_tech_service"]}
                fallback={<NotFoundPage />}
              >
                <VehiclesList />,
              </WithAnyRole>
            ),
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/reports",
        element: (
          <WithAnyRole
            roles={["view_schedule", "view_roster"]}
            fallback={<NotFoundPage />}
          >
            <ReportsPage />
          </WithAnyRole>
        ),
        children: [
          {
            path: "fake-schedule",
            element: (
              <WithAnyRole
                roles={["nariad_admin", "view_fake_schedule"]}
                fallback={<NotFoundPage />}
              >
                {" "}
                <FakeSchedule />
              </WithAnyRole>
            ),
          },
          {
            path: "exploatation-plan",
            element: <ExploatationPlanCalendar />,
          },
          {
            path: "workshift-counts",
            element: <WorkShiftCountsReport />,
          },
          {
            path: "line-timetbale-shifts",
            element: <Timetables_ByDateReport />,
          },
          {
            path: "schedule-recapitulation-report",
            element: <ScheduleRecapitulationReport />,
          },
          {
            path: "vehicle-recapitulation-report",
            element: <VehicleRecapitulationReport />,
          },
          {
            path: "schedule-history-report",
            element: <ScheduleHistoryReport />,
          },
          {
            path: "roster-report",
            element: <RosterDayReport />,
          },
          {
            path: "separated-cars",
            element: <SeparatedCarsReport />,
          },
          {
            path: "roster-outtimes",
            element: <RosterOutTimesReport />,
          },
          {
            path: "driver-personal-account",
            element: <DriverPersonalAccountReport />,
          },
          {
            path: "vehicle-personal-account",
            element: <VehiclePersonalAccountReport />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/admin",
        element: (
          <WithAnyRole roles={["nariad_admin"]} fallback={<NotFoundPage />}>
            <AdminPage />
          </WithAnyRole>
        ),
        children: [
          {
            index: true,
            element: <CurrentUser />,
          },
          {
            path: "drivers",
            element: <DriversList />,
          },
          {
            path: "current-user",
            element: <CurrentUser />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/authentication/callback",
        element: <div>Logging in</div>,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">
          <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <RouterProvider router={router} />
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
