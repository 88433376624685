import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Month, ScheduleParameters } from "../../data/api/types/schedule";
import { RootState } from "../../data/store";

export function useScheduleParameters() {
  const selectedMonthStr = useSelector((state: RootState) =>
    state.filters.selectedMonth ? state.filters.selectedMonth : null
  );
  const selectedMonth = useMemo(
    () => (selectedMonthStr ? moment(selectedMonthStr) : null),
    [selectedMonthStr]
  );
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );
  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const scheduleParameters = useMemo<ScheduleParameters | null>(() => {
    if (selectedTransport !== "" && selectedDepot >= 0 && selectedMonth) {
      return [
        selectedTransport,
        selectedDepot,
        selectedMonth.year(),
        (selectedMonth.month() + 1) as Month,
        selectedAutoColumn,
        1,
      ];
    } else {
      return null;
    }
  }, [selectedDepot, selectedMonth, selectedTransport, selectedAutoColumn]);

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  return {
    scheduleParameters,
    needsTransportSelect,
    needsDepotSelect,
    needsAutocolumnSelect,
  };
}
