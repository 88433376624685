import {
  DeleteOutline,
  DoneOutline,
  ImportExportOutlined,
  PauseCircleOutline,
  PlayCircleOutline,
  Print,
  SaveOutlined,
  SettingsSuggestOutlined,
  Wifi,
  WifiOff,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Portal,
  TableContainer,
} from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import { UseDriverFilterResult } from "../../components/schedule/useDriverFilter";
import {
  RecommendationResponse,
  //   useGetRestRecommendationMutation,
  //   useGetWorkRecommendationMutation,
} from "../../data/api/recommendationsApi";
import { Schedule, ScheduleParameters } from "../../data/api/types/schedule";
import { useSubmenu } from "../../hooks/useSubmenu";
import { RestRecommendationDialog } from "./RestRecommendationDialog";
import { RestRecommendationTable } from "./RestRecommendationTable";
import moment, { Moment } from "moment";
import { useDriverSchedulePdfExporter } from "../../printing/useDriverSchedulePdfExporter";
import { useProfile } from "../../common/useProfile";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import { green, red } from "@mui/material/colors";
import { useIsHoliday } from "../../components/schedule/useIsHoliday";
import { PrintScheduleDialog } from "./PrintScheduleDialog";
import { DaySummary, DriverSummary } from "../../components/schedule/types";

const enableWhenUnknown = false;

export function ScheduleTopMenu({
  scheduleParameters,
  schedule,
  daySummary,
  driverSummary,
  driverFilter,
  startPlanner,
  stopPlanner,
  saveSchedule,
  cleanupSchedule,
  markReady,
  reimportAllData,
  setDriverFilterValue,
  setError,
}: {
  scheduleParameters: ScheduleParameters | null;
  schedule: Schedule | undefined;
  daySummary: DaySummary[] | null;
  driverSummary: Record<string, DriverSummary> | null;
  driverFilter: UseDriverFilterResult;
  startPlanner: () => void;
  stopPlanner: () => Promise<{ status: "ok" }>;
  saveSchedule: () => Promise<{ status: "ok" }>;
  cleanupSchedule: () => Promise<{ status: "ok" }>;
  markReady: () => Promise<{ status: "ok" }>;
  reimportAllData: () => Promise<{ status: "ok" }>;
  setDriverFilterValue: (value: string) => void;
  setError: (value: ReactNode | null) => void;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");
  const canStartPlanner = profile?.roles?.includes("start_planner");
  // const isAdmin = profile?.roles?.includes("nariad_admin");

  const { rightToolbarMenu, belowSubmenu } = useSubmenu();
  const [schedulePrintDialogOpen, setSchedulePrintDialogOpen] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [printAdditionalData, setPrintAdditionalData] =
    useState<boolean>(false);
  const { exportPdf, isLoading: exportPdfLoading } =
    useDriverSchedulePdfExporter();

  // const [queryRecommendedRests, recommendedRestsStatus] =
  //   useGetRestRecommendationMutation();
  // const [queryRecommendedWorks, recommendedWorksStatus] =
  //   useGetWorkRecommendationMutation();
  // const [getRecommendations, getRecommendationsStatus] =
  //   useLazyGetRecommendationsQuery();
  const [recommendationsDialogVisible, setRecommendationsDialogVisible] =
    useState(false);

  const [recommendations, setRecommendations] = useState<
    | (RecommendationResponse & { results_for: "work" | "rest"; date: string })
    | null
  >(null);

  const selectedMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );
  const isHoliday = useIsHoliday(selectedMonth);

  const onDialogResult = useCallback(
    (
      results:
        | (RecommendationResponse & {
            results_for: "work" | "rest";
            date: string;
          })
        | null
    ) => {
      setRecommendationsDialogVisible(false);
      setRecommendations(results);
    },
    []
  );

  const isChannelConnected = useSelector(
    (state: RootState) =>
      state.schedule.socketConnected && state.schedule.scheduleConnected
  );

  const [saveScheduleBusy, setSaveScheduleBusy] = useState(false);
  const [cleanupScheduleBusy, setCleanupScheduleBusy] = useState(false);
  const [markReadyBusy, setMarkReadyBusy] = useState(false);
  const [reimportAllDataBusy, setReimportAllDataBusy] = useState(false);
  const busy =
    saveScheduleBusy ||
    cleanupScheduleBusy ||
    reimportAllDataBusy ||
    markReadyBusy;

  const isPreliminary = useSelector(
    (state: RootState) =>
      state.schedule.schedule?.db_schedule?.preliminary_ready !== true
  );

  return (
    <>
      <Portal container={belowSubmenu}>
        {recommendations && (
          <TableContainer component={Box}>
            <Alert
              severity="info"
              variant="standard"
              onClose={() => setRecommendations(null)}
              sx={{
                px: 4,
                py: 0,
                borderRadius: 0,
              }}
            >
              <span style={{ whiteSpace: "normal" }}>
                Предложения за{" "}
                {recommendations.results_for === "work"
                  ? "връщане на работа"
                  : "пускане в почивка"}{" "}
                за {moment(recommendations.date, "YYYY-MM-DD").format("LL")}
              </span>
            </Alert>
            <RestRecommendationTable
              results={recommendations}
              date={moment(recommendations.date, "YYYY-MM-DD")}
              resultsForWork={recommendations.results_for === "work"}
              variant="dense"
              setDriverFilterValue={setDriverFilterValue}
            />
          </TableContainer>
        )}
      </Portal>
      <Portal container={rightToolbarMenu}>
        {schedule && daySummary && driverSummary && selectedMonth && (
          <>
            <PrintScheduleDialog
              open={schedulePrintDialogOpen}
              startDate={startDate}
              endDate={endDate}
              printAdditionalData={printAdditionalData}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setPrintAdditionalData={setPrintAdditionalData}
              selectedMonth={selectedMonth}
              handleCancel={() => setSchedulePrintDialogOpen(false)}
              // handleConfirm={() => Promise.resolve()}
              handleConfirm={(options) =>
                exportPdf(
                  schedule,
                  daySummary,
                  driverSummary,
                  isHoliday,
                  options
                )
              }
              // onClick={() => exportPdf(schedule, isHoliday)}
            />
            <RestRecommendationDialog
              schedule={schedule}
              driverFilter={driverFilter}
              // getRecommendations={getRecommendations}
              // getRecommendationsStatus={getRecommendationsStatus}
              open={recommendationsDialogVisible}
              onClose={onDialogResult}
            />

            {isPreliminary &&
            scheduleParameters &&
            (scheduleParameters[0] === "TM" ||
              scheduleParameters[0] === "TB") ? (
              <IconButton
                color="inherit"
                size="large"
                aria-label="Приключи предварителен график"
                title="Приключи предварителен график"
                disabled={
                  busy ||
                  ["started", "start_request", "stop_request"].includes(
                    schedule.planner_status.status
                  ) ||
                  !scheduleParameters ||
                  !schedule ||
                  schedule.drivers_sync_status.status !== "ok" ||
                  !schedule.drivers ||
                  Object.values(schedule.drivers).length === 0
                }
                onClick={async () => {
                  if (
                    window.confirm(
                      `Сигурни ли сте че желаете да маркирате предварителния график за готов?`
                    )
                  ) {
                    setMarkReadyBusy(true);
                    setError(null);
                    try {
                      const result = await markReady();
                      if (result.status !== "ok") {
                        setError(
                          <div>
                            Грешка при маркиране на предварителен график за
                            готов:
                          </div>
                        );
                      }
                    } catch (e: any) {
                      console.log("Finalyze error:", e);
                      if (
                        e &&
                        e.status === "error" &&
                        Array.isArray(e.errors)
                      ) {
                        const errors: string[] = e.errors
                          .filter(
                            (e: any) =>
                              typeof e.message === "string" &&
                              e.message.length > 0
                          )
                          .map((e: any) => e.message);

                        setError(
                          <>
                            <div>
                              Грешка при маркиране на предварителен график за
                              готов:
                            </div>
                            <ul>
                              {errors.map((err, idx) => (
                                <li key={idx}>{err}</li>
                              ))}
                            </ul>
                          </>
                        );
                      } else {
                        setError(
                          "Грешка при маркиране на предварителен график за готов"
                        );
                      }
                    } finally {
                      setMarkReadyBusy(false);
                    }
                  }
                }}
              >
                {markReadyBusy ? (
                  <CircularProgress
                    size="0.75em"
                    sx={{ color: (theme) => theme.palette.grey[100] }}
                  />
                ) : (
                  <DoneOutline />
                )}
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton
              color="inherit"
              size="large"
              aria-label="Предложи водач за почивка"
              title="Предложи водач за почивка"
              disabled={
                busy ||
                ["started", "start_request", "stop_request"].includes(
                  schedule.planner_status.status
                ) ||
                !scheduleParameters ||
                !schedule ||
                schedule.drivers_sync_status.status !== "ok" ||
                !schedule.drivers ||
                Object.values(schedule.drivers).length === 0
              }
              onClick={() => {
                setRecommendations(null);
                setRecommendationsDialogVisible(true);
              }}
            >
              <SettingsSuggestOutlined />
            </IconButton>
            <IconButton
              color="inherit"
              size="large"
              aria-label="Експорт в PDF"
              title="Експорт в PDF"
              disabled={
                exportPdfLoading ||
                busy ||
                ["started", "start_request", "stop_request"].includes(
                  schedule.planner_status.status
                ) ||
                !scheduleParameters ||
                !schedule ||
                schedule.drivers_sync_status?.status !== "ok" ||
                !schedule.drivers ||
                Object.values(schedule.drivers).length === 0
              }
              // onClick={() => exportPdf(schedule, isHoliday)}
              onClick={() => {
                const startDate = moment(
                  (selectedMonth || "2024-01") + "-01",
                  "YYYY-MM-DD"
                );
                const endDate = startDate.clone().endOf("month");
                setStartDate(startDate);
                setEndDate(endDate);
                setPrintAdditionalData(false);
                setSchedulePrintDialogOpen(true);
              }}
            >
              {exportPdfLoading ? (
                <CircularProgress
                  size="0.75em"
                  sx={{ color: (theme) => theme.palette.grey[100] }}
                />
              ) : (
                <Print />
              )}
            </IconButton>
            {(isPreliminary ||
              (scheduleParameters &&
                scheduleParameters[0] !== "TM" &&
                scheduleParameters[0] !== "TB")) && (
              <IconButton
                color="inherit"
                size="large"
                aria-label="Изтрий данни от график"
                title="Изтрий данни от график"
                disabled={
                  isReadOnly ||
                  busy ||
                  ["started", "start_request", "stop_request"].includes(
                    schedule.planner_status.status
                  ) ||
                  !scheduleParameters ||
                  !schedule ||
                  schedule.drivers_sync_status?.status !== "ok" ||
                  !schedule.drivers ||
                  Object.values(schedule.drivers).length === 0 ||
                  (schedule.planner_status.status !== "stopped" &&
                    enableWhenUnknown &&
                    schedule.planner_status.status !== "unknown")
                }
                onClick={async () => {
                  if (
                    window.confirm(
                      `Сигурни ли сте че желаете да изтриете автоматично генерирания график?`
                    )
                  ) {
                    setCleanupScheduleBusy(true);
                    setError(null);
                    try {
                      const result = await cleanupSchedule();
                      if (result.status !== "ok") {
                        setError("Грешка при изтриване на данни от графика");
                      }
                    } catch {
                      setError("Грешка при изтриване на данни от графика");
                    } finally {
                      setCleanupScheduleBusy(false);
                    }
                  }
                }}
              >
                {cleanupScheduleBusy ? (
                  <CircularProgress
                    size="0.75em"
                    sx={{ color: (theme) => theme.palette.grey[100] }}
                  />
                ) : (
                  <DeleteOutline />
                )}
              </IconButton>
            )}
            <IconButton
              color="inherit"
              size="large"
              aria-label="Импорт на данни за специални смени и експлоатационен план"
              title="Импорт на данни за специални смени и експлоатационен план"
              disabled={
                //isReadOnly ||
                busy ||
                ["started", "start_request", "stop_request"].includes(
                  schedule.planner_status.status
                ) ||
                !scheduleParameters ||
                !schedule ||
                schedule.drivers_sync_status?.status !== "ok" ||
                !schedule.drivers ||
                Object.values(schedule.drivers).length === 0 ||
                (schedule.planner_status.status !== "stopped" &&
                  enableWhenUnknown &&
                  schedule.planner_status.status !== "unknown")
              }
              onClick={async () => {
                setReimportAllDataBusy(true);
                setError(null);
                try {
                  const result = await reimportAllData();
                  if (result.status !== "ok") {
                    setError("Грешка при импорт на данни за графика");
                  }
                } catch {
                  setError("Грешка при импорт на данни за графика");
                } finally {
                  setReimportAllDataBusy(false);
                }
              }}
            >
              {reimportAllDataBusy ? (
                <CircularProgress
                  size="0.75em"
                  sx={{ color: (theme) => theme.palette.grey[100] }}
                />
              ) : (
                <ImportExportOutlined />
              )}
            </IconButton>
            <IconButton
              color="inherit"
              size="large"
              aria-label="Запиши график"
              title="Запиши график"
              disabled={
                isReadOnly ||
                busy ||
                ["started", "start_request", "stop_request"].includes(
                  schedule.planner_status.status
                ) ||
                !scheduleParameters ||
                !schedule ||
                schedule.drivers_sync_status?.status !== "ok" ||
                !schedule.drivers ||
                Object.values(schedule.drivers).length === 0 ||
                (schedule.planner_status.status !== "stopped" &&
                  enableWhenUnknown &&
                  schedule.planner_status.status !== "unknown")
              }
              onClick={async () => {
                setSaveScheduleBusy(true);
                setError(null);
                try {
                  const result = await saveSchedule();
                  if (result.status !== "ok") {
                    setError("Грешка при записване на график");
                  }
                } catch {
                  setError("Грешка при записване на график");
                } finally {
                  setSaveScheduleBusy(false);
                }
              }}
            >
              {saveScheduleBusy ? (
                <CircularProgress
                  size="0.75em"
                  sx={{ color: (theme) => theme.palette.grey[100] }}
                />
              ) : (
                <SaveOutlined />
              )}
            </IconButton>
            {canStartPlanner ? (
              <IconButton
                color="inherit"
                size="large"
                aria-label={
                  schedule.planner_status.status !== "started"
                    ? "Започни сметки"
                    : "Спри сметки"
                }
                title={
                  schedule.planner_status.status !== "started"
                    ? "Започни сметки"
                    : "Спри сметки"
                }
                disabled={
                  isReadOnly ||
                  busy ||
                  !canStartPlanner ||
                  // ["started", "start_request", "stop_request"].includes(
                  //   schedule.planner_status.status
                  // ) ||
                  !scheduleParameters ||
                  !schedule ||
                  schedule.drivers_sync_status?.status !== "ok" ||
                  !schedule.drivers ||
                  Object.values(schedule.drivers).length === 0 ||
                  (schedule.planner_status.status !== "started" &&
                    schedule.planner_status.status !== "stopped" &&
                    enableWhenUnknown &&
                    schedule.planner_status.status !== "unknown" &&
                    schedule.planner_status.status !== "error") ||
                  ((scheduleParameters[0] === "TM" ||
                    scheduleParameters[0] === "TB") &&
                    !!schedule.db_schedule.preliminary_ready)
                }
                onClick={() =>
                  schedule.planner_status.status === "started"
                    ? stopPlanner()
                    : startPlanner()
                }
              >
                {schedule.planner_status.status !== "started" ? (
                  <PlayCircleOutline />
                ) : (
                  <PauseCircleOutline />
                )}
              </IconButton>
            ) : (
              <></>
            )}
          </>
        )}

        {isChannelConnected ? (
          <Wifi sx={{ color: green[200] }} titleAccess="Има връзка" />
        ) : (
          <WifiOff sx={{ color: red[200] }} titleAccess="Няма връзка" />
        )}
      </Portal>
    </>
  );
}
