import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../common/useProfile";
import {
  updateFilterTransports,
  updateSelectedTransport,
} from "../data/filtersSlice";
import { RootState, useAppDispatch } from "../data/store";

export function useTransportFilterEvents() {
  const dispatch = useAppDispatch();
  const profile = useProfile();

  useEffect(() => {
    if (profile) {
      dispatch(updateFilterTransports(profile.transports || {}));
    }
  }, [dispatch, profile]);
}

export function useTransportFilter() {
  const dispatch = useAppDispatch();

  const transports = useSelector((state: RootState) =>
    state.filters.transports.map((t) => t.transport)
  );
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const setSelectedTransport = useCallback(
    (transport: string) => {
      if (transport === "A" || transport === "TM" || transport === "TB") {
        dispatch(updateSelectedTransport(transport));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedTransport === "" && transports.length > 0) {
      dispatch(updateSelectedTransport(transports[0]));
    }
  }, [dispatch, selectedTransport, transports]);

  return {
    transports,
    selectedTransport,
    setSelectedTransport,
  };
}
