import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Transport } from "./api/types/driver";
import moment from "moment";
import type { WritableDraft } from "immer/dist/internal";

export interface FiltersState {
  autocolumns: number[];
  transports: {
    transport: Transport;
    garages: { id: number; autocolumns: number[] }[];
  }[];
  selectedTransport: Transport | "";
  needsTransportSelect: boolean;
  depots: { id: number; value: string; autocolumns: number[] }[];
  selectedDepot: number;
  needsDepotSelect: boolean;
  selectedAutoColumn: number;
  needsAutocolumnSelect: boolean;
  selectedMonth: string | null;
  selectedDate: string | null;
}

const initialState: FiltersState = {
  autocolumns: [1],
  transports: [],
  selectedTransport: "",
  needsTransportSelect: false,
  depots: [],
  selectedDepot: -1,
  needsDepotSelect: false,
  selectedAutoColumn: 1,
  needsAutocolumnSelect: false,
  selectedMonth: moment().format("YYYY-MM"),
  selectedDate: moment().add(1, "day").startOf("day").format("YYYY-MM-DD"),
};

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

function updateSelectedDepotFields(
  state: FiltersState,
  transport: ArrayElement<FiltersState["transports"]>,
  keepDepot: boolean
) {
  if (transport.garages.length === 0) {
    state.selectedDepot = -1;
    state.needsDepotSelect = false;
    state.depots = [];
    state.selectedAutoColumn = 1;
    state.needsAutocolumnSelect = false;
  } else if (transport.garages.length === 1) {
    state.selectedDepot = transport.garages[0].id;
    state.needsDepotSelect = false;
    state.depots = transport.garages.map((garage) => ({
      id: garage.id,
      value: "",
      autocolumns: garage.autocolumns || [1],
    }));
  } else {
    if (
      keepDepot &&
      transport.garages.findIndex(
        (garage) => garage.id === state.selectedDepot
      ) < 0
    ) {
      state.selectedDepot = transport.garages[0].id;
    }
    state.needsDepotSelect = true;
    state.depots = transport.garages.map((garage) => ({
      id: garage.id,
      value: "",
      autocolumns: garage.autocolumns || [1],
    }));
  }
}

function clearTransport(state: FiltersState) {
  state.selectedTransport = "";
  state.selectedDepot = -1;
  state.needsTransportSelect = false;
  state.needsDepotSelect = false;
  state.depots = [];
  state.selectedAutoColumn = 1;
  state.needsAutocolumnSelect = false;
}

function updateAutocolumns(
  state: WritableDraft<FiltersState>,
  depots: {
    id: number;
    value: string;
    autocolumns: number[];
  }[]
) {
  state.autocolumns = depots.find((d) => d.id === state.selectedDepot)
    ?.autocolumns || [1];

  if (state.autocolumns.length === 0) {
    state.autocolumns = [1];
  }

  if (state.autocolumns.length === 1) {
    state.needsAutocolumnSelect = false;
    state.selectedAutoColumn = state.autocolumns[0];
  } else {
    state.needsAutocolumnSelect = true;
    if (!state.autocolumns.includes(state.selectedAutoColumn)) {
      state.selectedAutoColumn = state.autocolumns[0];
    }
  }
}

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateFilterTransports: (
      state,
      action: PayloadAction<Record<string, Record<number, number[]>>>
    ) => {
      state.transports = Object.entries(action.payload)
        .filter(([t]) => t === "A" || t == "TM" || t === "TB")
        .map(([transport, garages]) => ({
          transport: transport as Transport,
          garages: Object.entries(garages).map(([garage, autocolumns]) => ({
            id: typeof garage === "number" ? garage : parseInt(garage, 10),
            autocolumns,
          })),
        }));

      if (state.transports.length === 0) {
        clearTransport(state);
      } else {
        const selectedTransportIdx = state.transports.findIndex(
          (t) => t.transport === state.selectedTransport
        );
        const selectedTransport =
          state.transports[
            selectedTransportIdx >= 0 ? selectedTransportIdx : 0
          ];

        state.selectedTransport = selectedTransport.transport;
        state.needsTransportSelect = state.transports.length > 1;
        updateSelectedDepotFields(
          state,
          selectedTransport,
          selectedTransportIdx >= 0
        );

        updateAutocolumns(state, state.depots);
      }
    },
    updateSelectedTransport: (state, action: PayloadAction<Transport | "">) => {
      const selectedTransportIdx = state.transports.findIndex(
        (t) => t.transport === action.payload
      );

      if (action.payload === "" || selectedTransportIdx < 0) {
        clearTransport(state);
      } else if (state.selectedTransport !== action.payload) {
        const transport = state.transports[selectedTransportIdx];

        state.selectedTransport = transport.transport;
        state.depots = transport.garages.map((garage) => ({
          id: garage.id,
          value: "",
          autocolumns: garage.autocolumns || [1],
        }));
        state.selectedDepot = state.depots.length > 0 ? state.depots[0].id : -1;
        state.needsDepotSelect = state.depots.length > 1;
      }

      updateAutocolumns(state, state.depots);
    },

    updateFilterDepots: (
      state,
      action: PayloadAction<
        { id: number; value: string; autocolumns: number[] }[]
      >
    ) => {
      if (action.payload.length === 0) {
        state.needsDepotSelect = false;
        state.selectedDepot = -1;
      } else if (action.payload.length === 1) {
        state.needsDepotSelect = false;
        state.selectedDepot = action.payload[0].id;
      } else {
        state.needsDepotSelect = true;
      }

      updateAutocolumns(state, action.payload);

      state.depots = action.payload;
    },
    updateSelectedDepot: (state, action: PayloadAction<number>) => {
      if (state.depots.findIndex((depot) => depot.id === action.payload) >= 0) {
        state.selectedDepot = action.payload;
      } else {
        state.selectedDepot = -1;
      }

      updateAutocolumns(state, state.depots);
    },
    updateSelectedAutoColumn: (
      state,
      action: PayloadAction<{
        autocolumn: string | number;
        allowAnyAutocolumn?: boolean;
      }>
    ) => {
      const value =
        typeof action.payload.autocolumn === "string"
          ? /^\s*-?\d+\s*$/.test(action.payload.autocolumn)
            ? parseInt(action.payload.autocolumn)
            : -1
          : action.payload.autocolumn;

      if (
        action.payload.allowAnyAutocolumn ||
        state.autocolumns.includes(value)
      ) {
        state.selectedAutoColumn = value;
      } else {
        state.selectedAutoColumn = state.autocolumns[0] || 1;
      }
    },
    updateSelectedMonth: (state, action: PayloadAction<string | null>) => {
      const selectedMonth = moment(action.payload, "YYYY-MM");
      if (selectedMonth.isValid()) {
        state.selectedMonth = selectedMonth.format("YYYY-MM");
      } else {
        state.selectedMonth = null;
      }
    },
    updateSelectedDate: (state, action: PayloadAction<string | null>) => {
      const selectedMonth = moment(action.payload, "YYYY-MM");
      if (selectedMonth.isValid()) {
        state.selectedMonth = selectedMonth.format("YYYY-MM");
      } else {
        state.selectedMonth = null;
      }

      const selectedDate = moment(action.payload, "YYYY-MM-DD");
      if (selectedDate.isValid()) {
        state.selectedDate = selectedDate.format("YYYY-MM-DD");
      } else {
        state.selectedDate = null;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFilterTransports,
  updateSelectedTransport,
  updateFilterDepots,
  updateSelectedDepot,
  updateSelectedAutoColumn,
  updateSelectedMonth,
  updateSelectedDate,
} = filtersSlice.actions;

export default filtersSlice.reducer;
