import moment, { Moment } from "moment";
import { GarageSettings } from "../common/useGarageSettings";
import {
  DayTask,
  ScheduleDay,
  ScheduleDriver,
  sortArray,
  Writeable,
} from "../data/api/types/schedule";
import { GetDriverNastavnik } from "../pages/reports/roster_report/helpers";
import {
  RosterDriver,
  RosterShift,
  RosterVehicle,
  TimetableRosterShift,
} from "../pages/roster/types/Roster";
import { PageData } from "./usePrintJourneyListData";

export type DayTaskForJourneyList = DayTask & {
  rosterShift: RosterShift;
  rosterDriver: RosterDriver;
};

export type PageDataShift = {
  driver_id: number;
  nastavnikId: number | null;
  nastavnikName: string | null;
  sl_number: string;
  name: string;
  lines: string;
  datas: {
    line_name: string;
    line_order: number;
    car_no: number;
    skip_car: boolean;
    shift_no: 1 | 2;
  }[];
  shiftTimes: {
    enterTime?: Moment;
    exitTime?: Moment;
  };
};

function getLineName(
  dt: DayTask & {
    rosterShift: RosterShift | null;
  }
) {
  if (dt.rosterShift?.type === "timetable") {
    return dt.rosterShift.shift.lineName;
  } else if (dt.rosterShift?.type === "special") {
    return dt.rosterShift.shift.workshift.description;
  } else {
    return null;
  }
}

function getCarNo(
  dt: DayTask & {
    rosterShift: RosterShift | null;
  }
) {
  if (dt.rosterShift?.type === "timetable") {
    return dt.rosterShift.shift.car;
  } else {
    return null;
  }
}

export function getSpecialShiftDetails(
  dt: DayTask & {
    rosterShift: RosterShift | null;
  }
): {
  line: string;
  car: string;
  shift: 1 | 2 | 3;
} | null {
  const lineName = getLineName(dt);

  if (
    dt.rosterShift?.type === "special" &&
    lineName &&
    lineName.trim() !== ""
  ) {
    const p1match = /^(п-на)(\s*(\d+))?/i.exec(lineName);
    const p2match = /^(персонална)(\s*(\d+))?/i.exec(lineName);
    if (p1match) {
      return {
        line: "П-НА",
        car: (p1match[3] ? p1match[3] : "") || dt.car_no?.toString() || "",
        shift: 3,
      };
    } else if (p2match) {
      return {
        line: "П-НА",
        car: (p2match[3] ? p2match[3] : "") || dt.car_no?.toString() || "",
        shift: 3,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getNightShiftDetails(
  dt: DayTask & {
    rosterShift: RosterShift | null;
  }
): {
  line: string;
  car: string;
  shift: 1 | 2 | 3;
} | null {
  const lineName = getLineName(dt);

  if (
    dt.rosterShift?.type === "timetable" &&
    lineName &&
    lineName.trim() !== ""
  ) {
    const nmatch = /^(н|n)(\s*(\d+))?/i.exec(lineName);
    if (nmatch) {
      return {
        line: lineName,
        car: dt.car_no?.toString() || "",
        shift: 3,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function isNightLine(dt: DayTaskForJourneyList): boolean {
  const lineNameStart =
    dt.rosterShift.type === "timetable"
      ? dt.rosterShift.shift.lineName.trim()[0]?.toLocaleLowerCase() || ""
      : "";
  return ["н", "h"].includes(lineNameStart);
}

export function getAllDaytasks(
  vehicle: (RosterVehicle & { special: boolean; night: boolean }) | null,
  settings: GarageSettings
): DayTaskForJourneyList[] {
  if (!vehicle) {
    return [];
  } else {
    const daytasksRaw: DayTaskForJourneyList[] = vehicle.shifts
      .flat()
      .flatMap((rd) =>
        rd.daytasks.map((dt) => ({
          ...dt,
          rosterShift: dt.rosterShift as RosterShift,
          rosterDriver: rd,
        }))
      )
      .filter((dt) => dt.veh1_id === vehicle.vehicle_id && dt.rosterShift);

    let daytasks: DayTaskForJourneyList[] = sortArray<{
      start_time: string | null;
    }>(daytasksRaw, "start_time", { in_place: true }) as any;

    if (vehicle.special) {
      daytasks = daytasks
        .map((dt) => {
          const ssd = getSpecialShiftDetails(dt);
          return {
            dt: ssd
              ? {
                  ...dt,
                  linename: ssd
                    ? ssd.line.trim()
                    : getLineName(dt)?.trim() || "",
                  car_no:
                    ssd && ssd.car && ssd.car.trim() !== ""
                      ? parseInt(ssd.car, 10)
                      : dt.car_no,
                }
              : dt,
            ssd: ssd,
          };
        })
        .filter(({ ssd }) => ssd)
        .map(({ dt }) => dt);
    } else {
      if (settings.splitNightCars) {
        if (vehicle.night) {
          daytasks = daytasks
            .map((dt) => {
              const ssd = getNightShiftDetails(dt);
              return {
                dt: ssd
                  ? {
                      ...dt,
                      shift_type: 1 as const,
                      linename: ssd
                        ? ssd.line.trim()
                        : getLineName(dt)?.trim() || "",
                      car_no:
                        ssd && ssd.car && ssd.car.trim() !== ""
                          ? parseInt(ssd.car, 10)
                          : dt.car_no,
                    }
                  : dt,
                ssd: ssd,
              };
            })
            .filter(({ ssd }) => ssd)
            .map(({ dt }) => dt);
        } else {
          daytasks = daytasks.filter(
            (dt) => !getSpecialShiftDetails(dt) && !getNightShiftDetails(dt)
          );
        }
      } else {
        daytasks = daytasks.filter((dt) => !getSpecialShiftDetails(dt));
      }
    }

    const [normalDt, nightDt] = daytasks.reduce<
      [typeof daytasks, typeof daytasks]
    >(
      ([normalDt, nightDt], dt) => {
        if (isNightLine(dt)) {
          nightDt.push(dt);
          return [normalDt, nightDt];
        } else {
          normalDt.push(dt);
          return [normalDt, nightDt];
        }
      },
      [[], []]
    );

    return normalDt.concat(nightDt);
  }
}

export function generateShiftData(
  shift: DayTaskForJourneyList[],
  drivers: readonly ScheduleDriver[],
  day_roster: Record<
    number,
    {
      driver_id: number;
      scheduleDay: ScheduleDay;
    }
  >[]
): PageDataShift | undefined {
  if (shift && shift.length > 0) {
    const shiftTimes = getShiftTimes(shift);

    const nastavnikId = GetDriverNastavnik(
      shift[0].rosterDriver.driver_id,
      day_roster
    );
    const nastavnikName = nastavnikId
      ? drivers.find((d) => d.sl_number === nastavnikId)?.name
      : null;

    return {
      driver_id: shift[0].rosterDriver.driver_id,
      sl_number: shift[0].rosterDriver.sl_number.toString(),
      nastavnikId: nastavnikId,
      nastavnikName: nastavnikName || null,
      name:
        drivers.find((d) => d.sl_number === shift[0].rosterDriver.sl_number)
          ?.name || "",
      lines: getLineNames(shift),
      datas: shift.map((shift) => {
        if (shift.rosterShift.type === "timetable") {
          return {
            line_name: shift.rosterShift.shift.lineName,
            line_order: shift.rosterShift.shift.lineOrder,
            car_no: shift.rosterShift.shift.car,
            skip_car: false,
            shift_no: shift.shift_type || 1,
          };
        } else {
          const details = getSpecialShiftDetails(shift);
          if (details) {
            return {
              line_name: details.line,
              line_order: 999997,
              car_no: details.car.length > 0 ? parseInt(details.car, 10) : 1000,
              skip_car: details.car.length === 0,
              shift_no: shift.rosterShift.shift.workshift.shift_type,
            };
          } else {
            return {
              line_name: shift.rosterShift.shift.workshift.description,
              line_order: 999998,
              car_no: 1000,
              skip_car: true,
              shift_no: shift.rosterShift.shift.workshift.shift_type,
            };
          }
        }
      }),
      shiftTimes: {
        enterTime:
          shift.length > 0 && !shiftTimes.enterTime.isSame(MAX_TIME)
            ? shiftTimes.enterTime.clone()
            : undefined,
        exitTime:
          shift.length > 0 && !shiftTimes.exitTime.isSame(MIN_TIME)
            ? shiftTimes.exitTime.clone()
            : undefined,
      },
    };
  } else {
    return undefined;
  }
}

export function groupDaytasksByShift(
  daytasks: DayTaskForJourneyList[]
): [DayTaskForJourneyList[], DayTaskForJourneyList[], DayTaskForJourneyList[]] {
  const groups: [
    DayTaskForJourneyList[],
    DayTaskForJourneyList[],
    DayTaskForJourneyList[]
  ] = [[], [], []];

  let currentShift = 0;
  for (let i = 0; i < daytasks.length && currentShift < 3; ++i) {
    if (i === 0 && daytasks[0].shift_type === 2) {
      // започваме с 2-ра смяна
      currentShift = 1;
    } else if (
      i === 0 &&
      (isNightLine(daytasks[0]) || getSpecialShiftDetails(daytasks[0]))
    ) {
      // започваме с нощна линия или персонална (3-та смяна)
      currentShift = 2;
    }

    if (
      i > 0 &&
      (daytasks[i - 1].shift_type !== daytasks[i].shift_type ||
        daytasks[i - 1].rosterShift.type !== daytasks[i].rosterShift.type ||
        (!isNightLine(daytasks[i - 1]) && isNightLine(daytasks[i])))
    ) {
      currentShift += 1;
    }
    if (currentShift >= 3) {
      break;
    }
    groups[currentShift].push(daytasks[i]);
  }

  return groups;
}

export function getLineNames(daytasks: DayTaskForJourneyList[]) {
  const lines = daytasks
    .filter((dt) => {
      const lineName = getLineName(dt);
      return lineName && lineName.trim().length > 0;
    })
    .map((dt) => {
      const lineName = getLineName(dt);
      const carNo = getCarNo(dt);
      return `${lineName?.trim() || ""}${
        carNo && carNo > 0 ? ` /  ${carNo}` : ""
      }`;
    })
    .filter((l) => l && l.trim() !== "")
    .join(";     ");

  return lines;
}

export type LineDocument = {
  readonly line: string;
  readonly car: string;
  readonly shift: 1 | 2 | 3;
};

export type LineDocuments = readonly [
  LineDocument | null,
  LineDocument | null,
  LineDocument | null,
  LineDocument | null
];

export function getLines(
  daytasks: DayTaskForJourneyList[],
  ...skip: (PageDataShift | null | undefined)[]
) {
  const skipped = skip.flatMap((s) => s?.datas || []);

  const lines = sortArray(
    daytasks.flat().flatMap((dt) => {
      const found = skipped.find(
        (s) =>
          s.line_name === getLineName(dt) &&
          s.car_no === dt.car_no &&
          s.shift_no === dt.shift_type
      );

      if (found) {
        return [];
      } else if (dt) {
        const lineName = getLineName(dt);
        const carNo = getCarNo(dt);
        if (
          dt.rosterShift.type === "timetable" &&
          carNo &&
          carNo > 0 &&
          lineName &&
          lineName.trim() !== ""
        ) {
          return [
            {
              line: lineName.trim(),
              car: carNo.toString(),
              shift:
                dt.shift_type && dt.shift_type >= 1 && dt.shift_type <= 3
                  ? dt.shift_type
                  : 1,
            },
          ];
        } else {
          const ssd = getSpecialShiftDetails(dt);
          const lineName = getLineName(dt);
          if (ssd) {
            return ssd;
          } else if (
            dt.rosterShift?.type === "special" &&
            lineName &&
            lineName.trim() !== ""
          ) {
            return [
              {
                line: lineName.trim(),
                car: "",
                shift:
                  dt.shift_type && dt.shift_type >= 1 && dt.shift_type <= 3
                    ? dt.shift_type
                    : 1,
              },
            ];
          } else {
            return [];
          }
        }
      } else {
        return [];
      }
    }),
    ["line", "car", "shift"],
    { in_place: true }
  );

  const byShift: Writeable<LineDocuments> = [null, null, null, null];

  if (lines.length === 1) {
    byShift[lines[0].shift - 1] = lines[0];
  } else if (lines.length === 2) {
    if (lines[0].shift === 1) {
      byShift[0] = lines[0];
      byShift[1] = lines[1];
    } else {
      byShift[1] = lines[0];
      byShift[2] = lines[1];
    }
  } else if (lines.length >= 3) {
    byShift[0] = lines[0];
    byShift[1] = lines[1];
    byShift[2] = lines[2];
  }

  return byShift;
}

export const MIN_TIME = moment("1970-01-01T00:00:00+0000");
export const MAX_TIME = moment("2200-01-01T00:00:00+0000");

export function getShiftTimes(daytasks: DayTaskForJourneyList[]) {
  const exitTime = daytasks
    .map((dt) => (dt.start_time ? moment(dt.start_time) : moment.invalid()))
    .filter((st) => st.isValid())
    .reduce((acc, st) => (st.isBefore(acc) ? st : acc), MAX_TIME);

  const enterTime = daytasks
    .map((dt) => (dt.end_time ? moment(dt.end_time) : moment.invalid()))
    .filter((st) => st.isValid())
    .reduce((acc, st) => (st.isAfter(acc) ? st : acc), MIN_TIME);

  return { exitTime, enterTime };
}

function findShiftByLineAndCar(
  shifts: readonly TimetableRosterShift[],
  shift: TimetableRosterShift
) {
  return shifts.find(
    (s) =>
      s.shift.line === shift.shift.line &&
      s.shift.car === shift.shift.car &&
      s.shift.workshift.shift_type !== shift.shift.workshift.shift_type
  );
}

function isRealTimetableShift(
  vehicle: RosterVehicle,
  dt: DayTask & {
    rosterShift: RosterShift | null;
  }
): dt is DayTask & {
  rosterShift: TimetableRosterShift;
} {
  return !!(
    dt.rosterShift &&
    dt.veh1_id === vehicle.vehicle_id &&
    dt.rosterShift.type === "timetable"
  );
}

export function isExportedCar(
  vehicle: (RosterVehicle & { special?: boolean }) | null | undefined,
  shifts: readonly RosterShift[] | null | undefined
): [boolean, readonly TimetableRosterShift[]] {
  if (
    !vehicle ||
    !shifts ||
    shifts.length === 0 ||
    vehicle.special ||
    (vehicle.shifts[0].length === 0 && vehicle.shifts[1].length === 0)
  ) {
    return [false, []];
  }

  // if (hasRealShift(vehicle, 1, false) && hasRealShift(vehicle, 2, false)) {
  //   return [false, []];
  // }

  const timetableShifts: TimetableRosterShift[] = shifts.filter(
    (s) => s.type === "timetable"
  ) as any;

  const rosterShifts: TimetableRosterShift[] = [];

  for (let rdIdx = 0; rdIdx < vehicle.shifts.length; ++rdIdx) {
    for (const vs of vehicle.shifts[rdIdx]) {
      for (const dt of vs.daytasks) {
        if (isRealTimetableShift(vehicle, dt)) {
          rosterShifts.push(dt.rosterShift);
        }
      }
    }
  }

  rosterShifts.sort(
    (a, b) => a.shift.workshift.end_time - b.shift.workshift.end_time
  );

  const lastFirstShift = rosterShifts.find(
    (s, i, ss) =>
      s.shift.workshift.shift_type === 1 &&
      (i >= ss.length - 1 || ss[i + 1].shift.workshift.shift_type === 2)
  );
  const firstSecondShift = rosterShifts.find(
    (s, i, ss) =>
      s.shift.workshift.shift_type === 2 &&
      (i <= 0 || ss[i - 1].shift.workshift.shift_type === 1)
  );

  if (
    lastFirstShift &&
    !firstSecondShift &&
    findShiftByLineAndCar(timetableShifts, lastFirstShift)
  ) {
    // Само първа смяна, съответната втора смяна е намерена другаде
    return [true, rosterShifts];
  } else if (
    firstSecondShift &&
    !lastFirstShift &&
    findShiftByLineAndCar(timetableShifts, firstSecondShift)
  ) {
    // Само втора смяна, съответната първа смяна е намерена другаде
    return [true, rosterShifts];
  } else if (
    firstSecondShift &&
    lastFirstShift &&
    (firstSecondShift.shift.line !== lastFirstShift.shift.line ||
      firstSecondShift.shift.car !== lastFirstShift.shift.car)
  ) {
    // Намерени първа и втора смяна по различна линия и кола
    return [true, rosterShifts];
  }

  return [false, rosterShifts];
}

export function doCorrectCarTimes(
  exportedCar: boolean,
  rosterShifts: readonly TimetableRosterShift[],
  shift1Times?: {
    enterTime?: Moment;
    exitTime?: Moment;
  },
  shift2Times?: {
    enterTime?: Moment;
    exitTime?: Moment;
  }
) {
  if (exportedCar && rosterShifts.length > 0) {
    const lastFirstShift = rosterShifts.find(
      (s, i, ss) =>
        s.shift.workshift.shift_type === 1 &&
        (i >= ss.length - 1 || ss[i + 1].shift.workshift.shift_type === 2)
    );
    const firstSecondShift = rosterShifts.find(
      (s, i, ss) =>
        s.shift.workshift.shift_type === 2 &&
        (i <= 0 || ss[i - 1].shift.workshift.shift_type === 1)
    );

    if (
      lastFirstShift &&
      lastFirstShift.shift.workshift.change_garage_time &&
      shift1Times?.enterTime
    ) {
      shift1Times.enterTime.add(
        lastFirstShift.shift.workshift.change_garage_time,
        "minutes"
      );
    }

    if (
      firstSecondShift &&
      firstSecondShift.shift.workshift.change_garage_time &&
      shift2Times?.exitTime
    ) {
      shift2Times.exitTime.subtract(
        firstSecondShift.shift.workshift.change_garage_time,
        "minutes"
      );
    }
  }
}

function prefixLength(v: string, c?: string): number {
  c = !c ? v[0] || "" : c;
  for (let i = 1; i < v.length; ++i) {
    if (c !== v[i]) {
      return i;
    }
  }
  return v.length;
}

export type JourneyListSummary = {
  line: string;
  car: string;
  shift: string;
  vehicle: string;
  serial: string;
};

export function* processJourneyListSummary(
  data: PageData[]
): Generator<JourneyListSummary> {
  const lines: Record<
    string,
    Record<
      number,
      Record<
        number,
        { shift: PageDataShift["datas"][0]; journeyList: PageData }
      >
    >
  > = {};
  const fake_cars: Record<string, number> = {};
  let serial_cut_start = 1000;

  console.log(data);

  for (const journeyList of data) {
    const shiftDatas = (journeyList.drivers.shift1?.datas || [])
      .concat(journeyList.drivers.shift2?.datas || [])
      .concat(journeyList.drivers.shift3?.datas || []);
    for (const shift of shiftDatas) {
      //console.log("D:", shift);
      const line_key = `${shift.line_order.toString().padStart(6, "0")}-${
        shift.line_name
      }`;

      if (!Object.hasOwn(lines, line_key)) {
        lines[line_key] = {};
      }

      let car_no = shift.car_no;
      if (shift.skip_car) {
        if (!Object.hasOwn(fake_cars, shift.line_name)) {
          fake_cars[shift.line_name] = 0;
        }
        fake_cars[shift.line_name] += 1;
        car_no += fake_cars[shift.line_name];
      }

      if (!Object.hasOwn(lines[line_key], car_no)) {
        lines[line_key][car_no] = {};
      }

      if (!Object.hasOwn(lines[line_key][car_no], shift.shift_no)) {
        lines[line_key][car_no][shift.shift_no] = { shift, journeyList };
        serial_cut_start = Math.min(
          serial_cut_start,
          prefixLength(journeyList.serialNumber, "0")
        );
      }
    }

    if (
      journeyList.drivers.shift1 &&
      shiftDatas.length === 0 &&
      journeyList.isExtraDriver
    ) {
      const line_key = "999999-Стажант";

      if (!Object.hasOwn(lines, line_key)) {
        lines[line_key] = {};
      }

      let car_no = 0;
      if (!Object.hasOwn(fake_cars, "Стажант")) {
        fake_cars["Стажант"] = 0;
      }
      fake_cars["Стажант"] += 1;
      car_no += fake_cars["Стажант"];

      if (!Object.hasOwn(lines[line_key], car_no)) {
        lines[line_key][car_no] = {};
      }

      if (!Object.hasOwn(lines[line_key][car_no], 1)) {
        lines[line_key][car_no][1] = {
          shift: {
            line_name: "Стажант",
            line_order: 999999,
            car_no: car_no,
            skip_car: true,
            shift_no: 1,
          },
          journeyList,
        };
        serial_cut_start = Math.min(
          serial_cut_start,
          prefixLength(journeyList.serialNumber, "0")
        );
      }
    }
  }

  // console.log("L:", lines);

  for (const lidx of sortArray(Object.keys(lines))) {
    const line = lines[lidx];
    for (const car_no of (Object.keys(line) as any as number[]).sort(
      (a, b) => a - b
    )) {
      const car = line[car_no];
      if (
        car[1] &&
        car[2] &&
        car[1].journeyList.serialNumber === car[2].journeyList.serialNumber
      ) {
        yield {
          line: car[1].shift.line_name,
          car: car[1].shift.skip_car ? "" : car[1].shift.car_no.toString(),
          shift: "1",
          vehicle: car[1].journeyList.vehicle?.vehicle_id || "",
          serial: car[1].journeyList.serialNumber.substring(serial_cut_start),
        };
      } else {
        for (const shift_no of (Object.keys(car) as any as number[]).sort(
          (a, b) => a - b
        )) {
          const shift = car[shift_no];
          yield {
            line: shift.shift.line_name,
            car: shift.shift.skip_car ? "" : shift.shift.car_no.toString(),
            shift: shift.journeyList.vehicle?.vehicle_id
              ? shift_no.toString()
              : "",
            vehicle: shift.journeyList.vehicle?.vehicle_id || "",
            serial: shift.journeyList.serialNumber.substring(serial_cut_start),
          };
        }
      }
    }
  }
}
