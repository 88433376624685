import {
  DeleteOutline,
  Done,
  Print,
  PrintOutlined,
  PrintTwoTone,
  SettingsSuggestOutlined,
  Wifi,
  WifiOff,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Portal,
  TableContainer,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../../common/useProfile";
import {
  useGetRosterQuery,
  usePostRosterActionMutation,
} from "../../data/api/hooks";
import { Vehicle } from "../../data/api/types/driver";
import { ApiRoster, RosterAction } from "../../data/api/types/roster";
import {
  ScheduleDriver,
  ScheduleParameters,
} from "../../data/api/types/schedule";
import { RootState } from "../../data/store";
import { useSubmenu } from "../../hooks/useSubmenu";
import { usePrintBlankJourneyList } from "../../printing/usePrintJourneyList";
import { Roster } from "./types/Roster";
import { InterShiftTimeErrorsTable } from "./InterShiftTimeErrorsTable";
import { getSettingsByGarage } from "../../common/useGarageSettings";
import { useDepotFilter } from "../../filters/useDepotFilter";
import { useTransportFilter } from "../../filters/useTransportFilter";

export function getActionText(action: RosterAction) {
  if (action.action === "create") {
    return "Създаден";
  } else if (action.action === "edit") {
    return "Редактиран";
  } else if (action.action === "automatic_edit") {
    return "Променен";
  } else if (action.action === "ready") {
    return "Маркиран като готов";
  } else if (action.action === "gps_export") {
    return "Прехвърлен";
  } else if (
    action.action === "other" &&
    action.sub_action?.startsWith("approve:")
  ) {
    return "Одобрен";
  } else {
    return "Друго действие";
  }
}

export function getUserText(action: RosterAction) {
  if (action.action === "other" && action.sub_action?.startsWith("approve:")) {
    const job = action.sub_action.substring(8);
    switch (job) {
      case "manager":
        return `${action.user?.name} (Директор)`;
      case "assistant_manager":
        return `${action.user?.name} (Зам. Директор)`;
      case "autocolumn_manager":
        return `${action.user?.name} (Началник Автоколона)`;
      default:
        return action.user?.name;
    }
  } else {
    return action.user?.name;
  }
}

export function getRosterStateTitle(roster: ApiRoster) {
  //console.log("getRosterStateTitle");
  const title: string[] = ["Действия:"];

  let i = 0;
  let skip = false;

  while (i < roster.actions.length) {
    if (
      !skip &&
      i > 0 &&
      ["create", "edit", "automatic_edit"].includes(
        roster.actions[i - 1].action
      )
    ) {
      skip = true;
    }

    if (!skip || roster.actions[i].action === "gps_export") {
      title.push(
        `${moment(roster.actions[i].timestamp).format("LLL")}: ${getActionText(
          roster.actions[i]
        )} от ${getUserText(roster.actions[i])}`
      );
    }

    i += 1;
  }

  return title.filter((l) => !!l).join("\n");
}

function isApproved(
  roster: ApiRoster,
  profile: NonNullable<ReturnType<typeof useProfile>>
) {
  let i = 0;
  while (
    i < roster.actions.length &&
    (i === 0 ||
      !["create", "edit", "automatic_edit"].includes(
        roster.actions[i - 1].action
      ))
  ) {
    if (
      roster.actions[i].action === "other" &&
      roster.actions[i].sub_action?.startsWith("approve:")
    ) {
      const job = roster.actions[i].sub_action?.substring(8) || "";
      if (
        roster.actions[i].user?.sub === profile.sub &&
        profile.roles.includes(job)
      ) {
        return true;
      }
    }
    i += 1;
  }

  return false;
}

function isReady(roster: ApiRoster) {
  let i = 0;
  while (
    i < roster.actions.length &&
    (i === 0 ||
      !["create", "edit", "automatic_edit"].includes(
        roster.actions[i - 1].action
      ))
  ) {
    if (roster.actions[i].action === "ready") {
      return true;
    }
    i += 1;
  }

  return false;
}

export function ApprovalSubmenu({
  scheduleParameters,
  selectedDate,
  hasErrors,
}: {
  scheduleParameters: ScheduleParameters | null;
  selectedDate: string;
  hasErrors: boolean;
}) {
  const profile = useProfile();

  const roster = useGetRosterQuery(
    {
      transport: scheduleParameters?.[0] || "A",
      depot_id: scheduleParameters?.[1] || 0,
      autocolumn: scheduleParameters?.[4] || 1,
      date: selectedDate,
    },
    {
      skip: !scheduleParameters,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: 5000,
    }
  );

  const [markReady, markReadyStatus] = usePostRosterActionMutation();
  const markReadyProps = useMemo(() => {
    const isManager =
      profile?.roles.includes("autocolumn_manager") ||
      profile?.roles.includes("assistant_manager") ||
      profile?.roles.includes("manager");

    const title = isManager ? "Одобри" : "Маркирай като готов";
    let color = "inherit";

    if (isManager) {
      if (
        roster.isSuccess &&
        roster.currentData &&
        roster.currentData.data.status === "ready"
      ) {
        color = green[100];
      } else if (
        roster.isSuccess &&
        roster.currentData &&
        roster.currentData.data.status === "approved"
      ) {
        color = green[100];
      } else {
        color = red[100];
      }
    } else {
      color = hasErrors ? red[100] : green[100];
    }

    return {
      endIcon: <Done />,
      children: title,
      title: `${title}${roster.isSuccess ? "\n\n" : ""}${
        roster.isSuccess && roster.currentData
          ? getRosterStateTitle(roster.currentData.data)
          : ""
      }`,
      sx: { mr: 4, color: color },
      disabled:
        !profile ||
        !roster.isSuccess ||
        !roster.currentData ||
        !scheduleParameters ||
        markReadyStatus.isLoading ||
        roster.currentData.data.actions.length === 0 ||
        (!isManager && !profile.roles.includes("export_roster")) ||
        (isManager && isApproved(roster.currentData.data, profile)) ||
        (!isManager && isReady(roster.currentData.data)),
    };
  }, [
    profile,
    roster.isSuccess,
    roster.currentData,
    scheduleParameters,
    markReadyStatus.isLoading,
    hasErrors,
  ]);

  const onClickMarkReady = useCallback(() => {
    if (scheduleParameters) {
      if (
        profile?.roles.includes("autocolumn_manager") ||
        profile?.roles.includes("assistant_manager") ||
        profile?.roles.includes("manager")
      ) {
        markReady({
          transport: scheduleParameters[0],
          depot_id: scheduleParameters[1],
          autocolumn: scheduleParameters[4],
          date: selectedDate,
          action: "other",
          sub_action: `approve:${
            profile?.roles.includes("manager")
              ? "manager"
              : profile?.roles.includes("assistant_manager")
              ? "assistant_manager"
              : profile?.roles.includes("autocolumn_manager")
              ? "autocolumn_manager"
              : "unknown"
          }`,
          comment: null,
        });
      } else {
        markReady({
          transport: scheduleParameters[0],
          depot_id: scheduleParameters[1],
          autocolumn: scheduleParameters[4],
          date: selectedDate,
          action: "ready",
          sub_action: null,
          comment: null,
        });
      }
    }
  }, [markReady, profile?.roles, scheduleParameters, selectedDate]);

  const stateTitle = useMemo(
    () =>
      roster.isSuccess && roster.currentData
        ? getRosterStateTitle(roster.currentData.data)
        : "",
    [roster.currentData, roster.isSuccess]
  );

  return (
    <>
      {roster.isSuccess && roster.currentData ? (
        <Typography sx={{ mr: 4 }} title={stateTitle}>{`Състояние: ${
          roster.currentData.data.status === "not-ready"
            ? "Работна версия"
            : roster.currentData.data.status === "edited"
            ? "Редактиран"
            : roster.currentData.data.status === "ready"
            ? "Готов"
            : roster.currentData.data.status === "approved"
            ? "Одобрен"
            : roster.currentData.data.status === "exported"
            ? "Прехвърлен"
            : "Неизвестно"
        }`}</Typography>
      ) : (
        <></>
      )}

      <Button color="inherit" onClick={onClickMarkReady} {...markReadyProps} />
    </>
  );
}

export function RosterTopMenu({
  isReadOnly,
  scheduleParameters,
  selectedDate,
  cleanupRoster,
  drivers,
  extraDrivers,
  errorIntershiftTimes,
  getRoster,
}: {
  isReadOnly: boolean;
  scheduleParameters: ScheduleParameters | null;
  selectedDate: string;
  cleanupRoster: () => void;
  drivers: readonly ScheduleDriver[];
  extraDrivers: ScheduleDriver[];
  apiVehicles: readonly Vehicle[];
  errorIntershiftTimes: readonly number[];
  getRoster: () => Roster;
}) {
  const { rightToolbarMenu, belowSubmenu } = useSubmenu();

  const isChannelConnected = useSelector(
    (state: RootState) =>
      state.schedule.socketConnected && state.schedule.scheduleConnected
  );

  const exportJourneyLists = usePrintBlankJourneyList();
  const [resterrors, setResterrors] = useState(false);

  const { selectedDepot } = useDepotFilter();
  const { selectedTransport } = useTransportFilter();
  const garageSettings = getSettingsByGarage(selectedTransport, selectedDepot);

  return (
    <>
      <Portal container={belowSubmenu}>
        {resterrors && (
          <TableContainer component={Box}>
            <Alert
              severity="info"
              variant="standard"
              onClose={() => setResterrors(false)}
              sx={{
                px: 4,
                py: 0,
                borderRadius: 0,
              }}
            >
              <span style={{ whiteSpace: "normal" }}>
                {errorIntershiftTimes.length > 0
                  ? "Нарушени междусменни почивки"
                  : "Няма нарушени междусменни почивки"}
              </span>
            </Alert>
            {errorIntershiftTimes.length > 0 && (
              <InterShiftTimeErrorsTable results={errorIntershiftTimes} />
            )}
          </TableContainer>
        )}
      </Portal>

      <Portal container={rightToolbarMenu}>
        <ApprovalSubmenu
          scheduleParameters={scheduleParameters}
          selectedDate={selectedDate}
          hasErrors={false}
        />

        <IconButton
          color="inherit"
          size="large"
          aria-label="Грешки"
          title="Нарушени междусменни почивки"
          disabled={!scheduleParameters || exportJourneyLists.isLoading}
          onClick={() => {
            setResterrors(true);
          }}
        >
          <SettingsSuggestOutlined />
        </IconButton>

        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт на пътен лист в PDF"
          title="Експорт на пътен лист в PDF"
          disabled={!scheduleParameters || exportJourneyLists.isLoading}
          onClick={() => {
            const { vehicles, allShifts } = getRoster();
            exportJourneyLists.exportPdf(
              vehicles,
              drivers,
              extraDrivers,
              allShifts,
              selectedDate
            );
          }}
        >
          {exportJourneyLists.isLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>

        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт на празен пътен лист в PDF"
          title="Експорт на празен пътен лист в PDF"
          disabled={!scheduleParameters || exportJourneyLists.isLoading}
          onClick={() => {
            exportJourneyLists.exportBlankPdf(selectedDate);
          }}
        >
          {exportJourneyLists.isLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <PrintOutlined />
          )}
        </IconButton>

        {garageSettings.printPowerConsumption && (
          <IconButton
            color="inherit"
            size="large"
            aria-label="Експорт на празен пътен лист с показания за изразходвана електроенергия в PDF"
            title="Експорт на празен пътен лист с показания за изразходвана електроенергия в PDF"
            disabled={!scheduleParameters || exportJourneyLists.isLoading}
            onClick={() => {
              exportJourneyLists.exportBlankPdf(selectedDate, true);
            }}
          >
            {exportJourneyLists.isLoading ? (
              <CircularProgress
                size="0.75em"
                sx={{ color: (theme) => theme.palette.grey[100] }}
              />
            ) : (
              <PrintTwoTone />
            )}
          </IconButton>
        )}

        <IconButton
          color="inherit"
          size="large"
          aria-label="Изтрий данни от наряд"
          title="Изтрий данни от наряд"
          disabled={isReadOnly || !scheduleParameters}
          onClick={cleanupRoster}
        >
          <DeleteOutline />
        </IconButton>

        {isChannelConnected ? (
          <Wifi sx={{ color: green[200] }} titleAccess="Има връзка" />
        ) : (
          <WifiOff sx={{ color: red[200] }} titleAccess="Няма връзка" />
        )}
      </Portal>
    </>
  );
}
