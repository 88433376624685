import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

export function GenericCommentDialog({
  open,
  initialValue,
  heading,
  label,
  onClose,
  onSave,
}: {
  open?: boolean;
  initialValue?: string | null;
  heading?: string;
  label?: string;
  onClose?: () => void;
  onSave?: (comment: string | null) => Promise<void>;
}) {
  const title =
    initialValue && initialValue.trim() !== ""
      ? "Промяна на коментар"
      : "Добавяне на коментар";

  const [comment, setComment] = useState<string>(initialValue || "");
  const [commentErrors, setCommentErrors] = useState<string | null>(null);
  const [commentBusy, setCommentBusy] = useState<boolean>(false);

  useEffect(() => {
    setComment(initialValue || "");
  }, [initialValue]);

  const handleClose = useCallback(() => {
    if (!commentBusy) {
      setComment("");
      setCommentErrors(null);
      setCommentBusy(false);
      onClose && onClose();
    }
  }, [commentBusy, onClose]);

  const handleSave = useCallback(async () => {
    if (!commentBusy && onSave) {
      try {
        setCommentErrors(null);
        setCommentBusy(true);
        await onSave(comment && comment.trim() !== "" ? comment : null);
        handleClose();
      } catch {
        setCommentErrors("Грешка при запис на коментар");
      } finally {
        setCommentBusy(false);
      }
    } else {
      handleClose();
    }
  }, [comment, commentBusy, handleClose, onSave]);

  return (
    <form>
      <Dialog
        open={!!open}
        onClose={onClose}
        fullWidth
        //onBackdropClick={(e) => {
        //  if (commentBusy) {
        //    e.preventDefault();
        //  }
        //}}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {heading && heading.trim() !== "" ? heading : title}
          </DialogContentText>
          <TextField
            autoFocus
            multiline
            required
            minRows={5}
            margin="dense"
            id="name"
            label={label || "Коментар"}
            type="email"
            fullWidth
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={commentErrors !== null}
            disabled={commentBusy}
          />
          {commentErrors && (
            <DialogContentText color="error">{`Грешка: ${commentErrors}`}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={commentBusy}>
            Отказ
          </Button>
          <Button onClick={handleSave} type="submit" disabled={commentBusy}>
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
