import { forwardRef } from "react";
import { NavLink as NavLinkBase, NavLinkProps } from "react-router-dom";

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => (
    <NavLinkBase
      ref={ref}
      {...props}
      className={({ isActive }) =>
        `${props.className} ${isActive ? "Mui-selected" : ""}`
      }
    />
  )
);
NavLink.displayName = "NavLink";
