import moment, { Moment } from "moment";
import {
  useGetExplPlanQuery,
  useGetLineListQuery,
  useLazyGetLineTimetableListQuery,
  usePostExplPlanImportMutation,
  usePutExplCorrectionMutation,
} from "../../data/api/hooks";
import { Transport } from "../../data/api/types/driver";
import { useMemo } from "react";
import { ExplPLanParameters } from "../../data/api/types/exploatation_plan";

export function useExplotationPlan(
  selectedTransport: Transport | "",
  selectedDepot: number,
  selectedMonth: Moment | null
  // startDate: Moment,
  // endDate: Moment
) {
  const explPlanParams = useMemo<ExplPLanParameters>(
    () =>
      selectedTransport !== "" && selectedDepot >= 0 && selectedMonth
        ? [
            selectedTransport,
            selectedDepot,
            moment(selectedMonth).startOf("month").format("YYYY-MM-DD"),
            moment(selectedMonth).endOf("month").format("YYYY-MM-DD"),
          ]
        : ["", -1, "", ""],
    [selectedTransport, selectedDepot, selectedMonth]
  );

  const ExplPLan = useGetExplPlanQuery(explPlanParams, {
    refetchOnMountOrArgChange: true,
    skip: !(
      explPlanParams[1] !== -1 &&
      explPlanParams[2] !== "" &&
      explPlanParams[3] !== ""
    ),
  });

  const lines = useGetLineListQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      day_date: moment(selectedMonth).startOf("month").format("YYYY-MM-DD"),
    },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const [loadTimetables, timetables] = useLazyGetLineTimetableListQuery();

  const [updateExplPlan, resultUpdateExplPlan] = usePutExplCorrectionMutation();

  const [importExplPlan, resultImportExplPlan] =
    usePostExplPlanImportMutation();

  const isLoading =
    ExplPLan?.isLoading ||
    ExplPLan?.isFetching ||
    lines?.isLoading ||
    lines?.isFetching ||
    timetables?.isLoading ||
    timetables?.isFetching ||
    resultUpdateExplPlan?.isLoading ||
    resultImportExplPlan?.isLoading;

  const isError =
    ExplPLan?.isError ||
    lines?.isError ||
    timetables?.isError ||
    resultUpdateExplPlan?.isError ||
    resultImportExplPlan?.isError;

  const lineList = useMemo(
    () =>
      lines && lines.data
        ? lines.data
            .filter((l) => l.razpcad_line_id !== null)
            .sort((a, b) =>
              a.line_order !== 0 && a.line_order > b.line_order ? 1 : -1
            )
            .map((l) => ({
              label: l.name,
              id: l.razpcad_line_id,
            }))
        : [],
    [lines]
  );
  return {
    lineList,
    ExplPLan,
    lines,
    explPlanParams,
    loadTimetables,
    timetables,
    isLoading,
    importExplPlan,
    resultImportExplPlan,
    updateExplPlan,
    isError,
  };
}
