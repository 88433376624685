import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { forwardRef } from "react";
import { createTheme } from "@mui/material";
import { blue, deepOrange, grey, orange, red } from "@mui/material/colors";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = "LinkBehavior";

export const theme = createTheme({
  typography: {
    fontSize: 12,
  },
  spacing: 4,
  palette: {
    primary: blue,
    secondary: orange,
    schedule: {
      shift1: blue[100],
      shift1Text: grey[900],
      fixedShift1: blue[100],
      fixedShift1Text: red[700],
      shift2: blue[200],
      shift2Text: grey[900],
      fixedShift2: blue[200],
      fixedShift2Text: red[700],
      rest: orange[100],
      restText: grey[900],
      fixedRest: orange[100],
      fixedRestText: red[700],
      automaticRest: orange[100],
      automaticRestText: grey[600],
      leave: deepOrange[200],
      leaveText: grey[900],
      fixedLeave: deepOrange[200],
      fixedLeaveText: red[700],
      unpaidLeave: deepOrange[200],
      unpaidLeaveText: grey[900],
      fixedUnpaidLeave: deepOrange[200],
      fixedUnpaidLeaveText: red[700],
      sickLeave: deepOrange[200],
      sickLeaveText: grey[900],
      leaveRest: deepOrange[200],
      leaveRestText: grey[900],
      intern: deepOrange[400],
      internText: grey[100],
      notWorking: red["A700"],
      notWorkingText: grey[700],
      realNoShow: deepOrange[900],
      realNoShowText: grey[100],
    },
  },
  components: {
    MuiCssBaseline: {},
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
