import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  MessageDriver,
  Schedule,
  ScheduleDriver,
  ScheduleParameters,
} from "./api/types/schedule";
import moment from "moment";
import { processUpdate } from "./schedules/processUpdate";
import {
  SchedulesChannelCallbackFunctions,
  VehiclesChannelCallbackFunctions,
} from "./schedules/socketFunctions";
import { VehiclesSchedule } from "./api/types/vehiclesSchedule";

export type ScheduleState = {
  selection: {
    row: number;
    startCell: number;
    endCell: number;
    initialMouseCoordinates: { x: number; y: number };
  } | null;
  ignoredSelection: boolean;
  isFocused: boolean;

  daysInMonth: number[];
  schedule: Schedule | undefined;

  explPlanVersion: number;

  schedulesChannelId: string | null;
  schedulesChannelCallbacks: SchedulesChannelCallbackFunctions;

  vehiclesSchedule: VehiclesSchedule | undefined;
  vehiclesChannelId: string | null;
  vehiclesChannelCallbacks: VehiclesChannelCallbackFunctions;

  socketConnected: boolean;
  scheduleConnected: boolean;
  vehiclesScheduleConnected: boolean;

  selectedStatesFilter: Record<string, readonly number[]>;
};

const initialState: ScheduleState = {
  selection: null,
  ignoredSelection: false,
  isFocused: false,

  daysInMonth: [],
  schedule: undefined,

  explPlanVersion: 1,

  schedulesChannelId: null,
  schedulesChannelCallbacks: {
    startPlanner: () => Promise.resolve({ status: "ok" }),
    stopPlanner: () => Promise.resolve({ status: "ok" }),
    saveSchedule: () => Promise.resolve({ status: "ok" }),
    cleanupSchedule: () => Promise.resolve({ status: "ok" }),
    markReady: () => Promise.resolve({ status: "ok" }),
    reimportAllData: () => Promise.resolve({ status: "ok" }),
    addDriverToVehicle: () => Promise.resolve({ status: "ok" }),
    changeTaskVehicle: () => Promise.resolve({ status: "ok" }),
    addNewDriverToVehicle: () => Promise.resolve({ status: "ok" }),
    removeDriverFromVehicle: () => Promise.resolve({ status: "ok" }),
    addShiftToVehicle: () => Promise.resolve({ status: "ok" }),
    removeShiftFromVehicle: () => Promise.resolve({ status: "ok" }),
    editDriverDayRosterComment: () => Promise.resolve({ status: "ok" }),
    clearDriverTasks: () => Promise.resolve({ status: "ok" }),
    createRoster: () => Promise.resolve({ status: "ok" }),
    updateDriverState: () => Promise.resolve({ status: "ok" }),
    updateAccessToken: () => Promise.resolve({ status: "ok" }),
    editDriverDayComment: () => Promise.resolve({ status: "ok" }),
    electroAddVehicleToShift: () => Promise.resolve({ status: "ok" }),
    electroAddDriverToShift: () => Promise.resolve({ status: "ok" }),
    electroRemoveVehicleFromShift: () => Promise.resolve({ status: "ok" }),
    electroRemoveDriverFromShift: () => Promise.resolve({ status: "ok" }),
  },

  vehiclesSchedule: undefined,
  vehiclesChannelId: null,
  vehiclesChannelCallbacks: {
    updateAccessToken: () => Promise.resolve({ status: "ok" }),
    addVehicleInterval: () => Promise.resolve({ status: "ok" }),
    editVehicleDayComment: () => Promise.resolve({ status: "ok" }),
  },

  socketConnected: false,
  scheduleConnected: false,
  vehiclesScheduleConnected: false,

  selectedStatesFilter: {},
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    startScheduleSelection: (
      state,
      action: PayloadAction<
        Omit<NonNullable<ScheduleState["selection"]>, "endCell">
      >
    ) => {
      state.selection = {
        ...action.payload,
        endCell: action.payload.startCell,
      };
    },
    clearScheduleSelection: (state) => {
      state.selection = null;
    },
    focusSheduleTable: (state) => {
      state.isFocused = true;
    },
    blurSheduleTable: (state) => {
      state.isFocused = false;
    },
    setScheduleSelection: (
      state,
      action: PayloadAction<{
        row: number;
        startCell: number;
        endCell: number;
        initialMouseCoordinates: {
          x: number;
          y: number;
        };
      } | null>
    ) => {
      state.selection = action.payload;
      state.ignoredSelection = false;
    },
    updateScheduleSelection: (
      state,
      action: PayloadAction<{
        x: number;
        y: number;
        initialX: number;
        initialY: number;
      }>
    ) => {
      if (state.selection && !state.ignoredSelection) {
        //console.log("Drag:", action.payload, state.selection.startCell);
        state.selection.endCell = state.selection.startCell + action.payload.x;
      }
    },
    setIgnoredSelection: (state, action: PayloadAction<boolean>) => {
      state.ignoredSelection = action.payload;
    },

    setDaysInMonth: (state, action: PayloadAction<number[]>) => {
      state.daysInMonth = action.payload;
    },
    setSchedule: (state, action: PayloadAction<Schedule | undefined>) => {
      state.schedule = action.payload;
    },

    schedulesChannelJoined: (
      state,
      action: PayloadAction<{ msg: any; parameters: ScheduleParameters | null }>
    ) => {
      const { msg, parameters } = action.payload;

      if (parameters) {
        const msgDrivers: MessageDriver[] = msg.drivers;
        const drivers: Record<string, ScheduleDriver> = {};
        // const isPreliminary = msg?.db_schedule?.preliminary_ready !== true;

        for (const { driver_days, ...driver } of msgDrivers || []) {
          const objDriverDays = Object.fromEntries(
            driver_days.map((d) => {
              return [d.day_in_month, d];
            })
          );
          drivers[driver.id] = {
            ...driver,
            driver_days: objDriverDays,
          };
        }
        msg.drivers = drivers;

        state.daysInMonth = Array.from(
          new Array(
            moment(`${parameters[2]}-${parameters[3]}`, "YYYY-MM").daysInMonth()
          ).keys()
        );
        state.schedule = msg;

        console.log("Channel joined:", msg);
      } else {
        console.error("Join message without parameters, ignored");
      }
    },

    schedulesUpdateReceived: (state, action: PayloadAction<any>) => {
      console.debug("Received update:", action.payload);
      if (state.schedule) {
        const old_expl_plan_status = state.schedule?.expl_plan_status?.status;
        state.schedule = processUpdate(action.payload, state.schedule);
        if (
          old_expl_plan_status !== "ok" &&
          state.schedule?.expl_plan_status?.status === "ok"
        ) {
          //console.log("Expl plan loaded");
          state.explPlanVersion += 1;
        }
      }
    },

    setSchedulesChannelId: (state, action: PayloadAction<string | null>) => {
      state.schedulesChannelId = action.payload;
    },

    setSchedulesChannelCallbacks: (
      state,
      action: PayloadAction<SchedulesChannelCallbackFunctions>
    ) => {
      state.schedulesChannelCallbacks = action.payload;
    },

    setVehiclesChannelId: (state, action: PayloadAction<string | null>) => {
      state.vehiclesChannelId = action.payload;
    },

    setVehiclesChannelCallbacks: (
      state,
      action: PayloadAction<VehiclesChannelCallbackFunctions>
    ) => {
      state.vehiclesChannelCallbacks = action.payload;
    },

    setVehiclesSchedule: (
      state,
      action: PayloadAction<VehiclesSchedule | undefined>
    ) => {
      state.vehiclesSchedule = action.payload;
    },

    vehiclesChannelJoined: (
      state,
      action: PayloadAction<{ msg: any; parameters: ScheduleParameters | null }>
    ) => {
      const { msg, parameters } = action.payload;

      if (parameters) {
        console.log("Vehicles channel joined:", msg);
        const vehicles: VehiclesSchedule["vehicles"] = {};
        for (const vehicle of msg.vehicles) {
          const days: VehiclesSchedule["vehicles"][0]["days"] = {};
          for (const day of vehicle.days) {
            days[day.date] = day;
          }
          vehicles[vehicle.vehicle_id] = {
            ...vehicle,
            days,
          };
        }

        state.vehiclesSchedule = {
          ...msg,
          vehicles,
        };
      } else {
        console.error("Vehicles join message without parameters, ignored");
      }
    },

    vehiclesUpdateReceived: (state, action: PayloadAction<any>) => {
      console.debug("Received vehicles update:", action.payload);
      if (state.vehiclesSchedule) {
        if (action.payload.type === "update") {
          if (action.payload.vehicles) {
            for (const { days: updatedDays, ...vehicle } of action.payload
              .vehicles) {
              const days =
                state.vehiclesSchedule.vehicles[vehicle.vehicle_id].days || {};
              for (const day of updatedDays) {
                days[day.date] = {
                  ...days[day.date],
                  ...day,
                };
              }
              state.vehiclesSchedule.vehicles[vehicle.vehicle_id] = {
                ...state.vehiclesSchedule.vehicles[vehicle.vehicle_id],
                ...vehicle,
                days,
              };
            }
          }
        } else if (action.payload.type === "delete") {
          for (const vehicleId of action.payload.vehicle_ids) {
            delete state.vehiclesSchedule.vehicles[vehicleId];
          }
        }
      }

      console.log("Updated vehicles schedule:", state.vehiclesSchedule);
    },

    setSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.socketConnected = action.payload;
      if (!action.payload) {
        state.scheduleConnected = action.payload;
        state.vehiclesScheduleConnected = action.payload;
      }
    },
    setScheduleConnected: (state, action: PayloadAction<boolean>) => {
      state.scheduleConnected = action.payload;
    },
    setVehiclesScheduleConnected: (state, action: PayloadAction<boolean>) => {
      state.vehiclesScheduleConnected = action.payload;
    },

    toggleSelectedStateFilter: (
      state,
      action: PayloadAction<{ date: string; state_id: number }>
    ) => {
      if (!state.selectedStatesFilter[action.payload.date]) {
        state.selectedStatesFilter[action.payload.date] = [
          action.payload.state_id,
        ];
      } else if (
        state.selectedStatesFilter[action.payload.date].includes(
          action.payload.state_id
        )
      ) {
        state.selectedStatesFilter[action.payload.date] =
          state.selectedStatesFilter[action.payload.date].filter(
            (s) => s !== action.payload.state_id
          );
        if (state.selectedStatesFilter[action.payload.date].length === 0) {
          delete state.selectedStatesFilter[action.payload.date];
        }
      } else {
        state.selectedStatesFilter[action.payload.date].push(
          action.payload.state_id
        );
      }
    },

    clearSelectedStateFilter: (
      state,
      action: PayloadAction<{ date: string }>
    ) => {
      state.selectedStatesFilter[action.payload.date] = [];
    },
  },
});

export const {
  startScheduleSelection,
  clearScheduleSelection,
  setScheduleSelection,
  updateScheduleSelection,
  setIgnoredSelection,
  focusSheduleTable,
  blurSheduleTable,
  setDaysInMonth,
  setSchedule,
  schedulesChannelJoined,
  schedulesUpdateReceived,
  setSchedulesChannelId,
  setSchedulesChannelCallbacks,
  setVehiclesChannelId,
  setVehiclesChannelCallbacks,
  setVehiclesSchedule,
  vehiclesChannelJoined,
  vehiclesUpdateReceived,
  setSocketConnected,
  setScheduleConnected,
  setVehiclesScheduleConnected,
  toggleSelectedStateFilter,
  clearSelectedStateFilter,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
