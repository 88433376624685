import { createApi } from "@reduxjs/toolkit/query/react";
import { Moment } from "moment";
import type { Driver, DriverList, DriverSkills, Vehicle } from "./types/driver";
import { ExploatationPlanDict } from "./types/exploatation_plan";
import {
  MessageSchedule,
  ScheduleParameters,
  sortArray,
  Transport,
} from "./types/schedule";
import { ExplPLanParameters } from "./types/exploatation_plan";
import { Line } from "./types/line";
import { Timetable } from "./types/timetable";
import {
  Schedule_SpecialShifts,
  SpecialShift,
} from "../../pages/nomenclatures/useSpecialShift";
import {
  DayExplPlan,
  DayExplPlanRoster,
  LineTimetable,
} from "../../pages/reports/useTimetables_ByDate";
import { DriverPersonalAccount } from "../../pages/reports/useDriverPersonalAccount";
import moment from "moment";
import { ScheduleReportData } from "../../pages/reports/schedule_recapitulation/useScheduleRecapitulation";
import { commonApiParameters, createBaseQuery } from "./commonApiFunctions";
import { ScheduleReportHistoryData } from "../../pages/reports/schedule_history/useSchedule_history";
import { ScheduleVehicle } from "./types/vehiclesSchedule";
import {
  UnprocessedApiRoster,
  RosterActionType,
  ApiRoster,
  processApiRoster,
  processApiRosterCollection,
  ApiRosterCollection,
} from "./types/roster";
import { NonWorkDayTaskGPS } from "../../pages/reports/roster_report/useExportGPS";
import { DayRosterData } from "../../pages/reports/roster_report/helpers";
import { FakeSchedule } from "./types";
import {
  RecommendationRequest,
  RecommendationResponse,
} from "./recommendationsApi";
import { PlannerSettings } from "../../common/useGarageSettings";

export type DaytaskStateItem = {
  abbrev: string;
  available: boolean;
  category: number | null;
  description: string;
  hard: boolean;
  score: number;
  state_id: number;
};

export const uiApi = createApi({
  reducerPath: "uiApi",
  baseQuery: createBaseQuery(import.meta.env.VITE_API_BASE),
  ...commonApiParameters,
  tagTypes: [
    "ExplPlan",
    "Drivers",
    "Vehicles",
    "ScheduleSpecialShifts",
    "Timetables_car_vt",
    "Timetables_car_ac",
    "ApiRosters",
    "ApiRoster",
    "FakeSchedule",
  ],

  endpoints: (builder) => ({
    getAllDrivers: builder.query<{ data: readonly Driver[] }, void>({
      query: () => "drivers",
    }),

    getDriversList: builder.query<
      DriverList,
      {
        transport: Transport | "";
        garage: number;
      }
    >({
      query: ({ transport, garage }) => `drivers_ext/${transport}/${garage}`,

      providesTags: ["Drivers"],
    }),

    putDriverCorrection: builder.mutation<
      { status: string },
      {
        transport: Transport | "";
        gps_depot_id: number;
        driver_id: number;
        work_by_schedule: boolean;
        preferred_vehicle: string | undefined;
        preferred_workshift: number | null;
        vehicletypes_skills: DriverSkills;
        color_id: number;
        license_expire: string | null;
        comptenence_expire: string | null;
        psiho_test_expire: string | null;
        no_overtime: boolean;
        quota_override: string | null;
        rest_on_weekends: boolean;
      }
    >({
      query: (payload) => ({
        url: `drivers_ext/${payload.transport}/${payload.gps_depot_id}/${payload.driver_id}`,
        method: "PUT",
        body: {
          work_by_schedule: payload.work_by_schedule,
          preferred_vehicle: payload.preferred_vehicle,
          preferred_workshift: payload.preferred_workshift,
          vehicletypes_skills: payload.vehicletypes_skills,
          color_id: payload.color_id,
          license_expire: payload.license_expire,
          comptenence_expire: payload.comptenence_expire,
          psiho_test_expire: payload.psiho_test_expire,
          no_overtime: payload.no_overtime,
          quota_override: payload.quota_override,
          rest_on_weekends: payload.rest_on_weekends,
        },
        headers: [],
      }),
      invalidatesTags: ["Drivers"],
    }),

    putVehicleCorrection: builder.mutation<
      { status: string },
      {
        transport: Transport | "";
        gps_depot_id: number;
        vehicle_id: number;
        autocolumn: number | null;
        periodic_tech_test: string | null;
      }
    >({
      query: (payload) => ({
        url: `vehicles/${payload.transport}/${payload.gps_depot_id}/${payload.vehicle_id}`,
        method: "PUT",
        body: {
          autocolumn: payload.autocolumn,
          periodic_tech_test: payload.periodic_tech_test,
        },
        headers: [],
      }),
      invalidatesTags: ["Vehicles"],
    }),

    getExplPlan: builder.query<ExploatationPlanDict, ExplPLanParameters>({
      query: (param) =>
        `expl_plan/${param[0]}/${param[1]}?start_date=${encodeURIComponent(
          param[2]
        )}&end_date=${encodeURIComponent(param[3])}`,
      providesTags: ["ExplPlan"],
    }),

    getLineList: builder.query<
      Line[],
      {
        transport: Transport | "";
        garage: number;
        day_date: string;
      }
    >({
      query: ({ transport, garage, day_date }) =>
        `lines/${transport}/${garage}?date=${encodeURIComponent(day_date)}`,
    }),

    getLineTimetableList: builder.query<
      Timetable[],
      {
        transport: Transport;
        garage: number;
        line_id: number;
      }
    >({
      query: ({ transport, garage, line_id }) =>
        `timetables/${transport}/${garage}?line_id=${line_id}`,
    }),

    getDepotList: builder.query<
      {
        transport: Transport;
        gps_depot_id: number;
        name: string;
        driver_const: number;
      }[],
      Transport | ""
    >({
      query: (transport) => `/depots/${transport || "UNK"}`,
    }),

    getDaytaskStateList: builder.query<
      Record<number, DaytaskStateItem>,
      Transport | ""
    >({
      query: (transport) => `/drivers_daytask_states/${transport || "UNK"}`,
      // Ако state_id === 15 (Почивни дни между Б, О и НО) категорията се променя ръчно на 3 - Почивка
      transformResponse: (response: { data: DaytaskStateItem[] }) =>
        Object.fromEntries(
          response.data.map((s) =>
            s.state_id === 15
              ? [s.state_id, { ...s, category: 3 }]
              : [s.state_id, s]
          )
        ),
    }),

    getVehicleTypeList: builder.query<
      Record<
        number,
        {
          id: number;
          description: string;
          koef: number;
          places: number;
          energy_meter: boolean | null | undefined;
        }
      >,
      Transport | ""
    >({
      query: (transport) => `/vehicletypes/${transport || "UNK"}`,
      transformResponse: (
        response: {
          id: number;
          description: string;
          koef: number;
          places: number;
          energy_meter: boolean | null | undefined;
        }[]
      ) =>
        Object.fromEntries(
          response.map((r) => [
            r.id,
            { ...r, description: r.description.trim() },
          ])
        ),
    }),

    getExplPlanCalendar: builder.query<
      Record<string, { day_type: 0 | 1 | 2; national_holiday: boolean }>,
      { start_date: string; end_date: string }
    >({
      query: ({ start_date, end_date }) =>
        `/expl_plan_calendar/A?start_date=${encodeURIComponent(
          start_date
        )}&end_date=${encodeURIComponent(end_date)}`,
    }),

    putExplCorrection: builder.mutation<
      { status: string; data: Array<LineTimetable> },
      {
        transport: Transport | "";
        gps_depot_id: number;
        line_id: number;
        timetables: { razpcad_tt_id: number | null; date: Moment }[];
      }
    >({
      query: (payload) => ({
        url: `expl_plan/${payload.transport}/${payload.gps_depot_id}`,
        method: "PUT",
        body: {
          razpcad_line_id: payload.line_id,
          timetables: payload.timetables,
        },
        headers: [],
      }),
      transformResponse: (response: {
        status: string;
        data: Array<LineTimetable>;
      }) => {
        response.data.forEach((r) =>
          r.cars.forEach((c) =>
            c.workshifts.forEach(
              (w) => (w.operate = w.autocolumn > 0 || !w.autocolumn)
            )
          )
        );
        return response;
      },
      invalidatesTags: ["ExplPlan"],
    }),

    postExplPlanImport: builder.mutation<
      { status: string },
      {
        transport: Transport | "";
        gps_depot_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      query: (payload) => ({
        url: `expl_plan/${payload.transport}/${payload.gps_depot_id}`,
        method: "POST",
        body: { start_date: payload.start_date, end_date: payload.end_date },
        headers: [],
      }),
      invalidatesTags: ["ExplPlan"],
    }),

    getScheduleSpecialShifts: builder.query<
      Schedule_SpecialShifts,
      {
        transport: Transport | "";
        garage: number;
        year: number;
        month: number;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `schedule_special_shifts/${param.transport}/${param.garage}/${param.year}/${param.month}/${param.avtocolona}`,
      providesTags: ["ScheduleSpecialShifts"],
    }),

    postScheduleShifts: builder.mutation<
      { status: string },
      {
        transport: Transport | "";
        gps_depot_id: number;
        month: number;
        year: number;
        avtovolona: number;
        day_in_month: string;
        special_shift_id: number;
        count: number;
      }
    >({
      query: (payload) => ({
        url: `schedule_special_shifts/${payload.transport}/${payload.gps_depot_id}/${payload.year}/${payload.month}/${payload.avtovolona}`,
        method: "POST",
        body: {
          day_in_month: payload.day_in_month,
          special_shift_id: payload.special_shift_id,
          count: payload.count,
        },
        headers: [],
      }),
      invalidatesTags: ["ScheduleSpecialShifts"],
    }),

    postSpecialShift: builder.mutation<
      { status: string },
      {
        shift: SpecialShift;
      }
    >({
      query: (payload) => ({
        url: `special_shifts/${payload.shift.transport}/${payload.shift.depot_id}/${payload.shift.autocolumn}`,
        method: "POST",
        body: {
          description: payload.shift.description,
          start_time: payload.shift.start_time,
          end_time: payload.shift.end_time,
          shift_type: payload.shift.shift_type,
          validity_start: payload.shift.validity_start,
          validity_end: payload.shift.validity_end,
          auto_fill: payload.shift.auto_fill,
          auto_fill_count: payload.shift.auto_fill_count,
        },
        headers: [],
      }),
      invalidatesTags: ["ScheduleSpecialShifts"],
    }),

    postTimetableVehicleType: builder.mutation<
      { status: string },
      {
        transport: Transport;
        depot: number;
        timetable_id: number;
        car_no: number;
        vehicletype_id: number;
      }
    >({
      query: (payload) => ({
        url: `timetables_car_vt/${payload.transport}/${payload.depot}`,
        method: "POST",
        body: {
          timetable_id: payload.timetable_id,
          car_no: payload.car_no,
          vehicletype_id: payload.vehicletype_id,
        },
        headers: [],
      }),
      invalidatesTags: ["Timetables_car_vt"],
    }),

    postTimetableCarAutocolumn: builder.mutation<
      { status: string },
      {
        transport: Transport;
        depot: number;
        timetable_id: number;
        car_no: number;
        autocolumn: number;
        shift_type: number | null;
        start_date: string;
        end_date: string | null;
      }
    >({
      query: (payload) => ({
        url: `timetables_car_ac/${payload.transport}/${payload.depot}`,
        method: "POST",
        body: {
          timetable_id: payload.timetable_id,
          car_no: payload.car_no,
          autocolumn: payload.autocolumn,
          shift_type: payload.shift_type,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
        headers: [],
      }),
      invalidatesTags: ["Timetables_car_ac"],
    }),

    postVehicleAutocolumn: builder.mutation<
      { status: string },
      {
        transport: Transport;
        depot: number;
        vehicle_id: number;
        autocolumn: number;
        start_date: string;
        end_date: string | null;
      }
    >({
      query: (payload) => ({
        url: `vehicle_autocolumn_periods/${payload.transport}/${payload.depot}/${payload.vehicle_id}`,
        method: "POST",
        body: {
          autocolumn: payload.autocolumn,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
        headers: [],
      }),
      invalidatesTags: ["Vehicles"],
    }),

    postDriverAutocolumn: builder.mutation<
      { status: string },
      {
        transport: Transport;
        depot: number;
        driver_id: number;
        autocolumn: number;
        start_date: string;
        end_date: string | null;
      }
    >({
      query: (payload) => ({
        url: `driver_autocolumn_periods/${payload.transport}/${payload.depot}/${payload.driver_id}`,
        method: "POST",
        body: {
          avtokolona: payload.autocolumn,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
        headers: [],
      }),
      invalidatesTags: ["Drivers"],
    }),

    postVehicleComposition: builder.mutation<
      { status: string },
      {
        transport: Transport;
        depot: number;
        veh1_id: number;
        veh2_id: number | null;
        start_date: string;
        end_date: string | null;
      }
    >({
      query: (payload) => ({
        url: `vehicle_compositions/${payload.transport}/${payload.depot}`,
        method: "POST",
        body: {
          veh1_id: payload.veh1_id,
          veh2_id: payload.veh2_id,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
        headers: [],
      }),
      invalidatesTags: ["Vehicles"],
    }),

    getTimetablesByDate: builder.query<
      DayExplPlan,
      {
        transport: Transport | "";
        garage: number;
        date: string;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `expl_plan_for_date/${param.transport}/${param.garage}/${
          param.avtocolona
        }?date=${encodeURIComponent(param.date)}`,
      transformResponse: (response: DayExplPlan) => {
        response.expl_plan.forEach((r) =>
          r.cars.forEach((c) =>
            c.workshifts.forEach((w) => (w.operate = w.autocolumn > 0))
          )
        );
        return response;
      },

      providesTags: ["Timetables_car_vt", "Timetables_car_ac"],
    }),

    getTimetablesByDateRoster: builder.query<
      DayExplPlanRoster,
      {
        transport: Transport | "";
        garage: number;
        date: string;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `expl_plan_for_date_roster/${param.transport}/${param.garage}/${
          param.avtocolona
        }?date=${encodeURIComponent(param.date)}`,
      providesTags: ["Timetables_car_vt", "Timetables_car_ac"],
    }),

    getRosterForDrivers: builder.query<
      { data: MessageSchedule },
      {
        transport: Transport | "";
        garage: number;
        month: number;
        year: number;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `roster_for_drivers/${param.transport}/${param.garage}/${param.year}/${param.month}/${param.avtocolona}`,
    }),

    getRosterForDriversCompact: builder.query<
      { data: MessageSchedule },
      {
        transport: Transport | "";
        garage: number;
        date: string;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `roster_for_drivers_compact/${param.transport}/${param.garage}/${
          param.avtocolona
        }/${encodeURIComponent(param.date)}`,
    }),

    getDriversSchedule: builder.query<
      ScheduleReportData,
      {
        transport: Transport | "";
        garage: number;
        month: number;
        year: number;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `schedule_for_drivers/${param.transport}/${param.garage}/${param.year}/${param.month}/${param.avtocolona}`,
    }),

    getDriversScheduleHistory: builder.query<
      ScheduleReportHistoryData,
      {
        transport: Transport | "";
        garage: number;
        month: number;
        year: number;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `schedule_for_drivers/${param.transport}/${param.garage}/${param.year}/${param.month}/${param.avtocolona}?with_history=true`,
    }),

    getDriversScheduleHistoryDay: builder.query<
      ScheduleReportHistoryData,
      {
        transport: Transport | "";
        garage: number;
        date: string;
        avtocolona: number;
        sl_number: number | null;
      }
    >({
      query: (param) =>
        `schedule_for_drivers_history/${param.transport}/${param.garage}/${
          param.avtocolona
        }/${encodeURIComponent(param.date)}?sl_number=${
          param.sl_number ? param.sl_number : ""
        }`,
    }),

    getDriverPersonalAccount: builder.query<
      DriverPersonalAccount,
      {
        transport: Transport | "";
        garage: number;
        month: number;
        year: number;
        avtocolona: number;
        driver_id: number | null;
      }
    >({
      query: (param) =>
        `driver_personal_account/${param.transport}/${
          param.garage
        }?start_date=${encodeURIComponent(
          moment({
            year: param.year,
            month: param.month,
            day: 1,
          }).format("YYYY-MM-DD")
        )}&end_date=${encodeURIComponent(
          moment({ year: param.year, month: param.month, day: 1 })
            .endOf("month")
            .format("YYYY-MM-DD")
        )}&driver_id=${param.driver_id}`,
    }),

    getVehicles: builder.query<
      { data: Vehicle[] },
      {
        transport: Transport | "";
        garage: number;
      }
    >({
      query: (param) => `vehicles/${param.transport}/${param.garage}`,
      providesTags: ["Vehicles"],
    }),

    getVehicleStates: builder.query<
      {
        data: {
          id: number;
          transport: Transport;
          description: string;
          desc_short: string;
          site_visible: boolean;
          category_id: number;
        }[];
        status: "ok";
      },
      Transport | ""
    >({
      query: (param) => `vehicles_states/${param}`,
    }),

    getVehiclesRoster: builder.query<
      {
        data: ScheduleVehicle[];
        status: "ok";
      },
      {
        transport: Transport | "";
        garage: number;
        month: number;
        year: number;
        avtocolona: number;
      }
    >({
      query: (param) =>
        `roster_for_vehicles/${param.transport}/${param.garage}/${param.year}/${param.month}/${param.avtocolona}`,
    }),

    getRosterList: builder.query<
      { data: ApiRosterCollection; status: "ok" },
      void
    >({
      query: () => "rosters_panel",
      transformResponse: (value: {
        data: UnprocessedApiRoster[];
        status: "ok";
      }) => {
        return { ...value, data: processApiRosterCollection(value.data) };
      },
      providesTags: ["ApiRosters"],
    }),

    getRoster: builder.query<
      { data: ApiRoster; status: "ok" },
      {
        transport: Transport;
        depot_id: number;
        autocolumn: number;
        date: string;
      }
    >({
      query: ({ transport, depot_id, autocolumn, date }) =>
        `rosters/${transport}/${depot_id}/${autocolumn}/${date}`,
      providesTags: ["ApiRoster"],
      transformResponse: (value: {
        data: UnprocessedApiRoster;
        status: "ok";
      }) => {
        return { ...value, data: processApiRoster(value.data) };
      },
    }),

    postRosterAction: builder.mutation<
      { status: "ok" },
      {
        transport: Transport;
        depot_id: number;
        autocolumn: number;
        date: string;
        action: RosterActionType;
        sub_action: string | null;
        comment: string | null;
      }
    >({
      query: ({ transport, depot_id, autocolumn, date, ...body }) => ({
        url: `rosters/${transport}/${depot_id}/${autocolumn}/${date}`,
        method: "POST",
        body: body,
        headers: [],
      }),
      invalidatesTags: ["ApiRosters", "ApiRoster"],
    }),

    //post на http://{{server}}/api/v1/rosters_export/A/9/2/2023-08-27
    postRosterExport: builder.mutation<
      | { status: "ok" }
      | {
          status: "error";
          data: {
            errors: {
              code: string;
              line_num: string;
              result: string;
            }[];
          };
        },
      {
        transport: Transport | "";
        depot_id: number;
        autocolumn: number;
        date: string;
        lineTasks: DayRosterData[];
        nonWorkTasks: NonWorkDayTaskGPS[] | undefined;
      }
    >({
      query: ({ transport, depot_id, autocolumn, date, ...body }) => ({
        url: `rosters_export/${transport}/${depot_id}/${autocolumn}/${date}`,
        method: "POST",
        body: body,
        headers: [],
      }),
      invalidatesTags: ["ApiRosters", "ApiRoster"],
    }),

    getFirstSerialNumber: builder.query<
      { data: { start_number: number }; status: "ok" },
      {
        transport: Transport;
        depot_id: number;
        autocolumn: number;
        date: string;
        count: number;
        ts: number;
      }
    >({
      query: ({ transport, depot_id, autocolumn, date, count }) =>
        `waybill_serial_numbers/${transport}/${depot_id}/${autocolumn}/${moment(
          date,
          "YYYY-MM-DD"
        ).format("YYYY")}/${date}?count=${count}`,
    }),

    getFakeSchedule: builder.query<
      FakeSchedule,
      ScheduleParameters | null | undefined
    >({
      query: (parameters) =>
        parameters
          ? `dummy_schedule/${parameters[0]}/${parameters[1]}/${parameters[2]}/${parameters[3]}/${parameters[4]}`
          : "dummy_schedule/A/0/0/0/0",
      transformResponse: (value: FakeSchedule) => ({
        ...value,
        data: {
          ...value.data,
          schedule: sortArray(value.data.schedule, "sl_number", {
            in_place: false,
          }),
        },
      }),
      providesTags: ["FakeSchedule"],
    }),

    fakeScheduleAction: builder.mutation<
      { status: "ok" },
      | {
          scheduleParameters: ScheduleParameters;
          action: "start_calc";
          params: PlannerSettings;
        }
      | {
          scheduleParameters: ScheduleParameters;
          action: "stop_calc";
        }
    >({
      query: (params) => ({
        url: `dummy_schedule/${params.scheduleParameters[0]}/${params.scheduleParameters[1]}/${params.scheduleParameters[2]}/${params.scheduleParameters[3]}/${params.scheduleParameters[4]}`,
        method: "POST",
        body: {
          action: params.action,
          params: params.action === "start_calc" ? params.params : undefined,
        },
        headers: [],
      }),
      invalidatesTags: ["FakeSchedule"],
    }),

    getRecommendations: builder.query<
      RecommendationResponse,
      RecommendationRequest
    >({
      query: (parameters) =>
        parameters.schedule_parameters
          ? `suggestions/${parameters.schedule_parameters[0]}/${
              parameters.schedule_parameters[1]
            }/${parameters.schedule_parameters[4]}?date=${encodeURIComponent(
              parameters.date
            )}&suggestion_type=${encodeURIComponent(
              parameters.suggestion_type
            )}`
          : `suggestions/A/0/0?date=${encodeURIComponent(
              parameters.date
            )}&&suggestion_type=${encodeURIComponent(
              parameters.suggestion_type
            )}`,
    }),

    postDriverDepotPeriod: builder.mutation<
      { status: "ok" },
      {
        transport: Transport;
        depot_id: number;
        sl_number: number;
        autocolumn: number;
        new_depot_id: number;
        start_date: string;
        end_date: string | null;
      }
    >({
      query: ({ transport, depot_id, sl_number, ...body }) => ({
        url: `driver_depot_periods/${transport}/${depot_id}/${sl_number}`,
        method: "POST",
        body: body,
        headers: [],
      }),
    }),
  }),
});

export const {
  useGetAllDriversQuery,
  useGetExplPlanQuery,
  useLazyGetExplPlanQuery,
  useGetDepotListQuery,
  useGetLineListQuery,
  useGetLineTimetableListQuery,
  useLazyGetLineTimetableListQuery,
  usePutExplCorrectionMutation,
  usePostExplPlanImportMutation,
  useGetDriversListQuery,
  useGetExplPlanCalendarQuery,
  usePutDriverCorrectionMutation,
  useGetScheduleSpecialShiftsQuery,
  usePostScheduleShiftsMutation,
  usePostSpecialShiftMutation,
  useGetTimetablesByDateQuery,
  useGetTimetablesByDateRosterQuery,
  useGetVehicleTypeListQuery,
  useLazyGetVehicleTypeListQuery,
  useGetDriversScheduleQuery,
  useGetDriverPersonalAccountQuery,
  usePostTimetableVehicleTypeMutation,
  useGetVehiclesQuery,
  usePostTimetableCarAutocolumnMutation,
  usePostVehicleAutocolumnMutation,
  usePostDriverAutocolumnMutation,
  usePostVehicleCompositionMutation,
  useGetDaytaskStateListQuery,
  useGetDriversScheduleHistoryQuery,
  usePutVehicleCorrectionMutation,
  useGetRosterForDriversQuery,
  useGetVehicleStatesQuery,
  useGetVehiclesRosterQuery,
  useGetRosterListQuery,
  useGetRosterQuery,
  usePostRosterActionMutation,
  usePostRosterExportMutation,
  useGetFirstSerialNumberQuery,
  useLazyGetFirstSerialNumberQuery,
  useGetDriversScheduleHistoryDayQuery,
  useGetRosterForDriversCompactQuery,
  useGetFakeScheduleQuery,
  useFakeScheduleActionMutation,
  useGetRecommendationsQuery,
  useLazyGetRecommendationsQuery,
  usePostDriverDepotPeriodMutation,
} = uiApi;
