import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { useCallback, useState } from "react";

import { ReportPrinter } from "./ReportPrinter";
import moment from "moment";

export function useRosterOuttimesPdfExporterer() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const [needsAutocolumnSelect] = useSelector((state: RootState) => [
    state.filters.needsAutocolumnSelect,
  ]);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      rows: {
        line_name: string;
        car_no: number;
        vehicle: number;
        start_time: string;
        driver_id: number;
      }[],
      selectedDate: string,
      selectedVehTypes: (string | undefined)[]
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter();
        printer.printSpace(5);

        printer.setFont("bold", 14, 1.2);
        // printer.printText(
        //   `АП "${
        //     depots.find((d) => d.id === selectedDepot)?.value || "?"
        //   }", Автокoлона ${selectedAutoColumn}`,
        //   printer.pageMarginX + printer.printableWidth / 2,
        //   printer.printableWidth,
        //   { align: "center" }
        // );
        printer.printText(
          `${selectedTransport !== "A" ? `${selectedTransport} Депо` : "АП"} "${
            depots.find((d) => d.id === selectedDepot)?.value || "? "
          } ${
            selectedTransport === "A" || needsAutocolumnSelect
              ? " , Автокoлона " + selectedAutoColumn.toString()
              : '"'
          }`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.setFont("bold", 12, 1.2);
        printer.printText(
          `ЧАСОВЕ НА ИЗЛИЗАНЕ ПО НАРЯД ЗА ДАТА: ${moment(
            selectedDate,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} ${moment(selectedDate, "YYYY-MM-DD").format(
            "dddd"
          )}`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.printSpace(5);

        printer.printText(
          `ИЗБРАНИ МАРКИ: ${
            selectedVehTypes.length > 0
              ? selectedVehTypes?.join(", ")
              : "всички"
          }`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        let marginY = printer.currentPageY;
        const pageMarginX = printer.pageMarginX;
        const tableRows = 32;

        for (let i = 0; i <= Math.floor(rows.length / tableRows); i++) {
          if (i % 2 === 0) {
            marginY = printer.currentPageY;
          } else {
            printer.currentPageY = marginY;
          }

          printer.autoTable({
            margin: {
              horizontal:
                i % 2 === 0
                  ? printer.pageMarginX
                  : pageMarginX + printer.printableWidth / 2,
              vertical: printer.pageMarginY,
            },
            styles: {
              halign: "center",
              valign: "top",
              font: "Roboto",

              //lineWidth: 0,
              fontSize: 11,
              cellPadding: 0,
              lineColor: [0, 0, 0],
              //lineWidth: 0.25,
              lineWidth: 0,
            },
            bodyStyles: {
              cellPadding: 4,
            },
            columnStyles: {
              0: { cellWidth: 45 },
              1: { cellWidth: 25 },
              2: {
                cellWidth: 55,
              },
              3: { cellWidth: 55 },
              4: { cellWidth: 55 },
            },
            body: rows.slice(
              i * tableRows,
              (i + 1) * tableRows > rows.length
                ? i * tableRows + rows.length - i * tableRows
                : i * tableRows + tableRows
            ) as any,
            columns: [
              {
                dataKey: "line_name",
                header: "Линия",
              },
              {
                dataKey: "car_no",
                header: "Кола",
              },
              {
                dataKey: "vehicle",
                header: "Кола излизане",
              },
              {
                dataKey: "driver_id",
                header: "Водач",
              },
              {
                dataKey: "start_time",
                header: "Час на излизане",
              },
            ],
            willDrawCell: (cell) => {
              if (cell.section === "head") {
                const oldWidth = printer.doc.getLineWidth();
                printer.doc.setLineWidth(1);

                printer.doc.line(
                  cell.cell.x,
                  cell.cell.y,
                  cell.cell.x + cell.cell.width,
                  cell.cell.y
                );

                printer.doc.line(
                  cell.cell.x,
                  cell.cell.y + cell.cell.height,
                  cell.cell.x + cell.cell.width,
                  cell.cell.y + cell.cell.height
                );
                printer.doc.setLineWidth(oldWidth);
              } else if (cell.section === "foot") {
                const oldWidth = printer.doc.getLineWidth();
                printer.doc.setLineWidth(1);
                printer.doc.line(
                  cell.cell.x,
                  cell.cell.y,
                  cell.cell.x + cell.cell.width,
                  cell.cell.y
                );
                printer.doc.setLineWidth(oldWidth);
              }
            },
          });
        }
        printer.save(
          `Часове на излизане -${selectedDate}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      depots,
      needsAutocolumnSelect,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
