import {
  AddComment,
  CommentsDisabled,
  DirectionsBus,
} from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { usePostDriverAutocolumnMutation } from "../../data/api/hooks";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";
import { useDriverCommentDialog } from "./useDriverCommentDialog";

export function DriverItemDialog({
  driver,
  selectedDate,
  driverComment,
  isReadOnly,
  isAssigned,
  saveComment,
  onCloseMenu,
  contextMenu,
}: {
  driver: RosterDriver | ElectroRosterDriver;
  selectedDate: string;
  driverComment: string | null;
  isReadOnly: boolean;
  isAssigned: boolean;
  saveComment: (data: {
    driver_id: string;
    shift_type: 1 | 2;
    date: string;
    comment: string | null;
  }) => Promise<{
    status: "ok";
  }>;
  onCloseMenu: () => void;
  contextMenu: {
    left: number;
    top: number;
  } | null;
}) {
  const {
    dialogOpen,
    openChangeCommentDialog,
    closeChangeCommentDialog,
    deleteComment,
    comment,
    setComment,
    commentErrors,
    commentBusy,
    changeComment,
  } = useDriverCommentDialog(
    driver,
    selectedDate,
    isReadOnly,
    driverComment,
    saveComment,
    onCloseMenu,
    onCloseMenu
  );

  const onCloseContextMenu = useCallback(() => {
    onCloseMenu();
  }, [onCloseMenu]);

  const profile = useProfile();
  const { scheduleParameters } = useScheduleParameters();

  const [changeAutocolumn, changeAutocolumnStatus] =
    usePostDriverAutocolumnMutation();

  const otherAutocolumns = useMemo(
    () =>
      scheduleParameters
        ? (
            profile?.garage_autocolumns?.[scheduleParameters[0]]?.[
              scheduleParameters[1]
            ] || []
          ).filter((a) => a !== scheduleParameters[4])
        : [],
    [profile?.garage_autocolumns, scheduleParameters]
  );

  const changeAutocolumnCallback = useCallback(
    (autocolumn: number) => {
      onCloseMenu();
      if (!isReadOnly && scheduleParameters) {
        console.log("Change driver autocolumn:", driver, autocolumn);
        changeAutocolumn({
          transport: scheduleParameters[0],
          depot: scheduleParameters[1],
          driver_id: driver.driver_id,
          autocolumn: autocolumn,
          start_date: selectedDate,
          end_date: selectedDate,
        })
          .unwrap()
          .then((result) => {
            if (result.status !== "ok") {
              alert("Грешка при промяна на автоколона");
              console.warn("Грешка при промяна на автоколона", result);
            } else {
              console.log("Success changing autocolumn for driver");
            }
          })
          .catch((error) => {
            alert("Грешка при промяна на автоколона");
            console.warn("Грешка при промяна на автоколона", error);
          });
      }
    },
    [
      changeAutocolumn,
      driver,
      isReadOnly,
      onCloseMenu,
      scheduleParameters,
      selectedDate,
    ]
  );

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={onCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu || undefined}
      >
        <MenuItem onClick={openChangeCommentDialog} disabled={!isAssigned}>
          <ListItemIcon>
            <AddComment fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {driverComment ? "Промяна на коментар" : "Добавяне на коментар"}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={deleteComment}
          disabled={!driverComment || !isAssigned}
        >
          <ListItemIcon>
            <CommentsDisabled fontSize="small" />
          </ListItemIcon>
          <ListItemText>Изтриване на коментар</ListItemText>
        </MenuItem>

        {otherAutocolumns.length > 0
          ? [
              <Divider key="divider" />,

              otherAutocolumns.length === 1 ? (
                <MenuItem
                  key="change-autocolumn"
                  disabled={isReadOnly || changeAutocolumnStatus.isLoading}
                  onClick={() => {
                    changeAutocolumnCallback(otherAutocolumns[0]);
                  }}
                >
                  <ListItemIcon>
                    <DirectionsBus fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Прехвърли в другата автоколона</ListItemText>
                </MenuItem>
              ) : (
                otherAutocolumns.map((autocolumn) => (
                  <MenuItem
                    key={`change-autocolumn-${autocolumn}`}
                    disabled={isReadOnly || changeAutocolumnStatus.isLoading}
                    onClick={() => {
                      changeAutocolumnCallback(autocolumn);
                    }}
                  >
                    <ListItemIcon>
                      <DirectionsBus fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{`Прехвърли в автоколона ${autocolumn}`}</ListItemText>
                  </MenuItem>
                ))
              ),
            ].flat()
          : undefined}
      </Menu>

      <form>
        <Dialog open={dialogOpen} onClose={closeChangeCommentDialog} fullWidth>
          <DialogTitle>
            {driverComment ? "Промяна на коментар" : "Добавяне на коментар"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{`${
              driverComment ? "Промяна на коментар" : "Добавяне на коментар"
            } за водач ${driver.sl_number}: ${driver.name}`}</DialogContentText>
            <TextField
              autoFocus
              multiline
              required
              minRows={5}
              margin="dense"
              id="name"
              label="Коментар"
              fullWidth
              variant="outlined"
              value={comment || ""}
              onChange={(e) => setComment(e.target.value)}
              error={commentErrors !== null}
              disabled={commentBusy}
            />
            {commentErrors && (
              <DialogContentText color="error">{`Грешка: ${commentErrors}`}</DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeChangeCommentDialog} disabled={commentBusy}>
              Отказ
            </Button>
            <Button
              onClick={changeComment}
              type="submit"
              disabled={commentBusy}
            >
              Запиши
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={commentBusy && !dialogOpen}
        //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
