import jsPDF, { TextOptionsLight } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import moment from "moment";

//interface PageHookParameters {
//  table: Table;

//  pageNumber: number;
//  pageCount: number;
//  settings: Settings;
//  doc: jsPDFDocument;
//  cursor: Pos | null;
//}

type FontStyle =
  | "thin"
  | "light"
  | "normal"
  | "medium"
  | "bold"
  | "black"
  | "thinitalic"
  | "lightitalic"
  | "italic"
  | "mediumitalic"
  | "bolditalic"
  | "blackitalic"
  | "slab-black"
  | "slab-bold"
  | "slab-extrabold"
  | "slab-extralight"
  | "slab-light"
  | "slab-medium"
  | "slab-normal"
  | "slab-semibold"
  | "slab-thin";

export class ReportPrinter {
  public doc: jsPDF;
  private printPageNumber: boolean;
  private pageNumberOffset: number;
  private printDate: boolean;
  private printMusat: boolean;
  private p_currentY: number;
  private p_pageMarginY;
  private p_pageMarginX;
  private lineSpacing;
  private headerText;
  private p_lastLineHeight;

  constructor(
    options: {
      orientation?: "p" | "portrait" | "l" | "landscape";
      printPageNumber?: boolean;
      pageNumberOffset?: number;
      printDate?: boolean;
      printMusat?: boolean;
      marginX?: number;
      marginY?: number;
      lineSpacing?: number;
      headerText?: string;
    } = {
      orientation: "portrait",
      printPageNumber: true,
      pageNumberOffset: 0,
      printDate: true,
      printMusat: true,
      marginX: 40,
      marginY: 40,
      lineSpacing: 1.2,
    }
  ) {
    this.p_lastLineHeight = 0;
    this.printPageNumber =
      options.printPageNumber !== undefined ? options.printPageNumber : true;
    this.pageNumberOffset =
      options.pageNumberOffset !== undefined ? options.pageNumberOffset : 0;
    this.printDate = options.printDate !== undefined ? options.printDate : true;
    this.printMusat =
      options.printMusat !== undefined ? options.printMusat : true;

    this.p_pageMarginX = options.marginX || 40;
    this.p_pageMarginY = options.marginY || 40;
    this.lineSpacing = options.lineSpacing || 1.2;

    this.headerText = options.headerText;

    this.doc = new jsPDF({
      orientation: options.orientation || "portrait",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });
    this.doc.addFont("/fonts/Roboto-Thin.ttf", "Roboto", "thin");
    this.doc.addFont("/fonts/Roboto-Light.ttf", "Roboto", "light");
    this.doc.addFont("/fonts/Roboto-Regular.ttf", "Roboto", "normal");
    this.doc.addFont("/fonts/Roboto-Medium.ttf", "Roboto", "medium");
    this.doc.addFont("/fonts/Roboto-Bold.ttf", "Roboto", "bold");
    this.doc.addFont("/fonts/Roboto-Black.ttf", "Roboto", "black");
    this.doc.addFont("/fonts/Roboto-ThinItalic.ttf", "Roboto", "thinitalic");
    this.doc.addFont("/fonts/Roboto-LightItalic.ttf", "Roboto", "lightitalic");
    this.doc.addFont("/fonts/Roboto-Italic.ttf", "Roboto", "italic");
    this.doc.addFont(
      "/fonts/Roboto-MediumItalic.ttf",
      "Roboto",
      "mediumitalic"
    );
    this.doc.addFont("/fonts/Roboto-BoldItalic.ttf", "Roboto", "bolditalic");
    this.doc.addFont("/fonts/Roboto-BlackItalic.ttf", "Roboto", "blackitalic");

    this.doc.addFont("/fonts/RobotoSlab-Black.ttf", "Roboto", "slab-black");
    this.doc.addFont("/fonts/RobotoSlab-Bold.ttf", "Roboto", "slab-bold");
    this.doc.addFont(
      "/fonts/RobotoSlab-ExtraBold.ttf",
      "Roboto",
      "slab-extrabold"
    );
    this.doc.addFont(
      "/fonts/RobotoSlab-ExtraLight.ttf",
      "Roboto",
      "slab-extralight"
    );
    this.doc.addFont("/fonts/RobotoSlab-Light.ttf", "Roboto", "slab-light");
    this.doc.addFont("/fonts/RobotoSlab-Medium.ttf", "Roboto", "slab-medium");
    this.doc.addFont("/fonts/RobotoSlab-Regular.ttf", "Roboto", "slab-normal");
    this.doc.addFont(
      "/fonts/RobotoSlab-SemiBold.ttf",
      "Roboto",
      "slab-semibold"
    );
    this.doc.addFont("/fonts/RobotoSlab-Thin.ttf", "Roboto", "slab-thin");

    this.p_currentY = this.p_pageMarginY;

    this.setFont("normal", 12);
    this.didAddPage();
  }

  public get pageMarginX() {
    return this.p_pageMarginX;
  }

  public get pageMarginY() {
    return this.p_pageMarginY;
  }

  private didAddPage(/*page?: PageHookParameters*/) {
    this.withFont(
      () => {
        if (this.headerText && this.headerText.trim() !== "") {
          this.doc.text(
            this.headerText,
            this.printableWidth / 2 + this.p_pageMarginX,
            this.p_pageMarginY / 2,
            {
              align: "center",
            }
          );
        }
        if (this.printPageNumber) {
          this.doc.text(
            `Стр. ${
              // page ? page.pageNumber :
              this.doc.getCurrentPageInfo().pageNumber + this.pageNumberOffset
            }`,
            this.p_pageMarginX,
            this.printableHeight +
              this.p_pageMarginY +
              (this.p_pageMarginY / 2 - 7),
            {
              align: "left",
            }
          );
        }

        if (this.printMusat) {
          this.doc.text(
            "НАРЯД - МУСАТ 2023",
            this.printableWidth / 2 + this.p_pageMarginX,
            this.printableHeight +
              this.p_pageMarginY +
              (this.p_pageMarginY / 2 - 7),
            {
              align: "center",
            }
          );
        }

        if (this.printDate) {
          this.doc.text(
            moment().format("DD.MM.YYYY г."),
            this.printableWidth + this.p_pageMarginX,
            this.printableHeight +
              this.p_pageMarginY +
              (this.p_pageMarginY / 2 - 7),
            {
              align: "right",
            }
          );
        }
      },
      "normal",
      7,
      1
    );
  }

  public getWidth(text: string) {
    return this.doc.getStringUnitWidth(text);
  }

  public getSize(text: string) {
    const dimensions = this.getDimensions(text);
    return {
      width: dimensions.w,
      height: dimensions.h * this.lineSpacing,
    };
  }

  public getDimensions(
    text: string,
    options?: {
      font?: string | undefined;
      fontSize?: number | undefined;
      maxWidth?: number | undefined;
      scaleFactor?: number | undefined;
    }
  ) {
    return this.doc.getTextDimensions(text, options);
  }

  setFont(style: FontStyle, size = 12, lineSpacing = 1.2) {
    this.doc.setFont("Roboto", style).setFontSize(size);
    this.lineSpacing = lineSpacing;
  }

  withFont<T>(
    callback: () => T,
    style: FontStyle,
    size: number,
    lineSpacing = 1.2
  ): T {
    const oldStyle = this.doc.getFont().fontStyle as
      | "normal"
      | "bold"
      | "italic"
      | "bolditalic";
    const oldSize = this.doc.getFontSize();
    const oldLineSpacing = this.lineSpacing;

    this.setFont(style, size, lineSpacing);
    try {
      return callback();
    } finally {
      this.setFont(oldStyle, oldSize, oldLineSpacing);
    }
  }

  public printSpace(height: number) {
    this.p_currentY += height;
  }

  public setPosition(y: number) {
    this.p_currentY = y;
  }

  public get printableWidth() {
    return this.doc.internal.pageSize.width - 2 * this.p_pageMarginX;
  }

  public get totalWidth() {
    return this.doc.internal.pageSize.width;
  }

  public get printableHeight() {
    return this.doc.internal.pageSize.height - 2 * this.p_pageMarginY;
  }

  public get totalHeight() {
    return this.doc.internal.pageSize.height;
  }

  public get currentPageY() {
    return this.p_currentY;
  }

  public set currentPageY(value: number) {
    this.p_currentY = value > this.p_pageMarginY ? value : this.p_pageMarginY;
  }

  public get lastLineHeight() {
    return this.p_lastLineHeight;
  }

  public resetY() {
    this.printSpace(-this.lastLineHeight);
  }

  public printTextExt(
    text: string,
    startX: number,
    startY: number,
    maxWidth: number | "page" | "unlimited" = "page",
    textOptions?: TextOptionsLight
  ) {
    this.p_currentY = startY;
    return this.printText(text, startX, maxWidth, textOptions);
  }

  public printText(
    text: string,
    startX: number = this.p_pageMarginX,
    maxWidth: number | "page" | "unlimited" = "page",
    textOptions?: TextOptionsLight
  ) {
    if (maxWidth === "unlimited") {
      this.addPage(this.doc.getTextDimensions(text).h);
      this.doc.text(text, startX, this.p_currentY, textOptions);
      const size = this.doc.getTextDimensions(text);
      this.p_currentY += size.h * this.lineSpacing;
      return { width: size.w, height: size.h * this.lineSpacing };
    } else {
      const width = maxWidth === "page" ? this.printableWidth : maxWidth;

      const splitContent: string[] = this.doc.splitTextToSize(text, width);
      const totalSize = { width: 0, height: 0 };
      for (const line of splitContent) {
        const size = this.doc.getTextDimensions(line);
        this.addPage(size.h);
        this.doc.text(line, startX, this.p_currentY, textOptions);
        this.p_currentY += size.h * (this.lineSpacing || 1);
        totalSize.width = Math.max(totalSize.width, size.w);
        totalSize.height += size.h * (this.lineSpacing || 1);
      }
      this.p_lastLineHeight = totalSize.height;

      return totalSize;
    }
  }

  public printLine(lineWidth: number) {
    const oldWidth = this.doc.getLineWidth();

    this.doc.setLineWidth(lineWidth);
    this.doc.line(
      this.p_pageMarginX,
      this.p_currentY,
      this.printableWidth + this.p_pageMarginX,
      this.p_currentY
    );
    this.doc.setLineWidth(oldWidth);
    this.p_currentY += this.doc.getLineHeight();
    this.p_lastLineHeight = this.doc.getLineHeight();
  }

  public printShortLine(start: number, length: number, lineWidth: number) {
    const oldWidth = this.doc.getLineWidth();

    this.doc.setLineWidth(lineWidth);
    this.doc.line(start, this.p_currentY, start + length, this.p_currentY);
    this.doc.setLineWidth(oldWidth);
    this.p_currentY += this.doc.getLineHeight();
    this.p_lastLineHeight = this.doc.getLineHeight();
  }

  public newPage() {
    this.doc.addPage();
    const font = this.doc.getFont();
    const fontSize = this.doc.getFontSize();
    this.didAddPage();
    this.doc.setFont(font.fontName, font.fontStyle).setFontSize(fontSize);
    this.p_currentY = this.p_pageMarginY;
  }

  public addPage(textHeight: number) {
    const endY = this.p_currentY + textHeight; //this.doc.getTextDimensions(text).h;

    if (endY > this.printableHeight + this.p_pageMarginY) {
      this.newPage();
      return true;
    }
    return false;
  }

  public save(filename: string) {
    this.doc.save(filename);
  }

  public autoTable({
    didDrawPage,
    didDrawCell,
    theme,
    headStyles,
    styles,

    startY,
    margin,

    ...options
  }: UserOptions) {
    if (startY || startY === 0) {
      this.p_currentY = startY;
    }

    autoTable(this.doc, {
      rowPageBreak: "avoid",
      ...options,
      margin: margin
        ? margin
        : { horizontal: this.p_pageMarginX, vertical: this.p_pageMarginY },
      theme: theme || "plain",
      headStyles: headStyles || {
        fillColor: undefined,
        textColor: [0, 0, 0],
      },
      styles: styles || {
        halign: "center",
        valign: "middle",
        font: "Roboto",
        fontSize: 8,
        cellPadding: 4,
        lineColor: [0, 0, 0],
        lineWidth: 0.25,
      },
      startY: this.p_currentY,

      didDrawPage: (page) => {
        this.didAddPage(/*page*/);

        if (didDrawPage) {
          return didDrawPage(page);
        }
      },
      didDrawCell: (cell) => {
        if (didDrawCell) {
          didDrawCell(cell);
        }

        this.p_currentY = cell.table.finalY || this.p_currentY;
      },
    });

    if ((this.doc as any).previousAutoTable?.finalY) {
      this.p_currentY = (this.doc as any).previousAutoTable.finalY;
    }
  }
}
