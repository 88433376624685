import "./ExplotationPlan.css";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import {
  useGetScheduleSpecialShiftsQuery,
  useGetVehicleTypeListQuery,
  useGetVehiclesQuery,
  useLazyGetExplPlanQuery,
  usePutExplCorrectionMutation,
} from "../../data/api/hooks";
import moment, { Moment } from "moment";
import "moment/locale/bg";
import { Profiler, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { DepotSelect } from "../../filters/DepotSelect";
import { RootState } from "../../data/store";
import { TransportSelect } from "../../filters/TransportSelect";
import { Timetable, timetableStartCode } from "../../data/api/types/timetable";
import { Transport } from "../../data/api/types/driver";
import { useExplotationPlan } from "../nomenclatures/useExplotationPlan";
import { grey, red } from "@mui/material/colors";
import { QueryError } from "../QueryError";
import { TimetbaleVehicletypeEditForm } from "../reports/TimetableVehicletype_EditForm";
import {
  LineTimetable,
  useVehicleGarageTypes,
} from "../reports/useTimetables_ByDate";
import { ExploatationPlanDict } from "../../data/api/types/exploatation_plan";

import bgLocale from "@fullcalendar/core/locales/bg";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // for selectable
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useProfile } from "../../common/useProfile";
//import momentPlugin from "@fullcalendar/moment";

import { DateSelectArg } from "@fullcalendar/core";
import { display, margin } from "@mui/system";

export interface Period {
  start: Moment;
  end: Moment;
}

const calendarSettings = {
  plugins: [/*momentPlugin,*/ dayGridPlugin, interactionPlugin],
  initialView: "dayGridMonth",
  height: 500,
  expandRows: true,
  timeZone: "UTC",
  locale: bgLocale,
  selectable: true,
  unselectAuto: false,
  showNonCurrentDates: false,
};

const getTimetableCorrection = (
  selectedTimetable: number | null,
  selectedPeriod: Period[]
) => {
  if (selectedPeriod && selectedPeriod.length > 0) {
    return selectedPeriod.reduce<
      {
        razpcad_tt_id: number | null;
        date: Moment;
      }[]
    >((prevp, p) => {
      let d = p.start;
      const arr: {
        razpcad_tt_id: number | null;
        date: Moment;
      }[] = [];
      while (d <= p.end) {
        arr.push({ razpcad_tt_id: selectedTimetable, date: d });
        d = moment(d).add(1, "day");
      }
      return prevp.concat(arr);
    }, []);
  } else {
    return [];
  }
};

const onSaveCorrection = (
  selectedLineId: number,
  selectedTransport: Transport,
  selectedDepot: number,
  selectedTimetable: number | null,
  selectedPeriod: Period[],
  updateExplPlan: ReturnType<typeof usePutExplCorrectionMutation>[0],
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedLineTimetable: React.Dispatch<
    React.SetStateAction<LineTimetable | undefined>
  >,
  needsAutocolumnSelect: boolean
) => {
  const timetableCorrection = getTimetableCorrection(
    selectedTimetable,
    selectedPeriod
  );

  updateExplPlan({
    transport: selectedTransport,
    gps_depot_id: selectedDepot,
    line_id: selectedLineId,
    timetables: timetableCorrection,
  }).then((res) => {
    console.log("Res:", res);
    if ("error" in res) {
      <QueryError error={res.error} />;
    } else {
      //ако върне разписание, за което не са инициализирани автоколона или марки по колите да покаже екрана за редакция
      if (res.data.data.length > 0) {
        const car_data = res.data.data[0];
        const visible = car_data.cars.reduce(
          (acc, c) =>
            acc ||
            (c.workshifts.length > 0
              ? c.workshifts.reduce(
                  (bfr, w) =>
                    bfr ||
                    (needsAutocolumnSelect && w.autocolumn <= 0) ||
                    w.vehicletype_id <= 0,
                  false
                )
              : false),
          false
        );
        setSelectedLineTimetable(car_data);
        setOpen(visible);
      }

      // loadExplPlan(explPlanParams, true);
      //console.log("Res:", res.error);
    }
  });
};

export function ExploatationPlanLineCalendar({
  mnth,
  ExplPlan,
  selectedLine,
  selectedTransport,
  selectedDepot,
  updateExplPlan,
  timetables,
  setStartDate,
  setEndDate,
  isLoading,
}: {
  mnth: Moment | null;
  ExplPlan: ExploatationPlanDict;
  selectedLine: {
    id: number;
    label: string;
  } | null;
  selectedTransport: Transport | "";
  selectedDepot: number;
  updateExplPlan: ReturnType<typeof usePutExplCorrectionMutation>[0];
  timetables: Timetable[];
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>> | null;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>> | null;
  isLoading: boolean;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const handleClick = () => setClicked(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  console.log("ExplPlanForm:", ExplPlan);
  const [selectedPeriod, setSelectedPeriod] = useState<Period[]>([]);
  const [showWorkDays, setShowWorkDays] = useState(false);
  const [showWеекDays, setShowWWeekDays] = useState(false);
  const [workdayRanges, setWorkdayRanges] = useState<Period[]>([]);
  const [weekendRanges, setWeekendRanges] = useState<Period[]>([]);
  console.log("selectedPeriod", selectedPeriod);
  const {
    data: specialshift_data,
    isLoading: isSpecialShiftLoading,
    isError: isSpecialShiftError,
  } = useGetScheduleSpecialShiftsQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      year: mnth ? mnth.year() : 0,
      month: mnth ? mnth.month() + 1 : 0,
      avtocolona: 1,
    },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const handleCheckboxChange = useCallback(
    (type: "workdays" | "weekends") => {
      const startOfMonthDate = moment(mnth, "YYYY-MM-DD").startOf("month");
      // .add(moment(mnth).startOf("month").utcOffset(), "minutes");

      const endOfMonthDate = moment(mnth, "YYYY-MM-DD").endOf("month");
      //.add(moment(mnth).startOf("month").utcOffset(), "minutes");
      if (!showWorkDays && !showWеекDays) setSelectedPeriod([]);
      if (type === "workdays") {
        setShowWorkDays(!showWorkDays);
        if (!showWorkDays) {
          const currentDay = startOfMonthDate;
          const workdays: Period[] = [];
          while (currentDay <= endOfMonthDate) {
            const daytype =
              specialshift_data?.holidays[currentDay.date() - 1]?.day_type;

            //if (!(currentDay.weekday() === 6 || currentDay.weekday() === 5)) {
            if (!(daytype != undefined && daytype > 0)) {
              const formattedDate = moment(currentDay, "YYYY-MM-DD");
              workdays.push({
                start: formattedDate,
                end: formattedDate,
              });
            }
            currentDay.add(1, "d");
          }

          setWorkdayRanges(workdays);
          setSelectedPeriod((prevRanges) => [...prevRanges, ...workdays]);
        } else {
          setSelectedPeriod((prevRanges) =>
            prevRanges.filter((range) => !workdayRanges.includes(range))
          );
          setWorkdayRanges([]);
        }
      } else if (type === "weekends") {
        setShowWWeekDays(!showWеекDays);
        if (!showWеекDays) {
          const currentDay = startOfMonthDate;
          const newWeekends: Period[] = [];

          while (currentDay <= endOfMonthDate) {
            const daytype =
              specialshift_data?.holidays[currentDay.date() - 1]?.day_type;
            if (daytype != undefined && daytype > 0) {
              const formattedDate = moment(currentDay);
              newWeekends.push({
                start: formattedDate,
                end: formattedDate,
              });
            }
            currentDay.add(1, "d");
          }
          setWeekendRanges(newWeekends);
          setSelectedPeriod((prevRanges) => [...prevRanges, ...newWeekends]);
        } else {
          setSelectedPeriod((prevRanges) =>
            prevRanges.filter((range) => !weekendRanges.includes(range))
          );
          setWeekendRanges([]);
        }
      }
    },
    [
      mnth,
      showWorkDays,
      showWеекDays,
      specialshift_data?.holidays,
      weekendRanges,
      workdayRanges,
    ]
  );

  const isDateInRange = (date: Moment) => {
    return selectedPeriod.some(
      (range) =>
        date.utc().isBetween(range.start, range.end) ||
        date.utc().isSame(range.start) ||
        date.utc().isSame(range.end)
    );
  };

  const events = useMemo(
    () =>
      ExplPlan &&
      selectedLine &&
      mnth &&
      ExplPlan[selectedLine.id] &&
      ExplPlan[selectedLine.id].timetables
        ? Object.keys(ExplPlan[selectedLine.id].timetables).map((key) => ({
            id: key.toString(),
            title: ExplPlan
              ? ExplPlan[selectedLine.id].timetables[key]?.tt_code
              : "",
            start: key,
            allDay: true,
            display: "background",
          }))
        : [],
    [ExplPlan, mnth, selectedLine]
  );
  const eventClassNames = (eventInfo: any) => {
    if (
      eventInfo?.event?.start &&
      isDateInRange(
        moment(eventInfo.event.start.toISOString(), "YYYY-MM-DD h:mm:ss a")
      )
    ) {
      return "selected-range";
    } else {
      return "outside-selected-range";
    }
  };

  // const [selectedTimetable, setSelectedTimetable] = useState<number | null>(
  //   null
  // );
  const [selectedLineTimetable, setSelectedLineTimetable] = useState<
    LineTimetable | undefined
  >(undefined);

  const [editFormVisible, setEditFormVisible] = useState(false);

  const vehicles = useGetVehiclesQuery({
    transport: selectedTransport,
    garage: selectedDepot,
  });

  const {
    currentData: vehicleTypesRecord,
    // isLoading: vehicleTypesLoading,
    // isError: vehicleTypesError,
  } = useGetVehicleTypeListQuery(selectedTransport, {
    skip: selectedTransport === "",
  });

  const handleTimetableMenuClick = (newId: number | null) => {
    //    setSelectedTimetable(newId);

    if (!isReadOnly && selectedLine && selectedTransport && selectedPeriod)
      onSaveCorrection(
        selectedLine?.id,
        selectedTransport,
        selectedDepot,
        newId,
        selectedPeriod,
        updateExplPlan,
        setEditFormVisible,
        setSelectedLineTimetable,
        needsAutocolumnSelect
      );
    // setSelectedPeriod([]);
    // setShowWWeekDays(false);
    // setShowWorkDays(false);
  };

  const { vehicleTypeList: vehicleTypeList } = useVehicleGarageTypes();

  // const vehicleTypeList = useMemo(
  //   () =>
  //     vehicleTypesRecord && vehicles?.currentData
  //       ? Object.values(vehicleTypesRecord)
  //           .filter((vt) =>
  //             vehicles?.currentData?.data
  //               ? vehicles.currentData.data.filter(
  //                   (vt1) => vt1.vehicletype_id === vt.id
  //                 ).length > 0
  //               : false
  //           )
  //           .map((v) => ({
  //             label: v.description,
  //             id: v.id,
  //           }))
  //       : [],

  //   [vehicleTypesRecord, vehicles.currentData]
  // );

  const handleSelect = (info: DateSelectArg) => {
    const startDate = moment(info.start.toISOString(), "YYYY-MM-DD h:mm:ss a");
    const endDate = moment(
      info.end.toISOString(),
      "YYYY-MM-DD h:mm:ss a"
    ).subtract(1, "day"); //.subtract(1, "day").startOf("day");

    const newRange = { start: startDate, end: endDate };
    setShowWorkDays(false);
    setShowWWeekDays(false);

    if (!info?.jsEvent?.shiftKey && selectedPeriod?.length > 0) {
      setSelectedPeriod([newRange]);
      return;
    }

    setSelectedPeriod((prevRanges) => {
      const overlappingRangeIndex = prevRanges.findIndex(
        (range) =>
          range.start.isBetween(newRange.start, newRange.end, null, "[]") ||
          range.end.isBetween(newRange.start, newRange.end, null, "[]") ||
          newRange.start.isBetween(range.start, range.end, null, "[]") ||
          newRange.end.isBetween(range.start, range.end, null, "[]")
      );

      if (overlappingRangeIndex !== -1) {
        return prevRanges.filter((_, index) => index !== overlappingRangeIndex);
      } else {
        return [...prevRanges, newRange];
      }
    });
  };

  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );
  console.log("selectedPeriod", selectedPeriod);
  return (
    <Box>
      {isLoading && (
        <Backdrop
          open
          sx={{ color: grey[300], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress size={60} color="inherit" />
        </Backdrop>
      )}

      <Container sx={{ height: "100%", width: "100%", pt: 2, pb: 2 }}>
        <Paper sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              display: "flow",
              width: "100%",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  checked={showWorkDays}
                  onChange={() => handleCheckboxChange("workdays")}
                />
                Делнични дни
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showWеекDays}
                  onChange={() => handleCheckboxChange("weekends")}
                />
                Празнични дни
              </label>
            </div>
            <div
              onContextMenu={(e) => {
                if (!isReadOnly) {
                  e.preventDefault();
                  setClicked(true);
                  setPoints({
                    x: e.pageX,
                    y: e.pageY,
                  });
                }
              }}
            >
              <FullCalendar
                {...calendarSettings}
                headerToolbar={
                  !(setStartDate || setEndDate) ? { right: "" } : {}
                }
                initialDate={
                  mnth
                    ? moment(mnth).startOf("month").format("YYYY-MM-DD")
                    : moment().startOf("month").format("YYYY-MM-DD")
                }
                events={events}
                selectable={true}
                select={handleSelect}
                dayCellClassNames={(date) => {
                  return isDateInRange(
                    moment(date.date.toISOString(), "YYYY-MM-DD h:mm:ss a")
                  )
                    ? "selected-range"
                    : "";
                }}
                eventClassNames={eventClassNames}
                datesSet={(e) => {
                  if (setStartDate && setEndDate) {
                    setStartDate(
                      moment(
                        e.start.toISOString(),
                        "YYYY-MM-DD h:mm:ss a"
                      ).startOf("month")
                    );
                    setEndDate(
                      moment(
                        e.start.toISOString(),
                        "YYYY-MM-DD h:mm:ss a"
                      ).endOf("month")
                    );
                  }
                  //setSelectedPeriod([{StartDate, EndDate}]);
                }}
              />

              {clicked && (
                <Menu
                  open={selectedPeriod !== undefined}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    points !== null
                      ? { top: points.y, left: points.x }
                      : undefined
                  }
                >
                  <MenuItem
                    key={0}
                    value={0}
                    onClick={() => handleTimetableMenuClick(null)}
                  >
                    Изчисти
                  </MenuItem>
                  {timetables &&
                    timetables &&
                    Object.values(timetables)
                      .sort(
                        (a, b) => timetableStartCode(b) - timetableStartCode(a)
                      )
                      .map((timetable) => (
                        <MenuItem
                          key={timetable.razpcad_tt_id}
                          value={timetable.razpcad_tt_id}
                          onClick={() =>
                            handleTimetableMenuClick(timetable.razpcad_tt_id)
                          }
                        >
                          {timetable.tt_code}
                        </MenuItem>
                      ))}
                </Menu>
              )}
            </div>
          </Box>
        </Paper>
      </Container>

      {selectedTransport !== "" &&
        selectedLineTimetable &&
        // !isError &&
        editFormVisible &&
        selectedPeriod && (
          <TimetbaleVehicletypeEditForm
            open={editFormVisible}
            setOpen={setEditFormVisible}
            selectedLineTimetable={selectedLineTimetable}
            setSelectedLineTimetable={setSelectedLineTimetable}
            vehicleTypeList={vehicleTypeList}
            selectedTransport={selectedTransport}
            selectedGarage={selectedDepot}
            selectedDate={
              selectedPeriod.length > 0
                ? selectedPeriod[0].start.format("YYYY-MM-DD")
                : ""
            }
          />
        )}
    </Box>
  );
}

export function ExploatationPlan({
  selLine,
  mnth,
}: {
  selLine: {
    id: number;
    label: string;
  } | null;
  mnth: Moment | null;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const [selectedLine, setSelectedLine] = useState<{
    id: number;
    label: string;
  } | null>(selLine);
  const [startDate, setStartDate] = useState<moment.Moment>(
    mnth ? moment(mnth.utc()).startOf("month") : moment().utc().startOf("month")
  );
  const [endDate, setEndtDate] = useState<moment.Moment>(
    mnth ? moment(mnth.utc()).endOf("month") : moment().utc().endOf("month")
  );

  const {
    lineList,
    ExplPLan,
    lines,
    explPlanParams,
    loadTimetables,
    timetables,
    isLoading,
    importExplPlan,
    resultImportExplPlan,
    updateExplPlan,
    isError,
  } = useExplotationPlan(selectedTransport, selectedDepot, startDate);

  useEffect(() => {
    if (
      !lines.isError &&
      !lines.isLoading &&
      !lines.isFetching &&
      lines.isSuccess &&
      selectedLine &&
      lineList &&
      lineList.findIndex((l) => l.id === selectedLine.id) === -1
    ) {
      setSelectedLine(null);
    }
  }, [
    lineList,
    lines.isError,
    lines.isFetching,
    lines.isLoading,
    lines.isSuccess,
    selectedLine,
  ]);

  const [loadExplPlan] = useLazyGetExplPlanQuery();

  const onImport = useCallback(
    () => {
      if (!isReadOnly && selectedDepot) {
        if (
          window.confirm(
            `Експлоатационният план ще бъде импортиран в първоначален вид. Всички направени корекции от ${startDate.format(
              "YYYY-MM-DD"
            )} до ${endDate.format(
              "YYYY-MM-DD"
            )} ще бъдат изтрити. Желаете ли да продължите?`
          )
        ) {
          importExplPlan({
            transport: selectedTransport,
            gps_depot_id: selectedDepot,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
          });
          if (resultImportExplPlan.error) {
            <QueryError error={resultImportExplPlan.error} />;
          } else {
            if (
              !resultImportExplPlan.isLoading &&
              resultImportExplPlan.isSuccess
            ) {
              loadExplPlan(explPlanParams, true);
            }
          }
        }
      }
    },
    [
      isReadOnly,
      selectedDepot,
      importExplPlan,
      selectedTransport,
      startDate,
      endDate,
      resultImportExplPlan.error,
      resultImportExplPlan.isLoading,
      resultImportExplPlan.isSuccess,
      loadExplPlan,
      explPlanParams,
    ]
    // TODO: Беше това:
    //[selectedDepot, startDate, endDate]
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
      style={{ cursor: "pointer" }}
      // style={{ cursor: cursor }}
    >
      <Container sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={3}>
            <Autocomplete
              sx={{ m: 1, width: "100%" }}
              disablePortal
              key={selectedLine?.id}
              options={lineList}
              value={selectedLine ? selectedLine : undefined}
              onChange={(_event, newValue) => {
                if (newValue) {
                  setSelectedLine(newValue);

                  if (
                    selectedTransport &&
                    selectedDepot &&
                    newValue &&
                    explPlanParams
                  ) {
                    if (!ExplPLan.data) loadExplPlan(explPlanParams, true);
                    loadTimetables({
                      transport: selectedTransport,
                      garage: selectedDepot,
                      line_id: newValue.id,
                    });
                  }
                } else {
                  setSelectedLine(null);
                }
              }}
              disabled={!selectedTransport || !selectedDepot}
              noOptionsText={""}
              renderInput={(params) => (
                <TextField {...params} label="Линия" variant="standard" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
          <Button
            disabled={isReadOnly || !selectedDepot || isLoading}
            style={{ cursor: "pointer" }}
            sx={{ marginLeft: "auto" }}
            //style={{ cursor: cursor }}

            onClick={onImport}
          >
            Импорт
          </Button>
        </Grid>
      </Container>

      {/*<h2>Drivers:</h2>*/}

      {isError && <QueryError error={null} />}
      {isLoading && (
        <Backdrop
          open
          sx={{ color: grey[300], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress size={60} color="inherit" />
        </Backdrop>
      )}
      {ExplPLan?.data && timetables?.data && (
        <ExploatationPlanLineCalendar
          ExplPlan={ExplPLan.data}
          mnth={startDate}
          selectedDepot={selectedDepot}
          selectedLine={selectedLine}
          selectedTransport={selectedTransport}
          timetables={timetables.data}
          updateExplPlan={updateExplPlan}
          setStartDate={setStartDate}
          setEndDate={setEndtDate}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}
