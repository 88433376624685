import { HelpOutline } from "@mui/icons-material";
import { alpha, Box, styled, SxProps, Theme, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import { useScheduleParameters } from "../../../components/schedule/useScheduleParameters";
import { Vehicle } from "../../../data/api/types/driver";
import { ScheduleDriver } from "../../../data/api/types/schedule";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../../data/schedules/types";
import { useSchedules } from "../../../data/schedules/useSchedules";
import { getShiftTitle, isLateCar } from "../shiftItemHelpers";
import {
  ElectroRoster,
  ElectroRosterCar,
  ElectroRosterDriver,
  ElectroRosterSpecislCarShift,
  ElectroRosterTimetableCarShift,
  ElectroRosterVehicle,
} from "../types/ElectroRoster";
import { Roster } from "../types/Roster";
import { warnOnPromise } from "../warnOnPromise";
import { ElectroDraggableDriverItem } from "./ElectroDraggableDriverItem";
import { ElectroDraggableVehicleItem } from "./ElectroDraggableVehicleItem";
import { ElectroDriverItem } from "./ElectroDriverItem";
import { ElectroVehicleItem } from "./ElectroVehicleItem";

function getCarTitle(car: ElectroRosterCar): string {
  return `${car.lineName}${car.car ? `/${car.car}` : ""}`;
}

export function callWithErrors<
  TData,
  TObject extends ElectroRosterCar | ElectroRosterVehicle | ElectroRosterDriver
>(
  func: (data: TData) => Promise<
    | {
        status: "error";
        errors: any[];
      }
    | {
        status: "ok";
      }
  >,
  data: TData,
  obj: TObject,
  createErrors: (
    result: {
      status: "error";
      errors: any[];
    },
    obj: TObject
  ) => void,
  clearErrors: () => void
) {
  warnOnPromise(func, data)
    .then((result) => {
      if (result.status === "error") {
        createErrors(result, obj);
      } else {
        clearErrors();
      }
    })
    .catch((e) => {
      console.warn("Error dropping item on car:", e);
      createErrors({ status: "error", errors: ["internal_server_error"] }, obj);
    });
}

export function useElectroDropOnShift(
  selectedDate: string,
  isReadOnly: boolean,
  isVehiclesOnly: boolean
) {
  const { electroAddDriverToShift, electroAddVehicleToShift } = useSchedules();

  const [errors, setErrors] = useState<string[]>([]);
  const createErrors = useCallback(
    (
      result: {
        status: "error";
        errors: { error_type?: string | null; message?: string | null }[];
      },
      car: ElectroRosterCar
    ) => {
      const errorMessages = result.errors.map((e) => {
        if (e.message && e.message !== "") {
          return e.message;
        } else {
          return `Неизвестна грешка за смяна ${getCarTitle(car)}`;
        }
      });
      if (errorMessages.length > 0) {
        setErrors(errorMessages);
      } else {
        setErrors([`Неизвестна грешка за смяна ${getCarTitle(car)}`]);
      }
    },
    []
  );

  const clearErrors = useCallback(() => setErrors([]), []);

  const dropDriverOnShift = useCallback(
    (
      car: ElectroRosterCar,
      driver: ElectroRosterDriver,
      shiftNumber: 1 | 2 | null
    ) => {
      if (isReadOnly) {
        return;
      }

      if (driver.type === "driver") {
        if (!shiftNumber) {
          if (driver.shift_type === 1 && car.shift1) {
            console.log("Drop driver on shift", driver, 1);
            callWithErrors(
              electroAddDriverToShift,
              {
                driver_id: driver.schedule_id,
                date: selectedDate,
                shift_type: 1,
                shift_id: car.shift1.shift.shift.workshift.id,
              },
              car,
              createErrors,
              clearErrors
            );
          } else if (driver.shift_type === 2 && car.shift2) {
            console.log("Drop driver on shift", driver, 2);
            callWithErrors(
              electroAddDriverToShift,
              {
                driver_id: driver.schedule_id,
                date: selectedDate,
                shift_type: 2,
                shift_id: car.shift2.shift.shift.workshift.id,
              },
              car,
              createErrors,
              clearErrors
            );
          } else {
            console.warn("Drop driver without shift (1)", driver);
            setErrors([
              `Няма налична смяна за водач ${driver.sl_number}, ${
                driver.shift_type === 2 ? "втора смяна" : "първа смяна"
              }`,
            ]);
          }
        } else if (shiftNumber === 1 && car.shift1) {
          console.log("Drop driver on shift", driver, 1);
          callWithErrors(
            electroAddDriverToShift,
            {
              driver_id: driver.schedule_id,
              date: selectedDate,
              shift_type: shiftNumber,
              shift_id: car.shift1.shift.shift.workshift.id,
            },
            car,
            createErrors,
            clearErrors
          );
        } else if (shiftNumber === 2 && car.shift2) {
          console.log("Drop driver on shift", driver, 2);
          callWithErrors(
            electroAddDriverToShift,
            {
              driver_id: driver.schedule_id,
              date: selectedDate,
              shift_type: shiftNumber,
              shift_id: car.shift2.shift.shift.workshift.id,
            },
            car,
            createErrors,
            clearErrors
          );
        } else {
          console.warn("Drop driver without shift (2)", driver);
          setErrors([
            `Няма налична смяна за водач ${driver.sl_number}, ${
              driver.shift_type === 2 ? "втора смяна" : "първа смяна"
            }`,
          ]);
        }
      } else {
        console.warn("Dropped unknown element:", driver);
        setErrors(["Поставен е неизвестен елемент"]);
      }
    },
    [
      clearErrors,
      createErrors,
      electroAddDriverToShift,
      isReadOnly,
      selectedDate,
    ]
  );

  const dropVehicleOnShift = useCallback(
    (
      car: ElectroRosterCar,
      vehicle: ElectroRosterVehicle,
      shiftNumber: 1 | 2 | null
    ) => {
      if (isReadOnly && !isVehiclesOnly) {
        return;
      }

      if (!shiftNumber) {
        console.log("Drop vehicle on both shifts", vehicle);
        if (car.shift1 && car.shift2) {
          callWithErrors(
            electroAddVehicleToShift,
            {
              date: selectedDate,
              shift1_id: car.shift1.shift.shift.workshift.id,
              shift2_id: car.shift2.shift.shift.workshift.id,
              veh1_id: vehicle.vehicle_id,
            },
            car,
            createErrors,
            clearErrors
          );
        } else if (car.shift1) {
          callWithErrors(
            electroAddVehicleToShift,
            {
              date: selectedDate,
              shift1_id: car.shift1.shift.shift.workshift.id,
              shift2_id: null,
              veh1_id: vehicle.vehicle_id,
            },
            car,
            createErrors,
            clearErrors
          );
        } else if (car.shift2) {
          callWithErrors(
            electroAddVehicleToShift,
            {
              date: selectedDate,
              shift1_id: null,
              shift2_id: car.shift2.shift.shift.workshift.id,
              veh1_id: vehicle.vehicle_id,
            },
            car,
            createErrors,
            clearErrors
          );
        }
      } else if (shiftNumber === 1 && car.shift1) {
        console.log("Drop vehicle on shift", vehicle, 1);
        callWithErrors(
          electroAddVehicleToShift,
          {
            date: selectedDate,
            shift1_id: car.shift1.shift.shift.workshift.id,
            shift2_id: null,
            veh1_id: vehicle.vehicle_id,
          },
          car,
          createErrors,
          clearErrors
        );
      } else if (shiftNumber === 2 && car.shift2) {
        console.log("Drop vehicle on shift", vehicle, 2);
        callWithErrors(
          electroAddVehicleToShift,
          {
            date: selectedDate,
            shift1_id: null,
            shift2_id: car.shift2.shift.shift.workshift.id,
            veh1_id: vehicle.vehicle_id,
          },
          car,
          createErrors,
          clearErrors
        );
      } else {
        console.warn("Drop vehicle without shift (2)", vehicle);
      }
    },
    [
      clearErrors,
      createErrors,
      electroAddVehicleToShift,
      isReadOnly,
      isVehiclesOnly,
      selectedDate,
    ]
  );

  return { dropDriverOnShift, dropVehicleOnShift, errors, clearErrors };
}

export type ElectroCarItemProps = {
  isReadOnly: boolean;
  isVehiclesOnly: boolean;
  drivers: readonly ScheduleDriver[];
  car: ElectroRosterCar;
  selectedDate: string;
  apiVehicles: readonly Vehicle[];
  showVehicleCommentDialog: (vehicle: ElectroRosterVehicle) => void;
  showVehicleStateDialog: (vehicle: ElectroRosterVehicle) => void;
  showCompositionDialog: (vehicle: ElectroRosterVehicle) => void;
  clearVehicleState: (vehicle: ElectroRosterVehicle) => void;
  style?: CSSProperties;

  roster: ElectroRoster;
  driverStyle?: CSSProperties;
  saveDriverComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveDriverShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;

  dropDriverOnShift: (
    car: ElectroRosterCar,
    driver: ElectroRosterDriver,
    shiftNumber: 1 | 2 | null
  ) => void;
  dropVehicleOnShift: (
    car: ElectroRosterCar,
    vehicle: ElectroRosterVehicle,
    shiftNumber: 1 | 2 | null
  ) => void;
  setErrors: (errors: readonly string[]) => void;
  getRoster: () => Roster;
};

const CarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginLeft: 1,
  marginRight: 1,
  border: "1px solid black",
  borderRadius: 3,
  "&:first-of-type": {
    marginTop: 1,
  },
  "&:not(:last-of-type)": {
    "&:not(:only-of-type)": {
      borderBottomColor: grey[400],
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  "&:last-of-type": {
    marginBottom: 1,
  },
  "&:not(:first-of-type)": {
    "&:not(:only-of-type)": {
      borderTop: "none",
      //borderTopColor: grey[400],
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
  alignContent: "center",
  padding: theme.spacing(0.5, 1.5),
  minHeight: 43,
}));

const ShiftItemContainer = styled(Box)(() => ({
  minHeight: 35,
  cursor: "pointer",
  height: "100%",
  backgroundColor: grey[300],
  border: `1px solid ${alpha(grey[900], 0.75)}`,
  borderRadius: 4,
}));

function ShiftItem({
  shift,
  shiftNumber,
  isReadOnly,
  roster,
  selectedDate,
  driverStyle,
  saveDriverComment,
  saveDriverShift,
  onDrop,
}: {
  shift: ElectroRosterTimetableCarShift | ElectroRosterSpecislCarShift | null;
  shiftNumber: 1 | 2;
  isReadOnly: boolean;
  roster: ElectroRoster;
  selectedDate: string;
  driverStyle?: CSSProperties;
  saveDriverComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveDriverShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  onDrop: (value: ElectroRosterDriver) => void;
}) {
  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept: shiftNumber === 1 ? ["driver-shift-1"] : ["driver-shift-2"],
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [shiftNumber, onDrop]
  );
  const isActive = isOver && canDrop;

  if (!shift) {
    return (
      <ShiftItemContainer
        sx={{
          borderColor: alpha(grey[400], 0),
          cursor: "no-drop",
          height: 35,
          marginTop: "1px",
        }}
      />
    );
  } else if (shift.driver) {
    return (
      <Box
        sx={{
          backgroundColor: isActive ? alpha(green[100], 0.75) : undefined,
        }}
        ref={drop}
      >
        {isReadOnly ? (
          <ElectroDriverItem
            isReadOnly={isReadOnly}
            roster={roster}
            driver={shift.driver}
            assigned
            selectedDate={selectedDate}
            saveComment={saveDriverComment}
            saveShift={saveDriverShift}
            style={driverStyle}
          />
        ) : (
          <ElectroDraggableDriverItem
            isReadOnly={isReadOnly}
            roster={roster}
            driver={shift.driver}
            assigned
            selectedDate={selectedDate}
            saveComment={saveDriverComment}
            saveShift={saveDriverShift}
            style={driverStyle}
          />
        )}
      </Box>
    );
  } else {
    return (
      <ShiftItemContainer
        ref={drop}
        sx={{
          borderColor: alpha(grey[400], 0.75),
          height: 35,
          marginTop: "1px",
          backgroundColor: isActive ? alpha(green[100], 0.75) : undefined,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HelpOutline fontSize="large" sx={{ color: alpha(red[400], 0.25) }} />
      </ShiftItemContainer>
    );
  }
}

function VehicleItem({
  isReadOnly,
  isVehiclesOnly,
  wide,
  drivers,
  vehicle,
  selectedDate,
  apiVehicles,
  showCompositionDialog,
  showVehicleCommentDialog,
  showVehicleStateDialog,
  clearVehicleState,
  setErrors,
  getRoster,
}: ElectroCarItemProps & {
  vehicle: ElectroRosterVehicle;
  wide?: boolean;
}) {
  if (isReadOnly && !isVehiclesOnly) {
    return (
      <ElectroVehicleItem
        wide={wide}
        assigned
        isReadOnly={isReadOnly}
        isVehiclesOnly={isVehiclesOnly}
        selectedDate={selectedDate}
        drivers={drivers}
        vehicle={vehicle}
        apiVehicles={apiVehicles}
        showVehicleCommentDialog={showVehicleCommentDialog}
        showVehicleStateDialog={showVehicleStateDialog}
        showCompositionDialog={showCompositionDialog}
        clearVehicleState={clearVehicleState}
        setErrors={setErrors}
        getRoster={getRoster}
      />
    );
  } else {
    return (
      <ElectroDraggableVehicleItem
        wide={wide}
        assigned
        isReadOnly={isReadOnly}
        isVehiclesOnly={isVehiclesOnly}
        selectedDate={selectedDate}
        drivers={drivers}
        vehicle={vehicle}
        apiVehicles={apiVehicles}
        showVehicleCommentDialog={showVehicleCommentDialog}
        showVehicleStateDialog={showVehicleStateDialog}
        showCompositionDialog={showCompositionDialog}
        clearVehicleState={clearVehicleState}
        setErrors={setErrors}
        getRoster={getRoster}
      />
    );
  }
}

const EmptyVehicleBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ sx?: SxProps<Theme> }>
>(({ sx, children }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "row",
        border: `1px solid ${alpha(grey[400], 0.75)}`,
        borderRadius: 1,
        minWidth: 88,
        height: 45,
        marginTop: "2px",
        // marginBottom: "4px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});

function CarVehicles(props: ElectroCarItemProps) {
  const { car, dropVehicleOnShift } = props;

  const [{ isOver: isOver1, canDrop: canDrop1 }, drop1] = useDrop(
    {
      accept: car.shift1?.driver ? ["electro-vehicle-item"] : [],
      drop: (vehicle: ElectroRosterVehicle) =>
        dropVehicleOnShift(car, vehicle, 1),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [dropVehicleOnShift, car]
  );
  const isActive1 = isOver1 && canDrop1;

  const [{ isOver: isOver2, canDrop: canDrop2 }, drop2] = useDrop(
    {
      accept: car.shift2?.driver ? ["electro-vehicle-item"] : [],
      drop: (vehicle: ElectroRosterVehicle) =>
        dropVehicleOnShift(car, vehicle, 2),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [dropVehicleOnShift, car]
  );
  const isActive2 = isOver2 && canDrop2;

  const [{ isOver: isOverBoth, canDrop: canDropBoth }, dropBoth] = useDrop(
    {
      accept:
        car.shift1?.driver || car.shift2?.driver
          ? ["electro-vehicle-item"]
          : [],
      drop: (vehicle: ElectroRosterVehicle) =>
        dropVehicleOnShift(car, vehicle, null),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [dropVehicleOnShift, car]
  );
  const isActiveBoth = isOverBoth && canDropBoth;

  if (
    car.shift1?.vehicle &&
    car.shift1?.vehicle?.vehicle_id === car.shift2?.vehicle?.vehicle_id
  ) {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", minWidth: 180 }}>
        <Box
          ref={dropBoth}
          sx={{
            width: 178,
            backgroundColor: isActiveBoth ? alpha(green[100], 0.25) : undefined,
          }}
        >
          <VehicleItem {...props} vehicle={car.shift1.vehicle} wide />
        </Box>
      </Box>
    );
  } else if (car.shift1?.vehicle && car.shift2?.vehicle) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "row", minWidth: 180 }}>
          <Box
            sx={{
              width: 86,
              backgroundColor: isActive1 ? alpha(green[100], 0.25) : undefined,
            }}
            ref={drop1}
          >
            <VehicleItem {...props} vehicle={car.shift1.vehicle} />
          </Box>
          <Box sx={{ minWidth: "6px", maxWidth: "6px" }} />
          <Box
            sx={{
              width: 86,
              backgroundColor: isActive2 ? alpha(green[100], 0.25) : undefined,
            }}
            ref={drop2}
          >
            <VehicleItem {...props} vehicle={car.shift2.vehicle} />
          </Box>
        </Box>
      </>
    );
  } else if (car.shift1?.vehicle) {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", minWidth: 180 }}>
        <Box
          sx={{
            width: 86,
            backgroundColor: isActive1 ? alpha(green[100], 0.25) : undefined,
          }}
          ref={drop1}
        >
          <VehicleItem {...props} vehicle={car.shift1.vehicle} />
        </Box>
        <Box sx={{ minWidth: "6px", maxWidth: "6px" }} />
        <EmptyVehicleBox
          ref={car.shift2 ? drop2 : undefined}
          sx={{
            backgroundColor: isActive2 ? alpha(green[100], 0.25) : undefined,
            cursor: !car.shift2 ? "no-drop" : undefined,
            borderColor: !car.shift2 ? alpha(grey[400], 0) : undefined,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {car.shift2 && (
            <HelpOutline
              fontSize="large"
              sx={{ color: alpha(red[400], 0.25) }}
            />
          )}
        </EmptyVehicleBox>
      </Box>
    );
  } else if (car.shift2?.vehicle) {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", minWidth: 180 }}>
        <EmptyVehicleBox
          ref={car.shift1 ? drop1 : undefined}
          sx={{
            backgroundColor: isActive1 ? alpha(green[100], 0.25) : undefined,
            cursor: !car.shift1 ? "no-drop" : undefined,
            borderColor: !car.shift1 ? alpha(grey[400], 0) : undefined,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {car.shift1 && (
            <HelpOutline
              fontSize="large"
              sx={{ color: alpha(red[400], 0.25) }}
            />
          )}
        </EmptyVehicleBox>
        <Box sx={{ minWidth: "4px", maxWidth: "4px" }} />
        <Box
          sx={{
            width: 86,
            backgroundColor: isActive2 ? alpha(green[100], 0.25) : undefined,
          }}
          ref={drop2}
        >
          <VehicleItem {...props} vehicle={car.shift2.vehicle} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", minWidth: 180 }}>
        <EmptyVehicleBox
          ref={car.shift1 ? drop1 : undefined}
          sx={{
            backgroundColor: isActive1 ? alpha(green[100], 0.25) : undefined,
            cursor: !car.shift1 ? "no-drop" : undefined,
            borderColor: !car.shift1 ? alpha(grey[400], 0) : undefined,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {car.shift1 && (
            <HelpOutline
              fontSize="large"
              sx={{ color: alpha(red[400], 0.25) }}
            />
          )}
        </EmptyVehicleBox>
        <Box sx={{ minWidth: "4px", maxWidth: "4px" }} />
        <EmptyVehicleBox
          ref={car.shift2 ? drop2 : undefined}
          sx={{
            backgroundColor: isActive2 ? alpha(green[100], 0.25) : undefined,
            cursor: !car.shift2 ? "no-drop" : undefined,
            borderColor: !car.shift2 ? alpha(grey[400], 0) : undefined,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {car.shift2 && (
            <HelpOutline
              fontSize="large"
              sx={{ color: alpha(red[400], 0.25) }}
            />
          )}
        </EmptyVehicleBox>
      </Box>
    );
  }
}

function isDriver(
  value: ElectroRosterVehicle | ElectroRosterDriver
): value is ElectroRosterDriver {
  return Object.hasOwn(value, "type") && (value as any).type === "driver";
}

function CarItemElement(props: ElectroCarItemProps) {
  const [{ isOver: isOver, canDrop: canDrop }, drop] = useDrop(
    {
      accept: !props.car.shift1
        ? props.car.shift2?.driver
          ? ["driver-shift-2", "electro-vehicle-item"]
          : ["driver-shift-2"]
        : !props.car.shift2
        ? props.car.shift1?.driver
          ? ["driver-shift-1", "electro-vehicle-item"]
          : ["driver-shift-1"]
        : props.car.shift1?.driver || props.car.shift2?.driver
        ? ["driver-shift-1", "driver-shift-2", "electro-vehicle-item"]
        : ["driver-shift-1", "driver-shift-2"],
      drop: (value: ElectroRosterVehicle | ElectroRosterDriver) =>
        isDriver(value)
          ? props.dropDriverOnShift(props.car, value, null)
          : props.dropVehicleOnShift(props.car, value, null),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [props.dropDriverOnShift, props.dropVehicleOnShift, props.car]
  );
  const isActive = isOver && canDrop;

  const { scheduleParameters } = useScheduleParameters();

  return (
    <CarContainer
      sx={{
        backgroundColor: grey[300],
        position: "relative",
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
      title={
        scheduleParameters
          ? getShiftTitle(
              props.car.shift1?.shift || null,
              props.car.shift2?.shift || null,
              props.selectedDate,
              null,
              scheduleParameters[0]
            )
          : ""
      }
    >
      <Box
        ref={drop}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minWidth: 75,
          backgroundColor: isActive ? alpha(green[100], 0.75) : undefined,
        }}
      >
        <Typography fontWeight="bold" fontSize={12} lineHeight={1.4}>
          {props.car.lineName +
            (props.car.car && props.car.car > 0 ? `/${props.car.car}` : "")}
        </Typography>
        {props.car.places && props.car.places > 0 && (
          <Typography fontWeight="normal" fontSize={10} lineHeight={1.2}>
            Места: {props.car.places}
          </Typography>
        )}
        {props.car.shift1 && (
          <Box>
            <Typography
              fontWeight={
                isLateCar(props.car.shift1.shift, props.selectedDate)
                  ? "bold"
                  : "normal"
              }
              fontSize={10}
              lineHeight={1.2}
              sx={{
                color: isLateCar(props.car.shift1.shift, props.selectedDate)
                  ? red[900]
                  : undefined,
              }}
            >
              &nbsp;I:{" "}
              {`${props.car.shift1.shift.start_time.format(
                "HH:mm"
              )} - ${props.car.shift1.shift.end_time.format("HH:mm")}`}
            </Typography>
            <Typography
              fontWeight={
                props.car.shift1.shift.work_time > 480 ? "bold" : "normal"
              }
              fontSize={10}
              lineHeight={1.2}
              sx={{
                color:
                  props.car.shift1.shift.work_time > 480 ? red[900] : undefined,
              }}
            >
              &nbsp;I: Зар.:{" "}
              {Math.floor(props.car.shift1.shift.work_time / 60)
                .toString()
                .padStart(2, "0")}
              :
              {(props.car.shift1.shift.work_time % 60)
                .toString()
                .padStart(2, "0")}
            </Typography>
          </Box>
        )}
        {props.car.shift2 && (
          <Box>
            <Typography
              fontWeight={
                isLateCar(props.car.shift2.shift, props.selectedDate)
                  ? "bold"
                  : "normal"
              }
              fontSize={10}
              lineHeight={1.2}
              sx={{
                color: isLateCar(props.car.shift2.shift, props.selectedDate)
                  ? red[900]
                  : undefined,
              }}
            >
              II:{" "}
              {`${props.car.shift2.shift.start_time.format(
                "HH:mm"
              )} - ${props.car.shift2.shift.end_time.format("HH:mm")}`}
            </Typography>
            <Typography
              fontWeight={
                props.car.shift2.shift.work_time > 480 ? "bold" : "normal"
              }
              fontSize={10}
              lineHeight={1.2}
              sx={{
                color:
                  props.car.shift2.shift.work_time > 480 ? red[900] : undefined,
              }}
            >
              II: Зар.:
              {Math.floor(props.car.shift2.shift.work_time / 60)
                .toString()
                .padStart(2, "0")}
              :
              {(props.car.shift2.shift.work_time % 60)
                .toString()
                .padStart(2, "0")}
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ minWidth: 88 }}>
            <ShiftItem
              shiftNumber={1}
              shift={props.car.shift1}
              isReadOnly={props.isReadOnly}
              roster={props.roster}
              selectedDate={props.selectedDate}
              saveDriverComment={props.saveDriverComment}
              saveDriverShift={props.saveDriverShift}
              onDrop={(driver) => props.dropDriverOnShift(props.car, driver, 1)}
            />
          </Box>
          <Box sx={{ minWidth: "4px", maxWidth: "4px" }} />
          <Box sx={{ minWidth: 88 }}>
            <ShiftItem
              shiftNumber={2}
              shift={props.car.shift2}
              isReadOnly={props.isReadOnly}
              roster={props.roster}
              selectedDate={props.selectedDate}
              saveDriverComment={props.saveDriverComment}
              saveDriverShift={props.saveDriverShift}
              onDrop={(driver) => props.dropDriverOnShift(props.car, driver, 2)}
            />
          </Box>
        </Box>

        <CarVehicles {...props} />
      </Box>
    </CarContainer>
  );
}

export const ElectroCarItem = forwardRef(
  (
    { style, ...props }: ElectroCarItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    // const { scheduleParameters } = useScheduleParameters();

    return (
      <div
        ref={ref}
        style={{
          display: "inline-flex",
          flexDirection: "column",
          margin: 0,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          fontSize: 12,
          fontWeight: "bold",
          //cursor: "pointer",
          minWidth: 200,
          ...style,
        }}
      >
        <CarItemElement {...props} />
      </div>
    );
  }
);
