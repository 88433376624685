import { Error } from "@mui/icons-material";
import { Container, Paper, useTheme } from "@mui/material";
import { memo } from "react";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { SchedulesContext } from "../../data/schedules/SchedulesContext";
import { DriverRosterImpl } from "./DriverRosterImpl";
import { ElectroDriverRosterImpl } from "./electro/ElectroDriverRosterImpl";

export const DriverRoster = memo(function DriverRoster() {
  const theme = useTheme();
  const profile = useProfile();
  const { scheduleParameters } = useScheduleParameters();

  const canViewRoster = profile?.roles?.includes("view_roster");
  const isElectroInterfaceEnabled =
    profile?.roles?.includes("electro_interface");

  if (canViewRoster) {
    return (
      <SchedulesContext>
        {isElectroInterfaceEnabled &&
        scheduleParameters &&
        (scheduleParameters[0] === "TM" || scheduleParameters[0] === "TB") ? (
          <ElectroDriverRosterImpl />
        ) : (
          <DriverRosterImpl />
        )}
      </SchedulesContext>
    );
  } else {
    return (
      <Container sx={{ mt: 4 }}>
        <Paper sx={{ px: 3, py: 1, textAlign: "center" }}>
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette.error.dark,
            }}
          >
            <Error fontSize="large" sx={{ mr: 4 }} />
            <span>Страницата не е намерена!</span>
          </h2>
        </Paper>
      </Container>
    );
  }
});
