import { DriverSkills } from "./driver";

export type Transport = "A" | "TM" | "TB";
export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ScheduleParameters = [
  Transport,
  number,
  number,
  Month,
  number,
  number
];

export type DayTask = {
  id: string;
  comment: string | null;
  veh1_id: number | null;
  veh2_id: number | null;
  shift_id: string | null;
  workshift_id: string | null;
  special_shift_id: string | null;
  username: string | null;
  inserted_at: string | null;
  updated_at: string | null;
  displaced_task?: boolean;

  // нови полета
  shift_type: 1 | 2 | null;
  linename: string | null;
  car_no: number | null;
  start_time: string | null;
  end_time: string | null;
  work_seconds: number;
  line_id: number | null | undefined;
};

export type ScheduleDay = {
  autocolumn: number;
  comment: string | null;
  day_in_month: string;
  full_id: string;
  nariad_info_state: null;
  state: number;
  state_name: string;
  state_short_name: string;
  desired_state: string | null;
  desired_state_id: number | null;
  pre_state_id: number | null;
  pre_state: {
    abbrev: string;
    available: boolean;
    description: string;
    hard: boolean;
    score: number | null;
    shift_category: number | null;
    shift_type: number | null;
    state_id: number;
  } | null;
  //schedule_state: {
  //  available: "desired";
  //  description: string;
  //  description_short: string;
  //  id: number;
  //  score: number;
  //  show_in_interface: boolean;
  //  sumc_ids: number[];
  //};
  total_workseconds: number;
  day_workseconds_by_roster: number;
  total_quota: number;
  work_depot_id?: number;
  work_week_workseconds: number | null;
  workshift: {
    shift_type: 1 | 2 | null;
    linename: string | null;
    car_no: number | null;
    start_time: string | null;
    end_time: string | null;
    work_seconds: number;
    line_id: number | null | undefined;
  };
  issues: {
    id: number;
    description: string;
    actual?: number;
    target?: number;
    level?: number;
  }[];
  roster_issues: {
    id: number;
    actual?: number;
    level: number;
    target?: number;
    description: string;
  }[];
  daytasks: DayTask[];
  intershift_times:
    | {
        current_task_line: string | null;
        prev_task_line: string | null;
        time: number;
      }[]
    | null;
  expired_docs?: boolean;
};

export type ScheduleDriverBase = {
  id: string;
  schedule_id: number;
  sl_number: number;
  name: string | null | undefined;
  quota: number;
  //current_quota: number;
  //current_workseconds: number;
  //driver_days: ScheduleDay[];
  home_depot_id?: number;
  general_issues: ScheduleDay["issues"];
  category: {
    id: number;
    name: string;
  };
  attributes: {
    work_by_schedule: boolean;
    preferred_vehicle: number | null;
    preferred_vehicle_type?: number | null;
    preferred_workshift: number | null;
    vehicletypes_skills: DriverSkills;
    color_id?: number | null;
    rest_on_weekends?: boolean | null;
    no_overtime?: boolean | null;
    quota_override?: number | null;
  };
  old_data: {
    cnt: number | null;
    description: string;
    description_short: string;
    state_id: number | null;
  };
  previous_cats?: {
    last_cat_cnt?: number;
    last_cat_id?: number;
    prev_cat_cnt?: number;
    prev_cat_id?: number;
  };
};

export type ScheduleDriver = ScheduleDriverBase & {
  driver_days: Record<string, ScheduleDay>;
};

export type ScheduleBase = {
  sequence: number;
  day_empty_shifts: {
    date: string;
    vehicletypes_empty_shifts: {
      empty_count: number;
      vehicletype_id: number | null;
    }[];
  }[];
  day_issues: {
    empty_shifts: number;
  }[];
  db_schedule: {
    autocolumn: number;
    brigade: number;
    depot_id: number;
    full_id: null;
    month: Month;
    transport: Transport;
    year: number;
    preliminary_ready?: boolean | null;
    preliminary_ready_time?: string | null;
    preliminary_ready_user?: string | null;
  };
  expl_plan_counts: {
    shift_type: number;
    counts: number[];
  }[];
  expl_plan_counts_by_vt: {
    vehicletype_id: number | null;
    shifts_counts: {
      shift_type: number;
      counts: number;
    }[];
  }[][];
  drivers_sync_status: {
    status: "starting" | "started" | "ok";
  };
  planner_status: {
    status:
      | "start_request"
      | "started"
      | "stop_request"
      | "stopped"
      | "unknown"
      | "error";
  };
  save_status: {
    status: "ok" | "needs_save" | "saving";
  };
  expl_plan_status: {
    status: "ok" | "loading";
  };
};

export type Schedule = ScheduleBase & {
  drivers: Record<string, ScheduleDriver>;
};

export type MessageDriver = ScheduleDriverBase & {
  driver_days: ScheduleDay[];
};

export type MessageSchedule = ScheduleBase & {
  drivers: MessageDriver[];
};

export function indexFromScheduleParameters([
  transport,
  depot_id,
  year,
  month,
  autocolumn,
  brigada,
]: ScheduleParameters): string {
  return `schedules:${transport}:${depot_id}:${year}:${month}:${autocolumn}:${brigada}`;
}

export function indexFromVehicleParameters([
  transport,
  depot_id,
  year,
  month,
  autocolumn,
  brigada,
]: ScheduleParameters): string {
  return `vehicles:${transport}:${depot_id}:${year}:${month}:${autocolumn}:${brigada}`;
}

export type IsAny<T> = unknown extends T
  ? [keyof T] extends [never]
    ? false
    : true
  : false;

type ExcludeArrayKeys<T> = T extends ArrayLike<any>
  ? Exclude<keyof T, keyof any[]>
  : keyof T;

type PathImpl<T, Key extends keyof T> = Key extends string
  ? IsAny<T[Key]> extends true
    ? never
    : T[Key] extends Record<string, any>
    ?
        | `${Key}.${PathImpl<T[Key], ExcludeArrayKeys<T[Key]>> & string}`
        | `${Key}.${ExcludeArrayKeys<T[Key]> & string}`
    : never
  : never;

type PathImpl2<T> = PathImpl<T, keyof T> | keyof T;

export type Path<T> = keyof T extends string
  ? PathImpl2<T> extends infer P
    ? P extends string | keyof T
      ? P
      : keyof T
    : keyof T
  : never;

export type Choose<
  T extends Record<string | number, any>,
  K extends Path<T>
> = K extends `${infer U}.${infer Rest}`
  ? Rest extends Path<T[U]>
    ? Choose<T[U], Rest>
    : never
  : T[K];

function access<T>(obj: T, path: Path<T>) {
  return path
    .split(".")
    .reduce(
      (o, i) => (o === null || o === undefined ? undefined : (o as any)[i]),
      obj
    );
}

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type DeepWriteable<T> = {
  -readonly [P in keyof T]: DeepWriteable<T[P]>;
};

export function sortArray<T>(
  array: readonly T[] | null | undefined,
  key?: Array<Path<T>> | Path<T>,
  opts?: {
    in_place?: false | undefined;
    reverse?: boolean;
  }
): T[];

export function sortArray<T>(
  array: T[] | null | undefined,
  key: Array<Path<T>> | Path<T> | undefined,
  opts: {
    in_place: true;
    reverse?: boolean;
  }
): T[];

export function sortArray<T>(
  array: T[] | readonly T[] | null | undefined,
  key?: Array<Path<T>> | Path<T>,
  {
    in_place,
    reverse,
  }: {
    in_place?: boolean;
    reverse?: boolean;
  } = {
    in_place: false,
    reverse: false,
  }
): T[] {
  if (array === null || array === undefined || array.length === 0) {
    return [];
  } else {
    const result = reverse ? -1 : 1;
    if (key === null || key === undefined) {
      const xs = in_place ? array : [...array];
      return (xs as Writeable<typeof xs>).sort((a, b) =>
        a < b ? -result : a > b ? result : 0
      );
    } else if (Array.isArray(key)) {
      const xs = in_place ? array : [...array];
      return (xs as Writeable<typeof xs>).sort((a, b) => {
        for (const k of key) {
          if (access(a, k) < access(b, k)) {
            return -result;
          } else if (access(a, k) > access(b, k)) {
            return result;
          }
        }
        return 0;
      });
    } else {
      const xs = in_place ? array : [...array];
      return (xs as Writeable<typeof xs>).sort((a, b) =>
        access(a, key) < access(b, key)
          ? -result
          : access(a, key) > access(b, key)
          ? result
          : 0
      );
    }
  }
}
