interface WorkShiftCountColumn {
  id:
    | "linenum"
    | "tt_code"
    | "car_count"
    | "first_shift_count"
    | "second_shift_count"
    | "total_shifts";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

export const WorkShiftCountColumns: readonly WorkShiftCountColumn[] = [
  { id: "linenum", label: "Линия", minWidth: 80 },
  { id: "tt_code", label: "Разписание", minWidth: 120 },

  {
    id: "first_shift_count",
    label: "I ва смяна(бр.)",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "second_shift_count",
    label: "II ра смяна(бр.)",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "total_shifts",
    label: "Общо смени (бр.)",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

export interface WorkShiftCountRowData {
  linenum: string;
  tt_code: string;
  car_count: number;
  first_shift_count: number;
  second_shift_count: number;
  total_shifts: number;
}

export function createWorkShiftCountData(
  linenum: string,
  tt_code: string,
  car_count: number,
  first_shift_count: number,
  second_shift_count: number
): WorkShiftCountRowData {
  const total_shifts = first_shift_count + second_shift_count;
  return {
    linenum,
    tt_code,
    car_count,
    first_shift_count,
    second_shift_count,
    total_shifts,
  };
}
