import { Transport } from "../data/api/types/driver";
import { useProfile } from "./useProfile";

export type PlannerSettings = {
  calc_timeout: number;
  enable_ktd: boolean;
  ktd_coefficient: number;
  hard_expl_plan: boolean;
  max_rests_correction: number;
  max_workdays: number;

  min_workdays: number;
  max_single_rests: number;
  max_expl_plan_difference: number;
  connect_previous_month: boolean;
  ignore_leaves: boolean;

  max_weekend_rests: number;
  weekend_ratio: number;

  num_workers: number;
};

export type GarageSettings = {
  scheduleSortOrder: "by_driver_id" | "by_vehicle_type";
  printJourneyListSummary: boolean;
  printJourneyListActualNextDate: boolean;
  printJourneyListActualNextDateForSpecialShifts: boolean;
  splitExportedCars: boolean;
  splitNightCars: boolean;
  printUserNames: boolean;
  printFakeLeaves: boolean;
  printFakeSickLeaves: boolean;
  printFakeWork: boolean;
  enablePlannerDialog: boolean;
  plannerSettings: PlannerSettings;
  printAdditionalTable: boolean;
  printPowerConsumption: boolean;
};

export const GARAGE_SETTINGS: Record<string, GarageSettings> = {
  "A-9": {
    scheduleSortOrder: "by_vehicle_type",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    printFakeLeaves: false,
    printFakeSickLeaves: false,
    printFakeWork: false,
    enablePlannerDialog: false,
    plannerSettings: {
      calc_timeout: 30 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: false,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 2,
      max_expl_plan_difference: 5,
      connect_previous_month: false,
      ignore_leaves: true,
      max_weekend_rests: 4,
      weekend_ratio: 0,
      num_workers: 0.9,
    },
    printAdditionalTable: false,
    printPowerConsumption: false,
  },
  "A-10": {
    scheduleSortOrder: "by_driver_id",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    printFakeLeaves: false,
    printFakeSickLeaves: false,
    printFakeWork: false,
    enablePlannerDialog: false,
    plannerSettings: {
      calc_timeout: 30 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 3,
      max_expl_plan_difference: 5,
      connect_previous_month: true,
      ignore_leaves: true,
      max_weekend_rests: 4,
      weekend_ratio: 0,
      num_workers: 0.9,
    },
    printAdditionalTable: false,
    printPowerConsumption: false,
  },
  "A-11": {
    scheduleSortOrder: "by_driver_id",
    printJourneyListSummary: true,
    // printJourneyListActualNextDate: true,
    printJourneyListActualNextDate: false, // 2024-04-15: Ася от Дружба иска пътните листа на нощните линии да са с датата на наряда, от някаква проверка им го искали
    printJourneyListActualNextDateForSpecialShifts: true, // 2024-04-18: Ася от Дружба иска пътните листа на персоналните да си останат с утрешна дата
    splitExportedCars: false, //2024-08-09: Ася от Дружба иска пътните листа да не са разделени да има за всяка линия различен пътен лист, когато се обслужват от един автобус
    splitNightCars: true,
    printUserNames: true,
    printFakeLeaves: false,
    printFakeSickLeaves: false,
    printFakeWork: false,
    enablePlannerDialog: false,
    plannerSettings: {
      calc_timeout: 30 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 3,
      max_expl_plan_difference: 5,
      connect_previous_month: true,
      ignore_leaves: true,
      max_weekend_rests: 4,
      weekend_ratio: 0,
      num_workers: 0.9,
    },
    printAdditionalTable: false,
    printPowerConsumption: false,
  },
  "TB-5": {
    // Тролеи Искър
    scheduleSortOrder: "by_driver_id",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    // Искър - само отпуски
    printFakeLeaves: true,
    printFakeSickLeaves: false,
    printFakeWork: true,
    enablePlannerDialog: true,
    plannerSettings: {
      calc_timeout: 60 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 3,
      max_expl_plan_difference: 0,
      connect_previous_month: true,
      ignore_leaves: false,
      max_weekend_rests: 2,
      weekend_ratio: 5,
      num_workers: 0.9,
    },
    printAdditionalTable: true,
    printPowerConsumption: true,
  },
  "TB-6": {
    // Тролеи Надежда
    scheduleSortOrder: "by_driver_id",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    // Надежда - отпуски и болнични
    printFakeLeaves: true,
    printFakeSickLeaves: true,
    printFakeWork: true,
    enablePlannerDialog: true,
    plannerSettings: {
      calc_timeout: 60 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 2,
      max_workdays: 6,
      max_single_rests: 3,
      max_expl_plan_difference: 1,
      connect_previous_month: true,
      ignore_leaves: false,
      max_weekend_rests: 5,
      weekend_ratio: 5,
      num_workers: 0.9,
    },
    printAdditionalTable: true,
    printPowerConsumption: true,
  },
  "TM-4": {
    // Трамваи Искър
    scheduleSortOrder: "by_driver_id",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    printFakeLeaves: false,
    printFakeSickLeaves: false,
    printFakeWork: true,
    enablePlannerDialog: false,
    plannerSettings: {
      calc_timeout: 60 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 1,
      max_expl_plan_difference: 5,
      connect_previous_month: false,
      ignore_leaves: false,
      max_weekend_rests: 5,
      weekend_ratio: 0,
      num_workers: 0.9,
    },
    printAdditionalTable: true,
    printPowerConsumption: false,
  },
  default: {
    scheduleSortOrder: "by_driver_id",
    // scheduleSortOrder: "by_vehicle_type",
    printJourneyListSummary: false,
    printJourneyListActualNextDate: false,
    printJourneyListActualNextDateForSpecialShifts: false,
    splitExportedCars: false,
    splitNightCars: false,
    printUserNames: false,
    printFakeLeaves: false,
    printFakeSickLeaves: false,
    printFakeWork: true,
    enablePlannerDialog: false,
    plannerSettings: {
      calc_timeout: 60 * 60,
      enable_ktd: true,
      ktd_coefficient: 1.0,
      hard_expl_plan: true,
      max_rests_correction: 0,
      min_workdays: 3,
      max_workdays: 6,
      max_single_rests: 1,
      max_expl_plan_difference: 8,
      connect_previous_month: false,
      ignore_leaves: false,
      max_weekend_rests: 5,
      weekend_ratio: 0,
      num_workers: 0.9,
    },
    printAdditionalTable: true,
    printPowerConsumption: false,
  },
};

export function getSettingsByGarage(transport: Transport | "", garage: number) {
  return (
    GARAGE_SETTINGS[`${transport}-${garage}`] || GARAGE_SETTINGS["default"]
  );
}

export function useGarageSettings(): GarageSettings {
  const profile = useProfile();

  if (profile) {
    const transports = Object.keys(profile.transports);

    if (transports.length === 1) {
      const transport = profile.transports[transports[0]];
      const garages = Object.keys(transport);

      if (garages.length === 1) {
        const garage = `${transports[0]}-${garages[0]}`;
        //console.log("Garage settings:", garage);

        return GARAGE_SETTINGS[garage] || GARAGE_SETTINGS["default"];
      }
    }

    //console.log("Garage settings:", "default");
    return GARAGE_SETTINGS["default"];
  } else {
    return GARAGE_SETTINGS["default"];
  }
}
