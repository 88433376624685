import { PureComponent, ReactNode } from "react";
import { ComponentProps, TableRowRef } from "./types";
import { filterGeneralIssues } from "./useTableData";
import { green, red } from "@mui/material/colors";
import { DataRow } from "./DataRow";
import { formatHours } from "./formatHours";

export class RowRenderer extends PureComponent<ComponentProps> {
  styleForWorkshifts(index: number): {
    backgroundColor?: string;
    color?: string;
    textShadow?: string;
    fontWeight?: string;
  } {
    const currentRow = this.props.data.rows[index];
    if (currentRow.rowType === "driver") {
      if (
        this.props.data.driverSummary[currentRow.driver.id].workshifts1 +
          this.props.data.driverSummary[currentRow.driver.id].workshifts2 <
        this.props.data.driverSummary[currentRow.driver.id].quota
      ) {
        return {
          backgroundColor: this.props.data.theme.palette.error.main,
          color: this.props.data.theme.palette.error.contrastText,
          textShadow: "rgba(0, 0, 0, 1) 0px 0px 2px",
        };
      } else if (
        this.props.data.driverSummary[currentRow.driver.id].workshifts1 +
          this.props.data.driverSummary[currentRow.driver.id].workshifts2 >
        this.props.data.driverSummary[currentRow.driver.id].quota
      ) {
        return {
          backgroundColor: this.props.data.theme.palette.warning.main,
          color: this.props.data.theme.palette.warning.contrastText,
          textShadow: "rgba(0, 0, 0, 1) 0px 0px 2px",
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  createHeaders(row: TableRowRef) {
    const yesterday = this.props.data.today.clone().subtract(1, "day");

    let rsTitle = "Работни смени / Норма за месеца (в дни)";
    let rsContent: ReactNode =
      row.rowType === "driver"
        ? `${
            this.props.data.driverSummary[row.driver.id].workshifts1 +
            this.props.data.driverSummary[row.driver.id].workshifts2
          } / ${
            row.driver.attributes?.quota_override
              ? Math.round(row.driver.attributes.quota_override / 8)
              : this.props.data.driverSummary[row.driver.id].quota
          }`
        : "";
    // let isRsExcluded = false;
    let rsStyle = this.styleForWorkshifts(this.props.index);

    // if (row.rowType === "driver" && row.driver.attributes?.quota_override) {
    //   console.log("Quota override:", row.driver);
    // }

    if (
      row.rowType === "driver" &&
      (row.driver.category?.id === 2 || row.driver.category?.id === 4)
    ) {
      rsTitle = row.driver.category?.name || "чл.110";
      rsContent = (
        <div style={{ fontSize: 11, textAlign: "center" }}>
          чл.110
          <br />
          {rsContent}
        </div>
      );
      // isRsExcluded = true;
      rsStyle = {
        fontWeight: "normal",
        backgroundColor: this.props.data.theme.palette.success.light,
        color: this.props.data.theme.palette.success.contrastText,
      };
    } else if (row.rowType === "driver" && row.driver.category?.id === 9) {
      rsTitle = row.driver.category.name || "чл.114";
      rsContent = (
        <div style={{ fontSize: 11, textAlign: "center" }}>
          чл.114
          <br />
          {rsContent}
        </div>
      );
      // isRsExcluded = true;
      rsStyle = {
        fontWeight: "normal",
        backgroundColor: this.props.data.theme.palette.success.light,
        color: this.props.data.theme.palette.success.contrastText,
      };
    }

    return row.rowType === "driver"
      ? [
          {
            title: row.headerTitle,
            content: row.header,
            subheader: row.subheader,
            style: {
              backgroundColor:
                row.driver.attributes?.color_id === 1
                  ? red[100]
                  : row.driver.attributes?.color_id === 2
                  ? green[100]
                  : undefined,
              color:
                filterGeneralIssues(row.driver).length > 0
                  ? this.props.data.theme.palette.error.main
                  : undefined,
            },
          },
          {
            title: rsTitle,
            content: rsContent,
            style: rsStyle,
          },
          {
            title: `Извънредно работно време (в часове) до ${
              this.props.data.selectedDate
                ? this.props.data.selectedDate.format("L")
                : yesterday.format("L")
            } включително`,
            content: this.props.data.selectedDate
              ? formatHours(
                  row.driver.driver_days[
                    this.props.data.selectedDate.format("YYYY-MM-DD")
                  ]?.total_workseconds || 0,
                  row.driver.driver_days[
                    this.props.data.selectedDate.format("YYYY-MM-DD")
                  ]?.total_quota || 0
                )
              : formatHours(
                  row.driver.driver_days[yesterday.format("YYYY-MM-DD")]
                    ?.total_workseconds || 0,
                  row.driver.driver_days[yesterday.format("YYYY-MM-DD")]
                    ?.total_quota || 0
                ),
          },
          {
            title: "Почивки по график",
            content: this.props.data.driverSummary[row.driver.id].rests,
          },
          {
            title: `${
              this.props.data.driverSummary[row.driver.id].nowork -
              this.props.data.driverSummary[row.driver.id].nowork_leave
            } болнични и ${
              this.props.data.driverSummary[row.driver.id].nowork_leave
            } отпуска`,
            content: this.props.data.driverSummary[row.driver.id].nowork,
            style: {
              backgroundColor:
                this.props.data.driverSummary[row.driver.id].nowork > 0
                  ? this.props.data.theme.palette.schedule.leave
                  : undefined,
            },
          },
          {
            title: "Последно състояние предходен месец",
            content: this.props.data.driverSummary[row.driver.id].prevLastState,
            style: {},
          },
        ]
      : [];
  }

  render() {
    if (
      this.props.index >= 0 &&
      this.props.index < this.props.data.rows.length
    ) {
      const row = this.props.data.rows[this.props.index];

      return (
        <DataRow
          headerWidth={this.props.data.headerColumnWidth}
          firstHeaderWidth={this.props.data.firstHeaderColumnWidth}
          today={this.props.data.today}
          isHoliday={this.props.data.isHoliday}
          style={this.props.style}
          theme={this.props.data.theme}
          row={row}
          rowIndex={this.props.index}
          headers={this.createHeaders(row)}
          onContextMenu={this.props.data.onContextMenu}
          onSelectionStart={this.props.data.onSelectionStart}
          onSelectionDrag={this.props.data.onSelectionDrag}
          onSelectionEnd={this.props.data.onSelectionEnd}
          setSelection={this.props.data.setSelection}
        />
      );
    } else {
      return (
        <DataRow
          headerWidth={this.props.data.headerColumnWidth}
          firstHeaderWidth={this.props.data.firstHeaderColumnWidth}
          today={this.props.data.today}
          isHoliday={this.props.data.isHoliday}
          style={this.props.style}
          theme={this.props.data.theme}
          row={this.props.data.cols.length}
          headers={[{}, {}, {}, {}, {}, {}]}
          rowIndex={0}
          setSelection={this.props.data.setSelection}
        />
      );
    }
  }
}
