import {
  useGetVehicleStatesQuery,
  useGetVehiclesRosterQuery,
} from "../../../data/api/hooks";
import { Transport } from "../../../data/api/types/driver";
import { useMemo } from "react";
import moment from "moment";

export function useVehicleRecapitulationReport(
  selectedTransport: Transport | "",
  selectedDepot: number,
  selectedDate: string,
  selectedAvtoColumn: number,
  veh_with_roster_ids: number[]
) {
  const states = useGetVehicleStatesQuery(selectedTransport);

  const getVehiclesRosterQueryData = useMemo(
    () => ({
      transport: selectedTransport,
      garage: selectedDepot,
      year: selectedDate ? moment(selectedDate, "YYYY-MM-DD").year() : 0,
      month: selectedDate ? moment(selectedDate, "YYYY-MM-DD").month() + 1 : 0,
      avtocolona: selectedAvtoColumn,
    }),
    [selectedAvtoColumn, selectedDate, selectedDepot, selectedTransport]
  );

  const { data: vehicles } = useGetVehiclesRosterQuery(
    getVehiclesRosterQueryData,
    {
      refetchOnMountOrArgChange: true,
      skip: !(selectedDepot !== -1 && selectedTransport !== ""),
    }
  );

  const rows = useMemo(() => {
    const resState = states.data?.data.find((s) =>
      s.description.toUpperCase().trim().startsWith("РЕЗ")
    );
    const docState = states.data?.data.find((s) =>
      s.description.toUpperCase().trim().startsWith("ИЗТЕКЪЛ")
    );
    const state_vehicles = vehicles?.data
      ? Object.values(vehicles?.data)
          ?.map((v) => {
            const veh = Object.values(v.days).find(
              (a) => a.date === selectedDate
            );

            return {
              vehicle_id: v.vehicle_id,
              state: veh?.state,
              comment: veh?.comment,
              veh: veh,
              avtokolona: veh?.autocolumn,
            };
          })
          .reduce<{
            [key: string]: {
              veh_id: number;
              veh_comment: string;
              state_comment: string;
              state_category: number;
              state_id: number;
              first_shift_reserv: boolean;
              second_shift_reseve: boolean;
            }[];
          }>((acc, x) => {
            if (x.state && x.avtokolona === selectedAvtoColumn) {
              //ако е категория на линия гледа ли го има в наряда и ако го няма го прави да е резерв
              if (
                x.state.category === 1 &&
                !veh_with_roster_ids.includes(x.vehicle_id) &&
                x.state.id === 0 && // т.е. наличен без друго състояние
                resState
              )
                x.state = {
                  category: 1,
                  comment: "",
                  description: resState.description,
                  description_short: resState.desc_short,
                  id: resState.id,
                };

              //ако е категория неналияен го гледа ако няма друга категория дали са му изтекли документите
              if (
                x.state.category === 2 &&
                x.state.expired_docs &&
                x.state.id === 0 &&
                docState
              )
                x.state = {
                  category: 2,
                  comment: "",
                  description: docState.description,
                  description_short: docState.desc_short,
                  id: docState.id,
                };

              // let comment = "";
              // if (x.state.comment && x.state.comment.length > 0) {
              //   comment = x.state.comment;
              // }
              // if (comment.length > 0 && x.comment && x.comment.length > 0)
              //   comment += "\n";

              // if (x.comment && x.comment.length > 0) {
              //   comment += x.comment;
              // }

              if (acc[x.state.id] === undefined) {
                acc[x.state.id] = [
                  {
                    veh_id: x.vehicle_id,
                    veh_comment: x.comment ? x.comment : "",
                    state_comment: x.state.comment ? x.state.comment : "",
                    state_category: x.state.category,
                    state_id: x.state.id,
                    first_shift_reserv:
                      x.veh?.daytasks !== undefined &&
                      x.veh?.daytasks?.length > 0 &&
                      x.veh?.daytasks.filter((d) => d.shift_type === 1)
                        .length === 0,
                    second_shift_reseve:
                      x.veh?.daytasks !== undefined &&
                      x.veh?.daytasks?.length > 0 &&
                      x.veh?.daytasks.filter((d) => d.shift_type === 2)
                        .length === 0,
                  },
                ];
              } else {
                acc[x.state.id].push({
                  veh_id: x.vehicle_id,
                  veh_comment: x.comment ? x.comment : "",
                  state_comment: x.state.comment ? x.state.comment : "",
                  state_category: x.state.category,
                  state_id: x.state.id,
                  first_shift_reserv:
                    x.veh?.daytasks !== undefined &&
                    x.veh?.daytasks?.length > 0 &&
                    x.veh?.daytasks.filter((d) => d.shift_type === 1).length ===
                      0,
                  second_shift_reseve:
                    x.veh?.daytasks !== undefined &&
                    x.veh?.daytasks?.length > 0 &&
                    x.veh?.daytasks.filter((d) => d.shift_type === 2).length ===
                      0,
                });
              }
            }

            Object.values(acc).forEach((s) =>
              s.sort((a, b) => a.veh_id - b.veh_id)
            );

            return acc;
          }, {})
      : undefined;

    return state_vehicles;
    //,{};
  }, [
    selectedAvtoColumn,
    selectedDate,
    states.data?.data,
    veh_with_roster_ids,
    vehicles?.data,
  ]);

  const totalVehicleCount = useMemo(() => {
    const state_vehicles = vehicles?.data
      ? Object.values(vehicles?.data)
          ?.map((v) => ({
            vehicle_id: v.vehicle_id,
            state: Object.values(v.days).find(
              (a) =>
                a.date === selectedDate && a.autocolumn === selectedAvtoColumn
            )?.state,
          }))
          .reduce((acc, x) => {
            if (x.state)
              return (acc += 1); // т.е. ако няма състояние или най-вероятно е в другата автоколона, да не брои
            else return acc;
          }, 0)
      : 0;
    if (state_vehicles) return state_vehicles;
    else return 0;
  }, [selectedAvtoColumn, selectedDate, vehicles?.data]);
  return {
    vehicles,
    rows,
    totalVehicleCount,
    states,
  };
}
