import { useTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { Moment } from "moment";
import { TitleDataCell, TitleHeaderCell } from "./TableElements";
import { DaySummary } from "./types";
import { UseHeaderScrollerResult } from "./useHeaderScroller";

export function SecondShiftHeaderRow({
  firstHeaderColumnWidth,
  headerColumnWidth,
  headerScroller,
  daySummary,
  isHoliday,
  today,
  selectedDate,
}: {
  firstHeaderColumnWidth: number;
  headerColumnWidth: number;
  headerScroller: UseHeaderScrollerResult;
  isHoliday: (day: string | Moment) => boolean | undefined;
  daySummary: DaySummary[];
  today: Moment;
  selectedDate: Moment | null;
}) {
  const theme = useTheme();

  return (
    <div
      style={{
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
      }}
    >
      <TitleHeaderCell
        tabIndex={0}
        title="Работни смени - втора смяна"
        width={firstHeaderColumnWidth}
        fixLeftIdx={0}
      >
        РС2
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={1}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={2}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={3}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={4}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={5}
      >
        &nbsp;
      </TitleHeaderCell>

      {daySummary.map((summary, idx) => (
        <TitleDataCell
          key={idx}
          tabIndex={0}
          isHoliday={isHoliday(summary.date)}
          title={
            summary.plan_shift2_by_vt !== summary.workshifts2
              ? `Броят работни смени в експлоатационния план, ${summary.plan_shift2_by_vt} е различен от планирания: ${summary.workshifts2}`
              : `Всички работни смени са покрити: ${summary.plan_shift2_by_vt}`
          }
          style={{
            ...(summary.plan_shift2_by_vt !== summary.workshifts2
              ? {
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                  fontWeight: "bold",
                  textShadow: isHoliday(summary.date)
                    ? "rgba(0, 0, 0, 1) 0px 0px 2px"
                    : undefined,
                }
              : undefined),
            ...(summary.date.isSameOrBefore(today, "day")
              ? {
                  boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
                }
              : undefined),
            ...(summary.date.isSame(selectedDate)
              ? {
                  backgroundColor: blue[300],
                  color: grey[900],
                  textShadow: undefined,
                }
              : {}),
          }}
        >
          {-(summary.plan_shift2_by_vt - summary.workshifts2)}
        </TitleDataCell>
      ))}
      <div style={{ minWidth: headerScroller.scrollerWidth }} />
    </div>
  );
}
