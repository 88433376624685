import {
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box, Container, useTheme } from "@mui/system";
import moment, { Moment } from "moment";
import { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetScheduleSpecialShiftsQuery,
  useLazyGetExplPlanQuery,
} from "../../data/api/hooks";
import { RootState } from "../../data/store";
import { DepotSelect } from "../../filters/DepotSelect";
import { TransportSelect } from "../../filters/TransportSelect";
import { QueryError } from "../QueryError";

import { ExploatationPlanLineCalendar } from "./ExplotationPlan";
import { useExplotationPlan } from "./useExplotationPlan";
import { DayTypes } from "./useSpecialShift";
import { MonthPicker } from "../../filters/MonthPicker";
import { ExploatationPlanDict } from "../../data/api/types/exploatation_plan";
import { useProfile } from "../../common/useProfile";

function StickyHeaderTable({
  linelist,
  selMonth,
  holidays,
  expl_plan,
  setSelectedLine,
  setEditFormVisible,
}: {
  linelist: {
    label: string;
    id: number;
  }[];
  selMonth: Moment | null;
  holidays: DayTypes[];
  expl_plan: ExploatationPlanDict;
  setSelectedLine: React.Dispatch<
    React.SetStateAction<{
      id: number;
      label: string;
    } | null>
  >;
  setEditFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();

  return (
    // <Container sx={{ height: "100%", pt: 2, pb: 2 }}>

    <Paper sx={{ height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ fontWeight: "bold", textAlign: "right" }}>
              <TableCell
                align="center"
                style={{
                  minWidth: 50,
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  //     background: theme.palette.action.hover,
                  fontWeight: "bold",
                  //borderRight: "1px solid rgba(224,224,224,1)",
                }}
              >
                Линия
              </TableCell>

              {selMonth &&
                [...Array(selMonth.daysInMonth())].map((_k, index) => (
                  <TableCell
                    key={index}
                    style={{
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      //background: theme.palette.action.hover,
                      backgroundImage:
                        holidays[index]?.day_type > 0
                          ? "repeating-linear-gradient(-45deg, transparent,transparent 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                          : "",

                      fontWeight: "bold",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {linelist &&
              linelist.map((line, index) => {
                return (
                  <TableRow
                    key={line.id}
                    style={
                      index % 2
                        ? {
                            background:
                              theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                          } //"#fdffe0" }
                        : { background: "white" }
                    }
                  >
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Link
                        component="button"
                        onClick={() => {
                          setSelectedLine(line);

                          setEditFormVisible(true);
                        }}
                      >
                        {line.label}
                      </Link>
                    </TableCell>

                    {selMonth &&
                      [...Array(selMonth.daysInMonth())].map((_k, index) => (
                        <TableCell
                          key={index}
                          itemType="number"
                          align="center"
                          style={{
                            padding: 0,
                            borderRight: "1px solid black",
                            backgroundImage:
                              holidays[index]?.day_type > 0
                                ? "repeating-linear-gradient(-45deg, transparent,transparent 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                                : "",
                          }}
                          title={
                            expl_plan[line.id] &&
                            expl_plan[line.id].timetables[
                              moment(selMonth)
                                .date(index + 1)
                                .format("YYYY-MM-DD")
                            ] &&
                            expl_plan[line.id].timetables[
                              moment(selMonth)
                                .date(index + 1)
                                .format("YYYY-MM-DD")
                            ].tt_code
                              ? expl_plan[line.id].timetables[
                                  moment(selMonth)
                                    .date(index + 1)
                                    .format("YYYY-MM-DD")
                                ].tt_code
                              : ""
                          }
                        >
                          {expl_plan[line.id] &&
                          expl_plan[line.id].timetables[
                            moment(selMonth)
                              .date(index + 1)
                              .format("YYYY-MM-DD")
                          ] &&
                          expl_plan[line.id].timetables[
                            moment(selMonth)
                              .date(index + 1)
                              .format("YYYY-MM-DD")
                          ].tt_code
                            ? expl_plan[line.id].timetables[
                                moment(selMonth)
                                  .date(index + 1)
                                  .format("YYYY-MM-DD")
                              ].tt_code.split("-")[0]
                            : ""}
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    // </Container>
  );
}

export function ExploatationPlanCalendar() {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );
  const selMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );

  const selectedMonth = useMemo(
    () =>
      selMonth && selMonth !== ""
        ? moment(selMonth + "-01", "YYYY-MM-DD")
        : null,
    [selMonth]
  );

  console.log("selectedMonth: ", selectedMonth);
  console.log("selMonth: ", selMonth);

  const [selectedLine, setSelectedLine] = useState<{
    id: number;
    label: string;
  } | null>(null);
  //   const [startDate, setStartDate] = useState<moment.Moment>(
  //     moment().startOf("month")
  //   );
  //   const [endDate, setEndtDate] = useState<moment.Moment>(
  //     moment().endOf("month")
  //   );

  const [editFormVisible, setEditFormVisible] = useState(false);

  const {
    data: specialshift_data,
    isLoading: isSpecialShiftLoading,
    isError: isSpecialShiftError,
  } = useGetScheduleSpecialShiftsQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      year: selectedMonth ? selectedMonth.year() : 0,
      month: selectedMonth ? selectedMonth.month() + 1 : 0,
      avtocolona: 1,
    },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const {
    lineList,
    ExplPLan,
    loadTimetables,
    timetables,
    explPlanParams,
    isLoading,
    importExplPlan,
    resultImportExplPlan,
    updateExplPlan,
    isError,
  } = useExplotationPlan(selectedTransport, selectedDepot, selectedMonth);

  const [loadExplPlan] = useLazyGetExplPlanQuery();

  const onImport = useCallback(
    () => {
      if (!isReadOnly && selectedDepot) {
        const startDate = moment(selectedMonth).startOf("month");
        const endDate = moment(selectedMonth).endOf("month");
        if (
          window.confirm(
            `Експлоатационният план ще бъде импортиран в първоначален вид. Всички направени корекции от ${startDate.format(
              "YYYY-MM-DD"
            )} до ${endDate.format(
              "YYYY-MM-DD"
            )} ще бъдат изтрити. Желаете ли да продължите?`
          )
        ) {
          importExplPlan({
            transport: selectedTransport,
            gps_depot_id: selectedDepot,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
          });
          if (resultImportExplPlan.error) {
            <QueryError error={resultImportExplPlan.error} />;
          } else {
            if (
              !resultImportExplPlan.isLoading &&
              resultImportExplPlan.isSuccess
            ) {
              loadExplPlan(explPlanParams, true);
            }
          }
        }
      }
    },
    [
      isReadOnly,
      selectedDepot,
      selectedMonth,
      importExplPlan,
      selectedTransport,
      resultImportExplPlan.error,
      resultImportExplPlan.isLoading,
      resultImportExplPlan.isSuccess,
      loadExplPlan,
      explPlanParams,
    ]
    // TODO: Беше това:
    //[selectedDepot, startDate, endDate]
  );

  // TODO: Беше това:
  // }, [lineList]);

  console.log("ExplPaln: ", ExplPLan.data);

  useEffect(() => {
    if (selectedTransport !== "" && selectedLine)
      loadTimetables({
        transport: selectedTransport,
        garage: selectedDepot,
        line_id: selectedLine.id,
      });
  }, [loadTimetables, selectedDepot, selectedLine, selectedTransport]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      //style={{ cursor: "pointer" }}
      // style={{ cursor: cursor }}
    >
      <Container sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={2}>
            <MonthPicker />
          </Grid>

          <Button
            sx={{ marginLeft: "auto" }}
            disabled={isReadOnly || !selectedDepot || isLoading}
            style={{ cursor: "pointer" }}
            //style={{ cursor: cursor }}

            onClick={onImport}
          >
            Импорт
          </Button>
        </Grid>
      </Container>

      {/*<h2>Drivers:</h2>*/}
      <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
        {(isError || isSpecialShiftError) && <QueryError error={null} />}
        {(isLoading || isSpecialShiftLoading) && (
          <Backdrop
            open
            sx={{
              color: grey[300],
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress size={60} color="inherit" />
          </Backdrop>
        )}
        {ExplPLan.data && specialshift_data?.holidays && (
          <StickyHeaderTable
            holidays={specialshift_data?.holidays}
            linelist={lineList}
            selMonth={selectedMonth}
            expl_plan={ExplPLan.data}
            setEditFormVisible={setEditFormVisible}
            setSelectedLine={setSelectedLine}
          />
        )}
      </Box>
      {selectedTransport !== "" &&
        // !isError &&
        editFormVisible &&
        ExplPLan?.data &&
        timetables?.data && (
          <Dialog
            open={editFormVisible}
            fullWidth
            maxWidth="lg"
            //  onClose={handleClose}

            // onLoad={() => document.getElementById("name")?.focus()}
          >
            <DialogTitle>Линия {selectedLine?.label}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Моля, изберете разписание за всеки ден.
              </DialogContentText>
              <Container sx={{ width: "1000" }}>
                <ExploatationPlanLineCalendar
                  ExplPlan={ExplPLan.data}
                  mnth={selectedMonth}
                  selectedDepot={selectedDepot}
                  selectedLine={selectedLine}
                  selectedTransport={selectedTransport}
                  timetables={timetables.data}
                  updateExplPlan={updateExplPlan}
                  setStartDate={null}
                  setEndDate={null}
                  isLoading={isLoading}
                />
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditFormVisible(false)}>Изход</Button>
            </DialogActions>
          </Dialog>
        )}
    </Box>
  );
}
