import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { useGetDriversListQuery } from "../../data/api/hooks";
import { RootState } from "../../data/store";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { DriverRosterInt } from "./DriverRosterInt";
import { useGetTimetablesByDateRosterQueryArray } from "./useGetTimetablesByDateRosterQueryArray";

//const COLUMNS = 6;

export function DriverRosterImpl() {
  const { scheduleParameters } = useScheduleParameters();
  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const timetables = useGetTimetablesByDateRosterQueryArray();

  const vehicles = useGetDriversListQuery(
    scheduleParameters
      ? { transport: scheduleParameters[0], garage: scheduleParameters[1] }
      : { transport: "", garage: 0 },
    {
      skip: !scheduleParameters,
    }
  );

  if (timetables.isSuccess && vehicles.isSuccess) {
    return (
      <DriverRosterInt
        selectedDate={selectedDate}
        api_vehicles={vehicles.data.vehicles}
        driversLoading={
          vehicles.isUninitialized || vehicles.isLoading || vehicles.isFetching
        }
        explPlan={timetables.data}
        explPlanLoading={
          timetables.isUninitialized ||
          timetables.isLoading ||
          timetables.isFetching
        }
      />
    );
  } else if (
    timetables.isLoading ||
    timetables.isFetching ||
    vehicles.isLoading ||
    vehicles.isFetching
  ) {
    return (
      <Backdrop
        open
        sx={{ color: grey[300], zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={60} color="inherit" />
      </Backdrop>
    );
  } else {
    return <></>;
  }
}
