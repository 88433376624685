import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { Transport } from "../data/api/types/schedule";

export function transportName(transport: string) {
  switch (transport) {
    case "A":
      return "Автобуси";
    case "TM":
      return "Трамваи";
    case "TB":
      return "Тролейбуси";
    default:
      return "Неизвестен";
  }
}

export function useProfile() {
  const { user } = useAuth();
  const profile = useMemo(() => {
    if (!user?.profile || user?.expired !== false) {
      return undefined;
    } else {
      // console.log("Profile:", user);
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        groups: _tokenGroups,
        transports: tokenTransports,
        garage_ids: tokenGarageIds,
        autocolumns: tokenAutocolumnIds,
        garage_autocolumns: tokenGarageAutocolumnIds,
        realm_roles: tokenRealmRoles,
        all_depots: tokenAllDepots,
        ...rest
      } = user.profile;
      const transports = (tokenTransports || []) as readonly string[];
      const garageIds = (tokenGarageIds || []) as readonly string[];
      const autocolumnIds = (tokenAutocolumnIds || []) as readonly string[];
      const garageAutocolumnIds = (tokenGarageAutocolumnIds ||
        []) as readonly string[];

      const allDepots: Record<Transport, number[]> = {
        A: [],
        TM: [],
        TB: [],
      };
      for (const all_depots_item of (Array.isArray(tokenAllDepots)
        ? tokenAllDepots
        : []) as string[]) {
        const matches = /(A|TM|TB)-(\[\s*(\d+,\s*)*(\d+)\s*\])/.exec(
          all_depots_item
        );
        if (matches && Object.hasOwn(allDepots, matches[1])) {
          const depotIds: number[] = JSON.parse(matches[2]);
          allDepots[matches[1] as Transport] = depotIds;
        }
      }

      const splitGarageAutocolumns = garageAutocolumnIds
        .map<[Transport, Record<number, number[]>]>((ga) => {
          const items = ga.split(",");
          if (items.length > 0) {
            const matches = /^(A|TM|TB)-(\d+)-(\d+)$/.exec(items[0]);
            if (matches) {
              const transport = matches[1] as Transport;
              const depot = parseInt(matches[2], 10);
              const autocolumns = items
                .map<number>((i) => {
                  const matches = /^(A|TM|TB)-(\d+)-(\d+)$/.exec(i);
                  if (matches) {
                    return parseInt(matches[3], 10);
                  } else {
                    return undefined as any as number;
                  }
                })
                .filter((a) => !!a);

              return [transport, { [depot]: autocolumns }];
            }
          }

          return undefined as any as [Transport, Record<number, number[]>];
        })
        .filter((a) => !!a);

      const garageAutocolumns: Record<
        Transport,
        Record<number, number[]>
      > = {} as any;
      for (const sga of splitGarageAutocolumns) {
        const t = sga[0];
        const g = sga[1];
        if (!garageAutocolumns[t]) {
          garageAutocolumns[t] = {};
        }
        for (const [key, value] of Object.entries(g)) {
          const autocolumns = garageAutocolumns[t][key as any as number] || [];
          garageAutocolumns[t][key as any as number] = [
            ...autocolumns,
            ...value,
          ];
        }
      }

      const userTransports: Record<
        string,
        Record<string, number[]>
      > = Object.fromEntries(
        (transports || []).map((transport) => [
          transport,
          Object.fromEntries(
            garageIds
              .filter((gid) => gid.startsWith(`${transport}-`))
              .map((gid) => [
                gid.substring(`${transport}-`.length),
                autocolumnIds
                  .filter((acid) => acid.startsWith(`${gid}-`))
                  .map((acid) => parseInt(acid.substring(`${gid}-`.length)), 10)
                  .sort(),
              ])
          ),
        ])
      );

      const garages: Record<string, number[]> = Object.fromEntries(
        Object.entries(userTransports).map(([transport, garage]) => [
          transport,
          Object.keys(garage).map((g) => parseInt(g, 10)),
        ])
      );

      //const access = user.profile.resource_access;
      //console.log("Access:", user);
      const roles: string[] =
        tokenRealmRoles && Array.isArray(tokenRealmRoles)
          ? (tokenRealmRoles || []).filter(
              (r) =>
                r !== "offline_access" &&
                r !== "default-roles-nariad" &&
                r !== "default-roles-master" &&
                r !== "uma_authorization"
            )
          : [];

      return {
        ...rest,
        garages: garages,
        transports: userTransports,
        roles: roles,
        garage_autocolumns: garageAutocolumns,
        all_depots: allDepots,
      };
    }
  }, [user?.profile, user?.expired]);

  return profile;
}

export type UseProfileResult = NonNullable<ReturnType<typeof useProfile>>;
