import {
  Apps,
  Chair,
  ChevronRight,
  Comment,
  Delete,
  DirectionsBus,
  DirectionsBusFilled,
  Hiking,
  MedicationLiquid,
} from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import {
  isAllowedChangeDepot,
  isAllowedInPreliminary,
  isAllowedRestoreDepot,
} from "./stateFunctions";
import { TableCellRef, TableRowRef } from "./types";
import { useScheduleParameters } from "./useScheduleParameters";

export function SelectStateMenu({
  contextMenu,
  changeDepotOnly,
  setContextMenu,
  onSelectState,
  onUpdateComment,
  onUpdateDepot,
}: {
  onSelectState: (stateId: number) => void;
  setContextMenu: (menu: null) => void;
  contextMenu: {
    mouseX: number;
    mouseY: number;
    row: TableRowRef;
    cell: TableCellRef;
  } | null;
  changeDepotOnly: boolean;
  onUpdateComment: () => void;
  onUpdateDepot: (restoreDriver?: boolean) => void;
}) {
  const isPreliminary = useSelector(
    (state: RootState) =>
      state.schedule.schedule?.db_schedule?.preliminary_ready !== true
  );
  const { scheduleParameters } = useScheduleParameters();

  return (
    <Menu
      open={contextMenu !== null}
      onClose={() => setContextMenu(null)}
      onContextMenu={(e) => {
        if (
          e.target instanceof HTMLElement &&
          e.target.classList.contains("MuiBackdrop-root")
        ) {
          e.preventDefault();
          setContextMenu(null);
        }
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {[
        !changeDepotOnly
          ? [
              <MenuItem key="1" disabled>
                Често използвани...
              </MenuItem>,
            ]
          : [],

        !changeDepotOnly
          ? [
              <MenuItem key="2" onClick={() => onUpdateComment()}>
                <ListItemIcon>
                  <Comment />
                </ListItemIcon>
                <ListItemText>Коментар...</ListItemText>
              </MenuItem>,
            ]
          : [],

        !changeDepotOnly ? [<Divider key="3" />] : [],

        isAllowedInPreliminary(isPreliminary, 24, changeDepotOnly)
          ? [
              <MenuItem key="4" onClick={() => onSelectState(24)}>
                <ListItemIcon>
                  <Chair />
                </ListItemIcon>
                <ListItemText>Почивка</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  P
                </Typography>
              </MenuItem>,
            ]
          : [],
        isAllowedInPreliminary(isPreliminary, 21, changeDepotOnly)
          ? [
              <MenuItem key="5" onClick={() => onSelectState(21)}>
                <ListItemIcon>
                  <DirectionsBus />
                </ListItemIcon>
                <ListItemText>1-ва смяна</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  Z
                </Typography>
              </MenuItem>,
            ]
          : [],
        isAllowedInPreliminary(isPreliminary, 22, changeDepotOnly)
          ? [
              <MenuItem key="6" onClick={() => onSelectState(22)}>
                <ListItemIcon>
                  <DirectionsBusFilled />
                </ListItemIcon>
                <ListItemText>2-ра смяна</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  X
                </Typography>
              </MenuItem>,
            ]
          : [],
        isAllowedInPreliminary(isPreliminary, 8, changeDepotOnly)
          ? [
              <MenuItem key="7" onClick={() => onSelectState(8)}>
                <ListItemIcon>
                  <MedicationLiquid />
                </ListItemIcon>
                <ListItemText>Неявка</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  N
                </Typography>
              </MenuItem>,
            ]
          : [],
        isAllowedInPreliminary(isPreliminary, 25, changeDepotOnly)
          ? [
              <MenuItem key="8" onClick={() => onSelectState(25)}>
                <ListItemIcon>
                  <Hiking />
                </ListItemIcon>
                <ListItemText style={{ minWidth: 90 }}>Отпуск</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  O
                </Typography>
              </MenuItem>,
            ]
          : [],
        isAllowedInPreliminary(isPreliminary, 1, changeDepotOnly)
          ? [
              <MenuItem key="9" onClick={() => onSelectState(1)}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText>Изтриване</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  Del
                </Typography>
              </MenuItem>,
            ]
          : [],

        !changeDepotOnly ? [<Divider key="10" />] : [],
        <MenuItem key="11" disabled>
          Задължително...
        </MenuItem>,

        isAllowedInPreliminary(isPreliminary, 5, changeDepotOnly)
          ? [
              <MenuItem key="12" onClick={() => onSelectState(5)}>
                <ListItemIcon>
                  <Chair />
                </ListItemIcon>
                <ListItemText>Почивка</ListItemText>
                <Typography color="text.secondary" fontWeight="bold">
                  ⇧ P
                </Typography>
              </MenuItem>,
            ]
          : [],
        !changeDepotOnly
          ? [
              <NestedMenuItem
                key="13"
                rightIcon={<ChevronRight />}
                leftIcon={
                  <ListItemIcon style={{ minWidth: 28 }}>
                    <DirectionsBus />
                  </ListItemIcon>
                }
                label="На линия..."
                parentMenuOpen={contextMenu !== null}
                sx={{ pl: 4, pr: 2 }}
              >
                <MenuItem disabled>На линия...</MenuItem>
                {/*<MenuItem onClick={() => onSelectState(2)}>На линия</MenuItem>*/}
                {isAllowedInPreliminary(isPreliminary, 28, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(28)}>
                    <ListItemText style={{ minWidth: 90 }}>
                      1-ва смяна
                    </ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      ⇧ Z
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 29, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(29)}>
                    <ListItemText>2-ра смяна</ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      ⇧ X
                    </Typography>
                  </MenuItem>
                )}
              </NestedMenuItem>,
            ]
          : [],
        !changeDepotOnly
          ? [
              <NestedMenuItem
                key="14"
                leftIcon={
                  <ListItemIcon style={{ minWidth: 28 }}>
                    <Hiking />
                  </ListItemIcon>
                }
                rightIcon={<ChevronRight />}
                label="Отпуски..."
                parentMenuOpen={contextMenu !== null}
                sx={{ pl: 4, pr: 2 }}
              >
                <MenuItem disabled>Отпуски...</MenuItem>
                {isAllowedInPreliminary(isPreliminary, 6, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(6)}>
                    <ListItemText>Отпуск</ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      O
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 33, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(33)}>
                    <ListItemText>Отпуск без документи</ListItemText>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 17, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(17)}>
                    <ListItemText>Служебен отпуск</ListItemText>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 19, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(19)}>
                    Ученически отпуск
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 10, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(10)}>
                    Неплатен отпуск
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 30, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(30)}>
                    Отпуск по майчинство
                  </MenuItem>
                )}
              </NestedMenuItem>,
            ]
          : [],
        !changeDepotOnly
          ? [
              <NestedMenuItem
                key="15"
                rightIcon={<ChevronRight />}
                leftIcon={
                  <ListItemIcon style={{ minWidth: 28 }}>
                    <MedicationLiquid />
                  </ListItemIcon>
                }
                label="Болни и неявки..."
                parentMenuOpen={contextMenu !== null}
                sx={{ pl: 4, pr: 2 }}
              >
                <MenuItem disabled>Болни и неявки...</MenuItem>
                {isAllowedInPreliminary(isPreliminary, 7, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(7)}>
                    <ListItemText>Болни</ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      B
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 34, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(34)}>
                    <ListItemText>Болни без документи</ListItemText>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 8, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(8)}>
                    <ListItemText>Неявка</ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      N
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 16, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(16)}>
                    Действителна неявка
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 20, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(20)}>Алкохол</MenuItem>
                )}
              </NestedMenuItem>,
            ]
          : [],
        <NestedMenuItem
          key="16"
          rightIcon={<ChevronRight />}
          leftIcon={
            <ListItemIcon style={{ minWidth: 28 }}>
              <Apps />
            </ListItemIcon>
          }
          label="Други..."
          parentMenuOpen={contextMenu !== null}
          sx={{ pl: 4, pr: 2 }}
        >
          <MenuItem disabled>Други...</MenuItem>
          {isAllowedInPreliminary(isPreliminary, 11, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(11)}>Маневрист</MenuItem>
          )}
          {isAllowedInPreliminary(isPreliminary, 9, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(9)}>Стажант</MenuItem>
          )}
          {isAllowedInPreliminary(isPreliminary, 37, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(37)}>Наставник</MenuItem>
          )}
          {isAllowedInPreliminary(isPreliminary, 4, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(4)}>Резерв</MenuItem>
          )}
          {isAllowedInPreliminary(isPreliminary, 3, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(3)}>
              Резерв с автобус
            </MenuItem>
          )}
          {isAllowedInPreliminary(isPreliminary, 32, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(32)}>Персонална</MenuItem>
          )}
          {!isPreliminary &&
            contextMenu?.cell &&
            scheduleParameters &&
            isAllowedChangeDepot(
              isPreliminary,
              12,
              contextMenu.cell,
              scheduleParameters
            ) && (
              <MenuItem
                onClick={() => {
                  // onSelectState(12);
                  if (
                    scheduleParameters &&
                    (scheduleParameters[0] === "TM" ||
                      scheduleParameters[0] === "TB")
                  ) {
                    onUpdateDepot(false);
                  } else {
                    onSelectState(12);
                  }
                }}
              >
                Командирован
              </MenuItem>
            )}
          {!isPreliminary &&
            contextMenu?.cell &&
            scheduleParameters &&
            isAllowedRestoreDepot(
              isPreliminary,
              12,
              contextMenu.cell,
              scheduleParameters
            ) && (
              <MenuItem
                onClick={() => {
                  // onSelectState(12);
                  if (
                    scheduleParameters &&
                    (scheduleParameters[0] === "TM" ||
                      scheduleParameters[0] === "TB")
                  ) {
                    onUpdateDepot(true);
                  } else {
                    onSelectState(12);
                  }
                }}
              >
                Прекратена командировка
              </MenuItem>
            )}
          {isAllowedInPreliminary(isPreliminary, 13, changeDepotOnly) && (
            <MenuItem onClick={() => onSelectState(13)}>Неназначен</MenuItem>
          )}
          {/*
              <MenuItem onClick={() => onSelectState(13)} disabled>
                Неназначен
              </MenuItem>
              <MenuItem onClick={() => onSelectState(15)} disabled>
                Почивни дни между Б, О и НО
              </MenuItem>
              */}
        </NestedMenuItem>,

        !changeDepotOnly ? [<Divider key="17" />] : [],
        !changeDepotOnly
          ? [
              <MenuItem key="18" disabled>
                Ако е възможно...
              </MenuItem>,
            ]
          : [],

        !changeDepotOnly
          ? [
              <NestedMenuItem
                key="19"
                rightIcon={<ChevronRight />}
                leftIcon={
                  <ListItemIcon style={{ minWidth: 28 }}>
                    <DirectionsBus />
                  </ListItemIcon>
                }
                label="На линия..."
                parentMenuOpen={contextMenu !== null}
                sx={{ pl: 4, pr: 2 }}
              >
                <MenuItem disabled>На линия...</MenuItem>
                {/*<MenuItem onClick={() => onSelectState(23)}>На линия</MenuItem>*/}
                {isAllowedInPreliminary(isPreliminary, 21, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(21)}>
                    <ListItemText style={{ minWidth: 90 }}>
                      1-ва смяна
                    </ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      Z
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 22, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(22)}>
                    <ListItemText style={{ minWidth: 90 }}>
                      2-ра смяна
                    </ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      X
                    </Typography>
                  </MenuItem>
                )}
              </NestedMenuItem>,
            ]
          : [],
        !changeDepotOnly
          ? [
              <NestedMenuItem
                key="20"
                rightIcon={<ChevronRight />}
                leftIcon={
                  <ListItemIcon style={{ minWidth: 28 }}>
                    <Chair />
                  </ListItemIcon>
                }
                label="Почивки и отпуски..."
                parentMenuOpen={contextMenu !== null}
                sx={{ pl: 4, pr: 2 }}
              >
                <MenuItem disabled>Почивки и отпуски...</MenuItem>
                {isAllowedInPreliminary(isPreliminary, 24, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(24)}>
                    <ListItemText style={{ minWidth: 90 }}>
                      Почивка
                    </ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      P
                    </Typography>
                  </MenuItem>
                )}
                {/*<MenuItem onClick={() => onSelectState(27)}>Почивка по график</MenuItem>*/}
                {isAllowedInPreliminary(isPreliminary, 25, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(25)}>
                    <ListItemText style={{ minWidth: 90 }}>Отпуск</ListItemText>
                    <Typography color="text.secondary" fontWeight="bold">
                      O
                    </Typography>
                  </MenuItem>
                )}
                {isAllowedInPreliminary(isPreliminary, 26, changeDepotOnly) && (
                  <MenuItem onClick={() => onSelectState(26)}>
                    <ListItemText style={{ minWidth: 90 }}>
                      Неплатен отпуск
                    </ListItemText>
                  </MenuItem>
                )}
              </NestedMenuItem>,
            ]
          : [],
      ].flat()}
    </Menu>
  );
}
