import moment from "moment";
import {
  useGetVehicleTypeListQuery,
  useGetVehiclesQuery,
} from "../../data/api/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import { useMemo } from "react";

export const SHIFT2_SECONDS = 39600; //11:00 ч.
export const NIGHTCAR_SECONDS = 81000; // 22:30 ч.
export const LATE_CAR_SECONDS = 52200; // 14:30 ч.

interface DayLineTimetableColumn {
  id:
    | "line_name"
    | "tt_code"
    | "car_no"
    | "vehicletype_name"
    | "start_time"
    | "end_time"
    | "work_hours"
    | "start_time2"
    | "end_time2"
    | "work_hours2"
    | "singleShift";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

export const DayLineTimetableColumns: DayLineTimetableColumn[] = [
  //   { id: "line_name", label: "Линия", minWidth: 100, align: "left" },
  //   { id: "tt_code", label: "Код разписание", minWidth: 120, align: "left" },
  { id: "car_no", label: "Кола", minWidth: 80, align: "left" },
  { id: "vehicletype_name", label: "Марка", minWidth: 120, align: "left" },
  { id: "start_time", label: "Начало", minWidth: 80, align: "center" },
  { id: "end_time", label: "Край", minWidth: 80, align: "center" },
  { id: "work_hours", label: "Заработка", minWidth: 80, align: "center" },
  { id: "start_time2", label: "Начало", minWidth: 80, align: "center" },
  { id: "end_time2", label: "Край", minWidth: 80, align: "center" },
  { id: "work_hours2", label: "Заработка", minWidth: 80, align: "center" },
  { id: "singleShift", label: "Едносменна", minWidth: 80, align: "center" },
];

export type VehicleType = {
  description: string;
  id: number;
  koef: number;
  places: number;
};

export type WorkShift = {
  id: number;
  number?: number;
  autocolumn: number;
  end_time: number;
  start_time: number;
  end_time_2: number | null;
  start_time_2: number | null;
  vehicletype_id: number;
  vehicletype: VehicleType;
  shift_type: number;
  operate: boolean;
};

export type Car = {
  car_no: number;
  workshifts: WorkShift[];
};

export type LineTimetable = {
  cars: Car[];
  code: string;
  line: number;
  line_name: string;
  line_order: number;
  timetable_id: number;
};

export type DaySpecShift = {
  id: number;
  count: number;
  description: string;
  end_time: number;
  start_time: number;
  shift_type: 1 | 2;
};

export type DayExplPlan = {
  expl_plan: LineTimetable[];
  spec_shifts: DaySpecShift[];
};

export type WorkShiftRoster = {
  id: string;
  number?: number;
  autocolumn: number;
  end_time: number;
  start_time: number;
  end_time_2: number | null;
  start_time_2: number | null;
  passenger_start?: number | null;
  passenger_end?: number | null;
  single_shift?: boolean | null;
  vehicletype_id: number;
  vehicletype: VehicleType;
  shift_type: number;
  change_garage_time?: number | null;
  change_stop_code?: number | null;
  change_stop_name?: string | null;
};

export type CarRoster = {
  car_no: number;
  workshifts: WorkShiftRoster[];
};

export type LineTimetableRoster = {
  cars: CarRoster[];
  code: string;
  line: number;
  line_name: string;
  line_order: number;
  timetable_id: number;
};

export type DaySpecShiftRoster = {
  id: string;
  //count: number;
  description: string;
  end_time: number;
  start_time: number;
  shift_type: 1 | 2;
};

export type DayExplPlanRoster = {
  expl_plan: LineTimetableRoster[];
  spec_shifts: DaySpecShiftRoster[];
};

export interface LineTimetableData {
  line_id: number;
  line_name: string;
  tt_code: string;
  car_no: number;
  vehicletype_name: string;
  shifts: {
    start_time: string;
    end_time: string;
    start_time_2: string;
    end_time_2: string;
    work_hours: string;
    first_shift: boolean;
  }[];
  nightCar: boolean;
  singleShift: boolean;
}

export function createLineTimetableData(
  line_id: number,
  line_name: string,
  tt_code: string,
  car_no: number,
  vehicletype_name: string,
  shifts: {
    start_time: number;
    end_time: number;
    shift_type: number;
    start_time_2: number | null;
    end_time_2: number | null;
  }[]
): LineTimetableData {
  const sh = shifts.map((shift) => ({
    start_time: moment
      .utc(moment.duration(shift.start_time, "seconds").as("milliseconds"))
      .format("HH:mm"),
    end_time: moment
      .utc(moment.duration(shift.end_time, "seconds").as("milliseconds"))
      .format("HH:mm"),
    start_time_2: moment
      .utc(moment.duration(shift.start_time_2, "seconds").as("milliseconds"))
      .format("HH:mm"),
    end_time_2: moment
      .utc(moment.duration(shift.end_time_2, "seconds").as("milliseconds"))
      .format("HH:mm"),
    work_hours: moment
      .utc(
        moment
          .duration(shift.end_time - shift.start_time, "seconds")
          .as("milliseconds") +
          moment
            .duration(
              shift?.end_time_2 && shift?.start_time_2
                ? shift.end_time_2 - shift.start_time_2
                : 0,
              "seconds"
            )
            .as("milliseconds")
      )
      .format("HH:mm"),
    first_shift: shift.shift_type === 1,
  }));
  const nightCar =
    shifts.sort((s1, s2) => (s1.start_time > s2.start_time ? 1 : -1))[
      shifts.length - 1
    ].end_time > NIGHTCAR_SECONDS;
  const singleShift =
    shifts.filter((sh) => sh.start_time_2 !== null).length > 0;
  return {
    line_id,
    line_name,
    tt_code,
    car_no,
    vehicletype_name,
    shifts: sh,
    nightCar,
    singleShift,
  };
}

export function useVehicleGarageTypes() {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const {
    currentData: vehicleTypesRecord,
    isLoading: vehicleTypesLoading,
    isError: vehicleTypesError,
  } = useGetVehicleTypeListQuery(selectedTransport, {
    skip: selectedTransport === "",
  });

  const vehicles = useGetVehiclesQuery({
    transport: selectedTransport,
    garage: selectedDepot,
  });

  const vehicleTypeList = useMemo(
    () =>
      vehicleTypesRecord && vehicles?.currentData
        ? Object.values(vehicleTypesRecord)
            .filter(
              (vt) =>
                (vehicles?.currentData?.data
                  ? vehicles.currentData.data.filter(
                      (vt1) => vt1.vehicletype_id === vt.id
                    ).length > 0
                  : false) || selectedTransport === "TM"
            )
            .map((v) => ({
              label: v.description,
              id: v.id,
            }))
        : [],

    [selectedTransport, vehicleTypesRecord, vehicles.currentData]
  );

  return { vehicleTypeList, vehicleTypesLoading, vehicleTypesError, vehicles };
}
