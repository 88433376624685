import { useEffect } from "react";
import { useProfile } from "../../common/useProfile";

export function useScheduleKeyboardEvents(
  disabled: boolean,
  updateState: (stateId: number) => void,
  focusSearchField: () => void,
  isEditingDisabled: boolean
) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_roster");

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      //if (!isScheduleFocused) {
      //  return;
      //}
      if (!disabled) {
        //console.log("Key press:", e);

        // Ако се използва "code" връща клавиша без значение дали се пише на кирилица или не
        if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyP" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Почивка пожелание
          updateState(24);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyP" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === true
        ) {
          // Почивка задължителна
          updateState(5);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyZ" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === true
        ) {
          // Задължителна 1-ва смяна
          updateState(28);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyZ" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Незадължителна 1-ва смяна
          updateState(21);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyX" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Незадължителна 2-ра смяна
          updateState(22);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyX" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === true
        ) {
          // Задължителна 2-ра смяна
          updateState(29);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyB" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Задължителна Болничен
          updateState(7);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyN" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Задължителна Неявка
          updateState(8);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyO" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          // Незадължителна Отпуска
          updateState(25);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "KeyO" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === true
        ) {
          // Задължителна Отпуска
          updateState(6);
        } else if (
          !isEditingDisabled &&
          !isReadOnly &&
          e.code === "Delete" &&
          e.ctrlKey === false &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          updateState(1);
        } else if (
          e.code === "KeyF" &&
          e.ctrlKey === true &&
          e.altKey === false &&
          e.shiftKey === false
        ) {
          focusSearchField();
          e.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handler);
    return () => document.removeEventListener("keydown", handler);
  }, [disabled, focusSearchField, isEditingDisabled, isReadOnly, updateState]);
}
