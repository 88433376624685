import { useEffect, useMemo, useState } from "react";
import { TableRowRef } from "./types";

export function useDriverFilter(
  rows: TableRowRef[],
  scrollToRow: (row: number, offset?: number) => void
) {
  const [driverFilterValue, setDriverFilterValue] = useState("");
  const [driverFilterField, setDriverFilterField] =
    useState<HTMLElement | null>(null);

  const foundDriver = useMemo(() => {
    //console.log("Filter changed:", foundDriver, scrollToRow);
    if (rows.length > 0 && driverFilterValue.length > 0) {
      return rows.findIndex(
        (row) =>
          row.rowType === "driver" &&
          row.header &&
          //row.header?.toString().startsWith(driverFilterValue)
          row.header?.toString()?.trim()?.split(" ")?.at(0) ===
            driverFilterValue
      );
    } else {
      return -1;
    }
  }, [driverFilterValue, rows]);

  useEffect(() => {
    //console.log("Filter changed:", foundDriver, scrollToRow);
    if (foundDriver >= 0) {
      scrollToRow(foundDriver);
    }
  }, [foundDriver, scrollToRow]);

  return {
    driverFilterValue,
    setDriverFilterValue,
    driverFilterField,
    setDriverFilterField,
  };
}

export type UseDriverFilter = typeof useDriverFilter;
export type UseDriverFilterResult = ReturnType<UseDriverFilter>;
