import { Error } from "@mui/icons-material";
import { Container, Paper, useTheme } from "@mui/material";

export function NotFoundPage() {
  const theme = useTheme();

  return (
    <Container sx={{ mt: 4 }}>
      <Paper sx={{ px: 3, py: 1, textAlign: "center" }}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.error.dark,
          }}
        >
          <Error fontSize="large" sx={{ mr: 4 }} />
          <span>Страницата не е намерена!</span>
        </h2>
      </Paper>
    </Container>
  );
}
