import { createApi } from "@reduxjs/toolkit/query/react";
import { commonApiParameters, createBaseQuery } from "./commonApiFunctions";
import { DaytaskStateItem } from "./hooks";
import { ScheduleParameters } from "./types/schedule";

export type StateCategory =
  | "FIRST_SHIFT"
  | "SECOND_SHIFT"
  | "REST"
  | "NO_WORK"
  | "OTHER";

export type RecommendationRequestDriver = {
  id: string;
  sl_number: number;
  current_state: StateCategory;
  last_state: StateCategory;
  last_state_count: number;
};

// export type RecommendationRequest = {
//   date: string;
//   count: number;
//   payload: RecommendationRequestDriver[];
// };
export type RecommendationRequest = {
  schedule_parameters: ScheduleParameters | null | undefined;
  date: string;
  suggestion_type: "suggestion_rest" | "suggestion_work";
};

export type RecommendationResponseDriver = {
  id: string;
  position: number;
  cost: number;
  solutionValue: number;
  last_state: StateCategory;
  last_state_count: number;
  suggestedShift?: 1 | 2 | 3 | null;
};

export type RecommendationResponse = {
  action: "suggestion_result";
  content: {
    suggestions: {
      suggestions: RecommendationResponseDriver[];
    };
  };
};

export type RecommendationResponseStatus =
  | "OPTIMAL"
  | "FEASIBLE"
  | "ABNORMAL"
  | "INFEASIBLE"
  | "MODEL_INVALID"
  | "NOT_SOLVED"
  | "UNBOUNDED";

export function getStateCategory(
  state: DaytaskStateItem | null | undefined
): StateCategory {
  if (!state) {
    return "OTHER";
  } else if (state.state_id === 15) {
    return "NO_WORK";
  } else {
    switch (state.category) {
      case 1:
        return "FIRST_SHIFT";
      case 2:
        return "SECOND_SHIFT";
      case 3:
        return "REST";
      case 4:
        return "NO_WORK";
      default:
        return "OTHER";
    }
  }
}

export function getCatCategory(
  category: number | null | undefined
): StateCategory {
  switch (category) {
    case 1:
      return "FIRST_SHIFT";
    case 2:
      return "SECOND_SHIFT";
    case 3:
      return "REST";
    case 4:
      return "NO_WORK";
    default:
      return "OTHER";
  }
}

export const recommendationsApi = createApi({
  reducerPath: "recommendationsApi",
  baseQuery: createBaseQuery(import.meta.env.VITE_RECOMMENDATIONS_API_BASE),
  ...commonApiParameters,
  tagTypes: ["RECOMMENDED_RESTS", "RECOMMENDED_WORKS"],
  endpoints: () => ({
    // getRestRecommendation: builder.mutation<
    //   RecommendationResponse,
    //   RecommendationRequest
    // >({
    //   query: ({ date, count, payload }) => ({
    //     url: `recommend-rest/${date}/${count}`,
    //     method: "PUT",
    //     body: payload,
    //     headers: [],
    //   }),
    //   invalidatesTags: ["RECOMMENDED_RESTS"],
    // }),
    // getWorkRecommendation: builder.mutation<
    //   RecommendationResponse,
    //   RecommendationRequest
    // >({
    //   query: ({ date, count, payload }) => ({
    //     url: `recommend-work/${date}/${count}`,
    //     method: "PUT",
    //     body: payload,
    //     headers: [],
    //   }),
    //   invalidatesTags: ["RECOMMENDED_WORKS"],
    // }),
  }),
});

// export const {
//   useGetRestRecommendationMutation,
//   useGetWorkRecommendationMutation,
// } = recommendationsApi;
