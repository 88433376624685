import { ScheduleDay, ScheduleParameters } from "../../data/api/types/schedule";
import { DriverTableRowRef, TableCellRef, TableRowRef } from "./types";

export function isRest(stateId: number): boolean {
  return [24, 5, 27].includes(stateId);
}

export function isLeave(stateId: number): boolean {
  // 25 - отпуск
  // 6 - задължителен отпуск
  // 33 - Отпуск без документи
  // 17 - Служебен отпуск
  // 19 -  Ученически отпуск
  // 10 - Неплатен отпуск
  // 30 - Отпуск по майчинство
  // 7 - Болни
  // 34 - Болни без документи
  // 26 - Неплатен отпуск
  // 15 - Почивни дни между Б, О и НО
  return [25, 6, 33, 17, 19, 10, 30, 7, 34, 26, 15].includes(stateId);
}

export function isStoredInPreliminaryField(
  isPreliminary: boolean,
  stateId: number
): boolean {
  // 12 - Командирован
  // 13 - Неназначен
  //
  // 25 - отпуск
  // 6 - задължителен отпуск
  // 33 - Отпуск без документи
  // 17 - Служебен отпуск
  // 19 -  Ученически отпуск
  // 10 - Неплатен отпуск
  // 30 - Отпуск по майчинство
  // 7 - Болни
  // 34 - Болни без документи
  // 26 - Неплатен отпуск
  return !isPreliminary || isLeave(stateId) || [12, 13].includes(stateId);
}

export function isAllowedInPreliminary(
  isPreliminary: boolean,
  stateId: number,
  changeDepotOnly: boolean | undefined
): boolean {
  // 1 - неопределен
  // 21 - 1-ва смяна
  // 22 - 2-ра смяна
  // 28 - задължителна 1-ва смяна
  // 29 - задължителна втора смяна
  //
  // 24 - почивка
  // 5 - задължителна почивка
  // 27 - Почивка по график
  // 25 - отпуск
  // 6 - задължителен отпуск
  // 33 - Отпуск без документи
  // 17 - Служебен отпуск
  // 19 -  Ученически отпуск
  // 10 - Неплатен отпуск
  // 30 - Отпуск по майчинство
  // 7 - Болни
  // 34 - Болни без документи
  // 12 - Командирован
  // 13 - Неназначен
  // 26 - Неплатен отпуск
  if (stateId !== 12 && changeDepotOnly) {
    return false;
  } else {
    return (
      !isPreliminary ||
      isStoredInPreliminaryField(isPreliminary, stateId) ||
      isRest(stateId) ||
      [1, 21, 22, 28, 29].includes(stateId)
    );
  }
}

export function isAllowedChangeDepot(
  isPreliminary: boolean,
  stateId: 12,
  cell: TableCellRef,
  scheduleParameters: ScheduleParameters
): boolean {
  // 12 - Командирован
  if (scheduleParameters[0] !== "TM" && scheduleParameters[0] != "TB") {
    return true;
  } else if (!isPreliminary && stateId === 12) {
    if (
      !cell.driver.home_depot_id ||
      cell.driver.home_depot_id === cell.driver_day.work_depot_id
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isAllowedRestoreDepot(
  isPreliminary: boolean,
  stateId: 12,
  cell: TableCellRef,
  scheduleParameters: ScheduleParameters
): boolean {
  // 12 - Командирован
  if (scheduleParameters[0] !== "TM" && scheduleParameters[0] != "TB") {
    return false;
  } else if (!isPreliminary && stateId === 12) {
    if (
      cell.driver.home_depot_id &&
      cell.driver.home_depot_id !== cell.driver_day.work_depot_id
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// export function isLeaveOverRest(
//   isPreliminary: boolean,
//   pre_state: ScheduleDay["pre_state"] | null | undefined,
//   state: number
// ) {
//   return !!(
//     isPreliminary &&
//     pre_state &&
//     isLeave(pre_state.state_id) &&
//     isRest(state)
//   );
// }

// export function isPreliminaryState(
//   isPreliminary: boolean,
//   pre_state: ScheduleDay["pre_state"] | null | undefined
// ) {
//   return !!(isPreliminary && pre_state);
// }

export function isTakenRest(
  isPreliminary: boolean,
  pre_state: ScheduleDay["pre_state"] | null | undefined,
  state: number
): pre_state is NonNullable<ScheduleDay["pre_state"]> {
  return !!(
    !isPreliminary &&
    pre_state &&
    isRest(pre_state.state_id) &&
    state !== 1 &&
    !isRest(state) &&
    !isLeave(state)
  );
}
