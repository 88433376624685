import {
  Paper,
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow as TableRowMUI,
} from "@mui/material";
import { Moment } from "moment";
import { DayTaskStates } from "../schedule_recapitulation/useScheduleRecapitulation";
import { Transport } from "../../../data/api/types/driver";
import { TableCell, TableRow } from "./StickyHeadTable";
import { PrecisionManufacturing } from "@mui/icons-material";
import { DayRosterData } from "./helpers";
import React from "react";

export function ReportRecapitulationContent({
  driver_rows,
  daytask_states,
  vehicle_rows,
  vehicles_state,
  selectedDate,
  totalDriverCount,
  totalVehicleCount,
  totalShiftWithoutDriverCount,
}: {
  driver_rows: { [key: string]: number[] };
  daytask_states: DayTaskStates[];
  vehicle_rows: {
    [key: string]: {
      veh_id: number;
      veh_comment: string;
      state_comment: string;
    }[];
  };
  vehicles_state: {
    id: number;
    transport: Transport;
    description: string;
    desc_short: string;
    site_visible: boolean;
    category_id: number;
  }[];
  selectedDate: Moment;
  totalDriverCount: number;
  totalVehicleCount: number;
  totalShiftWithoutDriverCount: number;
}) {
  // console.log("VehRows:", vehicle_rows);
  return (
    <>
      <Paper
        sx={{ height: "auto", px: 20, py: 6, my: 2 }}
        style={{ paddingBottom: 20, paddingTop: 6 }}
      >
        <h2 style={{ padding: "10" }}>Рекапитулация</h2>
        <TableContainer sx={{ height: "100%" }}>
          <Table
            id="roster-recapitulation-table"
            stickyHeader
            aria-label="stickyHeader"
            style={{ border: "line-width" }}
          >
            <TableHead>
              <TableRowMUI>
                <TableCellMUI align="center" style={{}} colSpan={3}>
                  ВОДАЧИ
                </TableCellMUI>
                <TableCellMUI align="center" style={{}} colSpan={3}>
                  КОЛИ
                </TableCellMUI>
                <TableCellMUI align="center" style={{}} colSpan={2}>
                  ДРУГИ
                </TableCellMUI>
              </TableRowMUI>
            </TableHead>
            <TableBody>
              {Array.from(
                Array(
                  Object.keys(driver_rows).length >
                    Object.keys(vehicle_rows).length
                    ? Object.keys(driver_rows).length - 1 > 2
                      ? Object.keys(driver_rows).length
                      : 2
                    : Object.keys(vehicle_rows).length > 2
                    ? Object.keys(vehicle_rows).length
                    : 2
                )
              ).map((_val, i) => (
                <React.Fragment key={i}>
                  <TableRow>
                    <TableCell>
                      {Object.keys(driver_rows).length > i &&
                      parseInt(Object.keys(driver_rows)[i], 10) >= 0
                        ? daytask_states.find(
                            (s) =>
                              s.state_id ===
                              parseInt(Object.keys(driver_rows)[i], 10)
                          )?.description
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {Object.keys(driver_rows).length > i &&
                        parseInt(Object.keys(driver_rows)[i], 10) >= 0 &&
                        driver_rows[Object.keys(driver_rows)[i]].length}
                    </TableCell>
                    <TableCell style={{ width: 20 }}></TableCell>

                    <TableCell>
                      {Object.keys(vehicle_rows).length > i &&
                        vehicles_state.find(
                          (s) =>
                            s.id === parseInt(Object.keys(vehicle_rows)[i], 10)
                        )?.description}
                    </TableCell>
                    <TableCell align="right">
                      {Object.keys(vehicle_rows).length > i &&
                        vehicle_rows[Object.keys(vehicle_rows)[i]].length}
                    </TableCell>
                    <TableCell style={{ width: 20 }}></TableCell>
                    <TableCell align="left">
                      {i === 0 && " Непокрити смени"}
                      {i === 1 && "Водачи без назначена задача"}
                    </TableCell>
                    <TableCell align="right">
                      {i === 0 && totalShiftWithoutDriverCount}
                      {i === 1 && driver_rows[-1]?.length}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}

              <TableRow>
                <TableCell align="right"> Общо</TableCell>
                <TableCell
                  align="right"
                  style={{ borderTop: "1px solid black" }}
                >
                  {totalDriverCount}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Общо</TableCell>
                <TableCell
                  align="right"
                  style={{ borderTop: "1px solid black" }}
                >
                  {totalVehicleCount}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
