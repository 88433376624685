import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import UserIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../data/store";
import { toggleDrawerOpen } from "../data/settingsSlice";
import { useState } from "react";
import { useAuth } from "react-oidc-context";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    drawerWidth && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    error: true;
  }
}

export function Header({
  setRightToolbarMenu,
}: {
  setRightToolbarMenu: (ref: HTMLDivElement) => void;
}) {
  //const { login, logout, isAuthenticated } = useOidc();
  const {
    signinRedirect: login,
    signoutRedirect: logout,
    isAuthenticated,
    user,
  } = useAuth();
  //const { oidcUser } = useOidcUser();

  //console.log("Loading:", oidcUserLoadingState, "User:", oidcUser);

  const dispatch = useDispatch();
  const drawerWidth = useSelector(
    (state: RootState) => state.settings.drawerWidth
  );
  const open = useSelector((state: RootState) => state.settings.drawerOpen);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      open={open}
      drawerWidth={drawerWidth}
      color={
        import.meta.env.VITE_GLOBAL_MESSAGE &&
        import.meta.env.VITE_GLOBAL_MESSAGE.trim() !== ""
          ? "error"
          : "primary"
      }
    >
      <Toolbar
        variant="dense"
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toggleDrawerOpen())}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Dashboard
        </Typography>
        {import.meta.env.VITE_GLOBAL_MESSAGE &&
          import.meta.env.VITE_GLOBAL_MESSAGE.trim() !== "" && (
            <Typography
              component="h1"
              variant="h6"
              //color="secondary.light"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {import.meta.env.VITE_GLOBAL_MESSAGE}
            </Typography>
          )}
        {isAuthenticated ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "right",
              //gap: 4,
            }}
          >
            <div
              ref={(ref) => (ref ? setRightToolbarMenu(ref) : undefined)}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "right",
                //gap: 4,
              }}
            />
            <IconButton
              color="inherit"
              size="large"
              aria-label={`Потребител: ${user?.profile.name || "Неизвестен"}`}
              title={`Потребител: ${user?.profile.name || "Неизвестен"}`}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              {/*
              <Badge badgeContent={4} color="secondary">
                <UserIcon />
              </Badge>
              */}
              <UserIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem disabled>Потребител</MenuItem>
              {/*
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              */}
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
              >
                <span style={{ marginRight: "0.5em" }}>Logout</span>
                <em>{user?.profile.name || "Unknown"}</em>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<LoginIcon />}
            onClick={() => login()}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
