import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

export type SerializableUser = Omit<
  User,
  "expires_in" | "expired" | "scopes" | "toStorageString"
>;

export interface AuthState {
  user: SerializableUser | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<SerializableUser | null>) => {
      //console.log("Updated user:", action.payload);
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser } = authSlice.actions;

export default authSlice.reducer;
