import { useCallback, useState } from "react";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";

export function useDriverCommentDialog(
  driver: RosterDriver | ElectroRosterDriver,
  selectedDate: string,
  isReadOnly: boolean,
  driverComment: string | null,
  saveComment: (data: {
    driver_id: string;
    shift_type: 1 | 2;
    date: string;
    comment: string | null;
  }) => Promise<{
    status: "ok";
  }>,
  onOpen: () => void,
  onClose: () => void
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [commentErrors, setCommentErrors] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(driverComment);
  const [commentBusy, setCommentBusy] = useState(false);

  const closeChangeCommentDialog = useCallback(() => {
    if (!commentBusy) {
      setDialogOpen(false);
      setComment("");
      setCommentErrors(null);
    }
  }, [commentBusy]);

  const openChangeCommentDialog = useCallback(() => {
    if (!isReadOnly) {
      onOpen();
      setComment(driverComment);
      setDialogOpen(true);
    }
  }, [driverComment, isReadOnly, onOpen]);

  const changeComment = useCallback(async () => {
    if (!isReadOnly) {
      if (!comment || comment.trim() === "") {
        setCommentErrors("Коментарът е задължителен");
      } else {
        try {
          setCommentBusy(true);
          await saveComment({
            driver_id: driver.schedule_id,
            shift_type: driver.shift_type || 1,
            date: selectedDate,
            comment: comment.trim() !== "" ? comment : null,
          });
          setComment(null);
          setCommentErrors(null);
          setDialogOpen(false);
          onClose();
        } catch {
          setCommentErrors("Коментарът не може да бъде записан");
        } finally {
          setCommentBusy(false);
        }
      }
    }
  }, [
    comment,
    driver.schedule_id,
    driver.shift_type,
    isReadOnly,
    onClose,
    saveComment,
    selectedDate,
  ]);

  const deleteComment = useCallback(async () => {
    if (!isReadOnly) {
      try {
        setCommentBusy(true);
        setComment(null);
        setCommentErrors(null);
        setDialogOpen(false);
        onClose();
        await saveComment({
          driver_id: driver.schedule_id,
          shift_type: driver.shift_type || 1,
          date: selectedDate,
          comment: null,
        });
        setCommentBusy(false);
      } catch {
        setCommentBusy(false);
        setTimeout(() => alert("Коментарът не може да бъде изтрит"), 50);
      }
    }
  }, [
    driver.schedule_id,
    driver.shift_type,
    isReadOnly,
    onClose,
    saveComment,
    selectedDate,
  ]);

  return {
    dialogOpen,
    openChangeCommentDialog,
    closeChangeCommentDialog,
    deleteComment,
    comment,
    setComment,
    commentErrors,
    commentBusy,
    changeComment,
  };
}
