import {
  styled,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Moment } from "moment";
import { RecommendationResponse } from "../../data/api/recommendationsApi";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(1, 3),
}));

export function RestRecommendationTable({
  results,
  resultsForWork,
  variant,
  setDriverFilterValue,
}: {
  date: Moment;
  results: RecommendationResponse;
  resultsForWork?: boolean;
  variant: "normal" | "dense";
  setDriverFilterValue: (value: string) => void;
}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left" width={1} sx={{ px: 2 }}>
            Во&shy;дач
          </TableCell>
          {resultsForWork && (
            <TableCell align="right" width={1} sx={{ px: 2 }}>
              Смя&shy;на
            </TableCell>
          )}
          {variant === "normal" && (
            <TableCell align="right" width={1} sx={{ px: 2 }}>
              Оцен&shy;ка
            </TableCell>
          )}
          <TableCell align="right" width={1} sx={{ textWrap: "pretty", px: 2 }}>
            Брой дни
          </TableCell>
          <TableCell align="left" sx={{ textWrap: "pretty" }}>
            Със&shy;то&shy;я&shy;ние
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.content.suggestions.suggestions.map((r) => (
          <TableRow
            key={`${r.id}-${r.suggestedShift || 0}`}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => setDriverFilterValue(r.id)}
          >
            <TableCell align="left" width={1}>
              <strong>{r.id}</strong>
            </TableCell>
            {resultsForWork && (
              <TableCell align="right" width={1}>
                {r.suggestedShift || ""}
              </TableCell>
            )}
            {variant === "normal" && (
              <TableCell align="right" width={1}>
                <em>{r.cost.toFixed(2)}</em>
              </TableCell>
            )}
            <TableCell align="right" width={1}>
              {r.last_state_count}
            </TableCell>
            <TableCell align="left">
              {r.last_state === "FIRST_SHIFT" ||
              r.last_state === "SECOND_SHIFT" ? (
                <>На ра&shy;бо&shy;та</>
              ) : r.last_state === "REST" ? (
                <>По&shy;чив&shy;ка</>
              ) : r.last_state === "NO_WORK" ? (
                <>От&shy;пуск или бо&shy;лен</>
              ) : (
                <>Дру&shy;го</>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
