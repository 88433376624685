import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { ReactNode, useCallback, useState } from "react";
import { getSettingsByGarage } from "../../common/useGarageSettings";
import { useProfile } from "../../common/useProfile";
import { useDriverFilter } from "../../components/schedule/useDriverFilter";
import { useHeaderScroller } from "../../components/schedule/useHeaderScroller";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { useTableData } from "../../components/schedule/useTableData";
import { VirtualizedTable } from "../../components/schedule/VirtualizedTable";
import { usePostDriverDepotPeriodMutation } from "../../data/api/hooks";
import { useSchedules } from "../../data/schedules/useSchedules";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { MonthPicker } from "../../filters/MonthPicker";
import { TransportSelect } from "../../filters/TransportSelect";
import { PlannerDialog } from "./PlannerDialog";
import { ScheduleTopMenu } from "./ScheduleTopMenu";
import { useCalculateGarageStatistics } from "./useCalculateGarageStatistics";
import { useVehicleTypesList } from "./useVehicleTypesList";

export function DriverScheduleImpl() {
  const {
    scheduleParameters,
    needsTransportSelect,
    needsDepotSelect,
    needsAutocolumnSelect,
  } = useScheduleParameters();

  const {
    schedule,
    daysInMonth,
    startPlanner: startPlannerImpl,
    stopPlanner,
    saveSchedule,
    cleanupSchedule,
    markReady,
    reimportAllData,
    updateDriverState,
    editDriverDayComment,
  } = useSchedules();

  const [postDriverDepotPeriod] = usePostDriverDepotPeriodMutation();

  const { vehicleTypes, vehicleTypesLoading, vehicleTypesError } =
    useVehicleTypesList(schedule);
  const [selectedVehicleType, setSelectedVehicleType] = useState(-2);

  const { columns, rows, daySummary, driverSummary } = useTableData(
    scheduleParameters,
    schedule,
    daysInMonth,
    selectedVehicleType
  );

  const headerScroller = useHeaderScroller();
  const driverFilter = useDriverFilter(rows, headerScroller.scrollToRow);

  const garageStatistics = useCalculateGarageStatistics(schedule, daySummary);

  const [menuError, setMenuError] = useState<ReactNode | null>(null);

  // const { plannerSettings, enablePlannerDialog } = useGarageSettings();
  // console.log("Planner settings:", plannerSettings);
  const { plannerSettings, enablePlannerDialog } = getSettingsByGarage(
    scheduleParameters?.[0] || "",
    scheduleParameters?.[1] || 0
  );
  const profile = useProfile();

  const [plannerDialogVisible, setPlannerDialogVisible] = useState(false);
  const startPlanner = useCallback(async () => {
    if (profile?.roles?.includes("nariad_admin") || enablePlannerDialog) {
      setPlannerDialogVisible(true);
    } else {
      const result = await startPlannerImpl(plannerSettings);
      return result;
    }
  }, [enablePlannerDialog, plannerSettings, profile?.roles, startPlannerImpl]);

  return (
    <>
      <ScheduleTopMenu
        scheduleParameters={scheduleParameters}
        schedule={schedule}
        daySummary={daySummary}
        driverSummary={driverSummary}
        driverFilter={driverFilter}
        startPlanner={startPlanner}
        stopPlanner={stopPlanner}
        saveSchedule={saveSchedule}
        cleanupSchedule={cleanupSchedule}
        markReady={markReady}
        reimportAllData={reimportAllData}
        setDriverFilterValue={driverFilter.setDriverFilterValue}
        setError={setMenuError}
      />

      {plannerDialogVisible && (
        <PlannerDialog
          open={plannerDialogVisible}
          initialSettings={plannerSettings}
          confirm={(settings) => {
            setPlannerDialogVisible(false);
            startPlannerImpl(settings);
          }}
          reject={() => setPlannerDialogVisible(false)}
        />
      )}

      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Collapse in={!!menuError}>
          <Alert
            color="error"
            onClose={() => setMenuError(null)}
            sx={{ ["& .MuiAlert-message"]: { maxHeight: 200, width: "100%" } }}
          >
            {menuError}
          </Alert>
        </Collapse>

        <Container sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={2}>
            {needsTransportSelect ? (
              <Grid xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid xs={12} sm={1}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid xs={12} sm={2}>
              <MonthPicker />
            </Grid>
            <Grid xs={12} sm={3} pr={3}>
              <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                <InputLabel id="schedule-transport">Марка</InputLabel>
                <Select
                  labelId="schedule-transport"
                  id="schedule-transport-select"
                  value={selectedVehicleType}
                  label="Марка"
                  onChange={(e) =>
                    setSelectedVehicleType(e.target.value as number)
                  }
                >
                  <MenuItem value={-2}>Всички</MenuItem>
                  <MenuItem value={-1}>Сменници</MenuItem>
                  {(vehicleTypesLoading ||
                    vehicleTypesError ||
                    vehicleTypes.length > 0) && <Divider />}
                  {vehicleTypesLoading ? (
                    <MenuItem
                      value={-1000}
                      disabled
                      sx={{ placeContent: "center" }}
                    >
                      <CircularProgress />
                    </MenuItem>
                  ) : vehicleTypesError ? (
                    <Box sx={{ placeContent: "center", px: 2, pt: 1 }}>
                      <Alert color="error">Грешка при зареждане на марки</Alert>
                    </Box>
                  ) : (
                    vehicleTypes.map((vehicleType) => (
                      <MenuItem key={vehicleType.id} value={vehicleType.id}>
                        {vehicleType.description}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>

        <Box style={{ flexGrow: 1 }}>
          {daySummary && driverSummary ? (
            <VirtualizedTable
              schedule={schedule}
              updateDriverState={updateDriverState}
              columns={columns}
              rows={rows}
              headerColumnWidth={52}
              firstHeaderColumnWidth={62}
              daySummary={daySummary}
              driverSummary={driverSummary}
              headerScroller={headerScroller}
              driverFilter={driverFilter}
              garageStatistics={garageStatistics}
              editDriverDayComment={editDriverDayComment}
              editDriverDepot={(data) => postDriverDepotPeriod(data).unwrap()}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
}
