import { Alert, AlertTitle, SxProps, Theme } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { usePostRosterExportMutation } from "../data/api/hooks";

const commonProperties: Array<keyof SerializedError | "error"> = [
  "name",
  "message",
  "stack",
  "code",
  "error",
];

const miniSerializeError = (
  value: any
): SerializedError & { error?: string } => {
  if (typeof value === "object" && value !== null) {
    const simpleError: SerializedError & { error?: string } = {};
    for (const property of commonProperties) {
      if (typeof value[property] === "string") {
        simpleError[property] = value[property];
      }
    }

    return simpleError;
  }

  return { message: String(value) };
};

// function is403Error(error?: FetchBaseQueryError | SerializedError | null) {
//   if (!error) {
//     return false;
//   } else if (
//     (error as any).status === "PARSING_ERROR" &&
//     (error as any).originalStatus === 403
//   ) {
//     return true;
//   } else if ((error as any).code === 403) {
//     return true;
//   } else {
//     return false;
//   }
// }

export function QueryError({
  error,
  sx,
}: {
  error?: FetchBaseQueryError | SerializedError | null;
  sx?: SxProps<Theme> | undefined;
}) {
  // const serialized = useMemo(() => miniSerializeError(error), [error]);
  // console.log("Data fetch error:", serialized, error);

  if (error) {
    // if (is403Error(error)) {
    //   console.warn("403: Unauthorized");
    // } else {
    //   console.warn("Error:", serialized);
    // }

    if (!error) {
      return <></>;
    } else {
      if (
        (error as any).data?.status === "error" &&
        (error as any).data?.error_type === "electro"
        // Array.isArray((error as any).data.data?.errors) &&
        // (error as any).data.data.errors.length > 0
      ) {
        const result: Exclude<
          Awaited<
            ReturnType<
              ReturnType<
                ReturnType<typeof usePostRosterExportMutation>[0]
              >["unwrap"]
            >
          >,
          { status: "ok" }
        > = (error as any).data;
        return (
          <Alert severity="error" sx={sx}>
            <AlertTitle>Грешка</AlertTitle>
            {result.data.errors.map((e, idx) => (
              <div key={idx}>{`${e.result} (разписание ${e.code})`}</div>
            ))}
          </Alert>
        );
      } else {
        return (
          <Alert severity="error" sx={sx}>
            Data fetch error:{" "}
            {"Възникна грешка по време на работа на системата!"}
          </Alert>
        );
      }
    }
  } else {
    return (
      <Alert severity="error" sx={sx}>
        Data fetch error: {"Възникна грешка по време на работа на системата!"}
      </Alert>
    );
  }
}
