import moment, { Moment } from "moment";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { DayTaskStates } from "../pages/reports/schedule_recapitulation/useScheduleRecapitulation";
import { ReportPrinter } from "./ReportPrinter";
import { Transport } from "../data/api/types/driver";
import { FontStyle } from "jspdf-autotable";

function compareArrays<T>(a: readonly T[], b: readonly T[]) {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
  }

  return true;
}

export function usePrintRosterReport() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const [needsAutocolumnSelect] = useSelector((state: RootState) => [
    state.filters.needsAutocolumnSelect,
  ]);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      rows: { [key: string]: number[] },
      daytask_states: DayTaskStates[],
      selectedDate: Moment,

      vehicle_rows: {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
          state_category: number;
          state_id: number;
          first_shift_reserv: boolean;
          second_shift_reseve: boolean;
        }[];
      },
      vehicle_states: {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[]
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter({
          marginX: 40,
          marginY: 40,
          headerText: `НАРЯД ЗА ДАТА: ${moment(
            selectedDate,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} ${moment(selectedDate, "YYYY-MM-DD").format(
            "dddd"
          )}, ${selectedTransport !== "A" ? "Депо" : "АП"} "${
            depots.find((d) => d.id === selectedDepot)?.value || "? "
          } ${
            selectedTransport === "A" || needsAutocolumnSelect
              ? '", Автокoлона ' + selectedAutoColumn.toString()
              : '"'
          }`,
        });
        printer.printSpace(5);

        printer.setFont("normal", 12, 1.2);

        selectedTransport === "A"
          ? printer.printText(
              "Утвърдил Директор: ....................",
              printer.printableWidth -
                (printer.getWidth("Утвърдил Директор: ....................") +
                  120)
            )
          : printer.printText(
              "Утвърдил: ....................",
              printer.printableWidth -
                (printer.getWidth("Утвърдил: ....................") + 120)
            );
        printer.printSpace(15);

        printer.setFont("normal", 18, 1.2);
        printer.printText(
          `${selectedTransport !== "A" ? "Депо" : "АП"} " ${
            depots.find((d) => d.id === selectedDepot)?.value.toUpperCase() ||
            "?"
          }"`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.printSpace(5);

        printer.setFont("normal", 12, 1.2);
        printer.printText(
          `СОФИЯ`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.printSpace(20);

        printer.setFont("normal", 12, 1.2);
        selectedTransport === "A"
          ? printer.printText(
              `График за работа на водачите на МПС на "Столичен автотранспорт" ЕАД`,
              printer.pageMarginX + printer.printableWidth / 2,
              printer.printableWidth,
              { align: "center" }
            )
          : printer.printText(
              `График за работа на водачите на "Столичен електротранспорт" ЕАД`,
              printer.pageMarginX + printer.printableWidth / 2,
              printer.printableWidth,
              { align: "center" }
            );

        printer.printSpace(20);

        printer.setFont("normal", 12, 1.2);
        printer.printText(
          `НАРЯД ЗА ДАТА: ${moment(selectedDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )} ${moment(selectedDate, "YYYY-MM-DD").format("dddd")} ${
            selectedTransport === "A" || needsAutocolumnSelect
              ? " , Автокoлона " + selectedAutoColumn.toString()
              : ""
          }`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.autoTable({
          html: "#roster-report-table",
          styles: {
            halign: "center",
            valign: "top",
            font: "Roboto",
            fontStyle: "normal",
            fontSize: 11,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            //lineWidth: 0.25,
            lineWidth: 0,
          },
          headStyles: {
            font: "Roboto",
            fontStyle: selectedTransport === "A" ? "normal" : "bold",
            fontSize: 9,
            cellPadding: { horizontal: 4, vertical: 3 },
          },
          bodyStyles: {
            font: "Roboto",
            fontStyle: "light" as FontStyle,
            //cellPadding: 4,
            cellPadding: { horizontal: 4, vertical: 2 },
          },
          columnStyles: {
            0: {
              cellWidth: 50,
              fontStyle: selectedTransport === "A" ? "normal" : "bold",
            },
            1: { cellWidth: 30 },
            2: { cellWidth: selectedTransport === "A" ? 45 : 70 },
            3: { cellWidth: 45 },
            4: {
              halign: "left",
              cellWidth:
                (printer.printableWidth - (50 + 30 + 45 + 45 + 45 + 45)) / 2,
            },
            5: { cellWidth: selectedTransport === "A" ? 45 : 70 },
            6: { cellWidth: 45 },
            7: {
              halign: "left",
              cellWidth:
                (printer.printableWidth - (50 + 30 + 45 + 45 + 45 + 45)) / 2,
            },
          },
          didParseCell: (cell) => {
            if (
              cell.section === "body" &&
              //(cell.column.index === 4 || cell.column.index === 9) &&
              cell.cell.raw instanceof HTMLTableCellElement
            ) {
              const lines: string[] = [];
              for (const child of cell.cell.raw.children) {
                if (child instanceof HTMLDivElement) {
                  lines.push(child.textContent || "");
                }
              }

              if (lines.length > 0) {
                //console.log("Comment:", lines, cell);
                cell.cell.text = lines;
              }
            }
          },
          willDrawCell: (cell) => {
            if (
              cell.section === "body" &&
              cell.row.index > 0 &&
              !compareArrays(
                cell.table.body[cell.row.index - 1].cells[0].text,
                cell.row.cells[0].text
              )
            ) {
              //console.log("Change line:", cell);
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);
              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );
              printer.doc.setLineWidth(oldWidth);
            } else if (cell.section === "head") {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);

              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );

              printer.doc.line(
                cell.cell.x,
                cell.cell.y + cell.cell.height,
                cell.cell.x + cell.cell.width,
                cell.cell.y + cell.cell.height
              );
              printer.doc.setLineWidth(oldWidth);
            } else if (cell.section === "foot") {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);
              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );
              printer.doc.setLineWidth(oldWidth);
            }
          },
        });

        printer.printSpace(50);
        printer.setFont("bold", 12, 1.2);

        let tableHeight =
          printer.doc.getTextDimensions(`РАЗПРЕДЕЛЕНИЕ НА ВОДАЧИ`).h + 15;

        for (const [stateId, drivers] of Object.entries(rows).filter(
          (r) => r[0] !== "21" && r[0] !== "22"
        )) {
          const state = daytask_states.find(
            (s) => s.state_id === parseInt(stateId, 10)
          );

          printer.setFont("bold", 12, 1.2);
          tableHeight +=
            printer.doc.getTextDimensions(
              `${state?.description || "Неопределен"} - ${drivers.length} бр.`
            ).h + 5;

          printer.setFont("light", 11, 2);

          tableHeight +=
            printer.doc.getTextDimensions(drivers.join(", ")).h + 15;
          break;
        }

        printer.addPage(tableHeight);

        printer.setFont("bold", 12, 1.2);
        printer.printText(`РАЗПРЕДЕЛЕНИЕ НА ВОДАЧИ`);

        printer.printSpace(15);

        for (const [stateId, drivers] of Object.entries(rows).filter(
          (r) => r[0] !== "21" && r[0] !== "22"
        )) {
          const state = daytask_states.find(
            (s) => s.state_id === parseInt(stateId, 10)
          );

          printer.setFont("bold", 12, 1.2);
          printer.printText(
            `${state?.description || "Неопределен"} - ${drivers.length} бр.`
          );

          printer.printSpace(5);

          printer.setFont("light", 11, 2);
          printer.printText(drivers.join(", "));

          printer.printSpace(15);
        }

        printer.printLine(1);
        printer.printSpace(15);

        printer.setFont("bold", 12, 1.2);

        // измерва има ли място за заглавие + ред от разпределението и след това изписва
        tableHeight =
          printer.doc.getTextDimensions(`РАЗПРЕДЕЛЕНИЕ НА КОЛИ`).h + 15;

        for (const [stateId, vehicles] of Object.entries(vehicle_rows).filter(
          (r) => r[0] !== "0"
        )) {
          const state = vehicle_states.find(
            (s) => s.id === parseInt(stateId, 10)
          );

          printer.setFont("bold", 12, 1.2);
          tableHeight += printer.doc.getTextDimensions(
            `${state?.description || "Неопределен"} - ${vehicles.length} бр.`
          ).h;
          tableHeight += 5;

          printer.setFont("light", 11, 2);
          tableHeight += printer.doc.getTextDimensions(
            vehicles.map((v) => v.veh_id).join(", ")
          ).h;
          tableHeight += 15;

          break;
        }

        printer.addPage(tableHeight);

        printer.setFont("bold", 12, 1.2);
        printer.printText(`РАЗПРЕДЕЛЕНИЕ НА КОЛИ`);

        printer.printSpace(15);

        const resState = vehicle_states.find((s) =>
          s.description.toUpperCase().trim().startsWith("РЕЗ")
        );
        const serviseState = vehicle_states.find((s) =>
          s.description.toUpperCase().trim().startsWith("СЕРВ")
        );

        for (const [stateId, vehicles] of Object.entries(vehicle_rows).filter(
          (r) => r[0] !== "0"
        )) {
          const state = vehicle_states.find(
            (s) => s.id === parseInt(stateId, 10)
          );

          printer.setFont("bold", 12, 1.2);
          printer.printText(
            `${state?.description || "Неопределен"} - ${vehicles.length} бр.`
          );

          printer.printSpace(5);
          //if (state?.id === serviseState?.id)
          {
            printer.setFont("light", 11, 2);
            printer.printText(
              vehicles
                .filter(
                  (s) =>
                    s.state_comment.length === 0 && s.veh_comment.length === 0
                )
                .map((v) => v.veh_id)
                .join(", "),
              printer.pageMarginX + 40
            );

            Object.values(
              vehicles
                .filter(
                  (s) => s.state_comment.length > 0 || s.veh_comment.length > 0
                )
                .reduce<{
                  [key: string]: {
                    description: string;
                    veh_id: number;
                  }[];
                }>((acc, x) => {
                  if (acc[x.state_comment + x.veh_comment] === undefined) {
                    acc[x.state_comment + x.veh_comment] = [
                      {
                        description: x.state_comment + " " + x.veh_comment,
                        veh_id: x.veh_id,
                      },
                    ];
                  } else {
                    acc[x.state_comment + x.veh_comment].push({
                      description: x.state_comment + " " + x.veh_comment,
                      veh_id: x.veh_id,
                    });
                  }
                  return acc;
                }, {})
            ).forEach((el) => {
              printer.setFont("light", 11, 2);
              printer.printText(
                // state?.description +
                //   " - " +
                el[0].description + ": " + el.map((v) => v.veh_id).join(", "),
                printer.pageMarginX + 40
              );
            });
            printer.printSpace(15);
          }
          // if (state?.id !== serviseState?.id) {
          //   printer.setFont("light", 11, 2);
          //   printer.printText(vehicles.map((v) => v.veh_id).join(", "));

          //   printer.printSpace(15);
          // }

          if (state?.id === resState?.id) {
            printer.setFont("bold", 12, 2);

            printer.printText(
              `Резерва I смяна -${
                Object.values(vehicle_rows)
                  .flatMap((r) => r.map((r) => r))
                  .filter((r) => r.first_shift_reserv)
                  .map((r) => r.veh_id).length
              } бр.`
            );
            printer.setFont("light", 11, 2);
            printer.printText(
              Object.values(vehicle_rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.first_shift_reserv)
                .map((r) => r.veh_id)
                .sort((a, b) => a - b)
                .join(", "),
              printer.pageMarginX + 40
            );

            printer.printSpace(15);

            printer.setFont("bold", 12, 2);

            printer.printText(
              `Резерва II смяна -${
                Object.values(vehicle_rows)
                  .flatMap((r) => r.map((r) => r))
                  .filter((r) => r.second_shift_reseve)
                  .map((r) => r.veh_id).length
              } бр.`
            );
            printer.setFont("light", 11, 2);
            printer.printText(
              Object.values(vehicle_rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.second_shift_reseve)
                .map((r) => r.veh_id)
                .sort((a, b) => a - b)
                .join(", "),
              printer.pageMarginX + 40
            );

            printer.printSpace(15);
          }
        }
        //ако няма резерва коли, да запише все пак резервите I и II смяна - отделено е защото другото трябва да е точно под резервата
        if (
          resState &&
          !Object.entries(vehicle_rows).find(
            (r) => r[0] === resState.id.toString()
          )
        ) {
          printer.setFont("bold", 12, 2);

          printer.printText(
            `Резерва I смяна -${
              Object.values(vehicle_rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.first_shift_reserv)
                .map((r) => r.veh_id).length
            } бр.`
          );
          printer.setFont("light", 11, 2);
          printer.printText(
            Object.values(vehicle_rows)
              .flatMap((r) => r.map((r) => r))
              .filter((r) => r.first_shift_reserv)
              .map((r) => r.veh_id)
              .sort((a, b) => a - b)
              .join(", "),
            printer.pageMarginX + 40
          );

          printer.printSpace(15);

          printer.setFont("bold", 12, 2);

          printer.printText(
            `Резерва II смяна -${
              Object.values(vehicle_rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.second_shift_reseve)
                .map((r) => r.veh_id).length
            } бр.`
          );
          printer.setFont("light", 11, 2);
          printer.printText(
            Object.values(vehicle_rows)
              .flatMap((r) => r.map((r) => r))
              .filter((r) => r.second_shift_reseve)
              .map((r) => r.veh_id)
              .sort((a, b) => a - b)
              .join(", "),
            printer.pageMarginX + 40
          );

          printer.printSpace(15);
        }

        //printer.printSpace(30);
        printer.printLine(1);
        printer.printSpace(15);

        printer.setFont("bold", 12, 1.2);

        //фалшиво изчертаване с бял текст, за да се изчисли дали ще стигне височината, за да не се пренесат само подписите на самостоятелна страница
        // и след това продължава с истинското изчертаване

        tableHeight = printer.doc.getTextDimensions(`РЕКАПИТУЛАЦИЯ`).h;

        const oldHeight = printer.currentPageY;
        const oldPagNUmber = printer.doc.getCurrentPageInfo().pageNumber;

        printer.autoTable({
          html: "#roster-recapitulation-table",
          styles: {
            font: "Roboto",
            fontSize: 9,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            lineWidth: 0,
            textColor: "White",
          },
          bodyStyles: {
            cellPadding: 4,
          },
          headStyles: {
            textColor: "White",
          },

          body: rows as any,
        });

        if (oldPagNUmber < printer.doc.getCurrentPageInfo().pageNumber) {
          // т.е. таблицата е прехвърлила страницата и подписите няма да са сами
          //започваме от началото на страницата

          printer.currentPageY = 0;
        } else {
          // събрала се е таблицата, но проверяваме дали подписите няма да останат сами
          tableHeight += printer.currentPageY - oldHeight;
          printer.currentPageY = oldHeight;

          printer.setFont("normal", 12, 1.2);
          tableHeight +=
            printer.doc.getTextDimensions(
              `Зам. Директор:     ...............................`
            ).h *
              3 +
            140;

          printer.addPage(tableHeight);
        }

        console.log("CurrentY", printer.currentPageY);
        printer.setFont("bold", 12, 1.2);
        printer.printText(`РЕКАПИТУЛАЦИЯ`);

        printer.autoTable({
          html: "#roster-recapitulation-table",
          styles: {
            halign: "center",
            valign: "top",
            font: "Roboto",
            fontSize: 9,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            //lineWidth: 0.25,
            lineWidth: 0,
          },
          bodyStyles: {
            cellPadding: 4,
          },
          columnStyles: {
            0: { halign: "left" },
            1: { halign: "right" },
            3: { halign: "left" },
            4: { halign: "right" },
            6: { halign: "left" },
            7: { halign: "right" },
          },
          willDrawCell: (cell) => {
            if (cell.section === "body") {
              if (
                cell.row.index === cell.table.body.length - 1 &&
                [1, 4].includes(cell.column.index)
              ) {
                const oldWidth = printer.doc.getLineWidth();
                printer.doc.setLineWidth(1);
                printer.doc.line(
                  cell.cell.x,
                  cell.cell.y,
                  cell.cell.x + cell.cell.width,
                  cell.cell.y
                );
                printer.doc.setLineWidth(oldWidth);
              }
            }
          },
          body: rows as any,
        });

        printer.setFont("normal", 12, 1.2);
        printer.printSpace(50);

        printer.printText(
          "Нарядчик:             ..............................."
        );
        printer.printSpace(35);

        if (selectedTransport === "A") {
          printer.printText("Н-к Автоколона:  ...............................");
          printer.printSpace(35);

          printer.printText(
            "Зам. Директор:     ..............................."
          );

          printer.printSpace(35);
          printer.printText(
            "Лице извършило предпътен технически преглед на МПС:"
          );
          printer.printSpace(5);
          printer.printText("Нощна смяна");
          printer.printSpace(15);
          printer.printText(
            "1. _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ "
          );
          printer.printText("/фамилия, подпис, печат/");
          printer.printSpace(15);
          printer.printText("Дневна смяна");
          printer.printSpace(15);
          printer.printText(
            "1. _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ "
          );
          printer.printText("/фамилия, подпис, печат/");
          printer.printSpace(25);
          printer.printText(
            "Лице извършило предпътна проверка за употреба на алкохол на водачите:"
          );
          printer.printSpace(5);
          printer.printText("Нощна смяна");
          printer.printSpace(15);
          printer.printText(
            "1. _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ "
          );
          printer.printText("/фамилия, подпис, печат/");
          printer.printSpace(15);
          printer.printText("Дневна смяна");
          printer.printSpace(15);
          printer.printText(
            "1. _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ "
          );
          printer.printText("/фамилия, подпис, печат/");
        }

        printer.save(
          `Наряд-${selectedDate}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      depots,
      needsAutocolumnSelect,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
