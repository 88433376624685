import { ItemContainer } from "./ItemContainer";
import { DraggableDriverItem } from "./DraggableDriverItem";
import { memo, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Roster, RosterDriver } from "./types/Roster";
import { green } from "@mui/material/colors";
import { alpha } from "@mui/material";
import { useSchedules } from "../../data/schedules/useSchedules";
import { warnOnPromise } from "./warnOnPromise";
import { useProfile } from "../../common/useProfile";
import { DriverItem } from "./DriverItem";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../data/schedules/types";
import moment from "moment";

export function useDropOnDrivers(
  roster: Roster,
  selectedDate: string,
  isReadOnly: boolean
) {
  const { removeDriverFromVehicle } = useSchedules();

  const dropOnDrivers = useCallback(
    (driver: RosterDriver) => {
      if (isReadOnly) {
        return;
      }

      console.log("Drop on drivers", driver, roster);

      if (driver.type === "driver") {
        const fromVehicles = roster.vehicles.filter((vehicle) =>
          vehicle.shifts.find((s) =>
            s.find((s) => s.driver_id === driver.driver_id)
          )
        );

        for (const vehicle of fromVehicles) {
          console.log("Dropped", driver, "from", vehicle);
          for (const d of vehicle.shifts) {
            for (const s of d) {
              if (s.driver_id === driver.driver_id) {
                warnOnPromise(removeDriverFromVehicle, {
                  driver_id: driver.schedule_id,
                  date: selectedDate,
                  veh1_id: vehicle.vehicle_id,
                  veh2_id: null,
                });
              }
            }
          }
        }
      } else {
        console.warn("Dropped unknown element:", driver);
      }
    },
    [isReadOnly, removeDriverFromVehicle, roster, selectedDate]
  );

  return dropOnDrivers;
}

export const DroppableDriversContainer = memo(
  ({
    isReadOnly,
    roster,
    title,
    selectedDate,
    selectedVehicleTypes,
    selectedLines,
    accept,
    onDrop,
    saveComment,
    saveShift,
  }: {
    isReadOnly: boolean;
    roster: Roster;
    title?: string;
    selectedDate: string;
    selectedVehicleTypes: readonly number[];
    selectedLines: readonly number[];
    accept: string[];
    onDrop: (item: RosterDriver) => void;
    saveComment: (
      data: EditDriverDayRosterCommentData
    ) => Promise<{ status: "ok" }>;
    saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  }) => {
    const [{ isOver, canDrop }, drop] = useDrop(
      {
        accept,
        drop: onDrop,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      },
      [accept, onDrop]
    );
    const isActive = isOver && canDrop;

    const [panelHeight, setPanelHeight] = useState(100);
    const [currentPanelHeight, setCurrentPanelHeight] = useState(100);
    const handleDrag = useCallback(
      (event: "start" | "move" | "end", delta: { x: number; y: number }) => {
        if (event === "move") {
          setCurrentPanelHeight(panelHeight + delta.y);
        } else if (event === "end") {
          setCurrentPanelHeight(panelHeight + delta.y);
          setPanelHeight(panelHeight + delta.y);
        }
      },
      [panelHeight]
    );

    const filteredDrivers = useMemo(
      () =>
        roster.unusedDrivers.filter((driver) => {
          const allowedVehicleType =
            driver.skillVehicleTypes.length === 0 ||
            selectedVehicleTypes.length === 0 ||
            driver.skillVehicleTypes.filter((svt) =>
              selectedVehicleTypes.includes(svt)
            ).length > 0;

          if (selectedLines.length > 0) {
            const yesterday = moment(selectedDate, "YYYY-MM-DD")
              .subtract(1, "day")
              .format("DD.MM.YYYY");
            const tasks =
              driver.old_daytasks.find((t) => t.date === yesterday)?.tasks ||
              [];

            const differentLineTasks = tasks.filter(
              (t) =>
                (t.line_id || t.line_id === 0) &&
                !selectedLines.includes(t.line_id)
            );
            const sameLineTasks = tasks.filter(
              (t) =>
                (t.line_id || t.line_id === 0) &&
                selectedLines.includes(t.line_id)
            );
            const allowedLine =
              sameLineTasks.length > 0 || differentLineTasks.length === 0;

            return allowedVehicleType && allowedLine;
          } else {
            return allowedVehicleType;
          }
        }),
      [roster.unusedDrivers, selectedDate, selectedLines, selectedVehicleTypes]
    );

    return (
      <ItemContainer
        ref={drop}
        title={title}
        style={{
          minHeight: currentPanelHeight,
        }}
        sx={{
          backgroundColor: isActive ? alpha(green[100], 0.25) : undefined,
        }}
        onDragTitle={handleDrag}
      >
        <Grid container>
          {filteredDrivers.map((driver) =>
            isReadOnly ? (
              <DriverItem
                key={`driver-${driver.driver_id}`}
                isReadOnly={isReadOnly}
                driver={driver}
                roster={roster}
                selectedDate={selectedDate}
                saveComment={saveComment}
                saveShift={saveShift}
              />
            ) : (
              <DraggableDriverItem
                key={`driver-${driver.driver_id}`}
                isReadOnly={isReadOnly}
                driver={driver}
                roster={roster}
                selectedDate={selectedDate}
                saveComment={saveComment}
                saveShift={saveShift}
              />
            )
          )}
        </Grid>
      </ItemContainer>
    );
  }
);
