import {
  Alert,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Portal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../data/store";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { QueryError } from "../QueryError";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { useGetVehicleTypeListQuery } from "../../data/api/hooks";

import { updateSelectedDate } from "../../data/filtersSlice";
import { useRosterReportData } from "./roster_report/useReportData";
import { useTableRows } from "./roster_report/useTableRows";
import { DayRosterData } from "./roster_report/helpers";
import { useSubmenu } from "../../hooks/useSubmenu";
import { usePrintSeparateCarsPdfExporterer } from "../../printing/useSeparateCarsPDFExporter";
import { Print } from "@mui/icons-material";
import { useVehicleTypesList } from "../schedule/useVehicleTypesList";
import { sortArray } from "../../data/api/types/schedule";
import { useVehicleGarageTypes } from "./useTimetables_ByDate";
import { useRosterOuttimesPdfExporterer } from "../../printing/useRosterOuttimesPdfExporter";

interface RosterOuttimesColumn {
  id: "line_name" | "car_no" | "vehicle" | "start_time" | "driver_id";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

export const RosterOuttimesColumns: RosterOuttimesColumn[] = [
  { id: "line_name", label: "Линия", minWidth: 80, align: "left" },
  { id: "car_no", label: "Кола", minWidth: 80, align: "left" },
  { id: "vehicle", label: "Кола излизане", minWidth: 80, align: "left" },
  { id: "driver_id", label: "Водач", minWidth: 80, align: "left" },
  { id: "start_time", label: "Час излизане", minWidth: 80, align: "left" },
];

function StickyHeadTable({
  rows,
}: {
  rows: {
    line_name: string;
    car_no: number;
    vehicle: number;
    start_time: string;
    driver_id: number;
  }[];
}) {
  return (
    <Paper sx={{ height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="stickyHeader">
          <TableHead>
            <TableRow>
              {RosterOuttimesColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, idx) => (
              <React.Fragment key={idx}>
                <TableRow>
                  <TableCell>{r.line_name}</TableCell>
                  <TableCell>{r.car_no > -1 ? r.car_no : ""}</TableCell>
                  <TableCell>{r.vehicle}</TableCell>
                  <TableCell>{r.driver_id}</TableCell>
                  <TableCell>{r.start_time}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function RosterOutTimesReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const dispatch = useAppDispatch();

  const {
    explPlanData,
    isExplPlanError,
    isExplPlanLoading,
    isRosterError,
    isRosterLoading,
    isExplPlanFetching,
    isRosterFetching,
    day_roster,
    day_workshift_ids,
  } = useRosterReportData(selectedDate, selectedAvtoColumn);

  const rows = useTableRows(
    selectedDate,
    explPlanData,
    undefined,
    undefined,
    day_roster,
    day_workshift_ids
  );

  console.log("Rows", rows);

  const { rightToolbarMenu } = useSubmenu();

  const { exportPdf, isLoading: exportPdfLoading } =
    useRosterOuttimesPdfExporterer();

  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<number[]>(
    []
  );
  //const schedule = useSelector((state: RootState) => state.schedule.schedule);
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  //   const {
  //     currentData: vehicleTypesRecord,
  //     isLoading: vehicleTypesLoading,
  //     isError: vehicleTypesError,
  //   } = useGetVehicleTypeListQuery(selectedTransport, {
  //     skip: selectedTransport === "",
  //   });

  //   const vehicleTypes = useMemo(() => {
  //     const vehicleTypes = sortArray(
  //       Object.values(vehicleTypesRecord || {}),
  //       "description",
  //       {
  //         in_place: true,
  //       }
  //     );
  //     return vehicleTypes;
  //   }, [vehicleTypesRecord]);
  const {
    vehicleTypeList: vehicleTypes,
    vehicleTypesLoading,
    vehicleTypesError,
    vehicles,
  } = useVehicleGarageTypes();

  const report_rows = useMemo(() => {
    return rows.arr
      .filter((r) => {
        const veh_type_id = vehicles?.data?.data.find(
          (v) => v.vehicle_id === r.vehicle1
        )?.vehicletype_id;
        if (r.vehicle1 > -1 && r.start_time !== null && r.start_time > 0)
          if (
            selectedVehicleTypes.length > 0 &&
            vehicles?.data &&
            veh_type_id &&
            selectedVehicleTypes.includes(veh_type_id)
          )
            return true;
          else if (selectedVehicleTypes.length === 0) return true;
        return false;
      })
      .sort((a, b) =>
        a.start_time && b.start_time
          ? a.start_time > b.start_time
            ? 1
            : -1
          : 1
      )
      .map((r) => {
        return {
          line_name: r.line_name,
          car_no: r.car_no,
          vehicle: r.vehicle1,
          driver_id: r.driver1,
          start_time:
            r.start_time !== null
              ? moment
                  .utc(
                    moment.duration(r.start_time, "seconds").as("milliseconds")
                  )
                  .format("HH:mm")
              : "",
        };
      });
  }, [rows.arr, selectedVehicleTypes, vehicles?.data]);

  //   const { vehicleTypes, vehicleTypesLoading, vehicleTypesError } =
  //     useVehicleTypesList(undefined);

  console.log("SelectedVehTypes", selectedVehicleTypes);
  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() =>
            exportPdf(
              report_rows,
              selectedDate,
              selectedVehicleTypes.map(
                (v) => vehicleTypes.find((vt) => vt.id === v)?.label
              )
            )
          }
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={2}>
              <DatePicker
                value={moment(selectedDate)}
                onChange={(e) =>
                  e &&
                  e.isValid() &&
                  dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
                }
                label="Дата"
                views={["year", "month", "day"]}
                openTo="day"
                slotProps={{
                  textField: {
                    variant: "standard",
                    sx: { m: 1, width: "100%" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} pr={3}>
              <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                <InputLabel id="roster-vehicletype">Марка</InputLabel>
                <Select
                  multiple
                  labelId="roster-vehicletype"
                  id="roster-vehicletype-select"
                  value={selectedVehicleTypes}
                  label="Марка"
                  renderValue={(selected) =>
                    selected
                      .map(
                        (vtIdx) =>
                          vehicleTypes.find((vt) => vt.id === vtIdx)?.label
                      )
                      .filter((l) => l && l.trim() !== "")
                      .join(", ")
                  }
                  onChange={(e) => {
                    if (typeof e.target.value === "string") {
                      setSelectedVehicleTypes([parseInt(e.target.value, 10)]);
                    } else if (typeof e.target.value === "number") {
                      setSelectedVehicleTypes([e.target.value]);
                    } else {
                      setSelectedVehicleTypes(e.target.value);
                    }
                  }}
                >
                  {vehicleTypesLoading ? (
                    <MenuItem
                      value={-1000}
                      disabled
                      sx={{ placeContent: "center" }}
                    >
                      <CircularProgress />
                    </MenuItem>
                  ) : vehicleTypesError ? (
                    <Box sx={{ placeContent: "center", px: 2, pt: 1 }}>
                      <Alert color="error">Грешка при зареждане на марки</Alert>
                    </Box>
                  ) : (
                    vehicleTypes.map((vehicleType) => (
                      <MenuItem key={vehicleType.id} value={vehicleType.id}>
                        <Checkbox
                          checked={selectedVehicleTypes.includes(
                            vehicleType.id
                          )}
                        />
                        <ListItemText primary={vehicleType.label} />
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {selectedVehicleTypes &&
            selectedVehicleTypes.length > 0 &&
            !isExplPlanError &&
            !isExplPlanLoading &&
            !isRosterError &&
            !isRosterLoading && (
              <Grid container mt={1} mb={3} spacing={2}>
                <Grid item sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}>
                  {/* <Paper sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}> */}
                  Избрани марки:{" "}
                  {selectedVehicleTypes
                    .map((v) => vehicleTypes.find((vt) => vt.id === v)?.label)
                    .join(", ")}
                  {/* </Paper> */}
                </Grid>
              </Grid>
            )}

          {(isExplPlanError || isRosterError) && (
            <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />
          )}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            open={
              isExplPlanLoading ||
              isRosterLoading ||
              isExplPlanFetching ||
              isRosterFetching
            }
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ height: "100%" }}>
            {report_rows && !isExplPlanError && !isRosterError && (
              <StickyHeadTable rows={report_rows} />
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
}
