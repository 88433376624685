import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import type { ElectroRosterVehicle } from "../pages/roster/types/ElectroRoster";
import { RosterVehicle } from "../pages/roster/types/Roster";

const FAKE_ELECTRO_VEHICLE: ElectroRosterVehicle = {
  vehicle_id: -1,
  vehicletype_id: null,
  vehicletype_name: null,
  includeEnergy: null,
  vehicle: {
    vehicle_id: -1,
    vehicletype_id: null,
    periodic_tech_test: null,
    days: {},
  },
  composition: null,
  places: 0,
  regularDrivers: [],
};

const FAKE_VEHICLE: RosterVehicle = {
  ...FAKE_ELECTRO_VEHICLE,
  shifts: [[], []],
};

function getVehicleLabel<T extends RosterVehicle | ElectroRosterVehicle>(
  vehicle?: T | null
): string {
  if (!vehicle || vehicle.vehicle_id < 0) {
    return "Без композиция";
  } else {
    return vehicle.vehicle_id.toFixed(0);
  }
}

export function VehicleCompositionDialog<
  T extends RosterVehicle | ElectroRosterVehicle
>({
  open,
  currentVehicle,
  vehicleList,
  onClose,
  onSave,
}: {
  open?: boolean;
  currentVehicle: T | null;
  vehicleList: readonly T[];
  onClose?: () => void;
  onSave?: (secondVehicle: T | null) => Promise<void>;
}) {
  const [value, setValue] = useState<T>(FAKE_VEHICLE as any);
  const [errors, setErrors] = useState<string[]>([]);
  const [busy, setBusy] = useState<boolean>(false);

  useEffect(() => {
    const otherVehicle =
      currentVehicle?.composition?.is_master === true
        ? vehicleList.find(
            (v) => v.vehicle_id === currentVehicle.composition?.other_veh
          ) || FAKE_VEHICLE
        : FAKE_VEHICLE;
    setValue(otherVehicle as any);
  }, [
    currentVehicle?.composition?.is_master,
    currentVehicle?.composition?.other_veh,
    vehicleList,
  ]);

  const handleClose = useCallback(() => {
    if (!busy) {
      setErrors([]);
      setBusy(false);
      onClose && onClose();
      setValue(FAKE_VEHICLE as any);
    }
  }, [busy, onClose]);

  const handleSave = useCallback(async () => {
    if (!busy && onSave) {
      try {
        setErrors([]);
        setBusy(true);
        await onSave(value && value.vehicle_id >= 0 ? value : null);
        handleClose();
      } catch (e: any) {
        console.warn("Грешка при запис на композиция:", e.data?.data);
        if (
          e.data?.data?.message &&
          typeof e.data.data.message === "string" &&
          e.data.data.message !== ""
        ) {
          setErrors([e.data.data.message]);
        } else if (
          e.data?.data?.message &&
          typeof e.data.data.message?.join === "function" &&
          e.data.data.message !== ""
        ) {
          setErrors(e.data.data.message);
        } else {
          setErrors(["Неизвестна грешка при запис на композиция"]);
        }
      } finally {
        setBusy(false);
      }
    } else {
      handleClose();
    }
  }, [busy, onSave, value, handleClose]);

  return (
    <form>
      <Dialog open={!!open} onClose={handleClose} fullWidth>
        <DialogTitle>Промяна на композиция</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Композиране на превозно средство{" "}
            <strong>{currentVehicle?.vehicle_id || "?"}</strong> с:
          </DialogContentText>

          <Autocomplete
            options={[FAKE_VEHICLE as any].concat(
              vehicleList.filter(
                (v) => v.vehicle_id !== currentVehicle?.vehicle_id
              )
            )}
            renderInput={(params) => (
              <TextField {...params} label="Ремарке" variant="standard" />
            )}
            value={value}
            onChange={(_, data) => {
              setValue(data || FAKE_VEHICLE);
              return data;
            }}
            noOptionsText="Без композиция"
            getOptionLabel={getVehicleLabel}
            isOptionEqualToValue={(option, value) =>
              option.vehicle_id === value.vehicle_id
            }
            autoSelect
          />

          {errors && errors.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <DialogContentText color="error">
                Грешка при запис на композиция:
              </DialogContentText>
              {errors.map((error, idx) => (
                <DialogContentText key={idx} color="error">
                  {error}
                </DialogContentText>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={busy}>
            Отказ
          </Button>
          <Button onClick={handleSave} type="submit" disabled={busy}>
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
