import { Portal } from "@mui/material";
import { useSubmenu } from "../../hooks/useSubmenu";
import { SubOutlet } from "../SubOutlet";

export function HomePage() {
  const { submenu } = useSubmenu();

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Portal container={submenu} />
      <SubOutlet />
    </div>
  );
}
