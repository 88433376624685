import { Driver, DriverSkills, Transport } from "../../data/api/types/driver";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

import { GridColDef } from "@mui/x-data-grid";
import { usePutDriverCorrectionMutation } from "../../data/api/hooks";
import { useProfile } from "../../common/useProfile";
import moment from "moment";

export type DriverFilter = {
  driver_id: string | null;
  //avtokolona: number | null;
  name: string | null;
  vehicle: string | null;
  shift: string | null;
  work_by_schedule: string | null;
  skills: string | null;
  color_id: string | null;
  license_expire: string | null;
  comptenence_expire: string | null;
  psiho_test_expire: string | null;
  category_desc: string | null;
  no_overtime: string | null;
  quota_override: string | null; //норма за тези назначени по членовете
  rest_on_weekends: string | null; // дали почива само в събота и неделя
};

export type Data = {
  driver_id: number;
  avtokolona: number | null;
  name: string;
  vehicle: string | undefined;
  shift: number | null;
  work_by_schedule: boolean;
  skills: number[];
  driver: Driver;
  color_id: number;
  license_expire: string | null;
  comptenence_expire: string | null;
  psiho_test_expire: string | null;
  category_desc: string | null;
  category_id: number | null;
  no_overtime: boolean | null;
  quota_override: string | null;
  rest_on_weekends: boolean | null;
};

export function createData(
  driver_id: number,
  avtokolona: number | null,
  name: string,
  vehicle: string | undefined,
  shift: number | null,
  work_by_schedule: boolean,
  skills: number[],
  driver: Driver,
  color_id: number,
  license_expire: string | null,
  comptenence_expire: string | null,
  psiho_test_expire: string | null,
  no_overtime: boolean | null,
  quota_override: string | null,
  rest_on_weekends: boolean | null,
  category_desc: string | null,
  category_id: number | null
): Data {
  return {
    driver_id,
    avtokolona,
    name,
    vehicle,
    shift,
    work_by_schedule,
    skills,
    driver,
    color_id,
    license_expire: license_expire
      ? moment(license_expire).format("DD.MM.YYYY")
      : "",
    comptenence_expire: comptenence_expire
      ? moment(comptenence_expire).format("DD.MM.YYYY")
      : "",
    psiho_test_expire: psiho_test_expire
      ? moment(psiho_test_expire).format("DD.MM.YYYY")
      : "",
    no_overtime,
    quota_override,
    rest_on_weekends,
    category_desc,
    category_id,
  };
}

export interface Column {
  id:
    | "driver_id"
    | "name"
    | "avtokolona"
    | "skills"
    | "vehicle"
    | "shift"
    | "work_by_schedule"
    | "color_id"
    | "license_expire"
    | "comptenence_expire"
    | "psiho_test_expire"
    | "category_desc"
    | "no_overtime"
    | "quota_override"
    | "rest_on_weekends";

  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  readonly?: boolean;
}

export const columns: readonly Column[] = [
  {
    id: "driver_id",
    label: "Номер",
    align: "center",
    minWidth: 90,
    readonly: true,
  },
  {
    id: "avtokolona",
    label: "Авток.",
    align: "center",
    minWidth: 80,
    readonly: true,
  },
  { id: "name", label: "Име", minWidth: 300, align: "left", readonly: true },
  {
    id: "vehicle",
    label: "Превозно средство",
    minWidth: 125,
    align: "center",
    readonly: false,
  },
  {
    id: "color_id",
    label: "Цвят",
    minWidth: 100,
    align: "center",
    readonly: false,
  },
  {
    id: "shift",
    label: "Смяна",
    minWidth: 120,
    align: "center",
    readonly: false,
  },
  {
    id: "work_by_schedule",
    label: "По график",
    minWidth: 100,
    align: "center",
    readonly: false,
  },
  {
    id: "category_desc",
    label: "Категория",
    minWidth: 180,
    align: "left",
    readonly: true,
  },
  {
    id: "no_overtime",
    label: "Полага ИТ",
    minWidth: 80,
    align: "center",
    readonly: false,
  },
  {
    id: "quota_override",
    label: "Норма",
    minWidth: 80,
    align: "right",
    readonly: false,
  },
  {
    id: "rest_on_weekends",
    label: "Почива само събота и неделя",
    minWidth: 80,
    align: "right",
    readonly: false,
  },

  {
    id: "skills",
    label: "Способности",
    minWidth: 300,
    align: "left",
    readonly: false,
  },
  {
    id: "license_expire",
    label: "Шофьорска книжка",
    minWidth: 150,
    align: "left",
    readonly: false,
  },
  {
    id: "comptenence_expire",
    label: "Професионална квалификация",
    minWidth: 150,
    align: "left",
    readonly: false,
  },
  {
    id: "psiho_test_expire",
    label: "Психо тест",
    minWidth: 150,
    align: "left",
    readonly: false,
  },
];

export const grid_columns: GridColDef[] = [
  {
    field: "driver_id",
    headerName: "Номер",
    minWidth: 80,
    align: "center",
    type: "number",
  },
  {
    field: "avtocolona",
    headerName: "Авток.",
    minWidth: 80,
    align: "center",
    type: "number",
  },
  { field: "name", headerName: "Име", minWidth: 300, align: "left" },
  {
    field: "vehicle",
    headerName: "Превозно средство",
    minWidth: 100,
    align: "center",
    type: "autocomplete",
    editable: true,
  },
  {
    field: "color_id",
    headerName: "Цвят",
    minWidth: 100,
    align: "center",
    type: "singleSelect",
    editable: true,
  },
  {
    field: "shift",
    headerName: "Смяна",
    minWidth: 50,
    align: "center",
    type: "singleSelect",
    editable: true,
  },
  {
    field: "work_by_schedule",
    headerName: "По график",
    minWidth: 80,
    align: "center",
    editable: true,
    type: "checkbox",
  },
  {
    field: "skills",
    headerName: "Способности",
    minWidth: 150,
    align: "left",
    type: "multipleSelect",
    editable: true,
  },
  {
    field: "license_expire",
    headerName: "Шофьорска книжка",
    minWidth: 150,
    align: "left",
    type: "date",
    editable: true,
  },
  {
    field: "comptenence_expire",
    headerName: "Професионална квалификация",
    minWidth: 150,
    align: "left",
    type: "date",
    editable: true,
  },
  {
    field: "psiho_test_expire",
    headerName: "Психо тест",
    minWidth: 150,
    align: "left",
    type: "date",
    editable: true,
  },
];

export const shiftList = [
  { id: 0, label: "  " },
  { id: 1, label: "1-ва" },
  { id: 2, label: "2-ра" },
];

export const WorkBySheduleList = [
  { id: 0, label: "  " },
  { id: 1, label: "да" },
  { id: 2, label: "не" },
];

export function useDriversList(
  data: Data[],
  transport: Transport | "",
  garage: number,
  skills: DriverSkills | undefined,
  isDriversLoading: boolean,
  isDriversError: boolean
) {
  const profile = useProfile();
  const isReadOnly =
    !profile?.roles?.includes("edit_schedule") &&
    !profile?.roles?.includes("edit_drivers_licenses");

  const [updateDriver, resultUpdateDriver] = usePutDriverCorrectionMutation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      drivers: data,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    // console.log("useEffect Data", data);
    reset({
      drivers: data,
    });
  }, [data, reset]);

  const { fields } = useFieldArray({
    name: "drivers",
    control,
  });

  const onSubmit = useCallback<SubmitHandler<{ drivers: Data[] }>>(
    (data) => {
      // console.log("Submit isDirty: ", isDirty);
      // console.log("Submit From submit dirtyFields: ", dirtyFields);
      //let isError = false;

      if (!isReadOnly && isDirty) {
        if (dirtyFields.drivers) {
          for (const index of Object.keys(dirtyFields.drivers)) {
            const driver = data.drivers[index as unknown as number];
            const veh_skills: DriverSkills = [];

            if (skills && driver.skills)
              driver.skills.forEach((element) => {
                const sk = skills.find((ss) => ss.id === element);
                if (sk) {
                  veh_skills.push(sk);
                }
              });
            updateDriver({
              transport: transport,
              gps_depot_id: garage,
              driver_id: driver.driver_id,
              work_by_schedule: driver.work_by_schedule,
              preferred_vehicle: driver.vehicle,
              preferred_workshift: driver.shift,
              vehicletypes_skills: veh_skills,
              color_id: driver.color_id || 0,
              license_expire: driver.license_expire
                ? moment(driver.license_expire, "DD.MM.YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              comptenence_expire: driver.comptenence_expire
                ? moment(driver.comptenence_expire, "DD.MM.YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              psiho_test_expire: driver.psiho_test_expire
                ? moment(driver.psiho_test_expire, "DD.MM.YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              no_overtime: driver.no_overtime ? driver.no_overtime : false,
              quota_override: !isNaN(Number(driver.quota_override))
                ? driver.quota_override
                : null,
              rest_on_weekends: driver.rest_on_weekends
                ? driver.rest_on_weekends
                : false,
            }).then((res) => {
              if ("error" in res && res.error) {
                // <QueryError error={res.error} />;
                // console.log("UpdateDriverError:", res.error);
                //isError = true;
              } else {
                // console.log("UpdateDriver OK:");
              }
            });
            // console.log(data.drivers[index as unknown as number]);
          }
        }
      }
    },
    [isDirty, dirtyFields, isReadOnly, skills, updateDriver, transport, garage]
  );

  const submit = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  const hasError = resultUpdateDriver.isError || isDriversError;
  const loading = resultUpdateDriver.isLoading || isDriversLoading;

  return {
    register,
    control,
    fields,
    handleSubmit: submit,
    isDirty,
    dirtyFields,
    hasError,
    loading,
  };
}
