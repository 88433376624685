import { Paper } from "@mui/material";
import { Moment } from "moment";
import { DayTaskStates } from "../schedule_recapitulation/useScheduleRecapitulation";

export function ReportDriversContent({
  rows,
  daytask_states,
  selectedDate,
  totalDriverCount,
}: {
  rows: { [key: string]: number[] };
  daytask_states: DayTaskStates[];
  selectedDate: Moment;
  totalDriverCount: number;
}) {
  return (
    <>
      <Paper
        sx={{ height: "auto", px: 20, py: 6, my: 2 }}
        style={{ paddingBottom: 6, paddingTop: 6 }}
      >
        <h2 style={{ padding: "10" }}>
          {`Разпределение на водачи  - общо ${totalDriverCount} бр.`}
        </h2>
        {Object.keys(rows)
          .filter((s) => s !== "21" && s !== "22")
          .map((r) => (
            <div key={r}>
              <h3>
                {parseInt(r, 10) >= 0
                  ? daytask_states.find((s) => s.state_id === parseInt(r, 10))
                      ?.description
                  : parseInt(r, 10) === -1
                  ? "Водачи без назначена задача"
                  : ""}{" "}
                - {rows[r].length} бр.
              </h3>

              <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                {rows[r].join(", ")}
              </p>
            </div>
          ))}
      </Paper>
    </>
  );
}
