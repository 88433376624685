import { Box, BoxProps } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";

export const DriverItemContainer = forwardRef(
  (
    {
      children,
      driverColor,
      hasErrors,
      sx,
      style,
      ...props
    }: PropsWithChildren<
      {
        driverColor?: "red" | "green" | null;
        hasErrors?: boolean;
      } & BoxProps
    >,
    ref: ForwardedRef<HTMLElement>
  ) => {
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0.25,
          border: `1px solid ${hasErrors ? red[900] : grey[900]}`,
          borderRadius: 0.75,
          backgroundColor:
            driverColor === "red"
              ? red[200]
              : driverColor === "green"
              ? green[200]
              : grey[200],
          cursor: "pointer",
          //height: "100%",
          minHeight: 35,
          transform: "translate3d(0, 0, 0)",
          ...sx,
        }}
        style={style}
        {...props}
      >
        {children}
      </Box>
    );
  }
);
