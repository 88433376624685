import moment from "moment";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { indexFromVehicleParameters } from "../api/types/schedule";
import { updateSelectedMonth } from "../filtersSlice";
import {
  vehiclesChannelJoined,
  vehiclesUpdateReceived,
  setVehiclesChannelCallbacks,
  setVehiclesChannelId,
  setVehiclesScheduleConnected,
} from "../scheduleSlice";
import { RootState, useAppDispatch } from "../store";
import { createVehiclesChannelCallbacks } from "./socketFunctions";

export function useVehiclesConnection() {
  const profile = useProfile();
  const editRosterDisabled = !profile?.roles?.includes("edit_roster");
  const editRosterVehiclesEnabled =
    editRosterDisabled && !!profile?.roles?.includes("edit_roster_vehicles");

  const dispatch = useAppDispatch();
  const { scheduleParameters: parameters } = useScheduleParameters();
  const connectedChannelId = useSelector(
    (state: RootState) => state.schedule.vehiclesChannelId
  );

  const [selectedDate, selectedMonth] = useSelector((state: RootState) => [
    state.filters.selectedDate,
    state.filters.selectedMonth,
  ]);

  useEffect(() => {
    const d = moment(selectedDate, "YYYY-MM-DD");
    const m = moment(selectedMonth, "YYYY-MM");
    if (d.isValid() && m.isValid()) {
      const me = m.clone().endOf("month");
      if (d.isBefore(m) || d.isAfter(me)) {
        dispatch(updateSelectedMonth(d.format("YYYY-MM")));
      }
    }
  }, [dispatch, selectedDate, selectedMonth]);

  const onJoinMessage = useCallback(
    (msg: any) => {
      dispatch(setVehiclesScheduleConnected(true));
      dispatch(vehiclesChannelJoined({ msg, parameters }));
    },
    [dispatch, parameters]
  );

  const onUpdateMessage = useCallback(
    (msg: any) => {
      dispatch(vehiclesUpdateReceived(msg));
    },
    [dispatch]
  );

  useEffect(() => {
    const channelId = parameters
      ? indexFromVehicleParameters(parameters)
      : null;

    if (channelId) {
      if (connectedChannelId && connectedChannelId !== channelId) {
        console.debug(
          "Change parameters, release old channel:",
          connectedChannelId,
          channelId
        );

        window.globals.nariadSocket.releaseChannel(connectedChannelId);
      }

      console.debug("Connecting new channel:", channelId);
      window.globals.nariadSocket
        .getChannel(channelId, onJoinMessage, onUpdateMessage, () => {
          // on join error
          dispatch(setVehiclesScheduleConnected(false));
          dispatch(setVehiclesChannelId(channelId));
        })
        .then((ref) => {
          dispatch(setVehiclesChannelId(ref.channelId));
          dispatch(
            setVehiclesChannelCallbacks(
              createVehiclesChannelCallbacks(
                ref.channel,
                editRosterDisabled,
                editRosterVehiclesEnabled
              )
            )
          );
        });
    }
  }, [
    parameters,
    onJoinMessage,
    onUpdateMessage,
    connectedChannelId,
    dispatch,
    editRosterDisabled,
    editRosterVehiclesEnabled,
  ]);
}
