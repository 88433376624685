import { Outlet, OutletProps, useOutletContext } from "react-router";

export function SubOutlet({ context, ...props }: OutletProps) {
  const parentContext = useOutletContext();

  if (typeof context === "object" && typeof parentContext === "object") {
    return <Outlet context={{ ...parentContext, ...context }} {...props} />;
  } else if (context !== undefined) {
    return <Outlet context={context} {...props} />;
  } else {
    return <Outlet context={parentContext} {...props} />;
  }
}
