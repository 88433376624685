// import { useTheme } from "@mui/material";
// import color from "color";
// import { useMemo } from "react";

export type BaseCellMarkerProps = {
  cellIndex: number;
  cellCount: number;
  rowIndex: number;
  isFirst?: boolean;
  isLast?: boolean;
  insideMarker?: boolean;
  zIndexOffset?: number;
  hidden?: boolean;
};

export function CellMarker({
  cellIndex,
  cellCount,
  rowIndex,
  isFirst,
  isLast,
  insideMarker,
  zIndexOffset = 0,
  borderWidth = 3,
  borderColor = "rgba(0, 0, 0, 0.75)",
  backgroundColor = "rgba(0, 0, 0, 0.25)",
  hidden,
}: BaseCellMarkerProps & {
  borderWidth: number;
  borderColor: string;
  backgroundColor: string;
}) {
  const marginTop = insideMarker
    ? -borderWidth / 3
    : rowIndex === 0
    ? undefined
    : -borderWidth;
  const marginRight = insideMarker
    ? -borderWidth / 3
    : cellIndex === cellCount - 1
    ? undefined
    : isLast
    ? -borderWidth
    : undefined;
  const marginBottom = insideMarker ? -borderWidth / 3 : -borderWidth;
  const marginLeft = insideMarker
    ? -borderWidth / 3
    : cellIndex === 0
    ? undefined
    : isFirst
    ? -borderWidth
    : undefined;

  return (
    <div
      style={{
        display: hidden ? "none" : undefined,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        //...margins,
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        zIndex: 1 + zIndexOffset,

        borderTop: `${borderWidth}px solid ${borderColor}`,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        borderLeft: isFirst
          ? `${borderWidth}px solid ${borderColor}`
          : undefined,
        borderRight: isLast
          ? `${borderWidth}px solid ${borderColor}`
          : undefined,
        borderTopLeftRadius: isFirst ? 2 * borderWidth : 0,
        borderBottomLeftRadius: isFirst ? 2 * borderWidth : 0,
        borderTopRightRadius: isLast ? 2 * borderWidth : 0,
        borderBottomRightRadius: isLast ? 2 * borderWidth : 0,

        backgroundColor: backgroundColor,
      }}
    />
  );
}

export function SelectedCellMarker(props: BaseCellMarkerProps) {
  // const theme = useTheme();
  // const colors = useMemo(
  //   () => ({
  //     borderColor: color(theme.palette.primary.dark).alpha(0.75).toString(),
  //     backgroundColor: color(theme.palette.primary.dark).alpha(0.25).toString(),
  //   }),
  //   [theme.palette.primary.dark]
  // );
  const borderColor = "rgba(25, 118, 210, 0.75)";
  const backgroundColor = "rgba(25, 118, 210, 0.25)";

  return (
    <CellMarker
      borderWidth={3}
      {...props}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    />
  );
}

export function ErrorCellMarker({
  isWarning,
  ...props
}: BaseCellMarkerProps & { isWarning?: boolean }) {
  // const theme = useTheme();
  // const colors = useMemo(() => {
  //   const baseColor = isWarning
  //     ? theme.palette.warning.light
  //     : theme.palette.error.light;
  //   return {
  //     borderColor: color(baseColor).alpha(0.5).toString(),
  //     backgroundColor: color(baseColor).alpha(0.2).toString(),
  //   };
  // }, [isWarning, theme.palette.error.light, theme.palette.warning.light]);

  //const borderColor = isWarning
  //  ? "rgba(255, 152, 0, 0.75)"
  //  : "rgba(239, 83, 80, 0.5)";
  //const backgroundColor = isWarning
  //  ? "rgba(255, 152, 0, 0.25)"
  //  : "rgba(239, 83, 80, 0.2)";
  const borderColor = isWarning
    ? "rgba(61, 170, 44, 0.5)"
    : "rgba(239, 83, 80, 0.5)";
  const backgroundColor = isWarning
    ? "rgba(239, 83, 80, 0.2)"
    : "rgba(239, 83, 80, 0.2)";

  return (
    <CellMarker
      borderWidth={3}
      {...props}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    />
  );
}
