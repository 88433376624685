import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableContainer,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { UseDriverFilterResult } from "../../components/schedule/useDriverFilter";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { useGetRecommendationsQuery } from "../../data/api/hooks";
import { RecommendationResponse } from "../../data/api/recommendationsApi";
import { Schedule } from "../../data/api/types/schedule";
import { RestRecommendationTable } from "./RestRecommendationTable";

export function RestRecommendationDialog({
  schedule,
  driverFilter: { setDriverFilterValue },
  open,
  onClose,
}: {
  schedule: Schedule;
  driverFilter: UseDriverFilterResult;
  open: boolean;
  onClose: (
    results:
      | (RecommendationResponse & {
          results_for: "work" | "rest";
          date: string;
        })
      | null
  ) => void;
}) {
  const { scheduleParameters } = useScheduleParameters();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [suggestionFor, setSuggestionFor] = useState<"work" | "rest">("work");

  const recommendations = useGetRecommendationsQuery(
    {
      schedule_parameters: scheduleParameters,
      date: selectedDate?.format("YYYY-MM-DD") || "",
      suggestion_type:
        suggestionFor === "rest" ? "suggestion_rest" : "suggestion_work",
    },
    {
      skip:
        (suggestionFor !== "rest" && suggestionFor !== "work") ||
        !selectedDate ||
        !selectedDate.isValid() ||
        !scheduleParameters,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (open === true && (!selectedDate || !selectedDate.isValid())) {
      const scheduleMonth = moment(
        `${schedule.db_schedule.year}-${schedule.db_schedule.month}-01`,
        "YYYY-MM-DD"
      );
      const today = moment().startOf("day").add(1, "day");

      if (today.isBefore(scheduleMonth.startOf("month"))) {
        setSelectedDate(scheduleMonth.startOf("month"));
      } else if (today.isAfter(scheduleMonth.endOf("month"))) {
        setSelectedDate(scheduleMonth.endOf("month"));
      } else {
        setSelectedDate(today);
      }
    }
  }, [
    open,
    schedule.db_schedule.month,
    schedule.db_schedule.year,
    selectedDate,
  ]);

  return (
    <Dialog
      onClose={() => {
        if (!recommendations.isLoading && !recommendations.isFetching) {
          onClose(
            recommendations.isSuccess && selectedDate && selectedDate.isValid()
              ? {
                  ...recommendations.data,
                  results_for: suggestionFor,
                  date: selectedDate.format("YYYY-MM-DD"),
                }
              : null
          );
        }
      }}
      open={open}
      fullWidth
    >
      <DialogTitle>Предложение за водачи</DialogTitle>
      <DialogContent>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={recommendations.isLoading || recommendations.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <DatePicker
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e || moment());
            }}
            label="За дата"
            views={["year", "month", "day"]}
            openTo="day"
            slotProps={{
              textField: {
                variant: "standard",
                //sx: { width: "100%" },
              },
            }}
          />
          <FormControl fullWidth variant="standard" sx={{ ml: 3 }}>
            <InputLabel id="demo-simple-select-label">
              Предложение за
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={suggestionFor}
              label="Предложение за"
              onChange={(e) => {
                setSuggestionFor(e.target.value === "rest" ? "rest" : "work");
              }}
            >
              <MenuItem value="work">Връщане на работа</MenuItem>
              <MenuItem value="rest">Почивка</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            mt: 6,
            minHeight: 370,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {recommendations.isSuccess &&
          selectedDate &&
          selectedDate.isValid() ? (
            <TableContainer component={Paper}>
              <RestRecommendationTable
                date={selectedDate}
                results={recommendations.data}
                resultsForWork={suggestionFor === "work"}
                variant="normal"
                setDriverFilterValue={setDriverFilterValue}
              />
            </TableContainer>
          ) : recommendations.isError ? (
            <Box>
              <Alert severity="error">
                <AlertTitle>Грешка</AlertTitle>
                Грешка при изчисляване на предложения за водач
              </Alert>
            </Box>
          ) : (
            <Box>
              <Alert severity="info">Изберете дата и вид предложение</Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(
              recommendations.isSuccess &&
                selectedDate &&
                selectedDate.isValid()
                ? {
                    ...recommendations.data,
                    results_for: suggestionFor,
                    date: selectedDate.format("YYYY-MM-DD"),
                  }
                : null
            );
          }}
          disabled={recommendations.isLoading || recommendations.isFetching}
        >
          Затвори
        </Button>
        <Button
          onClick={() => recommendations.refetch()}
          disabled={recommendations.isLoading || recommendations.isFetching}
        >
          Намери предложения
        </Button>
      </DialogActions>
    </Dialog>
  );
}
