import {
  Grid,
  Backdrop,
  CircularProgress,
  Portal,
  IconButton,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../data/store";
import { AutoColumnSelect } from "../../../filters/AutoColumnSelect";
import { DepotSelect } from "../../../filters/DepotSelect";
import { TransportSelect } from "../../../filters/TransportSelect";
import { QueryError } from "../../QueryError";
import React, { useEffect, useMemo, useState } from "react";
import { ReportDriversContent } from "./ReportDriversContent";
import { StickyHeadTable } from "./StickyHeadTable";
import { useReportData } from "./useReportData";
import { useTableRows } from "./useTableRows";
import { Print, Upgrade } from "@mui/icons-material";
import { usePrintRosterReport } from "../../../printing/usePrintRosterReport";
import { useSubmenu } from "../../../hooks/useSubmenu";
import { ReportVehiclesContent } from "./ReportVehiclesContent";
import { ReportRecapitulationContent } from "./ReportRecapitulationContent";
import { updateSelectedDate } from "../../../data/filtersSlice";
import { useExportGPS } from "./useExportGPS";
import { ApprovalSubmenu } from "../../roster/RosterTopMenu";
import { useScheduleParameters } from "../../../components/schedule/useScheduleParameters";
import { useProfile } from "../../../common/useProfile";
import { useGetRosterQuery } from "../../../data/api/hooks";
import { usePrintElectroRosterReport } from "../../../printing/usePrintElectroRosterReport";

export function RosterDayReport() {
  const { rightToolbarMenu } = useSubmenu();
  const profile = useProfile();

  const [needsTransportSelect, needsDepotSelect, needsAutocolumnSelect] =
    useSelector((state: RootState) => [
      state.filters.needsTransportSelect,
      state.filters.needsDepotSelect,
      state.filters.needsAutocolumnSelect,
    ]);

  //  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const { scheduleParameters } = useScheduleParameters();

  const dispatch = useAppDispatch();

  const {
    explPlanData,
    recapData,
    recapRows,
    totalDriverCount,
    isError,
    isLoading,
    VehicleRows,
    totalVehicleCount,
    VehicleStates,
    day_roster,
    day_workshift_ids,
  } = useReportData(selectedDate);

  const rows = useTableRows(
    selectedDate,
    explPlanData,
    VehicleRows,
    VehicleStates?.data?.data,
    day_roster,
    day_workshift_ids
  );
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const { exportPdf, isLoading: exportPdfLoading } = usePrintRosterReport();
  const { exportPdf: exportElectroPdf, isLoading: exportElectroPdfLoading } =
    usePrintElectroRosterReport();
  const { exportGPS, exportGPSStatus } = useExportGPS(selectedDate);

  const [today, setToday] = useState(moment().startOf("day"));
  useEffect(() => {
    const timer = setInterval(() => setToday(moment().startOf("day")), 30000);
    return () => clearInterval(timer);
  }, []);
  const isOldDate = useMemo(
    () => today.isSameOrAfter(moment(selectedDate, "YYYY-MM-DD")),
    [selectedDate, today]
  );

  const roster = useGetRosterQuery(
    {
      transport: scheduleParameters?.[0] || "A",
      depot_id: scheduleParameters?.[1] || 0,
      autocolumn: scheduleParameters?.[4] || 1,
      date: selectedDate,
    },
    {
      skip: !scheduleParameters,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: 5000,
    }
  );

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <ApprovalSubmenu
          scheduleParameters={scheduleParameters}
          selectedDate={selectedDate}
          hasErrors={false}
        />

        {profile?.roles?.includes("export_roster") ? (
          <IconButton
            color="inherit"
            size="large"
            aria-label="Експорт в GPS"
            title="Експорт в GPS"
            disabled={
              isOldDate ||
              isLoading ||
              isError ||
              exportPdfLoading ||
              exportElectroPdfLoading ||
              !recapRows ||
              !recapData ||
              !roster.currentData ||
              roster.currentData.data.actions.length === 0
            }
            onClick={() => {
              if (
                !isOldDate &&
                recapRows &&
                recapData &&
                VehicleRows &&
                VehicleStates?.data?.data
              ) {
                exportGPS(
                  {
                    rows: rows.arr.filter((r) => r.show),
                    recapRows: recapRows,
                    daytask_states: recapData.data.daytask_states,
                  }
                  // moment(selectedDate),
                  // totalDriverCount,
                  // VehicleRows,
                  // VehicleStates?.data?.data
                );
              }
            }}
          >
            {exportGPSStatus.isLoading ? (
              <CircularProgress
                size="0.75em"
                sx={{ color: (theme) => theme.palette.grey[100] }}
              />
            ) : (
              <Upgrade />
            )}
          </IconButton>
        ) : (
          <></>
        )}

        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={
            isLoading ||
            isError ||
            exportPdfLoading ||
            exportElectroPdfLoading ||
            !recapRows ||
            !recapData
          }
          onClick={() => {
            if (
              recapRows &&
              recapData &&
              VehicleRows &&
              VehicleStates?.data?.data
            ) {
              selectedTransport === "A"
                ? exportPdf(
                    recapRows,
                    recapData.data.daytask_states,
                    moment(selectedDate),
                    VehicleRows,
                    VehicleStates?.data?.data
                  )
                : exportElectroPdf(
                    recapRows,
                    recapData.data.daytask_states,
                    moment(selectedDate),
                    VehicleRows,
                    VehicleStates?.data?.data
                  );
            }
          }}
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} sm={2}>
              <DatePicker
                value={moment(selectedDate)}
                onChange={(e) =>
                  e &&
                  e.isValid() &&
                  dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
                }
                label="Дата"
                views={["year", "month", "day"]}
                openTo="day"
                slotProps={{
                  textField: {
                    variant: "standard",
                    sx: { m: 1, width: "100%" },
                  },
                }}
              />
            </Grid>
          </Grid>

          {(isError || exportGPSStatus.isError) && (
            <QueryError
              sx={{ mt: 2, mb: 3, mx: 1 }}
              error={exportGPSStatus.error}
            />
          )}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ py: 4 }}>
            {rows?.arr &&
              !isError &&
              explPlanData?.expl_plan &&
              recapRows &&
              recapData?.data.daytask_states &&
              VehicleRows &&
              VehicleStates?.data && (
                <React.Fragment>
                  <StickyHeadTable
                    rows={rows.arr.filter((r) => r.show && r.driver2 >= -1)}
                    selectedDate={moment(selectedDate)}
                    totalDriverCount={totalDriverCount}
                    selectedTransport={selectedTransport}
                  />
                  <ReportDriversContent
                    rows={recapRows}
                    daytask_states={recapData.data.daytask_states}
                    selectedDate={moment(selectedDate)}
                    totalDriverCount={totalDriverCount}
                  />

                  <ReportVehiclesContent
                    rows={VehicleRows}
                    vehicles_state={VehicleStates.data.data}
                    selectedDate={moment(selectedDate)}
                    totalVehicleCount={totalVehicleCount}
                  />
                  <ReportRecapitulationContent
                    driver_rows={recapRows}
                    selectedDate={moment(selectedDate)}
                    totalDriverCount={totalDriverCount}
                    vehicle_rows={VehicleRows}
                    daytask_states={recapData.data.daytask_states}
                    totalVehicleCount={totalVehicleCount}
                    vehicles_state={VehicleStates.data.data}
                    totalShiftWithoutDriverCount={rows.shiftWithoutDriverCnts}
                  />
                </React.Fragment>
              )}
            {/* {!isError && (
              <div>
                <Alert severity="info"> Няма данни за избраната дата!</Alert>
              </div>
            )} */}
          </Container>
        </Box>
      </Box>
    </>
  );
}
