import { Alert, useTheme } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { useCallback, useEffect, useMemo, useState } from "react";
import type {
  DaySummary,
  DriverSummary,
  TableColumnRef,
  TableRowRef,
} from "./types";
import { CELL_HEIGHT } from "./TableElements";
import { RowRenderer } from "./RowRenderer";
import { UseHeaderScrollerResult } from "./useHeaderScroller";
import { UseDriverFilterResult } from "./useDriverFilter";
import { VirtualizedList, VListData } from "./VirtualizedList";
import { useSelectStateMenu } from "./useSelectStateMenu";
import { useCellSelection } from "./useCellSelection";
import { SelectStateMenu } from "./SelectStateMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import { useIsHoliday } from "./useIsHoliday";
import { UseGarageStatisticsResult } from "../../pages/schedule/useCalculateGarageStatistics";
import {
  EditDriverDayCommentFunc,
  UpdateDriverStateFunc,
} from "../../data/schedules/types";
import { useScheduleKeyboardEvents } from "./useScheduleKeyboardEvents";
import { CommentDialog } from "./CommentDialog";
import { DatesHeaderRow } from "./DatesHeaderRow";
import { FirstShiftHeaderRow } from "./FirstShiftHeaderRow";
import { SecondShiftHeaderRow } from "./SecondShiftHeaderRow";
import { RemainingRestsHeaderRow } from "./RemainingRestsHeaderRow";
import { RestsHeaderRow } from "./RestsHeaderRow";
import { LeavesHeaderRow } from "./LeavesHeaderRow";
import { Moment } from "moment";
import moment from "moment";
import { Schedule, Transport } from "../../data/api/types/schedule";
import {
  isAllowedInPreliminary,
  // isStoredInPreliminaryField,
} from "./stateFunctions";
import { ChangeDepotDialog } from "./ChangeDepotDialog";

function ErrorAlert({
  open,
  clearErrors,
}: {
  open?: boolean;
  clearErrors: () => void;
}) {
  if (open) {
    return (
      <Alert
        severity="error"
        closeText="Затвори"
        onClose={clearErrors}
        style={{ borderRadius: 0, alignItems: "center" }}
      >
        Грешка при записа на състояние на водач
      </Alert>
    );
  } else {
    return <></>;
  }
}

export function VirtualizedTable({
  // schedule,
  updateDriverState,
  columns,
  rows,
  daySummary,
  driverSummary,
  firstHeaderColumnWidth,
  headerColumnWidth,
  driverFilter,
  headerScroller,
  garageStatistics,
  editDriverDayComment,
  editDriverDepot,
}: {
  schedule: Schedule | undefined;
  updateDriverState: UpdateDriverStateFunc;
  columns: TableColumnRef[];
  rows: TableRowRef[];
  daySummary: DaySummary[];
  driverSummary: Record<string, DriverSummary>;
  firstHeaderColumnWidth: number;
  headerColumnWidth: number;
  driverFilter: UseDriverFilterResult;
  headerScroller: UseHeaderScrollerResult;
  garageStatistics: UseGarageStatisticsResult | null;
  editDriverDayComment: EditDriverDayCommentFunc;
  editDriverDepot: (data: {
    transport: Transport;
    depot_id: number;
    sl_number: number;
    autocolumn: number;
    new_depot_id: number;
    start_date: string;
    end_date: string | null;
  }) => Promise<{ status: "ok" }>;
}) {
  const theme = useTheme();

  //const isEditingDisabled = useMemo(
  //  () =>
  //    ["started", "start_request", "stop_request"].includes(
  //      schedule?.planner_status?.status || ""
  //    ),
  //  [schedule?.planner_status?.status]
  //);
  // TODO: Да се оправи откъм сървъра
  const isEditingDisabled = false;

  const {
    onSelectState,
    onUpdateComment,
    onUpdateDepot,
    onContextMenu,
    setContextMenu,
    contextMenu,
    changeDepotOnly,
    commentDialog,
    onCloseCommentDialog,
    comment,
    commentErrors,
    commentBusy,
    setComment,
    onChangeComment,
    depotDialog,
    onCloseDepotDialog,
    depot_id,
    depotErrors,
    depotBusy,
    setDepot,
    onChangeDepot,
    ...stateMenu
  } = useSelectStateMenu(
    updateDriverState,
    editDriverDayComment,
    editDriverDepot,
    isEditingDisabled
  );
  const { onSelectionStart, onSelectionDrag, onSelectionEnd, setSelection } =
    useCellSelection();

  const selectedMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );
  const isHoliday = useIsHoliday(selectedMonth);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [today, setToday] = useState<Moment>(moment().startOf("day"));
  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = moment().startOf("day");
      if (newDate.isAfter(today)) {
        setToday(newDate);
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [today]);

  const data = useMemo<VListData>(() => {
    return {
      cols: columns,
      rows,
      today,
      selectedDate,
      headerColumnWidth,
      firstHeaderColumnWidth,
      driverSummary,
      isHoliday,
      theme,
      onContextMenu,
      onSelectionStart,
      onSelectionDrag,
      onSelectionEnd,
      onSelectState,
      setSelection,
    };
  }, [
    columns,
    rows,
    today,
    selectedDate,
    headerColumnWidth,
    firstHeaderColumnWidth,
    driverSummary,
    isHoliday,
    theme,
    onContextMenu,
    onSelectionStart,
    onSelectionDrag,
    onSelectionEnd,
    onSelectState,
    setSelection,
  ]);

  // Keyboard shortcuts
  const selection = useSelector((state: RootState) => state.schedule.selection);
  const isPreliminary = useSelector(
    (state: RootState) =>
      state.schedule.schedule?.db_schedule?.preliminary_ready !== true
  );
  // const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateState = useCallback(
    async (stateId: number) => {
      if (
        selection &&
        rows &&
        isAllowedInPreliminary(isPreliminary, stateId, changeDepotOnly)
      ) {
        const row = rows[selection.row];

        if (row && row.rowType === "driver") {
          // setIsUpdating(true);
          setError(null);

          const selectedStartCell = Math.min(
            row.cells.length - 1,
            Math.max(0, Math.min(selection.startCell, selection.endCell))
          );
          const selectedEndCell = Math.min(
            row.cells.length - 1,
            Math.max(0, Math.max(selection.startCell, selection.endCell))
          );

          try {
            // if (stateId === 1) {
            //   await updateDriverState({
            //     driver_id: row.id,
            //     state_id: stateId,
            //     preliminary: true,
            //     start_date:
            //       row.cells[selectedStartCell].date.format("YYYY-MM-DD"),
            //     end_date: row.cells[selectedEndCell].date.format("YYYY-MM-DD"),
            //   });
            // }

            await updateDriverState({
              driver_id: row.id,
              state_id: stateId,
              preliminary: false,
              start_date:
                row.cells[selectedStartCell].date.format("YYYY-MM-DD"),
              end_date: row.cells[selectedEndCell].date.format("YYYY-MM-DD"),
            });

            console.log("Driver updated");
          } catch (e) {
            console.log("Driver update failed:", e);
            if (e instanceof Error) {
              setError(e);
            } else {
              setError(new Error(JSON.stringify(e)));
            }
          }
          // .finally(() => setIsUpdating(false));
        }
      }
    },
    [isPreliminary, rows, selection, updateDriverState, changeDepotOnly]
  );

  const focusSearchField = useCallback(() => {
    //console.log("Searching...", driverFilterField);
    driverFilter.setDriverFilterValue("");
    driverFilter.driverFilterField && driverFilter.driverFilterField.focus();
  }, [driverFilter]);

  useScheduleKeyboardEvents(
    !!commentDialog,
    updateState,
    focusSearchField,
    isEditingDisabled
  );

  return (
    <>
      <CommentDialog
        dialog={commentDialog}
        comment={comment}
        commentBusy={commentBusy}
        commentErrors={commentErrors}
        setComment={setComment}
        onChangeComment={onChangeComment}
        onCloseDialog={onCloseCommentDialog}
      />

      <ChangeDepotDialog
        dialog={depotDialog}
        depot_id={depot_id}
        changeDepotBusy={depotBusy}
        changeDepotErrors={depotErrors}
        setDepotId={setDepot}
        onChangeDepot={onChangeDepot}
        onCloseDialog={onCloseDepotDialog}
      />

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <ErrorAlert
            open={!!error || !!stateMenu.error}
            clearErrors={() => {
              setError(null);
              stateMenu.setError(null);
            }}
          />
          {/*
          TODO: Да се върне джуруляка
          <Progress open={isUpdating || stateMenu.isUpdating} />
          */}

          <div
            style={{
              flexGrow: 0,
              display: "flex",
              flexDirection: "column",
              overflowX: "hidden",
            }}
            ref={headerScroller.headerRef}
          >
            <DatesHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              garageStatistics={garageStatistics}
              driverFilter={driverFilter}
              columns={columns}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <FirstShiftHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              daySummary={daySummary}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
            />

            <SecondShiftHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              daySummary={daySummary}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
            />

            <RemainingRestsHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              daySummary={daySummary}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
            />

            <RestsHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              daySummary={daySummary}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
            />

            <LeavesHeaderRow
              firstHeaderColumnWidth={firstHeaderColumnWidth}
              headerColumnWidth={headerColumnWidth}
              headerScroller={headerScroller}
              daySummary={daySummary}
              isHoliday={isHoliday}
              today={today}
              selectedDate={selectedDate}
            />
          </div>

          <div style={{ flexGrow: 1 }}>
            <AutoSizer>
              {({ width, height }) => (
                <>
                  <VirtualizedList
                    width={width || 0}
                    height={height || 0}
                    overscanCount={10}
                    itemCount={rows.length + 30}
                    itemSize={CELL_HEIGHT}
                    itemKey={(index, data) =>
                      index >= 0 && index < data.rows.length
                        ? data.rows[index].id
                        : `tmp-${index}`
                    }
                    itemData={data}
                    innerRef={headerScroller.setScrollContainer}
                  >
                    {RowRenderer}
                  </VirtualizedList>
                </>
              )}
            </AutoSizer>
          </div>

          <SelectStateMenu
            contextMenu={contextMenu}
            changeDepotOnly={changeDepotOnly}
            setContextMenu={setContextMenu}
            onSelectState={onSelectState}
            onUpdateComment={onUpdateComment}
            onUpdateDepot={onUpdateDepot}
          />
        </>
      </div>
    </>
  );
}
