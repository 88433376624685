import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { uiApi } from "./api/hooks";
import settingsReducer from "./settingsSlice";
import scheduleReducer from "./scheduleSlice";
import authReducer from "./authSlice";
import socketsReducer from "./socketsSlice";
import filtersReducer from "./filtersSlice";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { recommendationsApi } from "./api/recommendationsApi";

const filtersPersistConfig = {
  key: "filter",
  storage: storage,
};
const persistedFilters = persistReducer(filtersPersistConfig, filtersReducer);

export const store = configureStore({
  devTools: import.meta.env.DEV,
  reducer: {
    [uiApi.reducerPath]: uiApi.reducer,
    [recommendationsApi.reducerPath]: recommendationsApi.reducer,
    settings: settingsReducer,
    schedule: scheduleReducer,
    auth: authReducer,
    sockets: socketsReducer,
    filters: persistedFilters,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [
      //     "persist/PERSIST",
      //     "sockets/connect/fulfilled",
      //     "schedule/setSchedulesChannelCallbacks",
      //     "schedule/setVehiclesChannelCallbacks",
      //   ],
      //   ignoredPaths: [
      //     "sockets.socket",
      //     "sockets.disconnectingSocket",
      //     "schedule.schedulesChannelCallbacks",
      //     "schedule.vehiclesChannelCallbacks",
      //   ],
      // },
    })
      .concat(uiApi.middleware)
      .concat(recommendationsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);
