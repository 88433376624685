import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Portal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../data/store";
import moment from "moment";
import React, { useMemo } from "react";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { QueryError } from "../QueryError";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import {} from "../../data/api/hooks";

import { updateSelectedDate } from "../../data/filtersSlice";
import { useRosterReportData } from "./roster_report/useReportData";
import { useTableRows } from "./roster_report/useTableRows";
import { DayRosterData } from "./roster_report/helpers";
import { useSubmenu } from "../../hooks/useSubmenu";
import { usePrintSeparateCarsPdfExporterer } from "../../printing/useSeparateCarsPDFExporter";
import { Print } from "@mui/icons-material";

interface SeparateCarsColumn {
  id:
    | "line_name"
    | "car_no"
    | "end_time1"
    | "vehicle1"
    | "end_time12"
    | "stamp1"
    | "vehicle2"
    | "start_time2"
    | "start_time22"
    | "stamp2";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

export const SeparateCarsColumns: SeparateCarsColumn[] = [
  { id: "line_name", label: "Линия", minWidth: 80, align: "left" },
  { id: "car_no", label: "Кола", minWidth: 80, align: "left" },
  { id: "end_time1", label: "Час прибиране", minWidth: 80, align: "left" },
  { id: "vehicle1", label: "Кола 1 смяна", minWidth: 80, align: "left" },
  { id: "end_time12", label: "Час прибиране", minWidth: 80, align: "left" },
  { id: "stamp1", label: "Печат", minWidth: 80, align: "left" },
  { id: "vehicle2", label: "Кола 2 смяна", minWidth: 80, align: "left" },
  { id: "start_time2", label: "Час излизане", minWidth: 80, align: "left" },
  { id: "start_time22", label: "Час излизане", minWidth: 80, align: "left" },
  { id: "stamp2", label: "Печат", minWidth: 80, align: "left" },
];

function StickyHeadTable({
  rows,
}: {
  rows: {
    line_name: string;
    car_no: number;
    end_time1: string;
    vehicle1: string | number;
    vehicle2: string | number;
    start_time2: string;
  }[];
}) {
  return (
    <Paper sx={{ height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="stickyHeader">
          <TableHead>
            <TableRow>
              {SeparateCarsColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, idx) => (
              <React.Fragment key={idx}>
                <TableRow>
                  <TableCell>{r.line_name}</TableCell>
                  <TableCell>{r.car_no}</TableCell>
                  <TableCell>{r.end_time1}</TableCell>
                  <TableCell>{r.vehicle1}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{r.vehicle2}</TableCell>
                  <TableCell>{r.start_time2}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function SeparatedCarsReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const dispatch = useAppDispatch();

  const {
    explPlanData,
    isExplPlanError,
    isExplPlanLoading,
    isRosterError,
    isRosterLoading,
    isExplPlanFetching,
    isRosterFetching,
    day_roster,
    day_workshift_ids,
  } = useRosterReportData(selectedDate, selectedAvtoColumn);

  const rows = useTableRows(
    selectedDate,
    explPlanData,
    undefined,
    undefined,
    day_roster,
    day_workshift_ids
  );

  const report_rows = useMemo(() => {
    rows.arr.forEach((r) => {
      if (r.line_name.toUpperCase().startsWith("П-НА")) {
        r.vehicle2 = r.vehicle1;
        r.start_time2 = r.start_time2 ? r.start_time2 : r.start_time;
        r.end_time2 = r.end_time2 ? r.end_time2 : r.end_time;
        r.vehicle1 = -1;
        r.end_time = null;
      }
    });
    return rows.arr
      .filter(
        (r) =>
          (!r.special_shift &&
            ((r.end_time && r.start_time2 && r.end_time !== r.start_time2) || //ако има и първа и втора смяна по разписание
              (r.end_time && r.start_time2 === null) || // само първа смяна по разписание
              (r.end_time === null && r.start_time2) || //само втора смяна по разписание
              (r.vehicle1 > -1 &&
                r.vehicle2 > -1 &&
                r.vehicle1 !== r.vehicle2))) ||
          (r.line_name.toUpperCase().startsWith("П-НА") &&
            (r.vehicle1 > -1 || r.vehicle2 > -1))
      )
      .map((r) => {
        return {
          line_name: r.line_name,
          car_no: r.car_no,
          end_time1: r.end_time
            ? moment
                .utc(moment.duration(r.end_time, "seconds").as("milliseconds"))
                .format("HH:mm")
            : "",
          vehicle1: r.vehicle1 > -1 ? r.vehicle1 : "",
          vehicle2:
            r.vehicle2 > -1 && r.vehicle2 !== r.vehicle1 ? r.vehicle2 : "",
          start_time2:
            r.start_time2 !== null
              ? moment
                  .utc(
                    moment.duration(r.start_time2, "seconds").as("milliseconds")
                  )
                  .format("HH:mm")
              : "",
        };
      });
  }, [rows.arr]);

  console.log("Rows", rows);
  console.log("report_rows", report_rows);

  const { rightToolbarMenu } = useSubmenu();

  const { exportPdf, isLoading: exportPdfLoading } =
    usePrintSeparateCarsPdfExporterer();

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() => exportPdf(report_rows, selectedDate)}
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={2}>
              <DatePicker
                value={moment(selectedDate)}
                onChange={(e) =>
                  e &&
                  e.isValid() &&
                  dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
                }
                label="Дата"
                views={["year", "month", "day"]}
                openTo="day"
                slotProps={{
                  textField: {
                    variant: "standard",
                    sx: { m: 1, width: "100%" },
                  },
                }}
              />
            </Grid>
          </Grid>

          {(isExplPlanError || isRosterError) && (
            <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />
          )}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            open={
              isExplPlanLoading ||
              isRosterLoading ||
              isExplPlanFetching ||
              isRosterFetching
            }
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ height: "100%" }}>
            {report_rows && !isExplPlanError && !isRosterError && (
              <StickyHeadTable rows={report_rows} />
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
}
