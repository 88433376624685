import { yellow } from "@mui/material/colors";
import moment from "moment";
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import { useDrop } from "react-dnd";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../data/schedules/types";
import { DraggableShiftItem } from "./DraggableShiftItem";
import { DriverItemCommentIndicator } from "./DriverItemCommentIndicator";
import { DriverItemContainer } from "./DriverItemContainer";
import { DriverItemDialog } from "./DriverItemDialog";
import { DriverItemErrorIndicator } from "./DriverItemErrorIndicator";
import { DriverItemMainText } from "./DriverItemMainText";
import { DriverItemYesterdayShift } from "./DriverItemYesterdayShift";
import { Roster, RosterDriver, RosterShift } from "./types/Roster";
import { useDriverItemState } from "./useDriverItemState";

export type DriverItemProps = {
  isReadOnly: boolean;
  roster: Roster;
  driver: RosterDriver;
  assigned?: boolean;
  selectedDate: string;
  style?: CSSProperties;
  saveComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
};

const UnassignedDriverItem = forwardRef(
  (
    {
      isReadOnly,
      driver,
      //roster,
      selectedDate,
      style,
      saveComment,
      saveShift,
    }: Omit<DriverItemProps, "assigned">,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const currentDate = moment(selectedDate, "YYYY-MM-DD").startOf("day");
    const isFirstOfMonth = currentDate.isSame(
      currentDate.clone().startOf("month")
    );

    const {
      driverComment,
      driverTitle,
      contextMenu,
      setContextMenu,
      onContextMenu,
    } = useDriverItemState(driver, false, isReadOnly, isFirstOfMonth);

    const onDropShift = useCallback(
      (item: RosterShift) => {
        console.log("Dropped shift over driver:", item, driver);
        saveShift({
          driver_id: driver.schedule_id,
          date: selectedDate,
          shift_type: item.shift.workshift.shift_type,
          shift_id: item.shift.workshift.id,
          veh1_id: null,
          veh2_id: null,
        });
      },
      [driver, saveShift, selectedDate]
    );

    const [shiftProps, shiftRef] = useDrop(
      {
        accept: isReadOnly
          ? []
          : [
              "special-shift-single-1",
              "special-shift-single-2",
              "special-shift-multiple-1",
              "special-shift-multiple-2",
            ],
        drop: onDropShift,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      },
      [onDropShift]
    );
    const isShiftActive = shiftProps.isOver && shiftProps.canDrop;

    const driverShift = useMemo(() => {
      const dshifts = driver.daytasks.filter(
        (dt) => dt.shift_id && !dt.veh1_id && !!dt.rosterShift
      );
      if (dshifts.length > 1) {
        console.warn("Driver with multiple shifts without vehicle:", driver);
      }
      return dshifts[0] || null;
    }, [driver]);

    return (
      <DriverItemContainer
        id={`driver-item-${driver.driver_id}`}
        style={{
          ...style,
          position: "relative",
          backgroundColor: isShiftActive ? yellow[400] : undefined,
          padding: "0px 6px",
          minWidth: 48,
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
        ref={ref}
        driverColor={driver.color}
        hasErrors={driver.roster_issues.length > 0}
        onContextMenu={onContextMenu}
        title={driverTitle}
      >
        <div style={{ position: "relative", width: "100%" }} ref={shiftRef}>
          <DriverItemMainText
            driver={driver}
            assigned={false}
            isFirstOfMonth={isFirstOfMonth}
          />
          <DriverItemYesterdayShift driver={driver} />
          <DriverItemCommentIndicator
            comment={driverComment || driver.comment}
            isRoster={!!driverComment && driverComment.trim() !== ""}
            isUnassigned
          />

          <DriverItemDialog
            driver={driver}
            selectedDate={selectedDate}
            driverComment={driverComment}
            isReadOnly={isReadOnly}
            isAssigned={false}
            contextMenu={contextMenu}
            saveComment={saveComment}
            onCloseMenu={() => setContextMenu(null)}
          />
        </div>

        {driverShift?.rosterShift ? (
          <DraggableShiftItem
            style={{ marginLeft: 12 }}
            assigned
            inDriver
            isReadOnly={isReadOnly}
            selectedDate="2023-08-03"
            inUiVehicle={null}
            openChangeVehicleDialog={() => undefined}
            shift={driverShift.rosterShift}
          />
        ) : (
          <></>
        )}
        <DriverItemErrorIndicator isVisible={driver.roster_issues.length > 0} />
      </DriverItemContainer>
    );
  }
);

const AssignedDriverItem = forwardRef(
  (
    {
      isReadOnly,
      driver,
      selectedDate,
      style,
      saveComment,
    }: Omit<DriverItemProps, "assigned">,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const currentDate = moment(selectedDate, "YYYY-MM-DD").startOf("day");
    const isFirstOfMonth = currentDate.isSame(
      currentDate.clone().startOf("month")
    );

    const {
      driverComment,
      driverTitle,
      contextMenu,
      setContextMenu,
      onContextMenu,
    } = useDriverItemState(driver, true, isReadOnly, isFirstOfMonth);

    return (
      <DriverItemContainer
        id={`driver-item-${driver.driver_id}`}
        style={{
          ...style,
          position: "relative",
          padding: "0px 6px",
        }}
        ref={ref}
        driverColor={driver.color}
        hasErrors={driver.roster_issues.length > 0}
        onContextMenu={onContextMenu}
        title={driverTitle}
      >
        <DriverItemMainText
          driver={driver}
          assigned={true}
          isFirstOfMonth={isFirstOfMonth}
        />
        <DriverItemYesterdayShift driver={driver} />
        <DriverItemCommentIndicator
          comment={driverComment || driver.comment}
          isRoster={!!driverComment && driverComment.trim() !== ""}
        />
        <DriverItemErrorIndicator isVisible={driver.roster_issues.length > 0} />

        <DriverItemDialog
          driver={driver}
          selectedDate={selectedDate}
          driverComment={driverComment}
          isReadOnly={isReadOnly}
          isAssigned={true}
          contextMenu={contextMenu}
          saveComment={saveComment}
          onCloseMenu={() => setContextMenu(null)}
        />
      </DriverItemContainer>
    );
  }
);

export const DriverItem = forwardRef(
  ({ assigned, ...props }: DriverItemProps, ref: ForwardedRef<HTMLElement>) => {
    if (assigned) {
      return <AssignedDriverItem {...props} ref={ref} />;
    } else {
      return <UnassignedDriverItem {...props} ref={ref} />;
    }
  }
);
