import { CSSProperties, memo } from "react";
import { useDrag } from "react-dnd";
import { ShiftItem } from "./ShiftItem";
import { RosterShift } from "./types/Roster";

export const DraggableShiftItem = memo(function DraggableShiftItem({
  isReadOnly,
  shift,
  selectedDate,
  assigned,
  inDriver,
  inUiVehicle,
  style,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  shift: RosterShift;
  selectedDate: string;
  assigned?: boolean;
  inDriver?: boolean;
  inUiVehicle: number | null;
  style?: CSSProperties;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  const isNight =
    shift.type === "timetable" &&
    (shift.shift.lineName.toLocaleLowerCase().startsWith("н") ||
      shift.shift.lineName.toLocaleLowerCase().startsWith("h"));

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: `${shift.type}-shift-${
        shift.type === "timetable" && shift.single_shift ? "single" : "multiple"
      }-${
        shift.type === "timetable"
          ? isNight
            ? "3"
            : shift.shift.workshift.number || 1
          : shift.shift.workshift.shift_type
      }`,
      item: shift,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [shift]
  );

  return (
    <ShiftItem
      isReadOnly={isReadOnly}
      shift={shift}
      selectedDate={selectedDate}
      assigned={assigned}
      inDriver={inDriver}
      inUiVehicle={inUiVehicle}
      ref={drag}
      style={{ opacity: opacity, cursor: "pointer", ...style }}
      openChangeVehicleDialog={openChangeVehicleDialog}
    />
  );
});
