import { useTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { Moment } from "moment";
import { TitleDataCell, TitleHeaderCell } from "./TableElements";
import { DaySummary } from "./types";
import { UseHeaderScrollerResult } from "./useHeaderScroller";

export function RemainingRestsHeaderRow({
  firstHeaderColumnWidth,
  headerColumnWidth,
  headerScroller,
  daySummary,
  isHoliday,
  today,
  selectedDate,
}: {
  firstHeaderColumnWidth: number;
  headerColumnWidth: number;
  headerScroller: UseHeaderScrollerResult;
  isHoliday: (day: string | Moment) => boolean | undefined;
  daySummary: DaySummary[];
  today: Moment;
  selectedDate: Moment | null;
}) {
  const theme = useTheme();

  return (
    <div
      style={{
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
      }}
    >
      <TitleHeaderCell
        tabIndex={0}
        title="Оставащи почивки"
        width={firstHeaderColumnWidth}
        fixLeftIdx={0}
      >
        ОП
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={1}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={2}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={3}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={4}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={5}
      >
        &nbsp;
      </TitleHeaderCell>
      {daySummary.map((summary, idx) => {
        // const additionalRests =
        //   summary.workdays -
        //   (summary.plan_shift1_by_vt +
        //     summary.plan_shift2_by_vt +
        //     summary.plan_no_shift_by_vt) -
        //   (summary.rests + summary.nowork) -
        //   (summary.date.isSameOrBefore(today, "day") ? summary.undef_days : 0);

        return (
          <TitleDataCell
            key={idx}
            tabIndex={0}
            isHoliday={isHoliday(summary.date)}
            title={
              summary.additionalRests > 0
                ? `Може да бъдат дадени още ${summary.additionalRests} почивки, отпуски или болнични`
                : summary.additionalRests < 0
                ? `Трябва да бъдат премахнати ${-summary.additionalRests} почивки, отпуски или болнични за да бъде изпълнен планът.`
                : "Не могат да бъдат давани повече почивки"
            }
            style={{
              ...(summary.additionalRests < 0
                ? {
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.warning.contrastText,
                    fontWeight: "bold",
                    textShadow: isHoliday(summary.date)
                      ? "rgba(0, 0, 0, 1) 0px 0px 2px"
                      : undefined,
                  }
                : {}),
              ...(summary.date.isSameOrBefore(today, "day")
                ? {
                    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
                  }
                : {}),
              ...(summary.date.isSame(selectedDate)
                ? {
                    backgroundColor: blue[300],
                    color: grey[900],
                    textShadow: undefined,
                  }
                : {}),
            }}
          >
            {summary.additionalRests}
          </TitleDataCell>
        );
      })}
      <div style={{ minWidth: headerScroller.scrollerWidth }} />
    </div>
  );
}
