import { SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment, useMemo } from "react";
import { Vehicle } from "../../../data/api/types/driver";
import { ScheduleDriver } from "../../../data/api/types/schedule";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../../data/schedules/types";
import { ItemContainer } from "../ItemContainer";
import {
  ElectroRoster,
  ElectroRosterCar,
  ElectroRosterDriver,
  ElectroRosterVehicle,
} from "../types/ElectroRoster";
import { Roster } from "../types/Roster";
import { ElectroCarItem } from "./ElectroCarItem";

function Separator({
  cars,
  index,
}: {
  cars: readonly ElectroRosterCar[];
  index: number;
}) {
  if (
    index === 0 ||
    (index > 0 &&
      index < cars.length &&
      cars[index - 1].line !== cars[index].line &&
      cars[index].isTimetableCar)
  ) {
    return (
      <div
        style={{
          width: "100%",
          marginLeft: 3,
          marginRight: 3,
          marginTop: 8,
          padding: 4,
          borderRadius: 3,
          border: "1px solid black",
          backgroundColor: grey[200],
        }}
      >
        {cars[index].lineName}
      </div>
    );
  } else if (
    (index === 0 && !cars[index].isTimetableCar) ||
    (index > 0 &&
      index < cars.length &&
      cars[index - 1].isTimetableCar &&
      !cars[index].isTimetableCar)
  ) {
    return (
      <div
        style={{
          width: "100%",
          marginLeft: 3,
          marginRight: 3,
          marginTop: 8,
          padding: 4,
          borderRadius: 3,
          border: "1px solid black",
          backgroundColor: grey[200],
        }}
      >
        Специални смени
      </div>
    );
  } else {
    return null;
  }
}

export function ElectroDroppableShiftContainer({
  roster,
  drivers,
  selectedDate,
  title,
  headerSx,
  apiVehicles,
  selectedLines,
  showVehicleCommentDialog,
  showVehicleStateDialog,
  showCompositionDialog,
  clearVehicleState,
  saveDriverComment,
  saveDriverShift,
  isReadOnly,
  isVehiclesOnly,
  isPreliminaryReady,
  dropDriverOnShift,
  dropVehicleOnShift,
  setErrors,
  getRoster,
}: {
  roster: ElectroRoster;
  drivers: readonly ScheduleDriver[];
  selectedDate: string;
  apiVehicles: readonly Vehicle[];
  selectedLines: readonly number[];
  showVehicleCommentDialog: (vehicle: ElectroRosterVehicle) => void;
  showVehicleStateDialog: (vehicle: ElectroRosterVehicle) => void;
  showCompositionDialog: (vehicle: ElectroRosterVehicle) => void;
  clearVehicleState: (vehicle: ElectroRosterVehicle) => void;
  title?: React.ReactNode;
  headerSx?: SxProps<Theme>;
  // onDrop: (item: RosterShift) => void;
  saveDriverComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveDriverShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  isReadOnly: boolean;
  isVehiclesOnly: boolean;
  isPreliminaryReady: boolean;
  dropDriverOnShift: (
    car: ElectroRosterCar,
    driver: ElectroRosterDriver,
    shiftNumber: 1 | 2 | null
  ) => void;
  dropVehicleOnShift: (
    car: ElectroRosterCar,
    vehicle: ElectroRosterVehicle,
    shiftNumber: 1 | 2 | null
  ) => void;
  setErrors: (errors: readonly string[]) => void;
  getRoster: () => Roster;
}) {
  const filteredCars = useMemo(
    () =>
      selectedLines.length === 0
        ? roster.rosterCars
        : roster.rosterCars.filter(
            (car) =>
              !car.isTimetableCar ||
              (typeof car.line === "number" && selectedLines.includes(car.line))
          ),
    [roster.rosterCars, selectedLines]
  );

  return (
    <ItemContainer
      title={title}
      sx={{ flexGrow: 1, mb: 2 }}
      headerSx={headerSx}
    >
      {filteredCars.map((car, idx, cars) => (
        <Fragment key={idx}>
          <Separator cars={cars} index={idx} />
          <ElectroCarItem
            isReadOnly={isReadOnly || !isPreliminaryReady}
            isVehiclesOnly={isVehiclesOnly}
            car={car}
            roster={roster}
            selectedDate={selectedDate}
            apiVehicles={apiVehicles}
            drivers={drivers}
            showVehicleCommentDialog={showVehicleCommentDialog}
            showVehicleStateDialog={showVehicleStateDialog}
            showCompositionDialog={showCompositionDialog}
            clearVehicleState={clearVehicleState}
            saveDriverComment={saveDriverComment}
            saveDriverShift={saveDriverShift}
            dropDriverOnShift={dropDriverOnShift}
            dropVehicleOnShift={dropVehicleOnShift}
            setErrors={setErrors}
            getRoster={getRoster}
          />
        </Fragment>
      ))}
    </ItemContainer>
  );
}
