import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { indexFromScheduleParameters } from "../api/types/schedule";
import {
  schedulesChannelJoined,
  schedulesUpdateReceived,
  setSchedulesChannelId,
  setSchedulesChannelCallbacks,
  setScheduleConnected,
} from "../scheduleSlice";
import { RootState, useAppDispatch } from "../store";
import { createSchedulesChannelCallbacks } from "./socketFunctions";

export function useSchedulesConnection() {
  const profile = useProfile();
  const editScheduleDisabled = !profile?.roles?.includes("edit_schedule");
  const editRosterDisabled = !profile?.roles?.includes("edit_roster");
  const editRosterVehiclesEnabled =
    editRosterDisabled && !!profile?.roles?.includes("edit_roster_vehicles");

  const dispatch = useAppDispatch();
  const { scheduleParameters: parameters } = useScheduleParameters();
  const connectedChannelId = useSelector(
    (state: RootState) => state.schedule.schedulesChannelId
  );

  const onJoinMessage = useCallback(
    (msg: any) => {
      dispatch(setScheduleConnected(true));
      dispatch(schedulesChannelJoined({ msg, parameters }));
    },
    [dispatch, parameters]
  );

  const onUpdateMessage = useCallback(
    (msg: any) => {
      dispatch(schedulesUpdateReceived(msg));
    },
    [dispatch]
  );

  useEffect(() => {
    const channelId = parameters
      ? indexFromScheduleParameters(parameters)
      : null;

    if (channelId) {
      if (connectedChannelId && connectedChannelId !== channelId) {
        console.debug(
          "Change parameters, release old channel:",
          connectedChannelId,
          channelId
        );

        if (connectedChannelId.startsWith("schedules:")) {
          window.globals.nariadSocket.releaseChannels("schedules:");
        } else {
          window.globals.nariadSocket.releaseChannel(connectedChannelId);
        }
      }

      console.debug("Connecting new channel:", channelId);
      window.globals.nariadSocket
        .getChannel(channelId, onJoinMessage, onUpdateMessage, () => {
          // on join error
          dispatch(setScheduleConnected(false));
          dispatch(setSchedulesChannelId(channelId));
        })
        .then((ref) => {
          dispatch(setSchedulesChannelId(ref.channelId));
          dispatch(
            setSchedulesChannelCallbacks(
              createSchedulesChannelCallbacks(
                ref.channel,
                editScheduleDisabled,
                editRosterDisabled,
                editRosterVehiclesEnabled
              )
            )
          );
        });
    }
  }, [
    parameters,
    onJoinMessage,
    onUpdateMessage,
    connectedChannelId,
    dispatch,
    editScheduleDisabled,
    editRosterDisabled,
    editRosterVehiclesEnabled,
  ]);
}
