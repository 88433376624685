export async function warnOnPromise<TData, TResult>(
  func: (data: TData) => Promise<TResult>,
  data: TData
): Promise<TResult | { status: "error"; errors: any[] }> {
  try {
    const result = await func(data);
    console.debug("Promise ready:", result);
    return result;
  } catch (e: any) {
    console.warn("Promise error:", e);
    if (e.status === "error" && Array.isArray(e.errors)) {
      return e;
    } else {
      return {
        status: "error",
        errors: [e],
      };
    }
  }
}
