import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../data/store";
import { Moment } from "moment";
import { Box, Container } from "@mui/system";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Portal,
} from "@mui/material";
import { TransportSelect } from "../../../filters/TransportSelect";
import { DepotSelect } from "../../../filters/DepotSelect";
import { AutoColumnSelect } from "../../../filters/AutoColumnSelect";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { QueryError } from "../../QueryError";
import {
  DayTaskStates,
  useScheduleRecapitulation,
} from "./useScheduleRecapitulation";
import { useSubmenu } from "../../../hooks/useSubmenu";
import { Print } from "@mui/icons-material";
import { usePrintScheduleRecapitulationReport } from "../../../printing/usePrintScheduleRecapitulationReport";
import { updateSelectedDate } from "../../../data/filtersSlice";
import { useReportData } from "../roster_report/useReportData";

function ReportContent({
  rows,
  daytask_states,
  selectedDate,
  totalDriverCount,
}: {
  rows: { [key: string]: number[] };
  daytask_states: DayTaskStates[];
  selectedDate: Moment;
  totalDriverCount: number;
}) {
  const { rightToolbarMenu } = useSubmenu();
  const { exportPdf, isLoading: exportPdfLoading } =
    usePrintScheduleRecapitulationReport();

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={
            //!scheduleParameters ||
            //schedule.drivers_sync_status?.status !== "ok" ||
            exportPdfLoading
          }
          onClick={() =>
            exportPdf(rows, daytask_states, selectedDate, totalDriverCount)
          }
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>

      <Paper sx={{ height: "auto", px: 20, py: 6 }}>
        <h2 style={{ padding: "10" }}>
          {`Разпределение на водачи за ${selectedDate.format(
            "LL"
          )} - общо ${totalDriverCount} бр.`}
        </h2>
        {Object.keys(rows).map((r) => (
          <div key={r}>
            <h3>
              {parseInt(r, 10) >= 0
                ? daytask_states.find((s) => s.state_id === parseInt(r, 10))
                    ?.description
                : parseInt(r, 10) === -1
                ? "Водачи без назначена задача"
                : ""}{" "}
              - {rows[r].length} бр.
            </h3>

            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {rows[r].join(", ")}
            </p>
          </div>
        ))}
      </Paper>
    </>
  );
}

export function ScheduleRecapitulationReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  //const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const dispatch = useAppDispatch();

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  // const { data, isLoading, isError, rows, totalDriverCount } =
  //   useScheduleRecapitulation(
  //     selectedTransport,
  //     selectedDepot,
  //     selectedDate,
  //     selectedAvtoColumn
  //   );

  const {
    recapData: data,
    recapRows: rows,
    totalDriverCount,
    isError,
    isLoading,
  } = useReportData(selectedDate);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container>
        <Grid container spacing={2} mt={1}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsAutocolumnSelect ? (
            <Grid item xs={12} sm={2}>
              <AutoColumnSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={2}>
            <DatePicker
              value={moment(selectedDate)}
              onChange={(e) =>
                e &&
                e.isValid() &&
                dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
              }
              label="Дата"
              views={["year", "month", "day"]}
              openTo="day"
              slotProps={{
                textField: {
                  variant: "standard",
                  sx: { m: 1, width: "100%" },
                },
              }}
            />
          </Grid>
        </Grid>

        {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />}
      </Container>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Backdrop
          open={isLoading}
          sx={{
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 1,
              marginBottom: 4,
            }}
          />
        </Backdrop>
        <Container sx={{ py: 4 }}>
          {rows &&
            !isError &&
            data?.data.daytask_states &&
            data.data.drivers_schedule.length > 0 &&
            totalDriverCount && (
              <ReportContent
                rows={rows}
                daytask_states={data?.data.daytask_states}
                selectedDate={moment(selectedDate)}
                totalDriverCount={totalDriverCount}
              />
            )}
          {!isError && data?.data.drivers_schedule.length === 0 && (
            <div>
              <Alert severity="info"> Няма данни за избраната дата!</Alert>
            </div>
          )}
        </Container>
      </Box>
    </Box>
  );
}
