import { alpha } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";

export function DriverItemMainText({
  driver,
  isFirstOfMonth,
  assigned,
}: {
  driver: RosterDriver | ElectroRosterDriver;
  isFirstOfMonth: boolean;
  assigned?: boolean;
}) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          fontSize: 12,
          fontWeight: "bold",
          position: "relative",
          top: 7,
          color: driver.roster_issues.length > 0 ? red[900] : grey[900],
        }}
      >
        {assigned
          ? driver.sl_number
          : `${driver.sl_number}/${driver.shift_type || 1}`}
      </div>
      <div
        style={{
          position: "absolute",
          top: 1,
          right: assigned ? -1 : -7,
          zIndex: 1,
          //color: driver.roster_issues.length > 0 ? red[900] : grey[900],
          color: grey[900],
          fontSize: 9,
          fontWeight: "bold",
          borderRadius: 3,
          padding: "0px 3px",
          backgroundColor: alpha(grey[100], 0.5),
          lineHeight: 1,
        }}
      >
        <div>
          {isFirstOfMonth
            ? "0"
            : Math.round(
                (driver.total_workseconds - driver.total_quota * 3600) / 3600
              )}
        </div>
        {assigned && driver.intershift_times.length > 0 ? (
          <div>{Math.round(driver.intershift_times[0].time / 3600)}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
