import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { useGetTimetablesByDateRosterQuery } from "../../data/api/hooks";
import { RootState } from "../../data/store";

export function useGetTimetablesByDateRosterQueryArray() {
  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? moment(state.filters.selectedDate)
      : moment().add(1, "day").startOf("day")
  );
  const explPlanVersion = useSelector(
    (state: RootState) => state.schedule.explPlanVersion
  );
  const [lastExplPlanVersion, setLastExplPlanVersion] =
    useState(explPlanVersion);
  const { scheduleParameters } = useScheduleParameters();

  const timetables = useGetTimetablesByDateRosterQuery(
    scheduleParameters
      ? {
          transport: scheduleParameters[0],
          garage: scheduleParameters[1],
          date: selectedDate.format("YYYY-MM-DD"),
          avtocolona: scheduleParameters[4],
        }
      : { transport: "", garage: 0, date: "1970-01-01", avtocolona: 1 },
    {
      skip: !scheduleParameters,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const refetch = timetables.refetch;
  useEffect(() => {
    if (explPlanVersion !== lastExplPlanVersion) {
      refetch();
      setLastExplPlanVersion(explPlanVersion);
    }
  }, [explPlanVersion, lastExplPlanVersion, refetch]);

  return timetables;
}
