import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useScheduleParameters } from "../../../components/schedule/useScheduleParameters";
import { Vehicle } from "../../../data/api/types/driver";
import { RosterFilter } from "../RosterFilter";
import { DayExplPlanRoster } from "../../reports/useTimetables_ByDate";
import { useSchedules } from "../../../data/schedules/useSchedules";
import { useVehicleTypesList } from "../../schedule/useVehicleTypesList";
import { useVehicles } from "../../../data/schedules/useVehicles";
import { useProfile } from "../../../common/useProfile";
import { useSelector } from "react-redux";
import { RootState } from "../../../data/store";
import { usePostVehicleCompositionMutation } from "../../../data/api/hooks";
import {
  ElectroDroppableVehiclesContainer,
  useElectroDropOnVehicles,
} from "./ElectroDroppableVehiclesContainer";
import { ElectroDroppableShiftContainer } from "./ElectroDroppableShiftContainer";
import {
  ElectroDroppableDriversContainer,
  useElectroDropOnDrivers,
} from "./ElectroDroppableDriversContainer";
import {
  createElectroRoster,
  emptyElectroRoster,
} from "../types/createElectroRoster";
import { ElectroRosterVehicle } from "../types/ElectroRoster";
import { GenericCommentDialog } from "../../../components/GenericCommentDialog";
import { VehicleStateDialog } from "../../../components/VehicleStateDialog";
import { VehicleCompositionDialog } from "../../../components/VehicleCompositionDialog";
import { RosterTopMenu } from "../RosterTopMenu";
import moment from "moment";
import { createRoster, emptyRoster } from "../types/createRoster";
import { useElectroDropOnShift } from "./ElectroCarItem";
import { Close } from "@mui/icons-material";
import { green, grey, red } from "@mui/material/colors";

export const ElectroDriverRosterInt = memo(function ElectroDriverRosterInt({
  api_vehicles,
  driversLoading,
  explPlan,
  explPlanLoading,
  selectedDate,
}: {
  api_vehicles: readonly Vehicle[];
  driversLoading: boolean;
  explPlan: DayExplPlanRoster;
  explPlanLoading: boolean;
  selectedDate: string;
}) {
  const { scheduleParameters } = useScheduleParameters();

  const {
    schedule,
    editDriverDayRosterComment,
    addShiftToVehicle,
    createRoster: initRoster,
    clearDriverTasks,
  } = useSchedules();

  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_roster");
  const isVehiclesOnly =
    !!profile?.roles?.includes("edit_roster_vehicles") && isReadOnly;

  useEffect(() => {
    initRoster(selectedDate);
  }, [initRoster, selectedDate]);

  const { vehicles, ...vehiclesSchedule } = useVehicles();
  const { allVehicleTypes, vehicleTypesLoading, vehicleTypesError } =
    useVehicleTypesList(schedule);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<number[]>(
    []
  );
  const [selectedLines, setSelectedLines] = useState<number[]>([]);

  const vehiclesScheduleConnected = useSelector(
    (state: RootState) => state.schedule.vehiclesScheduleConnected
  );
  const scheduleConnected = useSelector(
    (state: RootState) => state.schedule.scheduleConnected
  );
  const socketConnected = useSelector(
    (state: RootState) => state.schedule.socketConnected
  );
  const connected =
    vehiclesScheduleConnected &&
    scheduleConnected &&
    socketConnected &&
    schedule?.drivers_sync_status?.status === "ok" &&
    !vehicleTypesLoading &&
    !explPlanLoading &&
    !driversLoading;

  console.log("Connection status:", connected);

  const roster = useMemo(() => {
    if (
      scheduleParameters &&
      schedule &&
      vehicles?.vehicles &&
      !vehicleTypesError &&
      !vehicleTypesLoading
    ) {
      return createElectroRoster(
        scheduleParameters[4],
        selectedDate,
        // drivers,
        vehicles.vehicles,
        schedule,
        explPlan,
        allVehicleTypes
      );
    } else {
      return emptyElectroRoster;
    }
  }, [
    scheduleParameters,
    schedule,
    vehicles?.vehicles,
    vehicleTypesError,
    vehicleTypesLoading,
    selectedDate,
    explPlan,
    allVehicleTypes,
  ]);

  const getRoster = useCallback(() => {
    if (
      scheduleParameters &&
      schedule &&
      vehicles?.vehicles &&
      !vehicleTypesError &&
      !vehicleTypesLoading
    ) {
      return createRoster(
        scheduleParameters[4],
        selectedDate,
        // drivers,
        vehicles.vehicles,
        schedule,
        explPlan,
        allVehicleTypes
      );
    } else {
      return emptyRoster;
    }
  }, [
    allVehicleTypes,
    explPlan,
    schedule,
    scheduleParameters,
    selectedDate,
    vehicleTypesError,
    vehicleTypesLoading,
    vehicles?.vehicles,
  ]);

  const [changeComposition] = usePostVehicleCompositionMutation();

  const saveVehicleComposition = useCallback(
    async (
      vehicle: ElectroRosterVehicle,
      secondVehicle: ElectroRosterVehicle | null
    ) => {
      if ((isReadOnly && !isVehiclesOnly) || !scheduleParameters) {
        return;
      }

      console.log("Change composition", vehicle, secondVehicle);

      const result = await changeComposition({
        transport: scheduleParameters[0],
        depot: scheduleParameters[1],
        veh1_id: vehicle.vehicle_id,
        veh2_id: secondVehicle?.vehicle_id || null,
        start_date: selectedDate,
        end_date: null,
      }).unwrap();

      if (result?.status !== "ok") {
        throw result;
      }
    },
    [
      changeComposition,
      isReadOnly,
      isVehiclesOnly,
      scheduleParameters,
      selectedDate,
    ]
  );

  const saveVehicleComment = useCallback(
    async (vehicle: ElectroRosterVehicle, comment: string | null) => {
      if (isReadOnly && !isVehiclesOnly) {
        return;
      }

      console.log("Add comment", comment, "to", vehicle);
      const result = await vehiclesSchedule.editVehicleDayComment({
        vehicle_id: vehicle.vehicle_id,
        date: selectedDate,
        comment,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, isVehiclesOnly, selectedDate, vehiclesSchedule]
  );

  const saveVehicleState = useCallback(
    async (
      vehicle: ElectroRosterVehicle,
      state: number,
      comment: string | null
    ) => {
      if (isReadOnly && !isVehiclesOnly) {
        return;
      }

      console.log("Add state", state, comment, "to", vehicle);
      const result = await vehiclesSchedule.addVehicleInterval({
        vehicle_id: vehicle.vehicle_id,
        start_date: selectedDate,
        end_date: null,
        state_id: state,
        comment,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, isVehiclesOnly, selectedDate, vehiclesSchedule]
  );

  const clearVehicleState = useCallback(
    async (vehicle: ElectroRosterVehicle) => {
      if (isReadOnly && !isVehiclesOnly) {
        return;
      }

      console.log("Clear state of", vehicle);
      const result = await vehiclesSchedule.addVehicleInterval({
        vehicle_id: vehicle.vehicle_id,
        start_date: selectedDate,
        end_date: null,
        state_id: 0,
        comment: null,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, isVehiclesOnly, selectedDate, vehiclesSchedule]
  );

  const [commentDialog, setCommentDialog] =
    useState<ElectroRosterVehicle | null>(null);
  const [stateDialog, setStateDialog] = useState<ElectroRosterVehicle | null>(
    null
  );
  const [compositionDialog, setCompositionDialog] =
    useState<ElectroRosterVehicle | null>(null);

  const showVehicleCommentDialog = useCallback(
    (vehicle: ElectroRosterVehicle) => {
      if (!isReadOnly || isVehiclesOnly) {
        setCommentDialog(vehicle);
      }
    },
    [isReadOnly, isVehiclesOnly]
  );

  const showVehicleStateDialog = useCallback(
    (vehicle: ElectroRosterVehicle) => {
      if (!isReadOnly || isVehiclesOnly) {
        setStateDialog(vehicle);
      }
    },
    [isReadOnly, isVehiclesOnly]
  );

  const showCompositionDialog = useCallback(
    (vehicle: ElectroRosterVehicle) => {
      if (!isReadOnly || isVehiclesOnly) {
        setCompositionDialog(vehicle);
      }
    },
    [isReadOnly, isVehiclesOnly]
  );

  const allFilteredVehicles = useMemo(
    () =>
      selectedVehicleTypes.length === 0
        ? roster.unusedRosterVehicles
        : roster.unusedRosterVehicles.filter(
            (vehicle) =>
              !vehicle.vehicletype_id ||
              selectedVehicleTypes.includes(vehicle.vehicletype_id)
          ),
    [roster.unusedRosterVehicles, selectedVehicleTypes]
  );

  const extraDrivers = useMemo(
    () =>
      Object.values(schedule?.drivers || {}).filter(
        // Стажанти
        (d) => d.driver_days[selectedDate]?.state === 9
      ),
    [schedule?.drivers, selectedDate]
  );

  const {
    dropDriverOnShift,
    dropVehicleOnShift,
    errors: dropOnShiftErrors,
    clearErrors: clearDropOnShiftErrors,
  } = useElectroDropOnShift(selectedDate, isReadOnly, isVehiclesOnly);
  const {
    dropDriver,
    errors: dropOnDriversErrors,
    clearErrors: clearDropOnDriversErrors,
  } = useElectroDropOnDrivers(roster.rosterCars, selectedDate, isReadOnly);
  const {
    dropVehicle,
    errors: dropOnVehiclesErrors,
    clearErrors: clearDropOnVehiclesErrors,
  } = useElectroDropOnVehicles(
    roster.rosterCars,
    selectedDate,
    isReadOnly,
    isVehiclesOnly
  );

  const [compositionErrors, setCompositionErrors] = useState<readonly string[]>(
    []
  );
  const errors = useMemo(
    () => [
      ...dropOnShiftErrors,
      ...dropOnVehiclesErrors,
      ...dropOnDriversErrors,
      ...compositionErrors,
    ],
    [
      compositionErrors,
      dropOnDriversErrors,
      dropOnShiftErrors,
      dropOnVehiclesErrors,
    ]
  );
  const clearErrors = useCallback(() => {
    clearDropOnShiftErrors();
    clearDropOnVehiclesErrors();
    clearDropOnDriversErrors();
    setCompositionErrors([]);
  }, [
    clearDropOnDriversErrors,
    clearDropOnShiftErrors,
    clearDropOnVehiclesErrors,
  ]);

  // console.log(dropOnVehiclesErrors);

  const errorIntershiftTimes = useMemo(
    () =>
      roster.rosterCars
        .flatMap((rc) => {
          if (rc.shift1?.driver && rc.shift2?.driver) {
            return [rc.shift1.driver, rc.shift2.driver];
          } else if (rc.shift1?.driver) {
            return [rc.shift1.driver];
          } else if (rc.shift2?.driver) {
            return [rc.shift2.driver];
          } else {
            return [];
          }
        })
        .reduce<number[]>(
          (acc, driver) =>
            driver.roster_issues.find(
              (issue) => issue.id === 176 || issue.id === 178
            )
              ? acc.concat(driver.sl_number)
              : acc,
          []
        ),
    [roster.rosterCars]
  );

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
        }}
        open={!connected}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <RosterTopMenu
        isReadOnly={isReadOnly || !schedule?.db_schedule?.preliminary_ready}
        scheduleParameters={scheduleParameters}
        selectedDate={selectedDate}
        // drivers={drivers.drivers}
        drivers={schedule ? [...Object.values(schedule.drivers)] : []}
        extraDrivers={extraDrivers}
        apiVehicles={api_vehicles}
        errorIntershiftTimes={errorIntershiftTimes}
        getRoster={getRoster}
        cleanupRoster={() => {
          if (
            window.confirm(
              `Сигурни ли сте че желаете да изтриете целия наряд за ${moment(
                selectedDate,
                "YYYY-MM-DD"
              ).format("LL")}?`
            )
          ) {
            clearDriverTasks(selectedDate);
          }
        }}
      />

      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <RosterFilter
          schedule={schedule}
          shifts={roster.allShifts}
          selectedVehicleTypes={selectedVehicleTypes}
          selectedLines={selectedLines}
          setSelectedVehicleTypes={setSelectedVehicleTypes}
          setSelectedLines={setSelectedLines}
        />

        {schedule?.db_schedule && !schedule.db_schedule.preliminary_ready && (
          <Alert severity="warning" sx={{ mx: 1, mb: 2 }}>
            <AlertTitle>Внимание:</AlertTitle>
            <Typography>
              Нарядът не може да се редактира преди да бъде потвърден
              предварителният график
            </Typography>
          </Alert>
        )}

        {errors.length > 0 ? (
          <Alert
            severity="error"
            sx={{ mx: 1, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => clearErrors()}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Грешки:</AlertTitle>
            {errors.map((error, idx) => (
              <Typography key={idx}>{error}</Typography>
            ))}
          </Alert>
        ) : undefined}

        <Box style={{ flexGrow: 1, overflow: "auto" }}>
          <DndProvider backend={HTML5Backend}>
            <Box
              sx={{
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <Box sx={{ height: "100%", p: 1, minWidth: 229, maxWidth: 229 }}>
                <ElectroDroppableVehiclesContainer
                  isReadOnly={isReadOnly}
                  isVehiclesOnly={isVehiclesOnly}
                  isPreliminaryReady={
                    !!schedule?.db_schedule?.preliminary_ready
                  }
                  title={`Свободни ПС: ${roster.unusedRosterVehicles.length}`}
                  roster={roster}
                  // drivers={drivers.drivers}
                  drivers={schedule ? [...Object.values(schedule.drivers)] : []}
                  allFilteredVehicles={allFilteredVehicles}
                  selectedLines={selectedLines}
                  selectedDate={selectedDate}
                  apiVehicles={api_vehicles}
                  showVehicleCommentDialog={showVehicleCommentDialog}
                  showVehicleStateDialog={showVehicleStateDialog}
                  showCompositionDialog={showCompositionDialog}
                  clearVehicleState={clearVehicleState}
                  setErrors={setCompositionErrors}
                  getRoster={getRoster}
                  onDrop={dropVehicle}
                />
              </Box>

              <Box
                sx={{
                  maxHeight: "100%",
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <ElectroDroppableShiftContainer
                  isReadOnly={isReadOnly}
                  isVehiclesOnly={isVehiclesOnly}
                  isPreliminaryReady={
                    !!schedule?.db_schedule?.preliminary_ready
                  }
                  title={
                    <>
                      <span style={{ marginRight: 40, fontWeight: "bold" }}>
                        Коли:
                      </span>
                      <span
                        style={{
                          marginRight: 40,
                          backgroundColor:
                            roster.summary.emptyShifts === 0 &&
                            roster.summary.noVehicleShifts > 0
                              ? green[800]
                              : undefined,
                          padding: "7px 16px 7px 16px",
                        }}
                      >
                        Непокрити смени: {roster.summary.emptyShifts}
                      </span>
                      <span
                        style={{
                          backgroundColor:
                            roster.summary.emptyShifts > 0 &&
                            roster.summary.noVehicleShifts === 0
                              ? green[800]
                              : undefined,
                          padding: "7px 16px 7px 16px",
                        }}
                      >
                        Смени без превозно седство:{" "}
                        {roster.summary.noVehicleShifts}
                      </span>
                    </>
                  }
                  // drivers={drivers.drivers}
                  drivers={schedule ? [...Object.values(schedule.drivers)] : []}
                  roster={roster}
                  selectedDate={selectedDate}
                  apiVehicles={api_vehicles}
                  selectedLines={selectedLines}
                  showVehicleCommentDialog={showVehicleCommentDialog}
                  showVehicleStateDialog={showVehicleStateDialog}
                  showCompositionDialog={showCompositionDialog}
                  clearVehicleState={clearVehicleState}
                  saveDriverComment={editDriverDayRosterComment}
                  saveDriverShift={addShiftToVehicle}
                  dropDriverOnShift={dropDriverOnShift}
                  dropVehicleOnShift={dropVehicleOnShift}
                  setErrors={setCompositionErrors}
                  getRoster={getRoster}
                  headerSx={{
                    backgroundColor:
                      roster.summary.emptyShifts > 0 ||
                      roster.summary.noVehicleShifts > 0
                        ? red[800]
                        : grey[300],
                    "& .MuiCardHeader-title": {
                      color:
                        roster.summary.emptyShifts > 0 ||
                        roster.summary.noVehicleShifts > 0
                          ? "white"
                          : undefined,
                    },
                  }}
                />
                <ElectroDroppableDriversContainer
                  isReadOnly={isReadOnly || isVehiclesOnly}
                  isPreliminaryReady={
                    !!schedule?.db_schedule?.preliminary_ready
                  }
                  title={`Свободни водачи: ${roster.unusedRosterDrivers.length}`}
                  roster={roster}
                  selectedLines={selectedLines}
                  selectedVehicleTypes={selectedVehicleTypes}
                  selectedDate={selectedDate}
                  accept={
                    isReadOnly ||
                    isVehiclesOnly ||
                    !schedule?.db_schedule?.preliminary_ready
                      ? []
                      : ["driver-shift-1", "driver-shift-2"]
                  }
                  saveComment={editDriverDayRosterComment}
                  saveShift={addShiftToVehicle}
                  onDrop={dropDriver}
                />
              </Box>
            </Box>
          </DndProvider>
        </Box>
      </Box>

      <GenericCommentDialog
        open={!!commentDialog}
        initialValue={commentDialog?.vehicle.days[selectedDate]?.comment}
        heading={`Коментар за превозно средство ${
          commentDialog?.vehicle_id || ""
        }:`}
        onClose={() => setCommentDialog(null)}
        onSave={async (comment) => {
          if (commentDialog) {
            await saveVehicleComment(commentDialog, comment);
          }
        }}
      />

      {scheduleParameters && (
        <>
          <VehicleStateDialog
            open={!!stateDialog}
            initialValue={
              stateDialog?.vehicle.days[selectedDate]?.state?.id || 0
            }
            heading={`Промяна на състояние на превозно средство ${
              stateDialog?.vehicle_id || ""
            }:`}
            transport={scheduleParameters[0]}
            onClose={() => setStateDialog(null)}
            onSave={async (state, comment) => {
              if (stateDialog) {
                await saveVehicleState(stateDialog, state, comment);
              }
            }}
          />

          <VehicleCompositionDialog
            open={!!compositionDialog}
            currentVehicle={compositionDialog}
            vehicleList={allFilteredVehicles}
            onClose={() => setCompositionDialog(null)}
            onSave={async (secondVehicle: ElectroRosterVehicle | null) => {
              if (compositionDialog) {
                await saveVehicleComposition(compositionDialog, secondVehicle);
              }
            }}
          />
        </>
      )}
    </div>
  );
});
