import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Portal,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSubmenu } from "../../hooks/useSubmenu";
import { NavLink } from "../../common/NavLink";
import { WithAuth } from "../../common/WithAuth";
import { SubOutlet } from "../SubOutlet";
import { WithAnyRole } from "../../common/WithRoles";

export function ReportsPage() {
  const { submenu } = useSubmenu();

  return (
    <WithAuth>
      <div style={{ width: "100%", height: "100%" }}>
        <Portal container={submenu}>
          <List
            dense
            subheader={
              <ListSubheader component="div" inset>
                Справки
              </ListSubheader>
            }
          >
            <WithAnyRole roles={["nariad_admin", "view_fake_schedule"]}>
              <ListItemButton to="/reports/fake-schedule" component={NavLink}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Предварителен график" />
              </ListItemButton>
            </WithAnyRole>

            <ListItemButton to="/reports/exploatation-plan" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Експлоатационен план" />
            </ListItemButton>

            <ListItemButton to="/reports/workshift-counts" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Разписания - обобщено" />
            </ListItemButton>
            <ListItemButton
              to="/reports/line-timetbale-shifts"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Разписания - заработки" />
            </ListItemButton>
            <ListItemButton
              to="/reports/schedule-recapitulation-report"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="График - рекапитулация" />
            </ListItemButton>

            <ListItemButton
              to="/reports/vehicle-recapitulation-report"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Коли - рекапитулация" />
            </ListItemButton>

            <ListItemButton
              to="/reports/schedule-history-report"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="График - история" />
            </ListItemButton>
            <ListItemButton to="/reports/roster-report" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Наряд" />
            </ListItemButton>
            <ListItemButton to="/reports/separated-cars" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Изнесени коли" />
            </ListItemButton>
            <ListItemButton to="/reports/roster-outtimes" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Часове на излизане" />
            </ListItemButton>
            <ListItemButton
              to="/reports/driver-personal-account"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Разход на водач" />
            </ListItemButton>
            <ListItemButton
              to="/reports/vehicle-personal-account"
              component={NavLink}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Разход на кола" />
            </ListItemButton>
          </List>
        </Portal>
        <SubOutlet />
      </div>
    </WithAuth>
  );
}
