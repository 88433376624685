export function formatHours(
  seconds: number | null | undefined,
  normalHours = 8
): string {
  //console.log("Formatting hours:", seconds, normalHours);
  if ((!seconds || seconds === 0) && (!normalHours || normalHours === 0)) {
    return "";
  } else {
    const diff_seconds = (seconds || 0) - normalHours * 3600;
    const diff_hours = diff_seconds / 3600;

    if (diff_hours >= 0) {
      return `+${Math.round(diff_hours)}`;
    } else {
      return `-${Math.round(Math.abs(diff_hours))}`;
    }
  }
}

export function formatHoursMinutes(seconds: number | null | undefined): string {
  //console.log("Formatting hours:", seconds, normalHours);
  if (!seconds || seconds === 0) {
    return "";
  } else {
    const diff_seconds = seconds || 0;
    const diff_hours = diff_seconds / 3600;

    return `${Math.floor(diff_hours).toString().padStart(2, "0")}:${(
      (diff_hours - Math.floor(diff_hours)) *
      60
    )
      .toFixed(0)
      .padStart(2, "0")}`;
  }
}
