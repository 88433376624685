import {
  styled,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(1, 3),
}));

export function InterShiftTimeErrorsTable({
  results,
}: {
  results: readonly number[];
}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left" width={1}>
            Водач
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((r, i) => (
          <TableRow
            key={i}
            hover
            sx={{ cursor: "pointer" }}
            // onClick={() => setDriverFilterValue(r.sl_number.toString())}
          >
            <TableCell align="left" width={1}>
              <strong>{r}</strong>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
