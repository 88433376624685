import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { usePutVehicleCorrectionMutation } from "../../data/api/hooks";
import { Transport } from "../../data/api/types/driver";
import { useCallback, useEffect, useMemo } from "react";
import moment, { Moment } from "moment";
import { useProfile } from "../../common/useProfile";

export interface Column {
  id: "vehicle_id" | "autocolumn" | "vehicle_type" | "periodic_tech_test";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  readonly?: boolean;
}

export interface VehicleType {
  id: number;
  description: string;
  koef: number;
  places: number;
}

export const columns: readonly Column[] = [
  {
    id: "vehicle_id",
    label: "Номер",
    align: "center",
    minWidth: 60,
    readonly: true,
  },
  {
    id: "autocolumn",
    label: "Автоколона",
    align: "center",
    minWidth: 30,
    readonly: true,
  },
  {
    id: "vehicle_type",
    label: "Тип",
    minWidth: 80,
    align: "left",
    readonly: true,
  },
  {
    id: "periodic_tech_test",
    label: "Подлежи на техн. преглед до: ",
    minWidth: 80,
    align: "center",
    readonly: false,
  },
];

export type VehicleFilter = {
  vehicle_id: string | null;
  vehicle_type: string | null;
  autocolumn: string | null;
  periodic_tech_test: string | null;
};

export type Data = {
  vehicle_id: number;
  vehicle_type: string;
  autocolumn: number | null;
  periodic_tech_test: string | null;
};

export function useVehiclesList(
  data: Data[],
  transport: Transport | "",
  garage: number,
  isVehiclesLoading: boolean,
  isVehiclesError: boolean
) {
  const profile = useProfile();
  const isReadOnly =
    !profile?.roles?.includes("edit_schedule") &&
    !profile?.roles?.includes("edit_tech_service");

  const [updateVehicle, resultUpdateVehicle] =
    usePutVehicleCorrectionMutation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      vehicles: data,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    console.log("useEffect Data", data);
    reset({
      vehicles: data,
    });
  }, [data, reset]);

  const { fields } = useFieldArray({
    name: "vehicles",
    control,
  });

  const onSubmit = useCallback<SubmitHandler<{ vehicles: Data[] }>>(
    (data) => {
      console.log("Submit isDirty: ", isDirty);
      console.log("Submit From submit dirtyFields: ", dirtyFields);
      //let isError = false;

      if (!isReadOnly && isDirty) {
        if (dirtyFields.vehicles) {
          for (const index of Object.keys(dirtyFields.vehicles)) {
            const vehicle = data.vehicles[index as unknown as number];

            updateVehicle({
              transport: transport,
              gps_depot_id: garage,
              vehicle_id: vehicle.vehicle_id,
              autocolumn: vehicle.autocolumn,
              periodic_tech_test: vehicle.periodic_tech_test
                ? moment(vehicle.periodic_tech_test, "DD.MM.YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            }).then((res) => {
              if ("error" in res && res.error) {
                // <QueryError error={res.error} />;
                console.log("UpdateDriverError:", res.error);
                //isError = true;
              } else {
                console.log("UpdateDriver OK:");
              }
            });
            console.log(data.vehicles[index as unknown as number]);
          }
        }
      }
    },
    [isDirty, dirtyFields, isReadOnly, updateVehicle, transport, garage]
  );

  const submit = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  const hasError = resultUpdateVehicle.isError || isVehiclesError;
  const loading = resultUpdateVehicle.isLoading || isVehiclesLoading;

  return {
    register,
    control,
    fields,
    handleSubmit: submit,
    isDirty,
    dirtyFields,
    hasError,
    loading,
  };
}
