import { useOutletContext } from "react-router-dom";

export const useSubmenu = () => {
  const { submenu, belowSubmenu, rightToolbarMenu } = useOutletContext<{
    submenu: HTMLDivElement | null;
    belowSubmenu: HTMLDivElement | null;
    rightToolbarMenu: HTMLDivElement | null;
  }>();

  console.assert(
    !!submenu && !!rightToolbarMenu && !!belowSubmenu,
    "Portals must be initialized before calling useSubmenu"
  );

  return {
    submenu: submenu as HTMLDivElement,
    belowSubmenu: belowSubmenu as HTMLDivElement,
    rightToolbarMenu: rightToolbarMenu as HTMLDivElement,
  };
};
