import { createContext, ReactNode } from "react";

const initialState = {
  isScheduleActive: true,
};

export const PSchedulesContext = createContext<{ isScheduleActive: boolean }>(
  initialState
);

export function SchedulesContext({
  children,
}: {
  children?: ReactNode | undefined;
}) {
  return (
    <PSchedulesContext.Provider value={initialState}>
      {children}
    </PSchedulesContext.Provider>
  );
}
