import { ItemContainer } from "../ItemContainer";
import { useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { green, grey, red } from "@mui/material/colors";
import { alpha } from "@mui/material";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../../data/schedules/types";
import {
  ElectroRoster,
  ElectroRosterCar,
  ElectroRosterDriver,
} from "../types/ElectroRoster";
import { ElectroDriverItem } from "./ElectroDriverItem";
import { ElectroDraggableDriverItem } from "./ElectroDraggableDriverItem";
import { useSchedules } from "../../../data/schedules/useSchedules";
import moment from "moment";
import { callWithErrors } from "./ElectroCarItem";

export function useElectroDropOnDrivers(
  rosterCars: readonly ElectroRosterCar[],
  selectedDate: string,
  isReadOnly: boolean
) {
  const { electroRemoveDriverFromShift } = useSchedules();

  const [errors, setErrors] = useState<string[]>([]);
  const createErrors = useCallback(
    (
      result: {
        status: "error";
        errors: { error_type?: string | null; message?: string | null }[];
      },
      driver: ElectroRosterDriver
    ) => {
      // console.log("Creating errors for:", result, vehicle);
      const errorMessages = result.errors.map((e) => {
        if (e.message && e.message !== "") {
          return e.message;
        } else {
          return `Неизвестна грешка за превозно средство ${driver.sl_number}`;
        }
      });
      if (errorMessages.length > 0) {
        setErrors(errorMessages);
      } else {
        setErrors([
          `Неизвестна грешка за превозно средство ${driver.sl_number}`,
        ]);
      }
    },
    []
  );

  const clearErrors = useCallback(() => setErrors([]), []);

  const dropDriver = useCallback(
    (driver: ElectroRosterDriver) => {
      if (isReadOnly) {
        return;
      }

      console.log("Drop on drivers", driver, rosterCars, selectedDate);

      if (driver.type === "driver") {
        const fromCars = rosterCars.filter(
          (car) =>
            car.shift1?.driver?.driver_id === driver.driver_id ||
            car.shift2?.driver?.driver_id === driver.driver_id
        );
        for (const car of fromCars) {
          console.log("Dropped", driver, "from", car);

          if (car.shift1?.driver?.driver_id === driver.driver_id) {
            callWithErrors(
              electroRemoveDriverFromShift,
              {
                driver_id: driver.schedule_id,
                date: selectedDate,
                shift_type: 1,
                shift_id: car.shift1.shift.shift.workshift.id,
              },
              driver,
              createErrors,
              clearErrors
            );
          }

          if (car.shift2?.driver?.driver_id === driver.driver_id) {
            callWithErrors(
              electroRemoveDriverFromShift,
              {
                driver_id: driver.schedule_id,
                date: selectedDate,
                shift_type: 2,
                shift_id: car.shift2.shift.shift.workshift.id,
              },
              driver,
              createErrors,
              clearErrors
            );
          }
        }
      } else {
        console.warn("Dropped unknown element:", driver);
      }
    },
    [
      clearErrors,
      createErrors,
      electroRemoveDriverFromShift,
      isReadOnly,
      rosterCars,
      selectedDate,
    ]
  );

  return { dropDriver, errors, clearErrors };
}

export function ElectroDroppableDriversContainer({
  roster,
  title,
  selectedDate,
  accept,
  isReadOnly,
  isPreliminaryReady,
  selectedLines,
  selectedVehicleTypes,
  saveComment,
  saveShift,
  onDrop,
}: {
  roster: ElectroRoster;
  title?: string;
  selectedDate: string;
  accept: string[];
  isReadOnly: boolean;
  isPreliminaryReady: boolean;
  selectedLines: readonly number[];
  selectedVehicleTypes: readonly number[];
  saveComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  onDrop: (driver: ElectroRosterDriver) => void;
}) {
  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [accept, onDrop]
  );
  const isActive = isOver && canDrop;

  const [panelHeight, setPanelHeight] = useState(100);
  const [currentPanelHeight, setCurrentPanelHeight] = useState(100);
  const handleDrag = useCallback(
    (event: "start" | "move" | "end", delta: { x: number; y: number }) => {
      if (event === "move") {
        setCurrentPanelHeight(panelHeight + delta.y);
      } else if (event === "end") {
        setCurrentPanelHeight(panelHeight + delta.y);
        setPanelHeight(panelHeight + delta.y);
      }
    },
    [panelHeight]
  );

  const filteredDrivers = useMemo(
    () =>
      roster.unusedRosterDrivers.filter((driver) => {
        const allowedVehicleType =
          driver.skillVehicleTypes.length === 0 ||
          selectedVehicleTypes.length === 0 ||
          driver.skillVehicleTypes.filter((svt) =>
            selectedVehicleTypes.includes(svt)
          ).length > 0;

        if (selectedLines.length > 0) {
          const yesterday = moment(selectedDate, "YYYY-MM-DD")
            .subtract(1, "day")
            .format("DD.MM.YYYY");
          const tasks =
            driver.old_daytasks.find((t) => t.date === yesterday)?.tasks || [];

          const differentLineTasks = tasks.filter(
            (t) =>
              (t.line_id || t.line_id === 0) &&
              !selectedLines.includes(t.line_id)
          );
          const sameLineTasks = tasks.filter(
            (t) =>
              (t.line_id || t.line_id === 0) &&
              selectedLines.includes(t.line_id)
          );
          const allowedLine =
            sameLineTasks.length > 0 || differentLineTasks.length === 0;

          return allowedVehicleType && allowedLine;
        } else {
          return allowedVehicleType;
        }
      }),
    [
      roster.unusedRosterDrivers,
      selectedDate,
      selectedLines,
      selectedVehicleTypes,
    ]
  );

  return (
    <ItemContainer
      ref={drop}
      title={title}
      style={{
        minHeight: currentPanelHeight,
        backgroundColor: isActive ? alpha(green[100], 0.25) : undefined,
      }}
      onDragTitle={handleDrag}
      headerSx={{
        backgroundColor: filteredDrivers.length > 0 ? red[800] : grey[300],
        "& .MuiCardHeader-title": {
          color: filteredDrivers.length > 0 ? "white" : undefined,
        },
      }}
    >
      <Grid container sx={{ pb: 2 }}>
        {filteredDrivers.map((driver) =>
          isReadOnly || !isPreliminaryReady ? (
            <ElectroDriverItem
              key={`driver-${driver.driver_id}`}
              isReadOnly={isReadOnly}
              driver={driver}
              roster={roster}
              selectedDate={selectedDate}
              saveComment={saveComment}
              saveShift={saveShift}
            />
          ) : (
            <ElectroDraggableDriverItem
              key={`driver-${driver.driver_id}`}
              isReadOnly={isReadOnly}
              driver={driver}
              roster={roster}
              selectedDate={selectedDate}
              saveComment={saveComment}
              saveShift={saveShift}
            />
          )
        )}
      </Grid>
    </ItemContainer>
  );
}
