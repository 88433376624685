import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../../common/useProfile";
import { useGetDepotListQuery } from "../../data/api/hooks";
import { RootState } from "../../data/store";

export function ChangeDepotDialog({
  dialog,
  depot_id,
  changeDepotErrors,
  changeDepotBusy,
  setDepotId,
  onChangeDepot,
  onCloseDialog,
}: {
  dialog: {
    visible: boolean;
    sl_number: number;
    start_date: string;
    end_date: string | null;
  } | null;
  depot_id: number | null;
  changeDepotErrors: React.ReactNode | null;
  changeDepotBusy: boolean;
  setDepotId: (depot_id: number | null) => void;
  onChangeDepot: () => Promise<void>;
  onCloseDialog: () => void;
}) {
  // const { depots } = useDepotFilter();

  const profile = useProfile();
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );
  const allDepotNames = useGetDepotListQuery(selectedTransport, {
    skip: selectedTransport === "" || !profile,
  });
  const depotNames = useMemo(
    () =>
      allDepotNames.currentData
        ? allDepotNames.currentData.filter(
            (depot) =>
              profile &&
              selectedTransport !== "" &&
              depot.transport === selectedTransport &&
              selectedDepot !== depot.gps_depot_id &&
              Object.hasOwn(profile.all_depots, selectedTransport) &&
              profile.all_depots[selectedTransport].includes(depot.gps_depot_id)
          )
        : [],
    [allDepotNames.currentData, profile, selectedDepot, selectedTransport]
  );

  return (
    <form>
      <Dialog open={!!dialog?.visible} onClose={onCloseDialog} fullWidth>
        <DialogTitle>Командироване на водач</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Командироване на водач {dialog?.sl_number || ""}
          </DialogContentText>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="schedule-depot">Гараж</InputLabel>
            <Select
              labelId="schedule-depot"
              id="schedule-depot-select"
              disabled={!depotNames || depotNames.length === 0}
              value={depot_id || ""}
              label="Депо"
              onChange={(e) => setDepotId(e.target.value as number)}
            >
              <MenuItem key={-1} value={""} disabled>
                &mdash;
              </MenuItem>
              {depotNames.map((depot) => (
                <MenuItem key={depot.gps_depot_id} value={depot.gps_depot_id}>
                  {depot.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {changeDepotErrors && (
            <DialogContentText color="error" sx={{ mt: 4 }}>
              {changeDepotErrors}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} disabled={changeDepotBusy}>
            Отказ
          </Button>
          <Button
            onClick={() => onChangeDepot()}
            type="submit"
            disabled={changeDepotBusy || !depot_id}
          >
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
