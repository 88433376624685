import { red } from "@mui/material/colors";

export function DriverItemErrorIndicator({
  isVisible,
}: {
  isVisible?: boolean;
}) {
  if (isVisible) {
    return (
      <div
        style={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "0 0 15px 15px",
          borderColor: `transparent transparent ${red[700]} transparent`,
          borderRadius: 2,
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      />
    );
  } else {
    return <></>;
  }
}
