import { CSSProperties, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import { ErrorCellMarker, SelectedCellMarker } from "./CellMarkers";
import {
  CELL_WIDTH,
  DataCell,
  DataHeaderCell,
  DataRowBox,
} from "./TableElements";
import { TableCellRef, TableRowRef } from "./types";
import moment, { Moment } from "moment";
import { Theme } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { isFromAnotherDepot, mouseDwonHandler } from "./mouseDownHandler";
import { formatHours } from "./formatHours";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "./useScheduleParameters";
import { ScheduleDay, ScheduleParameters } from "../../data/api/types/schedule";

function isSameAutocolumn(
  driver_day: ScheduleDay | null | undefined,
  scheduleParameters: ScheduleParameters | null | undefined
) {
  return (
    !driver_day ||
    !scheduleParameters ||
    driver_day.autocolumn === scheduleParameters[4]
  );
}

function isSameDepot(
  driver_day: ScheduleDay | null | undefined,
  scheduleParameters: ScheduleParameters | null | undefined
) {
  return (
    !driver_day ||
    !scheduleParameters ||
    !driver_day.work_depot_id ||
    driver_day.work_depot_id === scheduleParameters[1]
  );
}

function createCellTitle(
  prefix: string | null | undefined,
  driver_day: ScheduleDay | null | undefined,
  scheduleParameters: ScheduleParameters | null | undefined
): string {
  const pref = prefix || "";
  if (!driver_day) {
    return pref;
  } else if (
    !isSameAutocolumn(driver_day, scheduleParameters) &&
    !isSameDepot(driver_day, scheduleParameters)
  ) {
    return (
      pref +
      `\n\nВодачът е в депо ${driver_day.work_depot_id}, автоколона ${driver_day.autocolumn} за посочената дата`
    );
  } else if (!isSameAutocolumn(driver_day, scheduleParameters)) {
    return (
      pref +
      `\n\nВодачът е в автоколона ${driver_day.autocolumn} за посочената дата`
    );
  } else if (!isSameDepot(driver_day, scheduleParameters)) {
    return (
      pref +
      `\n\nВодачът е в депо ${driver_day.work_depot_id} за посочената дата`
    );
  } else {
    return pref;
  }
}

export function DataRow({
  style,
  headerWidth,
  firstHeaderWidth,
  headers,
  row,
  rowIndex,
  today,
  isHoliday,
  theme,
  onContextMenu,
  onSelectionStart,
  onSelectionDrag,
  onSelectionEnd,
  setSelection,
}: {
  style: CSSProperties;
  headerWidth: number;
  firstHeaderWidth: number;
  headers: {
    title?: string;
    content?: ReactNode;
    subheader?: string | number;
    style?: CSSProperties;
  }[];
  row: TableRowRef | number;
  rowIndex: number;
  today: Moment;
  isHoliday: (day: string | moment.Moment) => boolean | undefined;
  theme: Theme;
  onContextMenu?: (
    mouseX: number,
    mouseY: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number,
    changeDepotOnly: boolean
  ) => void;
  onSelectionStart?: (
    mouseX: number,
    mouseY: number,
    mouseButton: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number
  ) => void;
  onSelectionDrag?: (mouse: {
    x: number;
    y: number;
    initialX: number;
    initialY: number;
  }) => void;
  onSelectionEnd?: (mouseX: number, mouseY: number) => void;
  setSelection: (selection: {
    row: number;
    startCell: number;
    endCell: number;
    initialMouseCoordinates: {
      x: number;
      y: number;
    };
  }) => void;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const selection = useSelector((state: RootState) => state.schedule.selection);
  const startRow = selection ? selection.row : -1;
  const startCell = selection
    ? Math.min(selection.startCell, selection.endCell)
    : -1;
  const endCell = selection
    ? Math.max(selection.startCell, selection.endCell)
    : -1;
  const { scheduleParameters } = useScheduleParameters();

  if (typeof row === "number") {
    return (
      <DataRowBox style={style}>
        {headers.map((header, idx) => (
          <DataHeaderCell
            key={idx}
            tabIndex={0}
            title={header.title}
            width={idx === 0 ? firstHeaderWidth : headerWidth}
            firstColumnWidth={firstHeaderWidth}
            fixLeftIdx={idx}
            style={header.style}
            data-cell-type="header"
          >
            <div
              style={{ fontSize: theme.typography.fontSize, lineHeight: 1.4 }}
            >
              {header.content}
            </div>
            <div
              style={{
                fontSize: theme.typography.fontSize * 0.9,
                lineHeight: 1,
                color: theme.palette.primary.light,
              }}
            >
              {header.subheader}
            </div>
          </DataHeaderCell>
        ))}
        {[...Array(row)].map((_n, idx) => (
          <DataCell key={idx} data-cell-type="status" tabIndex={0}>
            &nbsp;
          </DataCell>
        ))}
      </DataRowBox>
    );
  } else if (row.rowType === "driver") {
    return (
      <DataRowBox style={style}>
        {headers.map((header, idx) => (
          <DataHeaderCell
            key={idx}
            tabIndex={0}
            title={header.title}
            width={idx === 0 ? firstHeaderWidth : headerWidth}
            firstColumnWidth={firstHeaderWidth}
            fixLeftIdx={idx}
            style={header.style}
            data-cell-type="header"
          >
            <div
              style={{ fontSize: theme.typography.fontSize, lineHeight: 1.4 }}
            >
              {header.content}
            </div>
            <div
              style={{
                fontSize: theme.typography.fontSize * 0.9,
                lineHeight: 1,
                color: theme.palette.primary.light,
              }}
            >
              {header.subheader}
            </div>
          </DataHeaderCell>
        ))}
        {row.cells.map((cell, cellIndex) => (
          <DataCell
            key={cell.id}
            tabIndex={0}
            data-cell-type="status"
            title={createCellTitle(
              cell.title,
              cell.driver_day,
              scheduleParameters
            )}
            isHoliday={isHoliday(cell.date)}
            background={cell.backgroundColor}
            foreground={cell.color}
            style={{
              opacity:
                !isSameAutocolumn(cell.driver_day, scheduleParameters) ||
                !isSameDepot(cell.driver_day, scheduleParameters)
                  ? 0.3
                  : undefined,
              position: "relative",
              ...(cell.date.isSameOrBefore(today, "day")
                ? {
                    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
                  }
                : undefined),
            }}
            onContextMenu={(e) => {
              if (
                onContextMenu &&
                !isReadOnly &&
                scheduleParameters &&
                isSameAutocolumn(cell.driver_day, scheduleParameters) &&
                !isFromAnotherDepot(scheduleParameters, row, cell)
              ) {
                e.preventDefault();
                onContextMenu(
                  e.clientX,
                  e.clientY,
                  row,
                  cell,
                  rowIndex,
                  cellIndex,
                  !isSameDepot(cell.driver_day, scheduleParameters)
                );
              }
            }}
            onMouseDown={(e) => {
              if (
                !isReadOnly &&
                isSameAutocolumn(cell.driver_day, scheduleParameters)
              ) {
                mouseDwonHandler(
                  e,
                  scheduleParameters,
                  row,
                  rowIndex,
                  cell,
                  cellIndex,
                  setSelection,
                  onSelectionStart,
                  onSelectionDrag,
                  onSelectionEnd
                );
              }
            }}
          >
            <ErrorCellMarker
              cellIndex={cellIndex}
              cellCount={row.cells.length}
              rowIndex={rowIndex}
              isFirst
              isLast
              insideMarker
              isWarning={cell.cellErrorKind === "warning"}
              hidden={!cell.cellErrorKind}
            />
            <SelectedCellMarker
              cellIndex={cellIndex}
              cellCount={row.cells.length}
              rowIndex={rowIndex}
              isFirst={cellIndex === 0 || cellIndex === startCell}
              isLast={
                cellIndex === row.cells.length - 1 || cellIndex === endCell
              }
              zIndexOffset={1}
              hidden={
                !(
                  rowIndex === startRow &&
                  cellIndex >= startCell &&
                  cellIndex <= endCell
                )
              }
            />
            <div
              style={{
                position: "relative",
                textDecoration: cell.driver_day.expired_docs
                  ? "line-through"
                  : undefined,
              }}
            >
              {cell.content ? (
                <div>{cell.content}</div>
              ) : cell.driver_day.expired_docs && !cell.content ? (
                <>ИД</>
              ) : (
                <>&nbsp;</>
              )}

              {((cell.driver_day.workshift.work_seconds &&
                cell.driver_day.workshift.work_seconds > 0) ||
                (cell.driver_day.day_workseconds_by_roster &&
                  cell.driver_day.day_workseconds_by_roster > 0)) &&
              cell.driver_day.state !== 9 ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "transparent",
                    //color: theme.palette.text.secondary,
                    transform: "scale(0.75) translate(90%, -40%)",
                    transformOrigin: "100% 0%",
                    fontWeight: "bold",
                    //zIndex: theme.zIndex.drawer + 1,
                  }}
                >
                  {formatHours(
                    cell.driver_day.workshift.work_seconds ||
                      cell.driver_day.day_workseconds_by_roster
                  )}
                </div>
              ) : undefined}

              {cell.driver_day.work_week_workseconds !== null ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    //backgroundColor: "transparent",
                    //paddingX: 0.5,
                    backgroundColor: "rgba(128, 128, 255, 0.3)",
                    //color: theme.palette.text.secondary,
                    color:
                      cell.driver_day.work_week_workseconds / 3600 >= 56
                        ? red[900]
                        : green[900],
                    transform: "scale(0.75) translate(50%, 65%)",
                    transformOrigin: "50% 50%",
                    fontWeight: "bold",
                    //zIndex: theme.zIndex.drawer + 1,
                  }}
                >
                  {formatHours(cell.driver_day.work_week_workseconds, 0)}
                </div>
              ) : undefined}
            </div>

            {cell.driver_day.comment ? (
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "15px 15px 0 0",
                  borderColor: `${green[600]} transparent transparent transparent`,
                  borderRadius: 0,
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 1,
                }}
              />
            ) : undefined}
          </DataCell>
        ))}
      </DataRowBox>
    );
  } else {
    //console.log("Render vehicle type row:", row);
    return (
      <DataRowBox style={style}>
        <DataHeaderCell
          tabIndex={0}
          width={10}
          firstColumnWidth={10}
          fixLeftIdx={0}
          style={{
            backgroundColor: theme.palette.grey[300],
            borderRight: "none",
          }}
          data-cell-type="header"
        >
          <div
            style={{
              fontSize: theme.typography.fontSize * 1.25,
              fontWeight: "bold",
              lineHeight: 1.4,
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              alignSelf: "start",
            }}
          >
            <em style={{ marginRight: theme.spacing(2) }}>Марка:</em>
            <span style={{ whiteSpace: "nowrap" }}>{row.description}</span>
          </div>
        </DataHeaderCell>
        <DataHeaderCell
          width={
            firstHeaderWidth +
            (row.headerColumns - 1) * headerWidth +
            row.dayColumns * CELL_WIDTH -
            10
          }
          style={{
            backgroundColor: theme.palette.grey[300],
          }}
        />
      </DataRowBox>
    );
  }
}
