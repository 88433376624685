import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Portal,
} from "@mui/material";
import { useSubmenu } from "../../hooks/useSubmenu";
import { NavLink } from "../../common/NavLink";
import { SubOutlet } from "../SubOutlet";
import { DepartureBoard } from "@mui/icons-material";
import { useSchedulesConnection } from "../../data/schedules/useSchedulesConnection";
import { useVehiclesConnection } from "../../data/schedules/useVehiclesConnection";

export function RosterPage() {
  const { submenu } = useSubmenu();

  useSchedulesConnection();
  useVehiclesConnection();

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Portal container={submenu}>
        <List
          dense
          subheader={
            <ListSubheader component="div" inset>
              Home options
            </ListSubheader>
          }
        >
          <ListItemButton to="/roster/" component={NavLink}>
            <ListItemIcon>
              <DepartureBoard />
            </ListItemIcon>
            <ListItemText primary="Наряд" />
          </ListItemButton>
        </List>
      </Portal>

      <SubOutlet />
    </div>
  );
}
