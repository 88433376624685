import { useCallback } from "react";
import { useSelector } from "react-redux";
//import { useSelector } from "react-redux";
import {
  setIgnoredSelection,
  setScheduleSelection,
  startScheduleSelection,
  updateScheduleSelection,
} from "../../data/scheduleSlice";
import { RootState, useAppDispatch } from "../../data/store";
import { TableCellRef, TableRowRef } from "./types";

type MouseDrag = {
  x: number;
  y: number;
  initialX: number;
  initialY: number;
};

export function useCellSelection() {
  const dispatch = useAppDispatch();
  const selection = useSelector((state: RootState) => state.schedule.selection);

  const setSelection = useCallback(
    (selection: {
      row: number;
      startCell: number;
      endCell: number;
      initialMouseCoordinates: {
        x: number;
        y: number;
      };
    }) => {
      dispatch(setScheduleSelection(selection));
    },
    [dispatch]
  );

  const onSelectionStart = useCallback(
    (
      mouseX: number,
      mouseY: number,
      mouseButton: number,
      _row: TableRowRef,
      _cell: TableCellRef,
      rowIndex: number,
      cellIndex: number
    ) => {
      if (mouseButton === 2) {
        if (
          selection?.row !== rowIndex ||
          Math.min(selection?.startCell, selection?.endCell) > cellIndex ||
          Math.max(selection?.startCell, selection?.endCell) < cellIndex
        ) {
          const newSelection = {
            row: rowIndex,
            startCell: cellIndex,
            initialMouseCoordinates: { x: mouseX, y: mouseY },
          };
          //console.log("Selection:", newSelection);
          dispatch(startScheduleSelection(newSelection));
        } else {
          //console.log("Ignore this selection");
          dispatch(setIgnoredSelection(true));
        }
      } else if (mouseButton === 0) {
        const newSelection = {
          row: rowIndex,
          startCell: cellIndex,
          initialMouseCoordinates: { x: mouseX, y: mouseY },
        };
        //console.log("Selection:", newSelection);
        dispatch(startScheduleSelection(newSelection));
      } else {
        dispatch(setIgnoredSelection(true));
      }
    },
    [dispatch, selection?.endCell, selection?.row, selection?.startCell]
  );

  const onSelectionDrag = useCallback<(mouse: MouseDrag) => void>(
    (mouse) => {
      //console.log("Selection drag:", mouseX);
      dispatch(updateScheduleSelection(mouse));
    },
    [dispatch]
  );

  const onSelectionEnd = useCallback(() => {
    //console.log("Selection end:", mouseX, mouseY);
    dispatch(setIgnoredSelection(false));
  }, [dispatch]);

  return { onSelectionStart, onSelectionDrag, onSelectionEnd, setSelection };
}
