import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { Schedule } from "../../data/api/types/schedule";
import { updateSelectedDate } from "../../data/filtersSlice";
import { RootState, useAppDispatch } from "../../data/store";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { TransportSelect } from "../../filters/TransportSelect";
import { useVehicleTypesList } from "../schedule/useVehicleTypesList";
import {
  ElectroSpecialRosterShift,
  ElectroTimetableRosterShift,
} from "./types/ElectroRoster";
import { RosterShift } from "./types/Roster";

export function RosterFilter({
  schedule,
  selectedVehicleTypes,
  selectedLines,
  shifts,
  setSelectedVehicleTypes,
  setSelectedLines,
}: {
  schedule: Schedule | undefined;
  selectedVehicleTypes: number[];
  selectedLines: number[];
  shifts:
    | readonly (
        | RosterShift
        | ElectroTimetableRosterShift
        | ElectroSpecialRosterShift
      )[]
    | null
    | undefined;
  setSelectedVehicleTypes: (types: number[]) => void;
  setSelectedLines: (lines: number[]) => void;
}) {
  const dispatch = useAppDispatch();

  const { needsTransportSelect, needsDepotSelect, needsAutocolumnSelect } =
    useScheduleParameters();
  const { vehicleTypes, vehicleTypesLoading, vehicleTypesError } =
    useVehicleTypesList(schedule);

  const lines = useMemo(
    () =>
      Object.values(
        (shifts || []).reduce<
          Record<number, { id: number; name: string; order: number | null }>
        >(
          (acc, shift) =>
            shift.type !== "timetable" || Object.hasOwn(acc, shift.shift.line)
              ? acc
              : {
                  ...acc,
                  [shift.shift.line]: {
                    id: shift.shift.line,
                    name: shift.shift.lineName,
                    order:
                      !shift.shift.lineOrder && shift.shift.lineOrder !== 0
                        ? null
                        : shift.shift.lineOrder,
                  },
                },
          {}
        )
      ).sort((a, b) => {
        if (!a.order && !b.order && a.order !== 0 && b.order !== 0) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        } else if (!a.order && a.order !== 0) {
          return 1;
        } else if (!b.order && b.order !== 0) {
          return -1;
        } else {
          return a.order - b.order;
        }
      }),
    [shifts]
  );

  const selectedDateStr = useSelector(
    (state: RootState) => state.filters.selectedDate
  );
  const selectedDate = useMemo(
    () => (selectedDateStr ? moment(selectedDateStr, "YYYY-MM-DD") : null),
    [selectedDateStr]
  );

  return (
    <Container sx={{ mt: 2, mb: 4 }}>
      <Grid container spacing={2} columns={14}>
        {needsTransportSelect ? (
          <Grid sm={14} md={3}>
            <TransportSelect />
          </Grid>
        ) : (
          <></>
        )}

        {needsDepotSelect ? (
          <Grid sm={14} md={3}>
            <DepotSelect />
          </Grid>
        ) : (
          <></>
        )}

        {needsAutocolumnSelect ? (
          <Grid sm={14} md={1}>
            <AutoColumnSelect />
          </Grid>
        ) : (
          <></>
        )}

        <Grid sm={14} md={2}>
          <DatePicker
            value={selectedDate}
            onChange={(e) =>
              e &&
              e.isValid() &&
              dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
            }
            label="Дата"
            views={["year", "month", "day"]}
            openTo="day"
            sx={{ m: 1, width: "100%" }}
            slotProps={{
              textField: {
                variant: "standard",
              },
            }}
          />
        </Grid>

        <Grid sm={14} md={3}>
          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
            <InputLabel id="roster-vehicletype">Марка</InputLabel>
            <Select
              multiple
              labelId="roster-vehicletype"
              id="roster-vehicletype-select"
              value={selectedVehicleTypes}
              label="Марка"
              renderValue={(selected) =>
                selected
                  .map(
                    (vtIdx) =>
                      vehicleTypes.find((vt) => vt.id === vtIdx)?.description
                  )
                  .filter((l) => l && l.trim() !== "")
                  .join(", ")
              }
              onChange={(e) => {
                if (typeof e.target.value === "string") {
                  setSelectedVehicleTypes([parseInt(e.target.value, 10)]);
                } else if (typeof e.target.value === "number") {
                  setSelectedVehicleTypes([e.target.value]);
                } else {
                  setSelectedVehicleTypes(e.target.value);
                }
              }}
            >
              {vehicleTypesLoading ? (
                <MenuItem
                  value={-1000}
                  disabled
                  sx={{ placeContent: "center" }}
                >
                  <CircularProgress />
                </MenuItem>
              ) : vehicleTypesError ? (
                <Box sx={{ placeContent: "center", px: 2, pt: 1 }}>
                  <Alert color="error">Грешка при зареждане на марки</Alert>
                </Box>
              ) : (
                vehicleTypes.map((vehicleType) => (
                  <MenuItem key={vehicleType.id} value={vehicleType.id}>
                    <Checkbox
                      checked={selectedVehicleTypes.includes(vehicleType.id)}
                    />
                    <ListItemText primary={vehicleType.description} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid sm={14} md={2}>
          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
            <InputLabel id="roster-line">Линия</InputLabel>
            <Select
              multiple
              labelId="roster-line"
              id="roster-line-select"
              value={selectedLines}
              label="Линия"
              renderValue={(selected) =>
                selected
                  .map(
                    (lineIdx) => lines.find((line) => line.id === lineIdx)?.name
                  )
                  .filter((l) => l && l.trim() !== "")
                  .join(", ")
              }
              onChange={(e) => {
                if (typeof e.target.value === "string") {
                  setSelectedLines([parseInt(e.target.value, 10)]);
                } else if (typeof e.target.value === "number") {
                  setSelectedLines([e.target.value]);
                } else {
                  setSelectedLines(e.target.value);
                }
              }}
            >
              {lines.length === 0 ? (
                <Box sx={{ placeContent: "center", px: 2, pt: 1 }}>
                  <Alert color="error">Грешка при зареждане на линии</Alert>
                </Box>
              ) : (
                lines.map((line) => (
                  <MenuItem key={line.id} value={line.id}>
                    <Checkbox checked={selectedLines.includes(line.id)} />
                    <ListItemText primary={line.name} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}
