import { green, red } from "@mui/material/colors";

export function DriverItemCommentIndicator({
  comment,
  isRoster,
  offset,
  isUnassigned,
}: {
  comment?: string | null;
  isRoster: boolean;
  offset?: [number, number];
  isUnassigned?: boolean;
}) {
  if (comment && comment.trim() !== "") {
    return (
      <div
        style={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "15px 15px 0 0",
          borderColor: `${
            isRoster ? red[800] : green[800]
          } transparent transparent transparent`,
          borderRadius: 2,
          position: "absolute",
          left: (isUnassigned ? -6 : 0) + (offset ? offset[0] : 0),
          top: 0 + (offset ? offset[1] : 0),
          pointerEvents: "none",
        }}
      />
    );
  } else {
    return <></>;
  }
}
