import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box, Container, IconButton, Portal, styled } from "@mui/material";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow as TableRowMUI,
  TextField,
} from "@mui/material";

import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { QueryError } from "../QueryError";
import {
  useGetDriversListQuery,
  useGetRosterForDriversQuery,
} from "../../data/api/hooks";
import { MonthPicker } from "../../filters/MonthPicker";
import {
  DiverPersonalAccountDay,
  PersonalAccountColumns,
} from "./useDriverPersonalAccount";
import { formatHoursMinutes } from "../../components/schedule/formatHours";
import { useDriverPersonalAcountPdfExporter } from "../../printing/useDriverPersonalAccountPrint";
import { useSubmenu } from "../../hooks/useSubmenu";
import { Print } from "@mui/icons-material";

const TableRow = styled(TableRowMUI)(() => ({
  height: 23,
}));

const TableCell = styled(TableCellMUI)(() => ({
  padding: "0px 16px",
}));

function StickyHeadTable({ rows }: { rows: DiverPersonalAccountDay[] }) {
  console.log("Rows:", rows);
  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table
          id="driver_personal_account"
          stickyHeader
          aria-label="stickyHeader"
        >
          <TableHead>
            <TableRowMUI>
              {PersonalAccountColumns.map((column) => (
                <TableCellMUI
                  key={column.id}
                  align={column.align}
                  //style={{ width: column.width }}
                  sx={{ width: column.width }}
                >
                  {column.label}
                </TableCellMUI>
              ))}
            </TableRowMUI>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              return (
                <TableRow hover tabIndex={-1} key={idx}>
                  <TableCell>{moment(row.daytask_date).format("L")}</TableCell>
                  {row.working_hours ? (
                    <React.Fragment>
                      <TableCell align="left">
                        {row.task_line !== "" ? row.task_line : row.day_task}
                      </TableCell>
                      <TableCell align="center">
                        {row.task_car > 0 ? row.task_car : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.task_shift > 0 ? row.task_shift : ""}
                      </TableCell>
                      <TableCell align="center">
                        {row.task_veh1_id && row.task_veh1_id > 0
                          ? row.task_veh1_id
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {/* {moment
                          .utc(
                            moment
                              .duration(row.start_shitf, "seconds")
                              .as("milliseconds")
                          )
                          .format("HH:mm")} */}
                        {row.start_shitf}
                      </TableCell>
                      <TableCell align="center">
                        {/* {moment
                          .utc(
                            moment
                              .duration(row.end_shift, "seconds")
                              .as("milliseconds")
                          )
                          .format("HH:mm")} */}
                        {row.end_shift}
                      </TableCell>
                      <TableCell align="center">
                        {moment
                          .utc(
                            moment
                              .duration(row.working_hours, "seconds")
                              .as("milliseconds")
                          )
                          .format("HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {/* {moment
                          .utc(
                            moment
                              .duration(row.inteshift_time, "seconds")
                              .as("milliseconds")
                          )
                          .format("HH:mm")} */}
                        {formatHoursMinutes(row.inteshift_time)}
                      </TableCell>
                    </React.Fragment>
                  ) : (
                    <TableCell colSpan={8}>
                      {row.state_id !== 15 ? row.state : "Почивка"}
                    </TableCell>
                  )}
                  {/* {PersonalAccountColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })} */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function DriverPersonalAccountReport() {
  const { rightToolbarMenu } = useSubmenu();

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const [selectedDriver, setSelectedDriver] = useState<{
    id: number;
    label: string;
  } | null>(null);

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const { exportPdf, isLoading: exportPdfLoading } =
    useDriverPersonalAcountPdfExporter();

  const {
    data: dataDrivers,
    isLoading: isDriversLoading,
    isError: isDriversError,
  } = useGetDriversListQuery(
    { transport: selectedTransport, garage: selectedDepot },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const selMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );

  const selectedMonth = useMemo(
    () => (selMonth && selMonth !== "" ? moment(selMonth) : null),
    [selMonth]
  );

  // const { data, isLoading, isError } = useGetDriverPersonalAccountQuery(
  //   {
  //     transport: selectedTransport,
  //     garage: selectedDepot,
  //     avtocolona: selectedAvtoColumn,
  //     driver_id: selectedDriver ? selectedDriver.id : null,
  //     year: selectedMonth ? selectedMonth.year() : 0,
  //     month: selectedMonth ? selectedMonth.month() : 0,
  //   },
  //   {
  //     refetchOnMountOrArgChange: 1,
  //     skip: !(
  //       selectedTransport !== "" &&
  //       selectedDepot !== -1 &&
  //       selectedDriver !== null
  //     ),
  //   }
  // );

  const selectedDate = useMemo(
    () => (selectedMonth ? selectedMonth.format("YYYY-MM-DD") : null),
    [selectedMonth]
  );

  // const { roster, isRosterError, isRosterLoading, isRosterFetching } =
  //   useRosterData(selectedDate);

  const getRosterForDriversQueryDataMonth = useMemo(
    () => ({
      transport: selectedTransport,
      garage: selectedDepot,
      month: moment(selectedDate).month() + 1,
      year: moment(selectedDate).year(),
      avtocolona: selectedAvtoColumn,
    }),
    [selectedAvtoColumn, selectedDate, selectedDepot, selectedTransport]
  );

  const {
    data: roster,
    isError: isRosterError,
    isLoading: isRosterLoading,
    isFetching: isRosterFetching,
  } = useGetRosterForDriversQuery(getRosterForDriversQueryDataMonth);

  console.log("Roster", roster);

  const driver_roster = useMemo<DiverPersonalAccountDay[] | undefined>(() => {
    if (roster && selectedDriver) {
      const data = roster.data.drivers
        .find((d) => d.sl_number.toString() === selectedDriver.label)
        ?.driver_days.filter(
          (day) => moment(day.day_in_month).month() === selectedMonth?.month()
        )
        .flatMap((d) => {
          const rows =
            d.daytasks.length > 0
              ? d.daytasks.map((dt) => {
                  const row: DiverPersonalAccountDay = {
                    daytask_date: d.day_in_month,
                    task_line: dt.linename ? dt.linename : "",
                    task_car: dt.car_no ? dt.car_no : 0,
                    task_shift: dt.shift_type ? dt.shift_type : 0,
                    task_veh1_id: dt.veh1_id,
                    start_shitf: moment(dt.start_time).format("HH:mm"),
                    end_shift: moment(dt.end_time).format("HH:mm"),
                    working_hours: dt.work_seconds,
                    inteshift_time: d.intershift_times?.find(
                      (it) => it.current_task_line === dt.linename
                    )?.time,
                    day_task: "",
                    day_type: null,
                    state: d.state_name,
                    state_id: d.state,
                    task_line_id: -1,
                  };
                  return row;
                })
              : {
                  daytask_date: d.day_in_month,
                  task_line: "",
                  task_car: 0,
                  task_shift: 0,
                  task_veh1_id: 0,
                  start_shitf: "",
                  end_shift: "",
                  working_hours: 0,
                  inteshift_time: 0,
                  day_task: "",
                  day_type: null,
                  state: d.state_name,
                  state_id: d.state,
                  task_line_id: -1,
                };
          return rows;
        });
      return data ? data : [];
    } else [];
  }, [roster, selectedDriver, selectedMonth]);

  console.log("driver_roster", driver_roster);

  useEffect(() => {
    setSelectedDriver(null);
  }, [selectedAvtoColumn]);

  const drivers = useMemo(() => {
    return dataDrivers?.drivers
      .filter((d) => d.avtocolona === selectedAvtoColumn && d.state <= 2)
      .map((d) => ({ label: d.sl_number.toString(), id: d.driver_id }));
  }, [dataDrivers?.drivers, selectedAvtoColumn]);

  const totalWorkHours = useMemo(() => {
    return driver_roster
      ? driver_roster.reduce(
          (acc, x) => (x.working_hours ? (acc += x.working_hours) : acc),
          0
        )
      : 0;
  }, [driver_roster]);
  // const totalDriverCount = useMemo(() => {
  //   const state_drivers = data?.data.drivers_schedule
  //     ?.map((d) => ({
  //       sl_number: d.sl_number,
  //       state: d.driver_days.find(
  //         (a) => a.day_in_month === selectedDate.format("yyyy-MM-DD")
  //       )?.state,
  //     }))
  //     .reduce((acc, x) => {
  //       x.state !== 13 ? (acc += 1) : acc;
  //       return acc;
  //     }, 0);
  //   return state_drivers;
  // }, [data?.data.drivers_schedule, selectedDate]);

  // console.log("Data: ", data);

  // console.log(
  //   "Map: ",
  //   data?.data.drivers_schedule?.map((d) => ({
  //     sl_number: d.sl_number,
  //     state: d.driver_days.find(
  //       (a) => a.day_in_month === selectedDate.format("yyyy-MM-DD")
  //     )?.state,
  //   }))
  // );
  // console.log(rows);
  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() => {
            const driver_name = dataDrivers?.drivers.find(
              (d) => d.sl_number === selectedDriver?.id
            )?.name;
            exportPdf(
              selectedMonth
                ? selectedMonth.format("MMMM YYYY").toUpperCase()
                : "",
              selectedDriver ? selectedDriver.label : "",
              driver_name ? driver_name : ""
            );
          }}
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={2}>
              <Autocomplete
                sx={{ m: 1 }}
                key={selectedDriver?.id}
                value={selectedDriver ? selectedDriver : undefined}
                options={drivers ? drivers : []}
                renderInput={(params) => (
                  <TextField {...params} label="Водач" variant="standard" />
                )}
                onChange={(_, data) => {
                  if (data) setSelectedDriver(data);
                }}
                noOptionsText={""}
                // getOptionLabel={(option) => (option ? option.label : "")}
                //isOptionEqualToValue={(option, value) => option === value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                autoSelect
                disabled={isDriversLoading}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MonthPicker />
            </Grid>
          </Grid>
          {driver_roster &&
            !isDriversError &&
            !isDriversLoading &&
            !isRosterError &&
            !isRosterLoading &&
            selectedDriver && (
              <Grid container mt={1} mb={1} spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
                >
                  Обща заработка:{" "}
                  {totalWorkHours ? (totalWorkHours / 60 / 60).toFixed(2) : "0"}{" "}
                  часа
                </Grid>
              </Grid>
            )}

          {(isDriversError || isRosterError) && (
            <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />
          )}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            //open={isLoading}
            open={false}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ py: 4, height: "100%" }}>
            {!isDriversError &&
              !isRosterError &&
              selectedDriver &&
              driver_roster && <StickyHeadTable rows={driver_roster} />}
          </Container>
        </Box>
      </Box>
    </>
  );
}
