import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { useCallback, useState } from "react";
import { DayRosterData } from "../pages/reports/roster_report/helpers";
import { ReportPrinter } from "./ReportPrinter";
import moment, { Moment } from "moment";

export function usePrintSeparateCarsPdfExporterer() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      rows: {
        line_name: string;
        car_no: number;
        end_time1: string;
        vehicle1: string | number;
        vehicle2: string | number;
        start_time2: string;
      }[],
      selectedDate: string
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter();
        printer.printSpace(5);

        printer.setFont("bold", 14, 1.2);
        printer.printText(
          `АП "${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }", Автокoлона ${selectedAutoColumn}`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.setFont("bold", 12, 1.2);
        printer.printText(
          `ИЗНЕСЕНИ КОЛИ ЗА ДАТА: ${moment(selectedDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )} ${moment(selectedDate, "YYYY-MM-DD").format("dddd")}`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.printSpace(5);

        printer.autoTable({
          styles: {
            halign: "center",
            valign: "top",
            font: "Roboto",

            //lineWidth: 0,
            fontSize: 11,
            cellPadding: 0,
            lineColor: [0, 0, 0],
            //lineWidth: 0.25,
            lineWidth: 0,
          },
          bodyStyles: {
            cellPadding: 4,
          },
          columnStyles: {
            0: { cellWidth: 35 },
            1: { cellWidth: 35 },
            2: {
              cellWidth: 45,
            },
            3: { cellWidth: 45 },
            4: { cellWidth: 50 },
            5: { cellWidth: 90 },
            6: { cellWidth: 45 },
            7: { cellWidth: 45 },
            8: { cellWidth: 45 },
            9: {},
          },
          body: rows as any,
          columns: [
            {
              dataKey: "line_name",
              header: "Линия",
            },
            {
              dataKey: "car_no",
              header: "Кола",
            },
            {
              dataKey: "end_time1",
              header: "Час при биране",
            },
            {
              dataKey: "vehicle1",
              header: "Кола 1 смяна",
            },
            {
              header: "Час при биране",
            },
            {
              header: "Печат",
            },
            {
              dataKey: "vehicle2",
              header: "Кола 2 смяна",
            },
            {
              dataKey: "start_time2",
              header: "Час из лизане",
            },
            {
              header: "Час из лизане",
            },
            {
              header: "Печат",
            },
          ],
          willDrawCell: (cell) => {
            if (cell.section === "head") {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);

              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );

              printer.doc.line(
                cell.cell.x,
                cell.cell.y + cell.cell.height,
                cell.cell.x + cell.cell.width,
                cell.cell.y + cell.cell.height
              );
              printer.doc.setLineWidth(oldWidth);
            } else if (cell.section === "foot") {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);
              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );
              printer.doc.setLineWidth(oldWidth);
            }
          },
        });

        printer.save(
          `Изнесени коли-${selectedDate}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [depots, selectedAutoColumn, selectedDepot]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
