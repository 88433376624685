import moment, { Moment } from "moment";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  GarageSettings,
  getSettingsByGarage,
} from "../common/useGarageSettings";
import { useProfile } from "../common/useProfile";
import { useLazyGetFirstSerialNumberQuery } from "../data/api/hooks";
import { Transport } from "../data/api/types/driver";
import {
  DayTask,
  ScheduleDay,
  ScheduleDriver,
} from "../data/api/types/schedule";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { useTransportFilter } from "../filters/useTransportFilter";
import {
  RosterDriver,
  RosterShift,
  RosterVehicle,
} from "../pages/roster/types/Roster";
import {
  getSpecialShiftDetails,
  isExportedCar,
  LineDocuments,
  doCorrectCarTimes,
  groupDaytasksByShift,
  getAllDaytasks,
  getLines,
  PageDataShift,
  generateShiftData,
  getNightShiftDetails,
} from "./printJourneyListHelpers";

const DEPOT_ABBREVIATIONS: Record<Transport, Record<number, string>> = {
  A: {
    9: "ЗЕМ",
    10: "МАЛ",
    11: "ДРУ",
  },
  TM: {
    2: "КРП",
    3: "БАН",
    4: "ИСК",
  },
  TB: {
    5: "ИСК",
    6: "НАД",
  },
};

const DEPOT_NAMES: Record<Transport, Record<number, string>> = {
  A: {
    9: 'АП "ЗЕМЛЯНЕ"',
    10: 'АП "МАЛАШЕВЦИ"',
    11: 'АП "ДРУЖБА"',
  },
  TM: {
    2: 'Депо "Красна Поляна"',
    3: 'Депо "Банишора"',
    4: 'Депо "Искър"',
  },
  TB: {
    5: 'Депо "Искър"',
    6: 'Депо "Надежда"',
  },
};

const DEPOT_ADDRESSES: Record<Transport, Record<number, string>> = {
  A: {
    9: 'ПОДЕЛЕНИЕ  "ЗЕМЛЯНЕ",  ул. "Житница" № 21  -  тел. (02) 955-75-50',
    10: 'ПОДЕЛЕНИЕ  "МАЛАШЕВЦИ",  ул. "Резбарска" № 11  -  тел. (02) 945-50-23',
    11: 'ПОДЕЛЕНИЕ  "ДРУЖБА",  ул. "Капитан Любен Кондаков" № 7  -  тел. (02) 973-22-82',
  },
  TM: {
    2: '1233 София, ул. "Подполковник Калитин" №30',
    3: '1233 София, ул. "Подполковник Калитин" №30',
    4: '1233 София, ул. "Подполковник Калитин" №30',
  },
  TB: {
    5: '1233 София, ул. "Подполковник Калитин" №30',
    6: '1233 София, ул. "Подполковник Калитин" №30',
  },
};

function sortShiftCondition(
  a: DayTask & {
    rosterShift: RosterShift | null;
  },
  b: DayTask & {
    rosterShift: RosterShift | null;
  }
) {
  if (
    a.rosterShift?.type === "timetable" &&
    b.rosterShift?.type === "special"
  ) {
    return -1;
  } else if (
    a.rosterShift?.type === "special" &&
    b.rosterShift?.type === "timetable"
  ) {
    return 1;
  } else if (!a.rosterShift?.type && b.rosterShift?.type) {
    return 1;
  } else if (a.rosterShift?.type && !b.rosterShift?.type) {
    return -1;
  } else if (!a.rosterShift?.type && !b.rosterShift?.type) {
    return 0;
  } else if (
    a.rosterShift &&
    b.rosterShift &&
    a.rosterShift.type === "timetable" &&
    b.rosterShift.type === "timetable"
  ) {
    if (a.rosterShift.shift.lineOrder < b.rosterShift.shift.lineOrder) {
      return -1;
    } else if (a.rosterShift.shift.lineOrder > b.rosterShift.shift.lineOrder) {
      return 1;
    } else {
      if (a.rosterShift.shift.car < b.rosterShift.shift.car) {
        return -1;
      } else if (a.rosterShift.shift.car > b.rosterShift.shift.car) {
        return 1;
      } else {
        if (a.shift_type && b.shift_type && a.shift_type < b.shift_type) {
          return -1;
        } else if (
          a.shift_type &&
          b.shift_type &&
          a.shift_type > b.shift_type
        ) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  } else if (
    a.rosterShift &&
    b.rosterShift &&
    a.rosterShift.type === "special" &&
    b.rosterShift.type === "special"
  ) {
    if (
      a.rosterShift.shift.workshift.description.toLocaleLowerCase() ===
      b.rosterShift.shift.workshift.description.toLocaleLowerCase()
    ) {
      return 0;
    } else if (
      a.rosterShift.shift.workshift.description.toLocaleLowerCase() <
      b.rosterShift.shift.workshift.description.toLocaleLowerCase()
    ) {
      return -1;
    } else {
      return 1;
    }
  } else {
    return 0;
  }
}

function sortVehicles<
  T extends {
    special: boolean;
    shifts: readonly [readonly RosterDriver[], readonly RosterDriver[]];
    sortBy?: DayTask & {
      rosterShift: RosterShift | null;
    };
  }
>(vehicles: T[]) {
  console.log("Sort vehicles");

  for (const vehicle of vehicles) {
    const allDrivers = [...vehicle.shifts[0], ...vehicle.shifts[1]];
    const allShifts = allDrivers.flatMap((driver) => driver.daytasks);

    const ttShifts = allShifts.filter((s) =>
      vehicle.special
        ? s.rosterShift?.type === "special"
        : s.rosterShift?.type === "timetable"
    );

    if (ttShifts.length > 0) {
      ttShifts.sort(sortShiftCondition);
      vehicle.sortBy = ttShifts[0] || null;
    } else {
      allShifts.sort(sortShiftCondition);
      vehicle.sortBy = allShifts[0] || null;
    }
  }

  vehicles.sort((a, b) => {
    if (a.special && !b.special) {
      return 1;
    } else if (!a.special && b.special) {
      return -1;
    } else if (a.sortBy && b.sortBy) {
      return sortShiftCondition(a.sortBy, b.sortBy);
    } else if (!a.sortBy && b.sortBy) {
      return 1;
    } else if (a.sortBy && !b.sortBy) {
      return -1;
    } else {
      return 0;
    }
  });

  return vehicles;
}

function* processVehicles(
  vehicles: readonly RosterVehicle[],
  includeEmptyVehicles: boolean,
  settings: GarageSettings
): Generator<
  RosterVehicle & { night: boolean; special: boolean; dt_comments: string[] }
> {
  for (const unfilteredVehicle of vehicles) {
    const vehicle = {
      ...unfilteredVehicle,
      shifts: unfilteredVehicle.shifts.map((ds) =>
        ds.map((d) => ({
          ...d,
          daytasks: d.daytasks.filter(
            (dt) =>
              dt.rosterShift && dt.veh1_id === unfilteredVehicle.vehicle_id
          ),
        }))
      ) as any as readonly [readonly RosterDriver[], readonly RosterDriver[]],
    };

    const hasTasks = vehicle.shifts.find((s) =>
      s.find((s) => s.daytasks.find((dt) => dt.rosterShift))
    );

    const driverComments = (
      includeEmptyVehicles && !hasTasks ? unfilteredVehicle : vehicle
    ).shifts.flatMap((vs) =>
      vs.flatMap((d) => d.daytasks.map((dt) => dt.comment || ""))
    );

    if (!hasTasks && includeEmptyVehicles) {
      yield {
        ...vehicle,
        special: false,
        night: false,
        dt_comments: driverComments,
      };
    } else if (hasTasks) {
      const hasSpecial =
        vehicle.shifts[0].find((s) =>
          s.daytasks.find(
            (dt) =>
              dt.veh1_id === vehicle.vehicle_id && !!getSpecialShiftDetails(dt)
          )
        ) ||
        vehicle.shifts[1].find((s) =>
          s.daytasks.find(
            (dt) =>
              dt.veh1_id === vehicle.vehicle_id && !!getSpecialShiftDetails(dt)
          )
        );

      if (hasSpecial) {
        const hasRegular =
          vehicle.shifts[0].find((s) =>
            s.daytasks.find(
              (dt) =>
                dt.veh1_id === vehicle.vehicle_id && !getSpecialShiftDetails(dt)
            )
          ) ||
          vehicle.shifts[1].find((s) =>
            s.daytasks.find(
              (dt) =>
                dt.veh1_id === vehicle.vehicle_id && !getSpecialShiftDetails(dt)
            )
          );

        yield {
          ...vehicle,
          special: true,
          night: false,
          dt_comments: driverComments,
        };
        if (hasRegular) {
          yield {
            ...vehicle,
            special: false,
            night: false,
            dt_comments: driverComments,
          };
        }
      } else {
        const hasNight =
          vehicle.shifts[0].find((s) =>
            s.daytasks.find(
              (dt) =>
                dt.veh1_id === vehicle.vehicle_id && !!getNightShiftDetails(dt)
            )
          ) ||
          vehicle.shifts[1].find((s) =>
            s.daytasks.find(
              (dt) =>
                dt.veh1_id === vehicle.vehicle_id && !!getNightShiftDetails(dt)
            )
          );

        if (hasNight && settings.splitNightCars) {
          const hasRegular =
            vehicle.shifts[0].find((s) =>
              s.daytasks.find(
                (dt) =>
                  dt.veh1_id === vehicle.vehicle_id && !getNightShiftDetails(dt)
              )
            ) ||
            vehicle.shifts[1].find((s) =>
              s.daytasks.find(
                (dt) =>
                  dt.veh1_id === vehicle.vehicle_id && !getNightShiftDetails(dt)
              )
            );

          if (hasRegular) {
            yield {
              ...vehicle,
              special: false,
              night: false,
              dt_comments: driverComments,
            };
          }
          yield {
            ...vehicle,
            special: false,
            night: true,
            dt_comments: driverComments,
          };
        } else {
          yield {
            ...vehicle,
            special: false,
            night: false,
            dt_comments: driverComments,
          };
        }
      }
    }
  }
}

export type PageData = {
  readonly serialNumber: string;
  readonly depotText: string;
  readonly depotName: string;
  readonly depotAddress: string;
  readonly isExportedCar: boolean;
  readonly isExtraDriver: boolean;
  readonly isSpecial: boolean;
  readonly isNight: boolean;
  readonly vehicle?: {
    readonly vehicle_id: string;
    readonly dk_no?: string;
    readonly type?: string;
    readonly includeEnergy: boolean | null | undefined;
  };
  readonly includeEnergyInEmpty?: boolean;
  readonly vehicle2_id: string | null;
  readonly drivers: {
    shift1?: PageDataShift;
    shift2?: PageDataShift;
    shift3?: PageDataShift;
  };
  readonly documents: LineDocuments;
  readonly comments: readonly string[];
  readonly userNames: string;
};

function generateComments(
  vehicle: RosterVehicle & {
    special?: boolean | undefined;
    dt_comments: string[];
  },
  rosterDateStr: string
) {
  const driverComments = vehicle.dt_comments;

  const vehicleComments = [
    vehicle.vehicle.days[rosterDateStr]?.comment || "",
    vehicle.vehicle.days[rosterDateStr]?.state?.id !== 0 &&
    vehicle.vehicle.days[rosterDateStr]?.state?.description
      ? `${vehicle.vehicle.days[rosterDateStr].state?.description}${
          vehicle.vehicle.days[rosterDateStr].state?.comment
            ? `: ${vehicle.vehicle.days[rosterDateStr].state?.comment}`
            : ""
        }`
      : "",
  ];

  return [...driverComments, ...vehicleComments];
}

function createDayRoster(
  drivers: readonly ScheduleDriver[],
  rosterDate: string,
  selectedAutoColumn: number
) {
  const arr: Record<number, { driver_id: number; scheduleDay: ScheduleDay }>[] =
    [];
  for (const driver of drivers) {
    const driver_day = Object.values(driver.driver_days).find(
      (dd) =>
        dd.day_in_month === rosterDate && dd.autocolumn === selectedAutoColumn
    );

    if (driver_day)
      arr.push({
        [driver.sl_number]: {
          driver_id: driver.sl_number,
          scheduleDay: driver_day,
        },
      });
  }
  return arr;
}

function generateVehicleData(
  // serialNumber: number,
  rosterDateStr: string,
  // rosterDate: Moment,
  selectedDepot: number,
  selectedTransport: Transport,
  vehicle: RosterVehicle & {
    special: boolean;
    night: boolean;
    dt_comments: string[];
  },
  shifts: readonly RosterShift[],
  drivers: readonly ScheduleDriver[],
  userNames: string,
  day_roster: Record<
    number,
    {
      driver_id: number;
      scheduleDay: ScheduleDay;
    }
  >[]
): PageData | PageData[] {
  const garageSettings = getSettingsByGarage(selectedTransport, selectedDepot);

  const daytasks = getAllDaytasks(vehicle, garageSettings);
  const [shift1, shift2, shift3] = groupDaytasksByShift(daytasks);

  const shift1Data = generateShiftData(shift1, drivers, day_roster);
  const shift2Data = generateShiftData(shift2, drivers, day_roster);
  const shift3Data = generateShiftData(shift3, drivers, day_roster);

  const [exportedCar, rosterShifts] = isExportedCar(vehicle, shifts);
  doCorrectCarTimes(
    exportedCar,
    rosterShifts,
    shift1Data?.shiftTimes,
    shift2Data?.shiftTimes
  );

  const exportedCarFlag = !!vehicle.vehicle.days[rosterDateStr]?.exported_car;
  if (exportedCar !== exportedCarFlag) {
    console.warn(
      "Грешен флаг за изнесена кола:",
      vehicle.vehicle_id,
      vehicle.vehicle.days[rosterDateStr]?.exported_car
    );
  }

  if (
    garageSettings.splitExportedCars &&
    exportedCar &&
    shift1Data &&
    shift2Data
  ) {
    return [
      {
        serialNumber: "000000000",
        depotText: DEPOT_ABBREVIATIONS[selectedTransport][selectedDepot] || "",
        depotName: DEPOT_NAMES[selectedTransport][selectedDepot] || "-",
        depotAddress: DEPOT_ADDRESSES[selectedTransport][selectedDepot] || "?",
        isExportedCar: exportedCar,
        isExtraDriver: false,
        isSpecial: !!vehicle.special,
        isNight: !!vehicle.night,
        vehicle: {
          vehicle_id: vehicle.vehicle_id.toString(),
          dk_no:
            vehicle.vehicle.dk_no && vehicle.vehicle.dk_no.trim() !== ""
              ? vehicle.vehicle.dk_no
              : undefined,
          type:
            vehicle.vehicletype_name &&
            vehicle.vehicletype_name.trim() !== "" &&
            vehicle.vehicletype_name.toLocaleLowerCase() !== "неизвестна"
              ? vehicle.vehicletype_name
              : undefined,
          includeEnergy: !!vehicle.includeEnergy,
        },
        vehicle2_id: vehicle.composition?.is_master
          ? vehicle.composition.other_veh.toString()
          : null,
        drivers: {
          shift1: shift1Data,
          shift2: undefined,
          shift3: undefined,
        },
        documents: getLines(daytasks, shift2Data, shift3Data),
        comments: [
          exportedCar ? "ИЗНЕСЕНА" : "",
          ...generateComments(vehicle, rosterDateStr),
        ].filter((l) => l.length > 0),
        userNames: userNames,
      },
      {
        serialNumber: "000000000",
        depotText: DEPOT_ABBREVIATIONS[selectedTransport][selectedDepot] || "",
        depotName: DEPOT_NAMES[selectedTransport][selectedDepot] || "-",
        depotAddress: DEPOT_ADDRESSES[selectedTransport][selectedDepot] || "?",
        isExportedCar: exportedCar,
        isExtraDriver: false,
        isSpecial: !!vehicle.special,
        isNight: !!vehicle.night,
        vehicle: {
          vehicle_id: vehicle.vehicle_id.toString(),
          dk_no:
            vehicle.vehicle.dk_no && vehicle.vehicle.dk_no.trim() !== ""
              ? vehicle.vehicle.dk_no
              : undefined,
          type:
            vehicle.vehicletype_name &&
            vehicle.vehicletype_name.trim() !== "" &&
            vehicle.vehicletype_name.toLocaleLowerCase() !== "неизвестна"
              ? vehicle.vehicletype_name
              : undefined,
          includeEnergy: !!vehicle.includeEnergy,
        },
        vehicle2_id: vehicle.composition?.is_master
          ? vehicle.composition.other_veh.toString()
          : null,
        drivers: {
          shift1: undefined,
          shift2: shift2Data,
          shift3: shift3Data,
        },
        documents: getLines(daytasks, shift1Data),
        comments: [
          exportedCar ? "ИЗНЕСЕНА" : "",
          ...generateComments(vehicle, rosterDateStr),
        ].filter((l) => l.length > 0),
        userNames: userNames,
      },
    ];
  } else {
    return {
      serialNumber: "000000000",
      depotText: DEPOT_ABBREVIATIONS[selectedTransport][selectedDepot] || "",
      depotName: DEPOT_NAMES[selectedTransport][selectedDepot] || "-",
      depotAddress: DEPOT_ADDRESSES[selectedTransport][selectedDepot] || "?",
      isExportedCar: exportedCar,
      isExtraDriver: false,
      isSpecial: !!vehicle.special,
      isNight: !!vehicle.night,
      vehicle: {
        vehicle_id: vehicle.vehicle_id.toString(),
        dk_no:
          vehicle.vehicle.dk_no && vehicle.vehicle.dk_no.trim() !== ""
            ? vehicle.vehicle.dk_no
            : undefined,
        type:
          vehicle.vehicletype_name &&
          vehicle.vehicletype_name.trim() !== "" &&
          vehicle.vehicletype_name.toLocaleLowerCase() !== "неизвестна"
            ? vehicle.vehicletype_name
            : undefined,
        includeEnergy: !!vehicle.includeEnergy,
      },
      vehicle2_id: vehicle.composition?.is_master
        ? vehicle.composition.other_veh.toString()
        : null,
      drivers: {
        shift1: shift1Data,
        shift2: shift2Data,
        shift3: shift3Data,
      },
      documents: getLines(daytasks),
      comments: [
        exportedCar ? "ИЗНЕСЕНА" : "",
        ...generateComments(vehicle, rosterDateStr),
      ].filter((l) => l.length > 0),
      userNames: userNames,
    };
  }
}

function generateExtraDriverData(
  serialNumber: number,
  rosterDate: Moment,
  selectedDepot: number,
  driver: ScheduleDriver,
  userNames: string,
  selectedTransport: Transport
): PageData {
  return {
    serialNumber: `${serialNumber
      .toString()
      .padStart(10, "0")}   /   ${rosterDate.format("DD/MM/YYYY")}`,
    depotText: DEPOT_ABBREVIATIONS[selectedTransport][selectedDepot] || "",
    depotName: DEPOT_NAMES[selectedTransport][selectedDepot] || "-",
    depotAddress: DEPOT_ADDRESSES[selectedTransport][selectedDepot] || "?",
    isExportedCar: false,
    isExtraDriver: true,
    isSpecial: false,
    isNight: false,
    vehicle: undefined,
    vehicle2_id: null,
    drivers: {
      shift1: {
        driver_id: driver.sl_number,
        sl_number: driver.sl_number.toString(),
        nastavnikId: null,
        nastavnikName: null,
        name: driver.name || "",
        lines: "",
        datas: [],
        shiftTimes: {
          enterTime: undefined,
          exitTime: undefined,
        },
      },
      shift2: undefined,
      shift3: undefined,
    },
    documents: [null, null, null, null],
    comments: ["СТАЖАНТ"].filter((l) => l.length > 0),
    userNames: userNames,
  };
}

export function usePrintJourneyListData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { selectedDepot } = useDepotFilter();
  const { selectedTransport } = useTransportFilter();
  const settings = getSettingsByGarage(selectedTransport, selectedDepot);

  const [getFirstSerialNumber] = useLazyGetFirstSerialNumberQuery();

  const profile = useProfile();
  const userNames =
    profile && selectedTransport !== "" && settings.printUserNames
      ? profile.name || ""
      : "";

  const emptyData = useCallback<
    (options: {
      rosterDate: string;
      includeEnergyInEmpty?: boolean;
    }) => Promise<PageData>
  >(
    async ({ rosterDate, includeEnergyInEmpty }) => {
      try {
        setIsDone(false);
        setHasError(false);
        setIsLoading(true);

        if (selectedTransport === "") {
          throw "Transport is not selected";
        } else {
          const firstSerial = await getFirstSerialNumber(
            {
              transport: selectedTransport,
              depot_id: selectedDepot,
              autocolumn: selectedAutoColumn,
              date: rosterDate,
              count: 1,
              ts: new Date().getTime(),
            },
            false
          ).unwrap();

          if (firstSerial.status !== "ok") {
            throw "Can't fetch serial number";
          } else {
            return {
              serialNumber: `${firstSerial.data.start_number
                .toString()
                .padStart(10, "0")}   /   ${moment(
                rosterDate,
                "YYYY-MM-DD"
              ).format("DD/MM/YYYY")}`,
              depotText:
                DEPOT_ABBREVIATIONS[selectedTransport][selectedDepot] || "",
              depotName: DEPOT_NAMES[selectedTransport][selectedDepot] || "-",
              depotAddress:
                DEPOT_ADDRESSES[selectedTransport][selectedDepot] || "?",
              isExportedCar: false,
              isExtraDriver: false,
              isSpecial: false,
              isNight: false,
              includeEnergyInEmpty: includeEnergyInEmpty,
              vehicle: undefined,
              vehicle2_id: null,
              drivers: {
                shift1: undefined,
                shift2: undefined,
                shift3: undefined,
              },
              documents: [null, null, null, null],
              comments: [],
              userNames: userNames,
            };
          }
        }
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
        throw err;
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      getFirstSerialNumber,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
      userNames,
    ]
  );

  const processData = useCallback<
    (options: {
      rosterDate: string;
      includeEmptyVehicles: boolean;
      vehicles: readonly RosterVehicle[];
      extraDrivers: ScheduleDriver[];
      // drivers: readonly Driver[];
      drivers: readonly ScheduleDriver[];
      shifts: readonly RosterShift[];
    }) => Promise<PageData[]>
  >(
    async ({
      rosterDate,
      includeEmptyVehicles,
      vehicles,
      extraDrivers,
      drivers,
      shifts,
    }) => {
      try {
        setIsDone(false);
        setHasError(false);
        setIsLoading(true);

        if (selectedTransport === "") {
          throw "Transport is not selected";
        } else {
          const journeyListVehicles = sortVehicles([
            ...processVehicles(
              vehicles || [],
              !!includeEmptyVehicles,
              settings
            ),
          ]);

          // const journeyListExtraDrivers = (extraDrivers || [])
          //   .map<Driver>(
          //     (scheduleDriver) =>
          //       (drivers || []).find(
          //         (d) => d.sl_number === scheduleDriver.sl_number
          //       ) as Driver
          //   )
          //   .filter((scheduleDriver) => !!scheduleDriver);
          const journeyListExtraDrivers = extraDrivers;

          const rosterDateMoment = moment(rosterDate, "YYYY-MM-DD");

          const day_roster = createDayRoster(
            drivers,
            rosterDate,
            selectedAutoColumn
          );

          const vehiclesData = journeyListVehicles.flatMap((vehicle) =>
            generateVehicleData(
              rosterDate,
              selectedDepot,
              selectedTransport,
              vehicle,
              shifts || [],
              drivers || [],
              userNames,
              day_roster
            )
          );

          const extraDriversData = journeyListExtraDrivers.map((driver) =>
            generateExtraDriverData(
              0,
              rosterDateMoment,
              selectedDepot,
              driver,
              userNames,
              selectedTransport
            )
          );

          const documents = vehiclesData.concat(extraDriversData);

          const firstSerial = await getFirstSerialNumber(
            {
              transport: selectedTransport,
              depot_id: selectedDepot,
              autocolumn: selectedAutoColumn,
              date: rosterDate,
              count: documents.length,
              ts: new Date().getTime(),
            },
            false
          ).unwrap();

          if (firstSerial.status !== "ok") {
            throw "Can't fetch serial number";
          } else {
            const garageSettings = getSettingsByGarage(
              selectedTransport,
              selectedDepot
            );

            return documents.map((d, dIdx) => {
              // const serialDate = (
              //   (garageSettings.printJourneyListActualNextDate &&
              //   !d.drivers?.shift1?.shiftTimes?.exitTime &&
              //   !d.drivers?.shift2?.shiftTimes?.exitTime
              //     ? d.drivers?.shift3?.shiftTimes?.enterTime
              //     : undefined) || rosterDateMoment
              // ).format("DD/MM/YYYY");
              let serialDate: string;
              if (
                garageSettings.printJourneyListActualNextDate &&
                !d.isSpecial &&
                !d.drivers?.shift1?.shiftTimes?.exitTime &&
                !d.drivers?.shift2?.shiftTimes?.exitTime
              ) {
                serialDate = (
                  d.drivers?.shift3?.shiftTimes?.enterTime || rosterDateMoment
                ).format("DD/MM/YYYY");
              } else if (
                garageSettings.printJourneyListActualNextDateForSpecialShifts &&
                d.isSpecial &&
                !d.drivers?.shift1?.shiftTimes?.exitTime &&
                !d.drivers?.shift2?.shiftTimes?.exitTime
              ) {
                serialDate = (
                  d.drivers?.shift3?.shiftTimes?.enterTime || rosterDateMoment
                ).format("DD/MM/YYYY");
              } else {
                serialDate = rosterDateMoment.format("DD/MM/YYYY");
              }

              return {
                ...d,
                serialNumber: `${(firstSerial.data.start_number + dIdx)
                  .toString()
                  .padStart(10, "0")}   /   ${serialDate}`,
              };
            });
          }
        }
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
        throw err;
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      getFirstSerialNumber,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
      userNames,
      settings,
    ]
  );

  return { processData, emptyData, isLoading, isDone, hasError };
}
