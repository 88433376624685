import { Assessment, DepartureBoard } from "@mui/icons-material";
import {
  Alert,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useCallback, useState } from "react";
//import { useAuth } from "react-oidc-context";
import { NavLink } from "../../common/NavLink";
import { useGetRosterListQuery } from "../../data/api/hooks";
import { ApiRoster } from "../../data/api/types/roster";
import {
  updateSelectedAutoColumn,
  updateSelectedDate,
  updateSelectedDepot,
  updateSelectedTransport,
} from "../../data/filtersSlice";
import { useAppDispatch } from "../../data/store";
import {
  getActionText,
  getRosterStateTitle,
  getUserText,
} from "../roster/RosterTopMenu";

function isRosterExported(roster: ApiRoster) {
  return !!roster.actions.find((a) => a.action === "gps_export");
}

function RosterDetailsDialog({
  open,
  roster,
  onClose,
}: {
  open: boolean;
  roster: ApiRoster | null;
  onClose: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Действия по наряд</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Действия по наряд за ${
            roster ? moment(roster.day_in_month, "YYYY-MM-DD").format("L") : ""
          }, ${roster?.depot_name || ""}, Автоколона ${
            roster?.autocolumn || 1
          }`}
        </DialogContentText>
        {roster && roster.status !== "exported" && isRosterExported(roster) ? (
          <Alert severity="warning" sx={{ mt: 3 }}>
            Променен наряд след прехвърляне в GPS!
          </Alert>
        ) : (
          <></>
        )}
        <List dense>
          {roster?.actions.map((action, idx) => (
            <ListItem key={idx}>
              <ListItemText
                primary={getActionText(action)}
                secondary={`${getUserText(action)}, ${moment(
                  action.timestamp
                ).format("LLL")}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

function RosterList({
  title,
  rosters,
  onClickRosterLink,
}: {
  title: string;
  rosters: readonly ApiRoster[];
  onClickRosterLink: (roster: ApiRoster) => void;
}) {
  const [dialogRoster, setDialogRoster] = useState<{
    roster: ApiRoster | null;
    open: boolean;
  }>({ roster: null, open: false });

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RosterDetailsDialog
        open={dialogRoster.open}
        roster={dialogRoster.roster}
        onClose={() => setDialogRoster({ ...dialogRoster, open: false })}
      />

      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "body2",
          color: "text.secondary",
          textTransform: "uppercase",
        }}
      />
      <Divider />

      <List dense={false}>
        {rosters.map((roster, idx) => (
          <ListItem
            key={idx}
            disablePadding
            sx={
              roster.status !== "exported" && isRosterExported(roster)
                ? {
                    backgroundColor: red[800],
                    color: "white",
                  }
                : {}
            }
            secondaryAction={
              <>
                <IconButton
                  edge="end"
                  to="/roster/"
                  component={NavLink}
                  onClick={() => onClickRosterLink(roster)}
                  title="Отвори наряд"
                  sx={
                    roster.status !== "exported" && isRosterExported(roster)
                      ? {
                          color: "white",
                        }
                      : {}
                  }
                >
                  <DepartureBoard />
                </IconButton>

                <IconButton
                  edge="end"
                  to="/reports/roster-report/"
                  component={NavLink}
                  onClick={() => onClickRosterLink(roster)}
                  title="Отвори справка"
                  sx={
                    roster.status !== "exported" && isRosterExported(roster)
                      ? {
                          color: "white",
                        }
                      : {}
                  }
                >
                  <Assessment />
                </IconButton>
              </>
            }
          >
            <ListItemButton
              //to="/roster/"
              //component={NavLink}
              //onClick={() => onClickRosterLink(roster)}
              onClick={() => setDialogRoster({ roster, open: true })}
              title={
                roster.status !== "exported" && isRosterExported(roster)
                  ? `Променен наряд след прехвърляне в GPS!\n\n${getRosterStateTitle(
                      roster
                    )}`
                  : getRosterStateTitle(roster)
              }
            >
              {`${moment(roster.day_in_month, "YYYY-MM-DD").format(
                "L"
              )}, създаден на ${moment(roster.create_timestamp).format("LL")}`}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

export function Index() {
  //const { user } = useAuth();
  const dispatch = useAppDispatch();

  const rosters = useGetRosterListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 5000,
  });
  //console.log(rosters.data?.data);

  const onClickRosterLink = useCallback(
    (roster: ApiRoster) => {
      dispatch(updateSelectedTransport(roster.transport));
      dispatch(updateSelectedDepot(roster.depot_id));
      dispatch(updateSelectedAutoColumn({ autocolumn: roster.autocolumn }));
      dispatch(updateSelectedDate(roster.day_in_month));
    },
    [dispatch]
  );

  return (
    <Container sx={{ py: 4 }}>
      {rosters.isLoading || rosters.isUninitialized
        ? "Loading..."
        : rosters.isSuccess && rosters.currentData
        ? rosters.currentData.data.transports.map((transport) =>
            transport.garages.map((garage) =>
              garage.autocolumns.map((autocolumn) => (
                <Paper
                  key={`${transport.transport}-${garage.garage}-${autocolumn.autocolumn}`}
                  sx={{ px: 2, py: 1, mt: 3 }}
                >
                  <Grid container spacing={2} sx={{ m: 2, mt: 0 }}>
                    <Grid xs={12} sx={{ mb: 2 }}>
                      <Typography variant="h6">{`${garage.garage_name}, Автоколона ${autocolumn.autocolumn}`}</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <RosterList
                        title="Незавършени наряди"
                        rosters={autocolumn.rosters.filter(
                          (roster) =>
                            roster.status === "not-ready" ||
                            roster.status === "edited"
                        )}
                        onClickRosterLink={onClickRosterLink}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RosterList
                        title="Неодобрени наряди"
                        rosters={autocolumn.rosters.filter(
                          (roster) => roster.status === "ready"
                        )}
                        onClickRosterLink={onClickRosterLink}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RosterList
                        title="Непрехвърлени наряди"
                        rosters={autocolumn.rosters.filter(
                          (roster) => roster.status === "approved"
                        )}
                        onClickRosterLink={onClickRosterLink}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RosterList
                        title="Прехвърлени наряди"
                        rosters={autocolumn.rosters.filter(
                          (roster) => roster.status === "exported"
                        )}
                        onClickRosterLink={onClickRosterLink}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ))
            )
          )
        : "Error!"}
    </Container>
  );
}
