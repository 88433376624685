import {
  alpha,
  Card,
  CardContent,
  CardHeader,
  SxProps,
  Theme,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  useCallback,
} from "react";

export const ItemContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    title?: React.ReactNode;
    isOver?: boolean;
    sx?: SxProps<Theme>;
    headerSx?: SxProps<Theme>;
    contentSx?: SxProps<Theme>;
    style?: CSSProperties;
    onDragTitle?: (
      event: "start" | "move" | "end",
      delta: { x: number; y: number }
    ) => void;
  }>
>(
  (
    { title, isOver, sx, headerSx, contentSx, style, onDragTitle, children },
    ref
  ) => {
    const mouseDownHandler = useCallback(
      (mouseDownEvent: MouseEvent) => {
        const startPosition = {
          x: mouseDownEvent.pageX,
          y: mouseDownEvent.pageY,
        };

        function onMouseMove(mouseMoveEvent: MouseEvent) {
          onDragTitle &&
            onDragTitle("move", {
              x: startPosition.x - mouseMoveEvent.pageX,
              y: startPosition.y - mouseMoveEvent.pageY,
            });
        }

        function onMouseUp(mouseUpEvent: MouseEvent) {
          onDragTitle &&
            onDragTitle("end", {
              x: startPosition.x - mouseUpEvent.pageX,
              y: startPosition.y - mouseUpEvent.pageY,
            });
          document.removeEventListener("mousemove", onMouseMove);
        }

        onDragTitle && onDragTitle("start", startPosition);

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp, { once: true });
      },
      [onDragTitle]
    );

    return (
      <Card
        style={style}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: isOver ? alpha(green[100], 0.25) : undefined,
          userSelect: "none",
          ...sx,
        }}
        ref={ref}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{
            color: "text.secondary",
            fontSize: 12,
          }}
          sx={{
            px: 4,
            pt: 1.5,
            pb: 1.5,
            backgroundColor: grey[300],
            cursor: onDragTitle ? "n-resize" : undefined,
            ...headerSx,
          }}
          onMouseDown={mouseDownHandler}
        />
        <CardContent
          sx={{
            px: 4,
            py: 2,
            alignContent: "baseline",
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflowY: "auto",
            overflowX: "hidden",
            ...contentSx,
          }}
        >
          {children}
        </CardContent>
        <div />
      </Card>
    );
  }
);
