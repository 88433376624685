import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Car, LineTimetable } from "./useTimetables_ByDate";
import {
  usePostTimetableCarAutocolumnMutation,
  usePostTimetableVehicleTypeMutation,
} from "../../data/api/hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Transport } from "../../data/api/types/driver";
import { QueryError } from "../QueryError";
import { useProfile } from "../../common/useProfile";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";

// type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

// // https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
// export const dirtyValues = (
//   dirtyFields: UnknownArrayOrObject | boolean,
//   allValues: UnknownArrayOrObject
// ): UnknownArrayOrObject => {
//   // NOTE: Recursive function.

//   // If *any* item in an array was modified, the entire array must be submitted, because there's no
//   // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
//   if (dirtyFields === true || Array.isArray(dirtyFields)) {
//     return allValues;
//   }

//   // Here, we have an object.
//   return Object.fromEntries(
//     Object.keys(dirtyFields).map((key ) => [
//       key,
//       dirtyValues(dirtyFields[key], allValues[key])
//     ])
//   );
// };

export function TimetbaleVehicletypeEditForm({
  open,
  setOpen,
  selectedLineTimetable,
  setSelectedLineTimetable,
  vehicleTypeList,
  selectedTransport,
  selectedGarage,
  selectedDate,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLineTimetable: LineTimetable;
  setSelectedLineTimetable: React.Dispatch<
    React.SetStateAction<LineTimetable | undefined>
  >;
  vehicleTypeList: {
    label: string;
    id: number;
  }[];
  selectedTransport: Transport;
  selectedGarage: number;
  selectedDate: string;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const [isChanged, setIsChanged] = useState(false);
  // const [isSaving, setIsSaving] = useState(false);

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const handleClose = useCallback(() => {
    if (!isChanged) {
      setIsChanged(false);
      setOpen(false);
      setSelectedLineTimetable(undefined);
    } else {
      if (
        window.confirm(
          "Направените промени няма да бъдат записани. Желаете ли да продължите?"
        )
      ) {
        setIsChanged(false);
        setOpen(false);
        setSelectedLineTimetable(undefined);
      }
    }
  }, [isChanged, setOpen, setSelectedLineTimetable]);

  // const handleOk = useCallback(() => {
  //   setIsSaving(true);
  // }, []);

  const [updateTimetableVehType, resultUpdateTimetableVehType] =
    usePostTimetableVehicleTypeMutation();

  const [updateTimetableAutocolumn, resultUpdateTimetableAutocolumn] =
    usePostTimetableCarAutocolumnMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      lineTiemtable: selectedLineTimetable,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    reset({
      lineTiemtable: selectedLineTimetable,
    });
  }, [reset, selectedLineTimetable]);

  // useEffect(() => {
  //   console.log("needAvtocolumn", needsAutocolumnSelect);
  //   if (!needsAutocolumnSelect)
  //     selectedLineTimetable.cars.forEach((car) =>
  //       car.workshifts.forEach((w) => (w.autocolumn = selectedAvtoColumn))
  //     );
  // }, [needsAutocolumnSelect, selectedAvtoColumn, selectedLineTimetable.cars]);

  useEffect(() => {
    if (isDirty) {
      console.log("isDirty: true");
      setIsChanged(true);
    } else if (
      isChanged &&
      resultUpdateTimetableAutocolumn.isSuccess &&
      resultUpdateTimetableVehType.isSuccess
    )
      setOpen(false);
    setIsChanged(false);
  }, [
    isChanged,
    isDirty,
    resultUpdateTimetableAutocolumn.isSuccess,
    resultUpdateTimetableVehType.isSuccess,
    setOpen,
  ]);

  const onSubmit = useCallback<SubmitHandler<{ lineTiemtable: LineTimetable }>>(
    (data) => {
      if (isReadOnly) {
        return;
      }

      //let isError = false;

      if (isDirty) {
        if (dirtyFields.lineTiemtable && dirtyFields.lineTiemtable) {
          for (const index of Object.values(dirtyFields.lineTiemtable)) {
            for (const idx of Object.keys(index) as any as keyof Car) {
              updateTimetableAutocolumn({
                transport: selectedTransport,
                depot: selectedGarage,
                car_no: data.lineTiemtable.cars[idx as any as number].car_no,
                autocolumn: data.lineTiemtable.cars[idx as any as number]
                  .workshifts[0].operate
                  ? data.lineTiemtable.cars[idx as any as number].workshifts[0]
                      .autocolumn != undefined &&
                    data.lineTiemtable.cars[idx as any as number].workshifts[0]
                      .autocolumn > 0
                    ? data.lineTiemtable.cars[idx as any as number]
                        .workshifts[0].autocolumn
                    : selectedAvtoColumn
                  : 0,
                timetable_id: data.lineTiemtable.timetable_id,
                start_date: selectedDate,
                end_date: null,
                shift_type: null,
              }).then((res) => {
                if ("error" in res && res.error) {
                  <QueryError error={res.error} />;

                  //isError = true;
                } else {
                  updateTimetableVehType({
                    transport: selectedTransport,
                    depot: selectedGarage,
                    car_no:
                      data.lineTiemtable.cars[idx as any as number].car_no,
                    vehicletype_id:
                      data.lineTiemtable.cars[idx as any as number]
                        .workshifts[0].vehicletype_id,
                    timetable_id: data.lineTiemtable.timetable_id,
                  }).then((res) => {
                    if ("error" in res && res.error) {
                      <QueryError error={res.error} />;

                      //isError = true;
                    } else {
                      if (data) {
                        setSelectedLineTimetable(data.lineTiemtable);
                        setOpen(false);
                      }
                    }
                  });

                  // if (data) setSelectedLineTimetable(data.lineTiemtable);
                }
              });

              //   if (
              //     !resultUpdateTimetableAutocolumn.isError &&
              //     !resultUpdateTimetableVehType.isError &&
              //     data
              //   ) {
              //     setSelectedLineTimetable(data.lineTiemtable);
              //     setOpen(false);
              //   }
            }
          }
        }
      }
    },
    [
      dirtyFields.lineTiemtable,
      isDirty,
      isReadOnly,
      selectedAvtoColumn,
      selectedDate,
      selectedGarage,
      selectedTransport,
      setOpen,
      setSelectedLineTimetable,
      updateTimetableAutocolumn,
      updateTimetableVehType,
    ]
  );

  const submit = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  const garageAutocolumns = useMemo(
    () =>
      profile?.garage_autocolumns?.[selectedTransport as Transport]?.[
        selectedGarage
      ] || [],
    [profile?.garage_autocolumns, selectedGarage, selectedTransport]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}

      // onLoad={() => document.getElementById("name")?.focus()}
    >
      <DialogTitle>
        Линия {selectedLineTimetable.line_name} <br /> Код{"    "}
        {selectedLineTimetable.code}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Моля, изберете марка за всяка кола.
        </DialogContentText>
        {(resultUpdateTimetableAutocolumn.isError ||
          resultUpdateTimetableVehType.isError) && <QueryError error={null} />}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Кола</TableCell>
                <TableCell>Марка</TableCell>
                {garageAutocolumns.length > 1 && (
                  <TableCell> Автоколона</TableCell>
                )}
                <Tooltip title="cdfd">
                  <>
                    {" "}
                    <TableCell>
                      {selectedTransport !== "A" ? "Работи" : "Работи в гаража"}
                    </TableCell>
                  </>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedLineTimetable.cars.map((c, idx) => (
                <TableRow key={c.car_no}>
                  <TableCell>{c.car_no}</TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`lineTiemtable.cars.${idx}.workshifts.${0}.vehicletype_id`}
                      render={({ field: { onChange, value, ...field } }) => (
                        <Select
                          fullWidth
                          sx={{ minWidth: "180px" }}
                          id={`lineTiemtable.cars.${idx}.workshifts.${0}.vehicletype_id`}
                          labelId={`lineTiemtable.cars.${idx}.workshifts.${0}.vehicletype.description`}
                          variant="standard"
                          value={value ? value : ""}
                          {...field}
                          onChange={(data) => {
                            onChange(data);

                            return data;
                          }}
                        >
                          {vehicleTypeList.map((name) => (
                            <MenuItem
                              key={name.id}
                              value={name.id}
                              onChange={(data) => {
                                onChange(data);
                                return data;
                              }}
                            >
                              {name.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </TableCell>
                  {garageAutocolumns.length > 1 && (
                    <TableCell>
                      {/* <TextField
                        id="car-auto-column-select"
                        //sx={{ m: 1, width: "100%" }}
                        variant="standard"
                        type="number"
                        {...register(
                          `lineTiemtable.cars.${idx}.workshifts.${0}.autocolumn`
                        )}
                        //    value={needsAutocolumnSelect ? "" : selectedAvtoColumn}
                      /> */}

                      <Controller
                        control={control}
                        name={`lineTiemtable.cars.${idx}.workshifts.${0}.autocolumn`}
                        render={({ field: { onChange, value, ...field } }) => (
                          <Select
                            //fullWidth

                            sx={{ minWidth: "50px" }}
                            id={`lineTiemtable.cars.${idx}.workshifts.${0}.autocolumn`}
                            labelId={`lineTiemtable.cars.${idx}.workshifts.${0}.vehicletype.autocolumn`}
                            variant="standard"
                            value={value ? value : ""}
                            {...field}
                            onChange={(data) => {
                              onChange(data);

                              return data;
                            }}
                          >
                            {garageAutocolumns.map((a) => (
                              <MenuItem
                                key={a}
                                value={a}
                                onChange={(data) => {
                                  onChange(data);
                                  return data;
                                }}
                              >
                                {a}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ alignContent: "center" }}>
                    <Controller
                      control={control}
                      name={`lineTiemtable.cars.${idx}.workshifts.${0}.operate`}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions onSubmit={submit}>
        <Button disabled={isReadOnly || !isDirty} onClick={submit}>
          Запис
        </Button>
        <Button onClick={handleClose}>Изход</Button>
      </DialogActions>
    </Dialog>
  );
}
