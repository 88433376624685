import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../../common/useProfile";
import { Transport } from "../../data/api/types/driver";
import {
  EditDriverDayCommentFunc,
  UpdateDriverStateFunc,
} from "../../data/schedules/types";
import { RootState } from "../../data/store";
import {
  isAllowedInPreliminary,
  // isStoredInPreliminaryField,
} from "./stateFunctions";
import { CELL_HEIGHT, CELL_WIDTH } from "./TableElements";
import { TableCellRef, TableRowRef } from "./types";
import { useScheduleParameters } from "./useScheduleParameters";

export function useSelectStateMenu(
  updateDriverState: UpdateDriverStateFunc,
  editDriverDayComment: EditDriverDayCommentFunc,
  editDriverDepot: (data: {
    transport: Transport;
    depot_id: number;
    sl_number: number;
    autocolumn: number;
    new_depot_id: number;
    start_date: string;
    end_date: string | null;
  }) => Promise<{ status: "ok" }>,
  isEditingDisabled: boolean
) {
  const profile = useProfile();
  const isReadOnly =
    isEditingDisabled || !profile?.roles?.includes("edit_schedule");

  const [changeDepotOnly, setChangeDepotOnly] = useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    row: TableRowRef;
    cell: TableCellRef;
  } | null>(null);

  const onContextMenu = useCallback(
    (
      mouseX: number,
      mouseY: number,
      row: TableRowRef,
      cell: TableCellRef,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _rowIndex: number,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _cellIndex: number,
      changeDepotOnly: boolean
    ) => {
      setChangeDepotOnly(changeDepotOnly);
      if (!isReadOnly) {
        setContextMenu(
          contextMenu === null
            ? //? { mouseX: mouseX + 2, mouseY: mouseY - 6, row }
              {
                mouseX: mouseX + CELL_WIDTH / 2,
                mouseY: mouseY + CELL_HEIGHT / 2,
                row,
                cell,
              }
            : null
        );
      }
    },
    [contextMenu, isReadOnly]
  );

  const selection = useSelector((state: RootState) => state.schedule.selection);
  const isPreliminary = useSelector(
    (state: RootState) =>
      state.schedule.schedule?.db_schedule?.preliminary_ready !== true
  );
  const selectedStartCell = selection
    ? Math.max(0, Math.min(selection.startCell, selection.endCell))
    : -1;
  const selectedEndCell = selection
    ? Math.min(
        Math.max(selection.startCell, selection.endCell),
        contextMenu?.row?.rowType === "driver" && contextMenu.row.cells
          ? contextMenu.row.cells.length - 1
          : -1
      )
    : -1;

  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const onSelectState = useCallback(
    async (stateId: number) => {
      if (
        !isReadOnly &&
        contextMenu &&
        contextMenu.row.rowType === "driver" &&
        contextMenu.row.cells[selectedStartCell] &&
        contextMenu.row.cells[selectedEndCell] &&
        isAllowedInPreliminary(isPreliminary, stateId, changeDepotOnly)
      ) {
        console.log(
          "Menu:",
          stateId,
          contextMenu.row.id,
          contextMenu.row.cells[selectedStartCell].date.format("YYYY-MM-DD"),
          contextMenu.row.cells[selectedEndCell].date.format("YYYY-MM-DD")
        );
        setIsUpdating(true);
        setError(null);
        setContextMenu(null);

        try {
          // if (stateId === 1) {
          //   await updateDriverState({
          //     driver_id: contextMenu.row.id,
          //     state_id: stateId,
          //     preliminary: true,
          //     start_date:
          //       contextMenu.row.cells[selectedStartCell].date.format(
          //         "YYYY-MM-DD"
          //       ),
          //     end_date:
          //       contextMenu.row.cells[selectedEndCell].date.format(
          //         "YYYY-MM-DD"
          //       ),
          //   });
          // }

          await updateDriverState({
            driver_id: contextMenu.row.id,
            state_id: stateId,
            preliminary: false,
            start_date:
              contextMenu.row.cells[selectedStartCell].date.format(
                "YYYY-MM-DD"
              ),
            end_date:
              contextMenu.row.cells[selectedEndCell].date.format("YYYY-MM-DD"),
          });

          console.log("Driver updated");
        } catch (e) {
          console.log("Driver update failed:", e);
          if (e instanceof Error) {
            setError(e);
          } else {
            setError(new Error(JSON.stringify(e)));
          }
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [
      contextMenu,
      changeDepotOnly,
      isPreliminary,
      isReadOnly,
      selectedEndCell,
      selectedStartCell,
      updateDriverState,
    ]
  );

  const [commentDialog, setCommentDialog] = useState<{
    sl_number: number;
    initial_comment: string | null;
    date: Moment;
    driver_id: string;
  } | null>(null);
  const [commentErrors, setCommentErrors] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [commentBusy, setCommentBusy] = useState(false);

  const [depotDialog, setDepotDialog] = useState<{
    visible: boolean;
    sl_number: number;
    start_date: string;
    end_date: string | null;
    home_depot_id: number;
  } | null>(null);
  const [depotErrors, setDepotErrors] = useState<React.ReactNode | null>(null);
  const [depotId, setDepotId] = useState<number | null>(null);
  const [depotBusy, setDepotBusy] = useState(false);

  const onCloseCommentDialog = useCallback(() => {
    if (!commentBusy) {
      setCommentDialog(null);
      setComment("");
      setCommentErrors(null);
    }
  }, [commentBusy]);

  const onUpdateComment = useCallback(() => {
    if (
      !isReadOnly &&
      contextMenu?.cell?.date?.isValid() &&
      contextMenu?.row?.rowType === "driver" &&
      contextMenu?.row?.id
    ) {
      setError(null);
      setContextMenu(null);
      setComment(contextMenu.cell.driver_day.comment);
      setCommentDialog({
        sl_number: contextMenu.cell.driver.sl_number,
        initial_comment: contextMenu.cell.driver_day.comment,
        date: contextMenu.cell.date,
        driver_id: contextMenu.cell.driver.id,
      });
    }
  }, [
    contextMenu?.cell,
    contextMenu?.row?.id,
    contextMenu?.row?.rowType,
    isReadOnly,
  ]);

  const onChangeComment = useCallback(async () => {
    console.log("Save comment...");
    if (!isReadOnly && commentDialog) {
      try {
        setCommentBusy(true);
        setCommentErrors(null);

        await editDriverDayComment({
          driver_id: commentDialog.driver_id,
          date: commentDialog.date.format("YYYY-MM-DD"),
          comment: comment && comment.trim() !== "" ? comment : null,
        });

        setComment(null);
        setCommentErrors(null);
        setCommentDialog(null);
        setContextMenu(null);
      } catch {
        setCommentErrors("Коментарът не може да бъде записан");
      } finally {
        setCommentBusy(false);
      }
    } else {
      setCommentErrors("Коментарът не може да бъде записан");
      setCommentBusy(false);
    }
  }, [comment, commentDialog, editDriverDayComment, isReadOnly]);

  const onCloseDepotDialog = useCallback(() => {
    if (!depotBusy) {
      setDepotDialog(null);
      setDepotId(null);
      setDepotErrors(null);
    }
  }, [depotBusy]);

  const { scheduleParameters } = useScheduleParameters();

  const onChangeDepot = useCallback(
    async (restoreDriver?: boolean) => {
      console.log("Save changed depot...", depotDialog, depotId, restoreDriver);
      if (!isReadOnly && depotDialog && scheduleParameters && depotId) {
        try {
          setDepotBusy(true);
          setDepotErrors(null);

          await editDriverDepot({
            transport: scheduleParameters[0],
            // depot_id: scheduleParameters[1],
            depot_id: depotDialog.home_depot_id,
            sl_number: depotDialog.sl_number,
            autocolumn: scheduleParameters[4],
            new_depot_id: restoreDriver ? depotDialog.home_depot_id : depotId,
            start_date: depotDialog.start_date,
            end_date: depotDialog.end_date,
          });

          setDepotId(null);
          setDepotErrors(null);
          setDepotDialog(null);
          setContextMenu(null);
        } catch (e: any) {
          console.log(e);
          if (
            e.data?.status === "error" &&
            Array.isArray(e.data.data?.errors) &&
            e.data.data.errors.length > 0 &&
            typeof e.data.data.errors[0] === "string"
          ) {
            setDepotErrors(
              <>
                <span style={{ display: "block" }}>
                  <strong>Грешка: </strong>
                  <span>Водачът не може да бъде командирован</span>
                </span>
                {(e.data.data.errors as string[]).map((err, idx) => (
                  <span key={idx} style={{ display: "block" }}>
                    {err}
                  </span>
                ))}
              </>
            );
          } else {
            setDepotErrors(
              <>
                <strong>Грешка: </strong>
                <span>
                  Водачът не може да бъде командирован: неизвестна грешка
                </span>
              </>
            );
          }
        } finally {
          setDepotBusy(false);
        }
      } else {
        setDepotErrors(
          <>
            <strong>Грешка: </strong>
            <span>
              Водачът не може да бъде командирован: параметрите не са заредени
            </span>
          </>
        );
        setDepotBusy(false);
      }
    },
    [depotDialog, depotId, editDriverDepot, isReadOnly, scheduleParameters]
  );

  const onUpdateDepot = useCallback(
    (restoreDriver?: boolean) => {
      if (
        !isReadOnly &&
        contextMenu?.row?.rowType === "driver" &&
        contextMenu?.row?.id &&
        contextMenu?.row?.cells?.length &&
        contextMenu?.row?.cells?.[selectedStartCell]?.date?.isValid() &&
        contextMenu?.row?.cells?.[selectedEndCell]?.date?.isValid()
      ) {
        const home_depot_id =
          contextMenu.row.driver.home_depot_id || scheduleParameters?.[1] || 0;

        setError(null);
        setContextMenu(null);
        setDepotId(restoreDriver ? home_depot_id : null);
        setDepotDialog({
          visible: !restoreDriver,
          sl_number: contextMenu.cell.driver.sl_number,
          start_date:
            contextMenu.row.cells[selectedStartCell].date.format("YYYY-MM-DD"),
          end_date:
            contextMenu.row.cells[selectedEndCell].date.format("YYYY-MM-DD"),
          home_depot_id: home_depot_id,
        });
      }
    },
    [
      contextMenu,
      isReadOnly,
      selectedEndCell,
      selectedStartCell,
      scheduleParameters,
    ]
  );

  useEffect(() => {
    if (depotDialog && !depotDialog.visible) {
      console.log("Прекратяване на командировка:", depotDialog);
      onChangeDepot(true);
      setDepotDialog(null);
    }
  }, [depotDialog, onChangeDepot]);

  return {
    onSelectState,
    onUpdateComment,
    onUpdateDepot,
    onContextMenu,
    setContextMenu,
    contextMenu,
    changeDepotOnly,
    isUpdating,
    error,
    setError,
    commentDialog,
    commentErrors,
    comment,
    commentBusy,
    depotDialog,
    depotErrors,
    depot_id: depotId,
    depotBusy,
    setComment,
    onCloseCommentDialog,
    onCloseDepotDialog,
    onChangeComment,
    onChangeDepot,
    setDepot: setDepotId,
  };
}
