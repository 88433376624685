import { useTheme } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGarageSettings } from "../../common/useGarageSettings";
import { useGetVehicleTypeListQuery } from "../../data/api/hooks";
import {
  Schedule,
  ScheduleDay,
  ScheduleDriver,
  ScheduleParameters,
} from "../../data/api/types/schedule";
import { RootState } from "../../data/store";
import { backgroundFromStateId, foregroundFromStateId } from "./helpers";
import {
  // isLeave,
  // isLeaveOverRest,
  // isPreliminaryState,
  // isRest,
  isTakenRest,
} from "./stateFunctions";
import { TableColumnRef, TableRowRef } from "./types";
import { useScheduleSummary } from "./useScheduleSummary";

export function filterGeneralIssues(driver: ScheduleDriver) {
  //console.log("issues");
  // const hasUndefinedDays = Object.values(driver?.driver_days || []).find(
  //   (d) => d.state === 0 || d.state === 1
  // );
  const receivedIssues = driver?.general_issues
    ? driver.general_issues.filter((gi) => gi.id !== 10 && gi.id !== 12)
    : [];
  // if (hasUndefinedDays) {
  //   receivedIssues.push({
  //     id: -1,
  //     description: `Не всички дни са попълнени, първият непопълнен ден е: ${moment(
  //       hasUndefinedDays.day_in_month,
  //       "YYYY-MM-DD"
  //     ).format("DD.MM.YYYY г.")}`,
  //   });
  // }

  return receivedIssues;
}

function generateDriverHeader(driver: ScheduleDriver) {
  return driver.attributes?.preferred_workshift &&
    driver.attributes.preferred_workshift > 0
    ? `${driver.sl_number} (${driver.attributes.preferred_workshift})`
    : driver.sl_number;
}

function generateDriverSubheader(driver: ScheduleDriver) {
  return driver.attributes?.preferred_vehicle &&
    driver.attributes.preferred_vehicle > 0
    ? driver.attributes.preferred_vehicle
    : undefined;
}

function generateDriverHeaderTitle(
  driver: ScheduleDriver,
  driverName: string | null,
  vehicleTypes:
    | Record<
        number,
        {
          id: number;
          description: string;
          koef: number;
          places: number;
        }
      >
    | undefined
) {
  const general_issues = filterGeneralIssues(driver);

  const vehicleType =
    !!vehicleTypes &&
    !!driver.attributes?.preferred_vehicle_type &&
    !!vehicleTypes[driver.attributes.preferred_vehicle_type]
      ? vehicleTypes[driver.attributes.preferred_vehicle_type].description
      : driver.attributes?.preferred_vehicle_type?.toString() || "";

  return [
    `Водач №${driver.sl_number}`,
    driverName && driverName.trim() !== "" ? `Име: ${driverName}` : null,
    driver.attributes?.preferred_workshift &&
    driver.attributes.preferred_workshift > 0
      ? `Предпочитана работна смяна: ${driver.attributes.preferred_workshift}`
      : undefined,
    driver.attributes?.preferred_vehicle
      ? `Марка/ПС: ${vehicleType || "Неизвестна"} (${
          driver.attributes.preferred_vehicle_type || 0
        })/${driver.attributes?.preferred_vehicle}`
      : undefined,
    driver.attributes?.vehicletypes_skills?.map(
      (s) => `Способности: ${s.description}`
    ) || [],
    driver.attributes?.no_overtime ? "Без извънредно работно време" : undefined,
    driver.attributes?.work_by_schedule ? "Работа по график" : undefined,
    driver.attributes?.rest_on_weekends ? "Почива в празнични дни" : undefined,
    general_issues.length > 0
      ? [
          "",
          "Открити проблеми:",
          ...general_issues.map((gi) => `    • ${gi.description}`),
        ]
      : undefined,
  ]
    .flat()
    .filter((l) => l !== null && l !== undefined)
    .join("\n");
}

function generateDriverDayTitle(
  driver_day: ScheduleDay,
  isPreliminary: boolean
) {
  const lines: string[] = [];

  // if (
  //   isPreliminary &&
  //   driver_day.pre_state &&
  //   isLeave(driver_day.pre_state.state_id) &&
  //   isRest(driver_day.state)
  // ) {
  //   lines.push(
  //     `График: ${driver_day.pre_state.state_id}: ${driver_day.pre_state.description}`
  //   );
  //   lines.push(
  //     `Предварителен график: ${driver_day.state}: ${driver_day.state_name}`
  //   );
  // } else if (isPreliminary && driver_day.pre_state) {
  //   if (driver_day.pre_state.state_id !== 1) {
  //     lines.push(
  //       `График: ${driver_day.pre_state.state_id}: ${driver_day.pre_state.description}`
  //     );
  //   }
  //   lines.push(
  //     `Предварителен график: ${driver_day.state}: ${driver_day.state_name}`
  //   );
  // }

  if (isTakenRest(isPreliminary, driver_day.pre_state, driver_day.state)) {
    lines.push(`${driver_day.state}: ${driver_day.state_name}`);
    lines.push("Взета почивка");
  } else {
    lines.push(`${driver_day.state}: ${driver_day.state_name}`);
  }

  const seventhWorkDay = driver_day.issues?.find((issue) => issue.id === 170);
  const issues =
    driver_day.issues
      ?.filter((issue) => issue.id !== 170)
      .map((issue) => `    • ${issue.description}`) || [];

  if (seventhWorkDay) {
    lines.push(seventhWorkDay.description);
  }

  if (issues.length > 0 || driver_day.expired_docs) {
    lines.push("Открити проблеми:");
    if (driver_day.expired_docs) {
      lines.push("    • Водачът е с изтекли документи!");
    }
    lines.push(...issues);
  }

  if (driver_day.comment && driver_day.comment.trim() !== "") {
    lines.push("", `Коментар: ${driver_day.comment}`);
  }

  return lines.join("\n");
}

export function useTableData(
  scheduleParameters: ScheduleParameters | null,
  schedule: Schedule | undefined,
  daysInMonth: number[],
  selectedVehicleType: number
) {
  //console.log("issues");
  const theme = useTheme();
  const settings = useGarageSettings();

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  // const selectedDepot = useSelector(
  //   (state: RootState) => state.filters.selectedDepot
  // );
  const { currentData: vehicleTypes } = useGetVehicleTypeListQuery(
    selectedTransport,
    {
      refetchOnMountOrArgChange: 1,
      skip: selectedTransport === "",
    }
  );
  //console.log("Vehicle types:", vehicleTypes);

  const { drivers, daySummary, driverSummary } = useScheduleSummary(
    scheduleParameters,
    schedule,
    daysInMonth,
    selectedVehicleType
  );

  // const apiDrivers = useGetDriversListQuery(
  //   { transport: selectedTransport, garage: selectedDepot },
  //   { skip: selectedTransport === "" || selectedDepot <= 0 }
  // );
  // const driverNames = useMemo(
  //   () =>
  //     Object.fromEntries(
  //       (apiDrivers.currentData?.drivers || []).map((d) => [
  //         d.sl_number,
  //         d.name,
  //       ])
  //     ),
  //   [apiDrivers.currentData?.drivers]
  // );

  const columns = useMemo<TableColumnRef[]>(() => {
    if (scheduleParameters) {
      //console.time("UI columns");
      const columns = daysInMonth.map((d) => {
        const date = moment(
          `${scheduleParameters[2]}-${scheduleParameters[3]}-${d + 1}`,
          "YYYY-MM-DD"
        );
        return {
          id: date.format("YYYY-MM-DD"),
          content: `${d + 1}`,
          title: date.format("DD.MM.YYYY г."),
          date: date,
        };
      });
      //console.timeEnd("UI columns");
      return columns;
    } else {
      return [];
    }
  }, [daysInMonth, scheduleParameters]);

  const isPreliminary = useSelector(
    (state: RootState) =>
      state.schedule.schedule?.db_schedule?.preliminary_ready !== true
  );

  //console.log("Create rows");
  const rows = useMemo<TableRowRef[]>(() => {
    if (scheduleParameters && daysInMonth.length > 0 && drivers.length > 0) {
      //console.time("UI rows");
      const dateStrings = daysInMonth.map(
        (d) =>
          `${scheduleParameters[2]
            .toString()
            .padStart(2, "0")}-${scheduleParameters[3]
            .toString()
            .padStart(2, "0")}-${(d + 1).toString().padStart(2, "0")}`
      );
      //const dates = dateStrings.map((d) => moment(d, "YYYY-MM-DD"));

      const rows = drivers.flatMap<TableRowRef>((driver, idx, drivers) => {
        const driverRow: TableRowRef = {
          rowType: "driver",
          id: driver.id,
          driver: driver,
          header: generateDriverHeader(driver),
          subheader: generateDriverSubheader(driver),
          headerTitle: generateDriverHeaderTitle(
            driver,
            /*driverNames[driver.sl_number] ||*/ driver.name || null,
            vehicleTypes
          ),
          headerWidth: 45,
          cells: dateStrings.map((date) => {
            const driver_day = driver.driver_days[date] || {
              comment: null,
              day_in_month: date,
              day_workseconds_by_roster: 0,
              daytasks: [],
              desired_state: null,
              desired_state_id: null,
              full_id: `${driver.id}_${date}`,
              issues: [
                { description: "Липсват данни", id: -1, level: 1, target: 1 },
              ],
              roster_issues: [],
              state: 1,
              state_name: "Лиспват данни",
              state_short_name: "",
              pre_state_id: null,
              pre_state: null,
              total_quota: 0,
              total_workseconds: 0,
              total_workseconds_by_roster: 0,
              work_week_workseconds: null,
              workshift: {
                car_no: null,
                end_time: null,
                linename: null,
                shift_type: null,
                start_time: null,
                work_seconds: null,
              },
            };

            let cellErrorKind: "error" | "warning" | undefined = undefined;
            if (driver_day.issues && driver_day.issues.length > 0) {
              const allLevel2 =
                driver_day.issues.filter((i) => i.level === 2).length ===
                driver_day.issues.length;

              cellErrorKind = allLevel2 ? "warning" : "error";
            } else if (driver_day.expired_docs) {
              cellErrorKind = "error";
            }

            let content: React.ReactNode;
            let colorStateId: number;
            // if (
            //   isLeaveOverRest(
            //     isPreliminary,
            //     driver_day.pre_state,
            //     driver_day.state
            //   )
            // ) {
            //   content = "П";
            //   colorStateId = driver_day.pre_state?.state_id || 15;
            // } else if (
            //   isPreliminaryState(isPreliminary, driver_day.pre_state)
            // ) {
            //   content = driver_day.pre_state?.abbrev || "";
            //   colorStateId = driver_day.pre_state?.state_id || 1;
            // } else if (
            if (
              isTakenRest(isPreliminary, driver_day.pre_state, driver_day.state)
            ) {
              content = (
                <>
                  {driver_day.state_short_name}
                  {/*<sup>П</sup>*/}
                </>
              );
              //colorStateId = driver_day.state;
              colorStateId = -1;
            } else {
              content = driver_day.state_short_name;
              colorStateId = driver_day.state;
            }

            return {
              id: driver_day.day_in_month,
              content: content,
              //title: `${driver_day.state}: ${driver_day.state_name}`,
              title: generateDriverDayTitle(driver_day, isPreliminary),
              cellErrorKind: cellErrorKind,
              backgroundColor: backgroundFromStateId(theme, colorStateId),
              color: foregroundFromStateId(theme, colorStateId),
              //date: moment(driver_day.day_in_month),
              date: moment(date, "YYYY-MM-DD"),
              driver_day: driver_day,
              driver: driver,
            };
          }),
        };

        if (
          settings.scheduleSortOrder === "by_vehicle_type" &&
          (idx === 0 ||
            (idx > 0 &&
              driver.attributes?.preferred_vehicle_type !==
                drivers[idx - 1]?.attributes?.preferred_vehicle_type))
        ) {
          return [
            {
              rowType: "vehicle_type",
              id: driver.attributes?.preferred_vehicle_type || 0,
              description:
                driver.attributes?.preferred_vehicle_type &&
                vehicleTypes &&
                vehicleTypes[driver.attributes?.preferred_vehicle_type]
                  ? vehicleTypes[driver.attributes?.preferred_vehicle_type]
                      .description
                  : driver.attributes?.preferred_vehicle_type?.toString() ||
                    "Сменници",
              headerColumns: 6,
              //dayColumns: Object.entries(driver.driver_days).length,
              dayColumns: daysInMonth.length,
            },
            driverRow,
          ];
        } else {
          return driverRow;
        }
      });
      //console.timeEnd("UI rows");
      return rows;
    } else {
      return [];
    }
  }, [
    daysInMonth,
    drivers,
    isPreliminary,
    scheduleParameters,
    settings.scheduleSortOrder,
    theme,
    vehicleTypes,
  ]);

  return {
    drivers,
    rows,
    columns,
    daySummary,
    driverSummary,
  };
}
