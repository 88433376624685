import { PropsWithChildren, useState } from "react";
import { grey, red } from "@mui/material/colors";
import { FilterAlt } from "@mui/icons-material";
import { TitleHeaderCell } from "./TableElements";
import { Input, styled, useTheme } from "@mui/material";
import { useAppDispatch } from "../../data/store";
import { clearScheduleSelection } from "../../data/scheduleSlice";

export const EditableCellInput = styled("input")(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  border: "none",
  borderRadius: 0,
  appearance: "none",
  outline: "none",
  padding: 0,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: "bold",

  color: grey[600],

  "&:focus": {
    border: "none",
    borderRadius: 0,
    appearance: "none",
    outline: "none",
  },
}));

const HIDE_INPUT = false;

export function EditableCell({
  children,
  width,
  fixLeftIdx,
  value,
  onChange,
  setDriverFilterField,
}: PropsWithChildren<{
  width?: number;
  fixLeftIdx?: number;
  value?: string;
  onChange?: (value: string) => void;
  setDriverFilterField?: (value: HTMLElement | null) => void;
}>) {
  //const theme = useTheme();
  const dispatch = useAppDispatch();
  const [editing, setEditing] = useState(false);

  return (
    <TitleHeaderCell
      tabIndex={0}
      width={width}
      fixLeftIdx={fixLeftIdx}
      style={{
        cursor: "text",
        borderBottom: editing ? `1px solid ${red[500]}` : undefined,
        justifyContent: "center",
        padding: "0px 2px",
      }}
      onClick={() => setEditing(true)}
    >
      {!HIDE_INPUT || editing ? (
        <>
          <EditableCellInput
            type="text"
            autoFocus
            value={value}
            onBlur={() => {
              setEditing(false);
              console.log("Blur edit box");
            }}
            onFocus={(e) => {
              e.target.select();
              dispatch(clearScheduleSelection());
              console.log("Focus edit box");
            }}
            onChange={(e) => onChange && onChange(e.target.value)}
            ref={setDriverFilterField}
          />
          <FilterAlt
            color="disabled"
            style={{
              pointerEvents: "none",
              position: "absolute",
              right: 2,
              opacity: 0.4,
            }}
          />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: grey[600],
          }}
        >
          <div style={{ flexGrow: 1 }}>{children}</div>
          <FilterAlt color="disabled" />
        </div>
      )}
    </TitleHeaderCell>
  );
}
