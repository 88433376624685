import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { SpecialShift } from "./useSpecialShift";
import { useCallback, useEffect, useState } from "react";
import { Transport } from "../../data/api/types/driver";
import moment, { Moment } from "moment";
import { usePostSpecialShiftMutation } from "../../data/api/hooks";
import { QueryError } from "../QueryError";
import { SHIFT2_SECONDS } from "../reports/useTimetables_ByDate";

export function SpecialShiftEditForm({
  open,
  setOpen,
  transport,
  depot,
  autocolumn,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transport: Transport;
  depot: number;
  autocolumn: number;
}) {
  const [value, setValue] = useState<SpecialShift>({
    transport: transport,
    depot_id: depot,
    auto_fill: true,
    auto_fill_count: 0,
    description: "",
    id: -1,
    end_time: 0,
    start_time: 0,
    shift_type: 1,
    validity_start: moment(),
    validity_end: null,
    autocolumn: autocolumn,
  });

  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [addSpecialShift, resultAddSpecialShift] =
    usePostSpecialShiftMutation();

  const handleClose = useCallback(() => {
    if (!isChanged) {
      console.log("Close");
      setIsChanged(false);
      setOpen(false);
    } else {
      if (
        window.confirm(
          "Направените промени няма да бъдат записани. Желаете ли да продължите?"
        )
      ) {
        setIsChanged(false);
        setOpen(false);
      }
    }
  }, [isChanged, setOpen]);

  useEffect(() => {
    if (resultAddSpecialShift.isSuccess && isSaving) {
      console.log("useEffect Close", resultAddSpecialShift.isSuccess, isSaving);
      setIsChanged(false);
      setIsSaving(false);
      setOpen(false);
    }
  }, [handleClose, isSaving, resultAddSpecialShift.isSuccess, setOpen]);

  //   useEffect(() => {
  //     if (isChanged) {
  //       const onUnload = (e: BeforeUnloadEvent) => {
  //         e.preventDefault();
  //         e.returnValue = "";
  //       };

  //       window.addEventListener("beforeunload", onUnload);
  //       return () => window.removeEventListener("beforeunload", onUnload);
  //     }
  //   }, [isChanged]);

  const handleOk = useCallback(() => {
    addSpecialShift({ shift: value });
    setIsSaving(true);
  }, [addSpecialShift, value]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChanged(true);
      value.description = event.target.value;
      setValue(value);
    },
    [value]
  );

  const handleStartChange = useCallback(
    (newValue: Moment | null) => {
      setIsChanged(true);
      value.start_time =
        moment(newValue).hours() * 3600 + moment(newValue).minutes() * 60;

      value.shift_type = value.start_time >= SHIFT2_SECONDS ? 2 : 1;

      setValue(value);
      //   setValue({
      //     ...value,
      //     start_time:
      //       moment(newValue).hours() * 3600 + moment(newValue).minutes() * 60,
      //   });
    },
    [value]
  );
  const handleEndChange = useCallback(
    (newValue: Moment | null) => {
      setIsChanged(true);
      value.end_time =
        moment(newValue).hours() * 3600 + moment(newValue).minutes() * 60;
      setValue(value);
      //   setValue({
      //     ...value,
      //     end_time:
      //       moment(newValue).hours() * 3600 + moment(newValue).minutes() * 60,
      //   });
    },
    [value]
  );

  const handleCountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChanged(true);
      value.auto_fill_count = event.target.value as any as number;
      setValue(value);

      //   setValue({
      //     ...value,
      //     auto_fill_count: event.target.value as any as number,
      //   });
    },
    [value]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onSubmit={handleOk}
      // onLoad={() => document.getElementById("name")?.focus()}
    >
      <DialogTitle>Нова специална смяна</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Моля, въведете параметри за смяната.
        </DialogContentText>
        {resultAddSpecialShift.isError && <QueryError error={null} />}
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <label>Име</label>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    inputRef={(element) => {
                      element?.focus();
                      //   setTimeout(() => {
                      //     element?.focus();
                      //   }, 0);
                    }}
                    sx={{ padding: 2 }}
                    id="name"
                    type="text"
                    variant="standard"
                    multiline
                    onChange={handleNameChange}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label>Начало</label>
                </TableCell>
                <TableCell>
                  <TimePicker
                    sx={{ width: 100 }}
                    defaultValue={moment("00:00", "hh:mm")}
                    onChange={handleStartChange}
                  ></TimePicker>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label>Край</label>
                </TableCell>
                <TableCell>
                  <TimePicker
                    sx={{ width: 100 }}
                    defaultValue={moment("00:00", "hh:mm")}
                    onChange={handleEndChange}
                  ></TimePicker>

                  {/* <TextField
                    sx={{ width: 100 }}
                    //defaultValue={moment("00:00", "hh:mm")}
                    type="text"
                    // onChange={handleEndChange}
                  >
                    <InputMask
                      mask="99:59"
                      //alwaysShowMask={false}
                      maskChar="_"
                    />
                  </TextField> */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label>Брой смени</label>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    defaultValue={0}
                    style={{ width: 100 }}
                    onChange={handleCountChange}
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isChanged} onClick={handleOk}>
          Запис
        </Button>
        <Button onClick={handleClose}>Изход</Button>
      </DialogActions>
    </Dialog>
  );
}
