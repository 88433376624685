import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { Vehicle } from "../../data/api/types/driver";
import {
  DroppableDriversContainer,
  useDropOnDrivers,
} from "./DroppableDriversContainer";
import {
  DroppableShiftContainer,
  useDropOnShifts,
} from "./DroppableShiftContainer";
import { RosterFilter } from "./RosterFilter";
import { RosterTopMenu } from "./RosterTopMenu";
import {
  DroppableVehiclesContainer,
  useDropOnVehicle,
} from "./DroppableVehiclesContainer";
import { createRoster, emptyRoster } from "./types/createRoster";
import { DayExplPlanRoster } from "../reports/useTimetables_ByDate";
import { useSchedules } from "../../data/schedules/useSchedules";
import { Close } from "@mui/icons-material";
import { useVehicleTypesList } from "../schedule/useVehicleTypesList";
import { useVehicles } from "../../data/schedules/useVehicles";
import { RosterShift, RosterVehicle } from "./types/Roster";
import { useProfile } from "../../common/useProfile";
import { ChangeVehicleDialog } from "./ChangeVehicleDialog";
import moment from "moment";
import { grey, red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../data/store";
import { usePostVehicleCompositionMutation } from "../../data/api/hooks";

export const DriverRosterInt = memo(function DriverRosterInt({
  api_vehicles,
  driversLoading,
  explPlan,
  explPlanLoading,
  selectedDate,
}: {
  api_vehicles: Vehicle[];
  driversLoading: boolean;
  explPlan: DayExplPlanRoster;
  explPlanLoading: boolean;
  selectedDate: string;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_roster");

  const { scheduleParameters } = useScheduleParameters();

  const {
    schedule,
    clearDriverTasks,
    editDriverDayRosterComment,
    addShiftToVehicle,
    changeTaskVehicle,
    createRoster: initRoster,
  } = useSchedules();

  useEffect(() => {
    initRoster(selectedDate);
  }, [initRoster, selectedDate]);

  const { vehicles, ...vehiclesSchedule } = useVehicles();
  const { allVehicleTypes, vehicleTypesLoading, vehicleTypesError } =
    useVehicleTypesList(schedule);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<number[]>(
    []
  );
  const [selectedLines, setSelectedLines] = useState<number[]>([]);

  const vehiclesScheduleConnected = useSelector(
    (state: RootState) => state.schedule.vehiclesScheduleConnected
  );
  const scheduleConnected = useSelector(
    (state: RootState) => state.schedule.scheduleConnected
  );
  const socketConnected = useSelector(
    (state: RootState) => state.schedule.socketConnected
  );
  const connected =
    vehiclesScheduleConnected &&
    scheduleConnected &&
    socketConnected &&
    schedule?.drivers_sync_status?.status === "ok" &&
    !vehicleTypesLoading &&
    !explPlanLoading &&
    !driversLoading;

  console.log("Connection status:", connected);

  const roster = useMemo(
    () =>
      scheduleParameters &&
      schedule &&
      vehicles?.vehicles &&
      !vehicleTypesError &&
      !vehicleTypesLoading
        ? createRoster(
            scheduleParameters[4],
            selectedDate,
            // drivers,
            vehicles.vehicles,
            schedule,
            explPlan,
            allVehicleTypes
          )
        : emptyRoster,
    [
      scheduleParameters,
      schedule,
      vehicles?.vehicles,
      vehicleTypesError,
      vehicleTypesLoading,
      selectedDate,
      explPlan,
      allVehicleTypes,
    ]
  );
  // console.log("Roster:", roster);

  const extraDrivers = useMemo(
    () =>
      Object.values(schedule?.drivers || {}).filter(
        // Стажанти
        (d) => d.driver_days[selectedDate]?.state === 9
      ),
    [schedule?.drivers, selectedDate]
  );

  const { dropOnVehicle, errors, clearErrors } = useDropOnVehicle(
    roster,
    selectedDate,
    isReadOnly
  );
  const dropOnShifts = useDropOnShifts(roster, selectedDate, isReadOnly);
  const dropOnDrivers = useDropOnDrivers(roster, selectedDate, isReadOnly);

  const freeShiftsCount1 = useMemo(
    () =>
      roster.unusedShifts.reduce(
        (acc, s) => (s.shift.workshift.shift_type !== 2 ? acc + 1 : acc),
        0
      ),
    [roster.unusedShifts]
  );
  const freeShiftsCount2 = useMemo(
    () => roster.unusedShifts.length - freeShiftsCount1,
    [freeShiftsCount1, roster.unusedShifts.length]
  );

  const [changeComposition] = usePostVehicleCompositionMutation();

  const saveVehicleComposition = useCallback(
    async (vehicle: RosterVehicle, secondVehicle: RosterVehicle | null) => {
      if (isReadOnly || !scheduleParameters) {
        return;
      }

      console.log("Change composition", vehicle, secondVehicle);

      const result = await changeComposition({
        transport: scheduleParameters[0],
        depot: scheduleParameters[1],
        veh1_id: vehicle.vehicle_id,
        veh2_id: secondVehicle?.vehicle_id || null,
        start_date: selectedDate,
        end_date: null,
      }).unwrap();

      if (result?.status !== "ok") {
        throw result;
      }
    },
    [changeComposition, isReadOnly, scheduleParameters, selectedDate]
  );

  const saveVehicleComment = useCallback(
    async (vehicle: RosterVehicle, comment: string | null) => {
      if (isReadOnly) {
        return;
      }

      console.log("Add comment", comment, "to", vehicle);
      const result = await vehiclesSchedule.editVehicleDayComment({
        vehicle_id: vehicle.vehicle_id,
        date: selectedDate,
        comment,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, selectedDate, vehiclesSchedule]
  );

  const saveVehicleState = useCallback(
    async (vehicle: RosterVehicle, state: number, comment: string | null) => {
      if (isReadOnly) {
        return;
      }

      console.log("Add state", state, comment, "to", vehicle);
      const result = await vehiclesSchedule.addVehicleInterval({
        vehicle_id: vehicle.vehicle_id,
        start_date: selectedDate,
        end_date: null,
        state_id: state,
        comment,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, selectedDate, vehiclesSchedule]
  );

  const clearVehicleState = useCallback(
    async (vehicle: RosterVehicle) => {
      if (isReadOnly) {
        return;
      }

      console.log("Clear state of", vehicle);
      const result = await vehiclesSchedule.addVehicleInterval({
        vehicle_id: vehicle.vehicle_id,
        start_date: selectedDate,
        end_date: null,
        state_id: 0,
        comment: null,
      });
      if (result?.status !== "ok") {
        throw result;
      }
    },
    [isReadOnly, selectedDate, vehiclesSchedule]
  );

  const [changeVehicleDialogShift, setChangeVehicleDialogShift] =
    useState<RosterShift | null>(null);
  const [changeVehicleDialogUiVehicle, setChangeVehicleDialogUiVehicle] =
    useState<number | null>(null);

  const driversContainerAccept = useMemo(
    () => (isReadOnly ? [] : ["driver-shift-1", "driver-shift-2"]),
    [isReadOnly]
  );

  const errorIntershiftTimes = useMemo(
    () =>
      roster.vehicles
        .reduce<number[]>(
          (vehicleAcc, vehicle) =>
            vehicleAcc.concat(
              vehicle.shifts.reduce<number[]>(
                (driversAcc, drivers) =>
                  driversAcc.concat(
                    drivers.reduce<number[]>(
                      (driverAcc, driver) =>
                        driver.roster_issues.find(
                          (issue) => issue.id === 176 || issue.id === 178
                        ) !== undefined
                          ? driverAcc.concat(driver.sl_number)
                          : driverAcc,
                      []
                    )
                  ),
                []
              )
            ),
          []
        )
        .flat(),
    [roster.vehicles]
  );

  const getRoster = useCallback(() => roster, [roster]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
        }}
        open={!connected}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <RosterTopMenu
        isReadOnly={isReadOnly}
        scheduleParameters={scheduleParameters}
        selectedDate={selectedDate}
        // vehicles={roster.vehicles}
        // drivers={drivers.drivers}
        drivers={schedule ? [...Object.values(schedule.drivers)] : []}
        extraDrivers={extraDrivers}
        apiVehicles={api_vehicles}
        // shifts={roster.allShifts}
        errorIntershiftTimes={errorIntershiftTimes}
        getRoster={getRoster}
        cleanupRoster={() => {
          if (
            window.confirm(
              `Сигурни ли сте че желаете да изтриете целия наряд за ${moment(
                selectedDate,
                "YYYY-MM-DD"
              ).format("LL")}?`
            )
          ) {
            clearDriverTasks(selectedDate);
          }
        }}
      />

      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <RosterFilter
          schedule={schedule}
          shifts={roster.allShifts}
          selectedVehicleTypes={selectedVehicleTypes}
          selectedLines={selectedLines}
          setSelectedVehicleTypes={setSelectedVehicleTypes}
          setSelectedLines={setSelectedLines}
        />

        {errors.length > 0 ? (
          <Alert
            severity="error"
            sx={{ mx: 1, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => clearErrors()}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Грешки:</AlertTitle>
            {errors.map((error, idx) => (
              <Typography key={idx}>{error}</Typography>
            ))}
          </Alert>
        ) : undefined}

        <Box style={{ flexGrow: 1, overflow: "auto" }}>
          <DndProvider backend={HTML5Backend}>
            <Box
              sx={{
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <Box sx={{ height: "100%", p: 1, minWidth: 215, maxWidth: 215 }}>
                <DroppableShiftContainer
                  isReadOnly={isReadOnly}
                  title={`Смени: ${freeShiftsCount1} 1-ва  ---  ${freeShiftsCount2} 2-ра`}
                  headerSx={{
                    backgroundColor:
                      freeShiftsCount1 > 0 || freeShiftsCount2 > 0
                        ? red[800]
                        : grey[300],
                    "& .MuiCardHeader-title": {
                      color:
                        freeShiftsCount1 > 0 || freeShiftsCount2 > 0
                          ? "white"
                          : undefined,
                    },
                  }}
                  roster={roster}
                  selectedDate={selectedDate}
                  selectedLines={selectedLines}
                  accept={
                    isReadOnly
                      ? []
                      : [
                          "timetable-shift-multiple-1",
                          "special-shift-multiple-1",
                          "timetable-shift-multiple-2",
                          "special-shift-multiple-2",
                          "timetable-shift-multiple-3",
                          "timetable-shift-single-1",
                          "special-shift-single-1",
                          "timetable-shift-single-2",
                          "special-shift-single-2",
                          "timetable-shift-single-3",
                        ]
                  }
                  onDrop={dropOnShifts}
                  openChangeVehicleDialog={(shift, uiVehicle) => {
                    setChangeVehicleDialogShift(shift);
                    setChangeVehicleDialogUiVehicle(uiVehicle);
                  }}
                />
              </Box>

              <Box
                sx={{
                  maxHeight: "100%",
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <DroppableVehiclesContainer
                  isReadOnly={isReadOnly}
                  title={`Автобуси:  ---  водачи без смяна: ${roster.summary.driversWithNoShift}  ---  свободни автобуси: ${roster.summary.unusedVehicles}`}
                  headerSx={{
                    backgroundColor:
                      roster.summary.driversWithNoShift > 0
                        ? red[800]
                        : grey[300],
                    "& .MuiCardHeader-title": {
                      color:
                        roster.summary.driversWithNoShift > 0
                          ? "white"
                          : undefined,
                    },
                  }}
                  roster={roster}
                  selectedDate={selectedDate}
                  selectedVehicleTypes={selectedVehicleTypes}
                  // drivers={drivers.drivers}
                  drivers={schedule ? [...Object.values(schedule.drivers)] : []}
                  apiVehicles={api_vehicles}
                  onDrop={dropOnVehicle}
                  saveShift={addShiftToVehicle}
                  saveDriverComment={editDriverDayRosterComment}
                  saveVehicleComment={saveVehicleComment}
                  saveVehicleState={saveVehicleState}
                  clearVehicleState={clearVehicleState}
                  openChangeVehicleDialog={(shift, uiVehicle) => {
                    setChangeVehicleDialogShift(shift);
                    setChangeVehicleDialogUiVehicle(uiVehicle);
                  }}
                  saveComposition={saveVehicleComposition}
                />
                <DroppableDriversContainer
                  isReadOnly={isReadOnly}
                  title={`Свободни водачи: ${roster.unusedDrivers.length}`}
                  roster={roster}
                  selectedDate={selectedDate}
                  selectedVehicleTypes={selectedVehicleTypes}
                  selectedLines={selectedLines}
                  accept={driversContainerAccept}
                  onDrop={dropOnDrivers}
                  saveComment={editDriverDayRosterComment}
                  saveShift={addShiftToVehicle}
                />
              </Box>
            </Box>
          </DndProvider>
        </Box>
      </Box>

      <ChangeVehicleDialog
        isReadOnly={isReadOnly}
        shift={changeVehicleDialogShift}
        inUiVehicle={changeVehicleDialogUiVehicle}
        vehicles={roster.vehicles}
        selectedDate={selectedDate}
        closeDialog={() => setChangeVehicleDialogShift(null)}
        changeTaskVehicle={changeTaskVehicle}
      />
    </div>
  );
});
