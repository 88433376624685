import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow as TableRowMUI,
} from "@mui/material";
import { Moment } from "moment";
import {
  DayRosterColumns,
  DayRosterData,
  DayRosterElectroColumns,
} from "./helpers";
import React from "react";
import { updateSelectedTransport } from "../../../data/filtersSlice";

export const TableRow = styled(TableRowMUI)(() => ({
  height: 25,
}));

export const TableCell = styled(TableCellMUI)(() => ({
  padding: "0px 16px",
}));

export const TableCellBorder = styled(TableCellMUI)(() => ({
  padding: "0px 16px",
  borderTop: "1px solid black",
}));

export function StickyHeadTable({
  rows,
  selectedDate,
  selectedTransport,
}: {
  rows: DayRosterData[];
  selectedDate: Moment;
  totalDriverCount: number;
  selectedTransport: string;
}) {
  // console.log("Rows:", rows);

  return (
    <Paper sx={{ height: "100%", px: 20, py: 6 }}>
      <h2 style={{ padding: "10" }}>
        {`НАРЯД ЗА ДАТА ${selectedDate.format("LL")}`}
      </h2>
      <TableContainer sx={{ height: "100%", border: "1pt solid black" }}>
        <Table
          id="roster-report-table"
          stickyHeader
          aria-label="stickyHeader"
          style={{ border: "line-width" }}
        >
          <TableHead>
            <TableRow>
              {selectedTransport === "A"
                ? DayRosterColumns.map((column) => (
                    <TableCellMUI
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCellMUI>
                  ))
                : DayRosterElectroColumns.map((column) => (
                    <TableCellMUI
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCellMUI>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item, idx, array) => (
              <React.Fragment key={idx}>
                <TableRow key={idx}>
                  {/* {(old_line = item.line_name)} */}
                  {idx === 0 ||
                  (array[idx - 1] &&
                    array[idx - 1].line_name !== item.line_name) ? (
                    <React.Fragment key={idx}>
                      <TableCellBorder
                        align="center"
                        sx={{
                          fontWeight:
                            selectedTransport === "A" ? "normal" : "bold",
                        }}
                      >
                        {item.line_name}
                      </TableCellBorder>
                      <TableCellBorder align="center">
                        {item.car_no > -1 ? item.car_no : ""}
                      </TableCellBorder>
                      <TableCellBorder align="center">
                        {item.vehicle1 > -1 ? item.vehicle1 : ""}
                        {item.vehicle12 > -1 ? "/ " + item.vehicle12 : ""}
                      </TableCellBorder>
                      <TableCellBorder align="center">
                        {" "}
                        {item.driver1 > -1 ? item.driver1 : ""}{" "}
                      </TableCellBorder>
                      <TableCellBorder align="left">
                        {item.comment1
                          .split("\n")
                          .filter((c) => c && c.trim() !== "")
                          .map((c, idx) => (
                            <div key={idx}>{c}</div>
                          ))}
                      </TableCellBorder>
                      <TableCellBorder align="center">
                        {item.vehicle2 !== item.vehicle1 && item.vehicle2 > -1
                          ? item.vehicle2
                          : ""}
                        {item.vehicle2 !== item.vehicle1 && item.vehicle22 > -1
                          ? "/" + item.vehicle22
                          : ""}
                      </TableCellBorder>
                      <TableCellBorder align="center">
                        {" "}
                        {item.driver2 > -1 ? item.driver2 : ""}{" "}
                      </TableCellBorder>
                      {selectedTransport !== "A" && (
                        <TableCellBorder align="center">
                          {item.car_no > -1 ? item.car_no : ""}
                        </TableCellBorder>
                      )}
                      <TableCellBorder align="left">
                        {item.comment2
                          .split("\n")
                          .filter((c) => c && c.trim() !== "")
                          .map((c, idx) => (
                            <div key={idx}>{c}</div>
                          ))}
                      </TableCellBorder>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={idx}>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight:
                            selectedTransport === "A" ? "normal" : "bold",
                        }}
                      >
                        {item.line_name}
                      </TableCell>
                      <TableCell align="center">
                        {item.car_no > -1 ? item.car_no : ""}
                      </TableCell>
                      <TableCell align="center">
                        {item.vehicle1 > -1 ? item.vehicle1 : ""}
                        {item.vehicle12 > -1 ? "/ " + item.vehicle12 : ""}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {item.driver1 > -1 ? item.driver1 : ""}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {item.comment1
                          .split("\n")
                          .filter((c) => c && c.trim() !== "")
                          .map((c, idx) => (
                            <div key={idx}>{c}</div>
                          ))}
                      </TableCell>
                      <TableCell align="center">
                        {item.vehicle2 !== item.vehicle1 && item.vehicle2 > -1
                          ? item.vehicle2
                          : ""}
                        {item.vehicle2 !== item.vehicle1 && item.vehicle22 > -1
                          ? "/ " + item.vehicle22
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {item.driver2 > -1 ? item.driver2 : ""}{" "}
                      </TableCell>
                      {selectedTransport !== "A" && (
                        <TableCell align="center">
                          {item.car_no > -1 ? item.car_no : ""}
                        </TableCell>
                      )}
                      <TableCell align="left">
                        {item.comment2
                          .split("\n")
                          .filter((c) => c && c.trim() !== "")
                          .map((c, idx) => (
                            <div key={idx}>{c}</div>
                          ))}
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
