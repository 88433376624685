import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../common/useProfile";
import { Transport } from "../data/api/types/driver";
import { updateSelectedAutoColumn } from "../data/filtersSlice";
import { RootState, useAppDispatch } from "../data/store";

export function AutoColumnSelect({
  isInMenu,
  showAllAutocolumns,
}: {
  isInMenu?: boolean;
  showAllAutocolumns?: boolean;
}) {
  const dispatch = useAppDispatch();
  const value = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );
  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const profile = useProfile();
  const garageAutocolumns = useMemo(
    () =>
      profile?.garage_autocolumns?.[selectedTransport as Transport]?.[
        selectedDepot
      ] || [],
    [profile?.garage_autocolumns, selectedDepot, selectedTransport]
  );
  const allowedAutocolumns = useMemo(
    () => profile?.transports?.[selectedTransport]?.[selectedDepot] || [],
    [profile?.transports, selectedDepot, selectedTransport]
  );
  const availableAutocolumns = useMemo(
    () => (showAllAutocolumns ? garageAutocolumns : allowedAutocolumns),
    [allowedAutocolumns, garageAutocolumns, showAllAutocolumns]
  );

  useEffect(() => {
    console.log("Autocolumn useEffect");
    return () => {
      if (!allowedAutocolumns.includes(selectedAutoColumn)) {
        console.log("Autocolumn useEffect: restore autocolumn");
        dispatch(updateSelectedAutoColumn({ autocolumn: -1 }));
      }
    };
  }, [allowedAutocolumns, dispatch, selectedAutoColumn, showAllAutocolumns]);

  return (
    <FormControl
      variant="standard"
      sx={{ m: 1, width: isInMenu ? "6.5em" : "100%" }}
    >
      <InputLabel id="autocolumn-select-label">Автоколона</InputLabel>
      <Select
        id="autocolumn-select-field"
        labelId="autocolumn-select-label"
        label="Автоколона"
        variant={isInMenu ? undefined : "standard"}
        value={value}
        onChange={(e) =>
          dispatch(
            updateSelectedAutoColumn({
              autocolumn: e.target.value,
              allowAnyAutocolumn: showAllAutocolumns,
            })
          )
        }
      >
        {availableAutocolumns.map((autocolumn) => (
          <MenuItem key={autocolumn} value={autocolumn}>
            {autocolumn}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
