import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { transportName } from "../common/useProfile";
import { useTransportFilter } from "./useTransportFilter";

export function TransportSelect({ isInMenu }: { isInMenu?: boolean }) {
  const { transports, selectedTransport, setSelectedTransport } =
    useTransportFilter();

  return (
    <FormControl
      variant={isInMenu ? undefined : "standard"}
      size={isInMenu ? "small" : undefined}
      sx={{ m: 1, width: isInMenu ? "10em" : "100%" }}
    >
      <InputLabel id="schedule-transport">Транспорт</InputLabel>
      <Select
        labelId="schedule-transport"
        id="schedule-transport-select"
        value={selectedTransport}
        label="Транспорт"
        onChange={(e) => setSelectedTransport(e.target.value)}
      >
        <MenuItem key={""} value={""} disabled>
          &mdash;
        </MenuItem>
        {transports.map((transport) => (
          <MenuItem key={transport} value={transport}>
            {transportName(transport)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
