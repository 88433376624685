import { DatePicker } from "@mui/x-date-pickers";
import { useMonthFilter } from "./useMonthFilter";

export function MonthPicker() {
  const { selectedMonth, setSelectedMonth } = useMonthFilter();

  return (
    <DatePicker
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(e)}
      label="Месец"
      views={["year", "month"]}
      openTo="month"
      slotProps={{
        textField: {
          variant: "standard",
          sx: { m: 1, width: "100%" },
        },
      }}
    />
  );
}
