import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Paper,
  TableContainer,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-router-dom";
import {
  useGetVehicleTypeListQuery,
  useGetVehiclesQuery,
} from "../../data/api/hooks";
import { RootState } from "../../data/store";
import { DepotSelect } from "../../filters/DepotSelect";
import { TransportSelect } from "../../filters/TransportSelect";
import { QueryError } from "../QueryError";
import {
  Data,
  VehicleFilter,
  VehicleType,
  columns,
  useVehiclesList,
} from "./useVehiclesList";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormRegister,
} from "react-hook-form";
import { TableComponents, TableVirtuoso } from "react-virtuoso";

import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useProfile } from "../../common/useProfile";

const VirtuosoTableComponents: TableComponents<
  FieldArrayWithId<
    {
      drivers: Data[];
    },
    "drivers",
    "id"
  >
> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: TableHead,
  TableRow: ({ ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function TableContenet({
  fields,
  filteredData,
  register,
  control,
  filter,
  setFilter,
  vehicletypes,
}: {
  fields: FieldArrayWithId<
    {
      vehicles: Data[];
    },
    "vehicles",
    "id"
  >[];
  filteredData: FieldArrayWithId<
    {
      vehicles: Data[];
    },
    "vehicles",
    "id"
  >[];
  register: UseFormRegister<{
    vehicles: Data[];
  }>;

  control: Control<{ vehicles: Data[] }, any>;
  filter: VehicleFilter | null;
  setFilter: React.Dispatch<React.SetStateAction<VehicleFilter | null>>;
  vehicletypes: VehicleType[] | null;
}) {
  const profile = useProfile();
  // console.log("FilterData: ", filteredData);
  return (
    <Paper sx={{ height: "100%" }}>
      {/* <Box sx={{ height: "100%" }}> */}

      <TableContainer sx={{ height: "100%" }}>
        {/* <Table stickyHeader aria-label="sticky table"> */}
        <TableVirtuoso
          data={filteredData}
          components={VirtuosoTableComponents}
          totalCount={filteredData.length}
          fixedHeaderContent={() => (
            <React.Fragment>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant="head"
                    style={{ width: column.minWidth }}
                    sx={{
                      backgroundColor: "background.paper",
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant="head"
                    style={{ width: column.minWidth }}
                    sx={{
                      backgroundColor: "background.paper",
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.id === "vehicle_type" && (
                      <Select
                        fullWidth
                        labelId="description" //{`drivers.${index}.skills`}
                        variant="standard"
                        value={filter?.vehicle_type ? filter.vehicle_type : ""}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.vehicle_type ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              size="small"
                              onClick={() =>
                                setFilter(
                                  filter
                                    ? { ...filter, vehicle_type: null }
                                    : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          const v: VehicleFilter | null = {
                            vehicle_type:
                              typeof value === "string" ? value : "",
                            vehicle_id: filter ? filter.vehicle_id : null,
                            autocolumn: filter ? filter.autocolumn : null,
                            periodic_tech_test: filter
                              ? filter.periodic_tech_test
                              : null,
                          };
                          setFilter(v);
                        }}
                      >
                        {vehicletypes ? (
                          vehicletypes.map((name) => (
                            <MenuItem key={name.id} value={name.description}>
                              {name.description}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem></MenuItem>
                        )}
                      </Select>
                    )}

                    {column.id !== "vehicle_type" && (
                      <TextField
                        variant="standard"
                        value={
                          filter && filter[column.id] ? filter[column.id] : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                sx={{
                                  display:
                                    filter && filter[column.id] ? "" : "none",
                                  p: 1,
                                  mr: 1,
                                }}
                                onClick={() => {
                                  const v: VehicleFilter = filter
                                    ? { ...filter }
                                    : {
                                        vehicle_id: null,
                                        vehicle_type: null,
                                        autocolumn: null,
                                        periodic_tech_test: null,
                                      };
                                  v[column.id] = null;
                                  if (column.id !== "autocolumn")
                                    v[column.id] = null;
                                  setFilter(v);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(data) => {
                          const v: VehicleFilter = filter
                            ? {
                                vehicle_id: filter.vehicle_id,
                                vehicle_type: filter.vehicle_type,
                                autocolumn: filter.autocolumn,
                                periodic_tech_test: filter.periodic_tech_test,
                              }
                            : {
                                vehicle_id: null,
                                vehicle_type: null,
                                autocolumn: null,
                                periodic_tech_test: null,
                              };

                          switch (column.id) {
                            case "vehicle_id":
                              v.vehicle_id = data.target.value;
                              break;
                            case "autocolumn":
                              v.autocolumn = data.target.value;
                              break;
                            case "periodic_tech_test":
                              v.periodic_tech_test = data.target.value;
                              break;
                          }

                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      ></TextField>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          )}
          // itemContent={rowContent}

          itemContent={(index, data) => {
            const idx = filteredData[index]
              ? fields.findIndex(
                  (x) => x.vehicle_id === filteredData[index].vehicle_id
                )
              : -1;

            if (idx !== -1)
              return (
                <React.Fragment key={data.vehicle_id}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      // padding=""
                      {...register(`vehicles.${idx}.${column.id}`)}
                      //style={ }
                    >
                      {column.id === "autocolumn" && (
                        <TextField
                          id="vehicle-auto-column-select"
                          //sx={{ m: 1, width: "100%" }}
                          variant="standard"
                          type="number"
                          {...register(`vehicles.${idx}.autocolumn`)}
                          InputProps={{
                            readOnly:
                              !profile?.roles?.includes("edit_schedule"),
                            disableUnderline: false,
                            inputProps: {
                              style: { textAlign: column.align },
                            },
                          }}
                        />
                      )}
                      {column.id === "periodic_tech_test" && (
                        //   <input
                        //     style={{ alignContent: "center" }}
                        //     type="date"
                        //     {...register(`vehicles.${idx}.periodic_tech_test`)}
                        //   />
                        <Controller
                          control={control}
                          name={`vehicles.${idx}.periodic_tech_test`}
                          render={({
                            field, //: { onChange, value, ...field },
                          }) => (
                            <DatePicker
                              readOnly={
                                !profile?.roles?.includes("edit_tech_service")
                              }
                              format="DD.MM.YYYY"
                              onChange={(value) => {
                                field.onChange(
                                  value ? value?.format("DD.MM.YYYY") : ""
                                );
                              }}
                              value={
                                field.value
                                  ? moment(field.value, "DD.MM.YYYY")
                                  : null
                              }
                              label="Дата"
                              views={["year", "month", "day"]}
                              openTo="day"
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  sx: { m: 1 },
                                },
                                actionBar: { actions: ["clear"] },
                              }}
                            />
                          )}
                        />
                      )}
                      {column.id !== "autocolumn" &&
                        column.id !== "periodic_tech_test" && (
                          <TextField
                            fullWidth
                            key={column.id}
                            {...register(`vehicles.${idx}.${column.id}`)}
                            variant="standard"
                            // sx={{ input: { textAlign: column.align } }}
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true,
                              inputProps: {
                                style: { textAlign: column.align },
                              },
                            }}
                          ></TextField>
                        )}
                    </TableCell>
                  ))}
                </React.Fragment>
              );
          }}
        ></TableVirtuoso>
        {/* </div> */}
      </TableContainer>
    </Paper>
  );
}

export function VehiclesList() {
  const profile = useProfile();
  const isReadOnly =
    !profile?.roles?.includes("edit_schedule") &&
    !profile?.roles?.includes("edit_tech_service");

  //const theme = useTheme();

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  //   const selectedAvtoColumn = useSelector(
  //     (state: RootState) => state.filters.selectedAutoColumn
  //   );

  const { data, isLoading, isError } = useGetVehiclesQuery(
    { transport: selectedTransport, garage: selectedDepot },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const [filter, setFilter] = useState<VehicleFilter | null>({
    vehicle_id: null,
    vehicle_type: null,
    autocolumn: null,
    periodic_tech_test: null,
  });

  const {
    currentData: vehicleTypesRecord,
    isLoading: vehicleTypesLoading,
    isError: vehicleTypesError,
  } = useGetVehicleTypeListQuery(selectedTransport, {
    skip: selectedTransport === "",
  });

  const rows = useMemo(
    () =>
      data?.data && vehicleTypesRecord
        ? Array.from(
            {
              length:
                //   filter(
                //     (veh) =>
                //       selectedAvtoColumn === 0 ||
                //       veh.autocolumn === selectedAvtoColumn
                //   ).
                data.data.length,
            },
            (_, index) => {
              const vehicle = data.data[index];
              //   filter(
              //     (veh) =>
              //       selectedAvtoColumn === 0 ||
              //       veh.autocolumn === selectedAvtoColumn
              //   )

              const idx = data.data.findIndex(
                (veh) => veh.vehicle_id === vehicle.vehicle_id
              );

              return {
                vehicle_id: data.data[idx].vehicle_id,
                vehicle_type: Object.values(vehicleTypesRecord).filter(
                  (vt) => vt.id === data.data[idx].vehicletype_id
                )[0]?.description,
                autocolumn: data.data[idx].autocolumn,
                periodic_tech_test: data.data[idx].periodic_tech_test
                  ? moment(data.data[idx].periodic_tech_test).format(
                      "DD.MM.YYYY"
                    )
                  : null,
              };
            }
          )
        : [],
    [data?.data, vehicleTypesRecord]
  );

  // console.log(
  //   "Rows:",
  //   rows.sort((a, b) => a.vehicle_id - b.vehicle_id)
  // );
  // console.log("VehTypes:", vehicleTypesRecord);

  const {
    register,
    control,
    handleSubmit,
    fields,
    isDirty,
    hasError,
    loading,
  } = useVehiclesList(
    rows.sort((a, b) => a.vehicle_id - b.vehicle_id),
    selectedTransport,
    selectedDepot,
    isLoading,
    isError
  );
  // console.log("Data:", data);

  const filteredData = useMemo(
    () =>
      fields.filter(
        (value) =>
          (!filter ||
            !filter.vehicle_id ||
            value.vehicle_id.toString().startsWith(filter.vehicle_id)) &&
          (!filter ||
            !filter.autocolumn ||
            value.autocolumn?.toString().startsWith(filter.autocolumn)) &&
          (!filter ||
            !filter.vehicle_type ||
            value.vehicle_type?.startsWith(filter.vehicle_type)) &&
          (!filter ||
            !filter.periodic_tech_test ||
            value.periodic_tech_test?.startsWith(filter.periodic_tech_test))
      ), //.map((v) => fields.findIndex((x) => x.driver_id === v.driver_id))
    [fields, filter]
  );

  useEffect(() => {
    if (!isReadOnly && isDirty) {
      const onUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };

      window.addEventListener("beforeunload", onUnload);
      return () => window.removeEventListener("beforeunload", onUnload);
    }
  }, [isDirty, isReadOnly]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100",
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Container sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={2}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {/* <Grid item xs={12} sm={2}>
              <AutoColumnSelect />
            </Grid> */}
            <Button
              className="me-3"
              style={{ whiteSpace: "nowrap" }}
              type="submit"
              disabled={isReadOnly || !isDirty} // || isSubmitting || isValidating}
              // variant={!hasErrors(errors) ? "primary" : "danger"}
            >
              &nbsp;Запиши
            </Button>
          </Grid>
          {data?.data && rows && !hasError && !loading && (
            <Grid container mt={1} mb={3} spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
              >
                Брой ПС: {rows.length.toString()}
              </Grid>

              {filteredData.length < rows.length && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
                >
                  Филтрирани ПС: {filteredData.length.toString()}
                </Grid>
              )}
            </Grid>
          )}
        </Container>
      </Form>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {hasError && <QueryError error={null} />}

        {loading && (
          <CircularProgress
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 1,
              marginBottom: 4,
            }}
          />
        )}

        {data?.data && rows && rows.length > 0 && !hasError && !loading && (
          <Container sx={{ height: "100%" }}>
            <TableContenet
              register={register}
              control={control}
              fields={fields}
              filteredData={filteredData}
              filter={filter}
              setFilter={setFilter}
              vehicletypes={
                vehicleTypesRecord ? Object.values(vehicleTypesRecord) : null
              }
            />
          </Container>
        )}
      </Box>
    </Box>
  );
}
