import { useCallback, useEffect, useRef, useState } from "react";
import { CELL_HEIGHT } from "./TableElements";

export function useHeaderScroller() {
  const [scrollerWidth, setScrollerWidth] = useState(0);
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null
  );
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollContainer?.parentElement) {
      const element = scrollContainer;
      const parent = scrollContainer.parentElement;

      const onScroll = () => {
        setScrollerWidth(
          parent.getBoundingClientRect().width - parent.clientWidth
        );
        headerRef.current?.scrollTo({
          left:
            parent.getBoundingClientRect().left -
            element.getBoundingClientRect().left,
        });
      };

      const observer = new MutationObserver(() => {
        setScrollerWidth(
          parent.getBoundingClientRect().width - parent.clientWidth
        );
      });

      setScrollerWidth(
        parent.getBoundingClientRect().width - parent.clientWidth
      );

      observer.observe(element, {
        subtree: true,
        childList: true,
        attributes: false,
      });
      parent.addEventListener("scroll", onScroll);

      return () => {
        parent.removeEventListener("scroll", onScroll);
        observer.disconnect();
      };
    }
  }, [scrollContainer]);

  const scrollToRow = useCallback(
    (row: number, offset = -10) => {
      if (scrollContainer?.parentElement) {
        const parent = scrollContainer.parentElement;

        parent.scrollTo({ top: row * CELL_HEIGHT + offset });
      }
    },
    [scrollContainer?.parentElement]
  );

  return {
    headerRef,
    scrollerWidth,
    setScrollContainer,
    scrollToRow,
  };
}

export type UseHeaderScroller = typeof useHeaderScroller;
export type UseHeaderScrollerResult = ReturnType<UseHeaderScroller>;
