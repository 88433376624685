import { useCallback } from "react";
import { DayRosterData } from "./helpers";
import { DayTaskStates } from "../schedule_recapitulation/useScheduleRecapitulation";
import { usePostRosterExportMutation } from "../../../data/api/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../data/store";

export interface NonWorkDayTaskGPS {
  state_description: string;
  drivers_list: string | null;
}

export function useExportGPS(selectedDate: string) {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const [postExportGPS, exportGPSStatus] = usePostRosterExportMutation();

  const exportGPS = useCallback(
    (data: {
      rows: DayRosterData[];
      recapRows:
        | {
            [key: string]: number[];
          }
        | undefined;
      daytask_states: DayTaskStates[];
    }) => {
      if (
        window.confirm(
          "Нарядът ще бъде експортиран в GPS системата. Желаете ли да продължите?"
        )
      ) {
        //       line_name: string;
        // car_no: number;
        // vehicle1: number;
        // driver1: number;
        // vehicle2: number;
        // driver2: number;
        // comment1: string;
        // comment2: string;
        // show: boolean;

        // line_id: number | null;
        // tt_code: string | null;
        // start_time: number | null;
        // end_time: number | null;

        const lineTasks = data.rows.map((task) => {
          const res =
            task.car_no === -1
              ? task.driver1 > 0 && task.driver2 > 0
                ? { ...task, vehicle2: -1, driver2: -1, comment2: "" }
                : task.driver1 === -1 && task.driver2 < -1
                ? { ...task, driver2: task.driver2 * -1 }
                : { ...task }
              : { ...task };
          return res;
        });
        const nonWorkData = data.recapRows
          ? Object.keys(data.recapRows)
              .filter((s) => s !== "21" && s !== "22" && parseInt(s, 10) >= 0)
              .map((r) => {
                const f = data.daytask_states.find(
                  (s) => s.state_id === parseInt(r, 10)
                );
                return {
                  state_description: f ? f.description : "",
                  drivers_list: data.recapRows
                    ? data.recapRows[r].join(" ")
                    : "",
                };
              })
          : undefined;

        // console.log("lineTasks", lineTasks);
        // console.log("nonWorkData", nonWorkData);

        postExportGPS({
          transport: selectedTransport,
          depot_id: selectedDepot,
          autocolumn: selectedAvtoColumn,
          date: selectedDate,
          lineTasks: lineTasks,
          nonWorkTasks: nonWorkData,
        });
        // try {
        //   const result = await postExportGPS({
        //     transport: selectedTransport,
        //     depot_id: selectedDepot,
        //     autocolumn: selectedAvtoColumn,
        //     date: selectedDate,
        //     lineTasks: lineTasks,
        //     nonWorkTasks: nonWorkData,
        //   }).unwrap();
        //   console.log("Result:", result);
        // } catch (e: any) {
        //   if (
        //     e.data?.status === "error" &&
        //     Array.isArray(e.data.data?.errors) &&
        //     e.data.data.errors.length > 0
        //   ) {
        //     const result: Exclude<
        //       Awaited<ReturnType<ReturnType<typeof postExportGPS>["unwrap"]>>,
        //       { status: "ok" }
        //     > = e.data;
        //     // console.warn("Error exporting to GPS:", result);
        //     for (const error of result.data.errors) {
        //       console.log("Export to GPS error:", error);
        //     }
        //   } else {
        //     console.warn("Unknown error exporting to GPS:", e);
        //   }
        // }
      }
    },
    [
      postExportGPS,
      selectedAvtoColumn,
      selectedDate,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    exportGPS,
    exportGPSStatus,
  };
}
