import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-router-dom";
import { RootState } from "../../data/store";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { useGetScheduleSpecialShiftsQuery } from "../../data/api/hooks";
import {
  DayTypes,
  Schedule_SpecialShiftData,
  SpecialShift,
  createScheduleSpecialShiftData,
  scehuleSpecShiftColumns,
  useSpecialShiftCalendar,
} from "./useSpecialShift";
import moment, { Moment } from "moment";
import { MonthPicker } from "../../filters/MonthPicker";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { QueryError } from "../QueryError";
import {
  FieldArrayWithId,
  FieldErrors,
  SubmitHandler,
  UseFormRegister,
} from "react-hook-form";
import { SpecialShiftEditForm } from "./SpecialShift_EditForm";
import { useProfile } from "../../common/useProfile";
import { NoEncryption } from "@mui/icons-material";

function StickyHeaderTable({
  rows,
  spec_shifts,
  selMonth,
  register,
  errors,
  totalRow,
  holidays,
  submit,
}: {
  rows: FieldArrayWithId<
    {
      specialshift_calendar: Schedule_SpecialShiftData[];
    },
    "specialshift_calendar",
    "id"
  >[];
  spec_shifts: SpecialShift[];
  selMonth: Moment | null;
  register: UseFormRegister<{
    specialshift_calendar: Schedule_SpecialShiftData[];
  }>;
  errors: FieldErrors<{
    specialshift_calendar: Schedule_SpecialShiftData[];
  }>;
  totalRow: { date: string; count: number }[];
  holidays: DayTypes[];
  submit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
}) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const [selectedShiftId, setSelectedShiftId] = useState(-1);
  const [selectedDayIdx, setSelectedDayIdx] = useState(-1);

  // const theme = useTheme();
  let tabindex = 0;
  return (
    // <Container sx={{ height: "100%", pt: 2, pb: 2 }}>
    <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ width: "100%", height: "100%" }}>
        <Table
          stickyHeader
          sx={{ width: "100%" }}
          style={{ padding: 0 }}
          aria-label="stickyHeader"
        >
          <TableHead>
            <TableRow
              sx={{
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              {scehuleSpecShiftColumns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    width: column.width,
                    maxWidth: column.width,
                    minWidth: column.width,
                  }}
                  style={{
                    tableLayout: "fixed",
                    position: "sticky",
                    left: i === 0 ? 0 : i === 1 ? 120 : 180,
                    zIndex: "3",
                    width: column.width,

                    //background: theme.palette.white,
                    fontWeight: "bold",
                    borderRight: i === 2 ? "1px solid rgba(224,224,224,1)" : "",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              {selMonth &&
                [...Array(selMonth.daysInMonth())].map((_k, index) => (
                  <TableCell
                    key={index}
                    style={{
                      zIndex: "1",
                      //    background: theme.palette.white,
                      backgroundImage:
                        holidays[index]?.day_type > 0
                          ? "repeating-linear-gradient(-45deg, white ,white 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                          : "white",

                      fontWeight: "bold",
                      width: "25",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {spec_shifts &&
              rows.map((shift, idx) => {
                tabindex = selMonth ? idx * selMonth.daysInMonth() : 0;
                // const idx = rows.findIndex((s) => s.shift_id === shift.id);
                //if (idx > -1)
                return (
                  <TableRow
                    key={shift.id}
                    style={
                      idx % 2
                        ? { background: "#fdffe0" }
                        : { background: "white" }
                    }
                  >
                    <TableCell
                      sx={{ maxWidth: 120 }}
                      style={{
                        width: "120",
                        maxWidth: "120",
                        position: "sticky",
                        left: 0,
                        zIndex: "1",
                        background: idx % 2 ? "#fdffe0" : "white",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {shift.description}
                    </TableCell>
                    <TableCell
                      sx={{ maxWidth: 60 }}
                      style={{
                        width: "60",

                        position: "sticky",
                        left: 120,
                        zIndex: "1",
                        background: idx % 2 ? "#fdffe0" : "white",
                      }}
                    >
                      {moment
                        .utc(
                          moment
                            .duration(shift.start_time, "seconds")
                            .as("milliseconds")
                        )
                        .format("HH:mm")}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "60",
                        position: "sticky",
                        left: 180,
                        zIndex: "1",
                        background: idx % 2 ? "#fdffe0" : "white",
                        borderRight: "1px solid rgba(224,224,224,1)",
                        //fontSize: "medium",
                      }}
                    >
                      {moment
                        .utc(
                          moment
                            .duration(shift.end_time, "seconds")
                            .as("milliseconds")
                        )
                        .format("HH:mm")}
                    </TableCell>
                    {selMonth &&
                      [...Array(selMonth.daysInMonth())].map((_k, index) => (
                        <TableCell
                          key={index}
                          //tabIndex={0}
                          itemType="number"
                          onFocus={() => {
                            setSelectedShiftId(idx);
                            setSelectedDayIdx(index);
                          }}
                          style={{
                            zIndex: "0",
                            position: "relative",
                            width: "25px",
                            height: "100%",

                            padding: 0,
                            backgroundImage:
                              holidays[index]?.day_type > 0
                                ? "repeating-linear-gradient(-45deg, transparent,transparent 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                                : "",
                          }}
                        >
                          {/* <div
                            //     tabIndex={0}
                            style={{
                              height: "100%",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            {selectedShiftId === idx &&
                              selectedDayIdx === index && (
                                <TextField
                                  tabIndex={1}
                                  id="outlined-error"
                                  error={
                                    errors.specialshift_calendar?.[idx]
                                      ?.schedule_days?.[index]
                                      ? true
                                      : false
                                  }
                                  {...register(
                                    `specialshift_calendar.${idx}.schedule_days.${index}.count`,
                                    {
                                      // setValueAs: v => parseInt(v),
                                      // valueAsNumber: true,
                                      validate: (value) =>
                                        (value as number) >= 0,
                                    }
                                  )}
                                  inputRef={(input) => input && input.focus()}
                                  onFocus={(event) => {
                                    console.log(
                                      "Text box focused shiftId:",
                                      idx,
                                      "dayindex:",
                                      index,
                                      "text: ",
                                      event.target.value
                                    );
                                    event.target.select();
                                  }}
                                  // onChange={(event) =>
                                  //   (event.target.value as any as number) < 0
                                  //     ? (event.target.value = "0")
                                  //     : event.target.value
                                  // }
                                  // autoFocus={
                                  //   selectedShiftId === idx &&
                                  //   selectedDayIdx === index
                                  // }
                                  type="number"
                                  label=""
                                  //helperText="Incorrect entry."

                                  variant="standard"
                                  style={{ width: "100%" }}
                                  sx={{
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                      {
                                        //   WebkitAppearance: "none",
                                        margin: 0,
                                        display: "none",
                                      },

                                    input: {
                                      textAlign: "center",
                                    },
                                    width: "100%",
                                  }}
                                  InputProps={{
                                    disableUnderline:
                                      !errors.specialshift_calendar?.[idx]
                                        ?.schedule_days?.[index],
                                    inputMode: "numeric",
                                    readOnly: isReadOnly,
                                    style: { width: "100%" },
                                    inputProps: { min: "0" },
                                  }}
                                ></TextField>
                              )}
                            {(selectedShiftId !== idx ||
                              selectedDayIdx !== index) &&
                              shift.schedule_days[index]?.count}
                          </div> */}
                          <input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "none",
                              outline: "none",
                              fontSize: "medium",
                              background:
                                holidays[index]?.day_type > 0
                                  ? "repeating-linear-gradient(-45deg, transparent,transparent 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                                  : idx % 2
                                  ? "#fdffe0"
                                  : "white",
                            }}
                            readOnly={isReadOnly}
                            min={0}
                            {...register(
                              `specialshift_calendar.${idx}.schedule_days.${index}.count`,
                              {
                                // setValueAs: v => parseInt(v),
                                // valueAsNumber: true,
                                validate: (value) => (value as number) >= 0,
                                //  onBlur: () => submit(),
                              }
                            )}
                          ></input>
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{ fontWeight: "bold", textAlign: "right" }}
                style={{
                  borderRight: "1px solid rgba(224,224,224,1)",
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: "1",
                  fontSize: "medium",
                }}
              >
                {" "}
                Общо
              </TableCell>
              {totalRow.length === holidays.length &&
                totalRow.map((day, index) => (
                  <TableCell
                    tabIndex={
                      // selMonth ? rows.length * selMonth.daysInMonth() : 0
                      -1
                    }
                    placeholder="NoTabIndex"
                    key={index}
                    itemType="number"
                    align="center"
                    style={{
                      zIndex: 0,
                      padding: 0,
                      backgroundImage:
                        holidays[index].day_type > 0
                          ? "repeating-linear-gradient(-45deg, transparent,transparent 8px, rgba(213, 211, 238, 0.7) 9px, rgba(213, 211, 238, 0.7) 10px)"
                          : "",
                      fontSize: "medium",
                    }}
                  >
                    {day.count}
                    {/* <TextField
                      tabIndex={
                        // selMonth ? rows.length * selMonth.daysInMonth() : 0
                        -1
                      }
                      placeholder="NoTabIndex"
                      type="number"
                      value={day.count}
                      variant="standard"
                      sx={{
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            //WebkitAppearance: "none",
                            margin: 0,
                          },

                        input: {
                          textAlign: "center",
                          fontWeight: "bold",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,

                        inputMode: "numeric",
                        readOnly: true,
                      }}
                    ></TextField> */}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    // </Container>
  );
}

export function SpecialShiftCalendar() {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );

  const selectedMonth = useMemo(
    () => (selMonth && selMonth !== "" ? moment(selMonth) : null),
    [selMonth]
  );
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const [editFormVisible, setEditFormVisible] = useState(false);

  const handleEditClickOpen = () => {
    setEditFormVisible(true);
  };

  const { data, isLoading, isError } = useGetScheduleSpecialShiftsQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      year: selectedMonth ? selectedMonth.year() : 0,
      month: selectedMonth ? selectedMonth.month() + 1 : 0,
      avtocolona: selectedAvtoColumn,
    },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const rows = useMemo<Schedule_SpecialShiftData[]>(() => {
    if (
      data?.schedule_spec_shifts &&
      selectedMonth &&
      data?.schedule_spec_shifts.length > 0
    ) {
      const arr = data.schedule_spec_shifts.filter(
        (spec) =>
          spec.type.validity_end === null ||
          spec.type.validity_end >= selectedMonth.startOf("month") ||
          spec.counts_by_date.reduce((cnt, sp) => cnt + sp.count, 0) > 0
      );
      return Array.from(
        {
          length: arr.length,
        },
        (_, index) => {
          const shift = arr[index];
          return createScheduleSpecialShiftData(
            shift.type.id,
            shift.type.description,
            moment
              .utc(
                moment
                  .duration(shift.type.start_time, "seconds")
                  .as("milliseconds")
              )
              .format("HH:mm"),
            moment
              .utc(
                moment
                  .duration(shift.type.end_time, "seconds")
                  .as("milliseconds")
              )
              .format("HH:mm"),
            shift.counts_by_date
          );
        }
      );
    } else {
      return [];
    }
  }, [data?.schedule_spec_shifts, selectedMonth]);

  const {
    register,
    handleSubmit,
    fields,
    isDirty,
    hasError,
    loading,
    errors,
    totalRow,
  } = useSpecialShiftCalendar(
    rows,
    selectedTransport,
    selectedDepot,
    selectedAvtoColumn,
    isLoading,
    isError,
    data?.spec_shifts_types,
    selectedMonth
  );

  useEffect(() => {
    if (!isReadOnly && isDirty) {
      const onUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };

      window.addEventListener("beforeunload", onUnload);
      return () => window.removeEventListener("beforeunload", onUnload);
    }
  }, [isDirty, isReadOnly]);

  const [newShift] = useState<SpecialShift | null>(null);
  useEffect(() => {
    if (newShift) {
      console.log("Време за добавяне на нова сняма: ", newShift);
    }
  }, [newShift]);

  //const handleEditFormClose = (newValue?: SpecialShift) => {
  //  setEditFormVisible(false);

  //  if (newValue) {
  //    setNewShift(newValue);
  //  }
  //};

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      {/* <Container sx={{ mt: 2, mb: 4 }}> */}
      <Grid container spacing={2}>
        {needsTransportSelect ? (
          <Grid item xs={12} sm={3}>
            <TransportSelect />
          </Grid>
        ) : (
          <></>
        )}
        {needsDepotSelect ? (
          <Grid item xs={12} sm={3}>
            <DepotSelect />
          </Grid>
        ) : (
          <></>
        )}
        {needsAutocolumnSelect ? (
          <Grid item xs={12} sm={2}>
            <AutoColumnSelect />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={2}>
          <MonthPicker />
        </Grid>
        <Button
          className="me-3"
          style={{ whiteSpace: "nowrap" }}
          onClick={handleSubmit}
          disabled={isReadOnly || !isDirty} //</Grid> || isSubmitting || isValidating}
          //variant={!hasErrors(errors) ? "primary" : "danger"}
        >
          &nbsp;Запиши
        </Button>
      </Grid>

      {/* </Container> */}

      {isError && <QueryError error={null} />}
      {isLoading && (
        <CircularProgress
          sx={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 1,
            marginBottom: 4,
          }}
        />
      )}
      <Form style={{ flexGrow: 1, overflow: "hidden" }} onSubmit={handleSubmit}>
        {data?.schedule_spec_shifts &&
          rows &&
          !hasError &&
          !loading &&
          fields &&
          totalRow && (
            <StickyHeaderTable
              rows={fields}
              spec_shifts={data.spec_shifts_types}
              selMonth={selectedMonth}
              register={register}
              errors={errors}
              totalRow={totalRow}
              holidays={data.holidays}
              submit={handleSubmit}
            />
          )}
      </Form>
      <Button onClick={handleEditClickOpen} disabled={isReadOnly}>
        Добави смяна
      </Button>
      {selectedTransport !== "" && (
        <SpecialShiftEditForm
          open={editFormVisible}
          setOpen={setEditFormVisible}
          depot={selectedDepot}
          transport={selectedTransport}
          autocolumn={selectedAvtoColumn}
        />
      )}
    </Box>
  );
}
