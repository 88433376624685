import { useMemo } from "react";
import { useProfile } from "../../common/useProfile";
import { DaySummary } from "../../components/schedule/types";
import { useIsHoliday } from "../../components/schedule/useIsHoliday";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { Schedule } from "../../data/api/types/schedule";

export function useCalculateGarageStatistics(
  schedule: Schedule | undefined,
  daySummary: DaySummary[] | null
) {
  const profile = useProfile();
  const { scheduleParameters } = useScheduleParameters();

  const isHoliday = useIsHoliday(
    scheduleParameters
      ? `${scheduleParameters[2]}-${scheduleParameters[3]}`
      : "2023-01"
  );

  const garageStatistics = useMemo(() => {
    if (
      !schedule ||
      !daySummary ||
      !(profile?.roles || []).includes("nariad_admin")
    ) {
      return null;
    }

    const driversCount = Object.values(schedule.drivers).length;
    const monthQuota =
      Object.values(schedule.drivers)
        .map((d) => d.quota)
        .reduce((max, q) => Math.max(max, q), 0) / 8;
    const shiftsCount =
      schedule.expl_plan_counts
        ?.flatMap((s) => s.counts)
        ?.reduce((acc, s) => acc + s, 0) || 0;

    const noworks = daySummary
      .filter((s) => s.date.isBefore() && !isHoliday(s.date))
      .map((s) => s.nowork);
    const noworkAverage =
      noworks.reduce((acc, s) => acc + s, 0) / noworks.length;
    const coveredShifts = (driversCount - noworkAverage) * monthQuota;
    const missingDrivers = Math.ceil(
      (shiftsCount - coveredShifts) / monthQuota
    );

    const averageShifts = shiftsCount / monthQuota;

    //console.log("Общо водачи:", driversCount);
    //console.log("Месечна норма", monthQuota);
    //console.log("Брой смени", shiftsCount);
    //console.log("Болнични и отпуски", noworkAverage);
    //console.log("Брой покрити смени", coveredShifts);
    //console.log("Недостиг на водачи", missingDrivers);

    return {
      driversCount,
      monthQuota,
      shiftsCount,
      noworkAverage,
      coveredShifts,
      missingDrivers,
      averageShifts,
    };
  }, [daySummary, isHoliday, profile?.roles, schedule]);

  return garageStatistics;
}

export type UseGarageStatisticsResult = NonNullable<
  ReturnType<typeof useCalculateGarageStatistics>
>;

export function reportStatistics(
  statistics: UseGarageStatisticsResult | null | undefined
) {
  if (!statistics) {
    return "";
  } else {
    return [
      `Месечна норма: ${statistics.monthQuota || 0}`,
      `Брой водачи: ${statistics.driversCount || 0}`,
      `Средно болнични и отпуск на ден: ${
        statistics.noworkAverage.toFixed(0) || 0
      }`,
      `Средно смени на ден: ${statistics.averageShifts.toFixed(0) || 0}`,
      `Брой смени: ${statistics.shiftsCount || 0}`,
      `Покрити смени: ${statistics.coveredShifts.toFixed(0) || 0}`,
      `Недостиг на водачи: ${statistics.missingDrivers || 0}`,
    ].join("\n");
  }
}
