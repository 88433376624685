import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { VehiclesScheduleState } from "./types";

export function useVehicles(): VehiclesScheduleState {
  const vehicles = useSelector(
    (state: RootState) => state.schedule.vehiclesSchedule
  );

  const channelCallbacks = useSelector(
    (state: RootState) => state.schedule.vehiclesChannelCallbacks
  );

  return useMemo<VehiclesScheduleState>(() => {
    const value = {
      vehicles,
      ...channelCallbacks,
    };
    return value;
  }, [vehicles, channelCallbacks]);
}
