import moment from "moment";
import {
  Schedule,
  sortArray,
  Writeable,
} from "../../../data/api/types/schedule";
import { ScheduleVehicle } from "../../../data/api/types/vehiclesSchedule";
import {
  DayExplPlanRoster,
  DaySpecShiftRoster,
  LineTimetableRoster,
} from "../../reports/useTimetables_ByDate";
import { Roster, RosterDriver, RosterShift, RosterVehicle } from "./Roster";

export const emptyRoster: Roster = {
  allShifts: [],
  unusedShifts: [],
  unusedDrivers: [],
  vehicles: [],
  summary: {
    unusedVehicles: 0,
    driversWithNoShift: 0,
  },
};

export function secondsToTime(seconds: number): [number, string] {
  const days = Math.floor(seconds / 86400);
  seconds = seconds % 86400;
  const minutes = Math.floor(seconds / 60);
  const secondsRem = seconds - minutes * 60;
  const hours = Math.floor(minutes / 60);
  const minutesRem = minutes - hours * 60;

  return [
    days,
    `${hours.toString().padStart(2, "0")}:${minutesRem
      .toString()
      .padStart(2, "0")}:${secondsRem.toString().padStart(2, "0")}`,
  ];
}

function getInitialLineShifts(
  autocolumn: number,
  date: string,
  expl_plan: readonly LineTimetableRoster[] | null | undefined
): readonly RosterShift[] {
  if (expl_plan && expl_plan.length > 0) {
    const shifts: Extract<RosterShift, { type: "timetable" }>[] =
      expl_plan.flatMap((line) =>
        line.cars.flatMap((car) =>
          car.workshifts
            .filter((shift) => shift.autocolumn === autocolumn)
            .map((shift) => {
              const [start_days, start_time_str] = secondsToTime(
                shift.start_time
              );
              const start_date = moment(date, "YYYY-MM-DD").add(
                start_days,
                "days"
              );
              const start_time = moment(
                `${start_date.format("YYYY-MM-DD")}T${start_time_str}`
              );
              const [end_days, end_time_str] = secondsToTime(
                shift.end_time_2 ? shift.end_time_2 : shift.end_time
              );
              const end_date = moment(date, "YYYY-MM-DD").add(end_days, "days");
              const end_time = moment(
                `${end_date.format("YYYY-MM-DD")}T${end_time_str}`
              );
              if (end_time.isBefore(start_time)) {
                end_time.add(1, "day");
              }

              return {
                type: "timetable",
                line_order: line.line_order,
                start_time,
                end_time,
                passenger_start: shift.passenger_start
                  ? moment(date, "YYYY-MM-DD")
                      .startOf("day")
                      .add(shift.passenger_start, "seconds")
                  : null,
                passenger_end: shift.passenger_end
                  ? moment(date, "YYYY-MM-DD")
                      .startOf("day")
                      .add(shift.passenger_end, "seconds")
                  : null,
                single_shift: !!shift.single_shift,
                work_time: end_time.diff(start_time, "minutes"),
                shift: {
                  line: line.line,
                  lineName: line.line_name,
                  lineOrder: line.line_order,
                  timetableId: line.timetable_id,
                  timetableCode: line.code,
                  car: car.car_no,
                  places: shift.vehicletype?.places || null,
                  workshift: {
                    ...shift,
                    number: shift.shift_type,
                  },
                },
                places: shift.vehicletype?.places || null,
              };
            })
        )
      );

    return (sortArray as any)(
      shifts,
      ["line_order", "shift.car", "shift.workshift.shift_type"],
      { in_place: true }
    );
  } else {
    return [];
  }
}

function getInitialSpecialShifts(
  date: string,
  expl_plan: readonly DaySpecShiftRoster[] | null | undefined
): readonly RosterShift[] {
  if (expl_plan && expl_plan.length > 0) {
    return expl_plan.flatMap((shift) => {
      // const start_time = moment(date, "YYYY-MM-DD")
      //   .startOf("day")
      //   .add(shift.start_time, "seconds");
      const [start_days, start_time_str] = secondsToTime(shift.start_time);
      const start_date = moment(date, "YYYY-MM-DD").add(start_days, "days");
      const start_time = moment(
        `${start_date.format("YYYY-MM-DD")}T${start_time_str}`
      );
      // const end_time = moment(date, "YYYY-MM-DD")
      //   .startOf("day")
      //   .add(shift.end_time, "seconds");
      const [end_days, end_time_str] = secondsToTime(shift.end_time);
      const end_date = moment(date, "YYYY-MM-DD").add(end_days, "days");
      const end_time = moment(
        `${end_date.format("YYYY-MM-DD")}T${end_time_str}`
      );
      if (end_time.isBefore(start_time)) {
        end_time.add(1, "day");
      }

      return {
        type: "special",
        start_time,
        end_time,
        work_time: end_time.diff(start_time, "minutes"),
        shift: {
          workshift: shift,
        },
        places: null,
      };
    });
  } else {
    return [];
  }
}

function getRosterDrivers(
  schedule: Schedule,
  // drivers: readonly Driver[],
  shifts: readonly RosterShift[],
  selectedDate: string,
  autocolumn: number
): readonly [RosterDriver[], RosterShift[]] {
  const allRosterDrivers: RosterDriver[] = [];
  const usedShifts: RosterShift[] = [];

  for (const scheduleDriver of Object.values(schedule.drivers)) {
    const currentDay = scheduleDriver.driver_days[selectedDate];
    const yesterday =
      scheduleDriver.driver_days[
        moment(selectedDate, "YYYY-MM-DD")
          .subtract(1, "day")
          .format("YYYY-MM-DD")
      ];

    if (
      currentDay &&
      !currentDay.expired_docs &&
      [/*1,*/ 3, 4, 21, 32, 28, 2, 11, 14, 23, 22, 29].includes(
        currentDay.state
      )
    ) {
      // const driver = drivers.find(
      //   (d) => d.sl_number === scheduleDriver.sl_number
      // );
      if (
        // driver &&
        // (!currentDay.autocolumn ||
        currentDay.autocolumn === autocolumn
      ) {
        const shift = shifts.filter(
          (s) =>
            (currentDay &&
              s.type === "timetable" &&
              currentDay.daytasks.find(
                (dt) => dt.workshift_id === s.shift.workshift.id
              )) ||
            (currentDay &&
              s.type === "special" &&
              currentDay.daytasks.find(
                (dt) => dt.special_shift_id === s.shift.workshift.id
              ))
        );
        if (shift) {
          usedShifts.push(...shift);
        }

        const old_daytasks: Writeable<RosterDriver["old_daytasks"]> = [];
        for (let i = 0; i < 7; ++i) {
          const oldDay = moment(selectedDate, "YYYY-MM-DD").subtract(
            1 + i,
            "days"
          );
          const day = scheduleDriver.driver_days[oldDay.format("YYYY-MM-DD")];

          if (day) {
            const filteredDaytasks = day.daytasks.filter(
              (dt) =>
                dt.linename &&
                dt.linename.trim() !== "" &&
                dt.start_time &&
                dt.end_time
            );

            const tasks =
              filteredDaytasks.length > 0
                ? filteredDaytasks
                : day.workshift.linename &&
                  day.workshift.linename.trim() !== "" &&
                  day.workshift.start_time &&
                  day.workshift.end_time
                ? [day.workshift]
                : [];

            if (tasks.length > 0) {
              old_daytasks.push({
                date: oldDay.format("DD.MM.YYYY"),
                total_workseconds: 0,
                tasks: tasks.map((t) => ({
                  line_id: t.line_id,
                  linename: t.linename as string,
                  car_no: t.car_no,
                  shift_type: t.shift_type,
                  start_time: t.start_time as string,
                  end_time: t.end_time as string,
                  work_seconds: t.work_seconds,
                })),
              });
            } else {
              old_daytasks.push({
                date: oldDay.format("DD.MM.YYYY"),
                total_workseconds: 0,
                tasks: [
                  {
                    line_id: undefined,
                    linename: day.state_name,
                    car_no: null,
                    shift_type: null,
                    start_time: selectedDate,
                    end_time: selectedDate,
                    work_seconds: 0,
                  },
                ],
              });
            }
          } else {
            old_daytasks.push({
              date: oldDay.format("L"),
              total_workseconds: 0,
              tasks: [],
            });
          }
        }

        const daytasks = currentDay.daytasks.map((dt) => ({
          ...dt,
          rosterShift:
            shift.find((s) => s.shift.workshift.id === dt.shift_id) || null,
        }));

        const dtShiftTypes: (1 | 2)[] = [];
        for (const dt of daytasks) {
          if (
            dt.rosterShift &&
            dt.rosterShift.type === "timetable" &&
            (dt.shift_type === 1 || dt.shift_type === 2) &&
            !dtShiftTypes.includes(dt.shift_type)
          ) {
            dtShiftTypes.push(dt.shift_type);
          }
        }

        allRosterDrivers.push({
          type: "driver",
          driver_id: scheduleDriver.sl_number,
          schedule_id: scheduleDriver.id,
          sl_number: scheduleDriver.sl_number,
          name: scheduleDriver.name /* || driver?.name*/ || "",
          preferred_vehicle: scheduleDriver.attributes?.preferred_vehicle,
          preferred_workshift: scheduleDriver.attributes?.preferred_workshift,
          schedule_state: currentDay.state,
          schedule_state_name: currentDay.state_name,
          schedule_state_abbr: currentDay.state_short_name,
          shift_type:
            dtShiftTypes.length === 1
              ? dtShiftTypes[0]
              : [22, 29].includes(currentDay.state)
              ? 2
              : 1,
          daytasks,
          old_daytasks,
          total_workseconds: yesterday?.total_workseconds || 0,
          total_quota: yesterday?.total_quota || 0,
          intershift_times: currentDay?.intershift_times || [],
          color:
            scheduleDriver.attributes?.color_id === 1
              ? "red"
              : scheduleDriver.attributes?.color_id === 2
              ? "green"
              : null,
          roster_issues: currentDay.roster_issues,
          comment: currentDay.comment,
          skillVehicleTypes:
            scheduleDriver.attributes?.vehicletypes_skills?.flatMap(
              (s) => s.razpcad_vehicletype_ids
            ) || [],
          vehicletypes_skills:
            scheduleDriver.attributes?.vehicletypes_skills || [],
        });

        // console.log("DEBUG:", allRosterDrivers[allRosterDrivers.length - 1]);
      }
    }
  }

  const unusedShifts: RosterShift[] = [];
  for (const shift of shifts) {
    if (!usedShifts.includes(shift)) {
      unusedShifts.push(shift);
    }
  }

  return [allRosterDrivers, unusedShifts];
}

function getUsedVehicles(
  allRosterDrivers: readonly RosterDriver[],
  vehicles: readonly ScheduleVehicle[],
  vehicleTypes: readonly {
    id: number;
    description: string;
    koef: number;
    places: number;
    energy_meter: boolean | null | undefined;
  }[],
  selectedDateStr: string
): readonly [{ [key: number]: RosterVehicle }, readonly RosterDriver[]] {
  const unusedRosterDrivers: RosterDriver[] = [];
  const rosterVehicles: { [key: number]: RosterVehicle } = {};

  for (const rd of allRosterDrivers) {
    const foundVehicles =
      rd.daytasks.length > 0
        ? vehicles.filter(
            (v) =>
              (!v.days[selectedDateStr]?.state ||
                v.days[selectedDateStr]?.state?.category === 1) &&
              rd.daytasks.find((dt) => dt.veh1_id === v.vehicle_id)
          )
        : [];

    if (foundVehicles.length > 0) {
      if (foundVehicles.length > 1) {
        console.log("Multiple vehicles for driver:", rd, foundVehicles);
      }

      for (const foundVehicle of foundVehicles) {
        const rosterVehicle = rosterVehicles[foundVehicle.vehicle_id];

        if (rosterVehicle) {
          // Съществуващ автобус
          if (rd.shift_type !== 2) {
            rosterVehicles[foundVehicle.vehicle_id] = {
              ...rosterVehicle,
              shifts: [
                [...rosterVehicle.shifts[0], rd],
                rosterVehicle.shifts[1],
              ],
            };
          } else {
            rosterVehicles[foundVehicle.vehicle_id] = {
              ...rosterVehicle,
              shifts: [
                rosterVehicle.shifts[0],
                [...rosterVehicle.shifts[1], rd],
              ],
            };
          }
        } else {
          // Новосъздаден автобус
          const regularDrivers = allRosterDrivers
            .filter(
              (d) =>
                !!d.preferred_vehicle &&
                d.preferred_vehicle === foundVehicle.vehicle_id
            )
            .map((d) => ({ id: d.driver_id, name: d.name }));

          const vt = vehicleTypes.find(
            (vt) => vt.id === foundVehicle.vehicletype_id
          );
          if (rd.shift_type !== 2) {
            // Първа смяна
            rosterVehicles[foundVehicle.vehicle_id] = {
              vehicle_id: foundVehicle.vehicle_id,
              vehicletype_id: foundVehicle.vehicletype_id,
              vehicletype_name: vt?.description || "Неизвестна",
              includeEnergy: vt?.energy_meter,
              shifts: [[rd], []],
              vehicle: foundVehicle,
              composition:
                foundVehicle.days[selectedDateStr]?.composition || null,
              places: foundVehicle.days[selectedDateStr]?.places || 0,
              regularDrivers: regularDrivers,
            };
          } else {
            // Втора смяна
            rosterVehicles[foundVehicle.vehicle_id] = {
              vehicle_id: foundVehicle.vehicle_id,
              vehicletype_id: foundVehicle.vehicletype_id,
              vehicletype_name: vt?.description || "Неизвестна",
              includeEnergy: vt?.energy_meter,
              shifts: [[], [rd]],
              vehicle: foundVehicle,
              composition:
                foundVehicle.days[selectedDateStr]?.composition || null,
              places: foundVehicle.days[selectedDateStr]?.places || 0,
              regularDrivers: regularDrivers,
            };
          }
        }
      }
    } else {
      unusedRosterDrivers.push(rd);
    }
  }

  return [rosterVehicles, unusedRosterDrivers];
}

//function isShiftAvailable(explPlan: DayExplPlanRoster, shift_id: string) {
//  for (const timetable of explPlan.expl_plan) {
//    for (const car of timetable.cars) {
//      for (const shift of car.workshifts) {
//        if (shift.id === shift_id) {
//          return true;
//        }
//      }
//    }
//  }
//
//  for (const shift of explPlan.spec_shifts) {
//    if (shift.id === shift_id) {
//      return true;
//    }
//  }
//
//  return false;
//}

export function createRoster(
  autocolumn: number,
  date: string,
  // driverList: DriverList,
  vehiclesSchedule: Record<number, ScheduleVehicle>,
  schedule: Schedule,
  explPlan: DayExplPlanRoster,
  vehicleTypes: readonly {
    id: number;
    description: string;
    koef: number;
    places: number;
    energy_meter: boolean | null | undefined;
  }[]
): Roster {
  console.time("Create roster");
  try {
    const vehicles = sortArray(
      Object.values(vehiclesSchedule).filter(
        (v) =>
          v.days[date] &&
          (!v.days[date].autocolumn ||
            v.days[date].autocolumn === autocolumn) &&
          (!v.days[date]?.state ||
            v.days[date]?.state?.category === 1 ||
            v.days[date]?.state?.category === 2)
      ),
      ["vehicletype_id", "vehicle_id"]
    );
    //const drivers = driverList.drivers.filter(
    //  (driver) =>
    //    (driver.avtocolona === autocolumn && driver.state === 1) ||
    //    driver.state === 2
    //);
    //const drivers = driverList.drivers;
    //const drivers = driverList.drivers.filter(
    //  (driver) => driver.avtocolona === autocolumn
    //);
    // const drivers = driverList.drivers;
    const allTimetableShifts = getInitialLineShifts(
      autocolumn,
      date,
      explPlan.expl_plan
    );
    const allSpecialShifts = getInitialSpecialShifts(
      date,
      explPlan.spec_shifts
    );
    const allShifts = allTimetableShifts.concat(allSpecialShifts);

    const [rosterDrivers, unusedShifts] = getRosterDrivers(
      schedule,
      // drivers,
      allShifts,
      date,
      autocolumn
    );
    const [usedVehicles, unusedDrivers] = getUsedVehicles(
      rosterDrivers,
      vehicles,
      vehicleTypes,
      date
    );

    // Освобождаване на смените от неизползвани водачи
    //for (const driver of unusedDrivers) {
    //  const shiftIds = driver.daytasks.map((s) => s.shift_id);
    //  const shifts = allShifts.filter((s) =>
    //    shiftIds.includes(s.shift.workshift.id)
    //  );
    //  for (const shift of shifts) {
    //    if (
    //      !unusedShifts.find(
    //        (s) => s.shift.workshift.id === shift.shift.workshift.id
    //      )
    //    ) {
    //      unusedShifts.push(shift);
    //    }
    //  }
    //}

    //
    for (const vehicle of Object.values(usedVehicles)) {
      if (vehicle.shifts[0].length > 1 || vehicle.shifts[1].length > 1) {
        console.debug("Multiple drivers in vehicle:", vehicle);
      }
    }
    //

    const unusedVehicles = vehicles
      .filter((v) => !usedVehicles[v.vehicle_id])
      .map<RosterVehicle>((v) => {
        const regularDrivers = rosterDrivers
          .filter(
            (d) => !!d.preferred_vehicle && d.preferred_vehicle === v.vehicle_id
          )
          .map((d) => ({ id: d.driver_id, name: d.name }));

        const vt = vehicleTypes.find((vt) => vt.id === v.vehicletype_id);
        return {
          vehicle_id: v.vehicle_id,
          vehicletype_id: v.vehicletype_id,
          vehicletype_name: vt?.description || "Неизвестна",
          includeEnergy: vt?.energy_meter,
          shifts: [[], []],
          vehicle: v,
          composition: v.days[date]?.composition || null,
          places: v.days[date]?.places || 0,
          regularDrivers: regularDrivers,
        };
      });

    const rosterVehicles = sortArray(
      Object.values(usedVehicles).concat(unusedVehicles),
      "vehicle_id",
      {
        in_place: true,
      }
    );

    const driversWithNoShift = rosterDrivers.filter(
      (rd) =>
        !rd.daytasks.find(
          (dt) =>
            (dt.special_shift_id &&
              allSpecialShifts.find(
                (s) => s.shift.workshift.id === dt.special_shift_id
              )) ||
            (dt.workshift_id &&
              allTimetableShifts.find(
                (s) => s.shift.workshift.id === dt.workshift_id
              ))
        )
    ).length;

    const missingShifts: Writeable<RosterDriver["daytasks"]> = [];
    for (const driver of rosterDrivers) {
      for (const daytask of driver.daytasks) {
        if (
          (daytask.workshift_id || daytask.special_shift_id) &&
          !daytask.rosterShift
        ) {
          missingShifts.push(daytask);
        }
      }
    }

    if (missingShifts.length > 0) {
      console.log("Missing shifts in expl_plan:", missingShifts);
    }

    return {
      allShifts: allShifts,
      unusedShifts: unusedShifts.sort((a, b) => {
        if (a.type === "timetable" && b.type === "special") {
          return -1;
        } else if (a.type === "special" && b.type === "timetable") {
          return 1;
        } else if (a.type === "timetable" && b.type === "timetable") {
          if (a.line_order < b.line_order) {
            return -1;
          } else if (a.line_order > b.line_order) {
            return 1;
          } else {
            if (a.shift.car < b.shift.car) {
              return -1;
            } else if (a.shift.car > b.shift.car) {
              return 1;
            } else {
              return (
                a.shift.workshift.shift_type - b.shift.workshift.shift_type
              );
            }
          }
        } else if (a.type === "special" && b.type === "special") {
          return 0;
        } else {
          return 0;
        }
      }),
      unusedDrivers: unusedDrivers,
      vehicles: rosterVehicles,
      summary: {
        unusedVehicles: unusedVehicles.length,
        driversWithNoShift,
      },
    };
  } finally {
    console.timeEnd("Create roster");
  }
}
