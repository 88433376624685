import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { ScheduleState } from "../../data/schedules/types";
import { RosterDriver, RosterShift, RosterVehicle } from "./types/Roster";

function useChangeVehicleDialog(
  dialogOpen: boolean,
  vehicles: readonly RosterVehicle[],
  shift: RosterShift | null,
  selectedDate: string,
  changeTaskVehicle: ScheduleState["changeTaskVehicle"],
  closeDialog: () => void,
  uiVehicle: number | null
) {
  const [dialogErrors, setDialogErrors] = useState<string | null>(null);
  const [newVehicle, setNewVehicle] = useState<number | null>(null);
  const [dialogBusy, setDialogBusy] = useState(false);

  const onCloseDialog = useCallback(() => {
    if (!dialogBusy) {
      closeDialog();
      setNewVehicle(null);
      setDialogErrors(null);
    }
  }, [closeDialog, dialogBusy]);

  //const currentVehicle = useMemo(
  //  () =>
  //    vehicles.find((v) =>
  //      v.shifts.find((vehicleShifts) =>
  //        vehicleShifts.find((vehicleShift) =>
  //          vehicleShift.daytasks.find(
  //            (dt) => dt.shift_id === shift?.shift.workshift.id
  //          )
  //        )
  //      )
  //    ),
  //  [vehicles, shift?.shift.workshift.id]
  //);
  const currentVehicle = useMemo(
    () => vehicles.find((v) => v.vehicle_id === uiVehicle),
    [uiVehicle, vehicles]
  );

  const [currentVShift, currentVShiftType] = useMemo<
    [RosterDriver | null, 1 | 2 | null]
  >(() => {
    const foundS1 = currentVehicle?.shifts[0]?.find((s) =>
      s.daytasks.find((dt) => dt.shift_id === shift?.shift.workshift.id)
    );
    const foundS2 = currentVehicle?.shifts[1]?.find((s) =>
      s.daytasks.find((dt) => dt.shift_id === shift?.shift.workshift.id)
    );

    if (foundS1) {
      return [foundS1, 1];
    } else if (foundS2) {
      return [foundS2, 2];
    } else {
      return [null, null];
    }
  }, [currentVehicle?.shifts, shift?.shift.workshift.id]);

  const autocompleteOptions = useMemo(
    () =>
      vehicles
        .filter(
          (v) =>
            v.vehicle_id !== currentVehicle?.vehicle_id &&
            v.vehicle.days[selectedDate]?.state?.category !== 2
        )
        .map((v) => v.vehicle_id),
    [vehicles, currentVehicle?.vehicle_id, selectedDate]
  );

  const onChangeVehicle = useCallback(async () => {
    const vehicle = vehicles.find((v) => v.vehicle_id === newVehicle);

    try {
      if (!shift?.shift.workshift.id) {
        setDialogErrors("Няма избрана смяна");
      } else if (!newVehicle) {
        setDialogErrors("Моля въведете номер на превозно средство");
      } else if (!vehicle) {
        setDialogErrors(
          "Грешка при записване на ново превозно средство: не може да бъде намерено текущото превозно средство"
        );
      } else if (!currentVehicle) {
        setDialogErrors(
          "Грешка при записване на ново превозно средство: не може да бъде намерено текущото превозно средство"
        );
      } else if (currentVehicle.vehicle_id === vehicle.vehicle_id) {
        setDialogErrors(
          "Новото превозно средство трябва да бъде различно от текущото"
        );
      } else if (!currentVShift || !currentVShiftType) {
        setDialogErrors(
          "Грешка при записване на ново превозно средство: не може да бъде намерена смяна в текущото превозно средство"
        );
      } else {
        setDialogBusy(true);

        const result = await changeTaskVehicle({
          driver_id: currentVShift.schedule_id,
          date: selectedDate,
          shift_type: currentVShiftType,
          shift_id: shift.shift.workshift.id,
          veh1_id: vehicle.vehicle_id,
          old_veh1_id: currentVehicle.vehicle_id,
        });

        if (result.status === "ok") {
          setDialogErrors(null);
          closeDialog();
          setNewVehicle(null);
        } else {
          console.log("Server error:", result);
          setDialogErrors(
            "Грешка при записване на ново превозно средство: сървърна грешка"
          );
        }
      }
    } catch (error) {
      console.log("Server error:", error);
      setDialogErrors(
        "Грешка при записване на ново превозно средство: сървърна грешка"
      );
    } finally {
      setDialogBusy(false);
    }
  }, [
    changeTaskVehicle,
    closeDialog,
    currentVShift,
    currentVShiftType,
    currentVehicle,
    newVehicle,
    selectedDate,
    shift?.shift.workshift.id,
    vehicles,
  ]);

  return {
    newVehicle,
    shift,
    setNewVehicle,
    autocompleteOptions,
    dialogOpen,
    dialogBusy,
    dialogErrors,
    onChangeVehicle,
    onCloseDialog,
  };
}

export function ChangeVehicleDialog({
  closeDialog,
  isReadOnly,
  vehicles,
  shift,
  selectedDate,
  changeTaskVehicle,
  inUiVehicle,
}: {
  closeDialog: () => void;
  isReadOnly: boolean;
  vehicles: readonly RosterVehicle[];
  shift: RosterShift | null;
  selectedDate: string;
  changeTaskVehicle: ScheduleState["changeTaskVehicle"];
  inUiVehicle: number | null;
}) {
  const dialog = useChangeVehicleDialog(
    !!shift,
    vehicles,
    shift,
    selectedDate,
    changeTaskVehicle,
    closeDialog,
    inUiVehicle
  );

  return (
    <form>
      <Dialog open={dialog.dialogOpen} onClose={dialog.onCloseDialog} fullWidth>
        <DialogTitle>Промяна на превозно средство за смяна</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Промяна на превозно средство за смяна: ${
            dialog.shift?.type === "timetable"
              ? `${dialog.shift.shift.lineName}/${dialog.shift.shift.car}`
              : dialog.shift?.shift.workshift.description
          }`}</DialogContentText>
          <Autocomplete
            autoFocus
            id="name"
            fullWidth
            selectOnFocus
            autoHighlight
            autoSelect
            value={dialog.newVehicle}
            onChange={(_e, value) => dialog.setNewVehicle(value)}
            disabled={dialog.dialogBusy || isReadOnly}
            getOptionLabel={(v) => v.toString()}
            options={dialog.autocompleteOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                margin="dense"
                variant="outlined"
                error={dialog.dialogErrors !== null}
                label="Ново превозно средство"
              />
            )}
          />
          {dialog.dialogErrors && (
            <DialogContentText color="error">{`Грешка: ${dialog.dialogErrors}`}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={dialog.onCloseDialog}
            disabled={dialog.dialogBusy || isReadOnly}
          >
            Отказ
          </Button>
          <Button
            onClick={dialog.onChangeVehicle}
            type="submit"
            disabled={dialog.dialogBusy}
          >
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
