import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import { useGetExplPlanCalendarQuery } from "../../data/api/hooks";

export function useIsHoliday(month: string | null) {
  const params = useMemo(() => {
    const month_ts = moment(month, "YYYY-MM");
    const start_date = month_ts.startOf("month").format("YYYY-MM-DD");
    const end_date = month_ts.endOf("month").format("YYYY-MM-DD");
    return { start_date, end_date };
  }, [month]);

  const holidays = useGetExplPlanCalendarQuery(params, {
    skip: month === null || month.length === 0,
  });

  const isHoliday = useCallback(
    (day: Moment | string | null | undefined) => {
      const ts = typeof day === "string" ? moment(day) : day;
      if (ts && ts.isValid()) {
        const ts_str = ts.format("YYYY-MM-DD");
        return holidays.data && holidays.data[ts_str]?.day_type > 0;
      } else {
        return false;
      }
    },
    [holidays.data]
  );

  return isHoliday;
}
