import { SchedulesContext } from "../../data/schedules/SchedulesContext";
import { DriverScheduleImpl } from "./DriverScheduleImpl";

export function DriverSchedule() {
  return (
    <SchedulesContext>
      <DriverScheduleImpl />
    </SchedulesContext>
  );
}
