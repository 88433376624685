import { Channel, Socket } from "phoenix";
import CancellationToken from "./CancellationToken";

export type ChannelRef = {
  sequence: number;
  channel: Channel;
  channelId: string;
};

function onUpdateMessage(
  msg: any,
  channelRef: ChannelRef,
  p_channels: Record<
    string,
    {
      ref: ChannelRef;
      onJoin: (msg: any, ref: ChannelRef) => void;
      onMessage: (msg: any, ref: ChannelRef) => void;
      onJoinError: () => void;
    }
  >,
  channelId: string,
  getChannel: (
    channelId: string,
    onJoin: (msg: any, ref: ChannelRef) => void,
    onMessage: (msg: any, ref: ChannelRef) => void,
    onJoinError: () => void
  ) => Promise<ChannelRef>,
  onJoin: (msg: any, ref: ChannelRef) => void,
  onMessage: (msg: any, ref: ChannelRef) => void,
  onJoinError: () => void
) {
  if (
    typeof msg.sequence === "number" &&
    channelRef.sequence + 1 !== msg.sequence
  ) {
    console.warn(
      `Invalid sequence (${msg.sequence} !== ${channelRef.sequence + 1}):`,
      channelId
    );
    channelRef.channel
      .leave()
      .receive("ok", () => {
        console.debug("Channel left:", channelId);
        delete p_channels[channelId];
        setTimeout(
          () => getChannel(channelId, onJoin, onMessage, onJoinError),
          250
        );
      })
      .receive("error", () => {
        console.warn("Channel leave error:", channelId);
      })
      .receive("timeout", () => {
        console.warn("Channel leave timeout:", channelId);
      });
  } else {
    channelRef.sequence = msg.sequence;
    onMessage(msg, channelRef);
  }
}

export class NariadSocket {
  private p_url: string;
  private p_access_token: string | undefined;
  private p_socket: Socket | null;
  private p_init_token: CancellationToken;
  private p_signal_init: () => void;
  private p_channels: Record<
    string,
    {
      ref: ChannelRef;
      onJoin: (msg: any, ref: ChannelRef) => void;
      onMessage: (msg: any, ref: ChannelRef) => void;
      onJoinError: () => void;
    }
  >;
  // private p_error_time: Date | null = null;

  private socketStatusCallbacks: ((connected: boolean) => void)[];
  private channelStatusCallbacks: ((
    channelId: string,
    connected: boolean
  ) => void)[];

  constructor(url: string) {
    this.p_url = url;
    this.p_access_token = undefined;
    this.p_socket = null;
    this.p_channels = {};
    const { cancel, token } = CancellationToken.create();
    this.p_init_token = token;
    this.p_signal_init = cancel;

    this.socketStatusCallbacks = [];
    this.channelStatusCallbacks = [];
  }

  addSocketStatusCallback(callback: (connected: boolean) => void) {
    this.socketStatusCallbacks.push(callback);
  }

  removeSocketStatusCallback(callback?: (connected: boolean) => void) {
    if (callback) {
      this.socketStatusCallbacks = this.socketStatusCallbacks.filter(
        (c) => c !== callback
      );
    } else {
      this.socketStatusCallbacks = [];
    }
  }

  private triggerSocketStatusCallbacks(value: boolean) {
    this.socketStatusCallbacks.forEach((callback) => callback(value));
  }

  addChannelStatusCallback(
    callback: (channelId: string, connected: boolean) => void
  ) {
    this.channelStatusCallbacks.push(callback);
  }

  removeChannelStatusCallback(
    callback?: (channelId: string, connected: boolean) => void
  ) {
    if (callback) {
      this.channelStatusCallbacks = this.channelStatusCallbacks.filter(
        (c) => c !== callback
      );
    } else {
      this.channelStatusCallbacks = [];
    }
  }

  private triggerChannelStatusCallbacks(channelId: string, value: boolean) {
    this.channelStatusCallbacks.forEach((callback) =>
      callback(channelId, value)
    );
  }

  releaseChannel(channelId: string) {
    try {
      const { ref } = this.p_channels[channelId]
        ? this.p_channels[channelId]
        : { ref: undefined };

      if (ref) {
        console.log("Releasing channel", channelId);
        delete this.p_channels[channelId];
        ref.channel.leave();
      }
    } catch (e) {
      console.warn("Can't release channel", channelId, e);
    }
  }

  releaseChannels(channelIdPrefix: string) {
    for (const [key, { ref }] of Object.entries(this.p_channels)) {
      if (key.startsWith(channelIdPrefix) && ref) {
        console.log("Releasing channel", key);
        delete this.p_channels[key];
        ref.channel.leave();
      }
    }
  }

  async getChannel(
    channelId: string,
    onJoin: (msg: any, ref: ChannelRef) => void,
    onMessage: (msg: any, ref: ChannelRef) => void,
    onJoinError: () => void
  ): Promise<ChannelRef> {
    if (this.p_channels[channelId]) {
      const { ref: channelRef } = this.p_channels[channelId];

      this.p_channels[channelId].onJoin = onJoin;
      this.p_channels[channelId].onMessage = onMessage;
      this.p_channels[channelId].onJoinError = onJoinError;

      channelRef.channel.off("update");
      channelRef.channel.on("update", (msg) =>
        onUpdateMessage(
          msg,
          channelRef,
          this.p_channels,
          channelId,
          this.getChannel,
          onJoin,
          onMessage,
          onJoinError
        )
      );
      return Promise.resolve(channelRef);
    } else {
      return new Promise<ChannelRef>((resolve, reject) => {
        this.p_init_token.onCancelled(() => {
          if (this.p_socket && this.p_access_token) {
            const channel = this.p_socket.channel(channelId, () => ({
              token: this.p_access_token,
            }));
            const channelRef = { sequence: 0, channel, channelId };
            this.p_channels[channelId] = {
              ref: channelRef,
              onJoin,
              onMessage,
              onJoinError,
            };

            channel.onClose(() => {
              console.debug("Channel closed:", channelId);
            });

            channel.on("update", (msg) =>
              onUpdateMessage(
                msg,
                channelRef,
                this.p_channels,
                channelId,
                this.getChannel.bind(this),
                onJoin,
                onMessage,
                onJoinError
              )
            );

            channel
              .join(20000)
              .receive("ok", (msg) => {
                if (typeof msg.sequence === "number") {
                  channelRef.sequence = msg.sequence - 1;
                }
                resolve(channelRef);
                this.triggerChannelStatusCallbacks(channelId, true);
                onJoin(msg, channelRef);
              })
              .receive("error", (error) => {
                console.warn("Channel join error:", channelId, error);
                this.triggerChannelStatusCallbacks(channelId, false);
                onJoinError();
              })
              .receive("timeout", () => {
                console.warn("Channel join timeout:", channelId);
                this.triggerChannelStatusCallbacks(channelId, false);
                onJoinError();
              });
          } else {
            this.triggerChannelStatusCallbacks(channelId, false);
            reject(
              new Error(`Can't join channel: socket unavailable: ${channelId}`)
            );
          }
        });
      });
    }
  }

  public get socket() {
    return this.p_socket;
  }

  public get access_token(): string | undefined {
    return this.p_access_token || undefined;
  }
  public set access_token(token: string | null | undefined) {
    if (!this.p_access_token) {
      console.debug("Socket received access token, connecting...");
      this.p_access_token = token || undefined;
      this.p_connect();
    } else {
      console.debug("Updating access token...");
      this.p_access_token = token || undefined;
      this.p_update_all_tokens();
    }
  }

  private p_connect() {
    // let is_reconnect = false;

    // if (this.p_socket) {
    //   if (this.p_socket.isConnected()) {
    //     // console.log("Disconnect from ws:", this);
    //     // this.p_socket.disconnect();
    //     console.log("Rejoining channels on connected socket:", this);
    //     this.rejoin_channels();
    //     return;
    //   }
    //   this.p_socket = null;
    //   // is_reconnect = true;
    // }

    if (this.p_socket) {
      this.p_socket.disconnect();
      this.p_socket = null;
    }

    this.p_socket = new Socket(this.p_url, {
      // logger: (kind, message, data) =>
      //   console.log("WEBSOCKET:", kind, message, data),
      heartbeatIntervalMs: 10000,
      timeout: 30000,
      params: () => ({ access_token: this.access_token }),
      // reconnectAfterMs: (tries) => {
      //   console.log("reconnectAfterMs", tries);
      //   return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000;
      // },
      // rejoinAfterMs: (tries) => {
      //   console.log("rejoinAfterMs", tries);
      //   return [1000, 2000, 5000][tries - 1] || 10000;
      // },
    });
    this.p_signal_init();

    this.p_socket.onOpen(this.p_on_connect.bind(this));
    this.p_socket.onClose(this.p_on_disconnect.bind(this));
    this.p_socket.onError(this.p_on_error.bind(this));

    this.p_socket.connect();

    // if (is_reconnect) {
    //   this.rejoin_channels();
    // }
  }

  // private rejoin_channels() {
  //   console.log("Reconnecting all channels", this.p_channels);
  //   const channels = Object.entries(this.p_channels);
  //   this.p_channels = {};
  //   for (const [channel_id, channel] of channels) {
  //     console.log("Reconnecting to channel", channel_id);
  //     this.getChannel(
  //       channel_id,
  //       channel.onJoin,
  //       channel.onMessage,
  //       channel.onJoinError
  //     );
  //   }
  // }

  private p_update_all_tokens() {
    if (this.access_token) {
      for (const [
        channelId,
        {
          ref: { channel },
        },
      ] of Object.entries(this.p_channels)) {
        channel
          .push("update_access_token", {
            access_token: this.access_token,
          })
          .receive("ok", () => {
            console.debug("Access token updated for:", channelId);
          })
          .receive("error", () => {
            console.warn("Error updating access token for:", channelId);
          })
          .receive("timeout", () => {
            console.warn("Timeout updating access token for:", channelId);
          });
      }
    }
  }

  private p_on_connect() {
    console.debug(
      new Date(),
      "Socket connected to",
      this.p_url,
      this.p_channels
    );
    this.triggerSocketStatusCallbacks(true);
  }

  private p_on_disconnect(e: CloseEvent) {
    console.debug(
      new Date(),
      "Socket disconnected from",
      this.p_socket,
      this.p_url,
      this.p_channels,
      e
    );
    this.triggerSocketStatusCallbacks(false);

    if (e.wasClean || e.code === 1000) {
      console.log("Trigger manual reconnect timer");
      (this.p_socket as any)?.reconnectTimer?.scheduleTimeout();
    }

    // const lastError =
    //   new Date().valueOf() - (this.p_error_time?.valueOf() || 0);
    // if (lastError > 500) {
    //   if (e instanceof CloseEvent && e.wasClean) {
    //     setTimeout(this.p_connect.bind(this), 0);
    //   } else {
    //     setTimeout(this.p_connect.bind(this), 500);
    //   }
    // }
  }

  private p_on_error(error: any) {
    console.debug(
      new Date(),
      "Socket error:",
      this.p_url,
      error,
      this.p_channels
    );
    this.triggerSocketStatusCallbacks(false);
    // this.p_error_time = new Date();
    // setTimeout(this.p_connect.bind(this), 1000);
  }
}
