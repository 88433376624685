import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ScheduleDay, Transport } from "../../../data/api/types/schedule";
import { RootState } from "../../../data/store";
import { DayExplPlanRoster } from "../useTimetables_ByDate";
import {
  createDayRosterData,
  DayRosterData,
  getRosterCarData,
  getRosterSpecShiftData,
} from "./helpers";

export function useTableRows(
  selectedDate: string,

  explPlanData: DayExplPlanRoster | undefined,
  vehicleRows:
    | {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
        }[];
      }
    | undefined,
  vehicles_state:
    | {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[]
    | undefined,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
    | undefined,
  day_workshift_ids: string[] | undefined
) {
  //console.log("useTableRows");

  //  const start_time = performance.now();

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  // if (day_roster)
  //   console.log(
  //     "Errors",
  //     Object.values(day_roster)
  //       .flatMap((d) =>
  //         Object.values(d).flatMap((dd) =>
  //           dd.scheduleDay.roster_issues.find((i) => i.id === 174)
  //         )
  //       )
  //       .filter((x) => x !== undefined)
  //   );
  //console.log("ids", day_workshift_ids);

  const rows = useMemo(() => {
    let arr: DayRosterData[] = [];
    let shiftWithoutDriverCnts = 0;

    if (explPlanData?.expl_plan) {
      explPlanData?.expl_plan
        .slice()
        .sort((a, b) =>
          a.line_order &&
          b.line_order &&
          a.line_order !== 0 &&
          a.line_order > b.line_order
            ? 1
            : -1
        )
        .map((line) => {
          const line_data = line.cars.flatMap((car) => {
            const rostercar_data = getRosterCarData(
              day_workshift_ids,
              car.workshifts
                .slice()
                .sort((s1, s2) => (s1.start_time > s2.start_time ? 1 : -1)),
              //roster.data,
              day_roster,
              selectedDate,
              vehicleRows,
              vehicles_state,
              selectedAvtoColumn,
              selectedTransport
            );
            let show = false;
            if (
              car.workshifts[0].autocolumn === selectedAvtoColumn ||
              rostercar_data.driver1 > -1 ||
              rostercar_data.driver2 > -1 ||
              rostercar_data.driver3 > -1
            )
              show = true;

            if (show) {
              shiftWithoutDriverCnts += rostercar_data.shiftWithoutDriverCnts;
            }
            let res: DayRosterData[] = [];
            res = res.concat(
              createDayRosterData(
                line.line_name,
                car.car_no,
                rostercar_data.vehicle1,
                rostercar_data.vehicle12,
                rostercar_data.driver1,
                rostercar_data.vehicle2,
                rostercar_data.vehicle22,
                rostercar_data.driver2,
                rostercar_data.comment1,
                rostercar_data.comment2,
                show,
                line.line,
                line.code,
                rostercar_data.start1,
                rostercar_data.end1,
                rostercar_data.start2,
                rostercar_data.end2,
                false
              )
            );
            if (car.workshifts.length > 2)
              res = res.concat(
                createDayRosterData(
                  line.line_name,
                  car.car_no,
                  -1,
                  -1,
                  -1,
                  rostercar_data.vehicle3,
                  rostercar_data.vehicle32,
                  rostercar_data.driver3,
                  "",
                  rostercar_data.comment3,
                  show,
                  line.line,
                  line.code,
                  null,
                  null,
                  rostercar_data.start3,
                  rostercar_data.end3,
                  false
                )
              );
            return res;
          });

          arr = arr.concat(line_data);
        });
    }

    if (explPlanData?.spec_shifts) {
      //const spec_shift_by_name_type = explPlanData.spec_shifts.reduce<{
      //  [key: string]: DaySpecShiftRoster[];
      //}>((acc, x) => {
      //  if (acc[x.description] === undefined) {
      //    acc[x.description] = [x];
      //  } else {
      //    acc[x.description].push(x);
      //  }
      //  return acc;
      //}, {});

      const spec_data = explPlanData.spec_shifts
        .slice()
        .sort((a, b) =>
          //a.description &&
          //b.description &&
          a.description.toUpperCase() < b.description.toUpperCase()
            ? -1
            : // : a.description > b.description
              // ? 1
              1
        )
        .map((spec) => {
          const rostercar_data = getRosterSpecShiftData(
            day_workshift_ids,
            spec,
            day_roster,
            selectedDate,
            vehicleRows,
            vehicles_state,
            explPlanData.spec_shifts
          );
          //   let show = false;
          //   if (
          //     spec.autocolumn === selectedAvtoColumn ||
          //     rostercar_data.driver1 > -1 ||
          //     rostercar_data.driver2 > -1
          //   )
          //     show = true;

          //if (rostercar_data.shiftWithoutDriverCnts > 0)
          //  console.log("SpecShift: ", spec);
          shiftWithoutDriverCnts += rostercar_data.shiftWithoutDriverCnts;

          let car_no = -1;
          if (spec.description.toUpperCase().startsWith("П-НА")) {
            if (!isNaN(parseInt(spec.description.substring(4).trim(), 10)))
              car_no = parseInt(spec.description.substring(4).trim(), 10);
          }

          return createDayRosterData(
            spec.description.toUpperCase().startsWith("П-НА")
              ? spec.description.replace(/[0-9]/g, "")
              : spec.description,
            car_no,
            rostercar_data.vehicle1,
            rostercar_data.vehicle12,
            rostercar_data.driver1,
            rostercar_data.vehicle2,
            rostercar_data.vehicle22,
            rostercar_data.driver2,
            rostercar_data.comment1,
            rostercar_data.comment2,
            true, //rostercar_data.driver2 < -1 ? false : true, // ако са обединени на един ред да не показва смяната
            null,
            null,
            spec.description.toUpperCase().startsWith("П-НА")
              ? spec.start_time
                ? spec.start_time + 24 * 3600
                : spec.start_time
              : spec.start_time,
            spec.description.toUpperCase().startsWith("П-НА")
              ? spec.end_time
                ? spec.end_time + 24 * 3600
                : spec.end_time
              : spec.end_time,

            null,
            null,
            true
          );
        });
      arr = arr.concat(spec_data);
    }
    // const end_time = performance.now();

    // console.log(
    //   "UseTableRow:",

    //   end_time - start_time
    // );

    return { arr, shiftWithoutDriverCnts };
    //,{};
  }, [
    day_roster,
    day_workshift_ids,
    explPlanData?.expl_plan,
    explPlanData?.spec_shifts,
    selectedAvtoColumn,
    selectedDate,
    vehicleRows,
    vehicles_state,
  ]);

  //console.log("Rows: ", rows);
  //console.log("recapData: ", recapData);

  return rows;
}
