export type Timetable = {
  razpcad_tt_id: number;
  razpcad_line_id: number;
  razpcad_linename: string;
  tt_code: string;
  description: string;
};

export function timetableStartCode(timetable: Timetable): number {
  // let idx = timetable.tt_code.indexOf("-", 0);
  // if (idx > -1)
  //     return timetable.tt_code.substring(0, idx) as any as number
  // else
  return timetable?.tt_code && timetable.tt_code.match("[0-9]+")
    ? (timetable.tt_code.match("[0-9]+")?.at(0) as any as number)
    : -1;
}
