import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { useCallback, useState } from "react";

import { ReportPrinter } from "./ReportPrinter";
import moment, { Moment } from "moment";
import { LineTimetableData } from "../pages/reports/useTimetables_ByDate";
import { shadows } from "@mui/system";
import { CellDef, FontStyle } from "jspdf-autotable";
import { rpcRandomIntFromInterval } from "../serviceWorker/worker";

export function usePrintTimetableByDatePdfExporter() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      rows: {
        [key: string]: LineTimetableData[];
      },
      selectedDate: string
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter({
          marginX: 30,
          marginY: 20,
          headerText: `РАЗПИСАНИЯ ЗА ДАТА: ${moment(
            selectedDate,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} ${moment(selectedDate, "YYYY-MM-DD").format(
            "dddd"
          )}, АП "${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }", Автокoлона ${selectedAutoColumn}`,
        });

        printer.printSpace(15);

        printer.setFont("bold", 14, 1.2);
        printer.printText(
          `АП "${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }", Автокoлона ${selectedAutoColumn}`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.setFont("bold", 12, 1.2);
        printer.printText(
          `ПЛАН - РАЗПИСАНИЯ ЗА ДАТА: ${moment(
            selectedDate,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} ${moment(selectedDate, "YYYY-MM-DD").format(
            "dddd"
          )}`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        let neededHeight = 0;
        let tablerowH = 0;

        const pageMarginX = printer.pageMarginX;
        let colNum = 0;

        let marginY = printer.currentPageY;

        const colWidth = (printer.printableWidth - 20) / 16;

        Object.entries(rows).map(([key, item], idx) => {
          if (rows[key].length > 0) {
            const line_rows = item.map((element) => {
              let start1 = "";
              let end1 = "";
              let change = "";
              let start2 = "";
              let end2 = "";
              let hours1 = "";
              let hours2 = "";
              for (const shift of element.shifts) {
                if (shift.first_shift) {
                  start1 = shift.start_time;
                  end1 = shift.end_time;
                  hours1 = shift.work_hours;
                  if (element.singleShift) start2 = shift.start_time_2;
                  end2 = shift.end_time_2;
                } else {
                  start2 = shift.start_time;
                  end2 = shift.end_time;
                  hours2 = shift.work_hours;
                  if (end1 === start2) change = start2;
                }
              }
              return {
                car_no: element.car_no,
                start1,
                end1,
                hours1,
                change,
                start2,
                end2,
                hours2,
              };
            });

            if (tablerowH === 0) {
              {
                printer.autoTable({
                  margin: {
                    horizontal:
                      colNum % 2 === 0
                        ? printer.pageMarginX
                        : pageMarginX + printer.printableWidth / 2 + 10,
                    vertical: printer.pageMarginY,
                  },
                  styles: {
                    halign: "center",
                    valign: "top",
                    font: "Roboto",

                    //lineWidth: 0,
                    fontSize: 10,
                    cellPadding: 2,
                    lineColor: [0, 0, 0],

                    lineWidth: 0,
                    fontStyle: "slab-normal" as FontStyle,
                  },

                  bodyStyles: {
                    cellPadding: 1,
                    textColor: "White",
                    // fontStyle: "black" as FontStyle,
                  },
                  headStyles: {
                    lineWidth: 0.25,
                  },
                  columnStyles: {
                    0: { cellWidth: colWidth },
                    1: {
                      cellWidth: colWidth,
                      //font: "Courier",
                      //cellPadding: 1,
                      fontStyle: "thinitalic" as FontStyle,
                    },
                    2: {
                      cellWidth: colWidth,
                    },
                    3: { cellWidth: colWidth },
                    4: { cellWidth: colWidth },
                    5: { cellWidth: colWidth },
                    6: { cellWidth: colWidth },
                    7: {
                      cellWidth: colWidth,
                      //font: "Courier",
                      //cellPadding: 1,
                      fontStyle: "thinitalic" as FontStyle,
                    },
                  },
                  //body: line_rows as any,
                  columns: [
                    {
                      dataKey: "car_no",
                      header: "Кола",
                    },
                    {
                      dataKey: "hours1",
                      header: "Зараб 1",
                    },
                    {
                      dataKey: "start1",
                      header: "1 см. излизане",
                    },
                    {
                      dataKey: "end1",
                      header: "1 см. прибиране",
                    },
                    {
                      dataKey: "change",
                      header: "Смяна на водачите",
                    },
                    {
                      dataKey: "start2",
                      header: " 2см. излизане",
                    },
                    {
                      dataKey: "end2",
                      header: "2 см. прибиране",
                    },
                    {
                      dataKey: "hours2",
                      header: "Зараб 2",
                    },
                  ],
                  body: line_rows.slice(0, 1) as any,
                });
                printer.printSpace(-15);
              }

              //измервам таблица с един ред колко височина заема

              tablerowH = 10 + 4;
            }

            printer.setFont("bold", 9, 1);
            neededHeight =
              10 + printer.getDimensions(`${key} ${item[0].tt_code}`).h;
            neededHeight += line_rows.length * tablerowH + printer.pageMarginY;

            //ако има място за заглавие на линия таблица започва ... ако не -> пренася
            if (
              printer.currentPageY + neededHeight >=
              printer.printableHeight + printer.pageMarginY
            ) {
              if (colNum % 2 === 0) {
                if (printer.doc.getCurrentPageInfo().pageNumber === 1)
                  printer.currentPageY = marginY;
                else printer.currentPageY = printer.pageMarginY;
              } else {
                //  printer.currentPageY = marginY;
                printer.addPage(neededHeight);
                printer.currentPageY = printer.pageMarginY;
                //printer.printableHeight + printer.pageMarginY * 2; // за да си го пренесе само
              }
              colNum++;

              {
                printer.autoTable({
                  margin: {
                    horizontal:
                      colNum % 2 === 0
                        ? printer.pageMarginX
                        : pageMarginX + printer.printableWidth / 2 + 10,
                    vertical: printer.pageMarginY,
                  },
                  styles: {
                    halign: "center",
                    valign: "top",
                    font: "Roboto",

                    //lineWidth: 0,
                    fontSize: 10,
                    cellPadding: 2,
                    lineColor: [0, 0, 0],
                    //lineWidth: 0.25,
                    lineWidth: 0,
                    fontStyle: "slab-normal" as FontStyle,
                  },

                  bodyStyles: {
                    cellPadding: 2,
                    textColor: "White",
                    // fontStyle: "black" as FontStyle,
                  },
                  headStyles: {
                    lineWidth: 0.25,
                  },
                  columnStyles: {
                    0: { cellWidth: colWidth },
                    1: {
                      cellWidth: colWidth,
                      //font: "Courier",
                      //cellPadding: 1,
                      fontStyle: "thinitalic" as FontStyle,
                    },
                    2: {
                      cellWidth: colWidth,
                    },
                    3: { cellWidth: colWidth },
                    4: { cellWidth: colWidth },
                    5: { cellWidth: colWidth },
                    6: { cellWidth: colWidth },
                    7: {
                      cellWidth: colWidth,
                      //font: "Courier",
                      //cellPadding: 1,
                      fontStyle: "thinitalic" as FontStyle,
                    },
                  },
                  //body: line_rows as any,
                  columns: [
                    {
                      dataKey: "car_no",
                      header: "Кола",
                    },
                    {
                      dataKey: "hours1",
                      header: "Зараб 1",
                    },
                    {
                      dataKey: "start1",
                      header: "1 см. излизане",
                    },
                    {
                      dataKey: "end1",
                      header: "1 см. прибиране",
                    },
                    {
                      dataKey: "change",
                      header: "Смяна на водачите",
                    },
                    {
                      dataKey: "start2",
                      header: " 2см. излизане",
                    },
                    {
                      dataKey: "end2",
                      header: "2 см. прибиране",
                    },
                    {
                      dataKey: "hours2",
                      header: "Зараб 2",
                    },
                  ],
                  body: line_rows.slice(0, 1) as any,
                });
                printer.printSpace(-15);
                marginY = printer.currentPageY;
              }
            }
            printer.printSpace(15);

            //printer.printSpace(-8);

            printer.autoTable({
              margin: {
                horizontal:
                  colNum % 2 === 0
                    ? printer.pageMarginX
                    : pageMarginX + printer.printableWidth / 2 + 10,
                top: 0,
                vertical: 0,
              },
              //    showHead: "never",
              styles: {
                halign: "center",
                valign: "top",
                font: "Roboto",
                fontSize: 10,
                cellPadding: 0,
                lineColor: [0, 0, 0],
                //lineWidth: 0.25,
                lineWidth: 0,
                fontStyle: "slab-normal" as FontStyle,
              },

              bodyStyles: {
                cellPadding: 2,
              },
              columnStyles: {
                0: { cellWidth: colWidth },
                1: {
                  cellWidth: colWidth,
                  fontStyle: "thinitalic" as FontStyle,
                },
                2: {
                  cellWidth: colWidth,
                },
                3: { cellWidth: colWidth },
                4: { cellWidth: colWidth },
                5: { cellWidth: colWidth },
                6: { cellWidth: colWidth },
                7: {
                  cellWidth: colWidth,

                  fontStyle: "thinitalic" as FontStyle,
                },
              },
              body: line_rows as any,
              head: [
                [
                  {
                    content: `${key}   ${item[0].tt_code}`,
                    styles: {
                      halign: "center",
                      fontStyle: "slab-bold" as FontStyle,
                    },
                    colSpan: 8,
                  } as CellDef,
                ],
              ],
              columns: [
                {
                  dataKey: "car_no",
                },
                {
                  dataKey: "hours1",
                },
                {
                  dataKey: "start1",
                },
                {
                  dataKey: "end1",
                },
                {
                  dataKey: "change",
                },
                {
                  dataKey: "start2",
                },
                {
                  dataKey: "end2",
                },
                {
                  dataKey: "hours2",
                },
              ],
            });
            printer.doc.setLineWidth(1);
            printer.doc.line(
              colNum % 2 === 0
                ? printer.pageMarginX
                : pageMarginX + printer.printableWidth / 2 + 10,
              printer.currentPageY,
              colNum % 2 === 0
                ? printer.printableWidth / 2 + printer.pageMarginX - 10
                : printer.printableWidth + printer.pageMarginX,
              printer.currentPageY
            );
            printer.printSpace(1);
          }
        });

        printer.save(
          `Разписания-${selectedDate}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [depots, selectedAutoColumn, selectedDepot]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
