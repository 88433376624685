import { Paper } from "@mui/material";
import { useProfile } from "../../common/useProfile";

export function CurrentUser() {
  const profile = useProfile();

  return (
    <Paper sx={{ px: 3, py: 1 }}>
      <h2>Build info:</h2>
      <pre>
        Build timestamp: {import.meta.env.VITE_REACT_APP_BUILD_TS || "Unknown"}
        <br />
        Version: {import.meta.env.VITE_REACT_APP_VERSION || "Unknown"}
        <br />
        API: {import.meta.env.VITE_API_BASE || "Unknown"}
        <br />
        WS: {import.meta.env.VITE_WS_ADDRESS || "Unknown"}
        <br />
        OIDC: {import.meta.env.VITE_AUTH_SERVER || "Unknown"}
      </pre>
      <h2>User data:</h2>
      <pre>{JSON.stringify(profile, undefined, 2)}</pre>
    </Paper>
  );
}
