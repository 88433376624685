import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { useCallback, useState } from "react";
import { ReportPrinter } from "./ReportPrinter";
import moment from "moment";

export function useDriverPersonalAcountPdfExporter() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const [needsAutocolumnSelect] = useSelector((state: RootState) => [
    state.filters.needsAutocolumnSelect,
  ]);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      selectedMonth: string,
      selectedDriverId: string,
      selectedDriverName: string
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter();
        printer.printSpace(5);

        printer.setFont("bold", 14, 1.2);

        printer.printText(
          `${selectedTransport !== "A" ? `${selectedTransport} Депо` : "АП"} "${
            depots.find((d) => d.id === selectedDepot)?.value || "? "
          } ${
            selectedTransport === "A" || needsAutocolumnSelect
              ? ", Автокoлона " + selectedAutoColumn.toString()
              : '"'
          }`,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.setFont("bold", 12, 1.2);
        printer.printText(
          `РАЗХОД НА ВОДАЧ СЛ.№${selectedDriverId}, ${selectedDriverName}, ЗА МЕСЕЦ ${selectedMonth}
          `,
          printer.pageMarginX + printer.printableWidth / 2,
          printer.printableWidth,
          { align: "center" }
        );

        printer.autoTable({
          styles: {
            halign: "center",
            valign: "top",
            font: "Roboto",
            fontSize: 10,
            lineColor: [0, 0, 0],
            lineWidth: 0,
          },
          bodyStyles: {
            cellPadding: 4,
          },
          columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 55, halign: "left" },
            2: {
              cellWidth: 50,
            },
            3: { cellWidth: 55 },
            4: { cellWidth: 55 },
            5: { cellWidth: 55 },
            6: { cellWidth: 55 },
            7: { cellWidth: 60 },
          },
          //body: rows ? (rows as any) : [],
          html: "#driver_personal_account",

          willDrawCell: (cell) => {
            if (cell.section === "head") {
              if (cell.column.index === 8) {
                cell.cell.text = ["Между", "сменна", "почивка"];
              }

              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);

              printer.doc.line(
                cell.cell.x,
                cell.cell.y,
                cell.cell.x + cell.cell.width,
                cell.cell.y
              );

              printer.doc.line(
                cell.cell.x,
                cell.cell.y + cell.cell.height,
                cell.cell.x + cell.cell.width,
                cell.cell.y + cell.cell.height
              );
              printer.doc.setLineWidth(oldWidth);
            } else if (cell.section === "foot") {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(1);
              printer.doc.line(
                cell.cell.x,
                cell.cell.y + cell.cell.height,
                cell.cell.x + cell.cell.width,
                cell.cell.y + cell.cell.height
              );
              printer.doc.setLineWidth(oldWidth);
            } else {
              const oldWidth = printer.doc.getLineWidth();
              printer.doc.setLineWidth(0.25);
              printer.doc.line(
                cell.cell.x,
                cell.cell.y + cell.cell.height,
                cell.cell.x + cell.cell.width,
                cell.cell.y + cell.cell.height
              );
              printer.doc.setLineWidth(oldWidth);
            }
          },
        });
        //}
        printer.save(
          `Разход на водач ${selectedDriverId} за ${selectedMonth}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      depots,
      needsAutocolumnSelect,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
