import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Divider, IconButton, styled, Toolbar } from "@mui/material";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawerOpen } from "../data/settingsSlice";
import { RootState } from "../data/store";
import { SubOutlet } from "../pages/SubOutlet";
import { Header } from "./Header";
import { SideMenu } from "./SideMenu";

interface DrawerProps extends MuiDrawerProps {
  drawerWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<DrawerProps>(({ theme, open, drawerWidth }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open &&
      drawerWidth && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(13),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(13),
        },
      }),
  },
}));

export function DrawerLayout() {
  const dispatch = useDispatch();
  const drawerWidth = useSelector(
    (state: RootState) => state.settings.drawerWidth
  );
  const open = useSelector((state: RootState) => state.settings.drawerOpen);

  const [rightToolbarMenu, setRightToolbarMenu] =
    useState<HTMLDivElement | null>(null);
  const [submenu, setSubmenu] = useState<HTMLDivElement | null>(null);
  const [belowSubmenu, setBelowSubmenu] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <Header setRightToolbarMenu={setRightToolbarMenu} />

      <Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={() => dispatch(toggleDrawerOpen())}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <SideMenu setSubmenu={setSubmenu} setBelowSubmenu={setBelowSubmenu} />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Toolbar variant="dense" />
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          {rightToolbarMenu && submenu && belowSubmenu ? (
            <SubOutlet context={{ rightToolbarMenu, submenu, belowSubmenu }} />
          ) : undefined}
        </Box>
      </Box>
    </>
  );
}
