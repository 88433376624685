import { ItemContainer } from "./ItemContainer";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { VehicleItem } from "./VehicleItem";
import {
  Roster,
  RosterDriver,
  RosterShift,
  RosterVehicle,
} from "./types/Roster";
import { AddShiftToVehicleData } from "../../data/schedules/types";
import { useSchedules } from "../../data/schedules/useSchedules";
import { warnOnPromise } from "./warnOnPromise";
import { grey } from "@mui/material/colors";
import { GenericCommentDialog } from "../../components/GenericCommentDialog";
import { VehicleStateDialog } from "../../components/VehicleStateDialog";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { Vehicle } from "../../data/api/types/driver";
import { VehicleCompositionDialog } from "../../components/VehicleCompositionDialog";
import { ScheduleDriver } from "../../data/api/types/schedule";

export function useDropOnVehicle(
  roster: Roster,
  selectedDate: string,
  isReadOnly: boolean
) {
  const { addDriverToVehicle, addNewDriverToVehicle, addShiftToVehicle } =
    useSchedules();

  const [errors, setErrors] = useState<string[]>([]);
  const createErrors = useCallback(
    (result: { status: "error"; errors: any[] }, vehicle: RosterVehicle) => {
      //console.error("ERROR", result.errors);
      const errorMessages = result.errors.map((e) => {
        if (e.error_type === "veh_overlaps") {
          return `Припокриващи се смени за превозно средство ${vehicle.vehicle_id}`;
        } else if (e.error_type === "prev_day_overlaps") {
          return `Припокриващи се смени с предния ден за превозно средство ${vehicle.vehicle_id}`;
        } else if (e.error_type === "next_day_overlaps") {
          return `Припокриващи се смени със следващия ден за превозно средство ${vehicle.vehicle_id}`;
        } else if (e.error_type === "driver_current_day") {
          return `Припокриващи се смени за водач с превозно средство ${vehicle.vehicle_id}`;
        } else if (e.error_type === "driver_prev_day") {
          return `Припокриващи се смени с предния ден за водач с превозно средство ${vehicle.vehicle_id}`;
        } else if (e.error_type === "driver_next_day") {
          return `Припокриващи се смени със следващия ден за водач с превозно средство ${vehicle.vehicle_id}`;
        } else {
          return `Неизвестна грешка за превозно средство ${vehicle.vehicle_id}`;
        }
      });
      if (errorMessages.length > 0) {
        setErrors(errorMessages);
      } else {
        setErrors([
          `Неизвестна грешка за превозно средство ${vehicle.vehicle_id}`,
        ]);
      }
    },
    []
  );

  const dropOnVehicle = useCallback(
    (
      item: RosterDriver | RosterShift,
      vehicle: RosterVehicle,
      shiftNumber: 1 | 2 | null,
      driverIdx: number
    ) => {
      if (isReadOnly) {
        return;
      }

      console.log("Drop on vehicle:", item, vehicle, shiftNumber, roster);

      if (item.type === "timetable" || item.type === "special") {
        if (!shiftNumber) {
          const otherShift = roster.unusedShifts.find(
            (shift) =>
              shift.type === "timetable" &&
              item.type === "timetable" &&
              shift.shift.line === item.shift.line &&
              shift.shift.car === item.shift.car &&
              shift.shift.workshift.shift_type !==
                item.shift.workshift.shift_type
          );

          const shift1 = vehicle.shifts[0]?.[driverIdx];
          const shift2 = vehicle.shifts[1]?.[driverIdx];

          if (item.shift.workshift.shift_type === 1) {
            const data: AddShiftToVehicleData[] = [];

            if (shift1?.schedule_id) {
              data.push({
                driver_id: shift1.schedule_id,
                date: selectedDate,
                shift_type: 1,
                shift_id: item.shift.workshift.id,
                veh1_id: vehicle.vehicle_id,
                veh2_id: null,
              });
            }
            if (otherShift && shift2?.schedule_id) {
              data.push({
                driver_id: shift2.schedule_id,
                date: selectedDate,
                shift_type: 2,
                shift_id: otherShift.shift.workshift.id,
                veh1_id: vehicle.vehicle_id,
                veh2_id: null,
              });
            }

            if (data.length > 0) {
              warnOnPromise(addShiftToVehicle, data).then((result) => {
                if (result.status === "error") {
                  createErrors(result, vehicle);
                } else {
                  setErrors([]);
                }
              });
            }
          } else {
            const data: AddShiftToVehicleData[] = [];
            if (otherShift && shift1?.schedule_id) {
              data.push({
                driver_id: shift1.schedule_id,
                date: selectedDate,
                shift_type: 1,
                shift_id: otherShift.shift.workshift.id,
                veh1_id: vehicle.vehicle_id,
                veh2_id: null,
              });
            }
            if (shift2?.schedule_id) {
              data.push({
                driver_id: shift2?.schedule_id || "",
                date: selectedDate,
                shift_type: 2,
                shift_id: item.shift.workshift.id,
                veh1_id: vehicle.vehicle_id,
                veh2_id: null,
              });
            }

            if (data.length > 0) {
              warnOnPromise(addShiftToVehicle, data).then((result) => {
                if (result.status === "error") {
                  createErrors(result, vehicle);
                } else {
                  setErrors([]);
                }
              });
            }
          }
        } else if (vehicle.shifts[shiftNumber - 1][driverIdx]) {
          if (vehicle.shifts[shiftNumber - 1][driverIdx]?.schedule_id) {
            warnOnPromise(addShiftToVehicle, {
              driver_id:
                vehicle.shifts[shiftNumber - 1][driverIdx]?.schedule_id || "",
              date: selectedDate,
              shift_type: shiftNumber,
              shift_id: item.shift.workshift.id,
              veh1_id: vehicle.vehicle_id,
              veh2_id: null,
            }).then((result) => {
              if (result.status === "error") {
                createErrors(result, vehicle);
              } else {
                setErrors([]);
              }
            });
          }
        } else {
          console.warn("No driver, can't drop shift");
        }
      } else if (item.type === "driver") {
        if (shiftNumber) {
          warnOnPromise(addDriverToVehicle, {
            driver_id: item.schedule_id,
            date: selectedDate,
            shift_type: shiftNumber,
            veh1_id: vehicle.vehicle_id,
            veh2_id: null,
          }).then((result) => {
            if (result.status === "error") {
              createErrors(result, vehicle);
            } else {
              setErrors([]);
            }
          });
        } else {
          warnOnPromise(addNewDriverToVehicle, {
            driver_id: item.schedule_id,
            date: selectedDate,
            shift_type: item.shift_type || 1,
            veh1_id: vehicle.vehicle_id,
            veh2_id: null,
          }).then((result) => {
            if (result.status === "error") {
              createErrors(result, vehicle);
            } else {
              setErrors([]);
            }
          });
        }
      } else {
        console.warn("Dropped unknown element:", item);
      }
    },
    [
      addDriverToVehicle,
      addNewDriverToVehicle,
      addShiftToVehicle,
      createErrors,
      isReadOnly,
      roster,
      selectedDate,
    ]
  );

  const clearErrors = useCallback(() => setErrors([]), []);

  return { dropOnVehicle, errors, clearErrors };
}

export const DroppableVehiclesContainer = memo(
  function DroppableVehiclesContainer({
    isReadOnly,
    roster,
    selectedDate,
    selectedVehicleTypes,
    title,
    headerSx,
    drivers,
    apiVehicles,
    onDrop,
    saveShift,
    saveDriverComment,
    saveVehicleComment,
    saveVehicleState,
    saveComposition,
    clearVehicleState,
    openChangeVehicleDialog,
  }: {
    isReadOnly: boolean;
    roster: Roster;
    selectedDate: string;
    selectedVehicleTypes: readonly number[];
    title?: string;
    headerSx?: SxProps<Theme>;
    drivers: readonly ScheduleDriver[];
    apiVehicles: readonly Vehicle[];
    onDrop: (
      item: RosterDriver | RosterShift,
      over: RosterVehicle,
      shiftNumber: 1 | 2 | null,
      driverIdx: number
    ) => void;
    saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
    saveDriverComment: (data: {
      driver_id: string;
      shift_type: 1 | 2;
      date: string;
      comment: string | null;
    }) => Promise<{ status: "ok" }>;
    saveVehicleComment: (
      vehicle: RosterVehicle,
      comment: string | null
    ) => Promise<void>;
    saveVehicleState: (
      vehicle: RosterVehicle,
      state: number,
      comment: string | null
    ) => Promise<void>;
    saveComposition: (
      vehicle: RosterVehicle,
      secondVehicle: RosterVehicle | null
    ) => Promise<void>;
    clearVehicleState: (vehicle: RosterVehicle) => void;
    openChangeVehicleDialog: (
      shift: RosterShift,
      uiVehicle: number | null
    ) => void;
  }) {
    const { scheduleParameters } = useScheduleParameters();

    const allFilteredVehicles = useMemo(
      () =>
        selectedVehicleTypes.length === 0
          ? roster.vehicles
          : roster.vehicles.filter(
              (vehicle) =>
                !vehicle.vehicletype_id ||
                selectedVehicleTypes.includes(vehicle.vehicletype_id)
            ),
      [roster.vehicles, selectedVehicleTypes]
    );
    const filteredVehicles = useMemo(() => {
      const unordered = allFilteredVehicles.filter(
        (v) => !v.composition || v.composition.is_master
      );

      return unordered;
    }, [allFilteredVehicles]);

    const [commentDialog, setCommentDialog] = useState<RosterVehicle | null>(
      null
    );
    const [stateDialog, setStateDialog] = useState<RosterVehicle | null>(null);
    const [compositionDialog, setCompositionDialog] =
      useState<RosterVehicle | null>(null);

    const showVehicleCommentDialog = useCallback(
      (vehicle: RosterVehicle) => {
        if (!isReadOnly) {
          setCommentDialog(vehicle);
        }
      },
      [isReadOnly]
    );

    const showVehicleStateDialog = useCallback(
      (vehicle: RosterVehicle) => {
        if (!isReadOnly) {
          setStateDialog(vehicle);
        }
      },
      [isReadOnly]
    );

    const showCompositionDialog = useCallback(
      (vehicle: RosterVehicle) => {
        if (!isReadOnly) {
          setCompositionDialog(vehicle);
        }
      },
      [isReadOnly]
    );

    return (
      <ItemContainer
        title={title}
        headerSx={headerSx}
        sx={{ flexGrow: 1, mb: 2 }}
        contentSx={{ backgroundColor: grey[300] }}
      >
        <Grid container>
          {filteredVehicles.map((vehicle, idx) => {
            const previousType = filteredVehicles[idx - 1]?.vehicletype_name;
            const type = vehicle.vehicletype_name;

            if (previousType !== type) {
              return (
                <Fragment key={`vehicle-${vehicle.vehicle_id}`}>
                  {/*scheduleParameters &&
                    scheduleParameters[0] !== "TM" &&
                    scheduleParameters[0] !== "TB" && (
                      <Grid
                        xs={12}
                        sx={{
                          backgroundColor: grey[200],
                          border: "1px solid black",
                          borderRadius: 0.75,
                          p: 1,
                          mx: 0.75,
                          mt: 2,
                        }}
                      >
                        {type || "Неизвестна"}
                      </Grid>
                    )*/}
                  <Grid
                    xs={12}
                    sx={{
                      backgroundColor: grey[200],
                      border: "1px solid black",
                      borderRadius: 0.75,
                      p: 1,
                      mx: 0.75,
                      mt: 2,
                    }}
                  >
                    {type || "Неизвестна"}
                  </Grid>
                  <Grid key={`vehicle-${vehicle.vehicle_id}`}>
                    <VehicleItem
                      isReadOnly={isReadOnly}
                      vehicle={vehicle}
                      roster={roster}
                      selectedDate={selectedDate}
                      //accept={accept}
                      drivers={drivers}
                      apiVehicles={apiVehicles}
                      onDrop={onDrop}
                      saveShift={saveShift}
                      saveDriverComment={saveDriverComment}
                      showVehicleCommentDialog={showVehicleCommentDialog}
                      showVehicleStateDialog={showVehicleStateDialog}
                      showCompositionDialog={showCompositionDialog}
                      clearVehicleState={clearVehicleState}
                      openChangeVehicleDialog={openChangeVehicleDialog}
                    />
                  </Grid>
                </Fragment>
              );
            } else {
              return (
                <Grid key={`vehicle-${vehicle.vehicle_id}`}>
                  <VehicleItem
                    isReadOnly={isReadOnly}
                    vehicle={vehicle}
                    roster={roster}
                    selectedDate={selectedDate}
                    //accept={accept}
                    drivers={drivers}
                    apiVehicles={apiVehicles}
                    onDrop={onDrop}
                    saveShift={saveShift}
                    saveDriverComment={saveDriverComment}
                    showVehicleCommentDialog={showVehicleCommentDialog}
                    showVehicleStateDialog={showVehicleStateDialog}
                    showCompositionDialog={showCompositionDialog}
                    clearVehicleState={clearVehicleState}
                    openChangeVehicleDialog={openChangeVehicleDialog}
                  />
                </Grid>
              );
            }
          })}
        </Grid>

        <GenericCommentDialog
          open={!!commentDialog}
          initialValue={commentDialog?.vehicle.days[selectedDate]?.comment}
          heading={`Коментар за превозно средство ${
            commentDialog?.vehicle_id || ""
          }:`}
          onClose={() => setCommentDialog(null)}
          onSave={async (comment) => {
            if (commentDialog) {
              await saveVehicleComment(commentDialog, comment);
            }
          }}
        />

        {scheduleParameters && (
          <>
            <VehicleStateDialog
              open={!!stateDialog}
              initialValue={
                stateDialog?.vehicle.days[selectedDate]?.state?.id || 0
              }
              heading={`Промяна на състояние на превозно средство ${
                stateDialog?.vehicle_id || ""
              }:`}
              transport={scheduleParameters[0]}
              onClose={() => setStateDialog(null)}
              onSave={async (state, comment) => {
                if (stateDialog) {
                  await saveVehicleState(stateDialog, state, comment);
                }
              }}
            />

            <VehicleCompositionDialog
              open={!!compositionDialog}
              currentVehicle={compositionDialog}
              vehicleList={allFilteredVehicles}
              onClose={() => setCompositionDialog(null)}
              onSave={async (secondVehicle: RosterVehicle | null) => {
                if (compositionDialog) {
                  await saveComposition(compositionDialog, secondVehicle);
                }
              }}
            />
          </>
        )}
      </ItemContainer>
    );
  }
);
