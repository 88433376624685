import moment, { Moment } from "moment";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { ReportPrinter } from "./ReportPrinter";
import { Transport } from "../data/api/types/driver";

export function usePrintVehicleRecapitulationReport() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (
      rows: {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
        }[];
      },
      vehicles_states: {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[],
      selectedDate: Moment,
      totalVehicleCount: number
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter();
        printer.printSpace(5);

        printer.setFont("bold", 18, 1.2);
        printer.printText(
          `Разпределение на водачи за ${moment(
            selectedDate,
            "YYYY-MM-DD"
          ).format("LL")}`
        );

        printer.setFont("italic", 12, 1.2);
        printer.printText(
          `Гараж ${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }, Автокoлона ${selectedAutoColumn}`
        );

        printer.printSpace(15);
        printer.setFont("bold", 12, 1.2);
        printer.printText(`Общо водачи: ${totalVehicleCount} бр.`);

        printer.printSpace(15);

        for (const [stateId, drivers] of Object.entries(rows)) {
          const state = vehicles_states.find(
            (s) => s.id === parseInt(stateId, 10)
          );

          printer.setFont("bold", 12, 1.2);
          printer.printText(
            `${state?.description || "Неопределен"} - ${drivers.length} бр.`
          );

          printer.printSpace(5);

          printer.setFont("normal", 9, 2);
          printer.printText(drivers.concat(drivers).concat(drivers).join(", "));

          printer.printSpace(15);
        }

        printer.save(
          `Коли-Рекапитулация-${selectedDate}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [depots, selectedAutoColumn, selectedDepot]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
