import { Box } from "@mui/material";
import moment from "moment";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";
import { isLateCar } from "./useDriverItemTitle";

export function DriverItemYesterdayShift({
  driver,
}: {
  driver: RosterDriver | ElectroRosterDriver;
}) {
  if (
    driver.old_daytasks[0]?.tasks[0] &&
    driver.old_daytasks[0].tasks[0].linename &&
    driver.old_daytasks[0].tasks[0].shift_type
  ) {
    return (
      <Box
        sx={{
          fontSize: 10,
          fontWeight: "normal",
          color: (theme) => theme.palette.text.secondary,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {`${driver.old_daytasks[0].tasks[0].linename}${
          driver.old_daytasks[0].tasks[0].car_no &&
          driver.old_daytasks[0].tasks[0].car_no > 0
            ? `/${driver.old_daytasks[0].tasks[0].car_no}`
            : ""
        }${
          driver.old_daytasks[0].tasks[0].end_time &&
          driver.old_daytasks[0].tasks[0].car_no &&
          driver.old_daytasks[0].tasks[0].car_no > 0 &&
          isLateCar(
            driver.old_daytasks[0].tasks[0].end_time,
            driver.old_daytasks[0].tasks[0].shift_type,
            moment(driver.old_daytasks[0].date, "DD.MM.YYYY"),
            0
          )
            ? "K"
            : ""
        }`}
      </Box>
    );
  } else {
    return <></>;
  }
}
