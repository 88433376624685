import { useDrag } from "react-dnd";
import { ElectroDriverItem, ElectroDriverItemProps } from "./ElectroDriverItem";

export function ElectroDraggableDriverItem({
  isReadOnly,
  driver,
  roster,
  selectedDate,
  assigned,
  style,
  saveComment,
  saveShift,
}: ElectroDriverItemProps) {
  const [dragStyle, drag] = useDrag(
    () => ({
      type: `driver-shift-${driver.shift_type || 1}`,
      item: driver,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [driver]
  );

  return (
    <ElectroDriverItem
      isReadOnly={isReadOnly}
      driver={driver}
      roster={roster}
      selectedDate={selectedDate}
      assigned={assigned}
      ref={drag}
      style={{ ...style, ...dragStyle }}
      saveComment={saveComment}
      saveShift={saveShift}
    />
  );
}
