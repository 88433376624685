import moment from "moment";
import { CSSProperties, ForwardedRef, forwardRef } from "react";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../../data/schedules/types";
import { DriverItemCommentIndicator } from "../DriverItemCommentIndicator";
import { DriverItemContainer } from "../DriverItemContainer";
import { DriverItemDialog } from "../DriverItemDialog";
import { DriverItemErrorIndicator } from "../DriverItemErrorIndicator";
import { DriverItemMainText } from "../DriverItemMainText";
import { DriverItemYesterdayShift } from "../DriverItemYesterdayShift";
import { ElectroRoster, ElectroRosterDriver } from "../types/ElectroRoster";
import { useDriverItemState } from "../useDriverItemState";

export type ElectroDriverItemProps = {
  isReadOnly: boolean;
  roster: ElectroRoster;
  driver: ElectroRosterDriver;
  assigned?: boolean;
  selectedDate: string;
  style?: CSSProperties;
  saveComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
};

const UnassignedDriverItem = forwardRef(
  (
    {
      isReadOnly,
      driver,
      //roster,
      selectedDate,
      style,
      saveComment,
    }: Omit<ElectroDriverItemProps, "assigned">,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const currentDate = moment(selectedDate, "YYYY-MM-DD").startOf("day");
    const isFirstOfMonth = currentDate.isSame(
      currentDate.clone().startOf("month")
    );

    const {
      driverComment,
      driverTitle,
      contextMenu,
      setContextMenu,
      onContextMenu,
    } = useDriverItemState(driver, false, isReadOnly, isFirstOfMonth);

    return (
      <DriverItemContainer
        id={`driver-item-${driver.driver_id}`}
        style={{
          ...style,
          position: "relative",
          padding: "0px 6px",
          minWidth: 48,
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
        ref={ref}
        driverColor={driver.color}
        hasErrors={driver.roster_issues.length > 0}
        onContextMenu={onContextMenu}
        title={driverTitle}
      >
        <div style={{ position: "relative" }}>
          <DriverItemMainText
            driver={driver}
            assigned={false}
            isFirstOfMonth={isFirstOfMonth}
          />
          <DriverItemYesterdayShift driver={driver} />
          <DriverItemCommentIndicator
            comment={driverComment || driver.comment}
            isRoster={!!driverComment && driverComment.trim() !== ""}
            offset={[-6, 0]}
          />

          <DriverItemDialog
            driver={driver}
            selectedDate={selectedDate}
            driverComment={driverComment}
            isReadOnly={isReadOnly}
            isAssigned={false}
            contextMenu={contextMenu}
            saveComment={saveComment}
            onCloseMenu={() => setContextMenu(null)}
          />
        </div>

        <DriverItemErrorIndicator isVisible={driver.roster_issues.length > 0} />
      </DriverItemContainer>
    );
  }
);

const AssignedDriverItem = forwardRef(
  (
    {
      isReadOnly,
      driver,
      selectedDate,
      style,
      saveComment,
    }: Omit<ElectroDriverItemProps, "assigned">,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const currentDate = moment(selectedDate, "YYYY-MM-DD").startOf("day");
    const isFirstOfMonth = currentDate.isSame(
      currentDate.clone().startOf("month")
    );

    const {
      driverComment,
      driverTitle,
      contextMenu,
      setContextMenu,
      onContextMenu,
    } = useDriverItemState(driver, true, isReadOnly, isFirstOfMonth);

    return (
      <DriverItemContainer
        id={`driver-item-${driver.driver_id}`}
        style={{
          ...style,
          position: "relative",
          padding: "0px 6px",
        }}
        ref={ref}
        driverColor={driver.color}
        hasErrors={driver.roster_issues.length > 0}
        onContextMenu={onContextMenu}
        title={driverTitle}
      >
        <DriverItemMainText
          driver={driver}
          assigned={true}
          isFirstOfMonth={isFirstOfMonth}
        />
        <DriverItemYesterdayShift driver={driver} />
        <DriverItemCommentIndicator
          comment={driverComment || driver.comment}
          isRoster={!!driverComment && driverComment.trim() !== ""}
        />
        <DriverItemErrorIndicator isVisible={driver.roster_issues.length > 0} />

        <DriverItemDialog
          driver={driver}
          selectedDate={selectedDate}
          driverComment={driverComment}
          isReadOnly={isReadOnly}
          isAssigned={true}
          contextMenu={contextMenu}
          saveComment={saveComment}
          onCloseMenu={() => setContextMenu(null)}
        />
      </DriverItemContainer>
    );
  }
);

export const ElectroDriverItem = forwardRef(
  (
    { assigned, ...props }: ElectroDriverItemProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    if (assigned) {
      return <AssignedDriverItem {...props} ref={ref} />;
    } else {
      return <UnassignedDriverItem {...props} ref={ref} />;
    }
  }
);
