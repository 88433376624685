import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "phoenix";
import { RootState } from "./store";

export interface SocketsState {
  socket: Socket | null;
  disconnectingSocket: Socket | null;
  skipConnect: boolean;
}

const initialState: SocketsState = {
  socket: null,
  disconnectingSocket: null,
  skipConnect: false,
};

export const connectSocket = createAsyncThunk(
  "sockets/connect",
  (
    payload: { url: string; params?: object | (() => object) | undefined },
    thunkAPI
  ) => {
    const state = (thunkAPI.getState() as RootState).sockets;

    if (state.skipConnect) {
      console.log("RTK: Socket already connecting...");
      return Promise.reject(new Error("Socket already connecting..."));
    } else if (state.socket) {
      const socket = state.socket;

      return new Promise<void>((resolve) => {
        console.log("RTK: opening websocket connection to:", payload.url);
        socket.onOpen(() => {
          resolve();
        });
        socket.onClose((e) => {
          console.log("Socket closed", e);
        });
        socket.onError((e) => {
          console.log("Socket error", e);
        });

        socket.connect();
      });
    } else {
      return Promise.reject(new Error("Invalid socket object"));
    }
  }
);

export const disconnectSocket = createAsyncThunk(
  "sockets/disconnect",
  (_param: void, thunkAPI) => {
    const socket = (thunkAPI.getState() as RootState).sockets
      .disconnectingSocket;

    if (socket) {
      return new Promise<void>((resolve) => {
        console.log("RTK: Disconnecting socket...");

        socket.disconnect(() => {
          resolve();
        });
      });
    } else {
      Promise.resolve();
    }
  }
);

export const socketsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    openSocket: (
      state,
      action: PayloadAction<{
        url: string;
        params?: object | (() => object) | undefined;
      }>
    ) => {
      state.socket = new Socket(action.payload.url, {
        params: action.payload.params,
      });
    },

    closeSocket: (state) => {
      if (state.socket) {
        state.socket.disconnect(() => {
          //
        });
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(connectSocket.pending, (state, action) => {
      if (!state.socket) {
        state.socket = new Socket(action.meta.arg.url, {
          params: action.meta.arg.params,
        });
        state.skipConnect = false;
      } else {
        state.skipConnect = true;
      }
    });
    builder.addCase(connectSocket.fulfilled, (state) => {
      state.skipConnect = false;
    });
    builder.addCase(connectSocket.rejected, (state) => {
      state.skipConnect = false;
      state.socket = null;
    });

    builder.addCase(disconnectSocket.pending, (state) => {
      state.disconnectingSocket = state.socket;
      state.socket = null;
      state.skipConnect = false;
    });
    builder.addCase(disconnectSocket.fulfilled, (state) => {
      state.disconnectingSocket = null;
    });
  },
});

// Action creators are generated for each case reducer function
export const { openSocket } = socketsSlice.actions;

export default socketsSlice.reducer;
