import { Transport } from "./driver";
import { sortArray } from "./schedule";

export type RosterActionType =
  | "create"
  | "edit"
  | "automatic_edit"
  | "ready"
  | "gps_export"
  | "other";

export type RosterAction = {
  action: RosterActionType;
  sub_action: string | null;
  comment: string | null;
  timestamp: string;
  user: {
    sub: string;
    username: string;
    name: string;
    roles: string[];
  } | null;
};

export type UnprocessedApiRoster = {
  actions: RosterAction[];
  autocolumn: number;
  create_timestamp: string;
  day_in_month: string;
  depot_id: number;
  depot_name: string;
  edited: boolean;
  exported_to_gps: boolean;
  ready: boolean;
  schedule_id: number;
  transport: Transport;
};

export type ApiRoster = {
  actions: RosterAction[];
  autocolumn: number;
  create_timestamp: string;
  day_in_month: string;
  depot_id: number;
  depot_name: string;
  edited: boolean;
  exported_to_gps: boolean;
  ready: boolean;
  schedule_id: number;
  transport: Transport;

  status: "not-ready" | "ready" | "approved" | "exported" | "edited";
};

export type ApiRosterCollection = {
  transports: {
    transport: Transport;
    garages: {
      garage: number;
      garage_name: string;
      autocolumns: {
        autocolumn: number;
        rosters: ApiRoster[];
      }[];
    }[];
  }[];
};

export function processApiRoster(value: UnprocessedApiRoster): ApiRoster {
  const actions = sortArray(value.actions, "timestamp", {
    in_place: false,
    reverse: true,
  });

  let status: ApiRoster["status"] = "not-ready";
  for (let i = actions.length - 1; i >= 0; --i) {
    if (["create"].includes(actions[i].action)) {
      status = "not-ready";
    } else if (["edit", "automatic_edit"].includes(actions[i].action)) {
      status = "edited";
    } else if (
      ["ready"].includes(actions[i].action) &&
      !["approved", "exported"].includes(status)
    ) {
      status = "ready";
    } else if (
      actions[i].action === "other" &&
      actions[i].sub_action?.startsWith("approve:")
    ) {
      status = "approved";
    } else if (["gps_export"].includes(actions[i].action)) {
      status = "exported";
    }
  }

  return {
    ...value,
    actions: actions,
    status: status,
  };
}

export function processApiRosterCollection(
  value: readonly UnprocessedApiRoster[]
): ApiRosterCollection {
  const result: ApiRosterCollection = { transports: [] };

  for (const roster of value) {
    const processedRoster = processApiRoster(roster);
    let transport = result.transports.find(
      (t) => t.transport === processedRoster.transport
    );
    if (!transport) {
      transport = { transport: processedRoster.transport, garages: [] };
      result.transports.push(transport);
    }

    let garage = transport.garages.find(
      (g) => g.garage === processedRoster.depot_id
    );
    if (!garage) {
      garage = {
        garage: processedRoster.depot_id,
        garage_name: processedRoster.depot_name,
        autocolumns: [],
      };
      transport.garages.push(garage);
    }

    let autocolumn = garage.autocolumns.find(
      (a) => a.autocolumn === processedRoster.autocolumn
    );
    if (!autocolumn) {
      autocolumn = { autocolumn: processedRoster.autocolumn, rosters: [] };
      garage.autocolumns.push(autocolumn);
    }

    autocolumn.rosters.push(processedRoster);
  }

  sortArray(result.transports, "transport", { in_place: true });
  result.transports.forEach((transport) => {
    sortArray(transport.garages, "garage", { in_place: true });
    transport.garages.forEach((garage) => {
      sortArray(garage.autocolumns, "autocolumn", { in_place: true });
      garage.autocolumns.forEach((autocolumn) => {
        sortArray(autocolumn.rosters, "day_in_month", { in_place: true });
      });
    });
  });

  return result;
}
