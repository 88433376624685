import "./App.css";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { DrawerLayout } from "./common/DrawerLayout";
import { updateUser } from "./data/authSlice";
import { useTransportFilterEvents } from "./filters/useTransportFilter";
import { useDepotFilterEvents } from "./filters/useDepotFilter";
import { RootState, useAppDispatch } from "./data/store";
import { setSocketConnected } from "./data/scheduleSlice";
import { Warning } from "@mui/icons-material";

function App() {
  const auth = useAuth();
  const oldToken = useSelector(
    (state: RootState) => state.auth.user?.access_token
  );
  const dispatch = useAppDispatch();

  useTransportFilterEvents();
  useDepotFilterEvents();

  useEffect(() => {
    const onConnectionStatus = (connected: boolean) => {
      dispatch(setSocketConnected(connected));
    };
    window.globals.nariadSocket.addSocketStatusCallback(onConnectionStatus);
    dispatch(
      setSocketConnected(!!window.globals.nariadSocket.socket?.isConnected())
    );

    return () => {
      window.globals.nariadSocket.removeSocketStatusCallback(
        onConnectionStatus
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (!auth.isLoading) {
      if (auth.user) {
        if (oldToken !== auth.user.access_token) {
          //console.log("Access token changed:", auth.user?.access_token, auth);
          const { ...serializableUser } = auth.user;
          dispatch(updateUser(serializableUser));

          window.globals.nariadSocket.access_token =
            serializableUser.access_token;
        }
      } else {
        dispatch(updateUser(null));
      }
    }
  }, [auth.isLoading, auth.user, dispatch, oldToken]);

  const [globalMessage, setGlobalMessage] = useState<string | undefined>(
    import.meta.env.VITE_GLOBAL_MESSAGE
  );

  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100vw", height: "100vh" }}>
      <DrawerLayout />
      <Dialog
        open={!!globalMessage && globalMessage.trim() !== ""}
        onClose={() => setGlobalMessage(undefined)}
        fullWidth
        maxWidth="md"
        aria-labelledby="global-message-dialog-title"
        aria-describedby="global-message-dialog-description"
      >
        <DialogTitle
          id="global-message-dialog-title"
          color="error.contrastText"
          bgcolor="error.dark"
        >
          Внимание
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center", mt: 5 }}>
          <Warning color="error" fontSize="large" />
          <DialogContentText
            id="global-message-dialog-description"
            color="error.dark"
            fontSize="1.5em"
          >
            {globalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGlobalMessage(undefined)}>Затвори</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default App;
