//import { OidcSecure } from "@axa-fr/react-oidc";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Portal,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSubmenu } from "../../hooks/useSubmenu";
import { NavLink } from "../../common/NavLink";
import { WithAuth } from "../../common/WithAuth";
import { SubOutlet } from "../SubOutlet";

export function AdminPage() {
  const { submenu } = useSubmenu();

  return (
    <WithAuth>
      <div style={{ width: "100%", height: "100%" }}>
        <Portal container={submenu}>
          <List
            dense
            subheader={
              <ListSubheader component="div" inset>
                Admin options
              </ListSubheader>
            }
          >
            <ListItemButton to="/admin/current-user" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Current user" />
            </ListItemButton>
            <ListItemButton to="/admin/drivers" component={NavLink}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Drivers" />
            </ListItemButton>
          </List>
        </Portal>
        <SubOutlet />
      </div>
    </WithAuth>
  );
}
