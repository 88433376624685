import {
  Build,
  Comment,
  Delete,
  Print,
  DirectionsBus,
  RvHookup,
} from "@mui/icons-material";
import {
  alpha,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { green, grey, orange, yellow } from "@mui/material/colors";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { GridProps } from "@mui/system/Unstable_Grid/GridProps";
import moment from "moment";
import { Moment } from "moment";
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ConnectDropTarget, useDrop } from "react-dnd";
import { useProfile } from "../../common/useProfile";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import {
  usePostVehicleAutocolumnMutation,
  usePostVehicleCompositionMutation,
} from "../../data/api/hooks";
import { Vehicle } from "../../data/api/types/driver";
import { ScheduleDriver, sortArray } from "../../data/api/types/schedule";
import { ScheduleVehicleDay } from "../../data/api/types/vehiclesSchedule";
import {
  AddShiftToVehicleData,
  EditDriverDayRosterCommentData,
} from "../../data/schedules/types";
import { usePrintBlankJourneyList } from "../../printing/usePrintJourneyList";
import { DraggableDriverItem } from "./DraggableDriverItem";
import { DraggableShiftItem } from "./DraggableShiftItem";
import { DriverItem } from "./DriverItem";
import { ShiftItem } from "./ShiftItem";
import type { ElectroRosterVehicle } from "./types/ElectroRoster";
import {
  Roster,
  RosterDriver,
  RosterShift,
  RosterVehicle,
} from "./types/Roster";

// const VehicleContainer = styled(Box)(() => ({
//   display: "inline-flex",
//   flexDirection: "column",
//   margin: 0,
//   paddingLeft: 2,
//   paddingRight: 2,
//   paddingTop: 1,
//   paddingBottom: 1,
//   fontSize: 12,
//   fontWeight: "bold",
//   //cursor: "pointer",
//   //width: "25%",
//   minWidth: 320,
// }));

const GridContainer = styled(
  forwardRef((props: GridProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Grid {...props} container columns={16} ref={ref} />
  )),
  { shouldForwardProp: (prop) => prop !== "container" && prop !== "columns" }
)(({ theme }) => ({
  width: "100%",
  marginLeft: 1,
  marginRight: 1,
  border: "1px solid black",
  borderRadius: 3,
  "&:first-of-type": {
    marginTop: 1,
  },
  "&:not(:last-of-type)": {
    "&:not(:only-of-type)": {
      borderBottomColor: grey[400],
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  "&:last-of-type": {
    marginBottom: 1,
  },
  "&:not(:first-of-type)": {
    "&:not(:only-of-type)": {
      borderTop: "none",
      //borderTopColor: grey[400],
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
  alignContent: "center",
  padding: theme.spacing(0.5, 1.5),
  minHeight: 43,
}));

function hasDriver(
  vehicle: RosterVehicle,
  driverIdx: number,
  shift: 1 | 2 | null
): boolean {
  if (shift) {
    const drivers = vehicle.shifts[shift - 1];
    return !!drivers[driverIdx]?.driver_id;
  } else {
    return hasDriver(vehicle, driverIdx, 1) || hasDriver(vehicle, driverIdx, 2);
  }
}

export type RosterVehicleTask = {
  daytasks: {
    linename: string;
    car_no: number | null;
    start_time: string;
    end_time: string;
    shifts: (1 | 2)[];
  }[];
  date: string;
};

export function getVehicleTasks(
  vehicleDays: Record<string, ScheduleVehicleDay>,
  selectedDate: string
): RosterVehicleTask[] {
  const day = moment(selectedDate, "YYYY-MM-DD").subtract(1, "day");
  const tasks: RosterVehicleTask[] = [];

  for (let i = 0; i < 7; ++i) {
    const dayStr = day.format("YYYY-MM-DD");

    if (!vehicleDays[dayStr]) {
      tasks.push({ daytasks: [], date: day.format("L") });
    } else {
      //const dts = vehicle.vehicle.days[dayStr].daytasks.filter(
      //  (dt) =>
      //    dt.shift_id &&
      //    roster.allShifts.find((rs) => rs.shift.workshift.id === dt.shift_id)
      //);
      const dts = vehicleDays[dayStr].daytasks;
      const source = dts.length > 0 ? dts : vehicleDays[dayStr].workshifts;

      const daytasks = sortArray(
        source
          .filter((task) => task.linename && task.linename.trim() !== "")
          .map((task) => ({
            linename: task.linename as string,
            car_no: task.car_no,
            start_time: task.start_time,
            end_time: task.end_time,
            shifts: task.shift_type ? [task.shift_type] : [],
          })),
        "start_time"
      );

      const filtered: typeof daytasks = [];
      for (let i = 0; i < daytasks.length; ++i) {
        if (
          i === 0 ||
          daytasks[i - 1].linename !== daytasks[i].linename ||
          daytasks[i - 1].car_no !== daytasks[i].car_no
        ) {
          filtered.push(daytasks[i]);
        } else if (filtered.length > 0) {
          filtered[filtered.length - 1].shifts.push(...daytasks[i].shifts);
        }
      }
      tasks.push({
        daytasks: filtered.map((f) => ({
          ...f,
          shifts: sortArray(f.shifts, undefined, { in_place: true }).filter(
            (s, sIdx, ss) => sIdx === 0 || ss[sIdx - 1] !== s
          ),
        })),
        date: day.format("L"),
      });
    }

    day.subtract(1, "day");
  }

  return tasks;
}

function AssignedShift({
  isReadOnly,
  daytask,
  vehicle,
  selectedDate,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  daytask: RosterDriver["daytasks"][0];
  vehicle: RosterVehicle;
  selectedDate: string;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  if (daytask?.rosterShift) {
    const onAnotherVehicle = vehicle.vehicle_id !== daytask.veh1_id;

    return isReadOnly || onAnotherVehicle ? (
      <ShiftItem
        key={daytask.id}
        isReadOnly={isReadOnly}
        shift={daytask.rosterShift}
        inUiVehicle={vehicle.vehicle_id}
        selectedDate={selectedDate}
        assigned
        onAnotherVehicle={daytask.veh1_id}
        openChangeVehicleDialog={openChangeVehicleDialog}
      />
    ) : (
      <DraggableShiftItem
        key={daytask.id}
        isReadOnly={isReadOnly}
        shift={daytask.rosterShift}
        inUiVehicle={vehicle.vehicle_id}
        selectedDate={selectedDate}
        assigned
        openChangeVehicleDialog={openChangeVehicleDialog}
      />
    );
  } else {
    return <></>;
  }
}

function VehicleItemElementShift({
  isReadOnly,
  isShiftActive,
  shiftRef,
  shift,
  vehicle,
  roster,
  selectedDate,
  saveComment,
  saveShift,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  isShiftActive: boolean;
  shiftRef: ConnectDropTarget;
  shift?: RosterDriver | null;
  vehicle: RosterVehicle;
  roster: Roster;
  selectedDate: string;
  saveComment: (
    data: EditDriverDayRosterCommentData
  ) => Promise<{ status: "ok" }>;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  return (
    <Grid
      container
      columns={13}
      xs={7}
      sx={{
        backgroundColor: isShiftActive ? orange[400] : undefined,
        borderRadius: 0.5,
        alignItems: "baseline",
        //height: "100%",
      }}
      ref={shiftRef}
    >
      <Grid
        xs={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {shift ? (
          shift.daytasks
            .filter((dt) => !!dt)
            .map((dt) => (
              <AssignedShift
                key={dt.id}
                isReadOnly={isReadOnly}
                daytask={dt}
                vehicle={vehicle}
                selectedDate={selectedDate}
                openChangeVehicleDialog={openChangeVehicleDialog}
              />
            ))
        ) : (
          <>&nbsp;</>
        )}
      </Grid>
      <Grid
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {shift ? (
          isReadOnly ? (
            <DriverItem
              isReadOnly={isReadOnly}
              driver={shift}
              roster={roster}
              selectedDate={selectedDate}
              assigned
              saveComment={saveComment}
              saveShift={saveShift}
            />
          ) : (
            <DraggableDriverItem
              isReadOnly={isReadOnly}
              driver={shift}
              roster={roster}
              selectedDate={selectedDate}
              assigned
              saveComment={saveComment}
              saveShift={saveShift}
            />
          )
        ) : (
          <>&nbsp;</>
        )}
      </Grid>
    </Grid>
  );
}

function hasDriverWithNoShift(
  vehicle: RosterVehicle,
  driverIdx: number
): boolean {
  if (vehicle.shifts[0].length === 0 && vehicle.shifts[1].length === 0) {
    return false;
  } else {
    const shift1Unfilled =
      !!vehicle.shifts[0]?.[driverIdx]?.driver_id &&
      !vehicle.shifts[0]?.[driverIdx]?.daytasks?.find((dt) => dt.rosterShift);
    const shift2Unfilled =
      !!vehicle.shifts[1]?.[driverIdx]?.driver_id &&
      !vehicle.shifts[1]?.[driverIdx]?.daytasks?.find((dt) => dt.rosterShift);

    return shift1Unfilled || shift2Unfilled;
  }
}

function CommentIndicator({ active }: { active?: boolean }) {
  if (active) {
    return (
      <div
        style={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "13px 13px 0 0",
          borderColor: `${green[600]} transparent transparent transparent`,
          borderRadius: 2,
          position: "absolute",
          left: 0,
          top: 0,
          pointerEvents: "none",
        }}
      />
    );
  } else {
    return <></>;
  }
}

function createLineForTitle(dt: {
  linename: string;
  car_no: number | null;
  start_time: string;
  end_time: string;
  shifts: (1 | 2)[];
}) {
  if (dt.car_no && dt.car_no > 0) {
    return `${dt.linename}/${dt.car_no}`;
  } else {
    return dt.linename;
  }
}

function createShiftsForTitle(dt: {
  linename: string;
  car_no: number | null;
  start_time: string;
  end_time: string;
  shifts: (1 | 2)[];
}) {
  if (dt.shifts && dt.shifts.length > 0) {
    return ` (${dt.shifts
      .map((s) => (s === 1 ? "I-ва" : s === 2 ? "II-ра" : `${s}-та`))
      .join(", ")} смяна)`;
  } else {
    return "";
  }
}

export function createVehicleTitle(
  vehicle: RosterVehicle | ElectroRosterVehicle,
  selectedDate: Moment,
  tasks: {
    daytasks: {
      linename: string;
      car_no: number | null;
      start_time: string;
      end_time: string;
      shifts: (1 | 2)[];
    }[];
    date: string;
  }[],
  expireDateComment: string | null
) {
  const selectedDateStr = selectedDate.format("YYYY-MM-DD");
  const vehicleDay = vehicle.vehicle.days[selectedDateStr];

  const tasksString = tasks.map(
    (daytask) =>
      `    ${daytask.date}: ${
        daytask.daytasks.length > 0
          ? daytask.daytasks
              .map((dt) => createLineForTitle(dt) + createShiftsForTitle(dt))
              .join(", ")
          : "Няма задача"
      }`
  );

  return [
    `ПС: ${vehicle.vehicle_id}`,
    `Марка: ${vehicle.vehicletype_name}`,
    vehicle.regularDrivers && vehicle.regularDrivers.length > 0
      ? ["Титуляри:"].concat(
          vehicle.regularDrivers.map((d) => `    ${d.id}: ${d.name}`)
        )
      : [],
    expireDateComment,
    vehicleDay?.comment && vehicleDay.comment.trim() !== ""
      ? `Коментар: ${vehicleDay.comment}`
      : null,
    vehicleDay?.state?.id && vehicleDay.state.id > 0
      ? `Състояние: ${vehicleDay.state.description}`
      : null,
    vehicleDay?.state?.id !== 0 &&
    vehicleDay?.state?.comment &&
    vehicleDay.state.comment.trim() !== ""
      ? `Коментар за състояние: ${vehicleDay.state.comment}`
      : null,
    "Задачи за предходни дни:",
    tasksString,
  ]
    .flat()
    .filter((l) => l && l.trim() !== "")
    .join("\n");
}

export function VehiclePlaces({
  vehicle,
}: {
  vehicle: RosterVehicle | ElectroRosterVehicle;
}) {
  if (vehicle.places && vehicle.places > 0) {
    return (
      <Typography
        fontSize={10}
        lineHeight={1}
        color={alpha(grey[900], 0.5)}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {vehicle.places} бр.
      </Typography>
    );
  } else {
    return <></>;
  }
}

export function SecondVehicle({
  vehicle,
}: {
  vehicle: RosterVehicle | ElectroRosterVehicle;
}) {
  if (vehicle.composition) {
    return (
      <Typography
        fontSize={10}
        lineHeight={1}
        color={alpha(grey[900], 0.5)}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textDecoration:
            vehicle.composition?.is_master === false
              ? "line-through"
              : undefined,
        }}
      >
        {vehicle.composition.other_veh}
      </Typography>
    );
  } else {
    return <></>;
  }
}

export function VehicleTasks({
  tasks,
  isElectro,
}: {
  tasks: {
    daytasks: {
      linename: string;
      car_no: number | null;
      start_time: string;
      end_time: string;
      shifts: (1 | 2)[];
    }[];
    date: string;
  }[];
  isElectro?: boolean;
}) {
  return (
    <>
      {tasks[0]?.daytasks?.map((dt, dtIdx, dts) => (
        <Typography
          key={dtIdx}
          fontSize={10}
          lineHeight={1}
          color={isElectro ? alpha(grey[900], 0.75) : alpha(grey[900], 0.5)}
          fontWeight={isElectro ? "bold" : "normal"}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${dt.linename}${dt.car_no ? `/${dt.car_no}` : ""}`}
          {dts.length === 1 && dt.shifts.includes(2) ? (
            <>
              <br />
              II см.
            </>
          ) : dts.length === 1 && dt.shifts.includes(1) ? (
            <>
              <br /> I см.
            </>
          ) : (
            <></>
          )}
        </Typography>
      ))}
    </>
  );
}

function VehicleItemElement({
  isReadOnly,
  vehicle,
  roster,
  driverIdx,
  selectedDate,
  drivers,
  onDrop,
  saveShift,
  saveDriverComment,
  showVehicleCommentDialog,
  showVehicleStateDialog,
  showCompositionDialog,
  clearVehicleState,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  vehicle: RosterVehicle;
  roster: Roster;
  driverIdx: number;
  selectedDate: string;
  drivers: readonly ScheduleDriver[];
  apiVehicles: readonly Vehicle[];
  onDrop: (
    item: RosterDriver | RosterShift,
    over: RosterVehicle,
    shiftNumber: 1 | 2 | null,
    driverIdx: number
  ) => void;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  saveDriverComment: (data: {
    driver_id: string;
    shift_type: 1 | 2;
    date: string;
    comment: string | null;
  }) => Promise<{ status: "ok" }>;
  showVehicleCommentDialog: (vehicle: RosterVehicle) => void;
  showVehicleStateDialog: (vehicle: RosterVehicle) => void;
  showCompositionDialog: (vehicle: RosterVehicle) => void;
  clearVehicleState: (vehicle: RosterVehicle) => void;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  const onDropShift1 = useCallback(
    (item: RosterDriver | RosterShift) => onDrop(item, vehicle, 1, driverIdx),
    [onDrop, vehicle, driverIdx]
  );
  const onDropShift2 = useCallback(
    (item: RosterDriver | RosterShift) => onDrop(item, vehicle, 2, driverIdx),
    [onDrop, vehicle, driverIdx]
  );
  const onDropBothShifts = useCallback(
    (item: RosterDriver | RosterShift) =>
      onDrop(item, vehicle, null, driverIdx),
    [onDrop, vehicle, driverIdx]
  );

  const vehicleState = vehicle.vehicle.days[selectedDate]?.state;

  const [shift1Props, shift1Ref] = useDrop(
    {
      accept:
        isReadOnly || vehicle.vehicle.days[selectedDate]?.state?.category === 2
          ? []
          : [
              hasDriver(vehicle, driverIdx, 1)
                ? "timetable-shift-single-1"
                : "x",
              hasDriver(vehicle, driverIdx, 1)
                ? "timetable-shift-multiple-1"
                : "x",
              hasDriver(vehicle, driverIdx, 1)
                ? "timetable-shift-single-3"
                : "x",
              hasDriver(vehicle, driverIdx, 1)
                ? "timetable-shift-multiple-3"
                : "x",
              hasDriver(vehicle, driverIdx, 1)
                ? "timetable-shift-single-2"
                : "x",
              //hasDriver(vehicle, driverIdx, 1) ? "special-shift-multiple-1" : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "special-shift-multiple-1"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "special-shift-multiple-2"
                : "x",
              "driver-shift-1",
            ],
      drop: onDropShift1,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [vehicle, onDropShift1]
  );
  const isShift1Active = shift1Props.isOver && shift1Props.canDrop;

  const [shift2Props, shift2Ref] = useDrop(
    {
      accept:
        isReadOnly || vehicle.vehicle.days[selectedDate]?.state?.category === 2
          ? []
          : [
              hasDriver(vehicle, driverIdx, 2)
                ? "timetable-shift-single-2"
                : "x",
              hasDriver(vehicle, driverIdx, 2)
                ? "timetable-shift-multiple-2"
                : "x",
              hasDriver(vehicle, driverIdx, 2)
                ? "timetable-shift-single-3"
                : "x",
              hasDriver(vehicle, driverIdx, 2)
                ? "timetable-shift-multiple-3"
                : "x",
              //hasDriver(vehicle, driverIdx, 2) ? "special-shift-multiple-2" : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "special-shift-multiple-1"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "special-shift-multiple-2"
                : "x",
              "driver-shift-2",
            ],
      drop: onDropShift2,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [vehicle, onDropShift2]
  );
  const isShift2Active = shift2Props.isOver && shift2Props.canDrop;

  const [bothShiftsProps, bothShiftsRef] = useDrop(
    {
      accept:
        isReadOnly || vehicle.vehicle.days[selectedDate]?.state?.category === 2
          ? []
          : [
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-multiple-1"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-multiple-2"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-multiple-3"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-single-1"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-single-2"
                : "x",
              hasDriver(vehicle, driverIdx, null)
                ? "timetable-shift-single-3"
                : "x",
              //hasDriver(vehicle, driverIdx, null) ? "special-shift-multiple-1" : "x",
              //hasDriver(vehicle, driverIdx, null) ? "special-shift-multiple-2" : "x",
              "driver-shift-1",
              "driver-shift-2",
            ],
      drop: onDropBothShifts,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [vehicle, onDropBothShifts]
  );
  const areBothShiftsActive = bothShiftsProps.isOver && bothShiftsProps.canDrop;

  const [contextMenu, setContextMenu] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      if (!isReadOnly) {
        event.preventDefault();
        setContextMenu(
          contextMenu !== null
            ? null
            : {
                left: event.clientX + 2,
                top: event.clientY - 6,
              }
        );
      }
    },
    [contextMenu, isReadOnly]
  );

  const isDisabled = vehicle.vehicle.days[selectedDate]?.state?.category === 2;
  const isPTDue = vehicle.vehicle.days[selectedDate]?.state?.id === -1;

  const tasks = useMemo(() => {
    return getVehicleTasks(vehicle.vehicle.days, selectedDate);
  }, [selectedDate, vehicle.vehicle.days]);

  const expireDateComment = useMemo(() => {
    const expireDate = vehicle.vehicle.periodic_tech_test
      ? moment(vehicle.vehicle.periodic_tech_test)
      : null;
    const date = moment(selectedDate, "YYYY-MM-DD").add(4, "days");
    if (expireDate && expireDate.isSameOrBefore(date)) {
      return `Предстои периодичен технически преглед до ${expireDate.format(
        "L"
      )}`;
    } else {
      return null;
    }
  }, [vehicle.vehicle.periodic_tech_test, selectedDate]);
  //console.log("Expire comment:", expireDateComment);

  const [changeComposition] = usePostVehicleCompositionMutation();

  const exportJourneyLists = usePrintBlankJourneyList();
  const { scheduleParameters } = useScheduleParameters();
  const profile = useProfile();

  const [changeAutocolumn] = usePostVehicleAutocolumnMutation();

  const otherAutocolumns = useMemo(
    () =>
      (scheduleParameters
        ? profile?.garage_autocolumns?.[scheduleParameters[0]]?.[
            scheduleParameters[1]
          ] || []
        : []
      ).filter((a) => a !== vehicle.vehicle.days[selectedDate]?.autocolumn),
    [
      profile?.garage_autocolumns,
      scheduleParameters,
      selectedDate,
      vehicle.vehicle.days,
    ]
  );

  const changeAutocolumnCallback = useCallback(
    (autocolumn: number) => {
      setContextMenu(null);
      if (!isReadOnly && scheduleParameters) {
        console.log("Change vehicle autocolumn:", vehicle, autocolumn);
        changeAutocolumn({
          transport: scheduleParameters[0],
          depot: scheduleParameters[1],
          autocolumn: autocolumn,
          vehicle_id: vehicle.vehicle_id,
          start_date: selectedDate,
          end_date: selectedDate,
        })
          .unwrap()
          .then((r) => {
            if (r.status === "ok") {
              console.log("Vehicle", vehicle, "moved:", r);
            } else {
              console.warn("Can't move vehicle", vehicle, r);
              alert(
                `Грешка при преместване на превозно средство ${vehicle.vehicle_id} в автоколона ${autocolumn}`
              );
            }
          })
          .catch((e) => {
            console.warn("Can't move vehicle", vehicle, e);
            alert(
              `Грешка при преместване на превозно средство ${vehicle.vehicle_id} в автоколона ${autocolumn}`
            );
          });
      }
    },
    [changeAutocolumn, isReadOnly, scheduleParameters, selectedDate, vehicle]
  );

  return (
    <GridContainer
      sx={{
        backgroundColor: isDisabled
          ? orange[200]
          : areBothShiftsActive || isShift1Active || isShift2Active
          ? orange[100]
          : expireDateComment
          ? yellow[300]
          : hasDriverWithNoShift(vehicle, driverIdx)
          ? "white"
          : grey[300],
        position: "relative",
      }}
      title={createVehicleTitle(
        vehicle,
        moment(selectedDate, "YYYY-MM-DD"),
        tasks,
        expireDateComment
      )}
    >
      <CommentIndicator
        active={
          driverIdx === 0 &&
          (!!vehicle.vehicle.days[selectedDate]?.comment ||
            (!!vehicleState?.id && vehicleState.id > 0) ||
            !!expireDateComment)
        }
      />

      <Grid
        xs={2}
        sx={{
          display: "block",
          flexDirection: "column",
          justifyContent: "flex-start",
          //height: "100%",
          minHeight: 37,
          cursor: "pointer",
        }}
        ref={bothShiftsRef}
        //title={"Автобус"}
        onContextMenu={!isReadOnly ? handleContextMenu : undefined}
      >
        <Typography
          fontWeight="bold"
          fontSize={12}
          lineHeight={1.4}
          style={{
            textDecoration:
              vehicle.vehicle.days[selectedDate]?.exported_car &&
              profile &&
              profile.roles &&
              profile.roles.includes("nariad_admin")
                ? "line-through"
                : undefined,
          }}
        >
          {driverIdx === 0 ? vehicle.vehicle_id : <>&nbsp;</>}
        </Typography>

        {scheduleParameters &&
        (scheduleParameters[0] === "TM" || scheduleParameters[0] === "TB") ? (
          <>
            <VehicleTasks tasks={tasks} isElectro />
            <hr
              style={{
                height: 1,
                padding: 0,
                margin: "1px 5px 4px 0px",
                border: "none",
                color: alpha(grey[900], 0.5),
                backgroundColor: alpha(grey[900], 0.5),
              }}
            />
            <SecondVehicle vehicle={vehicle} />
            <VehiclePlaces vehicle={vehicle} />
          </>
        ) : (
          <VehicleTasks tasks={tasks} />
        )}

        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={contextMenu || undefined}
        >
          <MenuItem disabled>Редакция на превозно средство</MenuItem>
          <Divider />

          {vehicleState && vehicleState.category !== 2
            ? [
                otherAutocolumns.length === 1
                  ? [
                      <MenuItem
                        key="change-autocolumn"
                        disabled={isPTDue}
                        onClick={() =>
                          changeAutocolumnCallback(otherAutocolumns[0])
                        }
                      >
                        <ListItemIcon>
                          <DirectionsBus fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          Прехвърли в другата автоколона
                        </ListItemText>
                      </MenuItem>,
                      <Divider key="divider" />,
                    ]
                  : otherAutocolumns.length > 1
                  ? otherAutocolumns
                      .map((autocolumn) => (
                        <MenuItem
                          key={`change-autocolumn-${autocolumn}`}
                          disabled={isPTDue}
                          onClick={() => changeAutocolumnCallback(autocolumn)}
                        >
                          <ListItemIcon>
                            <DirectionsBus fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            {`Прехвърли в автоколона ${autocolumn}`}
                          </ListItemText>
                        </MenuItem>
                      ))
                      .concat([<Divider key="divider" />])
                  : [],
                <MenuItem
                  key="print-daytasks"
                  disabled={isPTDue}
                  onClick={() => {
                    setContextMenu(null);
                    exportJourneyLists.exportPdf(
                      [vehicle],
                      drivers,
                      [],
                      //apiVehicles,
                      roster.allShifts,
                      selectedDate,
                      true
                    );
                  }}
                >
                  <ListItemIcon>
                    <Print fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Експорт на пътен лист в PDF</ListItemText>
                </MenuItem>,
              ].flat()
            : undefined}

          {vehicleState && vehicleState.id > 0 ? (
            <MenuItem
              disabled={isPTDue}
              onClick={() => {
                setContextMenu(null);
                clearVehicleState(vehicle);
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Изчисти състояние</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              disabled={isPTDue}
              onClick={() => {
                setContextMenu(null);
                showVehicleStateDialog(vehicle);
              }}
            >
              <ListItemIcon>
                <Build fontSize="small" />
              </ListItemIcon>
              <ListItemText>Задай състояние</ListItemText>
            </MenuItem>
          )}
          {scheduleParameters && scheduleParameters[0] === "TM" ? (
            <MenuItem
              disabled={isPTDue}
              onClick={() => {
                setContextMenu(null);
                if (vehicle?.composition) {
                  // showCompositionDialog(vehicle);

                  changeComposition({
                    transport: scheduleParameters[0],
                    depot: scheduleParameters[1],
                    veh1_id: vehicle.vehicle_id,
                    veh2_id: null,
                    start_date: selectedDate,
                    end_date: null,
                  });
                } else {
                  showCompositionDialog(vehicle);
                }
              }}
            >
              <ListItemIcon>
                <RvHookup fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {vehicle?.composition
                  ? "Разкачи композиция"
                  : "Създай композиция"}
              </ListItemText>
            </MenuItem>
          ) : undefined}
          <MenuItem
            onClick={() => {
              setContextMenu(null);
              showVehicleCommentDialog(vehicle);
            }}
          >
            <ListItemIcon>
              <Comment fontSize="small" />
            </ListItemIcon>
            <ListItemText>Коментар</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>

      {vehicle.vehicle.days[selectedDate]?.state?.category === 2 &&
      vehicle.shifts[0].length === 0 &&
      vehicle.shifts[1].length === 0 ? (
        <Grid
          container
          xs={14}
          sx={{
            border: `1px solid ${alpha(grey[900], 0.75)}`,
            borderRadius: 0.5,
            alignItems: "baseline",
            px: 1.75,
            py: 0,
            //height: "100%",
            //display: "block",
            lineHeight: 1,
          }}
        >
          {vehicle.vehicle.days[selectedDate]?.state?.id &&
          (vehicle.vehicle.days[selectedDate]?.state?.id as number) > 0 ? (
            <Typography fontSize={11}>
              <strong>
                {vehicle.vehicle.days[selectedDate]?.state?.description || "?"}
              </strong>
              {vehicle.vehicle.days[selectedDate]?.state?.comment ? (
                <>
                  :&nbsp;
                  <span>
                    {vehicle.vehicle.days[selectedDate].state?.comment}
                  </span>
                </>
              ) : (
                <></>
              )}
            </Typography>
          ) : vehicle.vehicle.days[selectedDate]?.state?.expired_docs ? (
            <Typography fontSize={11}>
              <strong>Изтекли документи</strong>
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <>
          <VehicleItemElementShift
            isReadOnly={isReadOnly}
            isShiftActive={isShift1Active}
            shiftRef={shift1Ref}
            shift={vehicle.shifts[0][driverIdx]}
            vehicle={vehicle}
            roster={roster}
            selectedDate={selectedDate}
            saveComment={saveDriverComment}
            saveShift={saveShift}
            openChangeVehicleDialog={openChangeVehicleDialog}
          />
          <VehicleItemElementShift
            isReadOnly={isReadOnly}
            isShiftActive={isShift2Active}
            shiftRef={shift2Ref}
            shift={vehicle.shifts[1][driverIdx]}
            vehicle={vehicle}
            roster={roster}
            selectedDate={selectedDate}
            saveComment={saveDriverComment}
            saveShift={saveShift}
            openChangeVehicleDialog={openChangeVehicleDialog}
          />
        </>
      )}
    </GridContainer>
  );
}

export function VehicleItem({
  isReadOnly,
  vehicle,
  roster,
  selectedDate,
  drivers,
  apiVehicles,
  onDrop,
  saveShift,
  saveDriverComment,
  showVehicleCommentDialog,
  showVehicleStateDialog,
  showCompositionDialog,
  clearVehicleState,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  vehicle: RosterVehicle;
  roster: Roster;
  selectedDate: string;
  drivers: readonly ScheduleDriver[];
  apiVehicles: readonly Vehicle[];
  onDrop: (
    item: RosterDriver | RosterShift,
    over: RosterVehicle,
    shiftNumber: 1 | 2 | null,
    driverIdx: number
  ) => void;
  saveShift: (data: AddShiftToVehicleData) => Promise<{ status: "ok" }>;
  saveDriverComment: (data: {
    driver_id: string;
    shift_type: 1 | 2;
    date: string;
    comment: string | null;
  }) => Promise<{ status: "ok" }>;
  showVehicleCommentDialog: (vehicle: RosterVehicle) => void;
  showVehicleStateDialog: (vehicle: RosterVehicle) => void;
  showCompositionDialog: (vehicle: RosterVehicle) => void;
  clearVehicleState: (vehicle: RosterVehicle) => void;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  const maxDriversCount = Math.max(
    1,
    Math.max(vehicle.shifts[0].length, vehicle.shifts[1].length)
  );
  const { scheduleParameters } = useScheduleParameters();

  return (
    //<VehicleContainer>
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        margin: 0,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: 12,
        fontWeight: "bold",
        //cursor: "pointer",
        //width: "25%",
        minWidth:
          scheduleParameters &&
          (scheduleParameters[0] === "TM" || scheduleParameters[0] === "TB")
            ? 362
            : 320,
      }}
    >
      {[...new Array(maxDriversCount)].map((_, idx) => (
        <VehicleItemElement
          key={idx}
          isReadOnly={isReadOnly}
          driverIdx={idx}
          vehicle={vehicle}
          roster={roster}
          selectedDate={selectedDate}
          drivers={drivers}
          apiVehicles={apiVehicles}
          onDrop={onDrop}
          saveShift={saveShift}
          saveDriverComment={saveDriverComment}
          showVehicleCommentDialog={showVehicleCommentDialog}
          showVehicleStateDialog={showVehicleStateDialog}
          showCompositionDialog={showCompositionDialog}
          clearVehicleState={clearVehicleState}
          openChangeVehicleDialog={openChangeVehicleDialog}
        />
      ))}
    </div>
    //</VehicleContainer>
  );
}
