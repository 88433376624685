import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "../common/useProfile";
import { useGetDepotListQuery } from "../data/api/hooks";
import { updateFilterDepots, updateSelectedDepot } from "../data/filtersSlice";
import { RootState, useAppDispatch } from "../data/store";

export function useDepotFilterEvents() {
  const dispatch = useAppDispatch();
  const profile = useProfile();

  //console.log("Depot filters", profile);
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const depotIds = useMemo<number[]>(
    () =>
      profile?.garages
        ? Object.entries(profile.garages)
            .filter((ds) => ds[0] === selectedTransport)
            .flatMap((ds) => ds[1])
        : [],
    [profile?.garages, selectedTransport]
  );
  const depotNames = useGetDepotListQuery(selectedTransport, {
    skip: selectedTransport === "" || !profile,
  });

  const depots = useMemo(
    () =>
      depotNames.data
        ? depotNames.data.filter(
            (dn) =>
              dn.transport === selectedTransport &&
              depotIds.includes(dn.gps_depot_id)
          )
        : [],
    [depotIds, depotNames.data, selectedTransport]
  );

  useEffect(() => {
    if (depots.length > 0) {
      //console.log("Depots:", depots);
      dispatch(
        updateFilterDepots(
          depots.map((depot) => {
            const autocolumns = profile?.transports?.[depot.transport]?.[
              depot.gps_depot_id
            ] || [1];

            return {
              id: depot.gps_depot_id,
              value: depot.name,
              autocolumns: autocolumns.length > 0 ? autocolumns : [1],
            };
          })
        )
      );
    }
  }, [depots, dispatch, profile?.transports]);
}

export function useDepotFilter() {
  const dispatch = useAppDispatch();

  const depots = useSelector((state: RootState) => state.filters.depots);
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const setSelectedDepot = useCallback(
    (depot: number) => {
      dispatch(updateSelectedDepot(depot));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedDepot === -1 && depots.length > 0) {
      dispatch(updateSelectedDepot(depots[0].id));
    }
  }, [depots, dispatch, selectedDepot]);

  return {
    depots,
    selectedDepot,
    setSelectedDepot,
  };
}
