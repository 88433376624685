import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  useGetVehicleTypeListQuery,
  useLazyGetVehicleTypeListQuery,
} from "../../data/api/hooks";
import { Schedule, sortArray } from "../../data/api/types/schedule";
import { RootState } from "../../data/store";

type VehicleTypeType = Awaited<
  ReturnType<
    ReturnType<ReturnType<typeof useLazyGetVehicleTypeListQuery>[0]>["unwrap"]
  >
>[0];

export function useLazyVehicleTypesList() {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const [triggerGetVehicleTypesList] = useLazyGetVehicleTypeListQuery();

  const getVehicleTypesList = useCallback<
    (
      schedule: Schedule,
      filter?: boolean
    ) => Promise<Record<number, VehicleTypeType>>
  >(
    async (schedule: Schedule, filter = true) => {
      const vehicleTypesRecord = await triggerGetVehicleTypesList(
        selectedTransport
      ).unwrap();

      if (filter) {
        const vehicleTypes = Object.values(vehicleTypesRecord || {});

        const driverVehicleTypes: Record<number, boolean> = {};
        if (schedule?.drivers) {
          for (const driver of Object.values(schedule.drivers)) {
            if (
              driver.attributes?.preferred_vehicle_type !== null &&
              driver.attributes?.preferred_vehicle_type !== undefined
            ) {
              driverVehicleTypes[driver.attributes.preferred_vehicle_type] =
                true;
            }
          }
        }

        const explPlanVehicleTypes: Record<number, boolean> = {};
        if (schedule?.expl_plan_counts_by_vt) {
          for (const day of schedule.expl_plan_counts_by_vt) {
            for (const vt of day) {
              if (
                vt.vehicletype_id !== null &&
                vt.vehicletype_id !== undefined
              ) {
                explPlanVehicleTypes[vt.vehicletype_id] = true;
              }
            }
          }
        }

        return Object.fromEntries(
          vehicleTypes
            .filter(
              (vt) =>
                vt.id === null ||
                vt.id === undefined ||
                explPlanVehicleTypes[vt.id] === true ||
                driverVehicleTypes[vt.id] === true
            )
            .map((vt) => [vt.id, vt])
        );
      } else {
        return vehicleTypesRecord;
      }
    },
    [selectedTransport, triggerGetVehicleTypesList]
  );

  return {
    getVehicleTypesList,
  };
}

export function useVehicleTypesList(schedule: Schedule | undefined) {
  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const {
    currentData: vehicleTypesRecord,
    isLoading: vehicleTypesLoading,
    isError: vehicleTypesError,
  } = useGetVehicleTypeListQuery(selectedTransport, {
    skip: selectedTransport === "",
  });

  const vehicleTypes = useMemo(() => {
    const vehicleTypes = sortArray(
      Object.values(vehicleTypesRecord || {}),
      "description",
      {
        in_place: true,
      }
    );

    const driverVehicleTypes: Record<number, boolean> = {};
    if (schedule?.drivers) {
      for (const driver of Object.values(schedule.drivers)) {
        if (
          driver.attributes?.preferred_vehicle_type !== null &&
          driver.attributes?.preferred_vehicle_type !== undefined
        ) {
          driverVehicleTypes[driver.attributes.preferred_vehicle_type] = true;
        }
      }
    }

    const explPlanVehicleTypes: Record<number, boolean> = {};
    if (schedule?.expl_plan_counts_by_vt) {
      for (const day of schedule.expl_plan_counts_by_vt) {
        for (const vt of day) {
          if (vt.vehicletype_id !== null && vt.vehicletype_id !== undefined) {
            explPlanVehicleTypes[vt.vehicletype_id] = true;
          }
        }
      }
    }

    //console.log("Loaded vehicle types:", vehicleTypes);
    //console.log("Driver vehicle types:", driverVehicleTypes);
    //console.log("Expl plan vehicle types:", driverVehicleTypes);

    return vehicleTypes.filter(
      (vt) =>
        vt.id === null ||
        vt.id === undefined ||
        explPlanVehicleTypes[vt.id] === true ||
        driverVehicleTypes[vt.id] === true
    );
  }, [schedule?.drivers, schedule?.expl_plan_counts_by_vt, vehicleTypesRecord]);

  return {
    vehicleTypesLoading,
    vehicleTypesError,
    vehicleTypes,
    allVehicleTypes: sortArray(
      Object.values(vehicleTypesRecord || {}),
      "description",
      { in_place: true }
    ),
  };
}
