import { objectTraps } from "immer/dist/internal";
import {
  DayTask,
  ScheduleDay,
  Transport,
} from "../../../data/api/types/schedule";
import {
  DaySpecShiftRoster,
  SHIFT2_SECONDS,
  WorkShiftRoster,
} from "../useTimetables_ByDate";
import moment from "moment";

interface RosterDayReportColumn {
  id:
    | "linenum"
    | "car_no"
    | "vehicle1"
    | "driver1"
    | "car_no2"
    | "vehicle2"
    | "driver2"
    | "comment1"
    | "comment2";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  width: number;
}

export const DayRosterColumns: readonly RosterDayReportColumn[] = [
  { id: "linenum", label: "Линия", minWidth: 40, width: 60, align: "center" },

  {
    id: "car_no",
    label: "Кола",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "vehicle1",
    label: "ПС 1 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "driver1",
    label: "Водач 1 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "comment1",
    label: "Забележка",
    minWidth: 80,
    align: "left",
    width: 80,
  },

  {
    id: "vehicle2",
    label: "ПС 2 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "driver2",
    label: "Водач 2 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "comment2",
    label: "Забележка",
    minWidth: 80,
    align: "left",
    width: 80,
  },
];

export const DayRosterElectroColumns: readonly RosterDayReportColumn[] = [
  { id: "linenum", label: "Линия", minWidth: 40, width: 60, align: "center" },

  {
    id: "car_no",
    label: "Кола",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "vehicle1",
    label: "ПС 1 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "driver1",
    label: "Водач 1 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "comment1",
    label: "Забележка",
    minWidth: 80,
    align: "left",
    width: 80,
  },

  {
    id: "vehicle2",
    label: "ПС 2 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "driver2",
    label: "Водач 2 смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "car_no2",
    label: "Кола",
    minWidth: 30,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 30,
  },
  {
    id: "comment2",
    label: "Забележка",
    minWidth: 80,
    align: "left",
    width: 80,
  },
];

export interface DayRosterData {
  line_name: string;
  car_no: number;
  vehicle1: number;
  vehicle12: number;
  driver1: number;
  vehicle2: number;
  vehicle22: number;
  driver2: number;
  comment1: string;
  comment2: string;
  show: boolean;

  line_id: number | null;
  tt_code: string | null;
  start_time: number | null;
  end_time: number | null;
  start_time2: number | null;
  end_time2: number | null;
  special_shift: boolean;
}

export function createDayRosterData(
  line_name: string,
  car_no: number,
  vehicle1: number,
  vehicle12: number,
  driver1: number,
  vehicle2: number,
  vehicle22: number,
  driver2: number,
  comment1: string,
  comment2: string,
  show: boolean,
  line_id: number | null,
  tt_code: string | null,
  start_time: number | null,
  end_time: number | null,
  start_time2: number | null,
  end_time2: number | null,
  special_shift: boolean
): DayRosterData {
  return {
    line_name,
    car_no,
    vehicle1,
    vehicle12,
    driver1,
    vehicle2,
    vehicle22,
    driver2,
    comment1,
    comment2,
    show,
    line_id,
    tt_code,
    start_time,
    end_time,
    start_time2,
    end_time2,
    special_shift,
  };
}

function getVehicleComment(
  veh_id: number,
  vehicleRows:
    | {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
        }[];
      }
    | undefined,
  vehicles_state:
    | {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[]
    | undefined,
  day_tasks: DayTask[] | undefined
) {
  let comment = "";
  //добавяне на коментар за други задачи
  if (day_tasks) {
    comment += day_tasks.map(
      (d) =>
        `${d.linename}${d.car_no ? "/" : ""} ${
          d.car_no ? d.car_no : ""
        } от ${moment(d.start_time).format("HH:mm")} - ${moment(
          d.end_time
        ).format("HH:mm")}`
    );
  }

  if (comment.length > 0) comment += "\n";
  if (veh_id > -1 && vehicleRows && vehicles_state) {
    for (const veh_state of Object.keys(vehicleRows)) {
      //if (veh_state > "0")
      {
        const veh = vehicleRows[veh_state].find((v) => v.veh_id === veh_id);
        if (veh)
          return (
            comment +
            (veh_state > "0" &&
            vehicles_state.find((state) => state.id === parseInt(veh_state, 10))
              ? vehicles_state.find(
                  (state) => state.id === parseInt(veh_state, 10)
                )?.description
              : "") +
            (veh_state > "0" && veh.state_comment.length > 0
              ? ":" + veh.state_comment
              : "") +
            (veh.veh_comment.length > 0 ? "\n" + veh.veh_comment : "")
          );
        //   +
        // veh.comment !==
        // ""
        // ? "\n" + veh.comment
        // : "";
      }
    }
  }
  return comment;
}

export function GetDriverNastavnik(
  driver_id: number | null | undefined,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
) {
  //търсим наставник за driver_id от подаден график za den

  if (!driver_id && driver_id !== 0) {
    return null;
  } else {
    const find_nastavnik = day_roster.find((d) => {
      const drv_day = Object.values(d)[0];
      return (
        drv_day?.scheduleDay?.state === 37 &&
        drv_day?.scheduleDay?.comment?.trim() === driver_id.toString()
      );
    });
    if (find_nastavnik) {
      return Object.values(find_nastavnik)[0]?.driver_id || null;
    } else {
      return null;
    }
  }
}

export function getRosterCarData(
  day_workshift_ids: string[] | undefined,
  workshifts: WorkShiftRoster[],
  //roster: MessageSchedule,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
    | undefined,
  roster_date: string,
  vehicleRows:
    | {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
        }[];
      }
    | undefined,
  vehicles_state:
    | {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[]
    | undefined,
  selectedAvtoColumn: number,
  selectedTransport: string
): {
  vehicle1: number;
  driver1: number;
  vehicle2: number;
  driver2: number;
  vehicle3: number;
  driver3: number;
  comment1: string;
  comment2: string;
  comment3: string;
  start1: number | null;
  start2: number | null;
  start3: number | null;
  end1: number | null;
  end2: number | null;
  end3: number | null;
  shiftWithoutDriverCnts: number;
  vehicle12: number;
  vehicle22: number;
  vehicle32: number;
} {
  let vehicle1_id = -1;
  let vehicle12_id = -1;
  let vehicle2_id = -1;
  let vehicle22_id = -1;
  let vehicle3_id = -1;
  let vehicle32_id = -1;
  let driver1_id = -1;
  let driver2_id = -1;
  let driver3_id = -1;
  let comment1 = "";
  let comment2 = "";
  let comment3 = "";
  let shiftWithoutDriverCnts = 0;
  let start1 = null;
  let start2 = null;
  let start3 = null;
  let end1 = null;
  let end2 = null;
  let end3 = null;
  let shift_cnt = 0;

  if (day_roster) {
    // console.log("workshifts: ", workshifts);
    shift_cnt = 0;
    for (const wrk of workshifts) {
      shift_cnt++;

      if (workshifts.length > 2) {
        console.log("shift: ", wrk, " shift_cnt: ", shift_cnt);
      }

      let find_driver = false;
      for (const driver of day_roster) {
        const drv_day = Object.values(driver).map((d) => d)[0];

        // const driver_day = driver.driver_days.find(
        //   (dd) => dd.day_in_month === roster_date
        // );
        const find_data = drv_day.scheduleDay?.daytasks?.find(
          (d) => d.workshift_id === wrk.id
        );

        if (find_data) {
          find_driver = true;
          //търсене на друга задача, като персонална например или комбинирани смени, за да ги покаже в коментара
          const second_data = drv_day.scheduleDay?.daytasks?.filter(
            (d) =>
              d.shift_id &&
              d.workshift_id !== wrk.id &&
              day_workshift_ids?.includes(d.shift_id)
          );

          if (wrk.shift_type === 1) {
            driver1_id = drv_day.driver_id;
            vehicle1_id = find_data.veh1_id ? find_data.veh1_id : -1;
            vehicle12_id = find_data.veh2_id ? find_data.veh2_id : -1;

            start1 = wrk.start_time;
            end1 = wrk.end_time;

            find_data.comment ? (comment1 = find_data.comment) : "";
            comment1 += getVehicleComment(
              vehicle1_id,
              vehicleRows,
              vehicles_state,
              second_data
            );
            //търсим наставник
            const nastavnik_id = GetDriverNastavnik(driver1_id, day_roster);
            // const find_nastavnik = day_roster.filter((d) => {
            //   const drv_day = Object.values(d).map((d) => d)[0];
            //   if (
            //     drv_day.scheduleDay.state === 37 &&
            //     drv_day.scheduleDay.comment === driver1_id.toString()
            //   )
            //     return true;
            //   else return false;
            // });
            //if (find_nastavnik.length > 0)
            if (nastavnik_id !== null)
              comment1 += "Наставник:  " + nastavnik_id;
          } else {
            if (wrk.shift_type === 2) {
              driver2_id = drv_day.driver_id;
              vehicle2_id = find_data.veh1_id ? find_data.veh1_id : -1;
              vehicle22_id = find_data.veh2_id ? find_data.veh2_id : -1;
              start2 = wrk.start_time;
              end2 = wrk.end_time;
              find_data.comment ? (comment2 = find_data.comment) : "";
              if (
                selectedTransport !== "A" &&
                wrk.passenger_start !== wrk.start_time
              )
                comment2 += "От гараж ";
              comment2 += getVehicleComment(
                vehicle2_id,
                vehicleRows,
                vehicles_state,
                second_data
              );
            }

            //търсим наставник
            // const find_nastavnik = day_roster.filter((d) => {
            //   const drv_day = Object.values(d).map((d) => d)[0];
            //   if (
            //     drv_day.scheduleDay.state === 37 &&
            //     drv_day.scheduleDay.comment === driver2_id.toString()
            //   )
            //     return true;
            //   else return false;
            // });
            // if (find_nastavnik.length > 0)
            const nastavnik_id = GetDriverNastavnik(driver2_id, day_roster);
            if (nastavnik_id !== null)
              comment2 += "Наставник:  " + nastavnik_id;

            if (shift_cnt === 3) {
              driver3_id = drv_day.driver_id;
              vehicle3_id = find_data.veh1_id ? find_data.veh1_id : -1;
              vehicle32_id = find_data.veh2_id ? find_data.veh2_id : -1;
              start3 = wrk.start_time;
              end3 = wrk.end_time;

              comment3 += getShiftWorkTimeComment(
                wrk,
                find_data.comment,
                getVehicleComment(
                  vehicle3_id,
                  vehicleRows,
                  vehicles_state,
                  second_data
                )
              );
              if (
                selectedTransport !== "A" &&
                wrk.passenger_start !== wrk.start_time
              )
                comment3 += "От гараж ";
            }
          }
        }
      }
      if (wrk.autocolumn === selectedAvtoColumn && !find_driver) {
        //console.log("Непокрита смяна", wrk);
        shiftWithoutDriverCnts += 1;
      }
    }
  }
  return {
    vehicle1: vehicle1_id,
    vehicle12: vehicle12_id,
    driver1: driver1_id,
    vehicle2: vehicle2_id,
    vehicle22: vehicle22_id,
    driver2: driver2_id,
    vehicle3: vehicle3_id,
    vehicle32: vehicle32_id,
    driver3: driver3_id,
    comment1: comment1,
    comment2: comment2,
    comment3: comment3,
    start1: start1,
    start2: start2,
    start3: start3,
    end1: end1,
    end2: end2,
    end3: end3,
    shiftWithoutDriverCnts: shiftWithoutDriverCnts,
  };
}

function getSpecShiftComment(
  workshifts: DaySpecShiftRoster,
  shift_comment: string | null,
  vehicle_comment: string | undefined
) {
  let comment =
    moment
      .utc(moment.duration(workshifts.start_time, "seconds").as("milliseconds"))
      .format("HH:mm") +
    " - " +
    moment
      .utc(moment.duration(workshifts.end_time, "seconds").as("milliseconds"))
      .format("HH:mm");

  shift_comment ? (comment += "\n" + shift_comment) : "";
  vehicle_comment ? (comment += "\n" + vehicle_comment) : "";

  return comment;
}

function getShiftWorkTimeComment(
  workshifts: WorkShiftRoster,
  shift_comment: string | null,
  vehicle_comment: string | undefined
) {
  let comment =
    moment
      .utc(moment.duration(workshifts.start_time, "seconds").as("milliseconds"))
      .format("HH:mm") +
    " - " +
    moment
      .utc(moment.duration(workshifts.end_time, "seconds").as("milliseconds"))
      .format("HH:mm");

  shift_comment ? (comment += "\n" + shift_comment) : "";
  vehicle_comment ? (comment += "\n" + vehicle_comment) : "";

  return comment;
}

function getSecondShiftSpecShift(
  //roster: MessageSchedule,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
    | undefined,
  roster_date: string,
  vehicle_id: number | null,
  workshifts: DaySpecShiftRoster,
  spec_shifts: DaySpecShiftRoster[]
) {
  if (vehicle_id && day_roster)
    for (const driver of day_roster) {
      // const driver_day = driver.driver_days.find(
      //   (dd) => dd.day_in_month === roster_date
      // );
      const drv_day = Object.values(driver).map((d) => d)[0];

      // const driver_day = driver.driver_days.find(
      //   (dd) => dd.day_in_month === roster_date
      // );
      // const find_data = drv_day.scheduleDay?.daytasks?.find(
      //   (d) => d.workshift_id === wrk.id
      // );

      const find_data = drv_day?.scheduleDay.daytasks?.find(
        (d) =>
          d.special_shift_id &&
          d.veh1_id === vehicle_id &&
          (moment(d.start_time).hours() * 3600 +
            moment(d.start_time).minutes() * 60 ===
            workshifts.end_time ||
            moment(d.end_time).hours() * 3600 +
              moment(d.end_time).minutes() * 60 ===
              workshifts.start_time)
      );

      if (find_data) {
        const spec = spec_shifts.find(
          (s) =>
            s.id === find_data.special_shift_id &&
            !s.description.toUpperCase().trim().startsWith("П")
        );
        if (spec) {
          return {
            driver: drv_day.driver_id,
            find_data: find_data,
            spec: spec,
          };
        }
      }
    }
  return {
    driver: null,
    find_data: null,
    spec: null,
  };
}

export function getRosterSpecShiftData(
  day_workshift_ids: string[] | undefined,
  workshifts: DaySpecShiftRoster,
  //roster: MessageSchedule,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
    | undefined,
  roster_date: string,
  vehicleRows:
    | {
        [key: string]: {
          veh_id: number;
          veh_comment: string;
          state_comment: string;
        }[];
      }
    | undefined,
  vehicles_state:
    | {
        id: number;
        transport: Transport;
        description: string;
        desc_short: string;
        site_visible: boolean;
        category_id: number;
      }[]
    | undefined,
  spec_shifts: DaySpecShiftRoster[]
): {
  vehicle1: number;
  driver1: number;
  vehicle2: number;
  driver2: number;
  comment1: string;
  comment2: string;
  shiftWithoutDriverCnts: number;
  vehicle12: number;
  vehicle22: number;
} {
  let vehicle1_id = -1;
  let vehicle12_id = -1;
  let vehicle2_id = -1;
  let vehicle22_id = -1;
  let driver1_id = -1;
  let driver2_id = -1;
  let comment1 = "";
  let comment2 = "";
  let shiftWithoutDriverCnts = 0;
  if (day_roster) {
    let find_driver = false;
    for (const driver of day_roster) {
      // const driver_day = driver.driver_days.find(
      //   (dd) => dd.day_in_month === roster_date
      // );

      const drv_day = Object.values(driver).map((d) => d)[0];

      const find_data = drv_day?.scheduleDay.daytasks?.find(
        (d) => d.special_shift_id === workshifts.id
      );

      if (find_data) {
        find_driver = true;

        //търсене на друга задача, като персонална например или комбинирани смени, за да ги покаже в коментара
        const second_data = drv_day.scheduleDay?.daytasks?.filter(
          (d) =>
            d.shift_id &&
            d.special_shift_id !== workshifts.id &&
            day_workshift_ids?.includes(d.shift_id)
        );

        if (workshifts.start_time < SHIFT2_SECONDS) {
          driver1_id = drv_day.driver_id;
          vehicle1_id = find_data.veh1_id ? find_data.veh1_id : -1;
          vehicle12_id = find_data.veh2_id ? find_data.veh2_id : -1;

          comment1 = getSpecShiftComment(
            workshifts,
            find_data.comment,
            getVehicleComment(
              vehicle1_id,
              vehicleRows,
              vehicles_state,
              second_data
            )
          );

          //търсим за същия автобус задача по специална смяна втора смяна, за да се запишат на един ред - > ако не е персонална  и началото и края на двете смени съвпадат и е със същия автобус
          const {
            driver: driver2,
            find_data: find_data2,
            spec: spec2,
          } = getSecondShiftSpecShift(
            day_roster,
            roster_date,
            vehicle1_id,
            workshifts,
            spec_shifts
          );

          if (spec2) {
            driver2_id = driver2;
            vehicle2_id = find_data2.veh1_id ? find_data2.veh1_id : -1;
            vehicle22_id = find_data2.veh2_id ? find_data2.veh2_id : -1;
            comment2 = getSpecShiftComment(
              spec2,
              find_data2.comment,
              getVehicleComment(
                vehicle2_id,
                vehicleRows,
                vehicles_state,
                second_data
              )
            );
          }
        } else {
          driver2_id = drv_day.driver_id;
          vehicle2_id = find_data.veh1_id ? find_data.veh1_id : -1;
          comment2 = getSpecShiftComment(
            workshifts,
            find_data.comment,
            getVehicleComment(
              vehicle2_id,
              vehicleRows,
              vehicles_state,
              second_data
            )
          );

          const { spec: spec2 } = getSecondShiftSpecShift(
            day_roster,
            roster_date,
            find_data.veh1_id,
            workshifts,
            spec_shifts
          );
          if (spec2) driver2_id = driver2_id * -1; //т.е. да не се показва реда със смяната изобщо, защото е обединен
        }
      }
    }
    if (
      !find_driver &&
      !workshifts.description.toUpperCase().includes("ЗАЯВКА")
    )
      shiftWithoutDriverCnts += 1;
  }
  return {
    vehicle1: vehicle1_id,
    vehicle12: vehicle12_id,
    driver1: driver1_id,
    vehicle2: vehicle2_id,
    vehicle22: vehicle22_id,
    driver2: driver2_id,
    comment1: comment1,
    comment2: comment2,
    shiftWithoutDriverCnts: shiftWithoutDriverCnts,
  };
}
