import { alpha, Box, styled, Typography } from "@mui/material";
import { green, grey, orange, yellow } from "@mui/material/colors";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { GridProps } from "@mui/system/Unstable_Grid/GridProps";
import moment from "moment";
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Vehicle } from "../../../data/api/types/driver";
import { ScheduleDriver } from "../../../data/api/types/schedule";
import { ScheduleVehicleDay } from "../../../data/api/types/vehiclesSchedule";
import { ElectroRosterVehicle } from "../types/ElectroRoster";
import { Roster } from "../types/Roster";
import {
  createVehicleTitle,
  getVehicleTasks,
  RosterVehicleTask,
  SecondVehicle,
  VehiclePlaces,
} from "../VehicleItem";
import { VehicleItemMenu } from "./VehicleItemMenu";

export type ElectroVehicleItemProps = {
  assigned?: boolean;
  wide?: boolean;
  isReadOnly: boolean;
  isVehiclesOnly: boolean;
  drivers: readonly ScheduleDriver[];
  vehicle: ElectroRosterVehicle;
  selectedDate: string;
  apiVehicles: readonly Vehicle[];
  showVehicleCommentDialog: (vehicle: ElectroRosterVehicle) => void;
  showVehicleStateDialog: (vehicle: ElectroRosterVehicle) => void;
  showCompositionDialog: (vehicle: ElectroRosterVehicle) => void;
  clearVehicleState: (vehicle: ElectroRosterVehicle) => void;
  style?: CSSProperties;
  setErrors: (errors: readonly string[]) => void;
  getRoster: () => Roster;
};

const GridContainer = styled(
  forwardRef((props: GridProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Grid {...props} container columns={16} ref={ref} />
  )),
  { shouldForwardProp: (prop) => prop !== "container" && prop !== "columns" }
)(({ theme }) => ({
  width: "100%",
  marginLeft: 1,
  marginRight: 1,
  border: "1px solid black",
  borderRadius: 3,
  "&:first-of-type": {
    marginTop: 1,
  },
  "&:not(:last-of-type)": {
    "&:not(:only-of-type)": {
      borderBottomColor: grey[400],
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  "&:last-of-type": {
    marginBottom: 1,
  },
  "&:not(:first-of-type)": {
    "&:not(:only-of-type)": {
      borderTop: "none",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
  alignContent: "center",
  padding: theme.spacing(0.5, 1.5),
  minHeight: 45,
}));

function CommentIndicator({ active }: { active?: boolean }) {
  if (active) {
    return (
      <div
        style={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "13px 13px 0 0",
          borderColor: `${green[600]} transparent transparent transparent`,
          borderRadius: 2,
          position: "absolute",
          left: 0,
          top: 0,
          pointerEvents: "none",
        }}
      />
    );
  } else {
    return <></>;
  }
}

export function ElectroVehicleTasks({
  wide,
  tasks,
}: {
  wide?: boolean;
  tasks: {
    daytasks: {
      linename: string;
      car_no: number | null;
      start_time: string;
      end_time: string;
      shifts: (1 | 2)[];
    }[];
    date: string;
  }[];
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {tasks[0]?.daytasks?.map((dt, dtIdx, dts) => (
        <Typography
          key={dtIdx}
          fontSize={10}
          lineHeight={1}
          color={alpha(grey[900], 0.75)}
          fontWeight="bold"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${dt.linename}${dt.car_no ? `/${dt.car_no}` : ""}`}
          {dts.length === 1 &&
          dt.shifts.includes(1) &&
          dt.shifts.includes(2) ? (
            <span style={wide ? { paddingLeft: 4 } : { display: "block" }}>
              I/II см.
            </span>
          ) : dts.length === 1 && dt.shifts.includes(2) ? (
            <span style={wide ? { paddingLeft: 4 } : { display: "block" }}>
              II см.
            </span>
          ) : dts.length === 1 && dt.shifts.includes(1) ? (
            <span style={wide ? { paddingLeft: 4 } : { display: "block" }}>
              I см.
            </span>
          ) : (
            <></>
          )}
        </Typography>
      ))}
    </Box>
  );
}

type ContextMenuPosition = {
  top: number;
  left: number;
};

function WideVehicleItemElement({
  isReadOnly,
  isVehiclesOnly,
  handleContextMenu,
  drivers,
  vehicle,
  tasks,
  selectedDate,
  vehicleState,
  contextMenu,
  clearVehicleState,
  showVehicleStateDialog,
  showVehicleCommentDialog,
  showCompositionDialog,
  setContextMenu,
  setErrors,
  getRoster,
}: ElectroVehicleItemProps & {
  vehicleState: ScheduleVehicleDay["state"];
  contextMenu: ContextMenuPosition | null;
  setContextMenu: (value: ContextMenuPosition | null) => void;
  handleContextMenu: (event: MouseEvent) => void;
  isDisabled: boolean;
  tasks: RosterVehicleTask[];
  expireDateComment: string | null;
  setErrors: (errors: readonly string[]) => void;
}) {
  return (
    <Grid
      xs={16}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onContextMenu={
        !isReadOnly || isVehiclesOnly ? handleContextMenu : undefined
      }
    >
      <Box>
        <Typography fontWeight="bold" fontSize={12} lineHeight={1.4}>
          {vehicle.vehicle_id}
        </Typography>
        <SecondVehicle vehicle={vehicle} />
      </Box>

      <ElectroVehicleTasks tasks={tasks} wide />

      <VehiclePlaces vehicle={vehicle} />

      <VehicleItemMenu
        isReadOnly={isReadOnly}
        isVehiclesOnly={isVehiclesOnly}
        selectedDate={selectedDate}
        drivers={drivers}
        vehicle={vehicle}
        vehicleState={vehicleState}
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        clearVehicleState={clearVehicleState}
        showVehicleStateDialog={showVehicleStateDialog}
        showVehicleCommentDialog={showVehicleCommentDialog}
        showCompositionDialog={showCompositionDialog}
        setErrors={setErrors}
        getRoster={getRoster}
      />
    </Grid>
  );
}

function RegularVehicleItemElement({
  isReadOnly,
  isVehiclesOnly,
  handleContextMenu,
  drivers,
  vehicle,
  tasks,
  selectedDate,
  vehicleState,
  contextMenu,
  clearVehicleState,
  showVehicleStateDialog,
  showVehicleCommentDialog,
  showCompositionDialog,
  setContextMenu,
  setErrors,
  getRoster,
}: ElectroVehicleItemProps & {
  vehicleState: ScheduleVehicleDay["state"];
  contextMenu: ContextMenuPosition | null;
  setContextMenu: (value: ContextMenuPosition | null) => void;
  handleContextMenu: (event: MouseEvent) => void;
  isDisabled: boolean;
  tasks: RosterVehicleTask[];
  expireDateComment: string | null;
  setErrors: (errors: readonly string[]) => void;
}) {
  return (
    <Grid
      xs={16}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onContextMenu={
        !isReadOnly || isVehiclesOnly ? handleContextMenu : undefined
      }
    >
      <Box>
        <Typography fontWeight="bold" fontSize={12} lineHeight={1.4}>
          {vehicle.vehicle_id}
        </Typography>
        <SecondVehicle vehicle={vehicle} />
        <VehiclePlaces vehicle={vehicle} />
      </Box>

      <Box>
        <ElectroVehicleTasks tasks={tasks} />
      </Box>

      <VehicleItemMenu
        isReadOnly={isReadOnly}
        isVehiclesOnly={isVehiclesOnly}
        selectedDate={selectedDate}
        drivers={drivers}
        vehicle={vehicle}
        vehicleState={vehicleState}
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        clearVehicleState={clearVehicleState}
        showVehicleStateDialog={showVehicleStateDialog}
        showVehicleCommentDialog={showVehicleCommentDialog}
        showCompositionDialog={showCompositionDialog}
        setErrors={setErrors}
        getRoster={getRoster}
      />
    </Grid>
  );
}

function AssignedVehicleItemElement(
  props: ElectroVehicleItemProps & {
    vehicleState: ScheduleVehicleDay["state"];
    contextMenu: ContextMenuPosition | null;
    setContextMenu: (value: ContextMenuPosition | null) => void;
    handleContextMenu: (event: MouseEvent) => void;
    isDisabled: boolean;
    tasks: RosterVehicleTask[];
    expireDateComment: string | null;
  }
) {
  const {
    wide,
    vehicle,
    selectedDate,
    vehicleState,
    isDisabled,
    tasks,
    expireDateComment,
  } = props;

  return (
    <GridContainer
      sx={{
        backgroundColor: isDisabled
          ? orange[200]
          : expireDateComment
          ? yellow[300]
          : grey[50],
        position: "relative",
        minHeight: 45,
      }}
      title={createVehicleTitle(
        vehicle,
        moment(selectedDate, "YYYY-MM-DD"),
        tasks,
        expireDateComment
      )}
    >
      <CommentIndicator
        active={
          !!vehicle.vehicle.days[selectedDate]?.comment ||
          (!!vehicleState?.id && vehicleState.id > 0) ||
          !!expireDateComment
        }
      />

      {wide ? (
        <WideVehicleItemElement {...props} />
      ) : (
        <RegularVehicleItemElement {...props} />
      )}
    </GridContainer>
  );
}

function UnassignedVehicleItemElement(
  props: ElectroVehicleItemProps & {
    vehicleState: ScheduleVehicleDay["state"];
    contextMenu: ContextMenuPosition | null;
    setContextMenu: (value: ContextMenuPosition | null) => void;
    handleContextMenu: (event: MouseEvent) => void;
    isDisabled: boolean;
    tasks: RosterVehicleTask[];
    expireDateComment: string | null;
  }
) {
  const {
    vehicle,
    selectedDate,
    vehicleState,
    isDisabled,
    tasks,
    expireDateComment,
  } = props;

  return (
    <GridContainer
      sx={{
        backgroundColor: isDisabled
          ? orange[200]
          : expireDateComment
          ? yellow[300]
          : grey[50],
        position: "relative",
        minHeight: 43,
      }}
      title={createVehicleTitle(
        vehicle,
        moment(selectedDate, "YYYY-MM-DD"),
        tasks,
        expireDateComment
      )}
    >
      <CommentIndicator
        active={
          !!vehicle.vehicle.days[selectedDate]?.comment ||
          (!!vehicleState?.id && vehicleState.id > 0) ||
          !!expireDateComment
        }
      />

      <RegularVehicleItemElement {...props} />
    </GridContainer>
  );
}

function VehicleItemElement(props: ElectroVehicleItemProps) {
  const { assigned, isReadOnly, isVehiclesOnly, vehicle, selectedDate } = props;

  const vehicleState = vehicle.vehicle.days[selectedDate]?.state;

  const [contextMenu, setContextMenu] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      if (!isReadOnly || isVehiclesOnly) {
        event.preventDefault();
        setContextMenu(
          contextMenu !== null
            ? null
            : {
                left: event.clientX + 2,
                top: event.clientY - 6,
              }
        );
      }
    },
    [contextMenu, isReadOnly, isVehiclesOnly]
  );

  const isDisabled = vehicle.vehicle.days[selectedDate]?.state?.category === 2;

  const tasks = useMemo(() => {
    return getVehicleTasks(vehicle.vehicle.days, selectedDate);
  }, [selectedDate, vehicle.vehicle.days]);

  const expireDateComment = useMemo(() => {
    const expireDate = vehicle.vehicle.periodic_tech_test
      ? moment(vehicle.vehicle.periodic_tech_test)
      : null;
    const date = moment(selectedDate, "YYYY-MM-DD").add(4, "days");
    if (expireDate && expireDate.isSameOrBefore(date)) {
      return `Предстои периодичен технически преглед до ${expireDate.format(
        "L"
      )}`;
    } else {
      return null;
    }
  }, [vehicle.vehicle.periodic_tech_test, selectedDate]);

  if (assigned) {
    return (
      <AssignedVehicleItemElement
        {...props}
        vehicleState={vehicleState}
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        handleContextMenu={handleContextMenu}
        isDisabled={isDisabled}
        tasks={tasks}
        expireDateComment={expireDateComment}
      />
    );
  } else {
    return (
      <UnassignedVehicleItemElement
        {...props}
        vehicleState={vehicleState}
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        handleContextMenu={handleContextMenu}
        isDisabled={isDisabled}
        tasks={tasks}
        expireDateComment={expireDateComment}
      />
    );
  }
}

export const ElectroVehicleItem = forwardRef(
  (
    { style, ...props }: ElectroVehicleItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const assignedStyle: CSSProperties = props.assigned
      ? { width: "100%" }
      : {
          paddingLeft: 2,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          width: "calc(100% - 1px)",
        };

    return (
      <div
        ref={ref}
        style={{
          margin: 0,
          // marginLeft: props.assigned ? -1 : undefined,
          display: "inline-flex",
          flexDirection: "column",
          fontSize: 12,
          fontWeight: "bold",
          cursor: "pointer",
          // minWidth: !props.assigned ? 70 : undefined,
          ...assignedStyle,
          ...style,
        }}
      >
        <VehicleItemElement {...props} />
      </div>
    );
  }
);
