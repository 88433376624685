import {
  DayTaskStates,
  DriverDayState,
} from "../schedule_recapitulation/useScheduleRecapitulation";

export interface DriverDayStateHistory extends DriverDayState {
  updated_at: string;
  username: {
    sub: string;
    username: string;
    name: string;
    roles: string[];
  } | null;
}

export interface DriverDayStateWithHistory extends DriverDayState {
  history_days: DriverDayStateHistory[];
}

export interface DriverDayStatesHistory {
  sl_number: number;
  home_depot_id: number | null;
  driver_days: DriverDayStateWithHistory[];
}

export interface ScheduleReportHistory {
  daytask_states: DayTaskStates[];
  drivers_schedule: DriverDayStatesHistory[];
}

export interface ScheduleReportHistoryData {
  data: ScheduleReportHistory;
}

interface ScheduleHistoryColumn {
  id: "sl_number" | "day" | "state" | "updated_at" | "username";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

export const ScheduleHistoryColumns: ScheduleHistoryColumn[] = [
  { id: "sl_number", label: "Водач", minWidth: 80, align: "center" },
  { id: "day", label: "Дата", minWidth: 120, align: "center" },
  { id: "state", label: "График", minWidth: 80, align: "center" },
  { id: "updated_at", label: "Дата корекция", minWidth: 80, align: "center" },
  { id: "username", label: "Потребител", minWidth: 120, align: "center" },
];

export interface ScheduleHistoryData {
  driver_id: number;
  day: string;
  state: string | null;
  updated_at: string;
  username: string;
}

export function createReportHistoryData(
  driver_id: number,
  day: string,
  state: string | null,
  updated_at: string,
  username: string
): ScheduleHistoryData {
  return {
    driver_id,
    day,
    state,
    updated_at,
    username,
  };
}
