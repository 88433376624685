import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Portal,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSubmenu } from "../../hooks/useSubmenu";
import { NavLink } from "../../common/NavLink";
import { WithAuth } from "../../common/WithAuth";
import { SubOutlet } from "../SubOutlet";
import { WithAnyRole } from "../../common/WithRoles";

export function NomenclaturePage() {
  const { submenu } = useSubmenu();

  return (
    <WithAuth>
      <div style={{ width: "100%", height: "100%" }}>
        <Portal container={submenu}>
          <List
            dense
            subheader={
              <ListSubheader component="div" inset>
                Номенклатури
              </ListSubheader>
            }
          >
            <WithAnyRole roles={["view_roster", "view_schedule"]}>
              <ListItemButton
                to="/nomenclatures/exploatation-plan"
                component={NavLink}
              >
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Експлоатационен план" />
              </ListItemButton>
            </WithAnyRole>

            <WithAnyRole
              roles={["view_roster", "view_schedule", "edit_drivers_licenses"]}
            >
              <ListItemButton to="/nomenclatures/drivers" component={NavLink}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Шофьори" />
              </ListItemButton>
            </WithAnyRole>

            <WithAnyRole
              roles={["view_roster", "view_schedule", "edit_tech_service"]}
            >
              <ListItemButton to="/nomenclatures/vehicles" component={NavLink}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Превозни средства" />
              </ListItemButton>
            </WithAnyRole>
          </List>
        </Portal>
        <SubOutlet />
      </div>
    </WithAuth>
  );
}
