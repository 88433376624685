import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ScheduleState } from "./types";

export function useSchedules(): ScheduleState {
  const daysInMonth = useSelector(
    (state: RootState) => state.schedule.daysInMonth
  );
  const schedule = useSelector((state: RootState) => state.schedule.schedule);

  const channelCallbacks = useSelector(
    (state: RootState) => state.schedule.schedulesChannelCallbacks
  );

  return useMemo<ScheduleState>(() => {
    const value = {
      daysInMonth,
      schedule,
      ...channelCallbacks,
    };
    return value;
  }, [daysInMonth, schedule, channelCallbacks]);
}
