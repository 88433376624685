import { Paper } from "@mui/material";
import { Moment } from "moment";
import { Transport } from "../../../data/api/types/driver";

import React from "react";

export function ReportVehiclesContent({
  rows,
  vehicles_state,
  selectedDate,
  totalVehicleCount,
}: {
  rows: {
    [key: string]: {
      veh_id: number;
      veh_comment: string;
      state_comment: string;
      state_category: number;
      state_id: number;
      first_shift_reserv: boolean;
      second_shift_reseve: boolean;
    }[];
  };
  vehicles_state: {
    id: number;
    transport: Transport;
    description: string;
    desc_short: string;
    site_visible: boolean;
    category_id: number;
  }[];
  selectedDate: Moment;
  totalVehicleCount: number;
}) {
  const resState = vehicles_state.find((s) =>
    s.description.toUpperCase().trim().startsWith("РЕЗ")
  );

  return (
    <>
      <Paper
        sx={{ height: "auto", px: 20, py: 6, my: 2 }}
        style={{ paddingBottom: 6, paddingTop: 6 }}
      >
        <h2 style={{ padding: "10" }}>
          {`Разпределение на коли - общо ${totalVehicleCount} бр.`}
        </h2>

        {Object.keys(rows)
          .filter(
            (r) => r !== "0"
            // &&
            // (rows[r][0].state_category !== 1 ||
            //   rows[r][0].state_id === resState?.id)
          )
          .map((r) => (
            <div key={r}>
              <h3>
                {
                  vehicles_state.find((s) => s.id === parseInt(r, 10))
                    ?.description
                }{" "}
                - {rows[r].length} бр.
              </h3>

              {
                // r === serviseState?.id.toString() &&
                <>
                  <p
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      marginLeft: "40px",
                    }}
                  >
                    {rows[r]
                      .filter(
                        (s) =>
                          s.state_comment.length === 0 &&
                          s.veh_comment.length === 0
                      )
                      .map((v) => v.veh_id)
                      .join(", ")}
                  </p>
                  {Object.values(
                    rows[r]
                      .filter(
                        (s) =>
                          s.state_comment.length > 0 || s.veh_comment.length > 0
                      )
                      .reduce<{
                        [key: string]: {
                          description: string;
                          veh_id: number;
                        }[];
                      }>((acc, x) => {
                        if (
                          acc[x.state_comment + x.veh_comment] === undefined
                        ) {
                          acc[x.state_comment + x.veh_comment] = [
                            {
                              description:
                                x.state_comment + " " + x.veh_comment,
                              veh_id: x.veh_id,
                            },
                          ];
                        } else {
                          acc[x.state_comment + x.veh_comment].push({
                            description: x.state_comment + " " + x.veh_comment,
                            veh_id: x.veh_id,
                          });
                        }
                        return acc;
                      }, {})
                  ).map((el) => (
                    <React.Fragment key={el[0].description}>
                      <h4
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          marginLeft: "40px",
                          display: "inline",
                        }}
                      >
                        {
                          // vehicles_state.find((s) => s.id === parseInt(r, 10))
                          //   ?.description +
                          //   " - " +
                          el[0].description
                        }
                        {el[0].description !== "" ? `: ` : ""}
                        <>&nbsp;</>
                      </h4>
                      <p
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          display: "inline",
                        }}
                      >
                        {el.map((v) => v.veh_id).join(", ")}
                        <br />
                      </p>
                    </React.Fragment>
                  ))}
                </>
              }

              {/* {r !== serviseState?.id.toString() && (
                <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                  {rows[r].map((v) => v.veh_id).join(", ")}
                </p>
              )} */}

              {r === resState?.id.toString() && (
                <>
                  <h3
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    Резерва I смяна -
                    {
                      Object.values(rows)
                        .flatMap((r) => r.map((r) => r))
                        .filter((r) => r.first_shift_reserv)
                        .map((r) => r.veh_id).length
                    }{" "}
                    бр.
                    <>&nbsp;</>
                  </h3>
                  <p
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      marginLeft: "40px",
                    }}
                  >
                    {Object.values(rows)
                      .flatMap((r) => r.map((r) => r))
                      .filter((r) => r.first_shift_reserv)
                      .map((r) => r.veh_id)
                      .sort((a, b) => a - b)
                      .join(", ")}
                  </p>

                  <h3
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    Резерва II смяна -
                    {
                      Object.values(rows)
                        .flatMap((r) => r.map((r) => r))
                        .filter((r) => r.second_shift_reseve)
                        .map((r) => r.veh_id).length
                    }{" "}
                    бр.
                    <>&nbsp;</>
                  </h3>
                  <p
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      marginLeft: "40px",
                    }}
                  >
                    {Object.values(rows)
                      .flatMap((r) => r.map((r) => r))
                      .filter((r) => r.second_shift_reseve)
                      .map((r) => r.veh_id)
                      .sort((a, b) => a - b)
                      .join(", ")}
                  </p>
                </>
              )}
            </div>
          ))}
        {!Object.keys(rows).find((r) => r === resState?.id.toString()) && (
          <>
            <h3
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              Резерва I смяна -
              {
                Object.values(rows)
                  .flatMap((r) => r.map((r) => r))
                  .filter((r) => r.first_shift_reserv)
                  .map((r) => r.veh_id).length
              }{" "}
              бр.
              <>&nbsp;</>
            </h3>
            <p
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                marginLeft: "40px",
              }}
            >
              {Object.values(rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.first_shift_reserv)
                .map((r) => r.veh_id)
                .sort((a, b) => a - b)
                .join(", ")}
            </p>

            <h3
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              Резерва II смяна -
              {
                Object.values(rows)
                  .flatMap((r) => r.map((r) => r))
                  .filter((r) => r.second_shift_reseve)
                  .map((r) => r.veh_id).length
              }{" "}
              бр.
              <>&nbsp;</>
            </h3>
            <p
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                marginLeft: "40px",
              }}
            >
              {Object.values(rows)
                .flatMap((r) => r.map((r) => r))
                .filter((r) => r.second_shift_reseve)
                .map((r) => r.veh_id)
                .sort((a, b) => a - b)
                .join(", ")}
            </p>
          </>
        )}
      </Paper>
    </>
  );
}
