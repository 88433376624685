import {
  Backdrop,
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import {
  CSSProperties,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import { RosterShift } from "./types/Roster";
import { DirectionsBus } from "@mui/icons-material";
import { getShiftColor, getShiftText, getShiftTitle } from "./shiftItemHelpers";
import { usePostTimetableCarAutocolumnMutation } from "../../data/api/hooks";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";
import { useProfile } from "../../common/useProfile";
import { grey } from "@mui/material/colors";

const ShiftContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "shift" && prop !== "selectedDate" && prop !== "inDriver",
})<{ shift: RosterShift; selectedDate: string; inDriver?: boolean }>(
  ({ shift, selectedDate, inDriver }) => ({
    margin: 1,
    padding: "0px 6px",
    border: "1px solid black",
    borderRadius: 3,
    backgroundColor: getShiftColor(shift, selectedDate),
    cursor: "pointer",
    minHeight: inDriver ? undefined : 35,
    maxHeight: inDriver ? 31 : undefined,
  })
);

const UnassignedShiftContainer = styled(ShiftContainer)(() => ({
  paddingBottom: 2,
}));

const ShiftTitle = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const ShiftTimes = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inDriver",
})<{ inDriver?: boolean }>(({ inDriver, theme }) => ({
  fontSize: 9.75,
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  whiteSpace: "nowrap",
  lineHeight: inDriver ? 1 : undefined,
}));

export const ShiftItem = forwardRef<
  HTMLElement,
  {
    isReadOnly: boolean;
    shift: RosterShift;
    selectedDate: string;
    style?: CSSProperties;
    assigned?: boolean;
    inDriver?: boolean;
    openChangeVehicleDialog: (
      shift: RosterShift,
      uiVehicle: number | null
    ) => void;
    onAnotherVehicle?: number | null;
    inUiVehicle: number | null;
  }
>(
  (
    {
      isReadOnly,
      shift,
      inUiVehicle,
      selectedDate,
      style,
      assigned,
      inDriver,
      openChangeVehicleDialog,
      onAnotherVehicle,
    },
    ref
  ) => {
    const profile = useProfile();

    const [contextMenu, setContextMenu] = useState<{
      left: number;
      top: number;
    } | null>(null);

    const { scheduleParameters } = useScheduleParameters();

    const onContextMenu = useCallback(
      (e: React.MouseEvent) => {
        if (!isReadOnly && !inDriver) {
          e.preventDefault();
          setContextMenu(
            contextMenu === null
              ? { left: e.clientX + 2, top: e.clientY - 6 }
              : null
          );
        }
      },
      [contextMenu, inDriver, isReadOnly]
    );

    const onCloseContextMenu = useCallback(() => {
      setContextMenu(null);
    }, []);

    const [changeAutocolumn, changeAutocolumnStatus] =
      usePostTimetableCarAutocolumnMutation();

    const otherAutocolumns = useMemo(
      () =>
        (scheduleParameters
          ? profile?.garage_autocolumns?.[scheduleParameters[0]]?.[
              scheduleParameters[1]
            ] || []
          : []
        ).filter(
          (a) =>
            shift.type === "timetable" && a !== shift.shift.workshift.autocolumn
        ),
      [
        profile?.garage_autocolumns,
        scheduleParameters,
        shift.shift.workshift,
        shift.type,
      ]
    );

    const changeAutocolumnCallback = useCallback(
      (autocolumn: number) => {
        setContextMenu(null);
        if (!isReadOnly && scheduleParameters && shift.type === "timetable") {
          console.log("Change shift autocolumn:", shift, autocolumn);
          changeAutocolumn({
            transport: scheduleParameters[0],
            depot: scheduleParameters[1],
            autocolumn: autocolumn,
            timetable_id: shift.shift.timetableId,
            car_no: shift.shift.car,
            shift_type: shift.shift.workshift.shift_type,
            start_date: selectedDate,
            end_date: selectedDate,
          });
        }
      },
      [changeAutocolumn, isReadOnly, scheduleParameters, selectedDate, shift]
    );

    if (assigned) {
      return (
        <ShiftContainer
          inDriver={inDriver}
          title={
            scheduleParameters
              ? getShiftTitle(
                  shift,
                  null,
                  selectedDate,
                  onAnotherVehicle,
                  scheduleParameters[0]
                )
              : ""
          }
          shift={shift}
          selectedDate={selectedDate}
          style={{ ...style, ...(onAnotherVehicle ? { opacity: 0.4 } : {}) }}
          ref={ref}
          onContextMenu={
            !isReadOnly && !onAnotherVehicle ? onContextMenu : undefined
          }
        >
          <ShiftTitle>{getShiftText(shift, true)}</ShiftTitle>
          {onAnotherVehicle ? (
            <ShiftTimes
              inDriver={inDriver}
            >{`Авт.: ${onAnotherVehicle}`}</ShiftTimes>
          ) : (
            <ShiftTimes inDriver={inDriver}>{`${shift.start_time.format(
              "HH:mm"
            )}-${shift.end_time.format("HH:mm")}`}</ShiftTimes>
          )}
          {scheduleParameters &&
            (scheduleParameters[0] === "TM" ||
              scheduleParameters[0] === "TB") &&
            shift.type === "timetable" &&
            shift.shift.places && (
              <ShiftTimes
                inDriver={inDriver}
                sx={{
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  lineHeight: 1,
                  marginBottom: "3px",
                }}
              >{`Места: ${shift.shift.places}`}</ShiftTimes>
            )}

          <Menu
            open={contextMenu !== null}
            onClose={onCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu || undefined}
          >
            <MenuItem
              onClick={() => {
                setContextMenu(null);
                if (!isReadOnly) {
                  openChangeVehicleDialog(shift, inUiVehicle);
                }
              }}
            >
              <ListItemIcon>
                <DirectionsBus fontSize="small" />
              </ListItemIcon>
              <ListItemText>Промяна на превозно средство за смяна</ListItemText>
            </MenuItem>
          </Menu>
        </ShiftContainer>
      );
    } else {
      return (
        <UnassignedShiftContainer
          inDriver={inDriver}
          title={
            scheduleParameters
              ? getShiftTitle(
                  shift,
                  null,
                  selectedDate,
                  null,
                  scheduleParameters[0]
                )
              : ""
          }
          shift={shift}
          selectedDate={selectedDate}
          style={style}
          ref={ref}
          onContextMenu={
            !isReadOnly && otherAutocolumns.length > 0
              ? onContextMenu
              : undefined
          }
        >
          <ShiftTitle>{getShiftText(shift, false)}</ShiftTitle>
          <ShiftTimes
            inDriver={inDriver}
            sx={{ fontSize: 10, whiteSpace: "nowrap", lineHeight: 1 }}
          >{`${shift.start_time.format("HH:mm")} - ${shift.end_time.format(
            "HH:mm"
          )}`}</ShiftTimes>
          <ShiftTimes
            inDriver={inDriver}
            sx={{ fontSize: 10, whiteSpace: "nowrap", lineHeight: 1 }}
          >{`Зар.: ${Math.floor(shift.work_time / 60)
            .toString()
            .padStart(2, "0")}:${(shift.work_time % 60)
            .toString()
            .padStart(2, "0")}`}</ShiftTimes>
          {scheduleParameters &&
            (scheduleParameters[0] === "TM" ||
              scheduleParameters[0] === "TB") &&
            shift.type === "timetable" &&
            shift.shift.places && (
              <ShiftTimes
                inDriver={inDriver}
                sx={{ fontSize: 10, whiteSpace: "nowrap", lineHeight: 1 }}
              >{`Места: ${shift.shift.places}`}</ShiftTimes>
            )}

          <Menu
            open={contextMenu !== null}
            onClose={onCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu || undefined}
          >
            {otherAutocolumns.length === 1 ? (
              <MenuItem
                onClick={() => {
                  changeAutocolumnCallback(otherAutocolumns[0]);
                }}
              >
                <ListItemIcon>
                  <DirectionsBus fontSize="small" />
                </ListItemIcon>
                <ListItemText>Прехвърли в другата автоколона</ListItemText>
              </MenuItem>
            ) : (
              otherAutocolumns.map((autocolumn) => (
                <MenuItem
                  key={autocolumn}
                  onClick={() => {
                    changeAutocolumnCallback(autocolumn);
                  }}
                >
                  <ListItemIcon>
                    <DirectionsBus fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{`Прехвърли в автоколона ${autocolumn}`}</ListItemText>
                </MenuItem>
              ))
            )}
          </Menu>

          {changeAutocolumnStatus.isLoading ? (
            <Backdrop
              open
              sx={{
                color: grey[300],
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress size={60} color="inherit" />
            </Backdrop>
          ) : (
            <></>
          )}
        </UnassignedShiftContainer>
      );
    }
  }
);
