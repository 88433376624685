import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import Grading from "@mui/icons-material/Grading";
import { NavLink } from "./NavLink";
import { useAuth } from "react-oidc-context";
import { Assessment, CalendarMonth, DepartureBoard } from "@mui/icons-material";
import { WithAnyRole } from "./WithRoles";

export function SideMenu({
  setSubmenu,
  setBelowSubmenu,
}: {
  setSubmenu: (ref: HTMLDivElement) => void;
  setBelowSubmenu: (ref: HTMLDivElement) => void;
}) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <div
        style={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List component="nav">
          <ListItemButton to="/" component={NavLink}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Начало" />
          </ListItemButton>

          <WithAnyRole roles={["view_schedule"]}>
            <ListItemButton to="/schedule/" component={NavLink}>
              <ListItemIcon>
                <CalendarMonth />
              </ListItemIcon>
              <ListItemText primary="Месечен график" />
            </ListItemButton>
          </WithAnyRole>

          <WithAnyRole roles={["view_roster"]}>
            <ListItemButton to="/roster/" component={NavLink}>
              <ListItemIcon>
                <DepartureBoard />
              </ListItemIcon>
              <ListItemText primary="Наряд" />
            </ListItemButton>
          </WithAnyRole>

          <WithAnyRole roles={["view_roster", "view_schedule"]}>
            <ListItemButton to="/reports" component={NavLink}>
              <ListItemIcon>
                <Assessment />
              </ListItemIcon>
              <ListItemText primary="Справки" />
            </ListItemButton>
          </WithAnyRole>

          <WithAnyRole
            roles={[
              "view_roster",
              "view_schedule",
              "edit_tech_service",
              "edit_drivers_licenses",
            ]}
          >
            <ListItemButton to="/nomenclatures" component={NavLink}>
              <ListItemIcon>
                <Grading />
              </ListItemIcon>
              <ListItemText primary="Номенклатури" />
            </ListItemButton>
          </WithAnyRole>

          <WithAnyRole roles={["nariad_admin"]}>
            <ListItemButton to="/admin" component={NavLink}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItemButton>
          </WithAnyRole>

          <Divider sx={{ my: 1 }} />
        </List>

        {/*submenu*/}
        <div
          style={{ flexGrow: 1 }}
          ref={(ref) => (ref ? setSubmenu(ref) : undefined)}
        />

        {/*belowSubmenu*/}
        <div
          style={{ flexShrink: 1 }}
          ref={(ref) => (ref ? setBelowSubmenu(ref) : undefined)}
        />
      </div>
    );
  } else {
    return (
      <List component="nav">
        <ListItemButton to="/" component={NavLink}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Начало" />
        </ListItemButton>
      </List>
    );
  }
}
