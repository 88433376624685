import { ScheduleParameters } from "../../data/api/types/schedule";
import { CELL_HEIGHT, CELL_WIDTH } from "./TableElements";
import { DriverTableRowRef, TableCellRef, TableRowRef } from "./types";

function processSelection(
  onSelectionStart: (
    mouseX: number,
    mouseY: number,
    mouseButton: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number
  ) => void,
  onSelectionDrag:
    | ((mouse: {
        x: number;
        y: number;
        initialX: number;
        initialY: number;
      }) => void)
    | undefined,
  onSelectionEnd: (mouseX: number, mouseY: number) => void,
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  row: TableRowRef,
  cell: TableCellRef,
  rowIndex: number,
  cellIndex: number,
  cellLocation: DOMRect,
  lastSentLocation: {
    x: number;
    y: number;
  },
  minCellIndex: number,
  maxCellIndex: number
) {
  const minX = cellLocation.left - (cellIndex - minCellIndex) * CELL_WIDTH;
  const maxX = cellLocation.left + (maxCellIndex - cellIndex) * CELL_WIDTH;

  onSelectionStart(
    e.clientX,
    e.clientY,
    e.button,
    row,
    cell,
    rowIndex,
    cellIndex
  );

  const onMove = (e: MouseEvent) => {
    setTimeout(() => {
      if (onSelectionDrag) {
        const x = Math.min(Math.max(e.clientX, minX), maxX);

        const diffX = Math.floor((x - cellLocation.left) / CELL_WIDTH);
        const diffY = Math.floor((e.clientY - cellLocation.top) / CELL_HEIGHT);

        if (lastSentLocation.x !== diffX || lastSentLocation.y !== diffY) {
          lastSentLocation.x = diffX;
          lastSentLocation.y = diffY;
          onSelectionDrag({
            x: diffX,
            y: diffY,
            initialX: e.clientX,
            initialY: e.clientY,
          });
        }
      }
    }, 0);
  };

  document.addEventListener(
    "mouseup",
    (e) => {
      document.removeEventListener("mousemove", onMove);
      onSelectionEnd(e.clientX, e.clientY);
      //setIsDragging(false);
    },
    { once: true }
  );
  document.addEventListener("mousemove", onMove);
}

export function isInAnotherDepot(
  scheduleParameters: ScheduleParameters,
  row: TableRowRef,
  cell: TableCellRef
): row is DriverTableRowRef {
  return (
    row.rowType === "driver" &&
    !!cell.driver_day.work_depot_id &&
    !!scheduleParameters &&
    cell.driver_day.work_depot_id !== scheduleParameters[1] &&
    cell.driver.home_depot_id === scheduleParameters[1]
  );
}

export function isFromAnotherDepot(
  scheduleParameters: ScheduleParameters,
  row: TableRowRef,
  cell: TableCellRef
): row is DriverTableRowRef {
  return (
    row.rowType === "driver" &&
    !!cell.driver_day.work_depot_id &&
    !!scheduleParameters &&
    cell.driver_day.work_depot_id !== scheduleParameters[1] &&
    cell.driver.home_depot_id !== scheduleParameters[1]
  );
}

export function mouseDwonHandler(
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  scheduleParameters: ScheduleParameters | null,
  row: TableRowRef,
  rowIndex: number,
  cell: TableCellRef,
  cellIndex: number,
  _setSelection?: (selection: {
    row: number;
    startCell: number;
    endCell: number;
    initialMouseCoordinates: {
      x: number;
      y: number;
    };
  }) => void,
  onSelectionStart?: (
    mouseX: number,
    mouseY: number,
    mouseButton: number,
    row: TableRowRef,
    cell: TableCellRef,
    rowIndex: number,
    cellIndex: number
  ) => void,
  onSelectionDrag?: (mouse: {
    x: number;
    y: number;
    initialX: number;
    initialY: number;
  }) => void,
  onSelectionEnd?: (mouseX: number, mouseY: number) => void
) {
  if (onSelectionStart && onSelectionEnd) {
    let target: HTMLElement | null = e.target as HTMLElement;
    while (target && target.dataset["cellType"] !== "status") {
      target = target.parentElement;
    }
    if (!target) {
      target = e.target as HTMLElement;
    }

    const cellLocation = target.getBoundingClientRect();
    const lastSentLocation = { x: 0, y: 0 };

    if (scheduleParameters && isInAnotherDepot(scheduleParameters, row, cell)) {
      // Клик върху комадирован водач
      let minCellIndex = cellIndex;
      let maxCellIndex = cellIndex;

      while (
        minCellIndex >= 0 &&
        row.cells[minCellIndex].driver_day.work_depot_id &&
        row.cells[minCellIndex].driver_day.work_depot_id !==
          scheduleParameters[1]
      ) {
        minCellIndex -= 1;
      }
      minCellIndex += 1;

      while (
        maxCellIndex < row.cells.length &&
        row.cells[maxCellIndex].driver_day.work_depot_id &&
        row.cells[maxCellIndex].driver_day.work_depot_id !==
          scheduleParameters[1]
      ) {
        maxCellIndex += 1;
      }
      maxCellIndex -= 1;

      processSelection(
        onSelectionStart,
        onSelectionDrag,
        onSelectionEnd,
        e,
        row,
        cell,
        rowIndex,
        cellIndex,
        cellLocation,
        lastSentLocation,
        minCellIndex,
        maxCellIndex
      );

      // setSelection &&
      //   setSelection({
      //     row: rowIndex,
      //     startCell: minCellIndex + 1,
      //     endCell: maxCellIndex - 1,
      //     initialMouseCoordinates: {
      //       x: e.clientX,
      //       y: e.clientY,
      //     },
      //   });
    } else if (
      scheduleParameters &&
      isFromAnotherDepot(scheduleParameters, row, cell)
    ) {
      // Командирован водач от друго депо, избора се игнорира
    } else if (scheduleParameters && row.rowType === "driver") {
      let minCellIndex = cellIndex;
      let maxCellIndex = cellIndex;

      while (
        minCellIndex >= 0 &&
        (!row.cells[minCellIndex].driver_day.work_depot_id ||
          row.cells[minCellIndex].driver_day.work_depot_id ===
            scheduleParameters[1])
      ) {
        minCellIndex -= 1;
      }
      minCellIndex += 1;

      while (
        maxCellIndex < row.cells.length &&
        (!row.cells[maxCellIndex].driver_day.work_depot_id ||
          row.cells[maxCellIndex].driver_day.work_depot_id ===
            scheduleParameters[1])
      ) {
        maxCellIndex += 1;
      }
      maxCellIndex -= 1;

      processSelection(
        onSelectionStart,
        onSelectionDrag,
        onSelectionEnd,
        e,
        row,
        cell,
        rowIndex,
        cellIndex,
        cellLocation,
        lastSentLocation,
        minCellIndex,
        maxCellIndex
      );
    }
  }
}
