import moment, { Moment } from "moment";
import { Transport } from "../../data/api/types/driver";
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePostScheduleShiftsMutation } from "../../data/api/hooks";
import { useProfile } from "../../common/useProfile";

export type DayTypes = {
  date: Moment;
  day_type: number;
};

export type SpecialShift = {
  id: number;
  transport: Transport;
  depot_id: number;
  description: string;
  start_time: number;
  end_time: number;
  shift_type: number;
  validity_start: Moment;
  validity_end: Moment | null;
  auto_fill: boolean;
  auto_fill_count: number;
  autocolumn: number;
};

export type Schedule_SpecialShift = {
  type: SpecialShift;
  counts_by_date: { count: number; date: string }[];
};

export type Schedule_SpecialShifts = {
  holidays: DayTypes[];
  schedule_spec_shifts: Schedule_SpecialShift[];
  spec_shifts_types: SpecialShift[];
};

interface ScheduleSpecialShiftColumn {
  id: "description" | "start_time" | "end_time";
  label: string;
  minWidth?: number;
  width: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

export const scehuleSpecShiftColumns: ScheduleSpecialShiftColumn[] = [
  { id: "description", label: "Вид", minWidth: 50, align: "left", width: 120 },
  {
    id: "start_time",
    label: "Начало",
    minWidth: 40,
    align: "center",
    width: 60,
  },
  { id: "end_time", label: "Край", minWidth: 40, align: "center", width: 60 },
];

export interface Schedule_SpecialShiftData {
  shift_id: number;
  description: string;
  start_time: string;
  end_time: string;
  schedule_days: {
    date: string;
    count: number;
  }[];
}

export function createScheduleSpecialShiftData(
  shift_id: number,
  description: string,
  start_time: string,
  end_time: string,
  schedule_days: {
    date: string;
    count: number;
  }[]
): Schedule_SpecialShiftData {
  return { shift_id, description, start_time, end_time, schedule_days };
}

export function createScheduleSpecialShiftDays(
  defaultCounts: number,
  selMonth: Moment
): { date: string; count: number }[] {
  const days = selMonth.daysInMonth();

  return [...Array(days)].map((m, index) => ({
    date: selMonth.startOf("month").add(index, "d").format("YYYY-MM-DD"),
    count: defaultCounts,
  }));
}

export function useSpecialShiftCalendar(
  data: Schedule_SpecialShiftData[],
  transport: Transport | "",
  garage: number,
  avtocolona: number,
  isLoading: boolean,
  isError: boolean,
  spec_shifts_types: SpecialShift[] | undefined,
  selectedMonth: Moment | null
) {
  const profile = useProfile();
  const isReadOnly = !profile?.roles?.includes("edit_schedule");

  const [updateScheduleShifts] = usePostScheduleShiftsMutation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      specialshift_calendar: data,
    },
    mode: "onBlur",
  });

  const { fields, append: driversAppend } = useFieldArray({
    name: "specialshift_calendar",
    control,
  });
  const w = useWatch({ control, name: "specialshift_calendar" }); //watch("specialshift_calendar", fields);

  const onSubmit = useCallback<
    SubmitHandler<{ specialshift_calendar: Schedule_SpecialShiftData[] }>
  >(
    (data) => {
      if (!isReadOnly && isDirty) {
        if (dirtyFields.specialshift_calendar) {
          for (const [index, value] of Object.entries(
            dirtyFields.specialshift_calendar
          )) {
            const schedul_shift =
              data.specialshift_calendar[index as unknown as number];

            if (value.schedule_days) {
              for (const [ind] of Object.entries(value.schedule_days)) {
                updateScheduleShifts({
                  transport: transport,
                  gps_depot_id: garage,
                  avtovolona: avtocolona,
                  year: moment(
                    schedul_shift.schedule_days[ind as any as number].date
                  ).year(),
                  month:
                    moment(
                      schedul_shift.schedule_days[ind as any as number].date
                    ).month() + 1,
                  day_in_month:
                    schedul_shift.schedule_days[ind as any as number].date,
                  special_shift_id: schedul_shift.shift_id,
                  count:
                    schedul_shift.schedule_days[ind as any as number].count,
                }).then((res) => {
                  if ("error" in res && res.error) {
                    // <QueryError error={res.error} />;
                    console.log("updateScheduleShifts:", res.error);
                  } else {
                    console.log("updateScheduleShifts OK:");
                  }
                });
              }
            }
          }
        }
      }
    },
    [
      avtocolona,
      dirtyFields.specialshift_calendar,
      garage,
      isDirty,
      isReadOnly,
      transport,
      updateScheduleShifts,
    ]
  );

  const submit = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  useEffect(() => {
    reset({
      specialshift_calendar: data,
    });

    if ((!data || data.length <= 0) && spec_shifts_types && selectedMonth) {
      const spec_shift_arr = spec_shifts_types.filter(
        (spec) =>
          spec.validity_end === null ||
          spec.validity_end >= selectedMonth.startOf("month")
      );

      const arr = Array.from({ length: spec_shift_arr.length }, (_, index) => {
        const days = createScheduleSpecialShiftDays(
          spec_shift_arr[index].auto_fill_count,
          selectedMonth
        );

        return createScheduleSpecialShiftData(
          spec_shift_arr[index].id,
          spec_shift_arr[index].description,
          moment
            .utc(
              moment
                .duration(spec_shift_arr[index].start_time, "seconds")
                .as("milliseconds")
            )
            .format("HH:mm"),
          moment
            .utc(
              moment
                .duration(spec_shift_arr[index].end_time, "seconds")
                .as("milliseconds")
            )
            .format("HH:mm"),
          days
        );
      });
      driversAppend(arr);

      // onSubmit({ specialshift_calendar: arr });
    }
  }, [data, driversAppend, reset, selectedMonth, spec_shifts_types]);

  const [totalRow, setTotalRow] = useState<{ date: string; count: number }[]>(
    []
  );

  useEffect(() => {
    if (
      selectedMonth &&
      !isLoading &&
      w.length > 0 &&
      w[0].schedule_days?.length > 0 &&
      selectedMonth.month() === moment(w[0].schedule_days[0].date).month()
    ) {
      const arr = [...Array(selectedMonth.daysInMonth())].map((m, index) => ({
        date: selectedMonth
          .startOf("month")
          .add(index, "d")
          .format("YYYY-MM-DD"),
        count: w.reduce(
          (prev, shift) =>
            shift.schedule_days[index]?.count
              ? prev +
                parseInt(shift.schedule_days[index].count as any as string, 10)
              : prev,
          0
        ),
      }));
      setTotalRow(arr);
    }
  }, [isLoading, selectedMonth, w]);

  const hasError = isError; //|| errors.specialshift_calendar; //resultUpdateDriver.isError || isError;
  const loading = isLoading; // resultUpdateDriver.isLoading || isLoading;

  return {
    register,
    control,
    fields,
    handleSubmit: submit,
    isDirty,
    dirtyFields,
    hasError,
    loading,
    errors,
    driversAppend,
    totalRow,
  };
}
