import moment, { Moment } from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { updateSelectedMonth } from "../data/filtersSlice";
import { RootState, useAppDispatch } from "../data/store";

export function useMonthFilter() {
  const dispatch = useAppDispatch();

  const selectedMonth = useSelector((state: RootState) =>
    state.filters.selectedMonth ? moment(state.filters.selectedMonth) : null
  );

  const setSelectedMonth = useCallback(
    (month: Moment | null) => {
      dispatch(
        updateSelectedMonth(
          month && month.isValid() ? month.format("YYYY-MM") : null
        )
      );
    },
    [dispatch]
  );

  return {
    selectedMonth,
    setSelectedMonth,
  };
}
