import { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useProfile } from "./useProfile";

export function WithAnyRole({
  children,
  roles,
  redirect,
  fallback,
}: PropsWithChildren<{
  roles: string[];
  redirect?: boolean;
  fallback?: ReactNode;
}>) {
  const auth = useAuth();
  const profile = useProfile();

  const hasRoles = useMemo(() => {
    // console.log("Checking for roles:", roles);
    if (profile) {
      for (const role of roles) {
        if (profile.roles.includes(role)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, [profile, roles]);

  const hasProfile = !!profile;

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    } else if (hasProfile && !hasRoles && redirect) {
      auth.signinRedirect();
    }
  }, [auth, hasRoles, redirect, hasProfile]);

  if (auth.isAuthenticated && hasRoles) {
    return <>{children}</>;
  } else {
    return <>{fallback}</>;
  }
}
