import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDepotFilter } from "./useDepotFilter";

export function DepotSelect({ isInMenu }: { isInMenu?: boolean }) {
  const { depots, selectedDepot, setSelectedDepot } = useDepotFilter();

  return (
    <FormControl
      variant={isInMenu ? undefined : "standard"}
      size={isInMenu ? "small" : undefined}
      sx={{ m: 1, width: isInMenu ? "10em" : "100%" }}
    >
      <InputLabel id="schedule-depot">Гараж</InputLabel>
      <Select
        labelId="schedule-depot"
        id="schedule-depot-select"
        value={selectedDepot}
        label="Гараж"
        onChange={(e) => setSelectedDepot(e.target.value as number)}
      >
        <MenuItem key={-1} value={-1} disabled>
          &mdash;
        </MenuItem>
        {depots.map((depot) => (
          <MenuItem key={depot.id} value={depot.id}>
            {depot.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
