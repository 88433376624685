import { SxProps, Theme } from "@mui/material";
import { memo, useCallback } from "react";
import { useDrop } from "react-dnd";
import { useSchedules } from "../../data/schedules/useSchedules";
import { DraggableShiftItem } from "./DraggableShiftItem";
import { ItemContainer } from "./ItemContainer";
import { ShiftItem } from "./ShiftItem";
import { Roster, RosterShift } from "./types/Roster";
import { warnOnPromise } from "./warnOnPromise";

export function useDropOnShifts(
  roster: Roster,
  selectedDate: string,
  isReadOnly: boolean
) {
  const { removeShiftFromVehicle } = useSchedules();

  const dropOnShifts = useCallback(
    (shift: RosterShift) => {
      if (isReadOnly) {
        return;
      }

      console.log("Drop on shifts", shift, roster);

      const fromVehicles = roster.vehicles.filter((vehicle) =>
        vehicle.shifts.find((s) =>
          s.find((s) =>
            s.daytasks.find(
              (dt) =>
                (shift.type === "timetable" &&
                  dt.workshift_id === shift.shift.workshift.id) ||
                (shift.type === "special" &&
                  dt.special_shift_id === shift.shift.workshift.id)
            )
          )
        )
      );

      if (fromVehicles.length > 0) {
        for (const vehicle of fromVehicles) {
          console.log("Dropped", shift, "from vehicle", vehicle);
          for (const d of vehicle.shifts) {
            for (const s of d) {
              if (
                s &&
                s.daytasks.find(
                  (dt) =>
                    (shift.type === "timetable" &&
                      dt.workshift_id === shift.shift.workshift.id) ||
                    (shift.type === "special" &&
                      dt.special_shift_id === shift.shift.workshift.id)
                )
              ) {
                warnOnPromise(removeShiftFromVehicle, {
                  driver_id: s.schedule_id,
                  date: selectedDate,
                  shift_id: shift.shift.workshift.id,
                });
              }
            }
          }
        }
      } else {
        // Drop from driver
        const fromDrivers = roster.unusedDrivers.filter((driver) =>
          driver.daytasks.find(
            (dt) =>
              dt.rosterShift?.shift?.workshift?.id === shift.shift.workshift.id
          )
        );
        for (const driver of fromDrivers) {
          console.log("Dropped", shift, "from driver", driver);

          warnOnPromise(removeShiftFromVehicle, {
            driver_id: driver.schedule_id,
            date: selectedDate,
            shift_id: shift.shift.workshift.id,
          });
        }
      }
    },
    [isReadOnly, removeShiftFromVehicle, roster, selectedDate]
  );

  return dropOnShifts;
}

export const DroppableShiftContainer = memo(function DroppableShiftContainer({
  isReadOnly,
  accept,
  roster,
  selectedDate,
  title,
  headerSx,
  selectedLines,
  onDrop,
  openChangeVehicleDialog,
}: {
  isReadOnly: boolean;
  roster: Roster;
  selectedDate: string;
  title?: string;
  headerSx?: SxProps<Theme>;
  accept: string[];
  selectedLines: readonly number[];
  onDrop: (item: RosterShift) => void;
  openChangeVehicleDialog: (
    shift: RosterShift,
    uiVehicle: number | null
  ) => void;
}) {
  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [accept, onDrop]
  );

  const isActive = isOver && canDrop;

  return (
    <ItemContainer
      ref={drop}
      title={title}
      isOver={isActive}
      sx={{ height: "100%" }}
      headerSx={headerSx}
    >
      {roster.unusedShifts
        .filter(
          (shift) =>
            selectedLines.length === 0 ||
            shift.type !== "timetable" ||
            selectedLines.includes(shift.shift.line)
        )
        .map((shift) =>
          shift.type === "timetable" ? (
            isReadOnly ? (
              <ShiftItem
                key={`timetable-shift-${shift.shift.workshift.id}`}
                isReadOnly={isReadOnly}
                shift={shift}
                inUiVehicle={null}
                selectedDate={selectedDate}
                openChangeVehicleDialog={openChangeVehicleDialog}
              />
            ) : (
              <DraggableShiftItem
                key={`timetable-shift-${shift.shift.workshift.id}`}
                isReadOnly={isReadOnly}
                shift={shift}
                inUiVehicle={null}
                selectedDate={selectedDate}
                openChangeVehicleDialog={openChangeVehicleDialog}
              />
            )
          ) : isReadOnly ? (
            <ShiftItem
              key={`special-shift-${shift.shift.workshift.id}`}
              isReadOnly={isReadOnly}
              shift={shift}
              inUiVehicle={null}
              selectedDate={selectedDate}
              openChangeVehicleDialog={openChangeVehicleDialog}
            />
          ) : (
            <DraggableShiftItem
              key={`special-shift-${shift.shift.workshift.id}`}
              isReadOnly={isReadOnly}
              shift={shift}
              inUiVehicle={null}
              selectedDate={selectedDate}
              openChangeVehicleDialog={openChangeVehicleDialog}
            />
          )
        )}
    </ItemContainer>
  );
});
