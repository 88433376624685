import { Check, FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { blue } from "@mui/material/colors";
import type { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Schedule } from "../../data/api/types/schedule";
import {
  clearSelectedStateFilter,
  toggleSelectedStateFilter,
} from "../../data/scheduleSlice";
import { RootState, useAppDispatch } from "../../data/store";
import {
  reportStatistics,
  UseGarageStatisticsResult,
} from "../../pages/schedule/useCalculateGarageStatistics";
import { EditableCell } from "./EditableCell";
import { TitleHeaderCell } from "./TableElements";
import { TableColumnRef } from "./types";
import { UseDriverFilterResult } from "./useDriverFilter";
import { UseHeaderScrollerResult } from "./useHeaderScroller";

function getStatesForDate(
  schedule: Schedule | undefined,
  selectedDate: string
): readonly { id: number; name: string }[] {
  if (!schedule) {
    return [];
  } else {
    const states: Record<number, { id: number; name: string }> = {};
    for (const driver of Object.values(schedule.drivers)) {
      const day = driver.driver_days[selectedDate];
      if (day) {
        states[day.state] = { id: day.state, name: day.state_name };
      }
    }
    return Object.values(states).sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );
  }
}

export function DatesHeaderRow({
  firstHeaderColumnWidth,
  headerColumnWidth,
  headerScroller,
  garageStatistics,
  driverFilter,
  columns,
  isHoliday,
  today,
  selectedDate,
  setSelectedDate,
}: {
  firstHeaderColumnWidth: number;
  headerColumnWidth: number;
  headerScroller: UseHeaderScrollerResult;
  garageStatistics: UseGarageStatisticsResult | null;
  driverFilter: UseDriverFilterResult;
  columns: TableColumnRef[];
  isHoliday: (day: string | Moment) => boolean | undefined;
  today: Moment;
  selectedDate: Moment | null;
  setSelectedDate: (date: Moment | null) => void;
}) {
  const dispatch = useAppDispatch();
  const schedule = useSelector((state: RootState) => state.schedule.schedule);
  const selectedStates = useSelector(
    (state: RootState) => state.schedule.selectedStatesFilter
  );

  const [statesList, setStatesList] = useState<
    readonly { id: number; name: string }[]
  >([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    date: string;
  } | null>(null);

  return (
    <div
      style={{
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
      }}
    >
      <EditableCell
        width={firstHeaderColumnWidth}
        fixLeftIdx={0}
        value={driverFilter.driverFilterValue}
        onChange={driverFilter.setDriverFilterValue}
        setDriverFilterField={driverFilter.setDriverFilterField}
      >
        {driverFilter.driverFilterValue}
      </EditableCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={1}
        title={reportStatistics(garageStatistics)}
      >
        РС
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={2}
      >
        ИТ
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={3}
      >
        П
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={4}
      >
        БО
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={5}
      >
        ПС
      </TitleHeaderCell>
      {columns.map((column) => (
        <TitleHeaderCell
          tabIndex={0}
          key={column.id}
          title={column.title}
          isHoliday={isHoliday(column.date)}
          style={{
            ...(column.date.isSameOrBefore(today, "day")
              ? {
                  boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
                }
              : {}),
            ...(column.date.isSame(selectedDate)
              ? { backgroundColor: blue[300] }
              : {}),
            position: "relative",
          }}
          onClick={() =>
            selectedDate && selectedDate.isSame(column.date)
              ? setSelectedDate(null)
              : setSelectedDate(column.date)
          }
          onContextMenu={(e) => {
            if (column.date?.isValid()) {
              e.preventDefault();
              setStatesList(
                getStatesForDate(schedule, column.date.format("YYYY-MM-DD"))
              );
              setContextMenu(
                contextMenu
                  ? null
                  : {
                      mouseX: e.clientX + 26,
                      mouseY: e.clientY - 6,
                      date: column.date.format("YYYY-MM-DD"),
                    }
              );
            }
          }}
        >
          {column.content}
          {selectedStates[column.date.format("YYYY-MM-DD")]?.length ? (
            <div style={{ position: "absolute", top: -1, right: -1 }}>
              <FilterAlt color="primary" fontSize="small" />
            </div>
          ) : undefined}
        </TitleHeaderCell>
      ))}
      <div style={{ minWidth: headerScroller.scrollerWidth }} />

      <Menu
        open={contextMenu !== null}
        onClose={() => {
          setStatesList([]);
          setContextMenu(null);
        }}
        onContextMenu={(e) => {
          if (
            e.target instanceof HTMLElement &&
            e.target.classList.contains("MuiBackdrop-root")
          ) {
            e.preventDefault();
            setStatesList([]);
            setContextMenu(null);
          }
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem disabled>
          <ListItemIcon />
          Филтър...
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={
            contextMenu
              ? () => {
                  dispatch(
                    clearSelectedStateFilter({ date: contextMenu.date })
                  );
                  setContextMenu(null);
                }
              : undefined
          }
        >
          <ListItemIcon>
            <FilterAltOff />
          </ListItemIcon>
          Изчисти
        </MenuItem>
        {statesList.map((state) => (
          <MenuItem
            key={state.id}
            onClick={
              contextMenu
                ? () => {
                    dispatch(
                      toggleSelectedStateFilter({
                        date: contextMenu.date,
                        state_id: state.id,
                      })
                    );
                  }
                : undefined
            }
          >
            <ListItemIcon>
              {selectedStates[contextMenu?.date || ""]?.includes(state.id) && (
                <Check />
              )}
            </ListItemIcon>
            {state.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
