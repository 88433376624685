import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { PlannerSettings } from "../../common/useGarageSettings";
import { useScheduleParameters } from "../../components/schedule/useScheduleParameters";

type FormModel = {
  calc_timeout: number;
  hard_expl_plan: boolean;
  enable_ktd: boolean;
  ktd_coefficient: number;
  max_single_rests: number;
  workdays: [number, number];
  max_expl_plan_difference: number;
  max_rests_correction: number;
  weekend_ratio: number;
  max_weekend_rests: number;
  connect_previous_month: boolean;
  // dont_ignore_leaves: boolean;
};

// function durationLabel(value: number): string {
//   if (value < 60) {
//     return `${value} с`;
//   } else if (value < 3600) {
//     return `${Math.floor(value / 60)} м`;
//   } else if (value % 3600 === 0) {
//     return `${Math.floor(value / 3600)} ч`;
//   } else {
//     return `${Math.floor(value / 3600)} ч ${durationLabel(value % 3600)}`;
//   }
// }

function weekendRatioValue(value: number) {
  switch (value) {
    case 0:
      return 100000;
    case 1:
      return -100;
    case 2:
      return -75;
    case 3:
      return -50;
    case 4:
      return -25;
    case 5:
      return 0;
    case 6:
      return 25;
    case 7:
      return 50;
    case 8:
      return 75;
    case 9:
      return 100;
    default:
      return 100000;
  }
}

const max_expl_plan_difference_values = [
  0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144,
];

function getMaxExplPlanDifferenceIndex(value: number): number {
  for (let i = 0; i < max_expl_plan_difference_values.length; ++i) {
    if (max_expl_plan_difference_values[i] === value) {
      return i;
    } else if (i === 0 && max_expl_plan_difference_values[i] > value) {
      return i;
    } else if (i > 0 && max_expl_plan_difference_values[i] > value) {
      return i - 1;
    }
  }
  return max_expl_plan_difference_values.length - 1;
}

function valuesFromGarageSettings(initialSettings: PlannerSettings): FormModel {
  return {
    calc_timeout: initialSettings.calc_timeout,
    hard_expl_plan: initialSettings.hard_expl_plan,
    enable_ktd: initialSettings.enable_ktd,
    ktd_coefficient: initialSettings.ktd_coefficient,
    workdays: [initialSettings.min_workdays, initialSettings.max_workdays],
    max_single_rests: initialSettings.max_single_rests,
    max_expl_plan_difference: getMaxExplPlanDifferenceIndex(
      initialSettings.max_expl_plan_difference
    ),
    max_rests_correction: initialSettings.max_rests_correction,
    weekend_ratio: initialSettings.weekend_ratio,
    max_weekend_rests: initialSettings.max_weekend_rests,
    connect_previous_month: initialSettings.connect_previous_month,
    // dont_ignore_leaves: !initialSettings.ignore_leaves,
  };
}

function isValidFormModel(value?: any): value is FormModel {
  return (
    value &&
    Number.isSafeInteger(value.calc_timeout) &&
    value.calc_timeout >= 300 &&
    value.calc_timeout <= 7200 &&
    Number.isSafeInteger(value.max_single_rests) &&
    value.max_single_rests >= 1 &&
    value.max_single_rests <= 10 &&
    Number.isSafeInteger(value.max_expl_plan_difference) &&
    value.max_expl_plan_difference >= 0 &&
    value.max_expl_plan_difference <= 11 &&
    Number.isSafeInteger(value.max_rests_correction) &&
    value.max_rests_correction >= 0 &&
    value.max_rests_correction <= 4 &&
    Number.isSafeInteger(value.weekend_ratio) &&
    value.weekend_ratio >= 0 &&
    value.weekend_ratio <= 9 &&
    Number.isSafeInteger(value.max_weekend_rests) &&
    value.max_weekend_rests >= 1 &&
    value.max_weekend_rests <= 5 &&
    typeof value.ktd_coefficient === "number" &&
    value.ktd_coefficient >= 0 &&
    value.ktd_coefficient <= 1 &&
    Array.isArray(value.workdays) &&
    value.workdays.length === 2 &&
    Number.isSafeInteger(value.workdays[0]) &&
    Number.isSafeInteger(value.workdays[1]) &&
    value.workdays[0] >= 1 &&
    value.workdays[0] <= 13 &&
    value.workdays[1] > value.workdays[0] &&
    value.workdays[1] <= 14 &&
    (typeof value.hard_expl_plan === "boolean" ||
      value.hard_expl_plan === null ||
      value.hard_expl_plan === undefined) &&
    (typeof value.enable_ktd === "boolean" ||
      value.enable_ktd === null ||
      value.enable_ktd === undefined) &&
    (typeof value.connect_previous_month === "boolean" ||
      value.connect_previous_month === null ||
      value.connect_previous_month === undefined)
    // (typeof value.dont_ignore_leaves === "boolean" ||
    //   value.dont_ignore_leaves === null ||
    //   value.dont_ignore_leaves === undefined)
  );
}

function cleanupFormModel(value: FormModel): FormModel {
  return {
    ...value,
    hard_expl_plan: !!value.hard_expl_plan,
    enable_ktd: !!value.enable_ktd,
    connect_previous_month: !!value.connect_previous_month,
    // dont_ignore_leaves: !!value.dont_ignore_leaves,
  };
}

export function PlannerDialog({
  open,
  initialSettings,
  confirm,
  reject,
}: {
  open: boolean;
  initialSettings: PlannerSettings;
  confirm: (settings: PlannerSettings) => void;
  reject: () => void;
}) {
  const { scheduleParameters } = useScheduleParameters();

  const initialFormModel = useMemo<FormModel>(() => {
    if (scheduleParameters) {
      try {
        const key = `planner-dialog:${scheduleParameters[0]}-${scheduleParameters[1]}`;
        const value = JSON.parse(atob(window.localStorage.getItem(key) || ""));
        if (isValidFormModel(value)) {
          return cleanupFormModel(value);
        } else {
          console.log("Can't decode parameters, using defaults");
        }
      } catch {
        console.log("Can't decode parameters, using defaults");
      }
    }

    return valuesFromGarageSettings(initialSettings);
  }, [initialSettings, scheduleParameters]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, isLoading, isSubmitting, isValidating },
  } = useForm<FormModel>({ defaultValues: initialFormModel });

  const onSubmit = useCallback<SubmitHandler<FormModel>>(
    (data) => {
      const settings: PlannerSettings = {
        calc_timeout: data.calc_timeout,
        enable_ktd: data.enable_ktd,
        ktd_coefficient: data.ktd_coefficient,
        hard_expl_plan: data.hard_expl_plan,
        max_rests_correction: data.max_rests_correction,
        weekend_ratio: weekendRatioValue(data.weekend_ratio),
        max_weekend_rests: data.max_weekend_rests,
        min_workdays: data.workdays[0],
        max_workdays: data.workdays[1],
        max_single_rests: data.max_single_rests,
        max_expl_plan_difference:
          max_expl_plan_difference_values[data.max_expl_plan_difference],
        connect_previous_month: data.connect_previous_month,
        // ignore_leaves: !data.dont_ignore_leaves,
        ignore_leaves: initialSettings.ignore_leaves,
        num_workers: initialSettings.num_workers,
      };

      if (scheduleParameters) {
        const key = `planner-dialog:${scheduleParameters[0]}-${scheduleParameters[1]}`;
        const value = btoa(JSON.stringify(data));
        window.localStorage.setItem(key, value);
      }

      console.log("Starting planner with settings:", settings);
      confirm(settings);
    },
    [
      confirm,
      initialSettings.ignore_leaves,
      initialSettings.num_workers,
      scheduleParameters,
    ]
  );

  const hard_expl_plan = watch("hard_expl_plan");
  const enable_ktd = watch("enable_ktd");

  return (
    <Dialog open={open} onClose={reject}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Параметри за автоматично изчисляване на график
        </DialogTitle>
        <DialogContent
          sx={{
            "&>.MuiFormGroup-root": {
              mt: 2,
              mb: 4,
            },
            "& .MuiSlider-markLabel": {
              marginTop: "-4px",
            },
            "& .MuiSlider-root": {
              marginBottom: "8px",
            },
          }}
        >
          {/*<FormGroup sx={{ ml: 3, mr: 3 }} style={{ marginTop: 14 }}>
            <Typography id="input-slider" variant="body2">
              Максимално време за работа:
            </Typography>
            <Controller
              name="calc_timeout"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={5 * 60}
                  max={120 * 60}
                  step={60}
                  marks={[
                    { value: 5 * 60, label: durationLabel(5 * 60) },
                    { value: 30 * 60, label: durationLabel(30 * 60) },
                    { value: 60 * 60, label: durationLabel(60 * 60) },
                    { value: 90 * 60, label: durationLabel(90 * 60) },
                    { value: 120 * 60, label: durationLabel(120 * 60) },
                  ]}
                  getAriaValueText={durationLabel}
                  valueLabelDisplay="auto"
                  valueLabelFormat={durationLabel}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>*/}

          <FormGroup style={{ marginLeft: 16, marginBottom: 0 }}>
            <Controller
              name="connect_previous_month"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={!!field.value} size="small" />
                  }
                  label="Свързване с данните от предходния месец"
                />
              )}
            />
          </FormGroup>

          {/*<FormGroup sx={{ ml: 4 }}>
            <Controller
              name="dont_ignore_leaves"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={!!field.value} size="small" />
                  }
                  label="Отчитане на отпуски и болнични"
                />
              )}
            />
          </FormGroup>

          <hr />*/}

          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Брой поредни работни дни:
            </Typography>
            <Controller
              name="workdays"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={1}
                  max={14}
                  step={1}
                  marks={[
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                    { value: 7, label: 7 },
                    { value: 14, label: 14 },
                  ]}
                  valueLabelDisplay="auto"
                  disableSwap
                  // ref={min_field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={(e, value) => {
                    if (
                      Array.isArray(value) &&
                      value.length === 2 &&
                      value[0] < value[1]
                    ) {
                      field.onChange(e);
                    }
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Максимален брой единични почивки за месеца:
            </Typography>
            <Controller
              name="max_single_rests"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={1}
                  max={10}
                  step={1}
                  marks={[
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                    { value: 7, label: 7 },
                    { value: 8, label: 8 },
                    { value: 9, label: 9 },
                    { value: 10, label: 10 },
                  ]}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Максимален брой почивки в събота и неделя:
            </Typography>
            <Controller
              name="max_weekend_rests"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={1}
                  max={5}
                  step={1}
                  marks={[
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                  ]}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Корекция на максималния брой поредни почивки:
            </Typography>
            <Controller
              name="max_rests_correction"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={0}
                  max={4}
                  step={1}
                  marks={[
                    { value: 0, label: 0 },
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                  ]}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 4 }}>
            <Controller
              name="hard_expl_plan"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={!!field.value} size="small" />
                  }
                  label="Твърдо покриване на експлоатационния план"
                />
              )}
            />
          </FormGroup>
          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Максимално разминаване на липсите за дните от месеца (+/-):
            </Typography>
            <Controller
              name="max_expl_plan_difference"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={0}
                  max={max_expl_plan_difference_values.length - 1}
                  step={1}
                  marks={max_expl_plan_difference_values.map(
                    (value, index) => ({
                      value: index,
                      label: value,
                    })
                  )}
                  disabled={!hard_expl_plan}
                  scale={(value) => max_expl_plan_difference_values[value]}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 3, mr: 3 }}>
            <Typography id="input-slider" variant="body2">
              Съотношение между липсите за празничен и делничен ден:
            </Typography>
            <Controller
              name="weekend_ratio"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  min={0}
                  max={9}
                  step={1}
                  disabled={!hard_expl_plan}
                  scale={weekendRatioValue}
                  marks={[
                    { value: 0, label: "ЕП" },
                    { value: 1, label: "2:1" },
                    { value: 2, label: "7:4" },
                    { value: 3, label: "3:2" },
                    { value: 4, label: "5:4" },
                    { value: 5, label: "1:1" },
                    { value: 6, label: "7:8" },
                    { value: 7, label: "3:4" },
                    { value: 8, label: "5:8" },
                    { value: 9, label: "1:2" },
                  ]}
                  valueLabelDisplay="off"
                  // valueLabelFormat={(value) =>
                  //   value === 0
                  //     ? "Експл. план"
                  //     : value < 0
                  //     ? `${-value}:1`
                  //     : `1:${value}`
                  // }
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>

          <FormGroup sx={{ ml: 4 }}>
            <Controller
              name="enable_ktd"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={!!field.value} size="small" />
                  }
                  label="Покриване на изискванията по кТД"
                />
              )}
            />
          </FormGroup>
          <FormGroup sx={{ ml: 3, mr: 3 }} style={{ marginBottom: 0 }}>
            <Typography id="input-slider" variant="body2">
              Процент водачи с покрито изискване за почивки по кТД:
            </Typography>
            <Controller
              name="ktd_coefficient"
              control={control}
              render={({ field }) => (
                <Slider
                  size="small"
                  disabled={!enable_ktd}
                  min={0}
                  max={1}
                  step={0.01}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 0.5, label: "50%" },
                    { value: 0.8, label: "80%" },
                    { value: 0.9, label: "90%" },
                    { value: 1, label: "100%" },
                  ]}
                  ref={field.ref}
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              reset(valuesFromGarageSettings(initialSettings));
            }}
            color="warning"
          >
            По подразбиране
          </Button>
          <Button
            onClick={() => {
              reset();
            }}
            color="warning"
          >
            Върни настройки
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button
            type="submit"
            color="success"
            disabled={!isValid || isLoading || isSubmitting || isValidating}
          >
            Започни
          </Button>
          <Button onClick={reject} color="primary">
            Изход
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
