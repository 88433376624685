import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useGetVehicleStatesQuery } from "../data/api/hooks";
import { Transport } from "../data/api/types/schedule";

const DEFAULT_STATE = 0;

export function VehicleStateDialog({
  open,
  initialValue,
  initialComment,
  heading,
  label,
  commentLabel,
  transport,
  onClose,
  onSave,
}: {
  open?: boolean;
  initialValue?: number;
  initialComment?: string | null;
  heading?: string;
  label?: string;
  commentLabel?: string;
  transport: Transport;
  onClose?: () => void;
  onSave?: (value: number, comment: string | null) => Promise<void>;
}) {
  const states = useGetVehicleStatesQuery(transport);

  const [value, setValue] = useState<number>(initialValue || DEFAULT_STATE);
  const [comment, setComment] = useState<string | null>(initialComment || "");
  const [errors, setErrors] = useState<string | null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  useEffect(() => {
    setValue(initialValue || DEFAULT_STATE);
    setComment(initialComment || "");
  }, [initialComment, initialValue]);

  const handleClose = useCallback(() => {
    if (!busy && !states.isLoading && !states.isFetching) {
      setValue(DEFAULT_STATE);
      setComment("");
      setErrors(null);
      setBusy(false);
      onClose && onClose();
    }
  }, [busy, onClose, states.isFetching, states.isLoading]);

  const handleSave = useCallback(async () => {
    if (!busy && !states.isLoading && !states.isFetching && onSave) {
      try {
        setErrors(null);
        setBusy(true);
        await onSave(value, comment && comment.trim() !== "" ? comment : null);
        handleClose();
      } catch {
        setErrors("Грешка при запис на състояние");
      } finally {
        setBusy(false);
      }
    } else {
      handleClose();
    }
  }, [
    busy,
    states.isLoading,
    states.isFetching,
    onSave,
    value,
    comment,
    handleClose,
  ]);

  return (
    <form>
      <Dialog open={!!open} onClose={onClose} fullWidth>
        <DialogTitle>Промяна на състояние</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {heading && heading.trim() !== "" ? heading : "Ново състояние"}
          </DialogContentText>
          {states.isSuccess ? (
            <Select
              labelId="vehicle-state-select-label"
              id="vehicle-state-select"
              value={value}
              label={label || "Състояние"}
              onChange={(e) =>
                setValue(
                  typeof e.target.value === "string"
                    ? parseInt(e.target.value, 10)
                    : e.target.value
                )
              }
            >
              {states.data.data
                .filter((s) => s.site_visible)
                .map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.description}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <CircularProgress />
          )}
          <TextField
            autoFocus
            multiline
            required
            minRows={5}
            margin="dense"
            id="name"
            label={commentLabel || "Коментар"}
            type="email"
            fullWidth
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={errors !== null}
            disabled={busy || states.isLoading || states.isFetching}
          />
          {errors && (
            <DialogContentText color="error">{`Грешка: ${errors}`}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={busy || states.isLoading || states.isFetching}
          >
            Отказ
          </Button>
          <Button
            onClick={handleSave}
            type="submit"
            disabled={busy || states.isLoading || states.isFetching}
          >
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
