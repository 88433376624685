import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../data/store";
import { Moment } from "moment";
import { Box, Container } from "@mui/system";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Portal,
} from "@mui/material";
import { TransportSelect } from "../../../filters/TransportSelect";
import { DepotSelect } from "../../../filters/DepotSelect";
import { AutoColumnSelect } from "../../../filters/AutoColumnSelect";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { useSubmenu } from "../../../hooks/useSubmenu";
import { Print } from "@mui/icons-material";
import { updateSelectedDate } from "../../../data/filtersSlice";
import { useVehicleRecapitulationReport } from "./useVehicleRecapitulationReport";
import { Transport } from "../../../data/api/types/driver";
import { usePrintVehicleRecapitulationReport } from "../../../printing/usePrintVehicleRecapitulationReport";
import React from "react";

function ReportContent({
  rows,
  vehicles_state,
  selectedDate,
  totalVehicleCount,
}: {
  rows: {
    [key: string]: {
      veh_id: number;
      veh_comment: string;
      state_comment: string;
    }[];
  };
  vehicles_state: {
    id: number;
    transport: Transport;
    description: string;
    desc_short: string;
    site_visible: boolean;
    category_id: number;
  }[];
  selectedDate: Moment;
  totalVehicleCount: number;
}) {
  const { rightToolbarMenu } = useSubmenu();
  const { exportPdf, isLoading: exportPdfLoading } =
    usePrintVehicleRecapitulationReport();

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() =>
            exportPdf(rows, vehicles_state, selectedDate, totalVehicleCount)
          }
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>

      <Paper sx={{ height: "auto", px: 20, py: 6 }}>
        <h2 style={{ padding: "10" }}>
          {`Разпределение на коли за ${selectedDate.format(
            "LL"
          )} - общо ${totalVehicleCount} бр.`}
        </h2>
        {Object.keys(rows).map((r) => (
          <div key={r}>
            <h3>
              {
                vehicles_state.find((s) => s.id === parseInt(r, 10))
                  ?.description
              }{" "}
              - {rows[r].length} бр.
            </h3>
            <>
              <p
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  marginLeft: "40px",
                }}
              >
                {rows[r]
                  .filter(
                    (s) =>
                      s.state_comment.length === 0 && s.veh_comment.length === 0
                  )
                  .map((v) => v.veh_id)
                  .join(", ")}
              </p>
              {Object.values(
                rows[r]
                  .filter(
                    (s) =>
                      s.state_comment.length > 0 || s.veh_comment.length > 0
                  )
                  .reduce<{
                    [key: string]: {
                      description: string;
                      veh_id: number;
                    }[];
                  }>((acc, x) => {
                    if (acc[x.state_comment + x.veh_comment] === undefined) {
                      acc[x.state_comment + x.veh_comment] = [
                        {
                          description: x.state_comment + " " + x.veh_comment,
                          veh_id: x.veh_id,
                        },
                      ];
                    } else {
                      acc[x.state_comment + x.veh_comment].push({
                        description: x.state_comment + " " + x.veh_comment,
                        veh_id: x.veh_id,
                      });
                    }
                    return acc;
                  }, {})
              ).map((el) => (
                <React.Fragment key={el[0].description}>
                  <h4
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      marginLeft: "40px",
                      display: "inline",
                    }}
                  >
                    {
                      // vehicles_state.find((s) => s.id === parseInt(r, 10))
                      //   ?.description +
                      //   " - " +
                      el[0].description
                    }
                    {el[0].description !== "" ? `: ` : ""}
                    <>&nbsp;</>
                  </h4>
                  <p
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline",
                    }}
                  >
                    {el.map((v) => v.veh_id).join(", ")}
                    <br />
                  </p>
                </React.Fragment>
              ))}
            </>

            {/* {r !== "6" && (
              <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                {rows[r].map((v) => v.veh_id).join(", ")}
              </p>
            )} */}
          </div>
        ))}
      </Paper>
    </>
  );
}

export function VehicleRecapitulationReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const { vehicles, rows, totalVehicleCount, states } =
    useVehicleRecapitulationReport(
      selectedTransport,
      selectedDepot,
      selectedDate,
      selectedAvtoColumn,
      []
    );

  const dispatch = useAppDispatch();
  // console.log("VehRows:", rows);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container>
        <Grid container spacing={2} mt={1}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsAutocolumnSelect ? (
            <Grid item xs={12} sm={2}>
              <AutoColumnSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={2}>
            <DatePicker
              value={moment(selectedDate)}
              onChange={(e) =>
                e &&
                e.isValid() &&
                dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
              }
              label="Дата"
              views={["year", "month", "day"]}
              openTo="day"
              slotProps={{
                textField: {
                  variant: "standard",
                  sx: { m: 1, width: "100%" },
                },
              }}
            />
          </Grid>
        </Grid>

        {/* {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />} */}
      </Container>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Backdrop
          open={false} //{isLoading}
          sx={{
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 1,
              marginBottom: 4,
            }}
          />
        </Backdrop>
        <Container sx={{ py: 4 }}>
          <>
            {rows &&
              //  !isError &&
              vehicles &&
              states?.data &&
              totalVehicleCount &&
              rows && (
                <ReportContent
                  rows={rows}
                  vehicles_state={states.data.data}
                  totalVehicleCount={totalVehicleCount}
                  selectedDate={moment(selectedDate)}
                />
              )}
            {!rows ||
              (Object.keys(rows).length === 0 && (
                <div>
                  <Alert severity="info"> Няма данни за избраната дата!</Alert>
                </div>
              ))}
          </>
        </Container>
      </Box>
    </Box>
  );
}
