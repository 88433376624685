import { Close, Print } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  PaperProps,
  Switch,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Moment } from "moment";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

export interface PrintScheduleDialogOptions {
  startDate: string;
  endDate: string;
  additionalData: boolean;
}

function getIsWholeMonth(
  selectedMonth: string,
  startDate: Moment,
  endDate: Moment
) {
  const monthStart = moment(selectedMonth + "-01", "YYYY-MM-DD");
  const monthEnd = monthStart.clone().endOf("month").startOf("day");
  return (
    startDate.isSameOrBefore(monthStart) && endDate.isSameOrAfter(monthEnd)
  );
}

export function PrintScheduleDialog({
  open,
  startDate,
  endDate,
  printAdditionalData,
  selectedMonth,
  setStartDate,
  setEndDate,
  setPrintAdditionalData,
  handleCancel,
  handleConfirm,
}: {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: (options: PrintScheduleDialogOptions) => Promise<void>;
  startDate: Moment;
  endDate: Moment;
  printAdditionalData: boolean;
  selectedMonth: string;
  setStartDate: (value: Moment) => void;
  setEndDate: (value: Moment) => void;
  setPrintAdditionalData: (value: boolean) => void;
}) {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    if (!busy) {
      handleCancel();
    }
  }, [busy, handleCancel]);

  const [minDate, maxDate] = useMemo(() => {
    const monthStart = moment(selectedMonth + "-01", "YYYY-MM-DD");
    const monthEnd = monthStart.clone().endOf("month").startOf("day");
    return [monthStart, monthEnd];
  }, [selectedMonth]);

  const isWholeMonth = useMemo(
    () => getIsWholeMonth(selectedMonth, startDate, endDate),
    [endDate, selectedMonth, startDate]
  );

  useEffect(() => {
    if (!isWholeMonth) {
      setPrintAdditionalData(false);
    }
  }, [isWholeMonth, setPrintAdditionalData]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setBusy(true);
      setError(false);
      handleConfirm({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        additionalData: getIsWholeMonth(selectedMonth, startDate, endDate)
          ? printAdditionalData
          : false,
      })
        .then(() => {
          console.log("Closed");
          handleCancel();
        })
        .catch((err) => {
          console.warn("Error printing:", err);
          setError(true);
        })
        .finally(() => setBusy(false));
    },
    [
      endDate,
      handleCancel,
      handleConfirm,
      printAdditionalData,
      selectedMonth,
      startDate,
    ]
  );

  const dialogPaper = useMemo<Partial<PaperProps<"form">>>(
    () => ({
      component: "form",
      onSubmit: handleSubmit,
    }),
    [handleSubmit]
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={dialogPaper as Partial<PaperProps<"div">>}
    >
      <DialogTitle>Печат на график</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Моля изберете период за отпечатания график:
        </DialogContentText>
        <DialogContentText
          display={error ? "block" : "none"}
          color="error"
          mt={4}
        >
          Грешка при отпечатване на график!
        </DialogContentText>
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid xs={12} sm={6}>
            <DatePicker
              value={startDate}
              disabled={busy}
              onChange={(e) => e && e.isValid() && setStartDate(e)}
              label="Начална дата"
              views={["day"]}
              openTo="day"
              minDate={minDate}
              maxDate={endDate.isSameOrBefore(maxDate) ? endDate : maxDate}
              sx={{ m: 1, width: "100%" }}
              slotProps={{
                textField: {
                  variant: "standard",
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <DatePicker
              value={endDate}
              disabled={busy}
              onChange={(e) => e && e.isValid() && setEndDate(e)}
              label="Крайна дата"
              views={["day"]}
              openTo="day"
              minDate={startDate.isSameOrAfter(minDate) ? startDate : minDate}
              maxDate={maxDate}
              sx={{ m: 1, width: "100%" }}
              slotProps={{
                textField: {
                  variant: "standard",
                },
              }}
            />
          </Grid>
          <Grid xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled={busy || !isWholeMonth}
                    checked={printAdditionalData}
                    onChange={(e) => setPrintAdditionalData(e.target.checked)}
                  />
                }
                label="С включена допълнителна информация"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          startIcon={<Close />}
          color="error"
          disabled={busy}
        >
          Затвори
        </Button>
        <Button type="submit" startIcon={<Print />} disabled={busy}>
          Отпечатай
        </Button>
      </DialogActions>
      <Backdrop
        open={busy}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
