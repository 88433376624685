import { ItemContainer } from "../ItemContainer";
import { Fragment, useCallback, useMemo, useState } from "react";
import { grey } from "@mui/material/colors";
import { Box, SxProps, Theme } from "@mui/material";
import { Vehicle } from "../../../data/api/types/driver";
import {
  ElectroRoster,
  ElectroRosterCar,
  ElectroRosterVehicle,
} from "../types/ElectroRoster";
import { ElectroDraggableVehicleItem } from "./ElectroDraggableVehicleItem";
import { useDrop } from "react-dnd";
import { useSchedules } from "../../../data/schedules/useSchedules";
import { ElectroVehicleItem } from "./ElectroVehicleItem";
import moment from "moment";
import { Roster } from "../types/Roster";
import { callWithErrors } from "./ElectroCarItem";
import { ScheduleDriver } from "../../../data/api/types/schedule";

export function useElectroDropOnVehicles(
  rosterCars: readonly ElectroRosterCar[],
  selectedDate: string,
  isReadOnly: boolean,
  isVehiclesOnly: boolean
) {
  const { electroRemoveVehicleFromShift } = useSchedules();

  const [errors, setErrors] = useState<string[]>([]);
  const createErrors = useCallback(
    (
      result: {
        status: "error";
        errors: { error_type?: string | null; message?: string | null }[];
      },
      vehicle: ElectroRosterVehicle
    ) => {
      // console.log("Creating errors for:", result, vehicle);
      const errorMessages = result.errors.map((e) => {
        if (e.message && e.message !== "") {
          return e.message;
        } else {
          return `Неизвестна грешка за превозно средство ${vehicle.vehicle_id}`;
        }
      });
      if (errorMessages.length > 0) {
        setErrors(errorMessages);
      } else {
        setErrors([
          `Неизвестна грешка за превозно средство ${vehicle.vehicle_id}`,
        ]);
      }
    },
    []
  );

  const clearErrors = useCallback(() => setErrors([]), []);

  const dropVehicle = useCallback(
    (vehicle: ElectroRosterVehicle) => {
      if (
        vehicle.vehicle.days[selectedDate]?.state?.category === 2 ||
        (isReadOnly && !isVehiclesOnly)
      ) {
        return;
      }

      console.log("Drop on vehicles", vehicle, rosterCars);

      const fromCars = rosterCars.filter(
        (car) =>
          car.shift1?.vehicle?.vehicle_id === vehicle.vehicle_id ||
          car.shift2?.vehicle?.vehicle_id === vehicle.vehicle_id
      );
      for (const car of fromCars) {
        console.log("Dropped", vehicle, "from", car);

        if (
          car.shift1?.vehicle?.vehicle_id === vehicle.vehicle_id &&
          car.shift2?.vehicle?.vehicle_id === vehicle.vehicle_id
        ) {
          callWithErrors(
            electroRemoveVehicleFromShift,
            {
              date: selectedDate,
              shift1_id: car.shift1.shift.shift.workshift.id,
              shift2_id: car.shift2.shift.shift.workshift.id,
              veh1_id: car.shift1.vehicle.vehicle_id,
            },
            vehicle,
            createErrors,
            clearErrors
          );
        } else if (car.shift1?.vehicle?.vehicle_id === vehicle.vehicle_id) {
          callWithErrors(
            electroRemoveVehicleFromShift,
            {
              date: selectedDate,
              shift1_id: car.shift1.shift.shift.workshift.id,
              shift2_id: null,
              veh1_id: car.shift1.vehicle.vehicle_id,
            },
            vehicle,
            createErrors,
            clearErrors
          );
        } else if (car.shift2?.vehicle?.vehicle_id === vehicle.vehicle_id) {
          callWithErrors(
            electroRemoveVehicleFromShift,
            {
              date: selectedDate,
              shift1_id: null,
              shift2_id: car.shift2.shift.shift.workshift.id,
              veh1_id: car.shift2.vehicle.vehicle_id,
            },
            vehicle,
            createErrors,
            clearErrors
          );
        }
      }
    },
    [
      clearErrors,
      createErrors,
      electroRemoveVehicleFromShift,
      isReadOnly,
      isVehiclesOnly,
      rosterCars,
      selectedDate,
    ]
  );

  return { dropVehicle, errors, clearErrors };
}

export function ElectroDroppableVehiclesContainer({
  drivers,
  selectedDate,
  title,
  headerSx,
  apiVehicles,
  allFilteredVehicles,
  selectedLines,
  showVehicleStateDialog,
  showVehicleCommentDialog,
  showCompositionDialog,
  clearVehicleState,
  isReadOnly,
  isVehiclesOnly,
  isPreliminaryReady,
  setErrors,
  getRoster,
  onDrop,
}: {
  roster: ElectroRoster;
  drivers: readonly ScheduleDriver[];
  selectedDate: string;
  title?: string;
  headerSx?: SxProps<Theme>;
  apiVehicles: readonly Vehicle[];
  allFilteredVehicles: readonly ElectroRosterVehicle[];
  selectedLines: readonly number[];
  showVehicleCommentDialog: (vehicle: ElectroRosterVehicle) => void;
  showVehicleStateDialog: (vehicle: ElectroRosterVehicle) => void;
  showCompositionDialog: (vehicle: ElectroRosterVehicle) => void;
  clearVehicleState: (vehicle: ElectroRosterVehicle) => void;
  isReadOnly: boolean;
  isVehiclesOnly: boolean;
  isPreliminaryReady: boolean;
  setErrors: (errors: readonly string[]) => void;
  getRoster: () => Roster;
  onDrop: (vehicle: ElectroRosterVehicle) => void;
}) {
  const filteredVehicles = useMemo(() => {
    const unordered = allFilteredVehicles.filter((v) => {
      // console.log("FV");
      const isMainVehicle = !v.composition || v.composition.is_master;
      if (isMainVehicle) {
        if (selectedLines.length === 0) {
          return true;
        } else {
          const yesterday = moment(selectedDate, "YYYY-MM-DD")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          const day = v.vehicle.days[yesterday];
          if (day) {
            const hasLine = day.daytasks.find(
              (dt) => dt.line_id && selectedLines.includes(dt.line_id)
            );
            return day.daytasks.length === 0 || !!hasLine;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    });

    return unordered;
  }, [allFilteredVehicles, selectedDate, selectedLines]);

  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept:
        (isReadOnly && !isVehiclesOnly) || !isPreliminaryReady
          ? []
          : ["electro-vehicle-item"],
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [isReadOnly, isVehiclesOnly, isPreliminaryReady, onDrop]
  );

  const isActive = isOver && canDrop;

  return (
    <ItemContainer
      ref={drop}
      title={title}
      headerSx={headerSx}
      isOver={isActive}
      sx={{ height: "100%" }}
      contentSx={{ px: 3 }}
    >
      <Box sx={{}}>
        {filteredVehicles.map((vehicle, idx) => {
          const previousType = filteredVehicles[idx - 1]?.vehicletype_name;
          const type = vehicle.vehicletype_name;

          if (previousType !== type) {
            return (
              <Fragment key={`vehicle-${vehicle.vehicle_id}`}>
                <Box
                  sx={{
                    width: "calc(100% - 7px)",
                    backgroundColor: grey[200],
                    border: "1px solid black",
                    borderRadius: 0.75,
                    p: 1,
                    mx: "5px",
                    mt: 2,
                    mb: 0.75,
                  }}
                >
                  {type || "Неизвестна"}
                </Box>
                <Box
                  key={`vehicle-${vehicle.vehicle_id}`}
                  sx={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    mx: "2px",
                    overflow: "hidden",
                  }}
                >
                  {vehicle.vehicle.days[selectedDate]?.state?.category === 2 ||
                  (isReadOnly && !isVehiclesOnly) ||
                  !isPreliminaryReady ? (
                    <ElectroVehicleItem
                      isReadOnly={isReadOnly || !isPreliminaryReady}
                      isVehiclesOnly={isVehiclesOnly}
                      drivers={drivers}
                      vehicle={vehicle}
                      selectedDate={selectedDate}
                      apiVehicles={apiVehicles}
                      showVehicleCommentDialog={showVehicleCommentDialog}
                      showVehicleStateDialog={showVehicleStateDialog}
                      showCompositionDialog={showCompositionDialog}
                      clearVehicleState={clearVehicleState}
                      setErrors={setErrors}
                      getRoster={getRoster}
                    />
                  ) : (
                    <ElectroDraggableVehicleItem
                      isReadOnly={isReadOnly || !isPreliminaryReady}
                      isVehiclesOnly={isVehiclesOnly}
                      drivers={drivers}
                      vehicle={vehicle}
                      selectedDate={selectedDate}
                      apiVehicles={apiVehicles}
                      showVehicleCommentDialog={showVehicleCommentDialog}
                      showVehicleStateDialog={showVehicleStateDialog}
                      showCompositionDialog={showCompositionDialog}
                      clearVehicleState={clearVehicleState}
                      setErrors={setErrors}
                      getRoster={getRoster}
                    />
                  )}
                </Box>
              </Fragment>
            );
          } else {
            return (
              <Box
                key={`vehicle-${vehicle.vehicle_id}`}
                sx={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  mx: "2px",
                  overflow: "hidden",
                }}
              >
                {vehicle.vehicle.days[selectedDate]?.state?.category === 2 ||
                (isReadOnly && !isVehiclesOnly) ||
                !isPreliminaryReady ? (
                  <ElectroVehicleItem
                    isReadOnly={isReadOnly || !isPreliminaryReady}
                    isVehiclesOnly={isVehiclesOnly}
                    drivers={drivers}
                    vehicle={vehicle}
                    selectedDate={selectedDate}
                    apiVehicles={apiVehicles}
                    showVehicleCommentDialog={showVehicleCommentDialog}
                    showVehicleStateDialog={showVehicleStateDialog}
                    showCompositionDialog={showCompositionDialog}
                    clearVehicleState={clearVehicleState}
                    setErrors={setErrors}
                    getRoster={getRoster}
                  />
                ) : (
                  <ElectroDraggableVehicleItem
                    isReadOnly={isReadOnly || !isPreliminaryReady}
                    isVehiclesOnly={isVehiclesOnly}
                    drivers={drivers}
                    vehicle={vehicle}
                    selectedDate={selectedDate}
                    apiVehicles={apiVehicles}
                    showVehicleCommentDialog={showVehicleCommentDialog}
                    showVehicleStateDialog={showVehicleStateDialog}
                    showCompositionDialog={showCompositionDialog}
                    clearVehicleState={clearVehicleState}
                    setErrors={setErrors}
                    getRoster={getRoster}
                  />
                )}
              </Box>
            );
          }
        })}
      </Box>
    </ItemContainer>
  );
}
