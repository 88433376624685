import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export function CommentDialog({
  dialog,
  comment,
  commentErrors,
  commentBusy,
  setComment,
  onChangeComment,
  onCloseDialog,
}: {
  dialog: {
    sl_number?: number;
    initial_comment: string | null;
    //date: moment.Moment;
    //driver_id: string;
  } | null;
  comment: string | null;
  commentErrors: string | null;
  commentBusy: boolean;
  setComment: (comment: string | null) => void;
  onChangeComment: () => Promise<void>;
  onCloseDialog: () => void;
}) {
  return (
    <form>
      <Dialog open={!!dialog} onClose={onCloseDialog} fullWidth>
        <DialogTitle>
          {dialog?.initial_comment
            ? "Промяна на коментар"
            : "Добавяне на коментар"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{`${
            dialog?.initial_comment
              ? "Промяна на коментар"
              : "Добавяне на коментар"
          }${
            dialog?.sl_number ? ` за водач ${dialog.sl_number}` : ""
          }`}</DialogContentText>
          <TextField
            autoFocus
            multiline
            required
            minRows={5}
            margin="dense"
            id="name"
            label="Коментар"
            type="email"
            fullWidth
            variant="outlined"
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
            error={commentErrors !== null}
            disabled={commentBusy}
          />
          {commentErrors && (
            <DialogContentText color="error">{`Грешка: ${commentErrors}`}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} disabled={commentBusy}>
            Отказ
          </Button>
          <Button
            onClick={onChangeComment}
            type="submit"
            disabled={commentBusy}
          >
            Запиши
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
