import { useDrag } from "react-dnd";
import {
  ElectroVehicleItem,
  ElectroVehicleItemProps,
} from "./ElectroVehicleItem";

export function ElectroDraggableVehicleItem({
  vehicle,
  style,
  ...props
}: ElectroVehicleItemProps) {
  const [dragStyle, drag] = useDrag(
    () => ({
      type: "electro-vehicle-item",
      item: vehicle,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [vehicle]
  );

  return (
    <ElectroVehicleItem
      vehicle={vehicle}
      {...props}
      ref={drag}
      style={{ ...style, ...dragStyle }}
    />
  );
}
