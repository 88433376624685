import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Portal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadTypeMap,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useGetDriversListQuery } from "../../data/api/hooks";
import { RootState } from "../../data/store";
import { useSelector } from "react-redux";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { DriverSkills, Vehicle } from "../../data/api/types/driver";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import {
  Data,
  DriverFilter,
  WorkBySheduleList,
  columns,
  createData,
  shiftList,
  useDriversList,
} from "./useDriversList";
import {
  FieldArrayWithId,
  UseFormRegister,
  Control,
  Controller,
} from "react-hook-form";

import { Form } from "react-router-dom";
import { isObject } from "@mui/x-data-grid/internals";
import ClearIcon from "@mui/icons-material/Clear";
import { QueryError } from "../QueryError";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { green, red } from "@mui/material/colors";
import { Print } from "@mui/icons-material";
import { usePrintDriversPdfExporterer } from "../../printing/usePrintDriversPdfExporter";
import { useSubmenu } from "../../hooks/useSubmenu";
import { useProfile } from "../../common/useProfile";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const VirtuosoTableComponents: TableComponents<
  FieldArrayWithId<
    {
      drivers: Data[];
    },
    "drivers",
    "id"
  >
> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef<
    HTMLTableSectionElement,
    TableHeadTypeMap["props"] & { style?: React.CSSProperties }
  >(({ style, ...props }, ref) => (
    <TableHead {...props} style={{ ...style, zIndex: 5 }} ref={ref} />
  )), //TableHead,
  TableRow: ({ ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function TableContenet({
  fields,
  filteredData,
  register,
  vehicles,
  control,
  skills,
  filter,
  setFilter,
}: {
  fields: FieldArrayWithId<{ drivers: Data[] }, "drivers", "id">[];
  filteredData: FieldArrayWithId<{ drivers: Data[] }, "drivers", "id">[];
  register: UseFormRegister<{
    drivers: Data[];
  }>;
  vehicles: Vehicle[];
  control: Control<{ drivers: Data[] }, any>;
  skills: DriverSkills;
  filter: DriverFilter | null;
  setFilter: React.Dispatch<React.SetStateAction<DriverFilter | null>>;
}) {
  const profile = useProfile();
  // console.log("Vehicles", vehicles);

  return (
    <Paper sx={{ height: "100%" }}>
      {/* <Box sx={{ height: "100%" }}> */}

      <TableContainer sx={{ height: "100%" }}>
        {/* <Table stickyHeader aria-label="sticky table"> */}
        <TableVirtuoso
          data={filteredData}
          components={VirtuosoTableComponents}
          totalCount={filteredData.length}
          fixedHeaderContent={() => (
            <React.Fragment>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant="head"
                    style={{
                      tableLayout: "fixed",
                      position: "sticky",
                      zIndex: idx === 0 ? "5" : "4",
                      left: idx === 0 ? "0" : "",
                      width: column.minWidth,
                    }}
                    sx={{
                      backgroundColor: "background.paper",
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant="head"
                    // style={{ width: column.minWidth }}
                    style={{
                      tableLayout: "fixed",
                      position: "sticky",
                      zIndex: idx === 0 ? "5" : "4",
                      left: idx === 0 ? "0" : "",
                      width: column.minWidth,
                    }}
                    sx={{
                      backgroundColor: "background.paper",
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.id === "skills" && skills && (
                      <Select
                        fullWidth
                        labelId="description" //{`drivers.${index}.skills`}
                        variant="standard"
                        value={filter?.skills ? filter.skills.split(", ") : []}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.skills ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              size="small"
                              onClick={() =>
                                setFilter(
                                  filter ? { ...filter, skills: null } : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          const v: DriverFilter | null = {
                            skills:
                              typeof value === "string"
                                ? value
                                : value.join(", "),
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          setFilter(v);
                        }}
                        multiple
                      >
                        {skills.map((name) => (
                          <MenuItem key={name.id} value={name.description}>
                            {name.description}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {column.id === "color_id" && (
                      <Select
                        fullWidth
                        value={filter?.color_id || 0}
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.color_id ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              onClick={() =>
                                setFilter(
                                  filter ? { ...filter, color_id: null } : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: data.target.value
                              ? data.target.value.toString()
                              : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };

                          setFilter(v);
                        }}
                      >
                        <MenuItem value="0" />
                        <MenuItem value="1">
                          <Avatar
                            alt="Червен"
                            sx={{
                              bgcolor: red[400],
                              width: 20,
                              height: 20,
                              fontSize: 12,
                            }}
                          >
                            Ч
                          </Avatar>
                        </MenuItem>
                        <MenuItem value="2">
                          <Avatar
                            alt="Зелен"
                            sx={{
                              bgcolor: green[400],
                              width: 20,
                              height: 20,
                              fontSize: 12,
                            }}
                          >
                            З
                          </Avatar>
                        </MenuItem>
                      </Select>
                    )}
                    {column.id === "shift" && (
                      <Select
                        fullWidth
                        value={filter?.shift ? filter?.shift : 0}
                        //  id={filter?.shift}

                        variant="standard"
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.shift ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              onClick={() =>
                                setFilter(
                                  filter ? { ...filter, shift: null } : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: data.target.value
                              ? data.target.value.toString()
                              : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };

                          setFilter(v);
                        }}
                      >
                        {shiftList.map((name) => (
                          <MenuItem key={name.id} value={name.id}>
                            {name.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {column.id === "work_by_schedule" && (
                      <Select
                        fullWidth
                        value={
                          filter?.work_by_schedule
                            ? filter?.work_by_schedule
                            : ""
                        }
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.work_by_schedule ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              onClick={() =>
                                setFilter(
                                  filter
                                    ? { ...filter, work_by_schedule: null }
                                    : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: data.target.value
                              ? data.target.value.toString()
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      >
                        {WorkBySheduleList.map((name) => (
                          <MenuItem key={name.id} value={name.id}>
                            {name.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "no_overtime" && (
                      <Select
                        fullWidth
                        value={filter?.no_overtime ? filter?.no_overtime : ""}
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.no_overtime ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              onClick={() =>
                                setFilter(
                                  filter
                                    ? { ...filter, no_overtime: null }
                                    : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: data.target.value
                              ? data.target.value.toString()
                              : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      >
                        {WorkBySheduleList.map((name) => (
                          <MenuItem key={name.id} value={name.id}>
                            {name.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "rest_on_weekends" && (
                      <Select
                        fullWidth
                        value={
                          filter?.rest_on_weekends
                            ? filter?.rest_on_weekends
                            : ""
                        }
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                display: filter?.rest_on_weekends ? "" : "none",
                                p: 1,
                                mr: 1,
                              }}
                              onClick={() =>
                                setFilter(
                                  filter
                                    ? { ...filter, rest_on_weekends: null }
                                    : null
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: data.target.value
                              ? data.target.value.toString()
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      >
                        {WorkBySheduleList.map((name) => (
                          <MenuItem key={name.id} value={name.id}>
                            {name.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "license_expire" && (
                      <DatePicker
                        value={
                          filter?.license_expire
                            ? moment(filter.license_expire, "DD-MM-YYYY")
                            : null
                        }
                        label="Изтича преди:"
                        views={["year", "month", "day"]}
                        openTo="day"
                        slotProps={{
                          textField: {
                            variant: "standard",
                            sx: { m: 1, marginBottom: 5 },
                          },
                          actionBar: { actions: ["clear"] },
                        }}
                        format="DD.MM.YYYY"
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: data
                              ? data.format("DD.MM.YYYY")
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      />
                    )}

                    {column.id === "comptenence_expire" && (
                      <DatePicker
                        value={
                          filter?.comptenence_expire
                            ? moment(filter.comptenence_expire, "DD-MM-YYYY")
                            : null
                        }
                        label="Изтича преди:"
                        views={["year", "month", "day"]}
                        openTo="day"
                        slotProps={{
                          textField: {
                            variant: "standard",
                            sx: { m: 1, marginBottom: 5 },
                          },
                          actionBar: { actions: ["clear"] },
                        }}
                        format="DD.MM.YYYY"
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: data
                              ? data.format("DD.MM.YYYY")
                              : null,
                            psiho_test_expire: filter
                              ? filter.psiho_test_expire
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      />
                    )}

                    {column.id === "psiho_test_expire" && (
                      <DatePicker
                        value={
                          filter?.psiho_test_expire
                            ? moment(filter.psiho_test_expire, "DD-MM-YYYY")
                            : null
                        }
                        label="Изтича преди:"
                        views={["year", "month", "day"]}
                        openTo="day"
                        slotProps={{
                          textField: {
                            variant: "standard",
                            sx: { m: 1, marginBottom: 5 },
                          },
                          actionBar: { actions: ["clear"] },
                        }}
                        format="DD.MM.YYYY"
                        onChange={(data) => {
                          const v: DriverFilter = {
                            skills: filter ? filter.skills : null,
                            driver_id: filter ? filter.driver_id : null,
                            name: filter ? filter.name : null,
                            work_by_schedule: filter
                              ? filter.work_by_schedule
                              : null,
                            shift: filter ? filter.shift : null,
                            vehicle: filter ? filter.vehicle : null,
                            color_id: filter ? filter.color_id : null,
                            license_expire: filter
                              ? filter.license_expire
                              : null,
                            comptenence_expire: filter
                              ? filter.comptenence_expire
                              : null,
                            psiho_test_expire: data
                              ? data.format("DD.MM.YYYY")
                              : null,
                            no_overtime: filter ? filter.no_overtime : null,
                            quota_override: filter
                              ? filter.quota_override
                              : null,
                            rest_on_weekends: filter
                              ? filter.rest_on_weekends
                              : null,
                            category_desc: filter ? filter.category_desc : null,
                          };
                          // console.log("Filter:", v);
                          setFilter(v);
                        }}
                      />
                    )}

                    {column.id !== "skills" &&
                      column.id !== "work_by_schedule" &&
                      column.id !== "shift" &&
                      column.id !== "color_id" &&
                      column.id !== "avtokolona" &&
                      column.id !== "license_expire" &&
                      column.id !== "comptenence_expire" &&
                      column.id !== "psiho_test_expire" &&
                      column.id !== "no_overtime" &&
                      column.id !== "rest_on_weekends" && (
                        <TextField
                          variant="standard"
                          value={
                            filter && filter[column.id] ? filter[column.id] : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  sx={{
                                    display:
                                      filter && filter[column.id] ? "" : "none",
                                    p: 1,
                                    mr: 1,
                                  }}
                                  onClick={() => {
                                    const v: DriverFilter = filter
                                      ? { ...filter }
                                      : {
                                          skills: null,
                                          driver_id: null,
                                          name: null,
                                          work_by_schedule: null,
                                          shift: null,
                                          vehicle: null,
                                          color_id: null,
                                          license_expire: null,
                                          comptenence_expire: null,
                                          psiho_test_expire: null,
                                          no_overtime: null,
                                          quota_override: null,
                                          rest_on_weekends: null,
                                          category_desc: null,
                                        };
                                    if (column.id !== "avtokolona")
                                      v[column.id] = null;
                                    setFilter(v);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(data) => {
                            const v: DriverFilter = filter
                              ? {
                                  skills: filter.skills,
                                  driver_id: filter.driver_id,
                                  name: filter.name,
                                  work_by_schedule: filter.work_by_schedule,
                                  shift: filter.shift,
                                  vehicle: filter.vehicle,
                                  color_id: filter.color_id,
                                  license_expire: filter.license_expire,
                                  comptenence_expire: filter.comptenence_expire,
                                  psiho_test_expire: filter.psiho_test_expire,
                                  no_overtime: filter.no_overtime,
                                  quota_override: filter.quota_override,
                                  rest_on_weekends: filter.rest_on_weekends,
                                  category_desc: filter.category_desc,
                                }
                              : {
                                  skills: null,
                                  driver_id: null,
                                  name: null,
                                  work_by_schedule: null,
                                  shift: null,
                                  vehicle: null,
                                  color_id: null,
                                  license_expire: null,
                                  comptenence_expire: null,
                                  psiho_test_expire: null,
                                  no_overtime: null,
                                  quota_override: null,
                                  rest_on_weekends: null,
                                  category_desc: null,
                                };

                            // column.id === "driver_id"
                            //   ? (v["driver_id"] = data.target.value)
                            //   : column.id === "name"
                            //   ? (v["name"] = data.target.value)
                            //   : (v["vehicle"] = data.target.value);
                            switch (column.id) {
                              case "driver_id":
                                v.driver_id = data.target.value;
                                break;
                              case "name":
                                v.name = data.target.value;
                                break;
                              case "vehicle":
                                v.vehicle = data.target.value;
                                break;
                              case "license_expire":
                                v.license_expire = data.target.value;
                                break;

                              case "category_desc":
                                v.category_desc = data.target.value;
                                break;
                              case "quota_override":
                                v.quota_override = data.target.value;
                                break;
                            }

                            // console.log("Filter:", v);
                            setFilter(v);
                          }}
                        ></TextField>
                      )}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          )}
          // itemContent={rowContent}

          itemContent={(index, data) => {
            {
              const idx = fields.findIndex(
                (x) => x.driver_id === filteredData[index].driver_id
              );

              if (idx !== -1)
                return (
                  <React.Fragment key={data.driver_id}>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          tableLayout: "fixed",
                          position: "sticky",
                          zIndex: index === 0 ? "2" : "",
                          left: index === 0 ? "0" : "",
                          width: column.minWidth,
                          background: "white",
                        }}
                        // padding="none"
                        {...register(`drivers.${idx}.${column.id}`)}
                        //style={ }
                      >
                        {column.id === "vehicle" ? (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.vehicle`}
                            render={({ field: { onChange, ...field } }) => (
                              <Autocomplete
                                options={
                                  vehicles
                                    ? [
                                        "",
                                        ...vehicles.map((v) =>
                                          v.vehicle_id.toString()
                                        ),
                                      ]
                                    : []
                                }
                                {...field}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label=""
                                    variant="standard"
                                  />
                                )}
                                onChange={(_, data) => {
                                  onChange(data);
                                  return data;
                                }}
                                noOptionsText={""}
                                getOptionLabel={(option) =>
                                  option ? option : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                autoSelect
                                readOnly={
                                  !profile?.roles?.includes("edit_schedule")
                                }
                              />
                            )}
                          />
                        ) : column.id === "work_by_schedule" ? (
                          <input
                            style={{ alignContent: "center" }}
                            type="checkbox"
                            {...register(`drivers.${idx}.work_by_schedule`)}
                            disabled={
                              !profile?.roles?.includes("edit_schedule")
                            }
                          />
                        ) : column.id === "no_overtime" ? (
                          <input
                            style={{ alignContent: "center" }}
                            type="checkbox"
                            {...register(`drivers.${idx}.no_overtime`)}
                            disabled={
                              !profile?.roles?.includes("edit_schedule")
                            }
                          />
                        ) : column.id === "rest_on_weekends" ? (
                          <input
                            style={{ alignContent: "center" }}
                            type="checkbox"
                            {...register(`drivers.${idx}.rest_on_weekends`)}
                            disabled={
                              !profile?.roles?.includes("edit_schedule")
                            }
                          />
                        ) : column.id === "shift" ? (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.shift`}
                            render={({ field: { onChange, ...field } }) => (
                              <Select
                                readOnly={
                                  !profile?.roles?.includes("edit_schedule")
                                }
                                fullWidth
                                id={`drivers.${idx}.shift`}
                                labelId={`drivers.${idx}.shift`}
                                variant="standard"
                                {...field}
                                onChange={(data) => {
                                  onChange(data);
                                  return data;
                                }}
                              >
                                {shiftList.map((name) => (
                                  <MenuItem
                                    key={name.id}
                                    value={name.id}
                                    onChange={(data) => {
                                      onChange(data);
                                      return data;
                                    }}
                                  >
                                    {name.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        ) : (
                          column.id === "skills" && (
                            <Controller
                              control={control}
                              name={`drivers.${idx}.skills`}
                              render={({
                                field: { onChange, value, ...field },
                              }) => (
                                <Select
                                  readOnly={
                                    !profile?.roles?.includes("edit_schedule")
                                  }
                                  fullWidth
                                  id={`drivers.${idx}.skills`}
                                  labelId="description" //{`drivers.${index}.skills`}
                                  variant="standard"
                                  multiple
                                  value={
                                    value
                                      ? Object.values(value).map((value) =>
                                          isObject(value) ? value.id : value
                                        )
                                      : []
                                  }
                                  {...field}
                                  onChange={(data) => {
                                    onChange(data);

                                    return data;
                                  }}
                                >
                                  {skills.map((name) => (
                                    <MenuItem
                                      key={name.id}
                                      value={name.id}
                                      onChange={(data) => {
                                        onChange(data);
                                        return data;
                                      }}
                                    >
                                      {name.description}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          )
                        )}

                        {column.id === "color_id" && (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.color_id`}
                            render={({
                              field: { onChange, value, ...field },
                            }) => (
                              <Select
                                readOnly={
                                  !profile?.roles?.includes("edit_schedule")
                                }
                                fullWidth
                                id={`drivers.${idx}.color_id`}
                                labelId="color_id"
                                variant="standard"
                                value={value}
                                {...field}
                                onChange={(data) => {
                                  onChange(data);
                                  return data;
                                }}
                              >
                                <MenuItem value="0" />
                                <MenuItem value="1">
                                  <Avatar
                                    alt="Червен"
                                    sx={{
                                      bgcolor: red[400],
                                      width: 20,
                                      height: 20,
                                      fontSize: 12,
                                    }}
                                  >
                                    Ч
                                  </Avatar>
                                </MenuItem>
                                <MenuItem value="2">
                                  <Avatar
                                    alt="Зелен"
                                    sx={{
                                      bgcolor: green[400],
                                      width: 20,
                                      height: 20,
                                      fontSize: 12,
                                    }}
                                  >
                                    З
                                  </Avatar>
                                </MenuItem>
                              </Select>
                            )}
                          />
                        )}

                        {column.id === "license_expire" && (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.license_expire`}
                            render={({
                              field, //: { onChange, value, ...field },
                            }) => (
                              <DatePicker
                                readOnly={
                                  !profile?.roles?.includes(
                                    "edit_drivers_licenses"
                                  )
                                }
                                format="DD.MM.YYYY"
                                onChange={(value) => {
                                  field.onChange(
                                    value ? value?.format("DD.MM.YYYY") : ""
                                  );
                                  // return value?.format("DD.MM.YYYY");
                                }}
                                value={
                                  field.value
                                    ? moment(field.value, "DD-MM-YYYY")
                                    : null
                                }
                                label="Изтича на:"
                                views={["year", "month", "day"]}
                                openTo="day"
                                slotProps={{
                                  textField: {
                                    variant: "standard",
                                    sx: { m: 1, marginBottom: 5 },
                                  },
                                  actionBar: { actions: ["clear"] },
                                }}
                              />
                            )}
                          />
                        )}

                        {column.id === "comptenence_expire" && (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.comptenence_expire`}
                            render={({
                              field, //: { onChange, value, ...field },
                            }) => (
                              <DatePicker
                                readOnly={
                                  !profile?.roles?.includes(
                                    "edit_drivers_licenses"
                                  )
                                }
                                format="DD.MM.YYYY"
                                onChange={(value) => {
                                  field.onChange(
                                    value ? value?.format("DD.MM.YYYY") : ""
                                  );
                                  // return value?.format("DD.MM.YYYY");
                                }}
                                value={
                                  field.value
                                    ? moment(field.value, "DD.MM.YYYY")
                                    : null
                                }
                                label="Изтича на:"
                                views={["year", "month", "day"]}
                                openTo="day"
                                slotProps={{
                                  textField: {
                                    variant: "standard",
                                    sx: { m: 1, marginBottom: 5 },
                                  },
                                  actionBar: { actions: ["clear"] },
                                }}
                              />
                            )}
                          />
                        )}

                        {column.id === "psiho_test_expire" && (
                          <Controller
                            control={control}
                            name={`drivers.${idx}.psiho_test_expire`}
                            render={({
                              field, //: { onChange, value, ...field },
                            }) => (
                              <DatePicker
                                readOnly={
                                  !profile?.roles?.includes(
                                    "edit_drivers_licenses"
                                  )
                                }
                                format="DD.MM.YYYY"
                                onChange={(value) => {
                                  field.onChange(
                                    value ? value?.format("DD.MM.YYYY") : ""
                                  );
                                  // return value?.format("DD.MM.YYYY");
                                }}
                                value={
                                  field.value
                                    ? moment(field.value, "DD.MM.YYYY")
                                    : null
                                }
                                label="Изтича на:"
                                views={["year", "month", "day"]}
                                openTo="day"
                                slotProps={{
                                  textField: {
                                    variant: "standard",
                                    sx: { m: 1, marginBottom: 5 },
                                  },
                                  actionBar: { actions: ["clear"] },
                                }}
                              />
                            )}
                          />
                        )}

                        {column.id === "driver_id" && (
                          <TextField
                            fullWidth
                            key={column.id}
                            {...register(`drivers.${idx}.driver_id`)}
                            variant="standard"
                            sx={{
                              input: { textAlign: column.align },
                              width: column.minWidth,
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                            InputProps={{
                              readOnly: column.readonly,

                              disableUnderline: true,
                            }}
                          ></TextField>
                        )}

                        {column.id !== "work_by_schedule" &&
                          column.id !== "vehicle" &&
                          column.id !== "shift" &&
                          column.id !== "skills" &&
                          column.id !== "color_id" &&
                          column.id !== "license_expire" &&
                          column.id !== "comptenence_expire" &&
                          column.id !== "psiho_test_expire" &&
                          column.id !== "driver_id" &&
                          column.id !== "no_overtime" &&
                          column.id !== "rest_on_weekends" && (
                            <TextField
                              fullWidth
                              key={column.id}
                              {...register(`drivers.${idx}.${column.id}`)}
                              variant="standard"
                              sx={{ input: { textAlign: column.align } }}
                              InputProps={{
                                readOnly: column.readonly,

                                disableUnderline: column.readonly,
                              }}
                            ></TextField>
                          )}
                      </TableCell>
                    ))}
                  </React.Fragment>
                );
            }
          }}
        ></TableVirtuoso>
        {/* </div> */}
      </TableContainer>
    </Paper>
  );
}

function VitrualTable({
  vehicles,
  skills,
  register,
  control,
  fields,
  filteredData,
  filter,
  setFilter,
}: {
  //rows: Data[];
  vehicles: Vehicle[];
  skills: DriverSkills;
  register: UseFormRegister<{ drivers: Data[] }>;
  control: Control<{ drivers: Data[] }, any>;
  fields: FieldArrayWithId<{ drivers: Data[] }, "drivers", "id">[];
  filteredData: FieldArrayWithId<{ drivers: Data[] }, "drivers", "id">[];
  filter: DriverFilter | null;
  setFilter: React.Dispatch<React.SetStateAction<DriverFilter | null>>;
}) {
  return (
    <TableContenet
      fields={fields}
      register={register}
      vehicles={vehicles}
      control={control}
      skills={skills}
      filter={filter}
      filteredData={filteredData}
      setFilter={setFilter}
    />
  );
}

export function DriversList() {
  const profile = useProfile();
  const isReadOnly =
    !profile?.roles?.includes("edit_schedule") &&
    !profile?.roles?.includes("edit_drivers_licenses");
  const showAllAutocolumns =
    profile?.roles?.includes("edit_drivers_licenses") &&
    profile?.roles?.includes("edit_all_drivers_licenses");
  // const showAllAutocolumns = true;

  //const theme = useTheme();

  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) =>
      state.filters.needsAutocolumnSelect || showAllAutocolumns
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const { data, isLoading, isError } = useGetDriversListQuery(
    { transport: selectedTransport, garage: selectedDepot },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const [filter, setFilter] = useState<DriverFilter | null>({
    driver_id: null,
    name: null,
    vehicle: null,
    shift: null,
    work_by_schedule: null,
    skills: null,
    color_id: null,
    license_expire: null,
    comptenence_expire: null,
    psiho_test_expire: null,
    no_overtime: null,
    quota_override: null,
    rest_on_weekends: null,
    category_desc: null,
  });

  const rows = useMemo<Data[]>(
    () =>
      data?.drivers
        ? Array.from(
            {
              length: data.drivers.filter(
                (drv) =>
                  (selectedAvtoColumn === 0 ||
                    drv.avtocolona === selectedAvtoColumn) &&
                  drv.state <= 2
              ).length,
            },
            (_, index) => {
              const driver = data.drivers.filter(
                (drv) =>
                  (selectedAvtoColumn === 0 ||
                    drv.avtocolona === selectedAvtoColumn) &&
                  drv.state <= 2
              )[index];
              const idx = data.drivers.findIndex(
                (drv) => drv.sl_number === driver.sl_number
              );
              return createData(
                data.drivers[idx].sl_number,
                data.drivers[idx].avtocolona,
                data.drivers[idx].name,
                data.drivers[idx].attributes?.preferred_vehicle?.toString() ||
                  "",
                data.drivers[idx].attributes?.preferred_workshift
                  ? data.drivers[idx].attributes?.preferred_workshift || 0
                  : 0,
                !!data.drivers[idx].attributes?.work_by_schedule,
                data.drivers[idx].attributes?.vehicletypes_skills?.map(
                  (s) => s.id
                ) || [],
                data.drivers[idx],
                data.drivers[idx].attributes?.color_id || 0,
                data.drivers[idx].attributes?.license_expire || "",
                data.drivers[idx].attributes?.comptenence_expire || "",
                data.drivers[idx].attributes?.psiho_test_expire || "",
                data.drivers[idx].attributes?.no_overtime || false,
                data.drivers[idx].attributes?.quota_override?.toString() || "",
                data.drivers[idx].attributes?.rest_on_weekends || false,
                data.drivers[idx].category_desc || "",
                data.drivers[idx].category_id || 0
              );
            }
          )
        : [],
    [data?.drivers, selectedAvtoColumn]
  );

  const {
    register,
    control,
    handleSubmit,
    fields,
    isDirty,
    hasError,
    loading,
  } = useDriversList(
    rows,
    selectedTransport,
    selectedDepot,
    data?.available_skills,
    isLoading,
    isError
  );
  // console.log("Data:", data);

  const filteredData = useMemo(
    () =>
      fields.filter(
        (value) =>
          (!filter ||
            !filter.driver_id ||
            value.driver_id.toString().startsWith(filter.driver_id)) &&
          (!filter ||
            !filter.name ||
            value.name.toUpperCase().startsWith(filter.name.toUpperCase())) &&
          (!filter ||
            !filter.category_desc ||
            (value.category_desc
              ? value.category_desc
                  .toUpperCase()
                  .includes(filter.category_desc.toUpperCase())
              : false)) &&
          (!filter ||
            !filter.vehicle ||
            value.vehicle?.startsWith(filter.vehicle)) &&
          (!filter ||
            !filter.quota_override ||
            value.quota_override?.startsWith(filter.quota_override)) &&
          (!filter ||
            !filter.shift ||
            filter.shift.includes("0") ||
            filter.shift.startsWith(
              value.shift ? value.shift.toString() : " "
            )) &&
          (!filter ||
            !filter.color_id ||
            filter.color_id === "0" ||
            filter.color_id === value.color_id?.toString()) &&
          (!filter ||
            !filter.work_by_schedule ||
            (filter.work_by_schedule.startsWith("1")
              ? value.work_by_schedule
              : !value.work_by_schedule)) &&
          (!filter ||
            !filter.no_overtime ||
            (filter.no_overtime.startsWith("1")
              ? value.no_overtime
              : !value.no_overtime)) &&
          (!filter ||
            !filter.rest_on_weekends ||
            (filter.rest_on_weekends.startsWith("1")
              ? value.rest_on_weekends
              : !value.rest_on_weekends)) &&
          (!filter ||
            !filter.skills ||
            filter.skills
              .split(", ")
              .reduce(
                (res, s) =>
                  res &&
                  value?.skills.filter(
                    (skill) =>
                      data?.available_skills.find((s) => s.id === skill)
                        ?.description === s
                  ).length > 0,
                true
              )) &&
          (!filter ||
            !filter.license_expire ||
            !value.license_expire ||
            moment(value.license_expire, "DD.MM.YYYY").isBefore(
              moment(filter.license_expire, "DD.MM.YYYY")
            )) &&
          (!filter ||
            !filter.comptenence_expire ||
            !value.comptenence_expire ||
            moment(value.comptenence_expire, "DD.MM.YYYY").isBefore(
              moment(filter.comptenence_expire, "DD.MM.YYYY")
            )) &&
          (!filter ||
            !filter.psiho_test_expire ||
            !value.psiho_test_expire ||
            moment(value.psiho_test_expire, "DD.MM.YYYY").isBefore(
              moment(filter.psiho_test_expire, "DD.MM.YYYY")
            ))
      ), //.map((v) => fields.findIndex((x) => x.driver_id === v.driver_id))
    [data?.available_skills, fields, filter]
  );

  useEffect(() => {
    if (!isReadOnly && isDirty) {
      const onUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };

      window.addEventListener("beforeunload", onUnload);
      return () => window.removeEventListener("beforeunload", onUnload);
    }
  }, [isDirty, isReadOnly]);

  const { exportPdf, isLoading: exportPdfLoading } =
    usePrintDriversPdfExporterer();

  const { rightToolbarMenu } = useSubmenu();

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={
            isReadOnly ||
            loading ||
            //schedule.drivers_sync_status?.status !== "ok" ||
            exportPdfLoading
          }
          onClick={() => exportPdf(filteredData, filter)}
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Form onSubmit={handleSubmit}>
          <Container sx={{ mt: 2, mb: 4 }}>
            <Grid container spacing={2}>
              {needsTransportSelect ? (
                <Grid item xs={12} sm={3}>
                  <TransportSelect />
                </Grid>
              ) : (
                <></>
              )}
              {needsDepotSelect ? (
                <Grid item xs={12} sm={3}>
                  <DepotSelect />
                </Grid>
              ) : (
                <></>
              )}
              {needsAutocolumnSelect ? (
                <Grid item xs={12} sm={2}>
                  <AutoColumnSelect showAllAutocolumns={showAllAutocolumns} />
                </Grid>
              ) : (
                <></>
              )}
              <Button
                className="me-3"
                style={{ whiteSpace: "nowrap" }}
                type="submit"
                disabled={isReadOnly || !isDirty} // || isSubmitting || isValidating}
              >
                &nbsp;Запиши
              </Button>
            </Grid>

            {data?.drivers && rows && !hasError && !loading && (
              <Container>
                <TextField
                  value={"Брой водачи: " + rows.length.toString()}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { padding: "10" },
                  }}
                />
              </Container>
            )}
          </Container>
        </Form>
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
          {hasError && <QueryError error={null} />}
          {loading && (
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          )}

          {data?.drivers &&
            rows &&
            rows.length > 0 &&
            !hasError &&
            !loading && (
              <VitrualTable
                //rows={rows}
                vehicles={data.vehicles ? data.vehicles : []}
                skills={data.available_skills}
                register={register}
                control={control}
                fields={fields}
                filteredData={filteredData}
                filter={filter}
                setFilter={setFilter}
              />
            )}
        </Box>
      </Box>
    </>
  );
}
