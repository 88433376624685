import { ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";

export function WithAuth({ children }: { children?: ReactNode }) {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth]);

  if (auth.isAuthenticated) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}
