import { blue, grey } from "@mui/material/colors";
import { Moment } from "moment";
import { TitleDataCell, TitleHeaderCell } from "./TableElements";
import { DaySummary } from "./types";
import { UseHeaderScrollerResult } from "./useHeaderScroller";

export function RestsHeaderRow({
  firstHeaderColumnWidth,
  headerColumnWidth,
  headerScroller,
  daySummary,
  isHoliday,
  today,
  selectedDate,
}: {
  firstHeaderColumnWidth: number;
  headerColumnWidth: number;
  headerScroller: UseHeaderScrollerResult;
  isHoliday: (day: string | Moment) => boolean | undefined;
  daySummary: DaySummary[];
  today: Moment;
  selectedDate: Moment | null;
}) {
  return (
    <div
      style={{
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
      }}
    >
      <TitleHeaderCell
        tabIndex={0}
        title="Почивки по график"
        width={firstHeaderColumnWidth}
        fixLeftIdx={0}
      >
        П
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={1}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={2}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={3}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={4}
      >
        &nbsp;
      </TitleHeaderCell>
      <TitleHeaderCell
        tabIndex={0}
        width={headerColumnWidth}
        firstColumnWidth={firstHeaderColumnWidth}
        fixLeftIdx={5}
      >
        &nbsp;
      </TitleHeaderCell>
      {daySummary.map((summary, idx) => (
        <TitleDataCell
          key={idx}
          tabIndex={0}
          isHoliday={isHoliday(summary.date)}
          style={{
            ...(summary.date.isSameOrBefore(today, "day")
              ? {
                  boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.6)",
                }
              : {}),
            ...(summary.date.isSame(selectedDate)
              ? {
                  backgroundColor: blue[300],
                  color: grey[900],
                  textShadow: undefined,
                }
              : {}),
          }}
        >
          {summary.rests}
        </TitleDataCell>
      ))}
      <div style={{ minWidth: headerScroller.scrollerWidth }} />
    </div>
  );
}
