export type DiverPersonalAccountDay = {
  day_task: string;
  day_type: number | null;
  daytask_date: string;
  end_shift: string | null;
  start_shitf: string | null;
  state: string;
  state_id: number;
  task_car: number;
  task_line: string;
  task_line_id: number;
  task_shift: number;
  task_veh1_id: number | null;
  working_hours: number | null;
  inteshift_time: number | null | undefined;
};

export type DriverPersonalAccount = {
  data: DiverPersonalAccountDay[];
};

interface PersonalAccountColumn {
  id:
    | "daytask_date"
    | "start_shift"
    | "end_shift"
    | "state"
    | "task_line"
    | "task_car"
    | "task_shift"
    | "working_hours"
    | "task_veh1_id"
    | "inteshift_time";

  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  width: number;
}

export const PersonalAccountColumns: readonly PersonalAccountColumn[] = [
  { id: "daytask_date", label: "Ден", minWidth: 80, width: 60 },

  {
    id: "task_line",
    label: "Линия",
    minWidth: 50,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "task_car",
    label: "Кола",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "task_shift",
    label: "Смяна",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "task_veh1_id",
    label: "ПС",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 50,
  },
  {
    id: "start_shift",
    label: "Начало",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "end_shift",
    label: "Край",
    minWidth: 50,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
    width: 80,
  },
  {
    id: "working_hours",
    label: "Заработка",
    minWidth: 120,
    align: "center",
    width: 80,
  },
  {
    id: "inteshift_time",
    label: "Междусменна почивка",
    minWidth: 80,
    align: "center",
    width: 80,
  },
];
