import { Channel } from "phoenix";
import { useCallback } from "react";
import { PlannerSettings } from "../../common/useGarageSettings";
import { useProfile } from "../../common/useProfile";
import {
  AddDriverToVehicleData,
  AddShiftToVehicleData,
  AddVehicleIntervalData,
  ChangeTaskVehicleData,
  EditDriverDayCommentData,
  EditDriverDayRosterCommentData,
  EditVehicleDayCommentData,
  ElectroAddDriverToShiftData,
  ElectroAddVehicleToShiftData,
  ElectroRemoveDriverFromShiftData,
  ElectroRemoveVehicleFromShiftData,
  RemoveDriverFromVehicleData,
  RemoveShiftFromVehicleData,
  UpdateDriverStateData,
} from "./types";

function callAsyncFunction<
  TData extends object = Record<string, never>,
  TResult = { status: "ok" }
>(
  channel: Channel | null | undefined,
  message: string,
  disable: boolean,
  data: TData
) {
  return new Promise<TResult>((resolve, reject) => {
    if (!disable && channel) {
      channel
        .push(message, data)
        .receive("ok", (msg) => {
          if (msg.status === "ok") {
            //console.log("response", msg);
            return resolve(msg);
          } else {
            console.log("Received error from server for:", message);
            reject(msg);
          }
        })
        .receive("error", (e) => {
          console.log("Received error from client for:", message);
          reject(e);
        })
        .receive("timeout", () => {
          console.log("Received timeout from client for:", message);
          reject(new Error("timeout"));
        });
    } else {
      console.log("Channel closed for:", message);
      reject("Channel closed");
    }
  });
}

function updateAccessTokenImpl(
  channel: Channel | null | undefined,
  _isReadOnly: boolean,
  access_token: string
) {
  return callAsyncFunction(channel, "update_access_token", false, {
    access_token,
  });
}

function startPlannerImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  settings: PlannerSettings
) {
  return callAsyncFunction(channel, "start_planner", isReadOnly, settings);
}

function stopPlannerImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean
) {
  return callAsyncFunction(channel, "stop_planner", isReadOnly, {});
}

function saveScheduleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean
) {
  return callAsyncFunction(channel, "save", isReadOnly, {});
}

function cleanupScheduleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean
) {
  return callAsyncFunction(channel, "clean_up", isReadOnly, {});
}

function markReadyImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean
) {
  return callAsyncFunction(channel, "set_preliminary_as_ready", isReadOnly, {});
}

function reimportAllDataImpl(
  channel: Channel | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _isReadOnly: boolean
) {
  return callAsyncFunction(channel, "reload_all", false, {});
}

function addDriverToVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: AddDriverToVehicleData
) {
  return callAsyncFunction(channel, "add_driver_to_vehicle", isReadOnly, data);
}

function addNewDriverToVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: AddDriverToVehicleData
) {
  return callAsyncFunction(
    channel,
    "add_new_driver_to_vehicle",
    isReadOnly,
    data
  );
}

function changeTaskVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: ChangeTaskVehicleData
) {
  return callAsyncFunction(channel, "change_task_vehicle", isReadOnly, data);
}

function removeDriverFromVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: RemoveDriverFromVehicleData
) {
  return callAsyncFunction(
    channel,
    "remove_driver_from_vehicle",
    isReadOnly,
    data
  );
}

function addShiftToVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: AddShiftToVehicleData | readonly AddShiftToVehicleData[]
) {
  return callAsyncFunction(channel, "add_shift_to_vehicle", isReadOnly, data);
}

function removeShiftFromVehicleImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: RemoveShiftFromVehicleData
) {
  return callAsyncFunction(
    channel,
    "remove_shift_from_vehicle",
    isReadOnly,
    data
  );
}

function editDriverDayRosterCommentImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: EditDriverDayRosterCommentData
) {
  return callAsyncFunction(
    channel,
    "edit_driver_day_roster_comment",
    isReadOnly,
    data
  );
}

function clearDriverTasksImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  date: string
) {
  return callAsyncFunction(channel, "clean_up_roster", isReadOnly, { date });
}

function createRosterImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  date: string
) {
  return callAsyncFunction(channel, "create_roster", isReadOnly, { date });
}

function updateDriverStateImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: UpdateDriverStateData
) {
  return callAsyncFunction(channel, "update_driver_state", isReadOnly, data);
}

function editDriverDayCommentImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: EditDriverDayCommentData
) {
  return callAsyncFunction(
    channel,
    "edit_driver_day_comment",
    isReadOnly,
    data
  );
}

function addVehicleIntervalImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: AddVehicleIntervalData
) {
  return callAsyncFunction(channel, "add_vehicle_interval", isReadOnly, data);
}

function editVehicleDayCommentImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: EditVehicleDayCommentData
) {
  return callAsyncFunction(
    channel,
    "edit_vehicle_day_comment",
    isReadOnly,
    data
  );
}

function electroAddVehicleToShiftImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: ElectroAddVehicleToShiftData
) {
  return callAsyncFunction(
    channel,
    "electro_add_vehicle_to_shift",
    isReadOnly,
    data
  );
}

function electroAddDriverToShiftImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: ElectroAddDriverToShiftData
) {
  return callAsyncFunction(
    channel,
    "electro_add_driver_to_shift",
    isReadOnly,
    data
  );
}

function electroRemoveVehicleFromShiftImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: ElectroRemoveVehicleFromShiftData
) {
  return callAsyncFunction(
    channel,
    "electro_remove_vehicle_from_shift",
    isReadOnly,
    data
  );
}

function electroRemoveDriverFromShiftImpl(
  channel: Channel | null | undefined,
  isReadOnly: boolean,
  data: ElectroRemoveDriverFromShiftData
) {
  return callAsyncFunction(
    channel,
    "electro_remove_driver_from_shift",
    isReadOnly,
    data
  );
}

export function useSchedulesChannelCallbacks(
  channel: Channel | null | undefined
) {
  const profile = useProfile();
  const editScheduleDisabled = !profile?.roles?.includes("edit_schedule");
  const editRosterDisabled = !profile?.roles?.includes("edit_roster");
  const editRosterVehiclesEnabled =
    editRosterDisabled && !!profile?.roles?.includes("edit_roster_vehicles");

  const updateAccessToken = useCallback(
    (access_token: string) =>
      updateAccessTokenImpl(channel, false, access_token),
    [channel]
  );
  const startPlanner = useCallback(
    (settings: PlannerSettings) =>
      startPlannerImpl(channel, editScheduleDisabled, settings),
    [channel, editScheduleDisabled]
  );
  const stopPlanner = useCallback(
    () => stopPlannerImpl(channel, editScheduleDisabled),
    [channel, editScheduleDisabled]
  );
  const saveSchedule = useCallback(
    () => saveScheduleImpl(channel, editScheduleDisabled),
    [channel, editScheduleDisabled]
  );
  const cleanupSchedule = useCallback(
    () => cleanupScheduleImpl(channel, editScheduleDisabled),
    [channel, editScheduleDisabled]
  );
  const markReady = useCallback(
    () => markReadyImpl(channel, editScheduleDisabled),
    [channel, editScheduleDisabled]
  );
  const reimportAllData = useCallback(
    () => reimportAllDataImpl(channel, editScheduleDisabled),
    [channel, editScheduleDisabled]
  );
  const addDriverToVehicle = useCallback(
    (data: AddDriverToVehicleData) =>
      addDriverToVehicleImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const addNewDriverToVehicle = useCallback(
    (data: AddDriverToVehicleData) =>
      addNewDriverToVehicleImpl(channel, editRosterDisabled, data),
    [editRosterDisabled, channel]
  );
  const changeTaskVehicle = useCallback(
    (data: ChangeTaskVehicleData) =>
      changeTaskVehicleImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const removeDriverFromVehicle = useCallback(
    (data: RemoveDriverFromVehicleData) =>
      removeDriverFromVehicleImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const addShiftToVehicle = useCallback(
    (data: AddShiftToVehicleData | readonly AddShiftToVehicleData[]) =>
      addShiftToVehicleImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const removeShiftFromVehicle = useCallback(
    (data: RemoveShiftFromVehicleData) =>
      removeShiftFromVehicleImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const editDriverDayRosterComment = useCallback(
    (data: EditDriverDayRosterCommentData) =>
      editDriverDayRosterCommentImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const clearDriverTasks = useCallback(
    (date: string) => clearDriverTasksImpl(channel, editScheduleDisabled, date),
    [channel, editScheduleDisabled]
  );
  const createRoster = useCallback(
    (date: string) =>
      createRosterImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        date
      ),
    [channel, editRosterDisabled, editRosterVehiclesEnabled]
  );
  const updateDriverState = useCallback(
    (data: UpdateDriverStateData) =>
      updateDriverStateImpl(channel, editScheduleDisabled, data),
    [channel, editScheduleDisabled]
  );
  const editDriverDayComment = useCallback(
    (data: EditDriverDayCommentData) =>
      editDriverDayCommentImpl(channel, editScheduleDisabled, data),
    [channel, editScheduleDisabled]
  );
  const electroAddVehicleToShift = useCallback(
    (data: ElectroAddVehicleToShiftData) =>
      electroAddVehicleToShiftImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    [channel, editRosterDisabled, editRosterVehiclesEnabled]
  );
  const electroAddDriverToShift = useCallback(
    (data: ElectroAddDriverToShiftData) =>
      electroAddDriverToShiftImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );
  const electroRemoveVehicleFromShift = useCallback(
    (data: ElectroRemoveVehicleFromShiftData) =>
      electroRemoveVehicleFromShiftImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    [channel, editRosterDisabled, editRosterVehiclesEnabled]
  );
  const electroRemoveDriverFromShift = useCallback(
    (data: ElectroRemoveDriverFromShiftData) =>
      electroRemoveDriverFromShiftImpl(channel, editRosterDisabled, data),
    [channel, editRosterDisabled]
  );

  return {
    startPlanner,
    stopPlanner,
    saveSchedule,
    cleanupSchedule,
    markReady,
    reimportAllData,
    addDriverToVehicle,
    addNewDriverToVehicle,
    changeTaskVehicle,
    removeDriverFromVehicle,
    addShiftToVehicle,
    removeShiftFromVehicle,
    editDriverDayRosterComment,
    clearDriverTasks,
    createRoster,
    updateDriverState,
    updateAccessToken,
    editDriverDayComment,
    electroAddVehicleToShift,
    electroAddDriverToShift,
    electroRemoveVehicleFromShift,
    electroRemoveDriverFromShift,
  };
}

export type SchedulesChannelCallbackFunctions = ReturnType<
  typeof useSchedulesChannelCallbacks
>;

export function createSchedulesChannelCallbacks(
  channel: Channel | null | undefined,
  editScheduleDisabled: boolean,
  editRosterDisabled: boolean,
  editRosterVehiclesEnabled: boolean
): SchedulesChannelCallbackFunctions {
  return {
    startPlanner: (settings: PlannerSettings) =>
      startPlannerImpl(channel, editScheduleDisabled, settings),
    stopPlanner: () => stopPlannerImpl(channel, editScheduleDisabled),
    saveSchedule: () => saveScheduleImpl(channel, editScheduleDisabled),
    cleanupSchedule: () => cleanupScheduleImpl(channel, editScheduleDisabled),
    markReady: () => markReadyImpl(channel, editScheduleDisabled),
    reimportAllData: () => reimportAllDataImpl(channel, editScheduleDisabled),
    addDriverToVehicle: (data: AddDriverToVehicleData) =>
      addDriverToVehicleImpl(channel, editRosterDisabled, data),
    addNewDriverToVehicle: (data: AddDriverToVehicleData) =>
      addNewDriverToVehicleImpl(channel, editRosterDisabled, data),
    changeTaskVehicle: (data: ChangeTaskVehicleData) =>
      changeTaskVehicleImpl(channel, editRosterDisabled, data),
    removeDriverFromVehicle: (data: RemoveDriverFromVehicleData) =>
      removeDriverFromVehicleImpl(channel, editRosterDisabled, data),
    addShiftToVehicle: (
      data: AddShiftToVehicleData | readonly AddShiftToVehicleData[]
    ) => addShiftToVehicleImpl(channel, editRosterDisabled, data),
    removeShiftFromVehicle: (data: RemoveShiftFromVehicleData) =>
      removeShiftFromVehicleImpl(channel, editRosterDisabled, data),
    editDriverDayRosterComment: (data: EditDriverDayRosterCommentData) =>
      editDriverDayRosterCommentImpl(channel, editRosterDisabled, data),
    clearDriverTasks: (date: string) =>
      clearDriverTasksImpl(channel, editScheduleDisabled, date),
    createRoster: (date: string) =>
      createRosterImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        date
      ),
    updateDriverState: (data: UpdateDriverStateData) =>
      updateDriverStateImpl(channel, editScheduleDisabled, data),
    updateAccessToken: (access_token: string) =>
      updateAccessTokenImpl(channel, false, access_token),
    editDriverDayComment: (data: EditDriverDayCommentData) =>
      editDriverDayCommentImpl(channel, editRosterDisabled, data),
    electroAddVehicleToShift: (data: ElectroAddVehicleToShiftData) =>
      electroAddVehicleToShiftImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    electroAddDriverToShift: (data: ElectroAddDriverToShiftData) =>
      electroAddDriverToShiftImpl(channel, editRosterDisabled, data),
    electroRemoveVehicleFromShift: (data: ElectroRemoveVehicleFromShiftData) =>
      electroRemoveVehicleFromShiftImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    electroRemoveDriverFromShift: (data: ElectroRemoveDriverFromShiftData) =>
      electroRemoveDriverFromShiftImpl(channel, editRosterDisabled, data),
  };
}

export function useVehiclesChannelCallbacks(
  channel: Channel | null | undefined
) {
  const profile = useProfile();
  const editRosterDisabled = !profile?.roles?.includes("edit_roster");
  const editRosterVehiclesEnabled =
    editRosterDisabled && !!profile?.roles?.includes("edit_roster_vehicles");

  const updateAccessToken = useCallback(
    (access_token: string) =>
      updateAccessTokenImpl(channel, false, access_token),
    [channel]
  );
  const addVehicleInterval = useCallback(
    (data: AddVehicleIntervalData) =>
      addVehicleIntervalImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    [channel, editRosterDisabled, editRosterVehiclesEnabled]
  );
  const editVehicleDayComment = useCallback(
    (data: EditVehicleDayCommentData) =>
      editVehicleDayCommentImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    [channel, editRosterDisabled, editRosterVehiclesEnabled]
  );

  return {
    updateAccessToken,
    addVehicleInterval,
    editVehicleDayComment,
  };
}

export type VehiclesChannelCallbackFunctions = ReturnType<
  typeof useVehiclesChannelCallbacks
>;

export function createVehiclesChannelCallbacks(
  channel: Channel | null | undefined,
  editRosterDisabled: boolean,
  editRosterVehiclesEnabled: boolean
): VehiclesChannelCallbackFunctions {
  return {
    updateAccessToken: (access_token: string) =>
      updateAccessTokenImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        access_token
      ),
    addVehicleInterval: (data: AddVehicleIntervalData) =>
      addVehicleIntervalImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
    editVehicleDayComment: (data: EditVehicleDayCommentData) =>
      editVehicleDayCommentImpl(
        channel,
        editRosterDisabled && !editRosterVehiclesEnabled,
        data
      ),
  };
}
