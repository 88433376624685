import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { Data, DriverFilter } from "../pages/admin/useDriversList";
import { ReportPrinter } from "./ReportPrinter";
import moment from "moment";

export function usePrintDriversPdfExporterer() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();

  const exportPdf = useCallback(
    async (rows: Data[], filter: DriverFilter | null) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const printer = new ReportPrinter();
        printer.printSpace(5);

        printer.setFont("bold", 18, 1.2);
        printer.printText(
          `Списък на водачите към дата ${moment().format("LL")}`
        );

        printer.setFont("italic", 12, 1.2);
        printer.printText(
          `Гараж ${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }, Автокoлона ${selectedAutoColumn}`
        );

        if (filter) {
          printer.setFont("italic", 12, 1.2);
          printer.printText(
            `Филтър:
            ${filter.driver_id ? `Служебен №: ${filter.driver_id}` : ""} ${
              filter.name ? `Име: ${filter.name}` : ""
            } ${filter.vehicle ? `Превозно средство: ${filter.vehicle}` : ""} ${
              filter.color_id
                ? `Цвят: ${filter.color_id === "1" ? `червен` : `зелен`}`
                : ""
            } ${
              filter.shift
                ? `Смяна: ${filter.shift === "1" ? `1-ва` : `2-ра`}`
                : ""
            } ${
              filter.work_by_schedule
                ? `По график: ${filter.work_by_schedule}`
                : ""
            } ${
              filter.skills ? `Способности: ${filter.skills.toString()}` : ""
            }${
              filter.license_expire
                ? `Шофьорска книжка изтича преди: ${filter.license_expire}
                `
                : ""
            }${
              filter.comptenence_expire
                ? `Професионална квалификация изтича преди: ${filter.comptenence_expire}
                `
                : ""
            }${
              filter.psiho_test_expire
                ? `Психо тест изтича преди: ${filter.psiho_test_expire}
                `
                : ""
            }`
          );
        }

        printer.printSpace(15);

        printer.autoTable({
          styles: {
            halign: "left",
            valign: "top",
            font: "Roboto",
            fontSize: 9,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            lineWidth: 0.25,
            //lineWidth: 0,
          },
          bodyStyles: {
            cellPadding: 4,
          },

          headStyles: {
            halign: "center",
          },
          columnStyles: {
            0: { cellWidth: 65 },
            1: { cellWidth: 55 },
            2: {
              halign: "left",
              // cellWidth:
              //   (printer.printableWidth - (45 + 20 + 45 + 45 + 45 + 45)) / 2,
            },
            3: { halign: "center" },
            4: { halign: "center" },
            5: { halign: "center" },
          },
          body: rows as any,
          columns: [
            {
              dataKey: "driver_id",
              header: "Служебен №",
            },
            {
              dataKey: "vehicle",
              header: "Превозно средство",
            },
            {
              dataKey: "name",
              header: "Име",
            },
          ]
            .concat(
              filter?.license_expire
                ? {
                    dataKey: "license_expire",
                    header: "Шоф. книжка изтича преди",
                  }
                : []
            )
            .concat(
              filter?.comptenence_expire
                ? {
                    dataKey: "comptenence_expire",
                    header: "Профес. квалиф. изтича преди",
                  }
                : []
            )
            .concat(
              filter?.psiho_test_expire
                ? {
                    dataKey: "psiho_test_expire",
                    header: "Психо тест истича преди",
                  }
                : []
            ),
        });

        printer.save(
          `Списък водачи-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [depots, selectedAutoColumn, selectedDepot]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
