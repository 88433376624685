import { Theme } from "@mui/material";

export function backgroundFromStateId(theme: Theme, stateId: number) {
  switch (stateId) {
    case -1:
      // Взета почивка
      return "#8b8b8b";
    case 4:
      // На работа 1-ва смяна - резерв
      return theme.palette.schedule.fixedShift1;
    case 14:
      // Допълнителни часове
      return theme.palette.schedule.fixedShift1;
    case 21:
      // На работа 1-ва смяна - по желание
      return theme.palette.schedule.shift1;
    case 28:
      // На работа 1-ва смяна
      return theme.palette.schedule.fixedShift1;
    case 22:
      // На работа 2-ра смяна - по желание
      return theme.palette.schedule.shift2;
    case 29:
      // На работа 2-ра смяна
      return theme.palette.schedule.fixedShift2;
    case 5:
      // Задължителна почивка
      return theme.palette.schedule.fixedRest;
    case 24:
      // Почивка по избор
      return theme.palette.schedule.rest;
    case 27:
      // Почивка по график
      return theme.palette.schedule.automaticRest;
    case 7:
      // Болнични
      return theme.palette.schedule.sickLeave;
    case 34:
      // Болнични без документи
      return theme.palette.schedule.sickLeave;
    case 6:
      // Отпуска
      return theme.palette.schedule.fixedLeave;
    case 25:
      // Отпуска по възможност
      return theme.palette.schedule.leave;
    case 10:
      // Неплатен отпуск
      return theme.palette.schedule.fixedUnpaidLeave;
    case 26:
      // Неплатен отпуск по желание
      return theme.palette.schedule.unpaidLeave;
    case 15:
      // Почивка между болнични/отпуск
      return theme.palette.schedule.leaveRest;
    case 17:
      // Служебен отпуск
      return theme.palette.schedule.fixedLeave;
    case 19:
      // Ученически отпуск
      return theme.palette.schedule.fixedLeave;
    case 33:
      // Отпуск без документи
      return theme.palette.schedule.fixedLeave;
    case 9:
      // Стажант
      return theme.palette.schedule.intern;
    case 13:
      // Неназначен
      return theme.palette.schedule.notWorking;
    case 8:
    case 16:
      // Действителна неявка
      return theme.palette.schedule.realNoShow;
    case 37:
      // Наставник
      return theme.palette.schedule.intern;
    default:
      return theme.palette.background.paper;
  }
}

export function foregroundFromStateId(theme: Theme, stateId: number) {
  switch (stateId) {
    case -1:
      // Взета почивка
      return "#fff";
    case 4:
      // На работа 1-ва смяна - резерв
      return theme.palette.schedule.fixedShift1Text;
    case 14:
      // Допълнителни часове
      return theme.palette.schedule.fixedShift1Text;
    case 21:
      // На работа 1-ва смяна - по желание
      return theme.palette.schedule.shift1Text;
    case 28:
      // На работа 1-ва смяна
      return theme.palette.schedule.fixedShift1Text;
    case 22:
      // На работа 2-ра смяна - по желание
      return theme.palette.schedule.shift2Text;
    case 29:
      // На работа 2-ра смяна
      return theme.palette.schedule.fixedShift2Text;
    case 5:
      // Задължителна почивка
      return theme.palette.schedule.fixedRestText;
    case 24:
      // Почивка по избор
      return theme.palette.schedule.restText;
    case 27:
      // Почивка по график
      return theme.palette.schedule.automaticRestText;
    case 7:
      // Болнични
      return theme.palette.schedule.sickLeaveText;
    case 34:
      // Болнични без документи
      return theme.palette.schedule.automaticRestText;
    case 6:
      // Отпуска
      return theme.palette.schedule.fixedLeaveText;
    case 25:
      // Отпуска по възможност
      return theme.palette.schedule.leaveText;
    case 10:
      // Неплатен отпуск
      return theme.palette.schedule.fixedUnpaidLeaveText;
    case 26:
      // Неплатен отпуск по желание
      return theme.palette.schedule.unpaidLeaveText;
    case 15:
      // Почивка между болнични/отпуск
      return theme.palette.schedule.leaveRestText;
    case 17:
      // Служебен отпуск
      return theme.palette.schedule.fixedLeaveText;
    case 19:
      // Ученически отпуск
      return theme.palette.schedule.fixedLeaveText;
    case 33:
      // Отпуск без документи
      return theme.palette.schedule.automaticRestText;
    case 9:
      // Стажант
      return theme.palette.schedule.internText;
    case 13:
      // Неназначен
      return theme.palette.schedule.notWorkingText;
    case 8:
    case 16:
      // Действителна неявка
      return theme.palette.schedule.realNoShowText;
    case 37:
      // Наставник
      return theme.palette.schedule.internText;
    default:
      return theme.palette.text.primary;
  }
}
