import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  GarageSettings,
  useGarageSettings,
} from "../../common/useGarageSettings";
import { Transport } from "../../data/api/types/driver";
import {
  Schedule,
  ScheduleDay,
  ScheduleDriver,
  ScheduleParameters,
  sortArray,
} from "../../data/api/types/schedule";
import { RootState } from "../../data/store";
import { DaySummary, DriverSummary } from "./types";
import { useIsHoliday } from "./useIsHoliday";

function isInAnotherDepot(
  driver: ScheduleDriver,
  day: ScheduleDay | null | undefined,
  scheduleDepot: number
): boolean {
  if (!driver.home_depot_id || !day?.work_depot_id) {
    return false;
  } else {
    return scheduleDepot !== day.work_depot_id;
  }
}

function processStates(
  state: number,
  // preliminaryState: number,
  isForDay: boolean,
  scheduleAutocolumn: number,
  scheduleDepot: number,
  driver: ScheduleDriver,
  day: ScheduleDay | null | undefined,
  // isPreliminary: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _isHoliday: (
    day: string | moment.Moment | null | undefined
  ) => boolean | undefined
) {
  const isExpiredDocs = !!day?.expired_docs;
  const driverAutocolumn = day?.autocolumn || 0;
  const isSameAutocolumn = driverAutocolumn === scheduleAutocolumn;
  const is110111114 =
    driver.category?.id === 2 ||
    driver.category?.id === 4 ||
    driver.category?.id === 9;

  let workshifts1 = 0;
  let workshifts2 = 0;
  let rests = 0;
  let nowork = 0;
  let nowork_leave = 0;
  let workdays = 0;
  let undef_days = 0;
  let fake_rests = 0;

  if (
    !isInAnotherDepot(driver, day, scheduleDepot) &&
    isSameAutocolumn &&
    isForDay &&
    is110111114 &&
    state === 1
  ) {
    // Ако водачът работи по чл. 110, 111 или 114 неопределеното състояние се брои за почивка за обобщението по дни
    fake_rests += 1;
  }

  if (!isForDay || (!isExpiredDocs && isSameAutocolumn)) {
    if (
      // За дата, не е командирован, не е стажант или наставник
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        [3, 4, 32, 21, 28, 2, 11, 14, 23].includes(state)) ||
      // За водач, може да бъде командирован, стажант или наставник
      (!isForDay && [3, 4, 32, 21, 28, 2, 11, 14, 23, 9, 37].includes(state))
    ) {
      // isForDay
      // Резерв с автобус
      // Резерв
      // Персонална
      // 1-ва смяна по график
      // 1-ва смяна по график - задължително
      // На линия
      // Маневрист
      // Допълнителни часове
      // На линия по желание

      // !isForDay
      // Стажант
      // Наставник
      workshifts1 += 1;
    } else if (
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        [22, 29].includes(state)) ||
      (!isForDay && [22, 29].includes(state))
    ) {
      // 2-ра смяна по график
      // 2-ра смяна по график - задължително
      workshifts2 += 1;
    } else if (
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        [24, 27, 15, 5, 8, 31, 16].includes(state)) ||
      (!isForDay && [24, 27, 15, 5, 8, 31, 16].includes(state))
    ) {
      // Почивка по желание
      // Почивка по график
      // Почивка между Б, О и НО
      // Почивка
      // Неявка
      // Неявка?
      // Действителна неявка
      // if (!(isPreliminary && preliminaryState === 13)) {
      // Ако разглеждаме предварителен график и водача не е назначен почивката не се брои
      rests += 1;
      // }
    } else if (
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        [6, 7, 34, /*8, 31, 16, */ 10, 17, 19, 20, 30, 25, 26, 33].includes(
          state
        )) ||
      (!isForDay &&
        [6, 7, 34, /*8, 31, 16, */ 10, 17, 19, 20, 30, 25, 26, 33].includes(
          state
        ))
    ) {
      // Отпуск
      // Болни
      // Болничен без документи
      // // Неявка
      // // Неявка?
      // // Действителна неявка
      // Неплатен отпуск
      // Служебен отпуск
      // Ученически отпуск
      // Алкохол
      // Отпуск по майчинство
      // Отпуск по желание
      // Неплатен отпуск по желание
      // Отпуск без документи
      nowork += 1;

      if (
        [6, 10, 17, 19, 30, 25, 26, 33].includes(state) /* ||
        (isPreliminary &&
          [6, 10, 17, 19, 30, 25, 26, 33].includes(preliminaryState))*/
      ) {
        // Отпуск
        nowork_leave += 1;
      }
    }

    // Пропускат се неназначени, стажанти и командировани
    if (
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        ![13, 9, 37, 12].includes(state)) ||
      (!isForDay && ![13, 12].includes(state))
    ) {
      // Неназначен
      // Стажанти
      // Наставник
      // Командирован
      workdays += 1;
    }

    // console.log("is110111114:", is110111114);
    if (
      (isForDay &&
        !isInAnotherDepot(driver, day, scheduleDepot) &&
        state === 1 &&
        !is110111114) ||
      (!isForDay && state === 1 && !is110111114)
    ) {
      undef_days += 1;
    }
  }

  return {
    workshifts1,
    workshifts2,
    rests,
    nowork,
    nowork_leave,
    workdays,
    undef_days,
    fake_rests,
  };
}

function filterDriversByVehicleType(
  drivers: readonly ScheduleDriver[],
  selectedTransport: Transport | "" | null | undefined,
  selectedVehicleType: number
): ScheduleDriver[] {
  return drivers.filter((d) => {
    if (selectedTransport === "TM" || selectedTransport === "TB") {
      if (selectedVehicleType === -2) {
        return true;
      } else if (selectedVehicleType === -1) {
        return (
          d.attributes?.preferred_vehicle_type === null ||
          d.attributes?.preferred_vehicle_type === undefined
        );
      } else {
        return (
          d.attributes?.vehicletypes_skills?.flatMap(
            (s) => s.razpcad_vehicletype_ids
          ) || []
        ).includes(selectedVehicleType);
      }
    } else {
      if (selectedVehicleType === -2) {
        return true;
      } else if (selectedVehicleType === -1) {
        return (
          d.attributes?.preferred_vehicle_type === null ||
          d.attributes?.preferred_vehicle_type === undefined
        );
      } else {
        return d.attributes?.preferred_vehicle_type === selectedVehicleType;
      }
    }
  });
}

function filterDriversByState(
  drivers: readonly ScheduleDriver[],
  selectedStates: Record<string, readonly number[]>
): ScheduleDriver[] {
  return drivers.filter((d) => {
    // console.log("States filter");
    const entries = Object.entries(selectedStates);
    if (entries.length === 0) {
      return true;
    } else {
      let allowed = true;
      for (const [date, state_ids] of entries) {
        allowed =
          allowed &&
          (!date ||
            !d.driver_days[date] ||
            !state_ids ||
            state_ids.length === 0 ||
            state_ids.includes(d.driver_days[date].state));
      }
      return allowed;
    }
  });
}

function filterDrivers(
  drivers: readonly ScheduleDriver[],
  selectedTransport: Transport | "" | null | undefined,
  selectedVehicleType: number,
  selectedStates: Record<string, readonly number[]>,
  filterStates: boolean
): ScheduleDriver[] {
  const filteredDrivers = filterDriversByVehicleType(
    drivers,
    selectedTransport,
    selectedVehicleType
  );
  if (filterStates) {
    return filterDriversByState(filteredDrivers, selectedStates);
  } else {
    return filteredDrivers;
  }
}

function sortDrivers(
  drivers: readonly ScheduleDriver[],
  settings: GarageSettings
) {
  return sortArray(
    Object.values(drivers),
    settings.scheduleSortOrder === "by_vehicle_type"
      ? [
          "attributes.preferred_vehicle_type",
          "attributes.preferred_vehicle",
          "sl_number",
        ]
      : ["sl_number"],
    {
      in_place: true,
      reverse: false,
    }
  );
}

export function useScheduleSummary(
  scheduleParams: ScheduleParameters | null,
  schedule: Schedule | undefined,
  daysInMonth: number[],
  selectedVehicleType: number
): {
  drivers: ScheduleDriver[];
  daySummary: DaySummary[] | null;
  driverSummary: Record<string, DriverSummary> | null;
} {
  const settings = useGarageSettings();

  const selectedStates = useSelector(
    (state: RootState) => state.schedule.selectedStatesFilter
  );

  const drivers = useMemo(
    () =>
      filterDriversByVehicleType(
        sortDrivers(Object.values(schedule?.drivers || {}), settings),
        scheduleParams?.[0],
        selectedVehicleType
      ),
    [schedule?.drivers, scheduleParams, selectedVehicleType, settings]
  );

  const filteredDrivers = useMemo(
    () => filterDriversByState(drivers, selectedStates),
    [drivers, selectedStates]
  );

  const [today, setToday] = useState<Moment>(moment().startOf("day"));
  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = moment().startOf("day");
      if (newDate.isAfter(today)) {
        setToday(newDate);
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [today]);

  const isHoliday = useIsHoliday(
    schedule?.db_schedule?.year && schedule?.db_schedule?.month
      ? `${schedule.db_schedule.year}-${schedule.db_schedule.month}`
      : today.format("YYYY-MM")
  );

  const summary = useMemo<{
    daySummary: DaySummary[] | null;
    driverSummary: Record<string, DriverSummary> | null;
  }>(() => {
    if (
      scheduleParams &&
      schedule &&
      schedule.db_schedule.transport === scheduleParams[0] &&
      schedule.db_schedule.depot_id === scheduleParams[1] &&
      schedule.db_schedule.year === scheduleParams[2] &&
      schedule.db_schedule.month === scheduleParams[3] &&
      schedule.db_schedule.autocolumn === scheduleParams[4] &&
      schedule.db_schedule.brigade === scheduleParams[5]
    ) {
      // console.time("Schedule summary");
      // const isPreliminary = !schedule.db_schedule.preliminary_ready;

      const monthPrefix = `${scheduleParams[2]
        .toFixed(0)
        .padStart(4, "0")}-${scheduleParams[3].toFixed(0).padStart(2, "0")}-`;

      const daySummary = daysInMonth.map<DaySummary>((dIdx) => {
        let workshifts1 = 0;
        let workshifts2 = 0;
        let rests = 0;
        let nowork = 0;
        let nowork_leave = 0;
        let workdays = 0;
        let undef_days = 0;
        let fake_rests = 0;

        for (const driver of drivers) {
          const day_str = `${monthPrefix}${(dIdx + 1)
            .toFixed(0)
            .padStart(2, "0")}`;
          const state = driver.driver_days[day_str]?.state || 0;
          // const preliminaryState =
          //   driver.driver_days[day_str]?.pre_state?.state_id || 0;

          const states = processStates(
            state,
            // preliminaryState,
            true,
            scheduleParams[4],
            scheduleParams[1],
            driver,
            driver.driver_days[day_str],
            // isPreliminary,
            isHoliday
          );
          workshifts1 += states.workshifts1;
          workshifts2 += states.workshifts2;
          rests += states.rests;
          nowork += states.nowork;
          nowork_leave += states.nowork_leave;
          workdays += states.workdays;
          undef_days += states.undef_days;
          fake_rests += states.fake_rests;
        }

        const plan_shifts_by_vt = (
          (schedule.expl_plan_counts_by_vt || [])[dIdx] || []
        )
          .filter((sc) =>
            selectedVehicleType === -2
              ? true
              : selectedVehicleType === -1
              ? sc.vehicletype_id === null || sc.vehicletype_id === undefined
              : sc.vehicletype_id === selectedVehicleType
          )
          .flatMap((sc) => sc.shifts_counts);
        //console.log("Shifts bt VT:", plan_shifts_by_vt);

        const result: Omit<DaySummary, "additionalRests"> = {
          plan_shift1:
            schedule.expl_plan_counts?.find((c) => c.shift_type === 1)?.counts[
              dIdx
            ] || 0,
          plan_shift2:
            schedule.expl_plan_counts?.find((c) => c.shift_type === 2)?.counts[
              dIdx
            ] || 0,
          plan_no_shift:
            schedule.expl_plan_counts?.find(
              (c) => c.shift_type !== 1 && c.shift_type !== 2
            )?.counts[dIdx] || 0,
          plan_shift1_by_vt: plan_shifts_by_vt
            .filter((sc) => sc.shift_type === 1)
            .reduce((acc, sc) => acc + sc.counts, 0),
          plan_shift2_by_vt: plan_shifts_by_vt
            .filter((sc) => sc.shift_type === 2)
            .reduce((acc, sc) => acc + sc.counts, 0),
          plan_no_shift_by_vt: plan_shifts_by_vt
            .filter((sc) => sc.shift_type !== 1 && sc.shift_type !== 2)
            .reduce((acc, sc) => acc + sc.counts, 0),
          workshifts1,
          workshifts2,
          rests,
          nowork,
          nowork_leave,
          workdays,
          undef_days,
          fake_rests,
          date: moment(
            `${monthPrefix}${(dIdx + 1).toFixed(0).padStart(2, "0")}`
          ),
        };

        const additionalRests =
          result.workdays -
          (result.plan_shift1_by_vt +
            result.plan_shift2_by_vt +
            result.plan_no_shift_by_vt) -
          (result.rests + result.nowork) -
          (result.date.isSameOrBefore(today, "day") ? result.undef_days : 0) -
          result.fake_rests;

        return { ...result, additionalRests };
      });

      const driverSummary = drivers.flatMap<DriverSummary & { id: string }>(
        (driver, idx) => {
          let workshifts1 = 0;
          let workshifts2 = 0;
          let rests = 0;
          let nowork = 0;
          let nowork_leave = 0;
          let workdays = 0;
          let undef_days = 0;
          let fake_rests = 0;

          for (const dIdx of daysInMonth) {
            const day_str = `${monthPrefix}${(dIdx + 1)
              .toFixed(0)
              .padStart(2, "0")}`;
            const state = driver.driver_days[day_str]?.state || 0;
            // const preliminaryState =
            //   driver.driver_days[day_str]?.pre_state?.state_id || 0;

            const states = processStates(
              state,
              // preliminaryState,
              false,
              scheduleParams[4],
              scheduleParams[1],
              driver,
              driver.driver_days[day_str],
              // isPreliminary,
              isHoliday
            );
            workshifts1 += states.workshifts1;
            workshifts2 += states.workshifts2;
            rests += states.rests;
            nowork += states.nowork;
            nowork_leave += states.nowork_leave;
            workdays += states.workdays;
            undef_days += states.undef_days;
            fake_rests += states.fake_rests;
          }

          const summary = {
            id: driver.id,
            workshifts1,
            workshifts2,
            rests,
            nowork,
            nowork_leave,
            workdays,
            undef_days,
            fake_rests,
            quota: Math.round(driver.quota / 8),
            //currentQuota: Math.round(driver.current_quota / 8),
            //overtimeHours: driver.current_workseconds,
            //overtimeHours: Object.values(driver.driver_days)
            //  .map((dd) => dd.workshift.work_seconds || 0)
            //  .reduce((prev, curr) => prev + curr, 0),
            prevLastState:
              driver &&
              driver.old_data &&
              driver.old_data.cnt &&
              driver.old_data.description_short !== " "
                ? driver.old_data.description_short +
                  "-" +
                  driver.old_data.cnt +
                  "бр"
                : "",
          };

          if (
            settings.scheduleSortOrder === "by_vehicle_type" &&
            (idx === 0 ||
              (idx > 0 &&
                driver.attributes?.preferred_vehicle_type !==
                  drivers[idx - 1]?.attributes?.preferred_vehicle_type))
          ) {
            return [
              {
                id: `VT-${driver.id}`,
                workshifts1: 0,
                workshifts2: 0,
                rests: 0,
                nowork: 0,
                nowork_leave: 0,
                workdays: 0,
                undef_days: 0,
                fake_rests: 0,
                quota: 0,
                //currentQuota: 0,
                //overtimeHours: 0,
                prevLastState: "",
              },
              summary,
            ];
          } else {
            return summary;
          }
        }
      );

      //console.timeEnd("Schedule summary");

      return {
        daySummary,
        driverSummary: Object.fromEntries(driverSummary.map((s) => [s.id, s])),
      };
    } else {
      return { daySummary: null, driverSummary: null };
    }
  }, [
    daysInMonth,
    drivers,
    isHoliday,
    schedule,
    scheduleParams,
    selectedVehicleType,
    settings.scheduleSortOrder,
    today,
  ]);

  return { ...summary, drivers: filteredDrivers };
}
