import {
  Build,
  Delete,
  DirectionsBus,
  Print,
  Comment,
  RvHookup,
} from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useMemo, useCallback } from "react";
import { useProfile } from "../../../common/useProfile";
import { useScheduleParameters } from "../../../components/schedule/useScheduleParameters";
import {
  usePostVehicleAutocolumnMutation,
  usePostVehicleCompositionMutation,
} from "../../../data/api/hooks";
import { ScheduleDriver } from "../../../data/api/types/schedule";
import { usePrintBlankJourneyList } from "../../../printing/usePrintJourneyList";
import { ElectroRosterVehicle } from "../types/ElectroRoster";
import { Roster } from "../types/Roster";

export function VehicleItemMenu({
  isReadOnly,
  isVehiclesOnly,
  selectedDate,
  drivers,
  vehicle,
  vehicleState,
  contextMenu,
  setContextMenu,
  clearVehicleState,
  showVehicleStateDialog,
  showCompositionDialog,
  showVehicleCommentDialog,
  setErrors,
  getRoster,
}: {
  isReadOnly: boolean;
  isVehiclesOnly: boolean;
  selectedDate: string;
  drivers: readonly ScheduleDriver[];
  vehicle: ElectroRosterVehicle;
  vehicleState: {
    id: number;
    category: number;
    description: string;
    description_short: string;
    comment: string | null;
    expired_docs?: boolean | undefined;
  } | null;
  contextMenu: {
    top: number;
    left: number;
  } | null;
  setContextMenu: (
    value: {
      top: number;
      left: number;
    } | null
  ) => void;
  clearVehicleState: (vehicle: ElectroRosterVehicle) => void;
  showVehicleStateDialog: (vehicle: ElectroRosterVehicle) => void;
  showCompositionDialog: (vehicle: ElectroRosterVehicle) => void;
  showVehicleCommentDialog: (vehicle: ElectroRosterVehicle) => void;
  setErrors: (errors: readonly string[]) => void;
  getRoster: () => Roster;
}) {
  const profile = useProfile();
  const { scheduleParameters } = useScheduleParameters();
  const [changeAutocolumn] = usePostVehicleAutocolumnMutation();
  const [changeComposition] = usePostVehicleCompositionMutation();

  const isPTDue = vehicle.vehicle.days[selectedDate]?.state?.id === -1;

  const otherAutocolumns = useMemo(
    () =>
      (scheduleParameters
        ? profile?.garage_autocolumns?.[scheduleParameters[0]]?.[
            scheduleParameters[1]
          ] || []
        : []
      ).filter((a) => a !== vehicle.vehicle.days[selectedDate]?.autocolumn),
    [
      profile?.garage_autocolumns,
      scheduleParameters,
      selectedDate,
      vehicle.vehicle.days,
    ]
  );

  const changeAutocolumnCallback = useCallback(
    (autocolumn: number) => {
      setContextMenu(null);
      if ((!isReadOnly || isVehiclesOnly) && scheduleParameters) {
        console.log("Change vehicle autocolumn:", vehicle, autocolumn);
        changeAutocolumn({
          transport: scheduleParameters[0],
          depot: scheduleParameters[1],
          autocolumn: autocolumn,
          vehicle_id: vehicle.vehicle_id,
          start_date: selectedDate,
          end_date: selectedDate,
        })
          .unwrap()
          .then((r) => {
            if (r.status === "ok") {
              console.log("Vehicle", vehicle, "moved:", r);
            } else {
              console.warn("Can't move vehicle", vehicle, r);
              alert(
                `Грешка при преместване на превозно средство ${vehicle.vehicle_id} в автоколона ${autocolumn}`
              );
            }
          })
          .catch((e) => {
            console.warn("Can't move vehicle", vehicle, e);
            alert(
              `Грешка при преместване на превозно средство ${vehicle.vehicle_id} в автоколона ${autocolumn}`
            );
          });
      }
    },
    [
      changeAutocolumn,
      isReadOnly,
      isVehiclesOnly,
      scheduleParameters,
      selectedDate,
      setContextMenu,
      vehicle,
    ]
  );

  const exportJourneyLists = usePrintBlankJourneyList();

  return (
    <Menu
      open={contextMenu !== null}
      onClose={() => setContextMenu(null)}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu || undefined}
    >
      <MenuItem disabled>Редакция на превозно средство</MenuItem>
      <Divider />

      {vehicleState && vehicleState.category !== 2
        ? [
            otherAutocolumns.length === 1
              ? [
                  <MenuItem
                    key="change-autocolumn"
                    disabled={isPTDue}
                    onClick={() =>
                      changeAutocolumnCallback(otherAutocolumns[0])
                    }
                  >
                    <ListItemIcon>
                      <DirectionsBus fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Прехвърли в другата автоколона</ListItemText>
                  </MenuItem>,
                  <Divider key="divider" />,
                ]
              : otherAutocolumns.length > 1
              ? otherAutocolumns
                  .map((autocolumn) => (
                    <MenuItem
                      key={`change-autocolumn-${autocolumn}`}
                      disabled={isPTDue}
                      onClick={() => changeAutocolumnCallback(autocolumn)}
                    >
                      <ListItemIcon>
                        <DirectionsBus fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        {`Прехвърли в автоколона ${autocolumn}`}
                      </ListItemText>
                    </MenuItem>
                  ))
                  .concat([<Divider key="divider" />])
              : [],
            <MenuItem
              key="print-daytasks"
              disabled={isPTDue}
              onClick={() => {
                setContextMenu(null);
                const oldRoster = getRoster();
                const oldRosterVehicle = oldRoster.vehicles.find(
                  (v) => v.vehicle_id === vehicle.vehicle_id
                );

                if (oldRosterVehicle) {
                  exportJourneyLists.exportPdf(
                    [oldRosterVehicle],
                    drivers,
                    [],
                    //apiVehicles,
                    oldRoster.allShifts,
                    selectedDate,
                    true
                  );
                }
              }}
            >
              <ListItemIcon>
                <Print fontSize="small" />
              </ListItemIcon>
              <ListItemText>Експорт на пътен лист в PDF</ListItemText>
            </MenuItem>,
          ].flat()
        : undefined}

      {vehicleState && vehicleState.id > 0 ? (
        <MenuItem
          disabled={isPTDue}
          onClick={() => {
            setContextMenu(null);
            clearVehicleState(vehicle);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Изчисти състояние</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          disabled={isPTDue}
          onClick={() => {
            setContextMenu(null);
            showVehicleStateDialog(vehicle);
          }}
        >
          <ListItemIcon>
            <Build fontSize="small" />
          </ListItemIcon>
          <ListItemText>Задай състояние</ListItemText>
        </MenuItem>
      )}
      {scheduleParameters && scheduleParameters[0] === "TM" ? (
        <MenuItem
          disabled={isPTDue}
          onClick={async () => {
            setContextMenu(null);
            if (vehicle?.composition) {
              try {
                setErrors([]);
                await changeComposition({
                  transport: scheduleParameters[0],
                  depot: scheduleParameters[1],
                  veh1_id: vehicle.vehicle_id,
                  veh2_id: null,
                  start_date: selectedDate,
                  end_date: null,
                }).unwrap();
              } catch (e: any) {
                console.warn("Грешка при запис на композиция:", e.data?.data);
                if (
                  e.data?.data?.message &&
                  typeof e.data.data.message === "string" &&
                  e.data.data.message !== ""
                ) {
                  setErrors([e.data.data.message]);
                } else if (
                  e.data?.data?.message &&
                  typeof e.data.data.message?.join === "function" &&
                  e.data.data.message !== ""
                ) {
                  setErrors(e.data.data.message);
                } else {
                  setErrors(["Неизвестна грешка при запис на композиция"]);
                }
              }
            } else {
              showCompositionDialog(vehicle);
            }
          }}
        >
          <ListItemIcon>
            <RvHookup fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {vehicle?.composition ? "Разкачи композиция" : "Създай композиция"}
          </ListItemText>
        </MenuItem>
      ) : undefined}
      <MenuItem
        onClick={() => {
          setContextMenu(null);
          showVehicleCommentDialog(vehicle);
        }}
      >
        <ListItemIcon>
          <Comment fontSize="small" />
        </ListItemIcon>
        <ListItemText>Коментар</ListItemText>
      </MenuItem>
    </Menu>
  );
}
