import { grey } from "@mui/material/colors";
import { CellDef } from "jspdf-autotable";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { useGarageSettings } from "../common/useGarageSettings";
import { DaySummary, DriverSummary } from "../components/schedule/types";
import { useScheduleParameters } from "../components/schedule/useScheduleParameters";
import {
  Schedule,
  ScheduleDriver,
  ScheduleParameters,
  sortArray,
} from "../data/api/types/schedule";
import { useDepotFilter } from "../filters/useDepotFilter";
import { PrintScheduleDialogOptions } from "../pages/schedule/PrintScheduleDialog";
import { useLazyVehicleTypesList } from "../pages/schedule/useVehicleTypesList";
import { ReportPrinter } from "./ReportPrinter";

function getDriverNumberCol(driver: ScheduleDriver) {
  if (
    driver?.attributes?.preferred_workshift &&
    driver.attributes.preferred_workshift > 0
  ) {
    return `${driver.sl_number} (${driver.attributes.preferred_workshift})`;
  } else {
    return driver?.sl_number?.toString() || "";
  }
}

function getDriverGroups(
  drivers: ScheduleDriver[],
  driverSummary: Record<string, DriverSummary>,
  scheduleSortOrder: "by_vehicle_type" | "by_driver_id"
) {
  return scheduleSortOrder === "by_vehicle_type"
    ? sortArray(
        drivers,
        [
          "attributes.preferred_vehicle_type",
          "attributes.preferred_vehicle",
          "sl_number",
        ],
        {
          in_place: true,
          reverse: false,
        }
      ).reduce((groups, driver) => {
        if (groups.length === 0) {
          // Първия водач, добавяме група
          groups.push([{ ...driver, summary: driverSummary[driver.id] }]);
        } else if (
          groups[groups.length - 1][groups[groups.length - 1].length - 1]
            .attributes?.preferred_vehicle_type ===
          driver.attributes?.preferred_vehicle_type
        ) {
          // Марката на текущя водач е същата като последната добавена, увеличаваме summaryIdx с 1
          groups[groups.length - 1].push({
            ...driver,
            summary: driverSummary[driver.id],
          });
        } else {
          // Марката на текущя водач е различна от последната добавена, добавяме нова група и увеличаваме summaryIdx с 2
          groups.push([{ ...driver, summary: driverSummary[driver.id] }]);
        }
        return groups;
      }, [] as (ScheduleDriver & { summary: DriverSummary })[][])
    : [
        sortArray(drivers, ["sl_number"], {
          in_place: true,
          reverse: false,
        }).map((driver) => ({ ...driver, summary: driverSummary[driver.id] })),
      ];
}

function getReportRows(
  scheduleParameters: ScheduleParameters,
  driverGroups: (ScheduleDriver & { summary: DriverSummary })[][],
  vehicleTypesList: Record<
    number,
    {
      id: number;
      description: string;
      koef: number;
      places: number;
    }
  >,
  // driverList: Record<number, string>,
  days: string[],
  holidays: boolean[],
  driverNumberWidth: number,
  nameWidth: number,
  busWidth: number,
  noteWidth: number,
  //fontSize: number,
  scheduleSortOrder: "by_vehicle_type" | "by_driver_id",
  getFontSize: () => number,
  options: PrintScheduleDialogOptions
) {
  // console.log("getReportRows");

  const dateStrings = days.map(
    (d) =>
      `${scheduleParameters[2]
        .toString()
        .padStart(2, "0")}-${scheduleParameters[3]
        .toString()
        .padStart(2, "0")}-${d.padStart(2, "0")}`
  );

  return driverGroups.flatMap((group) => {
    const firstRow: CellDef[][] =
      group.length > 0 && group[0].attributes?.preferred_vehicle_type
        ? [
            [
              {
                content:
                  "Марка: " +
                    vehicleTypesList[group[0].attributes.preferred_vehicle_type]
                      ?.description ||
                  group[0].attributes.preferred_vehicle_type.toString(),
                colSpan: days.length + 4 + (options.additionalData ? 3 : 0),
                styles: {
                  halign: "left",
                  fontSize: 10,
                  fontStyle: "bold",
                },
              },
            ],
          ]
        : [
            [
              {
                content: "Сменници",
                colSpan: days.length + 4 + (options.additionalData ? 3 : 0),
                styles: {
                  halign: "left",
                  fontSize: 10,
                  fontStyle: "bold",
                },
              },
            ],
          ];

    const driverRow: CellDef[][] = group.map<CellDef[]>((driver) =>
      [
        {
          content: getDriverNumberCol(driver),
          styles: {
            halign: "left",
            cellWidth: driverNumberWidth * getFontSize() + 6,
            fontStyle: "bold",
          },
        } as CellDef,
        options.additionalData
          ? [
              {
                content: driver.summary.quota,
                styles: {
                  halign: "left",
                  cellWidth: nameWidth / 4,
                  fontStyle: "bolditalic",
                  // fontSize: 6,
                },
              } as CellDef,
              {
                content:
                  driver.summary.workshifts1 + driver.summary.workshifts2,
                styles: {
                  halign: "left",
                  cellWidth: nameWidth / 4,
                  fontStyle: "bolditalic",
                },
              } as CellDef,
              {
                content: driver.summary.rests,
                styles: {
                  halign: "left",
                  cellWidth: nameWidth / 4,
                  fontStyle: "bolditalic",
                },
              } as CellDef,
              {
                content: driver.summary.nowork,
                styles: {
                  halign: "left",
                  cellWidth: nameWidth / 4,
                  fontStyle: "bolditalic",
                },
              } as CellDef,
            ]
          : ({
              content: driver.name /*|| driverList[driver.sl_number]*/ || "",
              styles: {
                halign: "left",
                cellWidth: nameWidth,
                fontStyle: "italic",
                fontSize: 6,
              },
            } as CellDef),
        {
          content: driver.attributes?.preferred_vehicle
            ? driver.attributes.preferred_vehicle.toString()
            : "",
          styles: {
            halign: "left",
            cellWidth: busWidth * getFontSize() + 6,
            fontStyle: "bold",
          },
        } as CellDef,
      ]
        .flat()
        .concat(
          dateStrings.map((date, dateIdx) => {
            const driver_day = driver.driver_days[date];

            let content: string;
            if (options.startDate <= date && options.endDate >= date) {
              content = driver_day?.state_short_name || " ";
            } else {
              content = " ";
            }

            return {
              content: content,
              styles: {
                fillColor: holidays[dateIdx] ? grey[300] : undefined,
                fontSize:
                  driver_day?.state_short_name &&
                  driver_day?.state_short_name.length > 1
                    ? 6
                    : 8,
              },
            };
          })
        )
        .concat({
          content: "",
          styles: {
            halign: "left",
            cellWidth: noteWidth,
          },
        } as CellDef)
    );

    if (scheduleSortOrder === "by_vehicle_type") {
      return firstRow.concat(driverRow);
    } else {
      return driverRow;
    }
  });
}

function getAdditionalDataRows(
  scheduleParameters: ScheduleParameters,
  daySummary: DaySummary[],
  days: string[],
  holidays: boolean[],
  driverNumberWidth: number,
  nameWidth: number,
  busWidth: number,
  noteWidth: number,
  //fontSize: number,
  _scheduleSortOrder: "by_vehicle_type" | "by_driver_id",
  getFontSize: () => number
) {
  // console.log("getReportRows");

  const dateStrings = days.map(
    (d) =>
      `${scheduleParameters[2]
        .toString()
        .padStart(2, "0")}-${scheduleParameters[3]
        .toString()
        .padStart(2, "0")}-${d.padStart(2, "0")}`
  );

  const driverRow: CellDef[][] = [
    [
      {
        content: "Експлоатационен план - първа смяна",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content:
              daySummary[dateIdx].plan_shift1_by_vt +
              daySummary[dateIdx].plan_no_shift_by_vt,
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Разлика - първа смяна",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: -(
              daySummary[dateIdx].plan_shift1_by_vt +
              daySummary[dateIdx].plan_no_shift_by_vt -
              daySummary[dateIdx].workshifts1
            ),
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Експлоатационен план - втора смяна",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: daySummary[dateIdx].plan_shift2_by_vt,
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Разлика - втора смяна",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: -(
              daySummary[dateIdx].plan_shift2_by_vt -
              daySummary[dateIdx].workshifts2
            ),
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Оставащи почивки",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: daySummary[dateIdx].additionalRests,
            // content:
            //   daySummary[dateIdx].workdays -
            //   (daySummary[dateIdx].plan_shift1_by_vt +
            //     daySummary[dateIdx].plan_shift2_by_vt +
            //     daySummary[dateIdx].plan_no_shift_by_vt) -
            //   (daySummary[dateIdx].rests + daySummary[dateIdx].nowork) -
            //   (daySummary[dateIdx].date.isSameOrBefore(today, "day")
            //     ? daySummary[dateIdx].undef_days
            //     : 0),
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Почивки по график",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: daySummary[dateIdx].rests,
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Болнични",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content:
              daySummary[dateIdx].nowork - daySummary[dateIdx].nowork_leave,
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
    [
      {
        content: "Отпуска",
        styles: {
          halign: "left",
          cellWidth:
            (driverNumberWidth + busWidth) * getFontSize() + 12 + nameWidth,
          fontStyle: "bold",
        },
      } as CellDef,
    ]
      .concat(
        dateStrings.map((_date, dateIdx) => {
          return {
            content: daySummary[dateIdx].nowork_leave,
            styles: {
              fillColor: holidays[dateIdx] ? grey[300] : undefined,
              fontSize: 6,
            },
          };
        })
      )
      .concat({
        content: "",
        styles: {
          halign: "left",
          cellWidth: noteWidth,
        },
      } as CellDef),
  ];

  return driverRow;
}

export function useDriverSchedulePdfExporter() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const { getVehicleTypesList } = useLazyVehicleTypesList();
  // const [getDriverNames] = useLazyGetDriversListQuery();

  // const selectedTransport = useSelector(
  //   (state: RootState) => state.filters.selectedTransport
  // );
  const { depots, selectedDepot } = useDepotFilter();
  const settings = useGarageSettings();

  const { scheduleParameters } = useScheduleParameters();
  const selectedTransport = scheduleParameters ? scheduleParameters[0] : "";

  const [today, setToday] = useState<Moment>(moment().startOf("day"));
  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = moment().startOf("day");
      if (newDate.isAfter(today)) {
        setToday(newDate);
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [today]);

  const exportPdf = useCallback(
    async (
      schedule: Schedule,
      daySummary: DaySummary[],
      driverSummary: Record<string, DriverSummary>,
      isHoliday: (day: string | Moment) => boolean | undefined,
      options: PrintScheduleDialogOptions
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const vehicleTypesListPromise = getVehicleTypesList(schedule, false);
        // const driverListDataPromise =
        //   selectedTransport !== ""
        //     ? getDriverNames({
        //         transport: selectedTransport,
        //         garage: selectedDepot,
        //       }).unwrap()
        //     : Promise.resolve<DriverList>({
        //         drivers: [],
        //         available_skills: [],
        //         vehicles: [],
        //       });

        const [vehicleTypesList /*driverListData*/] = await Promise.all([
          vehicleTypesListPromise,
          // driverListDataPromise,
        ]);

        // const driverList: Record<number, string> = Object.fromEntries(
        //   driverListData.drivers.map((d) => [d.sl_number, d.name])
        // );

        const month = moment(
          `${schedule.db_schedule.year}-${schedule.db_schedule.month}-01`,
          "YYYY-MM-DD"
        );
        const days = [...new Array(month.daysInMonth())].map((_d, idx) =>
          (idx + 1).toFixed(0)
        );
        const holidays = [...new Array(month.daysInMonth())].map(
          (_d, idx) =>
            !!isHoliday(
              `${schedule.db_schedule.year
                .toString()
                .padStart(4, "0")}-${schedule.db_schedule.month
                .toString()
                .padStart(2, "0")}-${(idx + 1)
                .toFixed(0)
                .toString()
                .padStart(2, "0")}`
            )
        );

        const printer = new ReportPrinter({
          orientation: "landscape",
          marginX: 30,
          marginY: 30,
          headerText:
            selectedTransport === "A"
              ? `ГРАФИК ЗА МЕСЕЦ ${month
                  .format("MMM")
                  .toUpperCase()} ${month.format("YYYY")} г., АП "${
                  depots.find((d) => d.id === selectedDepot)?.value || "?"
                }", Автокoлона ${schedule.db_schedule.autocolumn}`
              : undefined,
        });

        if (selectedTransport === "A") {
          printer.setFont("normal", 12, 1.2);

          printer.printText(
            "Утвърдил Директор: .........................",
            printer.printableWidth -
              (printer.getWidth(
                "Утвърдил Директор: ........................."
              ) +
                160)
          );
          printer.printSpace(15);
        }

        printer.setFont("bold", 12, 1.2);
        const startDate = moment(options.startDate, "YYYY-MM-DD");
        const endDate = moment(options.endDate, "YYYY-MM-DD");
        if (
          startDate.isSameOrBefore(month) &&
          endDate.isSameOrAfter(month.clone().endOf("month").startOf("day"))
        ) {
          printer.printText(
            `График за месец ${month.format("MMM")} ${month.format("YYYY")} г.`
          );
        } else {
          printer.printText(
            `График от ${startDate.format("LL")} до ${endDate.format("LL")}`
          );
        }

        printer.setFont("italic", 10, 1.2);
        printer.printText(
          `Гараж ${
            depots.find((d) => d.id === selectedDepot)?.value || "?"
          }, автокoлона ${schedule.db_schedule.autocolumn}`
        );

        printer.setFont("normal", 9, 1.2);
        const fontSize = 9;

        const driverGroups = getDriverGroups(
          Object.values(schedule?.drivers || {}),
          driverSummary,
          settings.scheduleSortOrder
        );

        const driverNumberWidth = driverGroups
          .flat()
          .map((driver) => printer.getWidth(getDriverNumberCol(driver)))
          .reduce((acc, w) => Math.max(acc, w), 1);

        const busWidth = printer.getWidth("Авто-");
        const nameWidth = 130;
        const noteWidth = printer.getWidth("Забележка") * fontSize + 6;

        if (options.additionalData) {
          printer.autoTable({
            styles: {
              cellWidth:
                (printer.doc.internal.pageSize.getWidth() -
                  printer.pageMarginX * 2 -
                  (driverNumberWidth * fontSize + 6) -
                  (busWidth * fontSize + 6) -
                  nameWidth -
                  noteWidth) /
                days.length,
              halign: "center",
              valign: "middle",
              font: "Roboto",
              fontSize: 8,
              cellPadding: 4,
              lineColor: [0, 0, 0],
              lineWidth: 0.25,
            },
            head: [
              [
                // { content: "Водач", styles: { halign: "left" } } as CellDef,
                // { content: "Име", styles: { halign: "left" } } as CellDef,
                // { content: "Авто-бус", styles: { halign: "left" } } as CellDef,
                { content: "", styles: { halign: "left" } } as CellDef,
              ]
                .concat(
                  days.map((d, dIdx) => ({
                    content: d,
                    styles: {
                      fillColor: holidays[dIdx] ? grey[300] : undefined,
                    },
                  })) as CellDef[]
                )
                .concat({
                  content: " ",
                  styles: { halign: "left" },
                } as CellDef),
            ],
            body: scheduleParameters
              ? getAdditionalDataRows(
                  scheduleParameters,
                  daySummary,
                  days,
                  holidays,
                  driverNumberWidth,
                  nameWidth,
                  busWidth,
                  noteWidth,
                  //fontSize,
                  settings.scheduleSortOrder,
                  printer.doc.getFontSize
                )
              : [],
          });

          printer.printSpace(30);
        }

        printer.autoTable({
          styles: {
            cellWidth:
              (printer.doc.internal.pageSize.getWidth() -
                printer.pageMarginX * 2 -
                (driverNumberWidth * fontSize + 6) -
                (busWidth * fontSize + 6) -
                nameWidth -
                noteWidth) /
              days.length,
            halign: "center",
            valign: "middle",
            font: "Roboto",
            fontSize: 8,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            lineWidth: 0.25,
          },
          head: [
            [
              { content: "Водач", styles: { halign: "left" } } as CellDef,
              options.additionalData
                ? [
                    { content: "Норма", styles: { halign: "left" } } as CellDef,
                    { content: "Смени", styles: { halign: "left" } } as CellDef,
                    {
                      content: "Почивки",
                      styles: { halign: "left" },
                    } as CellDef,
                    { content: "БО", styles: { halign: "left" } } as CellDef,
                  ]
                : ({ content: "Име", styles: { halign: "left" } } as CellDef),
              { content: "Авто-бус", styles: { halign: "left" } } as CellDef,
            ]
              .flat()
              .concat(
                days.map((d, dIdx) => ({
                  content: d,
                  styles: { fillColor: holidays[dIdx] ? grey[300] : undefined },
                })) as CellDef[]
              )
              .concat({
                content: "Забележка",
                styles: { halign: "left" },
              } as CellDef),
          ],
          body: scheduleParameters
            ? getReportRows(
                scheduleParameters,
                driverGroups,
                vehicleTypesList,
                // driverList,
                days,
                holidays,
                driverNumberWidth,
                nameWidth,
                busWidth,
                noteWidth,
                //fontSize,
                settings.scheduleSortOrder,
                printer.doc.getFontSize,
                options
              )
            : [],
        });

        if (selectedTransport === "A") {
          printer.setFont("normal", 12, 1.2);
          printer.printSpace(35);

          printer.printText(
            "Нарядчик:             ..............................."
          );
          printer.printSpace(25);

          printer.printText("Н-к Автоколона:  ...............................");
          printer.printSpace(25);

          printer.printText(
            "Зам. Директор:     ..............................."
          );

          printer.save(
            `График-${month.format("YYYY-MM")}-${options.startDate}-${
              options.endDate
            }-${depots.find((d) => d.id === selectedDepot)?.value || "-"}-${
              schedule.db_schedule.autocolumn
            }-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
          );
        }

        printer.save(
          `График-${month.format("YYYY-MM")}-${options.startDate}-${
            options.endDate
          }-${depots.find((d) => d.id === selectedDepot)?.value || "-"}-${
            schedule.db_schedule.autocolumn
          }-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
        //console.log("PDF exporter", doc);
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      depots,
      getVehicleTypesList,
      scheduleParameters,
      selectedDepot,
      selectedTransport,
      settings.scheduleSortOrder,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
