import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../data/store";
import { useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetTimetablesByDateQuery } from "../../data/api/hooks";
import { useExplotationPlan } from "../nomenclatures/useExplotationPlan";
import { QueryError } from "../QueryError";
import {
  WorkShiftCountColumns,
  WorkShiftCountRowData,
  createWorkShiftCountData,
} from "./useWorkShiftCounts";
import { updateSelectedDate } from "../../data/filtersSlice";

function StickyHeadTable({ rows }: { rows: WorkShiftCountRowData[] }) {
  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="stickyHeader">
          <TableHead>
            <TableRow>
              {WorkShiftCountColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.linenum}>
                  {WorkShiftCountColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell> Общо</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                {rows.reduce((cnt, r) => cnt + r.first_shift_count, 0)}
              </TableCell>
              <TableCell align="right">
                {rows.reduce((cnt, r) => cnt + r.second_shift_count, 0)}
              </TableCell>
              <TableCell align="right">
                {rows.reduce((cnt, r) => cnt + r.total_shifts, 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function WorkShiftCountsReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const dispatch = useAppDispatch();

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );
  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const { data, isLoading, isError } = useGetTimetablesByDateQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      avtocolona: selectedAvtoColumn,
      date: selectedDate,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(selectedDepot !== -1 && selectedTransport !== ""),
    }
  );

  const rows = useMemo<WorkShiftCountRowData[]>(() => {
    if (data?.expl_plan && data?.expl_plan.length > 0) {
      let arr: WorkShiftCountRowData[] = [];
      //for (const line in data.expl_plan) {
      const arr_element = data.expl_plan
        .filter((line) => line.cars.length > 0)
        .sort((a, b) => (a.line_order > b.line_order ? 1 : -1))
        .map((line) =>
          createWorkShiftCountData(
            line.line_name,
            line.code,
            0, // не се ползва

            line.cars.reduce(
              (ac, car) =>
                (ac += car.workshifts.reduce(
                  (acc, w) =>
                    w.autocolumn === selectedAvtoColumn && w.shift_type === 1
                      ? (acc += 1)
                      : acc,
                  0
                )),
              0
            ),
            line.cars.reduce(
              (ac, car) =>
                (ac += car.workshifts.reduce(
                  (acc, w) =>
                    w.autocolumn === selectedAvtoColumn && w.shift_type > 1
                      ? (acc += 1)
                      : acc,
                  0
                )),
              0
            )
          )
        );
      arr = arr.concat(arr_element);
      // }
      return arr;
    } else return [];
  }, [data?.expl_plan, selectedAvtoColumn]);
  //console.log("Row2", rows2);
  // const rows = useMemo(
  //   () =>
  //     ExplPLan?.data && ExplPLan?.data !== undefined && selectedDate
  //       ? Object.values(ExplPLan.data).map((value) =>
  //           createWorkShiftCountData(
  //             value.line_name,
  //             value.timetables[selectedDate.format("yyyy-MM-DD")]
  //               ? value.timetables[selectedDate.format("yyyy-MM-DD")].tt_code
  //               : "",
  //             0,
  //             value.timetables[selectedDate.format("yyyy-MM-DD")]
  //               ?.shifts_count[1]
  //               ? value.timetables[selectedDate.format("yyyy-MM-DD")]
  //                   .shifts_count[1].count
  //               : 0,
  //             value.timetables[selectedDate.format("yyyy-MM-DD")]
  //               ?.shifts_count[2]
  //               ? value.timetables[selectedDate.format("yyyy-MM-DD")]
  //                   .shifts_count[2].count
  //               : 0
  //           )
  //         )
  //       : [],
  //   [ExplPLan.data, selectedDate]
  // );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container>
        <Grid container spacing={2} mt={1}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsAutocolumnSelect ? (
            <Grid item xs={12} sm={2}>
              <AutoColumnSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={2}>
            <DatePicker
              value={moment(selectedDate)}
              onChange={(e) =>
                e &&
                e.isValid() &&
                dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
              }
              label="Дата"
              views={["year", "month", "day"]}
              openTo="day"
              slotProps={{
                textField: {
                  variant: "standard",
                  sx: { m: 1, width: "100%" },
                },
              }}
            />
          </Grid>
        </Grid>
        {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />}
      </Container>

      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Backdrop
          open={isLoading}
          sx={{
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 1,
              marginBottom: 4,
            }}
          />
        </Backdrop>
        <Container sx={{ height: "100%" }}>
          {rows && !isLoading && !isError && <StickyHeadTable rows={rows} />}
        </Container>
      </Box>
    </Box>
  );
}
